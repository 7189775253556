import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfilTrilha';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner, Table, Input } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg';
import { format, differenceInDays } from 'date-fns';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class VincularParticipantes extends Component {
    state = {
        id_startup: "",
        id_usuario: "",
        currentPage: 1,
        size: 20,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        tipo: "",
        tipo_escolha: "",
        id_usuario: "",
        filtro: false,
        pesquisando: false,
        procura: "",
        filtrado: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha, id_usuario } = this.props.match.params;
        this.setState({ id_trilha })
        this.listarAlunos();
        this.listarTrilha(id_trilha)
        this.listarIncubadoras()
        this.listarStartups()
        this.listarPerfis()


        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.alunos && nextProps.alunos) this.listarAlunos();
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha);
        }
        if (!this.props.incubadoras && nextProps.incubadoras) this.listarIncubadoras();
        if (!this.props.startups && nextProps.startups) this.listarStartups();
        if (!this.props.perfis && nextProps.perfis) this.listarPerfis();
        this.receberDadosAPI();

    }

    componentWillUnmount() {
        this.props.limparAlunos();
    }

    receberDadosAPI() {
        if (typeof this.props.alunos != "undefined" && this.props.alunos != null && !this.state.dadosApi && this.props.alunos.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
            if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && !this.state.dadosApi) {

                if (typeof this.props.startups != "undefined" && this.props.startups != null && !this.state.dadosApi) {

                    if (typeof this.props.perfis != "undefined" && this.props.perfis != null && !this.state.dadosApi) {
                        this.setState({ dadosApi: true });
                    }
                }
            }
        }
    }
    usuarioNovo(data) {
        var result = differenceInDays(
            new Date(),
            data
        )
    }
    listarAlunos() {
        const { currentPage, size } = this.state;
        this.props.getFuturosParticipantes(currentPage, size)
        const { participantes } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (participantes === "undefined") return null
    }
    changePageAtual(currentPage) {
        this.props.limparAlunos();
        const { tipo_escolha, size, filtrado, tipo } = this.state
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            if (tipo == "Incubadora" && filtrado) {
                this.props.getUsuariosIncubadora(tipo_escolha, currentPage, size)
            } else if (tipo == "Startup" && filtrado) {
                this.props.getParticipantes(tipo_escolha, currentPage, size)
            } else if (tipo == "Perfil" && filtrado) {
                this.props.getUsuariosPerfil(tipo_escolha, currentPage, size)
            } else {
                this.listarUsuarios();
            }
        })
    }
    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listarIncubadoras() {
        this.props.getIncubadorasCompleto()
        const { incubadoras } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadoras === "undefined") return null
    }

    listarStartups() {
        this.props.getStartupsCompleto()
        const { startups } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startups === "undefined") return null
    }

    listarPerfis() {
        const { currentPage, size } = this.state
        this.props.getPerfisAcesso(currentPage, size)
        const { perfis } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (perfis === "undefined") return null

    }

    apagarParticipante(id) {
        this.props.limparParticipantes();
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarParticipante(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarUsuarios();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }

    vincularParticipante(id_usuario) {
        const { id_trilha } = this.state;
        var id_curso = id_trilha
        ////////////console.log(id_usuario, id_startup)
        this.setState({ carregando: true })
        this.props.postMatricula({ id_usuario, id_curso }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }


    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.vincularAutor.modal_titulo,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.vincularParticipante.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.vincularParticipante(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.vincularParticipante.modal_sucesso
        })
    }


    handleSearch(procura) {
        this.setState({ procura: procura })
        this.setState({ pesquisando: true })
        // this.props.limparUsuarios()
        const { currentPage, size } = this.state;
        this.setState({ currentPage: 1 })
        this.props.getSearch(procura, currentPage, size)
    }

    eraseSearch(campo) {
        this.setState({ pesquisando: false })
        this.setState({ [campo]: "" })

    }


    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            this.handleSearch(procura)
        }
    }

    handleUsers() {
        const { tipo, tipo_escolha, currentPage, size, filtrado } = this.state
        this.setState({ filtrado: true })
        this.setState({ dadosApi: false })
        if (tipo == "Incubadora") {
            this.props.limparAlunos()
            this.setState({ dadosApi: false })
            this.props.getUsuariosIncubadora(tipo_escolha)

        } else if (tipo == "Startup") {
            this.props.limparAlunos()
            this.setState({ dadosApi: false })
            this.props.getParticipantes(tipo_escolha)

        } else if (tipo == "Perfil") {
            this.props.limparAlunos()
            this.setState({ dadosApi: false })
            this.props.getUsuariosPerfil(tipo_escolha)

        }
        const { usuarios } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (usuarios === "undefined") return null
    }

    cancelFiltro() {
        const { filtrado, filtro } = this.state
        this.setState({ filtrado: false })
        this.setState({ filtro: false })
        this.listarAlunos();
    }


    render() {
        const { id_trilha, msg, carregando, filtrado, tipo, tipo_escolha, id_usuario, filtro, erro, success, dadosApi, formSuccess, pesquisando, procura, idioma } = this.state;

        var alunos = [];
        if (pesquisando == false) {

            if (filtrado) {
                if (tipo == "Incubadora") {
                    if (this.props.usuariosIncubadora) usuariosIncubadora = this.props.usuariosIncubadora.usuarios;
                }
                if (tipo == "Startup") {
                    if (this.props.participantes) participantes = this.props.participantes.usuarios
                }
                if (tipo == "Perfil") {
                    if (this.props.usuariosPerfil) usuariosPerfil = this.props.usuariosPerfil.dados
                }
            } else {
                if (this.props.alunos) alunos = this.props.alunos.dados;
                var temPaginaAnterior = false;
                if (typeof this.props.alunos != "undefined" && this.props.alunos != null && this.props.alunos.currentPage !== "" && this.props.alunos.currentPage !== 1) {
                    temPaginaAnterior = true;
                }
                var proximaPagina = false;
                var temProximaPagina = false;
                if (typeof this.props.alunos != "undefined" && this.props.alunos !== null && this.props.alunos.nextPage <= this.props.alunos.totalPages && this.props.alunos.nextPage != null) {
                    proximaPagina = true;
                    temProximaPagina = true;
                }
            }
        } else {
            if (this.props.search) alunos = this.props.search.dados;
            var temPaginaAnteriorSearch = false;
            if (typeof this.props.search != "undefined" && this.props.search != null && this.props.search.currentPage !== "" && this.props.search.currentPage !== 1) {
                temPaginaAnteriorSearch = true;
            }
            var proximaPaginaSearch = false;
            var temProximaPaginaSearch = false;
            if (typeof this.props.search != "undefined" && this.props.search !== null && this.props.search.nextPage <= this.props.search.totalPages && this.props.search.nextPage != null) {
                proximaPaginaSearch = true;
                temProximaPaginaSearch = true;
            }
        }
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var usuariosPerfil = []
        if (this.props.usuariosPerfil) usuariosPerfil = this.props.usuariosPerfil.dados
        var usuariosIncubadora = []
        if (this.props.usuariosIncubadora) usuariosIncubadora = this.props.usuariosIncubadora.usuarios
        var participantes = []
        if (this.props.participantes) participantes = this.props.participantes.usuarios


        var incubadoras = []
        if (this.props.incubadoras) incubadoras = this.props.incubadoras.dados
        var startups = []
        if (this.props.startups) startups = this.props.startups.dados
        var perfis = []
        if (this.props.perfis) perfis = this.props.perfis.dados
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/participantes",
            state: { msg: idioma.vincularParticipante.msg_sucesso }
        }}></Redirect>
        ////console.log(alunos)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.vincularParticipante.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoPerfil dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row m-2">
                                <Link to="#" onClick={() => this.setState({ filtro: !filtro })}> <Botao loading={carregando} classes="botao-responsivo botao-estilo " posicao={"E"} cor="success" icone="filter" texto={idioma.vincularAutor.bt_filtrar} /></Link>

                                {filtro == true ?
                                    <>
                                        <Link to="#" onClick={() => this.cancelFiltro()}> <Botao loading={carregando} classes="botao-responsivo botao-estilo ml-2" posicao={"E"} cor="danger" icone="times" texto={idioma.vincularAutor.bt_cancelar} /></Link>

                                        <Input type="select" value={tipo} onChange={(ev) => this.onChangeInput("tipo", ev)} className="mt-2">
                                            <option>{idioma.vincularAutor.tipo_filtro}</option>
                                            <option value="Incubadora">{idioma.vincularAutor.ambiente}</option>
                                            <option value="Startup">{idioma.vincularAutor.negocio}</option>
                                            <option value="Perfil">{idioma.vincularAutor.perfil}</option>
                                        </Input>
                                        <Input type="select" value={tipo_escolha} onChange={(ev) => this.onChangeInput("tipo_escolha", ev)} className="mt-2" disabled={tipo == "" ? true : false} >
                                            {tipo == "" ?
                                                <option>{idioma.vincularAutor.escolha_filtro}</option>
                                                : ""}
                                            {tipo == "Incubadora" ?
                                                <>
                                                    <option>{idioma.vincularAutor.escolha_amb}</option>
                                                    {incubadoras ?
                                                        incubadoras.map(incubadora => (
                                                            <option value={incubadora.id}>{incubadora.nome}</option>

                                                        ))
                                                        : ""}
                                                </>
                                                : tipo == "Startup" ?
                                                    <>
                                                        <option>{idioma.vincularAutor.escolha_neg}</option>
                                                        {startups ?
                                                            startups.map(startup => (
                                                                <option value={startup.id}>{startup.nome}</option>

                                                            ))
                                                            : ""}
                                                    </>
                                                    : tipo == "Perfil" ?
                                                        <>
                                                            <option>{idioma.vincularAutor.escolha_perfil}</option>
                                                            {perfis ?
                                                                perfis.map(perfil => (
                                                                    <option value={perfil.id}>{perfil.nome}</option>

                                                                ))
                                                                : ""}
                                                        </>
                                                        :
                                                        <option>{idioma.vincularAutor.escolha_info}</option>}

                                        </Input>
                                        {filtro == true ?
                                            <Link to="#" onClick={() => this.handleUsers()}> <Botao loading={carregando} texto={idioma.vincularAutor.bt_filtrar} classes="botao-responsivo botao-estilo" cor="success" /> </Link>
                                            : ""}
                                    </>
                                    : ""}
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-lg-8">
                                    <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={`${idioma.vincularAutor.bt_pesquisar}...`} />
                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <span className="pr-3" onClick={() => this.handleSearch(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.vincularAutor.bt_pesquisar} icone="search" posicao="E" /></span>
                                        <span value={procura} onClick={() => this.eraseSearch("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    {dadosApi ?
                                        <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{idioma.vincularAutor.nome}</th>
                                                        <th>{idioma.vincularAutor.ambiente}</th>
                                                        <th>{idioma.vincularAutor.negocio}</th>
                                                        <th>{idioma.vincularAutor.acao}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(tipo == "Incubadora") && filtrado ?

                                                        usuariosIncubadora.map(aluno => (
                                                            <tr key={aluno.id}>

                                                                <td>{aluno.nome}</td>
                                                                <td>{aluno.incubadoras.length > 0 ?
                                                                    aluno.incubadoras.length == 1 ?
                                                                        aluno.incubadoras.map(incubadora => (
                                                                            incubadora
                                                                        ))
                                                                        :
                                                                        aluno.incubadoras.map(incubadora => (
                                                                            incubadora + ', '
                                                                        ))
                                                                    :
                                                                    <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                </td>
                                                                <td>{aluno.startups.length > 0 ?
                                                                    aluno.startups.length == 1 ?
                                                                        aluno.startups.map(startup => (
                                                                            startup
                                                                        ))
                                                                        :
                                                                        aluno.startups.map(startup => (
                                                                            startup + ', '
                                                                        ))
                                                                    :
                                                                    <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                </td>
                                                                <td>
                                                                    <span className="pr-3" onClick={() => this.vincularParticipante(aluno.id)} id="pesquisa" name="pesquisa">
                                                                        <Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.vincularAutor.bt_vincular} icone="sign-in-alt" posicao="E" />
                                                                    </span>
                                                                </td>

                                                            </tr>
                                                        ))
                                                        :
                                                        (tipo == "Startup") && filtrado ?
                                                            participantes.map(aluno => (
                                                                <tr key={aluno.id}>

                                                                    <td>{aluno.nome}</td>
                                                                    <td>{aluno.incubadoras.length > 0 ?
                                                                        aluno.incubadoras.length == 1 ?
                                                                            aluno.incubadoras.map(incubadora => (
                                                                                incubadora
                                                                            ))
                                                                            :
                                                                            aluno.incubadoras.map(incubadora => (
                                                                                incubadora + ', '
                                                                            ))
                                                                        :
                                                                        <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                    </td>
                                                                    <td>{aluno.startups.length > 0 ?
                                                                        aluno.startups.length == 1 ?
                                                                            aluno.startups.map(startup => (
                                                                                startup
                                                                            ))
                                                                            :
                                                                            aluno.startups.map(startup => (
                                                                                startup + ', '
                                                                            ))
                                                                        :
                                                                        <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                    </td>
                                                                    <td>
                                                                        <span className="pr-3" onClick={() => this.vincularParticipante(aluno.id)} id="pesquisa" name="pesquisa">
                                                                            <Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.vincularAutor.bt_vincular} icone="sign-in-alt" posicao="E" />
                                                                        </span>
                                                                    </td>

                                                                </tr>
                                                            ))
                                                            : (tipo == "Perfil") && filtrado ?
                                                                usuariosPerfil.map(aluno => (
                                                                    <tr key={aluno.id}>

                                                                        <td>{aluno.nome}</td>
                                                                        <td>{aluno.incubadoras.length > 0 ?
                                                                            aluno.incubadoras.length == 1 ?
                                                                                aluno.incubadoras.map(incubadora => (
                                                                                    incubadora
                                                                                ))
                                                                                :
                                                                                aluno.incubadoras.map(incubadora => (
                                                                                    incubadora + ', '
                                                                                ))
                                                                            :
                                                                            <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                        </td>
                                                                        <td>{aluno.startups.length > 0 ?
                                                                            aluno.startups.length == 1 ?
                                                                                aluno.startups.map(startup => (
                                                                                    startup
                                                                                ))
                                                                                :
                                                                                aluno.startups.map(startup => (
                                                                                    startup + ', '
                                                                                ))
                                                                            :
                                                                            <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                        </td>
                                                                        <td>
                                                                            <span className="pr-3" onClick={() => this.vincularParticipante(aluno.id)} id="pesquisa" name="pesquisa">
                                                                                <Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.vincularAutor.bt_filtrar} icone="sign-in-alt" posicao="E" />
                                                                            </span>
                                                                        </td>

                                                                    </tr>
                                                                ))
                                                                :
                                                                alunos.map(aluno => (
                                                                    <tr key={aluno.id}>

                                                                        <td>{aluno.nome}</td>
                                                                        <td>{aluno.incubadoras.length > 0 ?
                                                                            aluno.incubadoras.length == 1 ?
                                                                                aluno.incubadoras.map(incubadora => (
                                                                                    incubadora
                                                                                ))
                                                                                :
                                                                                aluno.incubadoras.map(incubadora => (
                                                                                    incubadora + ', '
                                                                                ))
                                                                            :
                                                                            <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                        </td>
                                                                        <td>{aluno.startups.length > 0 ?
                                                                            aluno.startups.length == 1 ?
                                                                                aluno.startups.map(startup => (
                                                                                    startup
                                                                                ))
                                                                                :
                                                                                aluno.startups.map(startup => (
                                                                                    startup + ', '
                                                                                ))
                                                                            :
                                                                            <span className="badge badge-pill badge-danger"> <FontAwesomeIcon icon="times" size="lg" /> </span>}
                                                                        </td>
                                                                        <td>
                                                                            <span className="pr-3" onClick={() => this.vincularParticipante(aluno.id)} id="pesquisa" name="pesquisa">
                                                                                <Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.vincularAutor.bt_vincular} icone="sign-in-alt" posicao="E" />
                                                                            </span>
                                                                        </td>

                                                                    </tr>
                                                                ))

                                                    }
                                                </tbody>
                                            </Table>
                                        </div>

                                        :
                                        <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <Skeleton count={1} width={80} />
                                                        </th>
                                                        <th>
                                                            <Skeleton count={1} width={80} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Skeleton count={1} width={80} />
                                                        </td>
                                                        <td>
                                                            <Skeleton count={1} width={60} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>}
                                    {dadosApi && !filtrado ?
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.vincularAutor.page1}</span>
                                                </li>

                                                {temPaginaAnterior ? <li className="page-item "><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.alunos.prevPage ? this.props.alunos.prevPage : 1)}>{this.props.alunos.prevPage ? this.props.alunos.prevPage : ""}</span></li> : ""}

                                                <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.alunos ? this.props.alunos.currentPage : "1"}</span></li>

                                                {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.alunos.nextPage ? this.props.alunos.nextPage : 1)}>{this.props.alunos.nextPage ? this.props.alunos.nextPage : ""}</span></li> : ""}

                                                <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.alunos ? this.props.alunos.totalPages : 0)}>{idioma.vincularAutor.page2}</span>
                                                </li>
                                            </ul>
                                        </nav>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    alunos: state.trilhas.futurosAlunos,
    trilha: state.trilhas.trilha,
    search: state.trilhas.search,
    incubadoras: state.trilhas.incubadorasCompleto,
    startups: state.trilhas.startupsCompleto,
    perfis: state.trilhas.perfis,
    usuariosPerfil: state.trilhas.usuariosPerfil,
    usuariosIncubadora: state.trilhas.usuariosIncubadora,
    participantes: state.trilhas.participantes,
})
export default connect(mapStatetoProps, actions)(VincularParticipantes);