import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';


class Titulo extends Component {

  render() {
    const { usuario, titulo } = this.props;

    return (
      <h2 className="display-4 titulo" style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_titulo }} >{titulo}</h2>

    )
  }
}
const mapStatetoProps = state => ({
  usuario: state.auth.usuario
})
export default connect(mapStatetoProps, actions)(Titulo);