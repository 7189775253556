export const portugues = {
  titulo: 'Notícias, Conteúdos e Insights',
  todos: 'Todos',
  conteudos: 'Conteúdos',
  noticias: 'Notícias',
  insights: 'Insights',
  bt_cadastrar: 'Cadastrar Conteúdo',
  search: 'Pesquisar...',
  bt_search: 'Pesquisar',
  page1: 'Primeira',
  page2: 'Última',
  novo_conteudo: {
    titulo: 'Cadastrar Novo Conteúdo',
    label_titulo: 'Título',
    place_titulo: 'Título do Conteúdo',
    fonte: 'Fonte',
    place_fonte: 'Fonte do Conteúdo',
    tipo: 'Selecione o tipo do conteúdo',
    place_tipo: 'Escolha o tipo',
    imagem: 'Imagem do conteúdo',
    previa: 'Prévia do conteúdo',
    descricao: 'Descrição do Conteúdo',
    bt_cadastar: 'Cadastrar',
    bt_cancelar: 'Cancelar',
    error_nome: 'Preencha o nome',
    error_tipo: 'Preencha o tipo',
    sucesso_msg: 'Conteúdo cadastrado com sucesso!',
    noticias: 'Notícias',
    conteudos: 'Conteúdos',
    insights: 'Insights'
  },
  editarCapa: {
    erro_arq: 'Envie uma imagem',
    msg_sucesso: 'Conteúdo editado com sucesso!',
    titulo: 'Editar conteúdo',
    img: 'Imagem do conteúdo',
    bt_salvar: 'Salvar',
    bt_cancelar: 'Cancelar'
  },
  editarConteudo: {
    erro_titulo: 'Preencha o título',
    erro_desc: 'Preencha uma descrição',
    erro_tipo: 'Preencha um tipo',
    msg_sucesso: 'Conteúdo editado com sucesso!',
    titulo: 'Editar conteúdo',
    input_titulo: 'Título',
    input_titulo2: 'Título do conteúdo',
    tipo: 'Tipo do conteúdo',
    carregando: 'Carregando...',
    fonte: 'Fonte',
    fonte2: 'Fonte do conteúdo',
    previa: 'Prévia',
    previ2: 'Prévia do conteúdo',
    desc: 'Descrição do conteúdo',
    bt_cancelar: 'Cancelar',
    bt_salvar: 'Salvar'
  },
  paginaVer: {
    titulo: 'Conteúdo'
  }
}

export const ingles = {
  titulo: 'News, Contents and Insights',
  todos: 'All',
  conteudos: 'Contents',
  noticias: 'News',
  insights: 'Insights',
  bt_cadastrar: 'Register Content',
  search: 'Search...',
  bt_search: 'Search',
  page1: 'Firt',
  page2: 'Last',
  novo_conteudo: {
    titulo: 'Register New Content',
    label_titulo: 'Title',
    place_titulo: 'Content Title',
    fonte: 'Source',
    place_fonte: 'Content Source',
    tipo: 'Select the content type',
    place_tipo: 'Choose the type',
    imagem: 'Content Image',
    previa: 'Content Preview',
    descricao: 'Content Description',
    bt_cadastar: 'Register',
    bt_cancelar: 'Cancel',
    error_nome: 'Fill the name',
    error_tipo: 'Fill the type',
    sucesso_msg: 'Content successfully registered!',
    noticias: 'News',
    conteudos: 'Contents',
    insights: 'Insights'
  },
  editarCapa: {
    erro_arq: 'Send a image',
    msg_sucesso: 'Content successfull edited!',
    titulo: 'Edit content',
    img: 'Content image',
    bt_salvar: 'Save',
    bt_cancelar: 'Cancel'
  },
  editarConteudo: {
    erro_titulo: 'Title must be filled',
    erro_desc: 'Description must be filled',
    erro_tipo: 'Type ust be filled',
    msg_sucesso: 'Content successfully edited!',
    titulo: 'Edit content',
    input_titulo: 'Title',
    input_titulo2: 'Content title',
    tipo: 'Content type',
    carregando: 'Loading...',
    fonte: 'Source',
    fonte2: 'Content source',
    previa: 'Preview',
    previ2: 'Content preview',
    desc: 'Content description',
    bt_cancelar: 'Cancel',
    bt_salvar: 'Save'
  },
  paginaVer: {
    titulo: 'Content'
  }
}