import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfilIncubadora';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import SemDados from '../../componentes/Mensagens/semDados';

// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconeUsuario from '../../arquivos/imagens/astronaut.png'
import Skeleton from "react-loading-skeleton";

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';


import Titulo from '../../componentes/TituloPaginas';


class Metodologia extends Component {
    state = {
        id_startup: "",
        id_incubadora: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        currentPage: 1,
        size: 3,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_incubadora } = this.props.match.params;
        this.setState({ id_incubadora })
        this.listarIncubadora(id_incubadora)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentWillUnmount() {
        this.props.limparUsuarios();
    }

    componentDidUpdate(nextProps) {
        const { id_incubadora } = this.props.match.params;
        if (!this.props.incubadora && nextProps.incubadora) {
            this.listarIncubadora(id_incubadora);
        }
        this.receberDadosAPI();
    }
    receberDadosAPI() {
        if (typeof this.props.incubadora != "undefined" && this.props.incubadora != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    listarIncubadora(id_incubadora) {
        this.props.getVerIncubadora(id_incubadora);
        const { incubadora } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadora === "undefined") return null
    }

    render() {
        const { msg, carregando, erro, success, dadosApi, id_incubadora, idioma } = this.state;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var incubadora = null;
        if (this.props.incubadora) incubadora = this.props.incubadora;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path

        ////////console.log(incubadora)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.menu1} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {incubadora != null && usuario != null ?
                        <BlocoPerfil dadosApi={dadosApi} incubadora={incubadora} credenciais={credenciais} usuario={usuario} path={path} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="d-flex row">
                            {dadosApi ?
                                <div className="col-lg-6 mb-3">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.acompanhamento.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                                        <div className="row pt-2 mt-3 mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.nome}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{incubadora ? incubadora.nome : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.segmento}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{incubadora ? incubadora.segmento : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.cidade}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{incubadora ? incubadora.cidade : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.cnpj}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{incubadora ? incubadora.cnpj : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.cep}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{incubadora ? incubadora.cep : ""}</p>
                                        </div>
                                        {incubadora &&
                                            incubadora.link ?
                                            <div className="row mb-3">
                                                <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.site}</strong></p>
                                                <p className="col-md-8 texto-centralizado"><a href={incubadora.link} className="ml-1">{idioma.acompanhamento.site_acesso}</a></p>

                                            </div>
                                            : ""}
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.endereco}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{incubadora ? incubadora.endereco : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.redes}</strong></p>
                                            {incubadora ?
                                                <div className="col-sm-8 texto-centralizado">
                                                    {(incubadora.facebook) ?
                                                        <a href={incubadora ? incubadora.facebook : ""} ><BotaoIcon loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'facebook-square']} /></a> : ""}
                                                    {(incubadora.instagram) ?
                                                        <a href={incubadora ? incubadora.instagram : ""} ><BotaoIcon loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'instagram']} /></a> : ""}
                                                    {(incubadora.linkedin) ?
                                                        <a href={incubadora ? incubadora.linkedin : ""} ><BotaoIcon loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'linkedin']} /></a> : ""}
                                                    {(incubadora.twitter) ?
                                                        <a href={incubadora ? incubadora.twitter : ""} ><BotaoIcon loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'twitter-square']} /></a> : ""}
                                                </div> : ""}
                                        </div>
                                    </div>
                                </div>
                                : <div className="col-lg-6 mb-3">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.acompanhamento.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><Skeleton count={1} width={80} /></p>
                                            <p className="col-md-8 texto-centralizado"><Skeleton count={1} width={80} /></p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><Skeleton count={1} width={80} /></p>
                                            <p className="col-md-8 texto-centralizado"><Skeleton count={1} width={80} /></p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><Skeleton count={1} width={80} /></p>
                                            <p className="col-md-8 texto-centralizado"><Skeleton count={1} width={80} /></p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><Skeleton count={1} width={80} /></p>
                                            <p className="col-md-8 texto-centralizado"><Skeleton count={1} width={80} /></p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><Skeleton count={1} width={80} /></p>
                                            <p className="col-md-8 texto-centralizado"><Skeleton count={1} width={80} /></p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><Skeleton count={1} width={80} /></p>
                                            <p className="col-md-8 texto-centralizado"><Skeleton count={1} width={80} /></p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><Skeleton count={1} width={80} /></p>
                                            <p className="col-md-8 texto-centralizado"><Skeleton count={1} width={80} /></p>
                                        </div>
                                    </div>
                                </div>}
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            {dadosApi && incubadora ?
                                <div className="col">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        {incubadora.pitch ?
                                            <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item" src={incubadora.pitch} allowfullscreen></iframe>
                                            </div>
                                            : <SemDados subtitulo={idioma.sem_dados_sub} titulo="Pitch" />}
                                    </div>
                                </div>
                                :
                                <div className="col">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <Skeleton rectangle={true} height={336} width={600} />
                                    </div>
                                </div>}

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    incubadora: state.incubadoras.incubadora,
})
export default connect(mapStatetoProps, actions)(Metodologia);