import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner, UncontrolledCollapse, Card, CardBody, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import entrega from './entrega';
import metodologia from './metodologia';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class Etapa extends Component {
    state = {
        msg: "",
        erro: "",
        success: "",
        id_etapa: "",
        id_startup: "",
        id_incubadora: "",
        id_processo: "",
        id_metodologia: "",
        pertence: false,
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup, id_incubadora, id_etapa, id_metodologia, id_processo } = this.props.match.params;
        this.setState({ id_etapa })
        this.setState({ id_startup })
        this.setState({ id_processo })
        this.setState({ id_incubadora })
        this.setState({ id_metodologia })
        this.listarStartup(id_startup)
        this.listarEtapa(id_etapa);

        this.listar(id_startup, id_metodologia, id_processo, id_etapa);
        this.verificaPertence(this.props.usuario.id, id_startup)

        if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }


    }
    componentDidUpdate(nextProps) {
        const { id_startup, id_metodologia, id_processo, id_etapa } = this.props.match.params;
        if (!this.props.estagios && nextProps.estagios) this.listar(id_startup, id_metodologia, id_processo, id_etapa);
        this.receberDadosAPI();
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.etapa && nextProps.etapa) {
            this.listarEtapa(id_etapa);
        }
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_startup);
        }
    }

    componentWillUnmount() {
        this.props.limparEstagiosStartups();
        this.props.limparStartup()
    }

    receberDadosAPI() {
        if (typeof this.props.estagios != "undefined" && this.props.estagios != null && !this.state.dadosApi && typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }
    onToggle = (field, ev) => {
        this.setState({ [field]: ev.target.files[0] });
    }
    listar(id, id_metodologia, id_processo, id_etapa) {
        this.props.getEstagiosStartup(id, id_metodologia, id_processo, id_etapa);
        const { estagios } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (estagios === "undefined") return null
    }
    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }

    listarEtapa(id_etapa) {
        this.props.getVerEtapa(id_etapa);
        const { etapa } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (etapa === "undefined") return null
    }

    handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
        if (usuarios.startups.length > 0) {
            for (let u in usuarios.startups) {
                if (usuarios.startups[u].id_startup === id_startup) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (usuarios.incubadoras.length > 0) {
            for (let u in usuarios.incubadoras) {
                if (usuarios.incubadoras[u].id_incubadora === id_incubadora) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (credenciais[0].id_perfil === 1 || credenciais[0].id_perfil === 9) {
            this.setState({ pertence: true })
        }

    }
    verificaPertence(id_usuario, id_startup) {
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao?.cor_secundaria
    }
    render() {
        const { msg, erro, success, pertence, id_incubadora, dadosApi, id_etapa, id_startup, id_processo, id_metodologia, idioma, msg_backend } = this.state;
        var estagios = [];
        var credenciais = [];
        var evidencias = [];
        var startup = null;
        var usuario = [];
        var etapa = [];
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (this.props.startup) startup = this.props.startup;
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.estagios) estagios = this.props.estagios.dados;
        if (this.props.evidencias) evidencias = this.props.evidencias;
        if (this.props.usuario) usuario = this.props.usuario;
        if (this.props.etapa) etapa = this.props.etapa;

        var path = this.props.match.path

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.etapa.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        usuarioPertence.pertence &&
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <div className="d-flex">
                                <div className="col-md-12 p-2">

                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    {dadosApi ?
                                        usuarioPertence.pertence ?
                                            <div className="row ">
                                                {estagios.map(estagio => (
                                                    <div className={etapa.colunas === 1 ? "col-md-12" : etapa.colunas === 4 ? "col-md-3" : etapa.colunas === 3 ? "col-md-4" : etapa.colunas === 2 ? "col-md-6" : "col-md-3"} key={etapa.id}>

                                                        <div className="etapa-card card">
                                                            <div>
                                                                <div className="directory-content text-center p-4">
                                                                    <div className="subtitle">{estagio.estagio.nome}</div>
                                                                    <hr />
                                                                    <div>
                                                                        {estagio.entregas.map(entrega => (
                                                                            <div className="entrega-card" key={entrega.id}>
                                                                                {(!entrega.temEvidencia) ?
                                                                                    <div className="col-md-12">
                                                                                        <div>
                                                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + id_metodologia + "/processos/" + id_processo + "/etapa/" + id_etapa + "/entrega/" + entrega.entrega.id + "/evidencias"}>
                                                                                                <div className="titulo-generico mb-1" >{entrega.entrega.nome}</div>
                                                                                                <span className="badge badge-pill badge-primary mb-1">{msg_backend[entrega.status]}</span>
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="col-md-12">
                                                                                        <div>
                                                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + id_metodologia + "/processos/" + id_processo + "/etapa/" + id_etapa + "/entrega/" + entrega.entrega.id + "/evidencias"}>
                                                                                                <div className="titulo-generico mb-1" >{entrega.entrega.nome}</div>
                                                                                                {(entrega.status === "ST4") ?
                                                                                                    <span className="badge badge-pill badge-success mb-1">{msg_backend[entrega.status]}</span>
                                                                                                    :
                                                                                                    (entrega.status === "ST3") ?
                                                                                                        <span className="badge badge-pill badge-danger mb-1">{msg_backend[entrega.status]}</span>
                                                                                                        :
                                                                                                        <span className="badge badge-pill badge-warning mb-1">{msg_backend[entrega.status]}</span>}
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.evidencias.sem_dados} />
                                        : <div className="row">
                                            <div className="col-md-3">
                                                <div className="etapa-card card">
                                                    <div>
                                                        <div className="directory-content text-center p-4">
                                                            <div className="subtitle">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <hr />
                                                            <div>
                                                                <div className="entrega-card">
                                                                    <div className="col-md-12">
                                                                        <div>
                                                                            <div className="titulo-generico mb-1" >
                                                                                <Skeleton count={1} width={80} />
                                                                            </div>
                                                                            <Skeleton count={1} width={80} />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="etapa-card card">
                                                    <div>
                                                        <div className="directory-content text-center p-4">
                                                            <div className="subtitle">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <hr />
                                                            <div>
                                                                <div className="entrega-card">
                                                                    <div className="col-md-12">
                                                                        <div>
                                                                            <div className="titulo-generico mb-1" >
                                                                                <Skeleton count={1} width={80} />
                                                                            </div>
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="etapa-card card">
                                                    <div>
                                                        <div className="directory-content text-center p-4">
                                                            <div className="subtitle">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <hr />
                                                            <div>
                                                                <div className="entrega-card">
                                                                    <div className="col-md-12">
                                                                        <div>
                                                                            <div className="titulo-generico mb-1" >
                                                                                <Skeleton count={1} width={80} />
                                                                            </div>
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="etapa-card card">
                                                    <div>
                                                        <div className="directory-content text-center p-4">
                                                            <div className="subtitle">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <hr />
                                                            <div>
                                                                <div className="entrega-card">
                                                                    <div className="col-md-12">
                                                                        <div>
                                                                            <div className="titulo-generico mb-1" >
                                                                                <Skeleton count={1} width={80} />
                                                                            </div>
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    estagios: state.startups.estagios,
    startup: state.startups.startup,
    etapa: state.startups.etapa,
    pertence: state.startups.pertence

})
export default connect(mapStatetoProps, actions)(Etapa);