import {
    GET_DESAFIOS, GET_DESAFIO, GET_MINHAS_STARTUPS, GET_MINHAS_INCUBADORAS, GET_SOLUCOES, GET_SOLUCAO, GET_USERS,
    LIMPAR_DESAFIOS, LIMPAR_DESAFIO, LIMPAR_SOLUCOES, GET_ARQUIVOS_DESAFIO, GET_ARQUIVOS_SOLUCAO, GET_AVALIADORES,
    LIMPAR_AVALIADORES, GET_AVALIACOES, GET_AVALIACOES_GESTOR, LIMPAR_USERS, GET_SEARCH, GET_AVALIADOR, GET_PILARES,
    GET_AVALIACAO
} from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_DESAFIOS:
            return {
                ...state,
                desafios: actions.payload.data
            }
        case GET_ARQUIVOS_DESAFIO:
            return {
                ...state,
                arquivosDesafio: actions.payload.data
            }
        case GET_AVALIACOES:
            return {
                ...state,
                avaliacoes: actions.payload.data
            }
        case GET_AVALIACOES_GESTOR:
            return {
                ...state,
                avaliacoesGestor: actions.payload.data
            }
        case GET_AVALIADORES:
            return {
                ...state,
                avaliadores: actions.payload.data
            }
        case GET_AVALIACAO:
            return {
                ...state,
                avaliacao: actions.payload.dados
            }
        case GET_AVALIADOR:
            return {
                ...state,
                avaliador: actions.payload.data
            }
        case GET_PILARES:
            return {
                ...state,
                pilares: actions.payload.data
            }
        case GET_SEARCH:
            return {
                ...state,
                search: actions.payload.data
            }
        case GET_ARQUIVOS_SOLUCAO:
            return {
                ...state,
                arquivosSolucao: actions.payload.data
            }
        case GET_DESAFIO:
            return {
                ...state,
                desafio: actions.payload.dados
            }
        case GET_SOLUCOES:
            return {
                ...state,
                solucoes: actions.payload.data
            }
        case GET_SOLUCAO:
            return {
                solucao: actions.payload.dados
            }
        case GET_MINHAS_STARTUPS:
            return {
                ...state,
                minhasStartups: actions.payload.data
            }
        case GET_MINHAS_INCUBADORAS:
            return {
                ...state,
                minhasIncubadoras: actions.payload.data
            }
        case GET_USERS:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case LIMPAR_DESAFIOS:
            return {
                desafios: null
            }
        case LIMPAR_DESAFIO:
            return {
                desafio: null
            }
        case LIMPAR_SOLUCOES:
            return {
                solucoes: null
            }
        case LIMPAR_AVALIADORES:
            return {
                avaliadores: null
            }
        case LIMPAR_USERS:
            return {
                usuarios: null
            }
        default:
            return state;
    }
}