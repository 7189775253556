import React from 'react';
import Header from '../../componentes/Header';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../styles/dashboard/video-react.css";
import '../../styles/dashboard/scrollbar.css';
import '../../styles/dashboard/index.css';

class PermissaoDashboard extends React.Component {
    state = {
        sitMenu: true,
        subMenu: "",
    }
    altSitMenu() {
        this.setState({ sitMenu: !this.state.sitMenu })
    }
    altSubMenu(qualSubmenu) {
        //console.log(this.props)
        this.setState({ subMenu: qualSubmenu })
    }
    altSitMenu = this.altSitMenu.bind(this);
    altSubMenu = this.altSubMenu.bind(this);
    render() {
        const { sitMenu, subMenu } = this.state;
        return (
            <>
                <Header handleLogout={this.props.handleLogout} dadosUsuario={this.props} altSitMenu={this.altSitMenu} />
                <div className="d-flex">
                    <div className="content-sidebar-open" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_secundaria }}>
                        {this.props.children}
                    </div>
                </div>
            </>
        )
    }
}
export default connect(null, actions)(PermissaoDashboard);