import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/usuarios';
import { Link } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import minhasStartups from '../../paginas/Startup/minhasStartups';
import { min } from 'date-fns';
import Toast from '../../componentes/Alertas/Toast.js';

import { apiImagem } from '../../config/';
import { portugues, ingles } from '../../utils/paginausuario_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class PerfilUsuario extends Component {
    state = {
        dadosAPI: false,
        path: "",
        currentPage: 1,
        size: 20,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    // componentDidMount() {

    //     if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }
    //     this.listarTrilha();

    // }
    // componentDidUpdate(nextProps) {
    //     if (!this.props.trilha && nextProps.trilha) this.listarTrilha();
    //     this.receberDadosAPI();
    // }

    // receberDadosAPI() {
    //     if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi && this.props.trilha.currentPage === this.state.currentPage) {
    //         this.setState({ dadosApi: true });
    //     }
    // }

    // listarTrilha(id_trilha) {
    //     this.props.getVerTrilha(id_trilha);
    //     const { trilha } = this.props;
    //     if (this.props.location.state) {

    //         this.setState({ msg: this.props.location.state.msg })
    //         this.props.location.state.msg = "";
    //     }
    //     if (trilha === "undefined") return null
    // }



    deletandoUsuario(id_usuario) {
        this.setState({ carregando: true })
        ////////////console.log(id_startup)
        this.props.deletarUsuario(id_usuario, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta("");
            }
        })
    }

    chamarAlertaDeletar(id_usuario) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_excluir.titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.modal_excluir.bt_cancelar,
            confirmButtonText: idioma.modal_excluir.bt_sucesso
        }).then((result) => {
            if (result.value) {
                this.deletandoUsuario(id_usuario);
            }
        })
    }
    sucessoAlerta(code) {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_excluir.info_sucesso
        })
    }

    chamarModalIdioma() {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_password.titulo,
            input: 'select',
            inputOptions: {
                portugues: idioma.modal_password.portugues,
                ingles: idioma.modal_password.ingles
            },
            inputPlaceholder: idioma.modal_password.idioma,
            showCancelButton: true,
            confirmButtonText: idioma.modal_password.bt_salvar,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#3085d6',
        }).then(value => {
            this.handleAlterarIdioma(value)
        })
    }

    async handleAlterarIdioma(idioma) {
        if (idioma.isDismissed) return;
        const { usuarioDetalhes } = this.props;
        const id_idioma = idioma.value === 'portugues' ? 1 : 3;

        const newData = {
            id: usuarioDetalhes.id,
            id_idioma: id_idioma
        }

        await this.props.putUserIdioma(newData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.sucessoAlerta(msg.erro.code);
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.sucessoAlerta(msg.erro.code);
            }
        });



    }

    render() {
        let { trilha, dadosApi, path, idioma, formSuccess } = this.state;
        var credenciais = [];
        var usuarioDetalhes = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = []
        if (this.props.usuario) usuario = this.props.usuario;
        if (this.props.usuarioDetalhes) usuarioDetalhes = this.props.usuarioDetalhes;
        if (this.props.dadosApi) dadosApi = this.props.dadosApi;
        if (this.props.path) path = this.props.path;
        if (formSuccess) return <Redirect to={{
            pathname: `/${usuario?.personalizacao?.titulo}/usuarios`,
        }}></Redirect>
        return (
            <>
                <div className="list-group-item bloco-card" >
                    {
                        dadosApi ?
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-auto" >
                                        <div className="img-perfil">
                                            <img className="rounded-circle mt-2" src={usuarioDetalhes.fileName ? apiImagem + usuarioDetalhes.fileName : "/imagens/astronaut.png"} width="120" height="120" alt='Avatar' ></img>
                                        </div>
                                    </div>
                                    <div className="col-md-9 ">
                                        <h2 className="nomeperfil">{usuarioDetalhes ? usuarioDetalhes.nome : ""}</h2>
                                    </div>
                                </div>
                            </div>
                            : <div className="row">
                                <div className="col-md-4" >
                                    <div className="img-perfil">
                                        <Skeleton circle={true} height={120} width={120} />
                                    </div>
                                </div>
                                <div className="col-md-4 ">
                                    <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                </div>
                            </div>}
                </div>
                <div className="list-group-item bloco-card" >
                    {
                        dadosApi ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <Link onClick={() => this.props.history.goBack()}>
                                                    <span className="text-perfil">
                                                        <FontAwesomeIcon icon="arrow-left" size="1x" color="black" className="mr-3" />
                                                        {idioma.bt_voltar}
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={path == "/usuarios/ver/:id" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/ver/" + usuarioDetalhes.id}>
                                                    <span className="text-perfil">
                                                        <FontAwesomeIcon icon="user-astronaut" size="1x" color="black" className="mr-3" />
                                                        {idioma.infos}
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className={path == "/usuarios/ver/:id" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/chat/" + usuarioDetalhes.id} className="mr-2" >
                                                    <span className="text-perfil">
                                                        <FontAwesomeIcon icon="comment" size="1x" color="black" className="mr-3" />
                                                        {idioma.bt_conversar}
                                                    </span>
                                                </Link>
                                            </div>
                                            {credenciais.map(credencial => ((credencial.id_componente == 17) ?
                                                < div className={path == "/usuarios/editar/:id" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/editar/" + usuarioDetalhes.id}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                            {idioma.editar}
                                                        </span>
                                                    </Link>
                                                </div>
                                                : ""))}
                                            {credenciais.map(credencial => ((credencial.id_componente == 18) ?
                                                <div className={path == "/usuarios/editar-senha/:id" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/editar-senha/" + usuarioDetalhes.id}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="key" size="1x" color="black" className="mr-3" />
                                                            {idioma.editar_senha}
                                                        </span>
                                                    </Link>
                                                </div>
                                                : ""))}

                                            {credenciais.map(credencial => ((credencial.id_componente == 99) ?
                                                <div className={path == "/usuarios/editar-idioma/:id" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to="#" onClick={() => this.chamarModalIdioma()}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                            {idioma.editar_idioma}
                                                        </span>
                                                    </Link>
                                                </div>
                                                : ""))}
                                            {credenciais.map(credencial => ((credencial.id_componente == 19) ?
                                                <div className="col-auto menu-cabecalho" >
                                                    <Link to="#" onClick={() => this.chamarAlertaDeletar(usuarioDetalhes.id)}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="trash-alt" size="1x" color="red" className="mr-3" />
                                                            {idioma.excluir}
                                                        </span>
                                                    </Link>
                                                </div>
                                                : ""))}
                                        </div >
                                    </div >
                                </div >

                            </div >
                            : <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            < div className="col-auto menu-cabecalho">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho active">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho active">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho" >
                                                <Skeleton count={1} width={80} />
                                            </div>
                                        </div >
                                    </div >
                                </div >

                            </div >

                    }
                </div >
            </>
        )
    }
}
const mapStatetoProps = state => ({
    // usuario: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario


})
export default connect(mapStatetoProps, actions)(PerfilUsuario);