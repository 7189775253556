import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ReactLoadingSkeleton = () => (
  <div className="col-md-12 mb-3">
    <div className="list-group-item">
      <div className="row">
        <div className="col-md-12">
          <div className="container-fluid">
            <div className="row">
              < div >
                <Skeleton count={1} width={80} />
              </div>
              < div >
                <Skeleton count={1} width={80} />
              </div>
              <div >
                <Skeleton count={1} width={80} />
              </div>
              <div >
                <Skeleton count={1} width={80} />
              </div>
              <div  >
                <Skeleton count={1} width={80} />
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >

  </div >
);

export default ReactLoadingSkeleton;