import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/radares';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import BotaoIcon from '../../../componentes/BotaoIcon';
import AlertaSuccess from '../../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input, Button, Table } from 'reactstrap';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Toast from '../../../componentes/Alertas/Toast';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaradar_bilingue';

class ListarTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 20,
        trilha: "",
        id_radarmetodologia: "",
        id_pilar: "",
        trilha: "",
        msg: "",
        id_radar: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        const { id_radar, id_pilar } = this.props.match.params
        this.setState({ id_radarmetodologia: id_radar })
        this.setState({ id_pilar })
        this.listarPerguntas(id_radar, id_pilar)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id_radar, id_pilar } = this.props.match.params
        if (!this.props.pilares && nextProps.pilares) this.listarPerguntas(this.state.id_radarmetodologia, id_pilar);
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparPerguntas()

    }
    receberDadosAPI() {
        if (typeof this.props.perguntas != "undefined" && this.props.perguntas != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }

    }

    listarPerguntas(id_radarmetodologia, id_pilar) {
        this.props.getPerguntas(id_radarmetodologia, id_pilar)
        const { perguntas } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (perguntas === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    //deletar 
    deletar(id_pergunta) {
        const { msg_backend, idioma } = this.state;
        const { id_radar, id_pilar } = this.props.match.params
        this.setState({ carregando: true })
        this.props.deletarPergunta(id_pergunta, (msg) => {
            if (msg.erro.error) {
                Toast({ icon: 'error', title: idioma.erro, text: msg_backend[msg.erro.code] })
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                Toast({ icon: 'success', title: idioma.sucesso, text: msg_backend[msg.erro.code] })
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.props.limparPerguntas()
                this.listarPerguntas(id_radar, id_pilar)


            }
        })
    }

    chamarDeletar(id_pergunta) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.perguntas.modal_titulo,
            html: idioma.perguntas.modal_info,
            input: 'text',
            inputPlaceholder: idioma.perguntas.modal_info,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_del,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value == idioma.confirm) {
                this.deletar(id_pergunta);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.msg_erro
                })
            }
        })
    }

    render() {
        const { id_radarmetodologia, id_pilar, msg, carregando, erro, success, dadosApi, formSuccess, idioma } = this.state;
        const { credenciais, usuario } = this.props

        var perguntas = [];
        if (this.props.perguntas) perguntas = this.props.perguntas.dados;

        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.perguntas.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + id_radarmetodologia + "/pilares"} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + id_radarmetodologia + "/pilar/" + id_pilar + "/pergunta/cadastrar"} className="pr-2"> <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" cor="success" /> </Link>
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.perguntas.assunto}</th>
                                                            <th className="coluna-tabela">{idioma.perguntas.pergunta}</th>
                                                            <th className="coluna-tabela">{idioma.perguntas.tipo}</th>
                                                            <th className="coluna-tabela">{idioma.perguntas.acoes}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {perguntas.map(pergunta => (
                                                            <tr key={pergunta.id}>

                                                                <td>{pergunta.nome}</td>
                                                                <td>{pergunta.pergunta}</td>
                                                                <td>{pergunta.tipo}</td>
                                                                <td>
                                                                    <a data-tip data-for='opcoes'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + pergunta.id_radarmetodologia + "/pilar/" + pergunta.id_pilar + "/pergunta/" + pergunta.id + "/opcoes"} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes={pergunta.tipo == 'multipla' ? "btn-primary btn-sm ml-1" : "btn-primary btn-sm ml-1 disabled"} icone="list-ul" />
                                                                        </Link>
                                                                    </a>

                                                                    <a data-tip data-for='editar'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + pergunta.id_radarmetodologia + "/pilar/" + pergunta.id_pilar + "/pergunta/" + pergunta.id + "/editar"} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-primary btn-sm ml-1" icone="edit" />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='deletar'>
                                                                        <Link to="#" onClick={() => this.chamarDeletar(pergunta.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    <ReactTooltip id='opcoes' type='dark'>
                                                                        <span>{idioma.cadastrarPerguntas.opcao}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='deletar' type='error'>
                                                                        <span>{idioma.bt_del}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' type='dark'>
                                                                        <span>{idioma.editar}</span>
                                                                    </ReactTooltip>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    perguntas: state.radares.perguntas,

})
export default connect(mapStatetoProps, actions)(ListarTrilhas);