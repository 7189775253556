import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfilSolucao';
import SemDados from '../../componentes/Mensagens/semDados';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { Button, Input } from 'reactstrap';
import { apiImagem } from '../../config/';
import { text } from '@fortawesome/fontawesome-svg-core';
import Skeleton from 'react-loading-skeleton';
import parse from 'html-react-parser';
import BootstrapTable from 'react-bootstrap-table-next';
import TextTruncate from 'react-text-truncate';
import BotaoIcon from '../../componentes/BotaoIcon';
import ReactTooltip from 'react-tooltip';
import Toast from '../../componentes/Alertas/Toast';

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';
import avaliadores from './avaliadores';

class VerDesafio extends Component {
  state = {
    currentPage: 1,
    size: 3,
    id_desafio: '',
    id_solucao: '',
    id_usuario: '',
    msg: '',
    erro: '',
    success: '',
    admin: false,
    botaoDeletar: false,
    botaoAlterar: false,
    botaoHTML: false,
    carregando: false,
    detalhesAvaliador: false,
    detalhesGestor: false,
    id_detalhe: '',
    modal: false,
    dadosApi: false,
    idioma: portugues,
    msg_backend: portuguese,
  };
  componentDidMount() {
    const { id_desafio, id_solucao } = this.props.match.params;
    if (this.props.usuario) {
      this.setState({ id_usuario: this.props.usuario.id });
    }
    this.setState({ id_desafio });
    this.setState({ id_solucao });
    this.listarSolucao(id_desafio, id_solucao);
    this.listarUsuarios();
    this.listarArquivos(id_solucao);
    this.listarAvaliacoes(id_solucao);
    this.listarAvaliacoesGestor(id_solucao);
    this.listarAvaliadores(id_desafio);

    // this.listarPilares(id_desafio)
    if (this.props.usuario) this.listarStartups(this.props.usuario.id);
    if (this.props.credenciais) this.checkAdmin(this.props.credenciais);

    const { usuario } = this.props;
    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }

  componentDidUpdate(nextProps) {
    const { id_desafio, id_solucao } = this.props.match.params;
    if (!this.props.solucao && nextProps.solucao) {
      this.listarSolucao(id_desafio, id_solucao);
    }
    if (!this.props.usuarios && nextProps.usuarios) {
      this.listarUsuarios();
    }
    if (!this.props.arquivos && nextProps.arquivos) {
      this.listarArquivos(id_solucao);
    }
    if (!this.props.avaliacoes && nextProps.avaliacoes) {
      this.listarAvaliacoes(id_solucao);
    }
    if (!this.props.avaliacoesGestor && nextProps.avaliacoesGestor) {
      this.listarAvaliacoesGestor(id_solucao);
    }
    if (!this.props.avaliadores && nextProps.avaliadores)
      this.listarAvaliadores(id_desafio);

    this.receberDadosAPI();
  }

  componentWillUnmount() {
    this.props.limparDesafio();
    this.props.limparSolucoes();
  }

  changePageAtual(currentPage) {
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.listarSolucoes(this.state.id_desafio);
    });
  }

  receberDadosAPI() {
    if (
      typeof this.props.solucao != 'undefined' &&
      this.props.solucao != null &&
      !this.state.dadosApi
    ) {
      if (
        typeof this.props.avaliacoes != 'undefined' &&
        this.props.avaliacoes != null &&
        !this.state.dadosApi
      ) {
        if (
          typeof this.props.avaliacoesGestor != 'undefined' &&
          this.props.avaliacoesGestor != null &&
          !this.state.dadosApi
        ) {
          if (
            typeof this.props.avaliadores != 'undefined' &&
            this.props.avaliadores != null &&
            !this.state.dadosApi &&
            this.props.avaliadores.currentPage === this.state.currentPage
          ) {
            this.chamarEnvioEmailEtapa2(this.props.solucao, this.props.avaliadores.dados);
            this.setState({ dadosApi: true });
          }
        }
      }
    }
  }

  chamarEnvioEmailEtapa2(solucao, avaliadores) {
    if (solucao.avaliacoes.length >= avaliadores.length && solucao.datahora_avaliacao_final === null) {
      if (solucao.media >= solucao.nota_corte) {
        this.props.postDesafioEmailAceite({ id_solucao: solucao.id, etapa: 2 }, (msg) => {
          if (msg.erro.error) {
            this.setState({
              erro: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ success: '' });
            this.setState({ carregando: false });
          } else {
            this.setState({
              success: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ erro: '' });
            this.setState({ carregando: false });
          }
        })
      } else {
        this.props.postDesafioEmailRejeitar({ id_solucao: solucao.id, etapa: 2 }, (msg) => {
          if (msg.erro.error) {
            this.setState({
              erro: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ success: '' });
            this.setState({ carregando: false });
          } else {
            this.setState({
              success: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ erro: '' });
            this.setState({ carregando: false });
          }
        })
      }
    }
  }

  listarArquivos(id_solucao) {
    this.props.getArquivosSolucao(id_solucao);
    const { arquivos } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (arquivos === 'undefined') return null;
  }

  listarAvaliadores(id_desafio) {
    const { currentPage, size } = this.state;
    this.props.getAvaliadores(id_desafio, currentPage, size);
    const { avaliadores } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (avaliadores === 'undefined') return null;
  }

  listarPilares(id_desafio) {
    this.props.getPilares(id_desafio);
    const { pilares } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (pilares === 'undefined') return null;
  }

  listarAvaliacoes(id_solucao) {
    this.props.getAvaliacoes(id_solucao);
    const { avaliacoes } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (avaliacoes === 'undefined') return null;
  }

  listarAvaliacoesGestor(id_solucao) {
    this.props.getAvaliacoesGestor(id_solucao);
    const { avaliacoesGestor } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (avaliacoesGestor === 'undefined') return null;
  }

  listarUsuarios() {
    const { currentPage } = this.state;
    var size = 1000;
    this.props.getUsuarios(currentPage, size);
    const { usuarios } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (usuarios === 'undefined') return null;
  }

  listarDesafio(id_desafio) {
    this.props.getVerDesafio(id_desafio);
    const { desafio } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (desafio === 'undefined') return null;
  }

  listarStartups(id_usuario) {
    this.props.getMinhasStartups(id_usuario);
    const { startups } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (startups === 'undefined') return null;
  }

  listarSolucao(id_desafio, id_solucao) {
    this.props.getVerSolucao(id_desafio, id_solucao);
    const { solucao } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (solucao === 'undefined') return null;
  }

  baixarEntrega(fileName) {
    window.open(apiImagem + fileName, '_blank');
  }

  checkAdmin(credenciais) {
    if (credenciais.length > 0) {
      if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
        this.setState({ admin: true });
      }
    }
  }

  deleteSolution(id_desafio, id_solucao) {
    this.setState({ carregando: true });
    this.props.deletarSolucao(id_solucao, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        this.props.limparSolucoes();
      }
    });
  }

  mostrarDetalhes(id_avaliacao) {
    const { detalhesAvaliador } = this.state;
    this.setState({ detalhesAvaliador: !detalhesAvaliador });
    this.setState({ id_detalhe: id_avaliacao });
  }
  mostrarDetalhesGestor(id_avaliacao) {
    const { detalhesGestor } = this.state;
    this.setState({ detalhesGestor: !detalhesGestor });
    this.setState({ id_detalhe: id_avaliacao });
  }

  editarAvalGestor(avalGestor, media) {
    const { id_desafio, id_solucao, id_usuario, idioma } = this.state;
    Swal.mixin({
      confirmButtonText: idioma.verSolucao.modal_prox + ' &rarr;',
      showCancelButton: true,
      progressSteps: ['1', '2', '3'],
    })
      .queue([
        {
          title: idioma.verSolucao.modal_titulo,
          // text: 'Nota média dos avaliadores: ' + media.toFixed(2),
          input: 'radio',
          inputOptions: {
            Reprovado: idioma.verSolucao.reprovado,
            Aprovado: idioma.verSolucao.aprovado,
          },
        },
        {
          title: idioma.verSolucao.obs,
          input: 'textarea',
          inputPlaceholder: avalGestor.observacao,
        },
        {
          title: idioma.verSolucao.doc_info,
          input: 'file',
          inputAttributes: {
            multiple: 'multiple',
          },
        },
      ])
      .then((result) => {
        if (result.value && result.value.length == 3) {
          this.avaliarGestor(
            avalGestor.id,
            id_desafio,
            id_solucao,
            id_usuario,
            result.value[0],
            result.value[1],
            result.value[2],
            media,
          );
        }
      });
  }

  avaliarGestor(
    id_avaliacao,
    id_desafio,
    id_solucao,
    id_usuario,
    resultado,
    observacao,
    files,
    nota_final,
  ) {
    const formData = new FormData();
    formData.append('id_avaliacao', id_avaliacao);
    formData.append('id_desafio', id_desafio);
    formData.append('id_solucao', id_solucao);
    formData.append('id_usuario', id_usuario);
    formData.append('resultado', resultado);
    formData.append('observacao', observacao);
    formData.append('nota_final', nota_final);
    for (let f in files) {
      formData.append('file', files[f]);
    }
    this.setState({ carregando: true });
    this.props.putAvaliacaoGestor(formData, (msg) => {
      if (msg.erro.error) {
        Toast({ icon: 'error', title: 'Erro!', text: msg.erro.message });
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        Toast({ icon: 'success', title: 'Sucesso!', text: msg.erro.message });
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.listarAvaliacoesGestor(id_solucao);
        if (resultado === "Aprovado") {
          this.props.postDesafioEmailAceite({ id_solucao, etapa: 3 }, (msg) => {
            if (msg.erro.error) {
              this.setState({
                erro: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ success: '' });
              this.setState({ carregando: false });
            } else {
              this.setState({
                success: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ erro: '' });
              this.setState({ carregando: false });
            }
          })
        } else {
          this.props.postDesafioEmailRejeitar({ id_solucao, etapa: 3 }, (msg) => {
            if (msg.erro.error) {
              this.setState({
                erro: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ success: '' });
              this.setState({ carregando: false });
            } else {
              this.setState({
                success: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ erro: '' });
              this.setState({ carregando: false });
            }
          })
        }
      }
    });
  }

  render() {
    const {
      id_solucao,
      idioma,
      detalhesAvaliador,
      detalhesGestor,
      id_detalhe,
      admin,
      botaoHTML,
      msg,
      carregando,
      erro,
      success,
      dadosApi,
      id_desafio,
      id_usuario,
    } = this.state;

    var usuario = [];
    if (this.props.usuario) usuario = this.props.usuario;
    var startups = null;
    if (this.props.startups) startups = this.props.startups.startups;
    var credenciais = [];
    if (this.props.credenciais) credenciais = this.props.credenciais;
    var solucao = null;
    if (this.props.solucao) solucao = this.props.solucao;
    var arquivos = [];
    if (this.props.arquivos) arquivos = this.props.arquivos.dados;
    var avaliacoes = [];
    if (this.props.avaliacoes) avaliacoes = this.props.avaliacoes.dados;
    var avaliacoesGestor = [];
    if (this.props.avaliacoesGestor)
      avaliacoesGestor = this.props.avaliacoesGestor.dados;
    var usuarios = [];
    if (this.props.usuarios) usuarios = this.props.usuarios.dados;
    var avaliadores = [];
    if (this.props.avaliadores) avaliadores = this.props.avaliadores.dados;
    var path = this.props.match.path;
    const countAvaliadores = avaliadores.filter((item) => item.avaliado);



    let columnsDetails = [];
    columnsDetails = [
      {
        dataField: 'datahora_aceite',
        text: idioma.pagina_ver.etapa1,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div className="text-muted ml-1 infosecundaria">
            {row.datahora_aceite == null ? (
              <a data-tip data-for="analise">
                <FontAwesomeIcon icon="clock" color="orange" />
              </a>
            ) : (
              <a data-tip data-for="aprovado">
                <FontAwesomeIcon icon="check" color="green" />
              </a>
            )}
            <ReactTooltip id="analise" type="warning">
              <span>{idioma.pagina_ver.info_envios}</span>
            </ReactTooltip>
            <ReactTooltip id="aprovado" type="success">
              <span>{idioma.pagina_ver.sucesso_envios}</span>
            </ReactTooltip>
          </div>
        ),
      },
      {
        dataField: 'nota_final',
        text: idioma.pagina_ver.etapa2,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <>
            <div className="text-muted ml-1 infosecundaria">
              {row.nota_final != null ? (
                row.nota_final
              ) : (
                <a data-tip data-for="analise">
                  <FontAwesomeIcon icon="clock" color="orange" />
                </a>
              )}
            </div>
            <ReactTooltip id="analise" type="warning">
              <span>{idioma.pagina_ver.info_envios}</span>
            </ReactTooltip>
          </>
        ),
      },
      {
        dataField: 'resultado',
        text: idioma.pagina_ver.aprovado,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div className="text-muted ml-1 infosecundaria">
            {row.resultado == 'Reprovado' ? (
              <a data-tip data-for="reprovado">
                <FontAwesomeIcon icon="times" color="red" />
              </a>
            ) : row.resultado == 'Aprovado' ? (
              <a data-tip data-for="aprovado">
                <FontAwesomeIcon icon="check" color="green" />
              </a>
            ) : (
              <a data-tip data-for="analise">
                <FontAwesomeIcon icon="clock" color="orange" />
              </a>
            )}
            <ReactTooltip id="analise" type="warning">
              <span>{idioma.pagina_ver.info_envios}</span>
            </ReactTooltip>
            <ReactTooltip id="aprovado" type="success">
              <span>{idioma.pagina_ver.sucesso_envios}</span>
            </ReactTooltip>
            <ReactTooltip id="reprovado" type="error">
              <span>{idioma.pagina_ver.info_aprovado}</span>
            </ReactTooltip>
          </div>
        ),
      },
    ];

    var rowsDetails = [];
    if (solucao !== null && solucao.id) {
      rowsDetails.push({
        id: solucao.id,
        id_desafio: solucao.id_desafio,
        datahora_aceite: solucao.datahora_aceite,
        resultado: solucao.avaliacoesGestor?.resultado,
        nota_final:
          solucao.media != null
            ? solucao.media.toFixed(2)
            : null,
      })
    }

    const columns = [
      {
        dataField: 'avaliador',
        text: idioma.verSolucao.avaliador,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => <p>{row.avaliador}</p>,
      },
      {
        dataField: 'nota_final',
        text: idioma.verSolucao.nota,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => <p>{row.nota_final}</p>,
      },
      {
        dataField: 'id',
        text: idioma.verSolucao.acoes,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div className="">
            <a data-tip data-for="detalhes">
              <Link
                onClick={() => this.mostrarDetalhes(row.id)}
                className="mr-2"
              >
                <BotaoIcon
                  loading={carregando}
                  classes="btn-primary btn-sm ml-1"
                  icone="eye"
                />
              </Link>
            </a>
            {row.id_usuario === id_usuario ? (
              <a data-tip data-for="editar">
                <Link
                  to={
                    '/' +
                    usuario?.personalizacao?.titulo +
                    '/hub/avaliar/' +
                    solucao.id_desafio +
                    '/solucao/' +
                    solucao.id +
                    '/editar/' +
                    row.id
                  }
                >
                  <BotaoIcon
                    loading={carregando}
                    classes="btn-primary btn-sm ml-1"
                    icone="edit"
                  />
                </Link>
              </a>
            ) : ''}
            <ReactTooltip id="detalhes" type="dark">
              <span>{idioma.verSolucao.detalhes}</span>
            </ReactTooltip>
            <ReactTooltip id="editar" type="dark">
              <span>{idioma.verSolucao.editar}</span>
            </ReactTooltip>
          </div>
        ),
      },
    ];

    const columnsGestor = [
      {
        dataField: 'gestor',
        text: idioma.verSolucao.gestor,
        sort: true,
        formatter: (cell, rowsGestor, rowIndex, extraData) => (
          <p>{rowsGestor.gestor}</p>
        ),
      },
      {
        dataField: 'resultado',
        text: idioma.verSolucao.resultado,
        sort: true,
        formatter: (cell, rowsGestor, rowIndex, extraData) => (
          <p>{rowsGestor.resultado}</p>
        ),
      },
      {
        dataField: 'observacao',
        text: idioma.verSolucao.obs1,
        sort: true,
        formatter: (cell, rowsGestor, rowIndex, extraData) => (
          <div className="">
            <TextTruncate
              line={3}
              element="span"
              truncateText={idioma.verSolucao.ler}
              text={
                rowsGestor.observacao
                  ? rowsGestor.observacao
                  : idioma.verSolucao.acessar_ava
              }
            />
          </div>
        ),
      },
      {
        dataField: 'id',
        text: idioma.verSolucao.acoes,
        sort: true,
        formatter: (cell, rowsGestor, rowIndex, extraData) => (
          <div className="">
            <a data-tip data-for="detalhes">
              <Link
                onClick={() => this.mostrarDetalhesGestor(rowsGestor.id)}
                className="mr-2"
              >
                <BotaoIcon
                  loading={carregando}
                  classes="btn-primary btn-sm ml-1"
                  icone="eye"
                />
              </Link>
            </a>
            <a data-tip data-for="editar">
              <Link
                onClick={() => this.editarAvalGestor(rowsGestor, mediaFinal)}
                className="mr-2"
              >
                <BotaoIcon
                  loading={carregando}
                  classes="btn-primary btn-sm ml-1"
                  icone="edit"
                />
              </Link>
            </a>
            <ReactTooltip id="detalhes" type="dark">
              <span>{idioma.verSolucao.detalhes}</span>
            </ReactTooltip>
            <ReactTooltip id="editar" type="dark">
              <span>{idioma.verSolucao.editar}</span>
            </ReactTooltip>
          </div>
        ),
      },
    ];

    var rows = [];
    for (let a in avaliacoes) {
      rows.push({
        id: avaliacoes[a].id,
        id_usuario: avaliacoes[a].id_usuario,
        avaliador: avaliacoes[a].avaliador,
        nota1: avaliacoes[a].nota1,
        nota2: avaliacoes[a].nota2,
        nota3: avaliacoes[a].nota3,
        nota4: avaliacoes[a].nota4,
        nota5: avaliacoes[a].nota5,
        nota_final: avaliacoes[a].nota_final,
        arquivo: avaliacoes[a].arquivos,
      });
    }

    var rowsGestor = [];
    for (let ag in avaliacoesGestor) {
      rowsGestor.push({
        id: avaliacoesGestor[ag].id,
        gestor: avaliacoesGestor[ag].gestor,
        resultado: avaliacoesGestor[ag].resultado,
        observacao: avaliacoesGestor[ag].observacao,
        arquivo: avaliacoesGestor[ag].arquivos,
      });
    }

    var rowsNota = [];
    let mediaFinal = 0;
    let situacao = null;
    if (avaliacoes.length == 3) {
      rowsNota.push({
        media1: avaliacoes[0].nota_final,
        media2: avaliacoes[1].nota_final,
        media3: avaliacoes[2].nota_final,
        mediaFinal: (
          (avaliacoes[0].nota_final +
            avaliacoes[1].nota_final +
            avaliacoes[2].nota_final) /
          3
        ).toFixed(2),
      });
      mediaFinal = (
        (avaliacoes[0].nota_final +
          avaliacoes[1].nota_final +
          avaliacoes[2].nota_final) /
        3
      ).toFixed(2);
      if (rowsNota[0].mediaFinal > solucao.nota_corte) {
        rowsNota[0].situacao = idioma.verSolucao.prev_apro;
        situacao = 'Pré-aprovado';
      }
      if (rowsNota[0].mediaFinal <= solucao.nota_corte) {
        rowsNota[0].situacao = idioma.verSolucao.prev_rep;
        situacao = idioma.verSolucao.situacao;
      }
    }
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario.personalizacao.cor_principal,
          }}
        >
          <Titulo titulo={idioma.verSolucao.titulo} />
        </div>

        <div className="container-fluid bloco-topo">
          {solucao != null ? (
            <BlocoPerfil
              solucao={solucao}
              dadosApi={dadosApi}
              usuario={usuario}
              path={path}
              credenciais={credenciais}
            />
          ) : (
            <div className="col-md-12 mb-2">
              <div className="list-group-item">
                {' '}
                <div className="row">
                  <div className="col-md-12 ">
                    <h2 className="">
                      <Skeleton count={1} width={160} />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 mb-2">
            <div className="card erpcard">
              <div className="card-header card-header-large bg-header d-flex align-items-center">
                <div className="flex">
                  <h5 className="margin-zero titulo-header-card">
                    {idioma.verSolucao.detalhes}
                  </h5>
                </div>
              </div>
              <div className=" mb-2">
                {dadosApi ? (
                  <>
                    <div className="container-fluid mt-4">
                      <div className="m-1 cursor-pointer">
                        <BootstrapTable
                          bootstrap4
                          keyField="id"
                          data={rowsDetails}
                          columns={columnsDetails}
                          condensed
                          bordered={false}
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="row ml-3 mt-3">
                        <span className="text-justify mr-2">
                          {parse('' + solucao.descricao)}
                        </span>
                      </div>
                      <div className="row ml-3">
                        <div className="text-justify mt-3">
                          <span className="mr-2">
                            <strong>{idioma.verSolucao.resumo}: </strong>
                          </span>
                          {solucao.resumo}
                        </div>
                      </div>
                      <div className="row ml-3">
                        <div className="col-md-6">
                          <div className="text-justify ">
                            {arquivos.length > 0 ? (
                              <>
                                <span className=" mr-2">
                                  <strong>{idioma.verSolucao.detalhes}:</strong>
                                </span>
                                {arquivos.map((arquivo) => (
                                  <div className="row ml-3">
                                    <span className="pl-2">
                                      <Link
                                        onClick={() =>
                                          this.baixarEntrega(arquivo.fileName)
                                        }
                                      >
                                        {arquivo.originalName}
                                      </Link>
                                    </span>
                                  </div>
                                ))}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          {credenciais.length > 0 &&
                            (credenciais[0].id_perfil == 1 ||
                              credenciais[0].id_perfil == 9 ||
                              (solucao != null &&
                                solucao.gestor &&
                                solucao.gestor.id == id_usuario)) && (
                              <div className="text-justify ">
                                {avaliacoes.length > 0 ? (
                                  <>
                                    <span className=" mr-2">
                                      <strong>
                                        {idioma.verSolucao.avaliacoes}:
                                      </strong>
                                    </span>
                                    {avaliacoes.map((avaliacao) =>
                                      avaliacao.arquivos.map((arquivo) => (
                                        <div className="row ml-3">
                                          <span className="pl-2">
                                            <Link
                                              onClick={() =>
                                                this.baixarEntrega(
                                                  arquivo.fileName,
                                                )
                                              }
                                            >
                                              <strong>
                                                {avaliacao.avaliador}
                                              </strong>
                                              : {arquivo.originalName}
                                            </Link>
                                          </span>
                                        </div>
                                      )),
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="row ml-3">
                        <div className="col-md-6">
                          {credenciais.length > 0 &&
                            (credenciais[0].id_perfil == 1 ||
                              credenciais[0].id_perfil == 9 ||
                              (solucao != null &&
                                solucao.gestor &&
                                solucao.gestor.id == id_usuario)) && (
                              <div className="text-justify ">
                                {avaliacoesGestor.length > 0 ? (
                                  <>
                                    <span className=" mr-2">
                                      <strong>
                                        {idioma.verSolucao.aval_gestor}:
                                      </strong>
                                    </span>
                                    {avaliacoesGestor.map((avaliacao) =>
                                      avaliacao.arquivos.map((arquivo) => (
                                        <div className="row ml-3">
                                          <span className="pl-2">
                                            <Link
                                              onClick={() =>
                                                this.baixarEntrega(
                                                  arquivo.fileName,
                                                )
                                              }
                                            >
                                              {arquivo.originalName}
                                            </Link>
                                          </span>
                                        </div>
                                      )),
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          {dadosApi &&
            credenciais.length > 0 &&
            (credenciais[0].id_perfil == 1 ||
              credenciais[0].id_perfil == 9 ||
              (solucao != null &&
                solucao.gestor &&
                solucao.gestor.id == id_usuario) ||
              (avaliadores.length > 0 &&
                avaliadores.map(
                  (avaliador) => avaliador.id_usuario == id_usuario,
                )) ? (
              <div className="col-md-12 mb-2">
                <div className="card erpcard">
                  <div className="card-header card-header-large bg-header d-flex align-items-center">
                    <div className="flex">
                      <h5 className="margin-zero titulo-header-card">
                        {idioma.verSolucao.avaliacoes}
                      </h5>
                    </div>
                  </div>
                  <div className="mb-2">
                    {dadosApi ? (
                      <>
                        <div className="container-fluid mt-4">
                          <div className="m-1 cursor-pointer">
                            <BootstrapTable
                              bootstrap4
                              keyField="id"
                              data={rows}
                              columns={columns}
                              condensed
                              bordered={false}
                              wrapperClasses="table-responsive"
                            />
                          </div>
                        </div>
                        {detalhesAvaliador &&
                          avaliacoes.map(
                            (avaliacao) =>
                              avaliacao.id == id_detalhe && (
                                <div className="container-fluid m-3">
                                  <div className="m-1 cursor-pointer">
                                    <div className="row">
                                      <strong className="mr-2">
                                        {idioma.avaliar.tecnologia}:
                                      </strong>
                                      {avaliacao.nota1}
                                    </div>
                                    <div className="row">
                                      <strong className="mr-2">
                                        {idioma.avaliar.mercado}:
                                      </strong>
                                      {avaliacao.nota2}
                                    </div>
                                    <div className="row">
                                      <strong className="mr-2">
                                        {idioma.avaliar.capital}:
                                      </strong>
                                      {avaliacao.nota3}
                                    </div>
                                    <div className="row">
                                      <strong className="mr-2">
                                        {idioma.avaliar.gestao}:
                                      </strong>
                                      {avaliacao.nota4}
                                    </div>
                                    <div className="row">
                                      <strong className="mr-2">
                                        {idioma.avaliar.empreendedor}:
                                      </strong>
                                      {avaliacao.nota5}
                                    </div>
                                    <div className="row">
                                      <strong className="mr-2">
                                        {idioma.verSolucao.med_final}:
                                      </strong>
                                      {avaliacao.nota_final}
                                    </div>
                                    <div className="row">
                                      <strong className="mr-2">
                                        {idioma.verSolucao.docs}:{' '}
                                      </strong>
                                    </div>
                                    {avaliacao.arquivos.map((arquivo) => (
                                      <div>
                                        <div className="row ml-2">
                                          <Link
                                            onClick={() =>
                                              this.baixarEntrega(
                                                arquivo.fileName,
                                              )
                                            }
                                          >
                                            {arquivo.originalName}
                                          </Link>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              ),
                          )}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ''
            ))}

          {dadosApi &&
            credenciais.length > 0 &&
            (credenciais[0].id_perfil == 1 ||
              credenciais[0].id_perfil == 9 ||
              (solucao != null && solucao.gestor.id == id_usuario)) && (
              <div className="col-md-12 mb-2">
                <div className="card erpcard">
                  <div className="card-header card-header-large bg-header d-flex align-items-center">
                    <div className="flex">
                      <h5 className="margin-zero titulo-header-card">
                        {idioma.verSolucao.aval_gestor}
                      </h5>
                    </div>
                  </div>
                  <div className="mb-2">
                    {dadosApi ? (
                      avaliacoes.length === 0 || countAvaliadores.length < avaliadores.length ? (
                        <>
                          <div className="container-fluid mt-4 text-center">
                            <h5>
                              {idioma.verSolucao.aguardando}:{' '}
                              {avaliacoes.length === 0 ? (
                                `0 de ${avaliadores.length}`
                              ) : (
                                `${countAvaliadores.length} de ${avaliadores.length}`
                              )}
                            </h5>
                          </div>
                        </>
                      )
                        : countAvaliadores.length >= avaliadores.length && avaliacoesGestor.length <= 0 ? (
                          <>
                            <div className="container-fluid mt-4 text-center">
                              <h5>
                                {idioma.pagina_ver.aval_gestor_analise}
                              </h5>
                            </div>
                          </>
                        )
                          // : avaliacoes.length && avaliacoesGestor.length === 0 ? (
                          //   <>
                          //     <div className="container-fluid m-3">
                          //       <div className="m-1 cursor-pointer">
                          //         <div className="row">
                          //           <strong className="mr-2">
                          //             {idioma.verSolucao.med_final}:
                          //           </strong>
                          //           {(
                          //             (avaliacoes[0].nota_final +
                          //               avaliacoes[1].nota_final +
                          //               avaliacoes[2].nota_final) /
                          //             avaliadores.length
                          //           ).toFixed(2)}
                          //         </div>
                          //         <div className="row">
                          //           <strong className="mr-2">
                          //             {idioma.verSolucao.situ}:{' '}
                          //           </strong>
                          //           {situacao}
                          //         </div>
                          //       </div>
                          //     </div>
                          //   </>
                          // ) 
                          : (
                            <>
                              <div className="container-fluid mt-4">
                                <div className="m-1 cursor-pointer">
                                  <BootstrapTable
                                    bootstrap4
                                    keyField="id"
                                    data={rowsGestor}
                                    columns={columnsGestor}
                                    condensed
                                    bordered={false}
                                    wrapperClasses="table-responsive"
                                  />
                                </div>
                              </div>
                              {detalhesGestor &&
                                avaliacoesGestor.map(
                                  (avaliacao) =>
                                    avaliacao.id == id_detalhe && (
                                      <div className="container-fluid m-3">
                                        <div className="m-1 cursor-pointer">
                                          <div className="row">
                                            <strong className="mr-2">
                                              {idioma.verSolucao.resultado}:
                                            </strong>
                                            {avaliacao.resultado}
                                          </div>
                                          <div className="row">
                                            <strong className="mr-2">
                                              {idioma.verSolucao.obs1}:
                                            </strong>
                                            {avaliacao.observacao}
                                          </div>
                                          <div className="row">
                                            <strong className="mr-2">
                                              {idioma.verSolucao.docs}:{' '}
                                            </strong>
                                          </div>
                                          {avaliacao.arquivos.map((arquivo) => (
                                            <div>
                                              <div className="row ml-2">
                                                <Link
                                                  onClick={() =>
                                                    this.baixarEntrega(
                                                      arquivo.fileName,
                                                    )
                                                  }
                                                >
                                                  {arquivo.originalName}
                                                </Link>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    ),
                                )}
                            </>
                          )
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => ({
  solucao: state.hub.solucao,
  startups: state.hub.minhasStartups,
  usuarios: state.hub.usuarios,
  arquivos: state.hub.arquivosSolucao,
  avaliacoes: state.hub.avaliacoes,
  avaliacoesGestor: state.hub.avaliacoesGestor,
  pilares: state.hub.pilares,
  avaliadores: state.hub.avaliadores,
});
export default connect(mapStatetoProps, actions)(VerDesafio);
