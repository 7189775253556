import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import BlocoPerfil from '../../containers/blocos/perfilIncubadora';
import SemDados from '../../componentes/Mensagens/semDados';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/astronaut.png'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { apiImagem } from '../../config/';
import { portugues, ingles } from '../../utils/paginaincubadora_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class Startup extends Component {
    state = {
        id: "",
        id_incubadora: "",
        currentPage: 1,
        size: 6,
        procura: "",
        segmento: "",
        pesquisandoStartup: false,
        filtrandoJornada: false,
        jornadaSelecionada: 0,
        msg: "",
        erro: "",
        success: "",
        temIncubadora: false,
        temStartups: false,
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_incubadora } = this.props.match.params;
        this.setState({ id_incubadora })
        this.listarIncubadora(id_incubadora)
        this.listarStartups(id_incubadora);
        this.listarJornadas(id_incubadora)
        this.setState({ temIncubadora: true })

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }


    }
    componentDidUpdate(nextProps) {
        const { id_incubadora } = this.props.match.params;

        if (!this.props.startups && nextProps.startups) this.listarStartups(id_incubadora);
        if (!this.props.jornadasIncubadora && nextProps.jornadasIncubadora) this.listarJornadas(id_incubadora);

        if (!this.props.incubadora && nextProps.incubadora) {
            this.listarIncubadora(id_incubadora);
        }
        this.receberDadosAPI();

    }

    componentWillUnmount() {
        this.props.limparStartups();
    }
    receberDadosAPI() {
        if (typeof this.props.incubadora != "undefined" && this.props.incubadora != null && !this.state.dadosApi
            && typeof this.props.startups != "undefined" && this.props.startups != null && this.props.startups.currentPage === this.state.currentPage) {
            this.setState({ temStartups: true });
            this.setState({ dadosApi: true });

        }
    }

    changePageAtual(currentPage, id_incubadora) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarStartups(id_incubadora);
        })
    }
    apagarstartup(id) {
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarStartup(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarStartups();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }
    listarStartups(id_incubadora) {

        const { currentPage, size, jornadaSelecionada } = this.state;

        this.props.getIncubadoraStartups(id_incubadora, currentPage, size, jornadaSelecionada)
        const { startups } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startups === "undefined") return null
    }

    listarIncubadora(id_incubadora) {
        ////console.log(id_incubadora)

        this.props.getVerIncubadora(id_incubadora);
        const { incubadora } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadora === "undefined") return null
    }

    listarJornadas(id_incubadora) {
        this.props.getJornadasIncubadora(id_incubadora)
        const { jornadasIncubadora } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (jornadasIncubadora === "undefined") return null
    }

    onChangeFilter() {
        const { filtrandoJornada, pesquisandoStartup, procura, id_incubadora } = this.state;
        if (filtrandoJornada) {
            this.setState({ filtrandoJornada: false })
            this.setState({ jornadaSelecionada: 0 })
            this.props.limparStartups();
            if (pesquisandoStartup) {
                this.handleSearchNomeStartup(procura)
            } else {
                this.listarStartups(id_incubadora)
            }
        } else {
            this.setState({ filtrandoJornada: true })
            if (pesquisandoStartup) {
                this.handleSearchNomeStartup(procura)
            } else {
                this.listarStartups(id_incubadora)
            }
        }

    }
    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }
    onChangeJornada(campo, id) {
        this.setState({ [campo]: id })
        this.props.limparStartups();
        this.setState({ dadosApi: false });
        const { pesquisandoStartup, procura, id_incubadora } = this.state
        if (pesquisandoStartup) {
            this.handleSearchNomeStartup(procura)
        } else {
            this.listarStartups(id_incubadora)
        }
    }

    handleSearchNomeStartup(procura) {
        const { idioma } = this.state
        this.setState({ procura: procura })
        if (procura == "") {
            Swal.fire({
                icon: 'error',
                title: idioma.listarStartups.erro_pesquisa,

            })
        } else {
            const { currentPage, size, id_incubadora, jornadaSelecionada } = this.state;
            this.setState({ pesquisandoStartup: true })
            this.props.getSearchNameStartupsIncubadora(procura, id_incubadora, currentPage, size, jornadaSelecionada)
        }

    }

    eraseSearchStartup(campo) {
        this.setState({ pesquisandoStartup: false })
        this.setState({ [campo]: "" })

    }


    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            this.handleSearchNomeStartup(procura)
        }
    }

    render() {
        const { procura, segmento, temStartups, pesquisandoStartup, id_incubadora, temIncubadora, id, msg, carregando, erro, success, dadosApi, idioma, jornadaSelecionada, filtrandoJornada } = this.state;
        var startups = [];
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var jornadas = [];
        if (this.props.jornadasIncubadora) jornadas = this.props.jornadasIncubadora.dados;

        if (pesquisandoStartup == false) {
            if (this.props.startups) startups = this.props.startups.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.startups != "undefined" && this.props.startups != null && this.props.startups.currentPage !== "" && this.props.startups.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.startups != "undefined" && this.props.startups !== null && this.props.startups.nextPage <= this.props.startups.totalPages && this.props.startups.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }

        } else {
            if (this.props.searchStartup) startups = this.props.searchStartup.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.searchStartup != "undefined" && this.props.searchStartup != null && this.props.searchStartup.currentPage !== "" && this.props.searchStartup.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temPaginaAnterior = false;
            if (typeof this.props.searchStartup != "undefined" && this.props.searchStartup !== null && this.props.searchStartup.nextPage <= this.props.searchStartup.totalPages && this.props.searchStartup.nextPage != null) {
                proximaPagina = true;
                temPaginaAnterior = true;
            }
        }


        var incubadora = null;
        if (this.props.incubadora) incubadora = this.props.incubadora;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var semImagem = apiImagem + "fundo_startup.jpg";
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.listarStartups.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {incubadora != null && usuario != null ?
                        <BlocoPerfil dadosApi={dadosApi} incubadora={incubadora} credenciais={credenciais} usuario={usuario} path={path} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {temIncubadora ?
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisa} />
                                    </div>
                                    <div className="col-md-4">
                                        <div>
                                            <span className="pr-3" onClick={() => this.handleSearchNomeStartup(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="botao-pesquisa-responsivo" texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                            <span value={procura} onClick={() => this.eraseSearchStartup("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" classes="botao-pesquisa-responsivo" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                        </div>
                                    </div>
                                    {credenciais.map(credencial => ((credencial.id_componente == 103) ?
                                        <div className="col-lg-12 mt-3">
                                            <span onClick={() => this.onChangeFilter()} id="filtrar" name="filtrar"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={!filtrandoJornada ? this.props.usuario?.personalizacao?.cor_terciaria : ""} cor={filtrandoJornada ? "danger" : ""} texto={!filtrandoJornada ? idioma.bt_filtrar : idioma.bt_cancelar} icone={!filtrandoJornada ? "filter" : "times"} posicao="E" /></span>
                                            {filtrandoJornada &&
                                                <div>
                                                    <span className={jornadaSelecionada == 0 ? "badge badge-success cursor-pointer mr-3 mt-4 py-2 px-3" : "badge badge-secondary cursor-pointer mr-3 mt-4 py-2 px-3"} onClick={() => this.onChangeJornada("jornadaSelecionada", 0)}>TODAS</span>
                                                    {jornadas.map(jornada => (
                                                        <span className={jornadaSelecionada == jornada.id ? "badge badge-success cursor-pointer mr-3 mt-4 py-2 px-3" : "badge badge-secondary cursor-pointer mr-3 mt-4 py-2 px-3"} onClick={() => this.onChangeJornada("jornadaSelecionada", jornada.id)}>{jornada.nome}</span>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                        : ""))}
                                </div>
                                <div className="d-flex">
                                    <div className="col-md-12 p-2">
                                        {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                        <AlertaErro erros={erro} />
                                        <AlertaSuccess erros={success} />
                                        <div>
                                            {dadosApi ?
                                                <div className="row">
                                                    {startups.length > 0 ? startups.map(startup => (
                                                        <div className="col-md-4" key={startup.id}>
                                                            <div className="directory-card  card-startups">
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/startups/" + startup.id}>
                                                                    <div className="directory-card card">
                                                                        <div>
                                                                            <div className="directory-bg text-center" style={(startup.fileNameCapa !== null) ? { backgroundImage: `url(${apiImagem}${startup.fileNameCapa})` } : { backgroundImage: `url(${semImagem})` }}>
                                                                                <div className="directory-overlay">
                                                                                    <img className="rounded-circle avatar-lg img-thumbnail" src={startup.fileName ? apiImagem + startup.fileName : iconeUsuario} alt={startup.nome}></img>
                                                                                </div>
                                                                            </div>
                                                                            <div className="directory-content text-center">
                                                                                <div className="titulo-startup">{startup.nome}</div>
                                                                                <span className="texto-cidade"> <FontAwesomeIcon icon="map-marker-alt" color="black" className="mr-2" />{" "} {startup.cidade}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )) : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.listarStartups.sem_dados} />}
                                                </div>
                                                : <div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="directory-card  card-startups">
                                                                <div className="directory-card card">
                                                                    <div>
                                                                        <div className="directory-skeleton-bg text-center">
                                                                            <div className="directory-overlay">
                                                                                <Skeleton circle={true} height={100} width={100} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="directory-content text-center">
                                                                            <div className="titulo-startup">
                                                                                <Skeleton count={1} width={80} />
                                                                            </div>
                                                                            <span className="texto-cidade">
                                                                                <Skeleton count={1} width={60} />
                                                                            </span>
                                                                            <span>
                                                                                <Skeleton count={1} width={60} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="directory-card  card-startups">
                                                                <div className="directory-card card">
                                                                    <div>
                                                                        <div className="directory-skeleton-bg text-center">
                                                                            <div className="directory-overlay">
                                                                                <Skeleton circle={true} height={100} width={100} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="directory-content text-center">
                                                                            <div className="titulo-startup">
                                                                                <Skeleton count={1} width={80} />
                                                                            </div>
                                                                            <span className="texto-cidade">
                                                                                <Skeleton count={1} width={60} />
                                                                            </span>
                                                                            <span>
                                                                                <Skeleton count={1} width={60} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="directory-card  card-startups">
                                                                <div className="directory-card card">
                                                                    <div>
                                                                        <div className="directory-skeleton-bg text-center">
                                                                            <div className="directory-overlay">
                                                                                <Skeleton circle={true} height={100} width={100} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="directory-content text-center">
                                                                            <div className="titulo-startup">
                                                                                <Skeleton count={1} width={80} />
                                                                            </div>
                                                                            <span className="texto-cidade">
                                                                                <Skeleton count={1} width={60} />
                                                                            </span>
                                                                            <span>
                                                                                <Skeleton count={1} width={60} />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>}
                                        </div>
                                        {dadosApi && startups.length > 0 ?
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center">
                                                    <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1, id_incubadora)} >{idioma.paginacao1}</span>
                                                    </li>

                                                    {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.startups.prevPage ? this.props.startups.prevPage : 1, id_incubadora)}>{this.props.startups.prevPage ? this.props.startups.prevPage : ""}</span></li> : ""}

                                                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.startups ? this.props.startups.currentPage : "1"}</span></li>

                                                    {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.startups.nextPage ? this.props.startups.nextPage : 1, id_incubadora)}>{this.props.startups.nextPage ? this.props.startups.nextPage : ""}</span></li> : ""}

                                                    <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.startups ? this.props.startups.totalPages : 0, id_incubadora)}>{idioma.paginacao2}</span>
                                                    </li>
                                                </ul>
                                            </nav>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="list-group-item bloco-card">
                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    <h5>{idioma.listarStartups.erro}</h5>
                                    <div className="row">
                                        <div className="col-md-8">
                                            <Input type="text" value={procura} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisa} />
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <span className="pr-3" onClick={() => this.handleSearchNomeStartup(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                                <span onClick={() => this.eraseSearchStartup()} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    startups: state.incubadoras.incubadoraStartups,
    incubadora: state.incubadoras.incubadora,
    jornadasIncubadora: state.incubadoras.jornadasIncubadora,
    searchStartup: state.incubadoras.searchStartupIncubadora,
})
export default connect(mapStatetoProps, actions)(Startup);