export const portugues = {
  title: 'Faça o login com sua conta',
  senha: 'Senha',
  bt_acesso: 'Acessar',
  esqueceu_senha: 'Esqueceu a senha?',
  idioma: 'Idioma',
  port: 'Português',
  ingles: 'Inglês',
  erro_email: 'Preencha o seu e-mail',
  erro_senha: 'Preencha a sua senha',
  recuperar_senha: 'Recuperar senha',
  tem_login: 'Já tem seu login?',
  msg_sucesso: 'Enviado no e-mail as intruções para recuperar a senha, verifique sua caixa de entrada!',
  bt_atualizarSenha: 'Atualizar senha',
  input_senha: 'Nova senha',
  login: 'Fazer login',
  erro_senha2: 'A senha deve ter pelo menos 6 caracteres',
  msg_sucesso2: 'Senha alterada com sucesso!',
  msg_erro: 'Chave inválida, por favor solicite recuperação de senha novamente!'
}

export const ingles = {
  title: 'Login with your account',
  senha: 'Password',
  bt_acesso: 'Access',
  esqueceu_senha: 'Forgot password?',
  idioma: 'Language',
  port: 'Portuguese',
  ingles: 'English',
  erro_email: 'E-mail must be filled',
  erro_senha: 'Password must be filled',
  recuperar_senha: 'Recover password',
  tem_login: 'Already have your login?',
  msg_sucesso: 'Sent in the email the instructions to recover the password, check your inbox!',
  bt_atualizarSenha: 'Update password',
  input_senha: 'New password',
  login: 'Login',
  erro_senha2: 'Password must be at least 6 characters long',
  msg_sucesso2: 'Password changed successfully!',
  msg_erro: 'Invalid key, please request password recovery again!'
}