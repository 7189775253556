import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardBody, UncontrolledCollapse } from 'reactstrap';
import validator from 'validator';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { GET_MODULOS, GET_MATERIAS } from '../../store/actions/types';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class CadastrarMateria extends Component {
    state = {
        nome: "",
        duracao: "",
        id_materia: "",
        id_aula: "",
        id_trilha: "",
        id_curso: "",
        id_modulo: "",
        ordem: "",
        descricao: "",
        conteudo: "",
        erro: "",
        success: "",
        carregando: "",
        data_publicacao: "",
        dadosApi: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha })
        this.listarTrilha(id_trilha)
        this.listar(id_trilha)
        if (this.props.location.state != undefined) {
            this.setState({ id_modulo: this.props.location.state.id_modulo })
        }
        //////////////console.log(id_trilha)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.modulos && nextProps.modulos) {
            this.listar(id_trilha);
        }
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha)
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparModulos()
    }

    receberDadosAPI() {
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
            if (typeof this.props.modulos != "undefined" && this.props.modulos != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }


    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }
    cadMateria() {
        const { nome, ordem, id_trilha, id_modulo, descricao, data_publicacao } = this.state;
        this.setState({ carregando: true })
        var id_curso = id_trilha
        ////////////console.log(nome, ordem, id_curso, id_modulo, descricao)
        this.props.postMateria({ nome, ordem, id_curso, id_modulo, descricao, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    listar(id_trilha) {
        this.props.getModulos(id_trilha)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (id_trilha === "undefined") return null
    }


    render() {
        const { nome, id_trilha, descricao, id_materia, idioma, data_publicacao, id_modulo, id_aula, ordem, erro, success, carregando, iconSalvar, formSuccess, dadosApi } = this.state;
        var modulos = [];
        if (this.props.modulos) modulos = this.props.modulos.dados.modulos;

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path 
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos",
            state: { msg: idioma.cadastrarMateria.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarMateria.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div>
                                <AlertaErro erros={erro} />
                                <AlertaSuccess erros={success} />
                                <div>
                                    <Form>
                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarMateria.nome}</Label>
                                                    <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrarMateria.nome2} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Label for="ordem">{idioma.cadastrarMateria.selecione_ord}</Label>
                                                <Input type="number" value={ordem} onChange={(ev) => this.onChnageInput("ordem", ev)} id="ordem" name="ordem" placeholder={idioma.cadastrarMateria.ordem_mat} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="descricao">{idioma.cadastrarMateria.conteudo_mat}</Label>
                                                    <Input type="textarea" value={descricao} autoComplete="descricao" onChange={(ev) => this.onChnageInput("descricao", ev)} name="descricao" id="descricao" placeholder={idioma.cadastrarMateria.conteudo_mat} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col>
                                                <Label for="modulo">{idioma.cadastrarAula.modulo}</Label>
                                                <Input type="select" onChange={(ev) => this.onChnageInput("id_modulo", ev)} id="id_modulo" name="id_modulo" >
                                                    <option>{idioma.cadastrarAula.escolher_mod}</option>
                                                    {modulos.map(modulo => (
                                                        <option value={modulo.modulo.id} key={modulo.modulo.id} selected={modulo.modulo.id == id_modulo ? true : false}>
                                                            {modulo.modulo.nome}
                                                        </option>))}
                                                </Input>

                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="data_publicacao">{idioma.cadastrarAtividade.data}</Label>
                                                    <Input type="date" value={data_publicacao} autoComplete="data_publicacao" onChange={(ev) => this.onChnageInput("data_publicacao", ev)} name="data_publicacao" id="data_publicacao" placeholder={idioma.cadastrarAtividade.data} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                            </Col>
                                        </Row>
                                        <Link to="#" onClick={() => this.cadMateria()} className="mt-3"> <Botao loading={carregando} texto={idioma.cadastrarMateria.bt_cadastrar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    trilha: state.trilhas.trilha,
    modulos: state.trilhas.modulos,
    materias: state.trilhas.materias,
    aulas: state.trilhas.aulas
})

export default connect(mapStateToProps, actions)(CadastrarMateria);