import React from 'react';

const SemDados = (props) => {
    return (
        <div>
            <div className="row">
                <div className="col-md-4" >
                    <div className="img-perfil">
                        <img className="rounded-circle mt-2" src={"/imagens/astronauta_sem_dados.png"} width="100%" ></img>
                    </div>
                </div>
                <div className="col mensagem-dados">
                    <h4>{props.titulo}</h4>
                    <p>{props.texto}</p>
                    <p>{props.subtexto}</p>

                </div>
            </div>
        </div>
    )
}
export default SemDados;