
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ReactLoadingSkeleton = () => (
  <SkeletonTheme color="lightGray">
    <div>
      <div className="row">
        <div className="col-md-4">
          <div className="directory-card  card-startups">
            <div className="directory-card card">
              <div>
                <div className="directory-skeleton-bg text-center">
                  <div className="directory-overlay">
                  </div>
                </div>
                <div className="directory-content text-center">
                  <div className="titulo-startup">
                    <Skeleton count={1} width={80} />
                  </div>
                  <div className="titulo-startup">
                    <Skeleton count={1} width={80} />
                  </div>
                  <span className="texto-cidade">
                    <Skeleton count={1} width={60} />
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="directory-card  card-startups">
            <div className="directory-card card">
              <div>
                <div className="directory-skeleton-bg text-center">
                  <div className="directory-overlay">
                  </div>
                </div>
                <div className="directory-content text-center">
                  <div className="titulo-startup">
                    <Skeleton count={1} width={80} />
                  </div>
                  <div className="titulo-startup">
                    <Skeleton count={1} width={80} />
                  </div>
                  <span className="texto-cidade">
                    <Skeleton count={1} width={60} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="directory-card  card-startups">
            <div className="directory-card card">
              <div>
                <div className="directory-skeleton-bg text-center">
                  <div className="directory-overlay">
                  </div>
                </div>
                <div className="directory-content text-center">
                  <div className="titulo-startup">
                    <Skeleton count={1} width={80} />
                  </div>
                  <div className="titulo-startup">
                    <Skeleton count={1} width={80} />
                  </div>
                  <span className="texto-cidade">
                    <Skeleton count={1} width={60} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </SkeletonTheme>
);

export default ReactLoadingSkeleton;


