import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';
import { apiImagem } from '../../../config';

class CadastrarProcesso extends Component {
  state = {
    nome_processo: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    msg_backend: portuguese,
    idioma: portugues,
    capa: '',
    capaPreview: '',
    colunas: ''
  }
  componentDidMount() {

    const { usuario } = this.props;

    this.listarProcesso();

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }

  componentDidUpdate(nextProps) {
    if (!this.props.processo && nextProps.processo) this.listarProcesso();
    this.receberDadosAPI();
  }

  componentWillUnmount() {
    const { limparProcessoMetodologia } = this.props;

    limparProcessoMetodologia();
  }

  receberDadosAPI() {
    if (typeof this.props.processo != "undefined" && this.props.processo != null && !this.state.dadosApi) {
      this.setState({ nome_processo: this.props.processo.dados.nome });
      this.setState({ capa: this.props.processo.dados.fileName });
      this.setState({ colunas: this.props.processo.dados.colunas });
      this.setState({ dadosApi: true });
    }
  }

  listarProcesso() {
    const { id_processo } = this.props.match.params;
    this.props.getProcesso(id_processo)
    const { processo } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state })
      this.props.location.state = "";
    }
    if (processo === "undefined") return null
  }

  handleEditarProcesso() {
    const { nome_processo, capa, capaPreview, colunas } = this.state;
    const { id_metodologia, id_processo } = this.props.match.params;
    if (!this.validate()) return;
    this.setState({ carregando: true })
    const formData = new FormData();
    if (capaPreview) formData.append('file', capa);
    formData.append('id', id_processo);
    formData.append('nome', nome_processo);
    formData.append('id_metodologia', id_metodologia);
    formData.append('colunas', colunas);
    this.props.putEditarProcesso(formData, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
      }
    }
    )
  }

  validate() {
    const { nome_processo, idioma, colunas } = this.state;
    if (!nome_processo) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro } });
    if (!colunas) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro_colunas } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  onChangeInputFile(campo, ev) {
    this.setState({ [campo]: ev.target.files[0] })

    const file = ev.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      // fileReader.readAsDataURL(file);
      fileReader.readAsDataURL(file)
      fileReader.addEventListener('load', () => this.setState({ capaPreview: fileReader.result }))
    }
  }

  render() {
    const { carregando, erro, formSuccess, idioma, nome_processo, capaPreview, capa } = this.state;
    const { id_metodologia } = this.props.match.params;

    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processos/listar`,
      state: idioma.editarProcesso.msg_sucesso
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.editarProcesso.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome_processo', e)} value={nome_processo} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarProcesso.input_place} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="colunas">{idioma.cadastrarProcesso.campo_colunas}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("colunas", ev)} id="colunas" name="colunas" >
                      <option value='1' selected={this.props.processo?.dados?.colunas === 1} >{idioma.cadastrarProcesso.coluna_1}</option>
                      <option value='2' selected={this.props.processo?.dados?.colunas === 2}>{idioma.cadastrarProcesso.coluna_2}</option>
                      <option value='3' selected={this.props.processo?.dados?.colunas === 3}>{idioma.cadastrarProcesso.coluna_3}</option>
                      <option value='4' selected={this.props.processo?.dados?.colunas === 4}>{idioma.cadastrarProcesso.coluna_4}</option>
                    </Input>
                  </FormGroup>
                  <FormGroup className='mt-5 mb-5'>
                    <img src={capaPreview ? capaPreview : `${apiImagem}${capa}`} alt="..." class="img-thumbnail mr-3" style={{ width: "100px" }} />
                    <Label for="capa">Capa</Label>
                    <Input type="file" id="capa" name="file" accept='image/png, image/jpeg, image/jpg' className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("capa", ev)} />
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleEditarProcesso()}> <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  processo: state.startups.processoMetodologia,
})
export default connect(mapStatetoProps, actions)(CadastrarProcesso);