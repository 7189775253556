import { GET_RADARES, GET_PILARES, GET_PERGUNTAS_RADAR, GET_RADAR, GET_OPCOES, LIMPAR_RADARES, LIMPAR_PILARES, LIMPAR_PERGUNTAS, LIMPAR_OPCOES } from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_RADARES:
            return {
                ...state,
                radares: actions.payload.data
            }
        case GET_RADAR:
            return {
                ...state,
                radar: actions.payload.data
            }
        case GET_PILARES:
            return {
                ...state,
                pilares: actions.payload.data
            }
        case GET_PERGUNTAS_RADAR:
            return {
                ...state,
                perguntas: actions.payload.data
            }
        case GET_OPCOES:
            return {
                ...state,
                opcoes: actions.payload.data
            }
        case LIMPAR_RADARES:
            return {
                radares: null
            }
        case LIMPAR_PILARES:
            return {
                pilares: null
            }
        case LIMPAR_PERGUNTAS:
            return {
                perguntas: null
            }
        case LIMPAR_OPCOES:
            return {
                opcoes: null
            }
        default:
            return state;
    }
}