import React from 'react';
import Header from '../../componentes/Header';
import Sidebar from '../../componentes/Sidebar';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../styles/dashboard/video-react.css";
import '../../styles/dashboard/scrollbar.css';
import '../../styles/dashboard/index.css';

class BaseDashboard extends React.Component {
    // altSitMenu() {
    //     // this.setState({ sitMenu: !this.state.sitMenu })
    //     this.props.handleSitMenu(!this.props.sitMenu)
    // }altSitMenu={this.props.handleSitMenu(!this.props.sitMenu)}
    // altSitMenu = this.altSitMenu.bind(this);

    render() {
        // const { sitMenu } = this.state;
        return (
            <>
                <Header handleLogout={this.props.handleLogout} dadosUsuario={this.props} />
                <div className="d-flex">
                    <Sidebar ativo={this.props.sitMenu} handleLogout={this.props.handleLogout} usuario={this.props.usuario} dadosUsuario={this.props} credenciais={this.props.credenciais} notificacoes={this.props.notificacoes} />
                    <div className={this.props.sitMenu ? "content-sidebar-open" : "content"} style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_secundaria }}>
                        {this.props.children}
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    sitMenu: state.auth.sitMenu
})
export default connect(mapStatetoProps, actions)(BaseDashboard)
