import axios from 'axios';
import { GET_PERFIS_ACESSO, GET_PERFIL_ACESSO, GET_CREDENCIAIS, GET_COMPONENTES, LIMPAR_PERFIL_ACESSO, LIMPAR_PERFIS_ACESSO } from './types'
import { api } from '../../config';
import { getHeaders, clearToken, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getPerfis = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/credenciais/perfis?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERFIS_ACESSO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuariosPerfil = (id_perfil, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/credenciais/perfis/${id_perfil}?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERFIS_ACESSO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postPerfil = (dadosPerfil, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosPerfil)
        axios.post(api + `/credenciais/perfil`, dadosPerfil, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putPerfil = (dadosPerfil, callback) => {
    return function (dispatch) {
        axios.put(api + `/credenciais/perfil/editar`, dadosPerfil, getHeadersPost())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}




export const getVerPerfil = (id_perfil) => {
    return function (dispatch) {
        axios.get(api + `/credenciais/perfil/${id_perfil}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERFIL_ACESSO, payload: response.data })
            })
            .catch(errorHandle)
    }
}


export const deletarPerfil = (id_perfil, callback) => {
    return function (dispatch) {
        axios.delete(api + `/credenciais/perfil/deletar/${id_perfil}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postCredencial = (dadosCredencial, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosCredencial)
        axios.post(api + `/credenciais/perfil/inserir/credencial`, dadosCredencial, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const getCredenciais = (id_perfil) => {
    return function (dispatch) {
        axios.get(api + `/credenciais/perfil/${id_perfil}/credenciais`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_CREDENCIAIS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const deletarCredencial = (id_perfil, id_componente, callback) => {
    return function (dispatch) {
        axios.delete(api + `/credenciais/perfil/${id_perfil}/excluir/componente/${id_componente}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getComponentes = () => {
    return function (dispatch) {
        axios.get(api + `/credenciais/componentes`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_COMPONENTES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const limparPerfil = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PERFIL_ACESSO })
    }
}
export const limparPerfis = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PERFIS_ACESSO })
    }
}