import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/eventos';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';


import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginaeventos_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class CadastrarEvento extends Component {
    state = {
        id_usuario: "",
        nome: "",
        descricao: "",
        data: "",
        data_fim: "",
        cidade: "",
        linkHTTP: "",
        responsavel: "",
        temDataFim: false,
        erro: "",
        success: "",
        file: null,
        carregando: "",
        formSuccess: false,
        editorState: EditorState.createEmpty(),
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    cadEvento() {
        const { id_usuario, nome, editorState, file, data, data_fim, temDataFim, linkHTTP, responsavel, cidade } = this.state;
        var http = "https://"
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (!this.validate()) return;
        this.setState({ carregando: true })
        if (linkHTTP.includes("https://")) {
            var link = linkHTTP
        } else {
            var link = http + linkHTTP
        }

        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('nome', nome)
        formData.append('id_usuario', id_usuario)
        formData.append('data', data)
        if (temDataFim) {
            formData.append('data_fim', data_fim)
        } else {
            formData.append('data_fim', data)
        }
        formData.append('descricao', descricao)
        formData.append('link', link)
        formData.append('responsavel', responsavel)
        formData.append('cidade', cidade)
        this.props.postEvento(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { nome, descricao, cidade, responsavel, data, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_nome } });
        // if (!descricao) return this.setState({ erro: { message: "Preencha uma descrição" } });
        if (!cidade) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_cidade } });
        if (!data) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_data } });
        if (!responsavel) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_responsavel } });
        return true;
    }
    render() {
        const { id_usuario, nome, descricao, file, data, data_fim, responsavel, linkHTTP, cidade, erro, temDataFim, success, carregando, iconSalvar, formSuccess, editorState, idioma } = this.state;
        if (formSuccess) return <Redirect to={{
            pathname: "/eventos",
            state: { msg: idioma.cadastrarEvento.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarEvento.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.cadastrarEvento.nome}</Label>
                                            <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChangeInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrarEvento.nome_ev} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="data">{idioma.cadastrarEvento.data}</Label>
                                            <Input type="date" value={data} autoComplete="data" onChange={(ev) => this.onChangeInput("data", ev)} name="data" id="data" placeholder={idioma.cadastrarEvento.data} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={8}>
                                        <Label for="data">{idioma.cadastrarEvento.info_evento}</Label>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1" onClick={() => this.setState({ temDataFim: true })} />{' '}
                                                {idioma.cadastrarEvento.sim}
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1" onClick={() => this.setState({ temDataFim: false })} />{' '}
                                                {idioma.cadastrarEvento.nao}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    {temDataFim &&
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="data_fim">{idioma.cadastrarEvento.data_fim}</Label>
                                                <Input type="date" value={data_fim} autoComplete="data_fim" onChange={(ev) => this.onChangeInput("data_fim", ev)} name="data_fim" id="data_fim" placeholder={idioma.cadastrarEvento.data_fim} />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row form>
                                    <Col md={8}>
                                        <Label for="nome">{idioma.cadastrarEvento.responsavel}</Label>

                                        <FormGroup>
                                            <Input type="text" value={responsavel} autoComplete="responsavel" onChange={(ev) => this.onChangeInput("responsavel", ev)} name="responsavel" id="responsavel" placeholder={idioma.cadastrarEvento.responsavel} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.cadastrarEvento.cidade}</Label>
                                            <Input type="text" value={cidade} autoComplete="cidade" onChange={(ev) => this.onChangeInput("cidade", ev)} name="cidade" id="cidade" placeholder={idioma.cadastrarEvento.cidade} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="linkHTTP">{idioma.cadastrarEvento.link}</Label>
                                            <Input type="text" value={linkHTTP} autoComplete="linkHTTP" onChange={(ev) => this.onChangeInput("linkHTTP", ev)} name="linkHTTP" id="linkHTTP" placeholder={idioma.cadastrarEvento.link} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="file">{idioma.cadastrarEvento.img}</Label>
                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                </FormGroup>
                                <Row>
                                    <Col md={12} className="mt-3">
                                        <FormGroup>
                                            <Label for="nome">{idioma.cadastrarEvento.descricao}</Label>
                                            <Card>

                                                <div>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}

                                                    />
                                                </div>
                                            </Card>

                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="hidden" value={id_usuario} id="id_usuario" name="id_usuario" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Link to="#" onClick={() => this.cadEvento()}> <Botao loading={carregando} classes="botao-responsivo botao-estilo" texto={idioma.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>

                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarios: state.auth.usuarios,
    eventos: state.eventos.eventos
})

export default connect(mapStateToProps, actions)(CadastrarEvento);