import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import BlocoPerfil from '../../containers/blocos/perfilTrilha';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Configuracoes extends Component {
    state = {
        id_trilha: "",
        startup: "",
        msg: "",
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha });
        this.listar(id_trilha, this.props.usuario.id);

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }


    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha);
        }
        if (!this.props.modulos && nextProps.modulos) {
            this.listar(id_trilha, this.props.usuario.id);
        }
        this.receberDadosAPI();
    }

    // componentWillUnmount() {
    //     this.props.limparTrilha()
    // }


    receberDadosAPI() {
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {

            if (typeof this.props.modulos != "undefined" && this.props.modulos != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listar(id_trilha, id_usuario) {
        this.props.getModulos(id_trilha, id_usuario)
        const { modulos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (modulos === "undefined") return null
    }

    //ativar trilha
    ativarTrilha(id_trilha) {
        this.setState({ carregando: true })
        ////////////console.log(id_trilha)
        this.props.ativarTrilha(id_trilha, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarTrilha(id_trilha);
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
            }
        })
    }

    chamarAlertaAtivar(id_trilha) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.modal_title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.config.modal_confirm_ativar
        }).then((result) => {
            if (result.value) {
                this.ativarTrilha(id_trilha);
            }
        })
    }

    sucessoAlerta(resp) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: resp
        })
    }


    // desativar trilha
    desativandoTrilha(id_trilha) {
        this.setState({ carregando: true })
        ////////////console.log(id_trilha)
        this.props.desativarTrilha(id_trilha, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarTrilha(id_trilha);
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
            }
        })
    }

    chamarAlerta(id_trilha) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.modal_title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.config.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.desativandoTrilha(id_trilha);
            }
        })
    }

    sucessoAlerta(resp) {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: resp
        })
    }

    //deletar trilha
    deletandoTrilha(id_trilha) {
        this.setState({ carregando: true })
        this.props.deletarTrilha(id_trilha, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccessDelete: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_trilha) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.config.modal_titulo,
            html: idioma.config.modal_info,
            input: 'text',
            inputPlaceholder: idioma.config.modal_confirm2,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.config.confirm) {
                this.deletandoTrilha(id_trilha);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.config.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.config.modal_sucesso
        })
    }


    duplicar(id_trilha) {
        let id_usuario = this.props.usuario.id
        this.setState({ carregando: true })
        ////////////console.log(id_startup)
        this.props.postDuplicarTrilha({ id_trilha, id_usuario }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ id_trilha: msg.erro.id_trilha })
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoDuplicar();
            }
        })
    }

    chamadoDuplicar(id_trilha) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_title,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.config.modal_confirm3
        }).then((result) => {
            if (result.value) {
                this.duplicar(id_trilha);
            }
        })
    }
    sucessoDuplicar() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.config.modal_sucesso2
        })
    }
    render() {
        const { id_trilha, dadosApi, formSuccess, formSuccessDelete, idioma } = this.state

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var matriculado = [];
        if (this.props.modulos) matriculado = this.props.modulos.dados.matriculado;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos",
        }}></Redirect>
        if (formSuccessDelete) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/",
        }}></Redirect>
        ////////console.log(this.props)

        // var isAuthor = trilha?.autores.filter(trilha => trilha.id_autor === usuario.id);
        var isAuthor = false;
        for (const l in trilha?.autores) {
            if (!isAuthor) isAuthor = (trilha?.autores[l].id_autor === usuario.id)
        }
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.config.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoPerfil matriculado={matriculado} dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {dadosApi ?
                                <div className="row">
                                    {credenciais.map(credencial => ((credencial.id_componente == 23) ?
                                        <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/cadastrar"}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <FontAwesomeIcon icon="chalkboard-teacher" size="3x" color="black" />
                                                            <div className="titulo-menu-perfil">{idioma.config.bt_cad_modulo}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 47) ?
                                        <div className="col-md-2 card-menu-perfil">
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/materias/cadastrar"}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <FontAwesomeIcon icon="chalkboard-teacher" size="3x" color="black" />
                                                            <div className="titulo-menu-perfil">{idioma.config.bt_cad_materia}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 25) ?
                                        <div className="col-md-2 card-menu-perfil">
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/aulas/cadastrar"}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <FontAwesomeIcon icon="chalkboard-teacher" size="3x" color="black" />
                                                            <div className="titulo-menu-perfil">{idioma.config.bt_cad_aula}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 48) ?
                                        <div className="col-md-2 card-menu-perfil">
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/atividades/cadastrar"}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <FontAwesomeIcon icon="chalkboard-teacher" size="3x" color="black" />
                                                            <div className="titulo-menu-perfil">{idioma.config.bt_cad_atividade}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 88) ?
                                        <div className="col-md-2 card-menu-perfil">
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/autores"}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <FontAwesomeIcon icon="users" size="3x" color="black" />
                                                            <div className="titulo-menu-perfil">{idioma.config.autores}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {trilha.id_autor == usuario.id ?
                                        isAuthor !== -1 && (
                                            <>
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/editar/" + trilha.id}>
                                                        <Card className="card-dash">
                                                            <CardBody className="img-text-wrapper">
                                                                <div className="logo-wrapper">
                                                                    <FontAwesomeIcon icon="pencil-alt" size="3x" color="black" />
                                                                    <div className="titulo-menu-perfil">{idioma.config.bt_edit_trilha}</div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Link to="#" onClick={() => this.chamadoDuplicar(trilha.id)}>
                                                        <Card className="card-dash">
                                                            <CardBody className="img-text-wrapper">
                                                                <div className="logo-wrapper">
                                                                    <FontAwesomeIcon icon="copy" size="3x" color="black" />
                                                                    <div className="titulo-menu-perfil">{idioma.config.bt_dup_trilha}</div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            </>
                                        )
                                        :
                                        credenciais.length > 0 ?
                                            credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9 || isAuthor !== 1 ?
                                                <>
                                                    <div className="col-md-2 card-menu-perfil">
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/editar/" + trilha.id}>
                                                            <Card className="card-dash">
                                                                <CardBody className="img-text-wrapper">
                                                                    <div className="logo-wrapper">
                                                                        <FontAwesomeIcon icon="pencil-alt" size="3x" color="black" />
                                                                        <div className="titulo-menu-perfil">{idioma.config.bt_edit_trilha}</div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                    <>
                                                        <div className="col-md-2 card-menu-perfil">
                                                            <Link to="#" onClick={() => this.chamadoDuplicar(trilha.id)}>
                                                                <Card className="card-dash">
                                                                    <CardBody className="img-text-wrapper">
                                                                        <div className="logo-wrapper">
                                                                            <FontAwesomeIcon icon="copy" size="3x" color="black" />
                                                                            <div className="titulo-menu-perfil">{idioma.config.bt_dup_trilha}</div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-2 card-menu-perfil">
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/autores"}>
                                                                <Card className="card-dash">
                                                                    <CardBody className="img-text-wrapper">
                                                                        <div className="logo-wrapper">
                                                                            <FontAwesomeIcon icon="users" size="3x" color="black" />
                                                                            <div className="titulo-menu-perfil">{idioma.config.autores}</div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </div>
                                                    </>
                                                </>
                                                : ""
                                            : ""}
                                    {trilha.id_autor == usuario.id || isAuthor !== -1 ?
                                        <div className="col-md-2 card-menu-perfil">
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/editar-capa/" + trilha.id}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <FontAwesomeIcon icon="camera" size="3x" color="black" />
                                                            <div className="titulo-menu-perfil">{idioma.config.bt_edit_capa}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        :
                                        credenciais.length > 0 ?
                                            credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 20 || isAuthor !== -1 ?
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/editar-capa/" + trilha.id}>
                                                        <Card className="card-dash">
                                                            <CardBody className="img-text-wrapper">
                                                                <div className="logo-wrapper">
                                                                    <FontAwesomeIcon icon="camera" size="3x" color="black" />
                                                                    <div className="titulo-menu-perfil">{idioma.config.bt_edit_capa}</div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                                : ""
                                            : ""}
                                    {credenciais.map(credencial => ((credencial.id_componente == 49) ?

                                        trilha.dataDesativacao == null ?
                                            <div className="col-md-2 card-menu-perfil">
                                                <Link to="#" onClick={() => this.chamarAlerta(id_trilha)}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="power-off" size="3x" color="red" />
                                                                <div className="titulo-menu-perfil">{idioma.config.bt_desativar}</div>
                                                            </div>


                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            :
                                            <div className="col-md-2 card-menu-perfil">
                                                <Link to="#" onClick={() => this.chamarAlertaAtivar(id_trilha)}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="power-off" size="3x" color="green" />
                                                                <div className="titulo-menu-perfil">{idioma.config.bt_ativar}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 60) ?
                                        <div className="col-md-2 card-menu-perfil">
                                            <Link to="#" onClick={() => this.chamarAlertaDeletar(id_trilha)}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <FontAwesomeIcon icon="trash-alt" size="3x" color="red" />
                                                            <div className="titulo-menu-perfil">{idioma.bt_deletar}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <Skeleton circle={true} height={100} width={100} />

                                                                <div className="subtitle">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <Skeleton circle={true} height={100} width={100} />

                                                                <div className="subtitle">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <Skeleton circle={true} height={100} width={100} />

                                                                <div className="subtitle">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <Skeleton circle={true} height={100} width={100} />

                                                                <div className="subtitle">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                                <div className="col-md-2 card-menu-perfil">
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <Skeleton circle={true} height={100} width={100} />

                                                                <div className="subtitle">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    trilha: state.trilhas.trilha,
    modulos: state.trilhas.modulos,

})
export default connect(mapStateToProps, actions)(Configuracoes);