import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Table, UncontrolledTooltip } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { differenceInDays } from 'date-fns';
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api } from '../../config';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Participantes extends Component {
    state = {
        id_startup: "",
        currentPage: 1,
        size: 5,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pertence: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup } = this.props.match.params;
        const { usuario } = this.props;
        this.setState({ id_startup })
        this.listarParticipantes(id_startup);
        this.listarStartup(id_startup)

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.participantes && nextProps.participantes) this.listarParticipantes(id_startup);
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparParticipantes()
        this.props.limparStartup()
    }


    receberDadosAPI() {
        if (typeof this.props.participantes != "undefined" && this.props.participantes != null && !this.state.dadosApi) {
            if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }

    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
        if (startup) {
            this.verificaIncubadora(this.props.usuario.incubadoras, startup)
        }
    }



    listarParticipantes(id_startup) {
        const { currentPage, size } = this.state;
        this.props.getParticipantes(id_startup, currentPage, size)
        const { participantes } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (participantes === "undefined") return null
        if (participantes) {
            this.handleButtons(this.props.usuario.id, participantes.usuarios)
        }
    }
    changePageAtual(currentPage) {
        const { id_startup } = this.state
        this.props.limparUsuarios();
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarParticipantes(id_startup);
        })
    }
    apagarParticipante(id_usuario) {
        this.props.limparParticipantes();
        const { id_startup } = this.state
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarParticipante(id_startup, id_usuario, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarParticipantes(id_startup);
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }
    chamarAlerta(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_deletar.titulo,
            text: idioma.modal_deletar.info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.modal_deletar.bt_cancelar,
            confirmButtonText: idioma.modal_deletar.bt_sucesso
        }).then((result) => {
            if (result.value) {
                this.apagarParticipante(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_deletar.info_sucesso
        })
    }


    verificaIncubadora(incubadoras, startup) {
        for (let i in incubadoras) {
            if (incubadoras[i].id_incubadora == startup.id_incubadora) {
                this.setState({ pertence: true })
            }
        }
    }
    handleButtons(id_usuario, usuarios) {
        for (let u in usuarios) {
            if (usuarios[u].id == id_usuario) {
                this.setState({ pertence: true })
            }
        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao.cor_secundaria
    }

    render() {
        const { id_startup, msg, carregando, erro, success, dadosApi, pertence, idioma } = this.state;

        var temPaginaAnterior = false;
        if (typeof this.props.participantes != "undefined" && this.props.participantes != null && this.props.participantes.currentPage !== "" && this.props.participantes.currentPage !== 1) {
            temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (typeof this.props.participantes != "undefined" && this.props.participantes !== null && this.props.participantes.nextPage <= this.props.participantes.totalPages && this.props.participantes.nextPage != null) {
            proximaPagina = true;
            temProximaPagina = true;
        }
        var participantes = [];
        if (this.props.participantes) participantes = this.props.participantes.usuarios;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var startup = null;
        if (this.props.startup) startup = this.props.startup;

        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.participantes.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.participantes.bt_voltar} icone="arrow-left" classes="botao-responsivo" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            {credenciais.map(credencial => ((credencial.id_componente == 42) ?
                                pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/participantes/cadastrar"} className="pr-2 botao-responsivo" key={credencial.id_componente}>
                                        <Botao texto={idioma.participantes.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                        {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline botao-responsivo btn-md" style={{ border: `1px solid ${this.props.usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                            {idioma.participantes.bt_cadastrar}
                                        </button> */}
                                    </Link>
                                    : ""
                                : ""))}
                            {credenciais.map(credencial => ((credencial.id_componente == 43) ?
                                pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/participantes/vincular"} key={credencial.id_componente}>
                                        <Botao texto={idioma.participantes.bt_vincular} classes="botao-responsivo" icone="link" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                        {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn botao-responsivo btn-outline btn-md" style={{ border: `1px solid ${this.props.usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                            {idioma.participantes.bt_vincular}
                                        </button> */}
                                    </Link>
                                    : ""
                                : ""))}

                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    {dadosApi ?
                                        <div className="row table-responsive">

                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th >{idioma.participantes.nome}</th>
                                                        <th>{idioma.participantes.perfil}</th>
                                                        <th>{idioma.participantes.acao}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {participantes.map(participante => (
                                                        <tr key={participante.id}>

                                                            <td>{participante.nome}</td>
                                                            <td>{participante.perfil != null ? participante.perfil : idioma.participantes.sem_perfil}</td>
                                                            <td>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/chat/" + participante.id} className="mr-2" >
                                                                    {/* <Link to="#" className="mr-2" > */}

                                                                    <button className="btn btn-outline-info btn-sm botao-responsivo"><FontAwesomeIcon icon="comment" className="mr-1" />{idioma.participantes.bt_conversar}</button>

                                                                </Link>
                                                                {/* {credenciais.map(credencial => ((credencial.id_componente == 44) ?

                                                                    <Link to={"/usuarios/ver/" + participante.id} className="mr-2" key={credencial.id_componente}>
                                                                        <button className="btn btn-outline-primary botao-responsivo btn-sm">Perfil</button>
                                                                    </Link>
                                                                    : ""))} */}

                                                                {credenciais.map(credencial => ((credencial.id_componente == 15) ?
                                                                    pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/editar/" + participante.id} className="mr-2" key={credencial.id_componente}>
                                                                            <button className="btn btn-outline-warning botao-responsivo btn-sm">{idioma.participantes.bt_editar}</button>
                                                                        </Link>
                                                                        : ""
                                                                    : ""))}
                                                                {credenciais.map(credencial => ((credencial.id_componente == 16) ?
                                                                    pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                                                        <span onClick={() => this.chamarAlerta(participante.id)} key={credencial.id_componente}>
                                                                            <Botao loading={carregando} texto={idioma.participantes.bt_deletar} classes="botao-responsivo" cor="danger" tamanho="sm" />
                                                                        </span>
                                                                        : ""
                                                                    : ""))}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        : <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th >{idioma.participantes.nome}</th>
                                                        <th>{idioma.participantes.perfil}</th>
                                                        <th>{idioma.participantes.acao}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>

                                                        <td>
                                                            <Skeleton count={1} width={80} />
                                                        </td>
                                                        <td>
                                                            <Skeleton count={1} width={80} />
                                                        </td>
                                                        <td>
                                                            <Skeleton count={1} width={80} />
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </div>}
                                    {/* {dadosApi ?
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >Primeira</span>
                                                </li>

                                                {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.participantes.prevPage ? this.props.participantes.prevPage : 1)}>{this.props.participantes.prevPage ? this.props.participantes.prevPage : ""}</span></li> : ""}

                                                <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.participantes ? this.props.participantes.currentPage : "1"}</span></li>

                                                {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.participantes.nextPage ? this.props.participantes.nextPage : 1)}>{this.props.participantes.nextPage ? this.props.participantes.nextPage : ""}</span></li> : ""}

                                                <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.participantes ? this.props.participantes.totalPages : 0)}>Última</span>
                                                </li>
                                            </ul>
                                        </nav>
                                        : ""} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    participantes: state.startups.participantes,
    startup: state.startups.startup,
    usuario: state.auth.usuario

})
export default connect(mapStatetoProps, actions)(Participantes);