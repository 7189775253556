import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class EditarStartup extends Component {
    state = {
        id_startup: "",
        nome: "",
        cnpj: "",
        segmento: "",
        cep: "",
        endereco: "",
        cidade: "",
        estado: "",
        siteHTTP: "",
        pitch: "",
        clientes: "",
        facebook: "",
        instagram: "",
        linkedin: "",
        twitter: "",
        erro: "",
        success: "",
        file: null,
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup } = this.props.match.params;
        this.props.getVerStartup(id_startup);

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;
        if (!this.props.startup && nextProps.startup) this.props.getVerStartup(id_startup);
        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparStartup()
    }
    receberDadosAPI() {
        const { id_startup } = this.props.match.params;
        if (typeof this.props.startup !== "undefined" && this.props.startup !== null && this.props.startup.id == id_startup && !this.state.dadosAPI) {
            this.setState({ id_startup: this.props.startup.id })
            this.setState({ nome: this.props.startup.nome })
            this.setState({ cnpj: this.props.startup.cnpj })
            this.setState({ cep: this.props.startup.cep })
            this.setState({ endereco: this.props.startup.endereco })
            this.setState({ cidade: this.props.startup.cidade })
            this.setState({ estado: this.props.startup.estado })
            this.setState({ siteHTTP: this.props.startup.site })
            this.setState({ segmento: this.props.startup.segmento })
            this.setState({ clientes: this.props.startup.clientes })
            this.setState({ facebook: this.props.startup.facebook })
            this.setState({ instagram: this.props.startup.instagram })
            this.setState({ linkedin: this.props.startup.linkedin })
            this.setState({ twitter: this.props.startup.twitter })
            this.setState({ pitch: this.props.startup.pitch })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    async atualizarStartup(id, nome, cnpj, cep, endereco, cidade, estado, siteHTTP, segmento, pitch, clientes, facebook, instagram, linkedin, twitter) {
        this.setState({ success: "" });
        var EmbedPitch = this.state.pitch.toString();
        ////////////console.log(EmbedPitch)
        var facebookURL = "https://www.facebook.com/"
        var instagramURL = "https://www.instagram.com/"
        var linkedinURL = "https://www.linkedin.com/in/"
        var twitterURL = "https://twitter.com/"

        var pitch = EmbedPitch.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/");
        var site = siteHTTP.replace("www.", "https://www.");
        if (facebook !== null) {
            if (!facebook.includes("https://www.facebook.com/") || !facebook.includes("facebook.com/") || !facebook.includes("www.facebook.com/")) {
                facebook = facebookURL + facebook
            }
        }
        if (linkedin !== null) {
            if (!linkedin.includes("https://www.linkedin.com/in/")) {
                linkedin = linkedinURL + linkedin
            }
        }
        if (instagram !== null) {
            if (!instagram.includes("https://www.instagram.com/")) {
                instagram = instagramURL + instagram
            }
        }
        if (twitter !== null) {
            if (!twitter.includes("https://twitter.com/")) {
                twitter = twitterURL + twitter
            }
        }
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        this.setState({ carregando: true })
        ////////console.log(facebook)
        this.props.putStartup({ id, nome, cnpj, cep, endereco, cidade, estado, siteHTTP, segmento, pitch, clientes, facebook, instagram, linkedin, twitter }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    receberDadosForm() {
        this.setState({ id_startup: document.querySelector("#id_startup").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ cnpj: document.querySelector("#cnpj").value })
        this.setState({ cep: document.querySelector("#cep").value })
        this.setState({ endereco: document.querySelector("#endereco").value })
        this.setState({ cidade: document.querySelector("#cidade").value })
        this.setState({ estado: document.querySelector("#estado").value })
        this.setState({ siteHTTP: document.querySelector("#siteHTTP").value })
        this.setState({ segmento: document.querySelector("#segmento").value })
        this.setState({ pitch: document.querySelector("#pitch").value })
        this.setState({ clientes: document.querySelector("#clientes").value })
        this.setState({ facebook: document.querySelector("#facebook").value })
        this.setState({ instagram: document.querySelector("#instagram").value })
        this.setState({ linkedin: document.querySelector("#linkedin").value })
        this.setState({ twitter: document.querySelector("#twitter").value })

    }
    validate() {
        const { nome, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.editar.erro_nome } });
        // if (!cep) return this.setState({ erro: { message: "Preencha o CEP" } });
        // if (!endereco) return this.setState({ erro: { message: "Preencha o endereço" } });
        // if (!cidade) return this.setState({ erro: { message: "Preencha a cidade" } });
        // if (!estado) return this.setState({ erro: { message: "Preencha a estado" } });
        // if (!segmento) return this.setState({ erro: { message: "Preencha o segmento" } });
        // if (!pitch) return this.setState({ erro: { message: "Preencha o link do pitch" } });

        return true;
    }
    render() {
        const { id_startup, nome, cnpj, cep, endereco, cidade, estado, siteHTTP, segmento, pitch, clientes, facebook, instagram, linkedin, twitter, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;
        var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (formSuccess) return <Redirect to={{
            pathname: "/ecossistema/startups/" + id_startup,
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editar.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        <BlocoPerfil dadosApi={dadosAPI} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form className="pt-2">
                                <Input type="hidden" value={id_startup} id="id_startup" name="id_startup" />
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.editar.nome}</Label>
                                            <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.editar.nome2 : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cnpj">{idioma.editar.cnpj}</Label>
                                            <Input type="text" value={cnpj} id="cnpj" name="cnpj" className="form-control" autoComplete="cnpj" placeholder={dadosAPI ? idioma.editar.cnpj : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("cnpj", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="segmento">{idioma.editar.segmento}</Label>
                                            <Input type="text" value={segmento} id="segmento" name="segmento" className="form-control" autoComplete="segmento" placeholder={dadosAPI ? idioma.editar.segmento2 : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("segmento", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cep">{idioma.editar.cep}</Label>
                                            <Input type="text" value={cep} id="cep" name="cep" className="form-control" autoComplete="cep" placeholder={dadosAPI ? idioma.editar.cep : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("cep", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="endereco">{idioma.editar.endereco}</Label>
                                            <Input type="text" value={endereco} id="endereco" name="endereco" className="form-control" autoComplete="endereco" placeholder={dadosAPI ? idioma.editar.endereco2 : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("endereco", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cidade">{idioma.editar.cidade}</Label>
                                            <Input type="text" value={cidade} id="cidade" name="cidade" className="form-control" autoComplete="cidade" placeholder={dadosAPI ? idioma.editar.cidade2 : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("cidade", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="estado">{idioma.editar.estado}</Label>
                                            <Input type="text" value={estado} id="estado" name="estado" className="form-control" autoComplete="estado" placeholder={dadosAPI ? idioma.editar.estado2 : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("estado", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="siteHTTP">{idioma.editar.site}</Label>
                                            <Input type="text" value={siteHTTP} id="siteHTTP" name="siteHTTP" className="form-control" autoComplete="siteHTTP" placeholder={dadosAPI ? idioma.editar.site2 : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("siteHTTP", ev)} />


                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="pitch">{idioma.editar.link}</Label>
                                            <Input type="text" value={pitch} id="pitch" name="pitch" className="form-control" autoComplete="pitch" placeholder={dadosAPI ? idioma.editar.link : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("pitch", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="clientes">{idioma.editar.num_clientes}</Label>
                                            <Input type="number" value={clientes} id="clientes" name="clientes" className="form-control" autoComplete="clientes" placeholder={dadosAPI ? idioma.editar.num_clientes : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("clientes", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="facebook">Facebook</Label>
                                            <Input type="text" value={facebook} id="facebook" name="facebook" className="form-control" autoComplete="facebook" placeholder={dadosAPI ? idioma.editar.face : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("facebook", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="instagram">Instagram</Label>
                                            <Input type="text" value={instagram} id="instagram" name="instagram" className="form-control" autoComplete="instagram" placeholder={dadosAPI ? idioma.editar.insta : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("instagram", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="linkedin">LinkedIn</Label>
                                            <Input type="text" value={linkedin} id="linkedin" name="linkedin" className="form-control" autoComplete="linkedin" placeholder={dadosAPI ? idioma.editar.linkedin : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("linkedin", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="twitter">Twitter</Label>
                                            <Input type="text" value={twitter} id="twitter" name="twitter" className="form-control" autoComplete="twitter" placeholder={dadosAPI ? idioma.editar.twitter : idioma.editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("twitter", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Link to="#" onClick={() => this.atualizarStartup(id_startup, nome, cnpj, cep, endereco, cidade, estado, siteHTTP, segmento, pitch, clientes, facebook, instagram, linkedin, twitter)}> <Botao loading={carregando} texto={idioma.participantes.bt_salvar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.participantes.bt_cancelar} classes="botao-responsivo botao-estilo" icone="times" posicao="E" cor="danger" /> </Link>

                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    startup: state.startups.startup

})
export default connect(mapStateToProps, actions)(EditarStartup)