import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import BotaoIcon from '../../../componentes/BotaoIcon';
import AlertaErro from '../../../componentes/Alertas/AlertaErro'
import Swal from 'sweetalert2';
import { Form, FormGroup, Input, Label, Table } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaconfig_bilingue';

class ListarVinculosRadar extends Component {
  state = {
    currentPage: 1,
    size: 20,
    trilha: "",
    msg: "",
    carregando: false,
    modal: false,
    abrir_form_vinculo: false,
    dadosApi: false,
    id_startup: '',
    msg_backend: portuguese,
    idioma: portugues
  }
  componentDidMount() {
    const { id_radar } = this.props.match.params;
    this.listarVinculosRadar(id_radar);
    this.listarIncubadoras();

    const { usuario } = this.props;

    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state });
    }

    this.setState({ id_startup: usuario.startups[0].id_startup });

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }
  componentDidUpdate(nextProps) {
    const { id_radar } = this.props.match.params;
    if (!this.props.radarVinculos && nextProps.radarVinculos) this.listarVinculosRadar(id_radar);
    if (!this.props.todasIncubadoras && nextProps.todasIncubadoras) this.listarIncubadoras();
    this.receberDadosAPI();
  }
  componentWillUnmount() {
  }
  receberDadosAPI() {
    if (typeof this.props.todasIncubadoras != "undefined" && this.props.todasIncubadoras != null &&
      typeof this.props.radarVinculos != "undefined" && this.props.radarVinculos != null && !this.state.dadosApi) {
      this.setState({ dadosApi: true });
    }
  }

  chamarAlertaExcluir(id_incubadora) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.listagemVinculosMetodologia.modal_excluir,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
      confirmButtonText: idioma.listagemVinculosMetodologia.modal_excluir_confirmar,
    }).then((result) => {
      if (result.value) {
        this.excluirVinculoRadar(id_incubadora);
      }
    })
  }

  excluirVinculoRadar(id_incubadora) {
    const { id_radar } = this.props.match.params;
    this.setState({ carregando: true })
    this.props.excluirVinculoRadar(id_incubadora, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ carregando: false });
      } else {
        this.setState({ carregando: false });
        this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
        this.listarVinculosRadar(id_radar);
      }
    })
  }


  listarVinculosRadar(id_radar) {
    this.props.getVinculosRadar(id_radar)
    const { metodologiaVinculos } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state })
      this.props.location.state = "";
    }
    if (metodologiaVinculos === "undefined") return null
  }

  listarIncubadoras() {
    this.props.getTodasIncubadoras(1, 1000)
    const { metodologiaVinculos } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state })
      this.props.location.state = "";
    }
    if (metodologiaVinculos === "undefined") return null
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  vincularRadarIncubadora(id_incubadora) {
    const { id_radar } = this.props.match.params;

    this.setState({ carregando: true })
    this.props.postVinculoRadarIncubadora({ id_incubadora, id_radarmetodologia: id_radar }, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ carregando: false });
      } else {
        this.setState({ carregando: false });
        this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
        this.setState({ abrir_form_vinculo: false });
        this.listarVinculosRadar(id_radar);
      }
    })
  }

  sucessoAlerta(msg) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: msg
    })
  }


  render() {
    const { msg, carregando, erro, dadosApi, idioma, id_incubadora, abrir_form_vinculo } = this.state;
    const { credenciais } = this.props;

    var radarVinculos = [];
    if (this.props.radarVinculos) radarVinculos = this.props.radarVinculos.incubadoras;

    var todasIncubadoras = [];
    if (this.props.todasIncubadoras) todasIncubadoras = this.props.todasIncubadoras.dados;
    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.listagemVinculosMetodologia.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              {erro && <AlertaErro erros={erro} />}
              <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
              <Link onClick={() => this.setState({ abrir_form_vinculo: !abrir_form_vinculo })} className='pr-2'>
                <Botao texto={abrir_form_vinculo ? idioma.listagemVinculosMetodologia.bt_cancelar : idioma.listagemVinculosMetodologia.bt_vincular} classes="botao-responsivo" icone={abrir_form_vinculo ? 'minus' : 'plus'} posicao="E" color="green" />
              </Link>
              {abrir_form_vinculo && (
                <div className="col-md-12 mb-2 mt-4">
                  <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <AlertaErro erros='' />
                        <Form>
                          <Input type="hidden" value='' id="id_radar" name="id_radar" />
                          <FormGroup>
                            <Label for="vinculo">{idioma.listagemVinculosMetodologia.select_incubadora}</Label>
                            <Input type="select" id="vinculo" name="id_incubadora" className="form-control" autoComplete="id_incubadora" onChange={(ev) => this.onChangeInput("id_incubadora", ev)}>
                              <option>{idioma.listagemVinculosMetodologia.select_incubadora}</option>
                              {todasIncubadoras.map((incubadora) => (
                                <option key={incubadora.id} value={incubadora.id} >{incubadora.nome}</option>
                              ))}
                            </Input>
                          </FormGroup>
                          <Link onClick={() => this.vincularRadarIncubadora(id_incubadora)} to="#">
                            <Botao loading={carregando} texto={idioma.listagemVinculosMetodologia.bt_vincular} classes="botao-responsivo botao-estilo" />
                          </Link>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-lg-12">
                <div className=" m-b-20">
                  <div className="card-body">
                    {dadosApi ?
                      <div className="row">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="coluna-tabela">{idioma.listagemMetodologia.nome}</th>
                              <th className="coluna-tabela">{idioma.listagemMetodologia.acoes}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {radarVinculos.map(radar => (
                              <tr key={radar.id}>
                                <td>{radar.nome}</td>
                                <td>
                                  <a data-tip data-for='excluir'>
                                    <Link to="#" onClick={() => this.chamarAlertaExcluir(radar.id_incubadora_radarmetodologia)} className="mr-2">
                                      <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="minus" />
                                    </Link>
                                  </a>
                                  <ReactTooltip id='excluir' type='error'>
                                    <span>{idioma.listagemVinculosMetodologia.bt_desvincular}</span>
                                  </ReactTooltip>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                      : <div className="row">
                        <Table>
                          <thead>
                            <tr>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>

                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <th>
                                <Skeleton count={1} width={80} />
                              </th>
                              <td>
                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>}
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>

      </>
    )
  }
}
const mapStatetoProps = state => ({
  radarVinculos: state.startups.radarVinculos,
  todasIncubadoras: state.startups.todasIncubadoras,
})
export default connect(mapStatetoProps, actions)(ListarVinculosRadar);