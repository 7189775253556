import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import validator from 'validator';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { GET_MODULOS } from '../../store/actions/types';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class CadastrarModulo extends Component {
    state = {
        nome: "",
        ordem: "",
        id_trilha: "",
        id_curso: "",
        erro: "",
        success: "",
        carregando: "",
        data_publicacao: "",
        formSuccess: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha })
        this.setState({ id_curso: id_trilha })
        this.listarTrilha(id_trilha)
        //////////////console.log(id_trilha)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha)
        }
        // if (!this.props.alunos && nextProps.alunos) {
        //     this.listarParticipantes(id_trilha);
        // }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparModulos()
    }

    receberDadosAPI() {
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }


    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }
    cadModulo() {
        const { nome, ordem, id_curso, data_publicacao } = this.state;
        if (!this.validate()) return;
        this.setState({ carregando: true })
        //////////////console.log(nome, ordem, id_curso)
        this.props.postModulo({ nome, ordem, id_curso, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    listar() {
        const { id_trilha } = this.state;
        this.props.getModulos(id_trilha)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (id_trilha === "undefined") return null
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }


    validate() {
        const { nome, ordem, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrarModulo.erro_nome } });
        if (!ordem) return this.setState({ erro: { message: idioma.cadastrarModulo.erro_ordem } });
        return true;
    }
    render() {
        const { nome, id_trilha, erro, success, carregando, data_publicacao, iconSalvar, formSuccess, dadosApi, idioma } = this.state;
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos",
            state: { msg: idioma.cadastrarModulo.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarModulo.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div>
                                <AlertaErro erros={erro} />
                                <AlertaSuccess erros={success} />
                                <Form>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="nome">{idioma.cadastrarMateria.nome}</Label>
                                                <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrarModulo.nome_mod} />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Label for="Ordem">{idioma.cadastrarMateria.selecione_ord}</Label>
                                            <Input type="number" onChange={(ev) => this.onChnageInput("ordem", ev)} id="ordem" name="ordem" placeholder={idioma.cadastrarModulo.ordem_mod} />
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="data_publicacao">{idioma.cadastrarAtividade.data}</Label>
                                                <Input type="date" value={data_publicacao} autoComplete="data_publicacao" onChange={(ev) => this.onChnageInput("data_publicacao", ev)} name="data_publicacao" id="data_publicacao" placeholder={idioma.cadastrarAtividade.data} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Link to="#" onClick={() => this.cadModulo()} className="mt-3">  <Botao loading={carregando} classes="botao-responsivo botao-estilo" texto={idioma.bt_salvar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                    <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    trilhas: state.trilhas.trilhas,
    modulos: state.trilhas.modulos,
    trilha: state.trilhas.trilha

})

export default connect(mapStateToProps, actions)(CadastrarModulo);