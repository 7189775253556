import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Toast } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import ProgressBar from 'react-customizable-progressbar'
import SemDados from '../../componentes/Mensagens/semMatricula';
import Titulo from '../../componentes/TituloPaginas';
import CarregandoPerfil from '../../componentes/Skeleton/CarregandoPerfil';
import CarregandoTabela from '../../componentes/Skeleton/CarregandoTabela';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import Swal from 'sweetalert2';


class ListarTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 3,
        progresso: "",
        id_usuario: "",
        id_trilha: "",
        isAuthor: false,
        isAdmin: false,
        id_modulo: "",
        id_usuario: "",
        msg: "",
        erro: "",
        success: "",
        matriculado: false,
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese,
        visibilidade: false
    }
    componentDidMount() {
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });
        const { id_trilha, id_modulo } = this.props.match.params;
        this.setState({ id_trilha: id_trilha })
        this.setState({ id_modulo: id_modulo })
        if (this.props.usuario) {
            this.setState({ id_usuario: this.props.usuario.id })
            this.listar(id_trilha, this.props.usuario.id);
            this.listarAutores(id_trilha);
        }
        this.listarTrilha(id_trilha)
        // this.listarParticipantes(id_trilha);

        this.setState({ visibilidade: this.props.trilha?.visibilidade === 'Público' })

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.modulos && nextProps.modulos) {
            this.listar(id_trilha, this.props.usuario.id);
            this.listarTrilha(id_trilha)
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparModulos()
        this.setState({ matriculado: false })
    }

    receberDadosAPI() {
        if (typeof this.props.modulos != "undefined" && this.props.modulos != null &&
            typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
            if (!this.state.visibilidade) {
                this.setState({ visibilidade: this.props.trilha.visibilidade === 'Público' })
            }
        }
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listarAutores(id_trilha) {
        this.props.getAutores(id_trilha)
        const { autores } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (autores === "undefined") return null
    }

    checarMatricula(alunos, id_usuario) {
        for (const a in alunos) {
            if (alunos[a].id == id_usuario) {
                this.setState({ matriculado: true })
            }
        }
    }

    listar(id_trilha, id_usuario) {
        this.props.getModulos(id_trilha, id_usuario)
        const { modulos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (modulos === "undefined") return null
    }

    render() {
        const { msg, erro, success, dadosApi, id_trilha, id_usuario, carregando, idioma } = this.state;
        var modulos = [];
        if (this.props.modulos) {
            modulos = this.props.modulos.dados.modulos;
        }
        var matriculado = false;
        if (this.props.modulos) matriculado = this.props.modulos.dados.matriculado;

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = null;

        if (this.props.usuario) usuario = this.props.usuario;
        var alunos = [];
        // if (this.props.alunos) alunos = this.props.alunos.usuarios;
        var path = this.props.match.path
        var isAuthor = false;
        isAuthor = (trilha?.id_autor === usuario.id)
        for (const l in trilha?.autores) {
            if (!isAuthor) isAuthor = (trilha?.autores[l].id_autor === usuario.id)
        }
        var isAdmin = (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9);
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.conteudos.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha showMenu={true} matriculado={matriculado} dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <CarregandoPerfil />
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {dadosApi ?
                                trilha?.visibilidade === 'Público' || isAuthor || isAdmin || matriculado ?
                                    <>
                                        {isAuthor || isAdmin ?
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/cadastrar"} > <Botao texto={idioma.conteudos.bt_mod} classes="botao-responsivo" icone="plus" posicao="E" cor="success" /> </Link>
                                            : ""}
                                        <div className="d-flex">
                                            <div className="col-md-12 p-2">
                                                {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                                <AlertaErro erros={erro} />
                                                <AlertaSuccess erros={success} />
                                                <div className="row table-responsive">
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>{idioma.conteudos.nome}</th>
                                                                <th>{idioma.conteudos.progre}</th>
                                                                {trilha?.visibilidade === 'Público' || isAuthor || isAdmin || matriculado ?
                                                                    <th>{idioma.conteudos.acao}</th>
                                                                    : ""}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {modulos.map(modulo => (
                                                                <tr key={modulo.modulo.id}>
                                                                    <td>{modulo.modulo.nome}</td>
                                                                    <td>
                                                                        <ProgressBar
                                                                            radius={20}
                                                                            progress={modulo.progresso}
                                                                            fillColor="#f2f2f2"
                                                                            strokeWidth={2}
                                                                            strokeColor={modulo.progresso == 100 ? "green" : "#656d78"}
                                                                            trackStrokeWidth={2}
                                                                            pointerRadius={4}
                                                                            pointerStrokeWidth={2}
                                                                            pointerStrokeColor={modulo.progresso == 100 ? "green" : "#656d78"}
                                                                        >
                                                                            <div className="indicator">
                                                                                <div>{modulo.progresso}%</div>
                                                                            </div>
                                                                        </ProgressBar>
                                                                    </td>
                                                                    {isAuthor || isAdmin || matriculado ?
                                                                        <td>
                                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + modulo.modulo.id_curso + "/modulos/" + modulo.modulo.id + "/aulas"} className="mr-2">
                                                                                <button className="btn btn-outline-primary btn-sm botao-responsivo">{idioma.conteudos.bt_visu}</button>
                                                                            </Link>
                                                                            {isAuthor || isAdmin ?
                                                                                credenciais.map(credencial => ((credencial.id_componente === 24) ?
                                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + modulo.modulo.id_curso + "/modulos/editar/" + modulo.modulo.id} key={credencial.id_componente}>
                                                                                        <button className="btn btn-outline-warning btn-sm botao-responsivo">{idioma.conteudos.bt_editar}</button>
                                                                                    </Link>
                                                                                    : ""))
                                                                                : ""}
                                                                        </td>
                                                                        :
                                                                        <td>
                                                                            {idioma.conteudos.inscreva}
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : <SemDados subtitulo={idioma.privado} titulo={idioma.conteudo_privado} />
                                : <CarregandoTabela />}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    modulos: state.trilhas.modulos,
    alunos: state.trilhas.alunos,
    trilha: state.trilhas.trilha,
    autores: state.trilhas.autores
})
export default connect(mapStatetoProps, actions)(ListarTrilhas);