export const portugues = {
  titulo: 'Perfil',
  sair: 'Sair',
  info: 'Nome',
  bt_voltar: 'Voltar',
  bt_salvar: 'Salvar',
  bt_cancelar: 'Cancelar',
  termos: 'Termos de uso',
  modal_titulo: 'Tem certeza que deseja sair?',
  modal_titulo2: 'Seu perfil foi editado com sucesso!',
  modal_img: 'Selecione a sua nova imagem de perfil',
  modal_info: 'Upload sua foto de perfil',
  modal_erro: 'O arquivo deve ser uma foto',
  modal_sucesso: 'Foto enviada',
  modal_info2: 'Sua nova foto de perfil',
  bt_confirm: 'Sim',
  politica: 'Políticas de privacidade',
  editar_perfil: {
    titulo: 'Editar perfil',
    label: 'Nome',
    erro_nome: 'Preencha o nome',
    erro_email: 'Preencha o e-mail',
    erro2_email: 'Preencha o e-mail corretamente'
  },
  editar_senha: {
    titulo: 'Editar senha',
    label: 'Senha',
    sucesso: 'Perfil editado com sucesso',
    titulo_erro: 'Preencha a senha',
    info_erro: 'A senha deve ter pelo menos 6 caracteres'
  },
  editar_foto: {
    info: 'Por favor, envie uma imagem!',
    sucesso: 'Foto de perfil editada com sucesso!',
    recomendado: 'Para que sua foto não fique distorcida, carregue uma imagem em tamanho quadrado, recomendamos no mínimo 200x200',
    titulo: 'Editar foto de perfil',
    label: 'Enviar nova foto de perfil',
    alt_img: 'Foto do perfil'
  }
}

export const ingles = {
  titulo: 'Profile',
  sair: 'Exit',
  info: 'Name',
  bt_voltar: 'Back',
  bt_salvar: 'Save',
  bt_cancelar: 'Cancel',
  termos: 'Terms of use',
  modal_titulo: 'Are you sure you want to quit?',
  modal_titulo2: 'Your profile has been successfully edited!',
  modal_img: 'Select your new profile picture',
  modal_info: 'Upload your profile picture',
  modal_erro: 'The file must be a photo',
  modal_sucesso: 'Photo sent',
  modal_info2: 'Your new profile picture',
  bt_confirm: 'Yes',
  politica: 'Privacy policies',
  editar_perfil: {
    titulo: 'Edit profile',
    label: 'Name',
    erro_nome: 'Fill a name',
    erro_email: 'Fill an e-mail',
    erro2_email: 'Fill the email correctly'
  },
  editar_senha: {
    titulo: 'Edit password',
    label: 'Password',
    sucesso: 'Profile succesfully edited',
    titulo_erro: 'Fill in password',
    info_erro: 'Password must be at least 6 characters long'
  },
  editar_foto: {
    info: 'Please, send a picture!',
    sucesso: 'Profile picture successfully edited!',
    titulo: 'Edit profile picture',
    recomendado: 'To avoid distortion, upload an image in square size, we recommend at least 200x200',
    label: 'Picture',
    alt_img: 'Profile picture'
  }
}