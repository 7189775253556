import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form, Card } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';

class CadastrarEtapa extends Component {
  state = {
    nome_etapa: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    editorState: EditorState.createEmpty(),
    msg_backend: portuguese,
    idioma: portugues,
    colunas: '',
    etapa_inicial: ''
  }
  componentDidMount() {

    const { usuario } = this.props;

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }


  validate() {
    const { nome_etapa, idioma, colunas, etapa_inicial } = this.state;
    if (!nome_etapa) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro } });
    // if (!colunas) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro_colunas } });
    if (!etapa_inicial) return this.setState({ erro: { message: idioma.cadastrarEtapa.erro_etapa } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    })
  }
  handleNovaEtapa() {
    const { nome_etapa, colunas, etapa_inicial, editorState } = this.state;
    const { id_processo, id_metodologia } = this.props.match.params;
    if (!this.validate()) return;
    this.setState({ carregando: true })
    var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.props.postNovaEtapa({ id_processo, id_metodologia, nome: nome_etapa, descricao, inicial: Number(etapa_inicial) }, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true })
      }
    }
    )
  }

  render() {
    const { carregando, erro, formSuccess, idioma, nome_etapa, editorState } = this.state;
    const { id_metodologia, id_processo } = this.props.match.params;

    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapas/listar`,
      state: idioma.cadastrarEtapa.msg_sucesso
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.cadastrarEtapa.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome_etapa', e)} value={nome_etapa} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarEtapa.nome_etapa} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="nome">{idioma.cadastrarEtapa.input_descricao}</Label>
                    <Card className="card-editor">
                      <div>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onEditorStateChange}
                        />
                      </div>
                    </Card>
                  </FormGroup>
                  {/* <FormGroup>
                    <Label for="colunas">{idioma.cadastrarEtapa.campo_colunas}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("colunas", ev)} id="colunas" name="colunas" >
                      <option>{idioma.cadastrarProcesso.colunas_placeholder}</option>
                      <option value='1'>{idioma.cadastrarProcesso.coluna_1}</option>
                      <option value='2'>{idioma.cadastrarProcesso.coluna_2}</option>
                      <option value='3'>{idioma.cadastrarProcesso.coluna_3}</option>
                      <option value='4'>{idioma.cadastrarProcesso.coluna_4}</option>
                    </Input>
                  </FormGroup> */}
                  <FormGroup>
                    <Label for="etapa_inicial">{idioma.cadastrarEtapa.etapa_inicial}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("etapa_inicial", ev)} id="etapa_inicial" name="etapa_inicial" >
                      <option>{idioma.cadastrarEtapa.etapa_placeholder}</option>
                      <option value='1'>{idioma.cadastrarEtapa.sim}</option>
                      <option value='0'>{idioma.cadastrarEtapa.nao}</option>
                    </Input>
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleNovaEtapa()}> <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  processos: state.startups.processos,
})
export default connect(mapStatetoProps, actions)(CadastrarEtapa);