import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TextTruncate from 'react-text-truncate';
import parse from 'html-react-parser'

import { ingles, portugues } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';


class ListarDesafios extends Component {
    state = {
        currentPage: 1,
        size: 3,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
        this.listar();
    }
    componentDidUpdate(nextProps) {
        if (!this.props.desafios && nextProps.desafios) this.listar();

        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparDesafios();
    }

    receberDadosAPI() {
        if (typeof this.props.desafios != "undefined" && this.props.desafios != null && !this.state.dadosApi && this.props.desafios.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listar();
        })
    }
    apagarstartup(id) {
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarstartup(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listar();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }


    listar() {
        const { currentPage, size } = this.state;
        this.props.getDesafios(currentPage, size)
        const { desafios } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (desafios === "undefined") return null
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
        ev.stopPropagation();
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao.cor_secundaria
        ev.stopPropagation();
    }

    render() {
        const { msg, carregando, erro, success, dadosApi, idioma } = this.state;
        var desafios = [];

        if (this.props.desafios) desafios = this.props.desafios.dados;
        var temPaginaAnterior = false;
        if (typeof this.props.desafios != "undefined" && this.props.desafios != null && this.props.desafios.currentPage !== "" && this.props.desafios.currentPage !== 1) {
            temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (typeof this.props.desafios != "undefined" && this.props.desafios !== null && this.props.desafios.nextPage <= this.props.desafios.totalPages && this.props.desafios.nextPage != null) {
            proximaPagina = true;
            temProximaPagina = true;
        }

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;



        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;


        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            {credenciais.map(credencial => ((credencial.id_componente === 32) ?

                                <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/cadastrar"}>
                                    <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                    {/* <button className="btn btn-outline btn-sm botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: usuario?.personalizacao?.cor_secundaria }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)}>
                                        {idioma.bt_cadastrar}
                                    </button> */}
                                </Link>
                                : ""))}

                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />

                                    {dadosApi ?
                                        <div className="row">
                                            {desafios.map(desafio => (
                                                <div className="col-md-3 col-xl-4" key={desafio.desafio.id}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/" + desafio.desafio.id + "/ver"}>
                                                        <div className="directory-card card">
                                                            <div>
                                                                <div className="directory-content text-center p-4">
                                                                    <h5 className="font-size-16 titulo-startup">{desafio.desafio.titulo}</h5>
                                                                    <div className="text-muted">
                                                                        <TextTruncate
                                                                            line={3}
                                                                            element="span"
                                                                            truncateText=" [Ler mais]"
                                                                            text={desafio.desafio.resumo ? desafio.desafio.resumo : ""}
                                                                        />
                                                                    </div>
                                                                    <p className="text-muted">{format(new Date(desafio.desafio.datahora_criacao), 'dd/MM/yyyy', { locale: pt })}</p>


                                                                </div>
                                                                <div className="  row justify-content-around pl-4 pb-3 pr-4" >
                                                                    <div className="col-md-6">
                                                                        <div className="text-center bg-secondary">
                                                                            <h3 className="text-white margin-zero">{desafio.solucoes}</h3>
                                                                            <span className="text-white">{idioma.proposta}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="text-center bg-secondary">
                                                                            <h3 className="text-white margin-zero">{desafio.aceitos}</h3>
                                                                            <span className="text-white">{idioma.selecionadas}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                        : <div className="row">
                                            <div className="col-md-3 col-xl-4">
                                                <div className="directory-card card">
                                                    <div>
                                                        <div className="directory-content text-center p-4">
                                                            <h5 className="font-size-16"><Skeleton count={1} width={60} /></h5>
                                                            <p className="text-muted"><Skeleton count={1} width={60} /></p>
                                                            <p className="text-muted"><Skeleton count={1} width={60} /></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    {dadosApi ?
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                                                </li>

                                                {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.desafios.prevPage ? this.props.desafios.prevPage : 1)}>{this.props.desafios.prevPage ? this.props.desafios.prevPage : ""}</span></li> : ""}

                                                <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.desafios ? this.props.desafios.currentPage : "1"}</span></li>

                                                {proximaPagina ? <li className="page-item cursor-pointer"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.desafios.nextPage ? this.props.desafios.nextPage : 1)}>{this.props.desafios.nextPage ? this.props.desafios.nextPage : ""}</span></li> : ""}

                                                <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.desafios ? this.props.desafios.totalPages : 0)}>{idioma.page2}</span>
                                                </li>
                                            </ul>
                                        </nav>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    desafios: state.hub.desafios,
    solucoes: state.hub.solucoes,
})
export default connect(mapStatetoProps, actions)(ListarDesafios);