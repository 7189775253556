import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link, Redirect } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfilDesafio';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pt from 'date-fns/locale/pt-BR';
import { apiImagem, apiTermoSigilo } from '../../config/';
import Skeleton from 'react-loading-skeleton';
import BootstrapTable from 'react-bootstrap-table-next';
import { format, addHours } from 'date-fns';
import parse from 'html-react-parser';
import Toast from '../../componentes/Alertas/Toast';
import ReactTooltip from 'react-tooltip';

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class VerDesafio extends Component {
  state = {
    currentPage: 1,
    size: 5,
    id_desafio: '',
    id_solucao: '',
    id_usuario: '',
    msg: '',
    erro: '',
    success: '',
    admin: false,
    botaoDeletar: false,
    botaoAlterar: false,
    botaoHTML: false,
    carregando: false,
    formFailed: false,
    modal: false,
    dadosApi: false,
    avaliador: false,
    verificado: false,
    idioma: portugues,
    msg_backend: portuguese,
  };
  componentDidMount() {
    const { id_desafio, id_solucao } = this.props.match.params;
    if (this.props.usuario) {
      this.setState({ id_usuario: this.props.usuario.id });
    }
    this.setState({ id_desafio });
    this.setState({ id_solucao });
    this.listarDesafio(id_desafio);
    this.listarSolucoes(id_desafio);
    this.listarArquivos(id_desafio);

    if (this.props.usuario) {
      this.verificaAvaliador(this.props.usuario.id, id_desafio);
      this.listarStartups(this.props.usuario.id);
    }

    // if (this.props.avaliador && !this.state.verificado) {
    //     this.verificaTermos(this.props.avaliador.dados)
    // }
    if (this.props.credenciais) this.checkAdmin(this.props.credenciais);

    const { usuario } = this.props;
    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }

  componentDidUpdate(nextProps) {
    const { id_desafio } = this.props.match.params;
    if (!this.props.desafio && nextProps.desafio) {
      this.listarDesafio(id_desafio);
    }
    if (!this.props.avaliador && nextProps.avaliador) {
      this.verificaAvaliador(this.props.usuario.id);
      // this.verificaTermos(nextProps.avaliador.dados)
    }
    if (!this.props.solucoes && nextProps.solucoes) {
      this.listarSolucoes(id_desafio);
    }
    if (this.props.avaliador && !this.state.verificado) {
      this.setState({ verificado: true });
      this.verificaTermos(this.props.avaliador.dados, this.props.desafio);
    }

    this.receberDadosAPI();
  }

  componentWillUnmount() {
    this.props.limparDesafio();
    this.props.limparSolucoes();
  }

  changePageAtual(currentPage) {
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.listarSolucoes(this.state.id_desafio);
    });
  }

  receberDadosAPI() {
    if (
      typeof this.props.desafio != 'undefined' &&
      this.props.desafio != null &&
      !this.state.dadosApi
    ) {
      if (
        typeof this.props.avaliador != 'undefined' &&
        this.props.avaliador != null &&
        !this.state.dadosApi
      ) {
        if (
          typeof this.props.solucoes != 'undefined' &&
          this.props.solucoes != null &&
          !this.state.dadosApi
        ) {
          if (
            typeof this.props.credenciais != 'undefined' &&
            this.props.credenciais != null &&
            !this.state.dadosApi
          ) {
            this.setState({ dadosApi: true });
          }
        }
      }
    }
  }

  verificaAvaliador(id_usuario, id_desafio) {
    this.props.getAvaliador(id_usuario, id_desafio);
    const { avaliador } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (avaliador === 'undefined') return null;
  }

  verificaTermos(avaliador, desafio) {
    const { id_usuario, idioma, id_desafio, admin } = this.state;
    
    if (avaliador != null && desafio !== null) {
      if (desafio?.id_autor && this.props.usuario.id !== desafio.id_autor) {
        if (!admin) {
          if (avaliador.data_aceite === null || avaliador.data_aprovacao === null) {
            if (avaliador.data_aceite == null) {
              Swal.fire({
                // title: 'Termos de uso',
                html: idioma.pagina_ver.info_termo +
                  '<br>' +
                  idioma.pagina_ver.info_arquivo +
                  '<a href="' +
                  apiTermoSigilo +
                  '" target="_blank">Termos de Sigilo</a>',
                showCancelButton: true,
                confirmButtonText: idioma.pagina_ver.bt_confirmar,
                cancelButtonText: idioma.pagina_ver.bt_recusar,
                confirmButtonColor: 'green',
                cancelButtonColor: 'red',
                input: 'file',
              }).then((result) => {
                if (result.isConfirmed) {
                  if (result.value == null) {
                    Swal.fire({
                      icon: 'error',
                      title: idioma.pagina_ver.info_erro,
                    });
                    this.setState({ verificado: true });
                    this.setState({ formFailed: true });
                  } else {
                    this.setState({ verificado: true });
                    this.aceiteAvaliador(id_usuario, result.value);
                    this.verificaAvaliador(id_usuario, id_desafio);
                  }
                }
                if (result.isDismissed) {
                  window.location = `/${this.props.usuario?.personalizacao?.titulo}/hub`
                }
              });
            } else if (avaliador.data_aceite && avaliador.data_aprovacao === null && !avaliador.fileName) {
              Swal.fire({
                html: idioma.pagina_ver.info_termo2 +
                  '<br>' +
                  idioma.pagina_ver.info_arquivo +
                  '<a href="' +
                  apiTermoSigilo +
                  '" target="_blank">Termos de Sigilo</a>',
                showCancelButton: true,
                confirmButtonText: idioma.pagina_ver.bt_confirmar,
                cancelButtonText: idioma.pagina_ver.bt_recusar,
                confirmButtonColor: 'green',
                cancelButtonColor: 'red',
                input: 'file',
              }).then((result) => {
                if (result.isConfirmed) {
                  if (result.value == null) {
                    Swal.fire({
                      icon: 'error',
                      title: idioma.pagina_ver.info_erro,
                    });
                    this.setState({ verificado: true });
                    this.setState({ formFailed: true });
                  } else {
                    this.setState({ verificado: true });
                    this.aceiteAvaliador(id_usuario, result.value);
                    this.verificaAvaliador(id_usuario, id_desafio);
                  }
                }
                if (result.isDismissed) {
                  window.location = `/${this.props.usuario?.personalizacao?.titulo}/hub`
                }
              });
            } else {
              this.alertaTermoEmAnalise();
            }
          }
        }
      }

    }
  }

  alertaTermoEmAnalise() {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.pagina_ver.info_termo_analise,
      confirmButtonColor: 'green',
      allowOutsideClick: false,
    }).then(() => {
      window.location = `/${this.props.usuario?.personalizacao?.titulo}/hub`
    })
  }

  aceiteAvaliador(id, file) {
    this.setState({ carregando: true });
    const { id_desafio } = this.state;
    // console.log(id, id_desafio, file)
    const formData = new FormData();
    formData.append('id', id);
    formData.append('id_desafio', id_desafio);
    formData.append('file', file);
    this.props.aceitarAvaliador(formData, (msg) => {
      if (msg.erro.error) {
        Toast({ icon: 'error', title: 'Erro!', text: msg.erro.message });
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
      } else {
        Toast({ icon: 'success', title: 'Sucesso!', text: msg.erro.message });
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        this.listarDesafio(id_desafio);
        this.alertaTermoEmAnalise();
      }
    });
  }

  listarArquivos(id_desafio) {
    this.props.getArquivosDesafio(id_desafio);
    const { arquivos } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (arquivos === 'undefined') return null;
  }

  listarDesafio(id_desafio) {
    this.props.getVerDesafio(id_desafio);
    const { desafio } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (desafio === 'undefined') return null;
  }

  listarStartups(id_usuario) {
    this.props.getMinhasStartups(id_usuario);
    const { startups } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (startups === 'undefined') return null;
  }

  listarSolucoes(id_desafio) {
    const { currentPage, size } = this.state;
    this.props.getSolucoes(id_desafio, currentPage, size);
    const { solucoes } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (solucoes === 'undefined') return null;
  }

  baixarEntrega(fileName) {
    window.open(apiImagem + fileName, '_blank');
  }
  //selecionar solucao

  deleteSolution(id_desafio, id_solucao) {
    this.setState({ carregando: true });
    this.props.deletarSolucao(id_solucao, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        this.props.limparSolucoes();
        this.listarSolucoes(id_desafio);
      }
    });
  }

  handleBotao(botaoDeletar) {
    if (botaoDeletar == true) {
      this.setState({ botaoDeletar: false });
    } else this.setState({ botaoDeletar: true });
  }

  handleBotaoAlterar(botaoAlterar) {
    if (botaoAlterar == true) {
      this.setState({ botaoAlterar: false });
    } else this.setState({ botaoAlterar: true });
  }

  checkAdmin(credenciais) {
    if (credenciais.length > 0) {
      if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
        this.setState({ admin: true });
      }
    }
  }

  chamarDetalhes(solucao) {
    window.open(apiImagem + solucao.fileName, '_blank');
  }

  chamarAlertaSolucao(solucao, credenciais) {
    const { id_desafio, id_solucao, idioma } = this.state;

    Swal.fire({
      title: idioma.info_selecionar,
      input: 'file',
      inputAttributes: {
        multiple: 'multiple',
      },
      showCloseButton: true,
      confirmButtonText: idioma.pagina_ver.bt_confirmar,
      showCancelButton: true,
      cancelButtonColor: 'red',
      cancelButtonText: idioma.pagina_ver.bt_recusar,
    }).then((result) => {
      if (result.isConfirmed && result.value) {
        credenciais.map(
          (credencial) =>
            credencial.id_componente == 72 &&
            this.selectSolution(id_desafio, solucao.id),
        );
        return;
      }

      if (result.isConfirmed && !result.value) {
        Swal.fire(idioma.erro_selecionar);
        return;
      }

      if (result.dismiss === Swal.DismissReason.cancel) {
        credenciais.map(
          (credencial) =>
            credencial.id_componente == 72 &&
            this.cancelSolution(solucao.id_desafio, solucao.id),
        );
        return
      }
    });
  }

  selectSolution(id_desafio, id_solucao) {
    this.setState({ carregando: true });
    this.props.postAceitarSolucao({ id_desafio, id_solucao }, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.listarSolucoes(id_desafio);
        this.setState({ formSuccess: true });
        this.props.postDesafioEmailAceite({ id_solucao, etapa: 1 }, (msg) => {
          if (msg.erro.error) {
            this.setState({
              erro: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ success: '' });
            this.setState({ carregando: false });
          } else {
            this.setState({
              success: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ erro: '' });
            this.setState({ carregando: false });
          }
        })
      }
    });
  }

  chamarAlertaCancelar(solucao, credenciais) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.pagina_ver.bt_cancelar_solucao,

      showCloseButton: true,
      confirmButtonText: idioma.pagina_ver.bt_cancelar_solucao,
    }).then((result) => {
      if (result.isConfirmed) {
        credenciais.map(
          (credencial) =>
            credencial.id_componente == 72 &&
            this.cancelSolution(solucao.id_desafio, solucao.id),
        );
      }
    });
  }

  cancelSolution(id_desafio, id_solucao) {
    this.setState({ carregando: true });
    this.props.postCancelarSolucao({ id_desafio, id_solucao }, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.listarSolucoes(id_desafio);
        this.setState({ formSuccess: true });
        this.props.postDesafioEmailRejeitar({ id_solucao, etapa: 1 }, (msg) => {
          if (msg.erro.error) {
            this.setState({
              erro: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ success: '' });
            this.setState({ carregando: false });
          } else {
            this.setState({
              success: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ erro: '' });
            this.setState({ carregando: false });
          }
        })
      }
    });
  }

  render() {
    const {
      botaoDeletar,
      formFailed,
      botaoAlterar,
      admin,
      botaoHTML,
      msg,
      carregando,
      erro,
      success,
      dadosApi,
      id_desafio,
      id_usuario,
      idioma,
    } = this.state;
    var desafio = [];
    if (this.props.desafio) desafio = this.props.desafio;
    var usuario = [];
    if (this.props.usuario) usuario = this.props.usuario;
    var startups = null;
    if (this.props.startups) startups = this.props.startups.startups;
    var credenciais = [];
    if (this.props.credenciais) credenciais = this.props.credenciais;
    var solucoes = [];
    if (this.props.solucoes) solucoes = this.props.solucoes.dados;
    var temPaginaAnterior = false;
    if (
      typeof this.props.solucoes != 'undefined' &&
      this.props.solucoes != null &&
      this.props.solucoes.currentPage !== '' &&
      this.props.solucoes.currentPage !== 1
    ) {
      temPaginaAnterior = true;
    }
    var proximaPagina = false;
    var temProximaPagina = false;
    if (
      typeof this.props.solucoes != 'undefined' &&
      this.props.solucoes !== null &&
      this.props.solucoes.nextPage <= this.props.solucoes.totalPages &&
      this.props.solucoes.nextPage != null
    ) {
      proximaPagina = true;
      temProximaPagina = true;
    }
    var arquivos = [];
    if (this.props.arquivos) arquivos = this.props.arquivos.dados;
    var path = this.props.match.path;

    var avaliador = [];
    if (this.props.avaliador) {
      avaliador = this.props.avaliador.dados;
      // this.verificaTermos(avaliador)
    }
    let columns = [];
    columns = [
      {
        dataField: 'titulo',
        text: idioma.pagina_ver.titulo_tabela,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <p>
            {row.titulo}
          </p>
        ),
      },
      {
        dataField: 'autor',
        text: idioma.pagina_ver.autor,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <p>
            {row.autor}
          </p>
        ),
      },
      {
        dataField: 'datahora_aceite',
        text: idioma.pagina_ver.etapa1,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div className="text-muted ml-1 infosecundaria">
            {row.datahora_aceite == null && row.datahora_rejeicao === null ? (
              <a data-tip data-for="analise">
                <FontAwesomeIcon icon="clock" color="orange" />
              </a>
            ) : row.datahora_rejeicao ? (
              <a data-tip data-for="reprovado">
                <FontAwesomeIcon icon="times" color="red" />
              </a>
            ) : (
              <a data-tip data-for="aprovado">
                <FontAwesomeIcon icon="check" color="green" />
              </a>
            )}
            <ReactTooltip id="analise" type="warning">
              <span>{idioma.pagina_ver.info_envios}</span>
            </ReactTooltip>
            <ReactTooltip id="aprovado" type="success">
              <span>{idioma.pagina_ver.sucesso_envios}</span>
            </ReactTooltip>
            <ReactTooltip id="reprovado" type="error">
              <span>{idioma.pagina_ver.info_aprovado}</span>
            </ReactTooltip>
          </div>
        ),
      },
      {
        dataField: 'nota_final',
        text: idioma.pagina_ver.etapa2,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <>
            <div className="text-muted ml-1 infosecundaria">
              {row.nota_final != null ? (
                row.nota_final
              ) : (
                <a data-tip data-for="analise">
                  <FontAwesomeIcon icon="clock" color="orange" />
                </a>
              )}
            </div>
            <ReactTooltip id="analise" type="warning">
              <span>{idioma.pagina_ver.info_envios}</span>
            </ReactTooltip>
          </>
        ),
      },
      {
        dataField: 'resultado',
        text: idioma.pagina_ver.aprovado,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div className="text-muted ml-1 infosecundaria">
            {row.resultado == 'Reprovado' ? (
              <a data-tip data-for="reprovado">
                <FontAwesomeIcon icon="times" color="red" />
              </a>
            ) : row.resultado == 'Aprovado' ? (
              <a data-tip data-for="aprovado">
                <FontAwesomeIcon icon="check" color="green" />
              </a>
            ) : (
              <a data-tip data-for="analise">
                <FontAwesomeIcon icon="clock" color="orange" />
              </a>
            )}
            <ReactTooltip id="analise" type="warning">
              <span>{idioma.pagina_ver.info_envios}</span>
            </ReactTooltip>
            <ReactTooltip id="aprovado" type="success">
              <span>{idioma.pagina_ver.sucesso_envios}</span>
            </ReactTooltip>
            <ReactTooltip id="reprovado" type="error">
              <span>{idioma.pagina_ver.info_aprovado}</span>
            </ReactTooltip>
          </div>
        ),
      },
      {
        dataField: 'data_criacao',
        text: idioma.pagina_ver.data,
        sort: true,
        formatter: (cell, row, rowIndex, extraData) => (
          <div className="text-muted ml-1 infosecundaria">
            {/* {row.data} */}
            {format(addHours(new Date(row.data_criacao), 3), 'dd/MM/yy', {
              locale: pt,
            })}
          </div>
        ),
      },
      admin ||
        (credenciais.length > 0 &&
          (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9))
        ? {
          dataField: '',
          text: idioma.pagina_ver.acoes,
          sort: true,
          formatter: (cell, row, rowIndex, extraData) => (
            <div className="text-muted ml-1 infosecundaria">
              {/* {row.data} */}
              <Link
                to={
                  '/' +
                  usuario?.personalizacao?.titulo +
                  '/hub/' +
                  row.id_desafio +
                  '/ver/solucao/' +
                  row.id
                }
              >
                <BotaoIcon
                  icone="eye"
                  classes="botao-responsivo btn-sm"
                  color={this.props.usuario?.personalizacao?.cor_terciaria}
                />
              </Link>
              {admin || desafio.id_autor === id_usuario ? (
                (row.datahora_aceite === null && row.datahora_rejeicao === null) ? (
                  <Link
                    onClick={() => this.chamarAlertaSolucao(row, credenciais)}
                  >
                    <BotaoIcon
                      icone="check"
                      classes="botao-responsivo btn-sm ml-3"
                      cor="success"
                    />
                  </Link>
                ) : row.datahora_aceite ? (
                  <Link
                    onClick={() => this.chamarAlertaCancelar(row, credenciais)}
                  >
                    <BotaoIcon
                      icone="times"
                      classes="botao-responsivo btn-sm ml-3"
                      cor="danger"
                    />
                  </Link>
                ) : (
                  <Link
                    onClick={() => this.chamarAlertaSolucao(row, credenciais)}
                  >
                    <BotaoIcon
                      icone="check"
                      classes="botao-responsivo btn-sm ml-3"
                      cor="success"
                    />
                  </Link>
                )
              ) : ''}
            </div>
          ),
        }
        : {
          dataField: '',
          text: 'Ações',
          sort: true,
          formatter: (cell, row, rowIndex, extraData) => (
            <div className="text-muted ml-1 infosecundaria">
              {/* {row.data} */}
              {row.datahora_aceite !== null || admin || desafio.id_autor === id_usuario ? (
                <Link
                  to={
                    '/' +
                    usuario?.personalizacao?.titulo +
                    '/hub/' +
                    row.id_desafio +
                    '/ver/solucao/' +
                    row.id
                  }
                >
                  <BotaoIcon
                    icone="eye"
                    classes="botao-responsivo btn-sm"
                    color={this.props.usuario?.personalizacao?.cor_terciaria}
                  />
                </Link>
              ) : ''}
            </div>
          ),
        },
    ];

    var rows = [];
    if (solucoes.length > 0) {
      for (let s in solucoes) {

          rows.push({
            id: solucoes[s].id,
            id_desafio: solucoes[s].id_desafio,
            id_autor: solucoes[s].id_autor,
            autor: solucoes[s].outros === null && solucoes[s].incubadora === null ? solucoes[s].startup : solucoes[s].incubadora === null && solucoes[s].startup === null ? solucoes[s].outros : solucoes[s].incubadora,
            titulo: solucoes[s].titulo,
            resumo: solucoes[s].resumo,
            data_criacao: solucoes[s].datahora_criacao,
            datahora_rejeicao: solucoes[s].datahora_rejeicao,
            datahora_aceite: solucoes[s].datahora_aceite,
            fileName: solucoes[s].fileName,
            resultado: solucoes[s].resultado,
            nota_final:
              solucoes[s].nota_final != null
                ? solucoes[s].nota_final.toFixed(2)
                : null,
          });
        
      }
    }
    if (formFailed)
      return (
        <Redirect
          to={{
            pathname: '/' + usuario?.personalizacao?.titulo + '/hub',
            state: {
              msg: 'Para acessar as informações do hub, aceite os termos!',
            },
          }}
        ></Redirect>
      );

    // console.log(credenciais)
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario.personalizacao.cor_principal,
          }}
        >
          <Titulo titulo={idioma.titulo} />
        </div>

        <div className="container-fluid bloco-topo">
          {desafio != null ? (
            <BlocoPerfil
              desafio={desafio}
              dadosApi={dadosApi}
              usuario={usuario}
              path={path}
              credenciais={credenciais}
            />
          ) : (
            <div className="col-md-12 mb-2">
              <div className="list-group-item">
                {' '}
                <div className="row">
                  <div className="col-md-12 ">
                    <h2 className="">
                      <Skeleton count={1} width={160} />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 mb-2">
            <div className="card erpcard">
              <div className="card-header card-header-large bg-header d-flex align-items-center">
                <div className="flex">
                  <h5 className="margin-zero titulo-header-card">
                    {idioma.pagina_ver.detalhes}
                  </h5>
                </div>
              </div>
              {dadosApi ? (
                <>
                  <div className="m-2">
                    <div className="row ml-2">
                      <span className="text-justify mr-2">
                        {parse('' + desafio.descricao)}
                      </span>
                    </div>
                    {arquivos.length > 0 && (
                      <div className="row ml-2">
                        <div className="text-justify ">
                          <>
                            <span className=" mr-2">
                              <strong>{idioma.pagina_ver.detalhes}:</strong>
                            </span>
                            {arquivos.map((arquivo) => (
                              <div className="row ml-2">
                                <span className="pl-2">
                                  <Link
                                    onClick={() =>
                                      this.baixarEntrega(arquivo.fileName)
                                    }
                                  >
                                    {arquivo.originalName}
                                  </Link>
                                </span>
                              </div>
                            ))}
                          </>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <div className="card erpcard">
              <div className="card-header card-header-large bg-header d-flex align-items-center">
                <div className="flex">
                  <h5 className="margin-zero titulo-header-card">
                    {idioma.pagina_ver.envios}
                  </h5>
                </div>
              </div>
              {dadosApi ? (
                <div className="d-flex">
                  <div className="container-fluid mt-2">
                    <div className="row m-2">
                      {credenciais.map((credencial) =>
                        credencial.id_componente == 73 ? (
                          <Link
                            to={
                              '/' +
                              usuario?.personalizacao?.titulo +
                              '/hub/desafio/' +
                              id_desafio +
                              '/solucao/cadastrar'
                            }
                          >
                            {' '}
                            <Botao
                              texto={idioma.pagina_ver.bt_nova_solucao}
                              classes="botao-responsivo btn-sm"
                              cor="success"
                            />{' '}
                          </Link>
                        ) : (
                          ''
                        ),
                      )}
                    </div>

                    <div className="container-fluid mt-4">
                      <div className="m-1s">
                        <BootstrapTable
                          bootstrap4
                          keyField="id"
                          data={rows}
                          columns={columns}
                          condensed
                          bordered={false}
                          wrapperClasses="table-responsive"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* {dadosApi
                ? desafio.id_autor === id_usuario ||
                admin ||
                (avaliador != null && (
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li
                        className={
                          temPaginaAnterior
                            ? 'page-item'
                            : 'page-item disabled'
                        }
                      >
                        <span
                          className="page-link cursor-pointer"
                          onClick={() => this.changePageAtual(1)}
                        >
                          {idioma.page1}
                        </span>
                      </li>

                      {temPaginaAnterior ? (
                        <li className="page-item">
                          <span
                            className="page-link cursor-pointer"
                            onClick={() =>
                              this.changePageAtual(
                                this.props.solucoes.prevPage
                                  ? this.props.solucoes.prevPage
                                  : 1,
                              )
                            }
                          >
                            {this.props.solucoes.prevPage
                              ? this.props.solucoes.prevPage
                              : ''}
                          </span>
                        </li>
                      ) : (
                        ''
                      )}

                      <li
                        className="page-item active"
                        style={{
                          backgroundColor:
                            usuario?.personalizacao?.cor_principal,
                        }}
                      >
                        <span className="page-link cursor-pointer">
                          {this.props.solucoes
                            ? this.props.solucoes.currentPage
                            : '1'}
                        </span>
                      </li>

                      {!proximaPagina ? (
                        <li className="page-item cursor-pointer">
                          <span
                            className="page-link cursor-pointer"
                            onClick={() =>
                              this.changePageAtual(
                                this.props.solucoes.nextPage
                                  ? this.props.solucoes.nextPage
                                  : 1,
                              )
                            }
                          >
                            {this.props.solucoes.nextPage
                              ? this.props.solucoes.nextPage
                              : ''}
                          </span>
                        </li>
                      ) : (
                        ''
                      )}

                      <li
                        className={
                          temProximaPagina
                            ? 'page-item'
                            : 'page-item disabled'
                        }
                      >
                        <span
                          className="page-link cursor-pointer"
                          onClick={() =>
                            this.changePageAtual(
                              this.props.solucoes
                                ? this.props.solucoes.totalPages
                                : 0,
                            )
                          }
                        >
                          {idioma.page2}
                        </span>
                      </li>
                    </ul>
                  </nav>
                ))
                : ''} */}
              {dadosApi && (
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                      <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                    </li>

                    {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.solucoes.prevPage ? this.props.solucoes.prevPage : 1)}>{this.props.solucoes.prevPage ? this.props.solucoes.prevPage : ""}</span></li> : ""}

                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.solucoes ? this.props.solucoes.currentPage : "1"}</span></li>

                    {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.solucoes.nextPage ? this.props.solucoes.nextPage : 1)}>{this.props.solucoes.nextPage ? this.props.solucoes.nextPage : ""}</span></li> : ""}

                    <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                      <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.solucoes ? this.props.solucoes.totalPages : 0)}>{idioma.page2}</span>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => ({
  desafio: state.hub.desafio,
  startups: state.hub.minhasStartups,
  solucoes: state.hub.solucoes,
  arquivos: state.hub.arquivosDesafio,
  avaliador: state.hub.avaliador,
});
export default connect(mapStatetoProps, actions)(VerDesafio);
