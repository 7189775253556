import React from 'react';
import BaseDashboard from './BaseDashboard';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Swal from 'sweetalert2'
import Toast from '../../componentes/Alertas/Toast';

import { portugues, ingles } from '../../utils/blocodashboard_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

const baseDashboard = Component => {
    class ComponentBaseDashboard extends React.Component {
        state = {
            verificado: false,
            idioma: portugues,
            msg_backend: portuguese,
        }

        componentDidMount() {
            const { authorized, getUsuario, history, usuario } = this.props;
            getUsuario();
            if (!authorized) return history.replace(`/`)

            document.title = `Plataforma ${usuario?.personalizacao?.titulo}`

            if (usuario?.idioma?.id === 3) {
                this.setState({ idioma: ingles })
                this.setState({ msg_backend: english })
            } else {
                this.setState({ idioma: portugues })
                this.setState({ msg_backend: portuguese })
            }

            const foiVerificado = localStorage.getItem('verificado');
            if (foiVerificado) {
                this.setState({ verificado: true });
            }
            this.buscarNotificacoes()
        }
        componentDidUpdate(nextProps) {
            const { authorized, history } = this.props;
            if (this.props.usuario && !this.state.verificado && !this.props.personalizacao) {
                this.verificarPermissao(this.props.usuario)
            }
            if (this.props.usuario?.idioma && !nextProps.usuario?.idioma) {
                if (this.props.usuario?.idioma?.id === 3) {
                    this.setState({ idioma: ingles })
                    this.setState({ msg_backend: english })
                } else {
                    this.setState({ idioma: portugues })
                    this.setState({ msg_backend: portuguese })
                }
            }
            document.title = `Plataforma ${this.props.usuario?.personalizacao?.titulo}`
            if (!nextProps.authorized || !authorized) return history.replace(`/`)

        }
        buscarNotificacoes() {
            const { usuario } = this.props;
            if (this.props.authorized) {
                if (usuario !== undefined) {
                    this.props.getNotificacoes(usuario.id)
                    const { notificacoes } = this.props;
                    if (this.props.location.state) {
                        this.setState({ msg: this.props.location.state.msg })
                        this.props.location.state.msg = "";
                    }
                    if (notificacoes === "undefined") return null
                } else return 0
            }
        }

        // verPersonalizacao() {
        //     const { usuario, personalizacao, getPersonalizacoesIncubadora } = this.props;

        //     if (usuario?.incubadoras.length > 0) {
        //         getPersonalizacoesIncubadora(usuario.incubadoras[0].id);
        //     } else {
        //         getPersonalizacoesIncubadora(usuario?.startups[0].id_startup);
        //     }

        // }

        verificarPermissao(usuario) {
            const { idioma } = this.state;
            let id = usuario.id
            const foiVerificado = localStorage.getItem('verificado');
            if (foiVerificado) return;
            if (usuario.data_aceite == null) {
                Swal.fire({
                    // title: 'Termos de uso',
                    html: idioma.modal_titulo + `<a href="/${this.props.usuario?.personalizacao?.titulo}/termos">` + idioma.termos + '</a>' + idioma.modal_titulo2 + `<a href="/${this.props.usuario?.personalizacao?.titulo}/politica">` + idioma.politica + '</a>' + idioma.modal_titulo3,

                    showCancelButton: true,
                    confirmButtonText: idioma.aceitar,
                    cancelButtonText: idioma.recusar,
                    confirmButtonColor: "green",
                    cancelButtonColor: "red"
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ verificado: true });
                        this.props.putPermissao({ id }, (msg) => {
                            if (msg.erro.error) {
                                Toast({ icon: 'error', title: idioma.erro, text: this.state.msg_backend[msg.erro.code] })
                                this.setState({ success: "" });
                                this.setState({ verificado: false });
                                this.setState({ carregando: false });
                            } else {
                                Toast({ icon: 'success', title: idioma.sucesso, text: this.state.msg_backend[msg.erro.code] })
                                this.setState({ erro: "" });
                                this.setState({ carregando: false });
                                this.setState({ formSuccess: true });
                                localStorage.setItem('verificado', true)
                            }
                        }
                        )
                    }
                    if (result.isDismissed) {
                        this.props.handleLogout()
                    }
                })
            }
        }

        render() {
            var credenciais = []
            if (this.props.usuario) credenciais = this.props.usuario.credenciais;
            var notificacoes = []
            if (this.props.notificacoes) notificacoes = this.props.notificacoes.dados;
            return (
                <BaseDashboard {...this.props} credenciais={credenciais} sitMenu={this.props.sitMenu} notificacoes={notificacoes}>
                    <Component {...this.props} credenciais={credenciais} sitMenu={this.props.sitMenu} />
                </BaseDashboard>
            )
        }
    }
    const mapStatetoProps = state => ({
        authorized: state.auth.authorized,
        usuario: state.auth.usuario,
        personalizacao: state.incubadoras.personalizacao,
        notificacoes: state.auth.notificacoes,
        sitMenu: state.auth.sitMenu
    })
    return connect(mapStatetoProps, actions)(ComponentBaseDashboard)
}
export default baseDashboard;