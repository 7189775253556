export const portugues = {
  titulo: 'Meu Ambiente de Inovação',
  pesquisa: 'Pesquisa...',
  bt_pesquisar: "Pesquisar",
  bt_cancelar: 'Cancelar',
  sem_dados: 'Não há incubadoras para exibir',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  page1: 'Primeira',
  page2: 'Última',
  modal_titulo: 'Você tem certeza?',
  modal_texto: 'Não será possível restaurar esse negócio!',
  bt_salvar: 'Sim, excluir este negócio!',
  modal_sucesso: 'Usuário excluído com sucesso!'
}

export const ingles = {
  titulo: 'My Innovation Space',
  pesquisa: 'Search...',
  bt_pesquisar: "Search",
  bt_cancelar: 'Cancel',
  sem_dados: 'There are no incubators to show',
  sem_dados_sub: 'What a pity! There is no information to display.',
  page1: 'First',
  page2: 'Last',
  modal_titulo: 'Are you sure?',
  modal_texto: "Won't be possible to restore this business",
  bt_salvar: 'Yes, delete this business',
  modal_sucesso: 'User successfully deleted!'
}