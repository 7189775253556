import axios from 'axios';
import {
    GET_CHATS, GET_POR_CHAT, GET_ULTIMA, GET_USERS, GET_PERFIS_ACESSO, GET_STARTUPS_ATIVAS, GET_INCUBADORAS_ATIVAS, GET_USUARIOS_INCUBADORA,
    GET_MENSAGENS, GET_PARTICIPANTES, GET_USUARIOS_PERFIL, LOGIN_USER, GET_CHATS_BUSCA,
    LIMPAR_CHATS, LIMPAR_POR_CHAT, LIMPAR_ULTIMA, LIMPAR_MENSAGENS, LIMPAR_USUARIOS_CHAT, GET_VERIFICACAO
} from './types'
import { api } from '../../config';
import { getHeaders, clearToken, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';


//GET
export const getChats = (id_usuario, page, size) => {
    return function (dispatch) {
        axios.get(api + `/chat/usuario/${id_usuario}/paginado?page=${page}&size=${size}`, getHeaders())
            .then((response) => {
                // console.log(response)
                dispatch({ type: GET_CHATS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getMensagens = (id_chat, id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/mensagens/chat/${id_chat}?id_usuario=${id_usuario}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_MENSAGENS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchChats = (id_usuario, pesquisa) => {
    return function (dispatch) {
        axios.get(api + `/chat/buscar?id_usuario=${id_usuario}&pesquisa=${pesquisa}`, getHeaders())
            .then((response) => {
                // console.log(response)
                dispatch({ type: GET_CHATS_BUSCA, payload: response })
            })
            .catch(errorHandle)
    }
}
export const getSearchChatsUser = (id_usuario, id_usuario2) => {
    return function (dispatch) {
        axios.get(api + `/chat/buscar/usuario?id_usuario=${id_usuario}&id_usuario2=${id_usuario2}`, getHeaders())
            .then((response) => {
                // console.log(response)
                dispatch({ type: GET_CHATS_BUSCA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchNameChats = (id_usuario, pesquisa) => {
    return function (dispatch) {
        axios.get(api + `/chat/buscar/nome?id_usuario=${id_usuario}&pesquisa=${pesquisa}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_CHATS_BUSCA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPorChat = (id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/mensagem/chat`, getHeaders())
            .then((response) => {
                //////console.log(response)
                dispatch({ type: GET_POR_CHAT, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUltimaMensagem = (id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/mensagem/ultimo`, getHeaders())
            .then((response) => {
                //////console.log(response)
                dispatch({ type: GET_ULTIMA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuarios = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USERS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPerfis = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/credenciais/perfis?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_PERFIS_ACESSO, payload: response })
            })
            .catch(errorHandle)
    }
}


export const getStartupsAtivas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/ativos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)

                dispatch({ type: GET_STARTUPS_ATIVAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getIncubadorasAtivas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/ativos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_INCUBADORAS_ATIVAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuariosIncubadora = (id_incubadora, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadora/${id_incubadora}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_USUARIOS_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getParticipantes = (id_startup, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id_startup}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_PARTICIPANTES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuariosPerfil = (id_perfil, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/perfil/${id_perfil}/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_USUARIOS_PERFIL, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerificaChat = (id_usuario1, id_usuario2, callback) => {
    return function (dispatch) {
        axios.get(api + `/chat/verificar?id_usuario1=${id_usuario1}&id_usuario2=${id_usuario2}`, getHeaders())
            .then((response) => {

                callback({ erro: response.data });
                dispatch({ type: GET_VERIFICACAO, payload: response })
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getChatPorNome = (id_usuario1, pesquisa) => {
    return function (dispatch) {
        axios.get(api + `/chat/buscar/nomes?id_usuario1=${id_usuario1}&pesquisa=${pesquisa}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_USERS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuario = () => {
    return function (dispatch) {
        axios.get(api + '/perfil', getHeaders())
            .then((response) => {
                dispatch({ type: LOGIN_USER, payload: response.data });
            })
            .catch(errorHandle)

    }

}


//POST
export const postChat = (dados, callback) => {
    return function (dispatch) {
        axios.post(api + `/chat/inserir`, dados, getHeadersPost())
            .then((response) => {
                //console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postChatPorNome = (dados, callback) => {
    return function (dispatch) {
        axios.post(api + `/chat/nome/inserir`, dados, getHeadersPost())
            .then((response) => {
                //console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postMensagem = (dados, callback) => {
    return function (dispatch) {
        axios.post(api + `/mensagem/inserir`, dados, getHeadersPost())
            .then((response) => {

                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postBroadcast = (dados, callback) => {
    return function (dispatch) {
        axios.post(api + `/mensagem/broadcast`, dados, getHeadersPost())
            .then((response) => {

                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const postBroadcastIncubadora = (dados, callback) => {
    return function (dispatch) {
        axios.post(api + `/mensagem/incubadora`, dados, getHeadersPost())
            .then((response) => {

                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

//PUT
export const putChat = (dados, callback) => {
    return function (dispatch) {
        axios.put(api + `/chat/alterar`, dados, getHeadersPost())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putMensagem = (dados, callback) => {
    return function (dispatch) {
        axios.put(api + `/mensagem/solucao/editar`, dados, getHeadersPost())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const desativarChat = (id_chat, id_usuario1, id_usuario2, callback) => {
    return function (dispatch) {
        axios.put(api + `/chat/${id_chat}/desativar?id_usuario1=${id_usuario1}&id_usuario2=${id_usuario2}`, id_chat, getHeadersPost())
            .then((response) => {
                //console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

//DELETE
export const deletarChat = (id, id_usuario1, id_usuario2, callback) => {
    return function (dispatch) {
        axios.delete(api + `/chat/${id}/desativar?id_usuario1=${id_usuario1}&id_usuario2=${id_usuario2}`, getHeaders())
            .then((response) => {
                // console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarMensagem = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/mensagem/${id}/deletar`, getHeaders())
            .then((response) => {
                //console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// Actions de limpar

export const limparChats = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_CHATS })
    }
}

export const limparUltima = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ULTIMA })
    }
}

export const limparPorChat = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_POR_CHAT })
    }
}

export const limparMensagens = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MENSAGENS })
    }
}

export const limparUsuarios = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_USUARIOS_CHAT })
    }
}