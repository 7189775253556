import { GET_EVENTOS, GET_EVENTO, GET_SEARCH_EVENT, LIMPAR_EVENTOS, LIMPAR_EVENTO } from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_EVENTOS:
            return {
                ...state,
                eventos: actions.payload.data
            }
        case GET_EVENTO:
            return {
                ...state,
                evento: actions.payload.dados
            }
        case GET_SEARCH_EVENT:
            return {
                ...state,
                searchEvento: actions.payload.data
            }
        case LIMPAR_EVENTOS:
            return {
                eventos: null
            }
        case LIMPAR_EVENTO:
            return {
                evento: null
            }
        default:
            return state;
    }
}