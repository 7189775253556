import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form, Card } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';

class EditarEtapa extends Component {
  state = {
    nome_etapa: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    editorState: EditorState.createEmpty(),
    msg_backend: portuguese,
    idioma: portugues,
    colunas: '',
    etapa_inicial: ''
  }
  componentDidMount() {

    const { usuario } = this.props;
    this.listarEtapa();

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }

  componentDidUpdate(nextProps) {
    if (!this.props.etapa && nextProps.etapa) this.listarEtapa();
    this.receberDadosAPI();
  }

  componentWillUnmount() {
    const { limparEtapa } = this.props;

    limparEtapa();
  }


  receberDadosAPI() {
    if (typeof this.props.etapa != "undefined" && this.props.etapa != null && !this.state.dadosApi) {
      this.setState({ nome_etapa: this.props.etapa.nome });
      this.setState({ etapa_incial: this.props.etapa.inicial });
      this.onEditorStateChangeInicial(this.props.etapa.descricao);
      this.setState({ dadosApi: true });
    }
  }

  listarEtapa() {
    const { id_etapa } = this.props.match.params;
    this.props.getVerEtapa(id_etapa)
    const { etapa } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state })
      this.props.location.state = "";
    }
    if (etapa === "undefined") return null
  }


  validate() {
    const { nome_etapa, idioma, colunas, etapa_inicial } = this.state;
    if (!nome_etapa) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro } });
    // if (!colunas) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro_colunas } });
    if (!etapa_inicial) return this.setState({ erro: { message: idioma.cadastrarEtapa.erro_etapa } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }
  onEditorStateChangeInicial = (descricao) => {
    if (descricao) {
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(descricao)
          )

        ),
      })
    }
  }

  handleEditarEtapa() {
    const { id_etapa } = this.props.match.params;
    const { nome_etapa, colunas, etapa_inicial, editorState } = this.state;
    this.setState({ carregando: true })
    var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    this.props.putEditarEtapa({ id: id_etapa, nome: nome_etapa, descricao, inicial: etapa_inicial }, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true })
      }
    }
    )
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    })
  }

  render() {
    const { carregando, erro, formSuccess, idioma, nome_etapa, editorState } = this.state;
    const { id_metodologia, id_processo } = this.props.match.params;


    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapas/listar`,
      state: idioma.editarEtapa.msg_sucesso
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.editarEtapa.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome_etapa">{idioma.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome_etapa', e)} value={nome_etapa} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarEtapa.nome_etapa} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="nome">{idioma.cadastrarEtapa.input_descricao}</Label>
                    <Card className="card-editor">
                      <div>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onEditorStateChange}
                        />
                      </div>
                    </Card>
                  </FormGroup>
                  {/* <FormGroup>
                    <Label for="colunas">{idioma.cadastrarEtapa.campo_colunas}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("colunas", ev)} id="colunas" name="colunas" >
                      <option value='1' selected={this.props.etapa?.colunas === 1} >{idioma.cadastrarProcesso.coluna_1}</option>
                      <option value='2' selected={this.props.etapa?.colunas === 2}>{idioma.cadastrarProcesso.coluna_2}</option>
                      <option value='3' selected={this.props.etapa?.colunas === 3}>{idioma.cadastrarProcesso.coluna_3}</option>
                      <option value='4' selected={this.props.etapa?.colunas === 4}>{idioma.cadastrarProcesso.coluna_4}</option>
                    </Input>
                  </FormGroup> */}
                  <FormGroup>
                    <Label for="etapa_inicial">{idioma.cadastrarEtapa.etapa_inicial}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("etapa_inicial", ev)} id="etapa_inicial" name="etapa_inicial" >
                      <option>{idioma.cadastrarEtapa.etapa_placeholder}</option>
                      <option value='1' selected={this.props.etapa?.inicial === 1}>{idioma.cadastrarEtapa.sim}</option>
                      <option value='0' selected={this.props.etapa?.inicial === 0}>{idioma.cadastrarEtapa.nao}</option>
                    </Input>
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleEditarEtapa()}> <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  etapa: state.startups.etapa,
})
export default connect(mapStatetoProps, actions)(EditarEtapa);