import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Spinner, Table, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import Titulo from '../../componentes/TituloPaginas';
import SemDados from '../../componentes/Mensagens/semDados';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

class CriarVinculoPersonalizacao extends Component {
  state = {
    id_perfil: "",
    erro: "",
    success: "",
    carregando: "",
    id_personalizacao: '',
    id_vinculo: '',
    msg_backend: portuguese,
    formSuccess: false,
    spinner: true,
    currentPage: 1,
    size: 10,
    pesquisandoIncubadora: false,
    procura: "",
    keyPress: null,
    keyDown: null,
    dadosApi: false,
    idioma: portugues
  }

  componentDidMount() {
    this.listarIncubadoras();

    const { usuario } = this.props;

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }

  }

  componentDidUpdate(nextProps) {
    if (!this.props.incubadoras && nextProps.incubadoras) this.listarIncubadoras();
    this.receberDadosAPI();
  }


  receberDadosAPI() {
    if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && !this.state.dadosApi && this.props.incubadoras.currentPage === this.state.currentPage) {
      this.setState({ dadosApi: true });
    }
  }

  listarIncubadoras() {
    const { currentPage, size } = this.state;
    const { getIncubadorasAtivas } = this.props;

    getIncubadorasAtivas(currentPage, size);
    this.setState({ spinner: false })

    const { incubadoras } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg })
      this.props.location.state.msg = "";
    }
    if (incubadoras === "undefined") return null

  }

  handleCriarVinculo(id_incubadora) {
    const { postVinculoPersonalizacao } = this.props;
    const id = this.props.match.params.id_personalizacao;

    this.setState({ carregando: true })
    const novoVinculo = {
      id_incubadora,
      id_personalizacao: id
    }

    postVinculoPersonalizacao(novoVinculo, (msg) => {
      const { msg_backend } = this.state;
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: false })
      } else {
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.listarIncubadoras(id_incubadora);
        this.setState({ formSuccess: true })
      }
    }
    )

  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  changePageAtual(currentPage) {
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.listarIncubadoras();
    })
  }

  handleSearchNomeIncubadora(procura) {
    this.setState({ procura: procura })
    this.setState({ pesquisandoIncubadora: true })
    const { currentPage, size } = this.state;
    this.props.getSearchIncubadoras(procura, currentPage, size)
  }

  eraseSearchStartup(campo) {
    this.setState({ pesquisandoIncubadora: false })
    this.setState({ [campo]: "" })

  }

  handleEnter(ev) {
    const { procura } = this.state;
    if (ev.key === 'Enter') {
      this.handleSearchNomeIncubadora(procura)
    }
  }

  render() {
    const { erro, success, carregando, spinner, pesquisandoIncubadora, procura, idioma } = this.state;
    const { personalizacao, usuario, searchIncubadoras } = this.props;


    let dados_incubadoras = []
    if (this.props.incubadoras) dados_incubadoras = this.props.incubadoras
    const id = this.props.match.params.id_personalizacao;

    if (pesquisandoIncubadora === false) {
      if (this.props.incubadoras) dados_incubadoras = this.props.incubadoras
      var temPaginaAnterior = false;
      if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && this.props.incubadoras.currentPage !== "" && this.props.incubadoras.currentPage !== 1) {
        temPaginaAnterior = true;
      }
      var proximaPagina = false;
      var temProximaPagina = false;
      if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras !== null && this.props.incubadoras.nextPage <= this.props.incubadoras.totalPages && this.props.incubadoras.nextPage != null) {
        proximaPagina = true;
        temProximaPagina = true;
      }

    } else {
      if (this.props.searchIncubadoras) dados_incubadoras = this.props.searchIncubadoras; 
      var temPaginaAnteriorStartup = false;
      if (typeof this.props.searchIncubadoras != "undefined" && this.props.searchIncubadoras != null && this.props.searchIncubadoras.currentPage !== "" && this.props.searchIncubadoras.currentPage !== 1) {
        temPaginaAnteriorStartup = true;
      }
      var proximaPaginaIncubadora = false;
      var temPaginaAnteriorIncubadora = false;
      if (typeof this.props.searchIncubadoras != "undefined" && this.props.searchIncubadoras !== null && this.props.searchIncubadoras.nextPage <= this.props.searchIncubadoras.totalPages && this.props.searchIncubadoras.nextPage != null) {
        proximaPaginaIncubadora = true;
        temPaginaAnteriorIncubadora = true;
      }
    }

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario?.personalizacao.cor_principal }} >
          {/* <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal}}> */}
          <Titulo titulo={idioma.criarVinculoPersonalizacao.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <AlertaErro erros={erro} />
              <AlertaSuccess erros={success} />
              <div className="row">
                <div className="col-lg-8">
                  <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.criarVinculoPersonalizacao.pesquisar} />
                </div>
                <div className="col-lg-4">
                  <div>
                    <span className="pr-3" onClick={() => this.handleSearchNomeIncubadora(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.criarVinculoPersonalizacao.pesquisa} icone="search" posicao="E" /></span>
                    <span value={procura} onClick={() => this.eraseSearchStartup("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" cor="danger" texto={idioma.novaPersonalizacao.bt_cancelar} icone="times" posicao="E" /></span>
                  </div>
                </div>
              </div>
              <>
                <div className='list-group bloco-card'>
                  <Link to={`/${usuario?.personalizacao?.titulo}/personalizacao/${id}/editar`} > <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" posicao="E" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
                </div>
                {spinner ? (
                  <Table>
                    <thead>
                      <th >{idioma.personalizacao.incubadora}</th>
                      <th>{idioma.personalizacao.acoes}</th>
                    </thead>
                    <div className="row d-flex justify-content-center mt-5">
                      <Spinner />
                    </div>
                  </Table>
                ) : (
                  <Table>
                    <tbody>
                      {dados_incubadoras?.dados?.length !== 0 ? dados_incubadoras?.dados?.map((incub, index) => (
                        <tr key={index}>
                          <td>{incub?.nome}</td>
                          <td>
                            <Link to='#' onClick={() => this.handleCriarVinculo(incub.id)}>
                              <Botao loading={carregando} texto={idioma.criarVinculoPersonalizacao.bt_vincular} color={this.props.usuario?.personalizacao.cor_terciaria} />
                            </Link>
                          </td>
                        </tr>
                      )) :
                        <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.criarVinculoPersonalizacao.sem_dados} />
                      }
                    </tbody>
                  </Table>
                )}
              </>
              {dados_incubadoras?.length !== 0 ?
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                      <span className="page-link" onClick={() => this.changePageAtual(1)} >{idioma.criarVinculoPersonalizacao.page1}</span>
                    </li>

                    {temPaginaAnterior ? <li className="page-item"><span className="page-link" onClick={() => this.changePageAtual(this.props.incubadoras.prevPage ? this.props.incubadoras.prevPage : 1)}>{this.props.incubadoras.prevPage ? this.props.incubadoras.prevPage : ""}</span></li> : ""}

                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link">{this.props.incubadoras ? this.props.incubadoras.currentPage : "1"}</span></li>

                    {proximaPagina ? <li className="page-item"><span className="page-link" onClick={() => this.changePageAtual(this.props.incubadoras.nextPage ? this.props.incubadoras.nextPage : 1)}>{this.props.incubadoras.nextPage ? this.props.incubadoras.nextPage : ""}</span></li> : ""}

                    <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                      <span className="page-link" onClick={() => this.changePageAtual(this.props.incubadoras ? this.props.incubadoras.totalPages : 0)}>{idioma.criarVinculoPersonalizacao.page2}</span>
                    </li>
                  </ul>
                </nav>
                : ""}
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  usuario: state.auth.usuario,
  incubadoras: state.incubadoras.incubadorasAtivas,
  searchIncubadoras: state.incubadoras.searchIncubadorasEcossistema,
  personalizacao: state.incubadoras.personalizacaoIncubadora
})

export default connect(mapStateToProps, actions)(CriarVinculoPersonalizacao);