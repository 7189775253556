import React, { Component } from 'react';
import { Form, Input, InputGroup, InputGroupAddon, InputGroupText, Alert, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import Botao from '../../componentes/Botoes'
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { env, apiImagem } from '../../config/';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { portugues, ingles } from '../../utils/paginalogin_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
library.add(fas);

class Login extends Component {
    state = {
        email: "",
        senha: "",
        erro: "",
        msg: "",
        carregando: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { personalizacao } = this.props.match.params;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg });
            this.props.location.state.msg = "";
        }

        if (personalizacao) {
            document.title = `Plataforma ${personalizacao.toUpperCase()}`
        }

        const idiomaLocal = localStorage.getItem('idioma');
        if (idiomaLocal) {
            if (idiomaLocal === 'ingles') {
                this.setState({ idioma: ingles })
                this.setState({ msg_backend: english })
            } else {
                this.setState({ idioma: portugues })
                this.setState({ msg_backend: portuguese })
            }
        }

        // this.getPersonalizacao(personalizacao);
    }
    // componentWillUnmount() {
    //     const { limparPersonalizacaoUsuario } = this.props;

    //     limparPersonalizacaoUsuario();
    // }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value })
    }

    // getPersonalizacao(nome) {
    //     if (!nome) return;

    //     const { getPersonalizacaoUsuario, usuarioPersonalizacao } = this.props;
    //     getPersonalizacaoUsuario(nome);
    // }

    handleLogin() {
        this.setState({ msg: "" });
        this.setState({ erro: "" });
        const { email, senha } = this.state;
        if (!this.validate()) return;
        this.setState({ carregando: true });
        this.props.handleLogin({ email, senha }, (msg) => {
            if (msg.erro.code) {
                ////////console.log(msg.erro.error)
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });

            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            }
        })
    }

    validate() {
        const { email, senha, idioma } = this.state;
        if (!email) return this.setState({ erro: { message: idioma.erro_email } })
        if (!senha) return this.setState({ erro: { message: idioma.erro_senha } })
        return true;
    }

    handleLanguage(tipo, ev) {
        ev.preventDefault();
        if (tipo === 'ingles') {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
            localStorage.setItem('idioma', 'ingles')
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
            localStorage.setItem('idioma', 'portugues')
        }
    }

    render() {
        const { email, senha, erro, msg, carregando, idioma } = this.state;
        const { usuarioPersonalizacao } = this.props;
        const { personalizacao } = this.props.match.params;
        return (
            <>
                <div className="tela-login container-fluid d-flex flex-column">
                    <div className="row" style={{ height: '98vh' }}>
                        <div className="col-md-3 col-sm-12 sem-padding">
                            <div className="login">

                                <Form className="form-signin text-center">
                                    <img className="mb-4" src={usuarioPersonalizacao?.data ? `${apiImagem}${usuarioPersonalizacao?.data?.dados[0]?.logo_dark_arquivo}` : "imagens/logo_cebbi.png"} alt="Plataforma" width="100%" style={{ maxHeight: "300px" }} />
                                    <div style={{ display: 'flex', marginBottom: '30px', justifyContent: 'center' }}>
                                        <button onClick={(e) => this.handleLanguage('portugues', e)} style={{ marginRight: '10px', border: `${idioma === portugues ? '2px solid gray' : 'none'}`, borderRadius: '2px', backgroundColor: 'transparent' }}>
                                            <img src='/imagens/brazil-flag.png' alt='Bandeira Brasil' style={{ width: '30px', height: 'auto' }} />
                                        </button>
                                        <button onClick={(e) => this.handleLanguage('ingles', e)} style={{ border: `${idioma === ingles ? '2px solid gray' : 'none'}`, borderRadius: '2px', backgroundColor: 'transparent' }}>
                                            <img src='/imagens/usa-flag.png' alt='Bandeira EUA' style={{ width: '30px', height: 'auto' }} />
                                        </button>
                                    </div>
                                    <h2 className="h5 mb-3 font-weight-normal">{idioma.title}</h2>
                                    {env === "hom" ?
                                        <Alert color="danger">
                                            ATENÇÃO! Você está no ambiente de Homologação
                                        </Alert>
                                        : ""}
                                    <AlertaErro erros={erro} />
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon="user" /></InputGroupText></InputGroupAddon>
                                        <Input type="email" value={email} name="email" id="email" placeholder="E-mail" onChange={(ev) => this.onChangeInput("email", ev)} />
                                    </InputGroup>
                                    <br />
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon="lock" /></InputGroupText></InputGroupAddon>
                                        <Input type="password" value={senha} name="senha" id="senha" placeholder={idioma.senha} onChange={(ev) => this.onChangeInput("senha", ev)} />
                                    </InputGroup>
                                    <br />
                                    <Link to="#" onClick={() => this.handleLogin()}> <Botao loading={carregando} texto={idioma.bt_acesso} cor='success' tamanho="md" posicao="D" classes="btn-block" icone="sign-in-alt" /> </Link>
                                    <p className="text-center mt-2"><Link to={`/${personalizacao}/recuperar-senha`}>{idioma.esqueceu_senha}</Link></p>
                                </Form>
                            </div>
                        </div>
                        <div className="col-md-9 fundo-img-login">
                            <img className="img-fluid w-100" src={usuarioPersonalizacao ? `${apiImagem}${usuarioPersonalizacao?.data?.dados[0]?.banner_arquivo}` : "imagens/fundo_cebbi.png"} alt="Plataforma" style={{ minHeight: '100%' }} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = state => ({
    mensagem: state.auth.mensagem,
    usuarioPersonalizacao: state.usuarios.usuarioPersonalizacao
})

export default connect(mapStatetoProps, actions)(Login);
