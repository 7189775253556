import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Col, Row, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import Swal from 'sweetalert2';
import BlocoPerfil from '../../containers/blocos/perfil';
import { PieChart } from '../../componentes/Graficos/Consultas'
import RadarArea from '../../componentes/Graficos/ConsultasArea'
import { ResponsivePie } from '@nivo/pie'
import Skeleton from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginarelatorios_bilingue';



class RadarResumo extends Component {
    state = {
        id_processo: "",
        id_metodologia: "",
        id_incubadora: "",
        msg: "",
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        ativo: false,
        dadosApi: true,
        idioma: portugues
    }

    componentDidMount() {
        const { usuario } = this.props;
        // Recupera os params da url para usar na classe
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
        } else {
            this.setState({ idioma: portugues })
        }

        this.listarIncubadoras(this.props.usuario.id)
        // this.listarMetodologias()
        // this.listarRelatorio()
        // this.listarRelatorioDesafios()
        // this.listarRelatoriosMentorias()
        // this.listarRelatoriosDesenvolvimento()
        // this.listarRelatoriosConteudos()
        // this.listarRelatoriosIncubadora()
        // this.listarRelatoriosMetodologia()
        // this.listarRelatoriosStartups()



    }

    componentDidUpdate(nextProps) {
        if (!this.props.incubadoras && nextProps.incubadoras) {
            this.listarMinhasIncubadoras(this.props.usuario.id)
        }
        // if (!this.props.metodologias && nextProps.metodologias) {
        //     this.listarMetodologias(this.props.usuario.id)
        // }
        // if (!this.props.relatorios && nextProps.relatorios) {
        //     this.listarRelatorios()
        // }
        // if (!this.props.desafios && nextProps.desafios) {
        //     this.listarRelatoriosDesafios()
        // }
        // if (!this.props.mentorias && nextProps.mentorias) {
        //     this.listarRelatoriosMentorias()
        // }
        // if (!this.props.relatoriosDesenvolvimento && nextProps.relatoriosDesenvolvimento) {
        //     this.listarRelatoriosDesenvolvimento()
        // }
        // if (!this.props.relatoriosConteudos && nextProps.relatoriosConteudos) {
        //     this.listarRelatoriosConteudos()
        // }
        // if (!this.props.relatoriosIncubadora && nextProps.relatoriosIncubadora) {
        //     this.listarRelatoriosIncubadora()
        // }
        // if (!this.props.relatoriosMetodologia && nextProps.relatoriosMetodologia) {
        //     this.listarRelatoriosMetodologia()
        // }
        // if (!this.props.relatoriosStartups && nextProps.relatoriosStartups) {
        //     this.listarRelatoriosStartups()
        // }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        // this.props.limparPerguntas()
        // this.props.limparRespostas()
        // this.props.limparStartup()

    }

    receberDadosAPI() {
        if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && !this.state.dadosApi) {

            if (typeof this.props.metodologias != "undefined" && this.props.metodologias != null && !this.state.dadosApi) {

                if (typeof this.props.desafios != "undefined" && this.props.desafios != null && !this.state.dadosApi) {

                    if (typeof this.props.mentorias != "undefined" && this.props.mentorias != null && !this.state.dadosApi) {

                        if (typeof this.props.relatoriosDesenvolvimento != "undefined" && this.props.relatoriosDesenvolvimento != null && !this.state.dadosApi) {

                            if (typeof this.props.relatoriosConteudos != "undefined" && this.props.relatoriosConteudos != null && !this.state.dadosApi) {

                                if (typeof this.props.relatoriosIncubadora != "undefined" && this.props.relatoriosIncubadora != null && !this.state.dadosApi) {

                                    if (typeof this.props.relatoriosMetodologia != "undefined" && this.props.relatoriosMetodologia != null && !this.state.dadosApi) {

                                        if (typeof this.props.relatoriosStartups != "undefined" && this.props.relatoriosStartups != null && !this.state.dadosApi) {
                                            this.setState({ dadosApi: true });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value.toString() }) 
    }

    handleMetodologia(ev) {
        this.setState({ id_metodologia: ev.target.value })
    }

    listarIncubadoras(id_usuario) {
        this.props.getMinhasIncubadoras(id_usuario);
        const { incubadoras } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadoras === "undefined") return null
    }

    listarMetodologias() {
        this.props.getMetodologias();
        const { metodologias } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (metodologias === "undefined") return null
    }




    listarRelatorioDesafios(id_incub) {
        const { id_incubadora } = this.state 
        this.props.getRelatorioDesafios(id_incub);
        const { desafios } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (desafios === "undefined") return null
    }


    listarRelatoriosMentorias() {
        const { id_incubadora } = this.state
        this.props.getRelatorioMentorias(id_incubadora);
        const { mentorias } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (mentorias === "undefined") return null
    }


    listarRelatoriosDesenvolvimento() {
        const { id_incubadora } = this.state
        this.props.getRelatorioDesenvolvimento(id_incubadora);
        const { relatoriosDesenvolvimento } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (relatoriosDesenvolvimento === "undefined") return null
    }
    listarRelatoriosConteudos() {
        const { id_incubadora } = this.state
        this.props.getRelatorioConteudos(id_incubadora);
        const { relatoriosConteudos } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (relatoriosConteudos === "undefined") return null
    }
    listarRelatoriosIncubadora() {
        const { id_incubadora } = this.state
        this.props.getRelatorioIncubadora(id_incubadora);
        const { relatoriosIncubadora } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (relatoriosIncubadora === "undefined") return null
    }
    listarRelatoriosMetodologia() {
        const { id_incubadora } = this.state
        this.props.getRelatorioMetodologia(id_incubadora);
        const { relatoriosMetodologia } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (relatoriosMetodologia === "undefined") return null
    }
    listarRelatoriosStartups() {
        const { id_incubadora } = this.state
        this.props.getRelatorioStartups(id_incubadora);
        const { relatoriosStartups } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (relatoriosStartups === "undefined") return null
    }
    setarAtivo() {
        this.setState({ ativo: true })
    }
    render() {
        // Form names
        const { id_metodologia, id_incubadora, id_processo, dadosApi, carregando, ativo, idioma } = this.state;

        var incubadoras = [];
        var metodologias = [];

        var incubadoras = [];
        if (this.props.incubadoras) incubadoras = this.props.incubadoras.incubadoras;

        if (this.props.metodologias) metodologias = this.props.metodologias.dados;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;

        var relatorios = []
        if (this.props.relatorios) relatorios = this.props.relatorios;

        var desafios = []
        if (this.props.desafios) desafios = this.props.desafios;

        var mentorias = []
        if (this.props.mentorias) mentorias = this.props.mentorias;

        var relatoriosDesenvolvimento = []
        if (this.props.relatoriosDesenvolvimento) relatoriosDesenvolvimento = this.props.relatoriosDesenvolvimento.dados;

        var relatoriosConteudos = []
        if (this.props.relatoriosConteudos) relatoriosConteudos = this.props.relatoriosConteudos;

        var relatoriosIncubadora = []
        if (this.props.relatoriosIncubadora) relatoriosIncubadora = this.props.relatoriosIncubadora.dados;

        var relatoriosMetodologia = []
        if (this.props.relatoriosMetodologia) relatoriosMetodologia = this.props.relatoriosMetodologia.dados;
        ////console.log(relatoriosMetodologia)
        var relatoriosStartups = []
        if (this.props.relatoriosStartups) relatoriosStartups = this.props.relatoriosStartups.dados;

        var path = this.props.match.path

        const dataDesafios = [
            {
                "id": "Total",
                "label": idioma.total,
                "value": desafios.totalDesafios,
                "color": "hsl(58, 70%, 50%)"
            },
            {
                "id": "Resolvidos",
                "label": idioma.resolvidos,
                "value": desafios.totalResolvidos,
                "color": "hsl(336, 70%, 50%)"
            },
            {
                "id": "Soluções",
                "label": idioma.solucoes,
                "value": desafios.totalSolucoes,
                "color": "hsl(100, 70%, 50%)"
            },
        ]



        const dataMentorias = [
            {
                "id": "Evidências",
                "label": idioma.evidencias,
                "value": mentorias.evidencias,
                "color": "hsl(58, 70%, 50%)"
            },
            {
                "id": "Mentorias",
                "label": idioma.mentorias,
                "value": mentorias.mentorias,
                "color": "hsl(336, 70%, 50%)"
            },
        ]

        const dataConteudosStartup = [
            {
                "id": "Atividades",
                "label": idioma.atv,
                "value": relatoriosConteudos.startupsAtividades,
                "color": "hsl(58, 70%, 50%)"
            },
            {
                "id": "Aulas",
                "label": idioma.aulas,
                "value": relatoriosConteudos.startupsAulas,
                "color": "hsl(336, 70%, 50%)"
            },
        ]

        const dataConteudosIncubadora = [
            {
                "id": "Atividades",
                "label": idioma.atv,
                "value": relatoriosConteudos.incubadoraAtividades,
                "color": "hsl(58, 70%, 50%)"
            },
            {
                "id": "Aulas",
                "label": idioma.aulas,
                "value": relatoriosConteudos.incubadoraAulas,
                "color": "hsl(336, 70%, 50%)"
            },
        ]


        const dataIncubadora = []

        for (let p in relatoriosIncubadora.pilares) {
            dataIncubadora.push({
                "id": relatoriosIncubadora.pilares[p].id,
                "label": relatoriosIncubadora.pilares[p].nome,
                "value": relatoriosIncubadora.pilares[p].media,
                "color": "hsl(58, 70%, 50%)"
            })
        }



        ////console.log(relatoriosMetodologia)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link to="#" onClick={() => this.props.history.goBack()} > <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            <div>
                                <div className="col-lg-12">
                                    <FormGroup>
                                        <Row>
                                            <Col md={8}>
                                                <Label for="id_incubadora"></Label>
                                                <Input type="select" onChange={(ev) => this.onChangeInput("id_incubadora", ev)} id="id_incubadora" name="id_incubadora">
                                                    <option>{idioma.label_select}</option>
                                                    {incubadoras.map(incubadora => (
                                                        <option value={incubadora.id} key={incubadora.id}>
                                                            {incubadora.nome}
                                                        </option>))}
                                                </Input>
                                            </Col>

                                            <Col md={3} className="mt-4">
                                                <span onClick={() => {
                                                    this.listarRelatorioDesafios(id_incubadora)
                                                    this.listarRelatoriosMentorias()
                                                    this.listarRelatoriosDesenvolvimento()
                                                    this.listarRelatoriosConteudos()
                                                    this.listarRelatoriosIncubadora()
                                                    this.listarRelatoriosMetodologia()
                                                    this.listarRelatoriosStartups()
                                                    this.setarAtivo()
                                                }} ><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_gerar} icone="filter" posicao="E" /></span>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                    {dadosApi && ativo == true ?
                        <div className={ativo ? "rel-ativado" : "rel-desativado"}>
                            <div className="col-md-12 mb-2">
                                <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                    <p className="text-center"><strong>{idioma.metodologia}</strong></p>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th className="coluna-tabela">ID</th>
                                                <th className="coluna-tabela">{idioma.nome}</th>
                                                <th className="coluna-tabela">{idioma.media}</th>
                                                {relatoriosMetodologia?.processos ?
                                                    relatoriosMetodologia?.processos.map(processo =>
                                                        <th>{processo.nome}</th>
                                                    )
                                                    : ""}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {relatoriosMetodologia?.lista ?
                                                relatoriosMetodologia?.lista.map(relatorio => (
                                                    <tr>
                                                        <td>{relatorio.id}</td>
                                                        <td>{relatorio.nome}</td>
                                                        <td>{relatorio.media}</td>

                                                        {relatorio.processos.map(processo => (
                                                            <td>{processo.media}</td>
                                                        ))}
                                                    </tr>
                                                ))
                                                : ""}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.hub}</strong></p>
                                            <PieChart data={dataDesafios} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.mentorias}</strong></p>
                                            <PieChart data={dataMentorias} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="col-md-12 mb-2">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <p className="text-center"><strong>{idioma.desenv}</strong></p>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th className="coluna-tabela">ID</th>
                                                    <th className="coluna-tabela">{idioma.nome}</th>
                                                    <th className="coluna-tabela">{idioma.entregas}</th>
                                                    <th className="coluna-tabela">{idioma.evidencias}</th>
                                                    <th className="coluna-tabela">{idioma.aceitas}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {relatoriosDesenvolvimento?.length > 0 ?
                                                    relatoriosDesenvolvimento.map(relatorio => (
                                                        <tr>
                                                            <td>{relatorio.id}</td>
                                                            <td>{relatorio.nome}</td>
                                                            <td>{relatorio.qtdeEntregas}</td>
                                                            <td>{relatorio.evidencias}</td>
                                                            <td>{relatorio.aceitas}</td>
                                                        </tr>
                                                    ))
                                                    : ""}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.geral}</strong></p>
                                            <p className="text-center geral"><strong>{relatoriosIncubadora.geral == null ? 0 : relatoriosIncubadora.geral}</strong></p>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.pilares}</strong></p>
                                            <PieChart data={dataIncubadora} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="col-md-12 mb-2">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <p className="text-center"><strong>{idioma.negocios}</strong></p>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th className="coluna-tabela">ID</th>
                                                    <th className="coluna-tabela">{idioma.nome}</th>
                                                    <th className="coluna-tabela">{idioma.segmento}</th>
                                                    <th className="coluna-tabela">{idioma.media}</th>
                                                    <th className="coluna-tabela">{idioma.faturamento}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {relatoriosStartups?.length > 0 ?
                                                    relatoriosStartups.map(relatorio => (
                                                        <tr>
                                                            <td>{relatorio.id}</td>
                                                            <td>{relatorio.nome}</td>
                                                            <td>{relatorio.segmento}</td>
                                                            <td>{relatorio.media}</td>
                                                            <td>{relatorio.faturamento}</td>
                                                        </tr>
                                                    ))
                                                    : ""}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.conteudos}</strong></p>
                                            <PieChart data={dataConteudosStartup} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.conteudos2}</strong></p>
                                            <PieChart data={dataConteudosIncubadora} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={"rel-desativado"}>
                            <div className="col-md-12 mb-2">
                                <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                    <p className="text-center"><strong>{idioma.metodologia}</strong></p>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th className="coluna-tabela">
                                                    <Skeleton count={1} width={80} />
                                                </th>
                                                <th className="coluna-tabela">
                                                    <Skeleton count={1} width={80} />
                                                </th>
                                                <th className="coluna-tabela">
                                                    <Skeleton count={1} width={80} />
                                                </th>
                                                <th>
                                                    <Skeleton count={1} width={80} />
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Skeleton count={1} width={80} />
                                                </td>
                                                <td>
                                                    <Skeleton count={1} width={80} />
                                                </td>
                                                <td>
                                                    <Skeleton count={1} width={80} />
                                                </td>
                                                <td>
                                                    <Skeleton count={1} width={80} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 ">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.hub}</strong></p>
                                            <Skeleton rectangle={true} width={80} height={80} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.mentorias}</strong></p>
                                            <Skeleton rectangle={true} width={80} height={80} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="col-md-12 mb-2">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <p className="text-center"><strong>{idioma.desenv}</strong></p>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.geral}</strong></p>
                                            <Skeleton rectangle={true} width={80} height={80} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.pilares}</strong></p>
                                            <Skeleton rectangle={true} width={80} height={80} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="col-md-12 mb-2">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <p className="text-center"><strong>{idioma.negocios}</strong></p>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                    <th className="coluna-tabela">
                                                        <Skeleton count={1} width={80} />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                    <td>
                                                        <Skeleton count={1} width={80} />
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.conteudos}</strong></p>
                                            <Skeleton rectangle={true} width={80} height={80} />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="list-group-item App p-4">
                                            <p className="text-center"><strong>{idioma.conteudos2}</strong></p>
                                            <Skeleton rectangle={true} width={80} height={80} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    incubadoras: state.startups.minhasIncubadoras,
    metodologias: state.startups.todasMetodologias,
    relatorios: state.startups.relatorios,
    desafios: state.startups.desafios,
    mentorias: state.startups.mentorias,
    relatoriosDesenvolvimento: state.startups.relatoriosDesenvolvimento,
    relatoriosConteudos: state.startups.relatoriosConteudos,
    relatoriosIncubadora: state.startups.relatoriosIncubadora,
    relatoriosMetodologia: state.startups.relatoriosMetodologia,
    relatoriosStartups: state.startups.relatoriosStartups,
})

export default connect(mapStateToProps, actions)(RadarResumo);