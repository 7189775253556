import React from 'react';
import BaseLogin from './BaseLogin';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index'

const baseLogin = Component => {
    class ComponentBaseLogin extends React.Component {
        state = {
            nome_personalizacao: '',
            urlRedirect: ''
        }
        componentDidMount() {
            const { personalizacao } = this.props.match.params;
            const { getUsuario, history } = this.props;
            getUsuario();
            localStorage.setItem('verificado', '');
            const urlPerso = localStorage.getItem('urlPerso');
            const perso = sessionStorage.getItem('url-perso');
            if (history.location.pathname === `/${personalizacao}/login` || history.location.pathname === `/` || history.location.pathname === `/${personalizacao}`) {
                if (perso) {
                    this.getPersonalizacao(perso)
                    return history.push(`/${perso}/login`)
                } else if (urlPerso && !personalizacao) {
                    this.getPersonalizacao(urlPerso)
                    return history.push(`/${urlPerso}/login`)
                } else if (personalizacao) {
                    this.getPersonalizacao(personalizacao)
                    return history.push(`/${personalizacao}/login`)
                } else {
                    return history.push('/cebbicaatinga/login');
                }
            }

        }
        componentDidUpdate(nextProps) {
            const { personalizacao } = this.props.match.params;
            const { history, authorized, getUsuario } = this.props;
            getUsuario();
            if (authorized) return history.push(`/${personalizacao}/conteudos`)
        }
        getPersonalizacao(nome) {
            if (!nome) return;

            const { getPersonalizacaoUsuario } = this.props;
            getPersonalizacaoUsuario(nome);
        }
        render() {
            return (
                <BaseLogin>
                    <Component {...this.props} />
                </BaseLogin>

            )
        }
    }
    const mapStatetoProps = state => ({
        authorized: state.auth.authorized,
        usuario: state.auth.usuario,
        personalizacao: state.usuarios.usuarioIncubadora,
        usuarioPersonalizacao: state.usuarios.usuarioPersonalizacao
    })
    return connect(mapStatetoProps, actions)(ComponentBaseLogin)
}
export default baseLogin;