import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input, Button, Table } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Titulo from '../../componentes/TituloPaginas';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

class ListarEcossistema extends Component {
    state = {
        currentPage: 1,
        size: 20,
        incubadora: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pesquisandoIncubadora: false,
        procura: "",
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        this.listarIncubadoras();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.incubadoras && nextProps.incubadoras) this.listarIncubadoras();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparIncubadora();
        this.props.limparIncubadoras();
    }
    receberDadosAPI() {
        if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && !this.state.dadosApi && this.props.incubadoras.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarIncubadoras();
        })
    }

    //ativar incubadora
    ativarIncubadora(id) {
        const { msg_backend } = this.state
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.ativarIncubadora(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.sucessoAlertaAtivar();
                this.listarIncubadoras()
                // this.setState({ formSuccess: true });
            }
        })
    }
    //desativar incubadora
    desativarIncubadora(id) {
        const { msg_backend } = this.state;
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.desativarIncubadora(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.sucessoAlerta();
                this.listarIncubadoras()
                // this.setState({ formSuccess: true });
            }
        })
    }

    chamarAlertaAtivar(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemIncubadoras.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.ativarIncubadora(id);
            }
        })
    }
    sucessoAlertaAtivar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemIncubadoras.modal_sucesso
        })
    }


    chamarAlerta(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemIncubadoras.modal_confirm2
        }).then((result) => {
            if (result.value) {
                this.desativarIncubadora(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemIncubadoras.modal_sucesso2
        })
    }

    //deletar incubadora
    deletandoIncubadora(id_incubadora) {
        const { msg_backend } = this.state;
        this.setState({ carregando: true })
        this.props.deletarIncubadora(id_incubadora, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
                this.props.limparIncubadoras();
                this.listarIncubadoras();


            }
        })
    }

    chamarAlertaDeletar(id_incubadora) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo2,
            html: idioma.listagemIncubadoras.modal_info,
            input: 'text',
            inputPlaceholder: idioma.listagemIncubadoras.modal_confirm3,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.listagemIncubadoras.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.listagemIncubadoras.confirm) {
                this.deletandoIncubadora(id_incubadora);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.listagemIncubadoras.erro_msg
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemIncubadoras.modal_sucesso3
        })
    }
    listarIncubadoras() {
        const { currentPage, size } = this.state;
        this.props.getIncubadoras(currentPage, size)
        const { incubadoras } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadoras === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleSearchNomeIncubadora(procura) {
        this.setState({ procura: procura })
        const { currentPage, size } = this.state;
        this.setState({ pesquisandoIncubadora: true })
        this.props.getSearchIncubadoras(procura, currentPage, size)
    }

    eraseSearchIncubadora(campo) {
        this.setState({ pesquisandoIncubadora: false })
        this.setState({ [campo]: "" })
        this.listarIncubadoras()


    }

    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            this.handleSearchNomeIncubadora(procura)

        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao?.cor_secundaria
    }

    render() {
        const { incubadora, msg, carregando, erro, success, dadosApi, pesquisandoIncubadora, procura, idioma } = this.state;
        const { usuario } = this.props;

        var incubadoras = [];

        if (pesquisandoIncubadora == false) {

            if (this.props.incubadoras) incubadoras = this.props.incubadoras.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && this.props.incubadoras.currentPage !== "" && this.props.incubadoras.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras !== null && this.props.incubadoras.nextPage <= this.props.incubadoras.totalPages && this.props.incubadoras.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        } else {
            if (this.props.searchIncubadora) incubadoras = this.props.searchIncubadora.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.searchIncubadora != "undefined" && this.props.searchIncubadora != null && this.props.searchIncubadora.currentPage !== "" && this.props.searchIncubadora.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.searchIncubadora != "undefined" && this.props.searchIncubadora !== null && this.props.searchIncubadora.nextPage <= this.props.searchIncubadora.totalPages && this.props.searchIncubadora.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        }
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        // ////console.log(incubadoras)
        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.listagemIncubadoras.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/cadastrar"}>
                                <Botao texto={idioma.listagemIncubadoras.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                    {idioma.listagemIncubadoras.bt_cadastrar}
                                </button> */}
                            </Link>
                            <div className="row mt-4">
                                <div className="col-lg-8">
                                    <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.criarVinculoPersonalizacao.pesquisar} />
                                </div>
                                <div className="col-lg-4">
                                    <span className="pr-3" onClick={() => this.handleSearchNomeIncubadora(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="botao-pesquisa-responsivo" texto={idioma.criarVinculoPersonalizacao.pesquisa} icone="search" posicao="E" /></span>
                                    <span value={procura} onClick={() => this.eraseSearchIncubadora("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" classes="botao-pesquisa-responsivo" texto={idioma.criarVinculoPersonalizacao.bt_cancelar} icone="times" posicao="E" /></span>
                                </div>
                            </div>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th className="coluna-tabela">{idioma.listagemIncubadoras.nome}</th>
                                                        <th className="coluna-tabela">{idioma.listagemIncubadoras.segmento}</th>
                                                        <th className="coluna-tabela">{idioma.listagemIncubadoras.cidade}</th>
                                                        <th className="coluna-tabela">{idioma.listagemIncubadoras.status}</th>
                                                        <th className="coluna-tabela">{idioma.listagemIncubadoras.acao}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {incubadoras.map(incubadora => (
                                                        <tr key={incubadora.id}>

                                                            <td>{incubadora.nome}</td>
                                                            <td>{incubadora.segmento}</td>
                                                            <td>{incubadora.cidade}</td>
                                                            {incubadora.dataDesativacao == null ?
                                                                <td><span className="badge badge-pill badge-success mb-1">{idioma.listagemIncubadoras.atv}</span></td>
                                                                : <td><span className="badge badge-pill badge-danger mb-1">{idioma.listagemIncubadoras.desatv}</span></td>}

                                                            <td>
                                                                <a data-tip data-for='cadastrar'>
                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadora/" + incubadora.id + "/startups/cadastrar"} className="mr-2">
                                                                        <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="plus" />

                                                                    </Link>
                                                                </a>
                                                                <a data-tip data-for='participantes'>
                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadora/" + incubadora.id + "/usuarios/"} className="mr-2">
                                                                        <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="user-friends" />

                                                                    </Link>
                                                                </a>
                                                                <a data-tip data-for='perfil'>
                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id} className="mr-2">
                                                                        <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="eye" />
                                                                    </Link>
                                                                </a>
                                                                <a data-tip data-for='editar'>
                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id + "/editar"} className="mr-2">
                                                                        <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="pencil-alt" />
                                                                    </Link>
                                                                </a>
                                                                {incubadora.dataDesativacao == null ?
                                                                    <a data-tip data-for='desativar'>
                                                                        <Link to="#" onClick={() => this.chamarAlerta(incubadora.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="ban" />
                                                                        </Link>
                                                                    </a>
                                                                    :
                                                                    <a data-tip data-for='ativar'>
                                                                        <Link to="#" onClick={() => this.chamarAlertaAtivar(incubadora.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-success btn-sm ml-1" icone="check" />
                                                                        </Link>
                                                                    </a>
                                                                }
                                                                {credenciais.map(credencial => ((credencial.id_componente == 46) ?
                                                                    <a data-tip data-for='deletar'>
                                                                        <Link to="#" onClick={() => this.chamarAlertaDeletar(incubadora.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    : ""))}


                                                                <ReactTooltip id='perfil' type='dark'>
                                                                    <span>{idioma.listagemIncubadoras.perfil}</span>
                                                                </ReactTooltip>
                                                                <ReactTooltip id='participantes' type='dark'>
                                                                    <span>{idioma.listagemIncubadoras.participantes}</span>
                                                                </ReactTooltip>
                                                                <ReactTooltip id='cadastrar' type='dark'>
                                                                    <span>{idioma.listagemIncubadoras.bt_cadastrar_neg}</span>
                                                                </ReactTooltip>
                                                                <ReactTooltip id='editar' type='dark'>
                                                                    <span>{idioma.listagemIncubadoras.bt_editar}</span>
                                                                </ReactTooltip>
                                                                <ReactTooltip id='desativar' type='error'>
                                                                    <span>{idioma.listagemIncubadoras.bt_desativar}</span>
                                                                </ReactTooltip>
                                                                <ReactTooltip id='ativar' type='success'>
                                                                    <span>{idioma.listagemIncubadoras.ativar}</span>
                                                                </ReactTooltip>
                                                                <ReactTooltip id='deletar' type='error'>
                                                                    <span>{idioma.listagemIncubadoras.bt_deletar}</span>
                                                                </ReactTooltip>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                            {dadosApi ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.criarVinculoPersonalizacao.page1}</span>
                                        </li>

                                        {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadoras.prevPage ? this.props.incubadoras.prevPage : 1)}>{this.props.incubadoras.prevPage ? this.props.incubadoras.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }} ><span className="page-link cursor-pointer">{this.props.incubadoras ? this.props.incubadoras.currentPage : "1"}</span></li>

                                        {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadoras.nextPage ? this.props.incubadoras.nextPage : 1)}>{this.props.incubadoras.nextPage ? this.props.incubadoras.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadoras ? this.props.incubadoras.totalPages : 0)}>{idioma.criarVinculoPersonalizacao.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                        </div>

                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    incubadoras: state.incubadoras.incubadoras,
    searchIncubadora: state.incubadoras.searchIncubadorasEcossistema,


})
export default connect(mapStatetoProps, actions)(ListarEcossistema);