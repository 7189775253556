import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class CadastrarTrilha extends Component {
    state = {
        nome: "",
        previa: "",
        descricao: "",
        id_autor: "",
        id_trilha: "",
        tipo_campo: "",
        previa: "",
        erro: "",
        success: "",
        carregando: "",
        data_publicacao: "",
        formSuccess: false,
        editorState: EditorState.createEmpty(),
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        if (this.props.usuario) this.setState({ id_autor: this.props.usuario.id })
        ///////////console.log(this.props.usuario.id)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onEditorStateChangeInicial = (content) => {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(content)
                )

            ),
        })
    }

    cadTrilha() {
        const { nome, editorState, id_autor, tipo_campo, previa, data_publicacao } = this.state;
        var visibilidade = tipo_campo;
        var descricao = editorState;
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.postTrilha({ nome, descricao, visibilidade, id_autor, previa, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ id_trilha: msg.erro.id_trilha });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { nome, data_publicacao, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrar.erro_nome } });
        if (!data_publicacao) return this.setState({ erro: { message: idioma.cadastrar.erro_data } });
        return true;
    }
    render() {
        const { nome, previa, descricao, id_trilha, id_autor, editorState, erro, success, data_publicacao, carregando, iconSalvar, formSuccess, idioma } = this.state;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos",
            state: { msg: idioma.cadastrar.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrar.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.cadastrar.nome}</Label>
                                            <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChangeInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrar.nome2} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label for="tipo_campo">{idioma.cadastrar.visibilidade}</Label>
                                        <Input type="select" onChange={(ev) => this.onChangeInput("tipo_campo", ev)} id="tipo_campo" name="tipo_campo" >
                                            <option>{idioma.cadastrar.escolher}</option>
                                            <option value="Público">
                                                {idioma.cadastrar.publico}
                                            </option>
                                            <option value="Privado">
                                                {idioma.cadastrar.privado}
                                            </option>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8} className="pb-2">
                                        <Label for="previa">{idioma.cadastrar.previa}</Label>
                                        <Input type="textarea" value={previa} autoComplete="previa" onChange={(ev) => this.onChangeInput("previa", ev)} name="previa" id="previa" placeholder={idioma.cadastrar.previa} />

                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="data_publicacao">{idioma.cadastrar.data}</Label>
                                            <Input type="date" value={data_publicacao} autoComplete="data_publicacao" onChange={(ev) => this.onChangeInput("data_publicacao", ev)} name="data_publicacao" id="data_publicacao" placeholder={idioma.cadastrar.data} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.cadastrar.desc}</Label>
                                            <Card className="card-editor">
                                                <div id='editor'>
                                                    <ReactQuill
                                                        theme='snow'
                                                        value={editorState}
                                                        bounds={'.app'}
                                                        onChange={
                                                            (content) => {
                                                                this.onEditorStateChange(content)
                                                            }}
                                                        modules={{
                                                            toolbar: {
                                                                container: [
                                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                    ['bold', 'italic', 'underline'],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    [{ 'align': [] }],
                                                                    ['link', 'image'],
                                                                    ['clean'],
                                                                    [{ 'color': [] }]
                                                                ],
                                                            },
                                                        }}
                                                    />
                                                    {/* <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            /> */}
                                                </div>
                                            </Card>
                                            {/* <Card className="card-editor">
                                                <div>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}

                                                    />
                                                </div>
                                            </Card> */}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="hidden" value={id_autor} id="id_autor" name="id_autor" />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Link to="#" onClick={() => this.cadTrilha()}> <Botao loading={carregando} texto={idioma.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarios: state.auth.usuarios,
    trilhas: state.trilhas.trilhas
})

export default connect(mapStateToProps, actions)(CadastrarTrilha);