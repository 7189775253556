export const portugues = {
  titulo: 'Ecossistema de ambientes de inovação e negócios',
  menu1: 'Ambiente de Inovação',
  menu2: 'Negócios',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  pesquisa_input: 'Pesquisar...',
  bt_pesquisa: 'Pesquisar',
  bt_cancelar: 'Cancelar',
  bt_pagina1: 'Primeira',
  bt_pagina2: 'Última',
  modal_titulo: 'Você tem certeza?',
  modal_info: 'Não será possível restaurar esse ambiente de inovação!',
  modal_confirm: ' Sim, excluir este ambiente de inovação!',
  modal_sucesso: 'Ambiente de inovação excluída com sucesso!',
  page1: 'Primeira',
  page2: 'Última',
  acompanhamento: {
    bt_voltar: 'Voltar',
    titulo: 'Acompanhamento',
    nome: 'Nome',
    nome2: 'Nome completo',
    segmento: 'Segmento',
    segmento2: 'Segmento de atuação',
    cidade: 'Cidade',
    estado: 'Estado',
    cnpj: 'CNPJ',
    cep: 'CEP',
    endereco: 'Endereço',
    redes: 'Redes',
    site: 'Site',
    site2: 'Site do ambiente de inovação',
    site_acesso: 'Acesse o site'
  },
  cadastrar: {
    erro_nome: 'Preencha o nome do ambiente de inovação',
    msg_sucesso: 'Ambiente de inovação cadastrado com sucesso!',
    titulo: 'Cadastrar ambiente de inovação',
    img: 'Imagem do ambiente de inovação',
    estado: 'Estado',
    link: 'Link do Pitch',
    num_incub: 'Número de negócios',
    bt_cadastrar: 'Cadastrar'
  },
  cadastrarStartup: {
    erro_nome: 'Preencha o nome',
    erro_cidade: 'Preencha a cidade',
    msg_sucesso: 'Negócio cadastrada com sucesso!',
    titulo: 'Cadastrar negócio',
    num_clientes: 'Número de clientes',
    link2: 'Link do Pitch (apenas Youtube)',
    erro_acesso: 'Acesso negado!'
  },
  cadastrarUsuario: {
    erro_nome: 'Preencha o nome',
    erro_idioma: 'Selecione um idioma',
    erro_email: 'Preencha o e-mail',
    erro_email2: 'Preencha um e-mail válido',
    erro_senha: 'Preencha a senha',
    erro_senha2: 'Preencha a senha com no mínimo 6 caracteres',
    msg_sucesso: 'Usuário cadastrado com sucesso!',
    titulo: 'Adicionar usuário',
    idioma: 'Idioma',
    portugues: 'Português',
    ingles: 'Inglês',
    email: 'E-mail',
    senha: 'Senha'
  },
  editar: {
    titulo: 'Editar incubadora',
    nome: 'Nome do ambiente de inovação',
    carregando: 'Carregando...',
    segmento2: 'Segmento do ambiente de inovação',
    endereco2: 'Endereço do ambiente de inovação',
    cidade2: 'Cidade do ambiente de inovação',
    estado2: 'Estado do ambinete de inovação',
    face: 'Facebook do ambiente de inovação',
    insta: 'Instagram do ambiente de inovação',
    linkedin: 'Linkedin do ambiente de inovação',
    twitter: 'Twitter do ambiente de inovação',
    bt_salvar: 'Salvar'
  },
  editarCapa: {
    msg_sucesso: 'Capa alterada com sucesso!',
    erro_img: 'Envie uma imagem',
    titulo: 'Editar capa de fundo',
    foto_capa: 'Foto de capa'
  },
  editarImagem: {
    titulo: 'Editar foto do perfil',
    foto_perfil: 'Foto de perfil'
  },
  incubadora: {
    modal_titulo: 'Escolha a Jornada de Desenvolvimento desejada:',
    modal_place: 'Escolha a Jornada de Desenvolvimento',
    modal_confirm: 'Vincular Jornada de Desenvolvimento'
  },
  usuarios: {
    modal_info: 'Não será possível restaurar esse usuário!',
    modal_confirm: 'Sim, excluir este usuário!',
    modal_sucesso: 'Usuário excluído com sucesso!',
    titulo: 'Participantes',
    bt_vincular: 'Vincular participante',
    nome: 'Nome',
    perfil: 'Perfil',
    acao: 'Ação',
    erro_perfil: 'Não possui perfil',
    conversar: 'Conversar',
    edit: 'Editar',
    del: 'Deletar'
  },
  vincularusuario: {
    modal_confirm: 'Sim, vincular este usuário!',
    modal_sucesso: 'Usuário vinculado com sucesso',
    msg_sucesso: 'Usuário vinculado com sucesso!',
    titulo: 'Vincular usuários',
    pesquisar: 'Pesquisar...',
    bt_pesquisa: 'Pesquisar',
    negocio: 'Negócio',
    bt_vincular: 'Vincular'
  }
}

export const ingles = {
  titulo: 'Innovation and business environment ecosystem',
  menu1: 'Innovation space',
  menu2: 'Business',
  sem_dados_sub: 'What a pity! There is no information to display.',
  pesquisa_input: 'Search...',
  bt_pesquisa: 'Search',
  bt_cancelar: 'Cancel',
  bt_pagina1: 'First',
  bt_pagina2: 'Last',
  modal_titulo: 'Are you sure',
  modal_info: "Won't be possible to restore this innovation space",
  modal_confirm: 'Yes, delete this innovation space!',
  modal_sucesso: 'Innovation space successfully deleted!',
  page1: 'First',
  page2: 'Last',
  acompanhamento: {
    bt_voltar: 'Back',
    titulo: 'Monitoring',
    nome: 'Name',
    nome2: 'Full name',
    segmento: 'Segment',
    segmento2: 'Business segment',
    cidade: 'City',
    estado: 'State',
    cnpj: 'CNPJ',
    cep: 'Postal code',
    endereco: 'Address',
    redes: 'Social medias',
    site: 'Website',
    site2: 'Innovation space website',
    site_acesso: 'Access the website'
  },
  cadastrar: {
    erro_nome: 'Fill in the name of the innovation space',
    msg_sucesso: 'Innovation space successfully registered',
    titulo: 'Register innovation space',
    img: 'Image of the innovation space',
    estado: 'State',
    link: 'Pitch link',
    num_incub: 'number of incubators',
    bt_cadastrar: 'Register'
  },
  cadastrarStartup: {
    erro_nome: 'Name must be filled',
    erro_cidade: 'City must be filled',
    msg_sucesso: 'Business successfully registered!',
    titulo: 'Register business',
    num_clientes: 'Number of customers',
    link2: 'Pitch link (only Youtube)',
    erro_acesso: 'Access denied!'
  },
  cadastrarUsuario: {
    erro_nome: 'Name must be filled',
    erro_idioma: 'Select a language',
    erro_email: 'E-mail must be filled',
    erro_email2: 'Fill in a valid email',
    erro_senha: 'Preencha a senha',
    erro_senha2: 'Password must be at least 6 characters long',
    msg_sucesso: 'User successfully registered!',
    titulo: 'Add user',
    idioma: 'Language',
    portugues: 'Portuguese',
    ingles: 'English',
    email: 'E-mail',
    senha: 'Password'
  },
  editar: {
    titulo: 'Edit incubator',
    nome: 'Innovation space name',
    carregando: 'Loading...',
    segmento2: 'Innovation space segment',
    endereco2: 'Innovation space address',
    cidade2: 'Innovation space city',
    estado2: 'Innovation space state',
    face: 'Innovation space Facebook',
    insta: 'Innovation space Instagram',
    linkedin: 'Innovation space Linkedin',
    twitter: 'Innovation space Twitter',
    bt_salvar: 'Save'
  },
  editarCapa: {
    msg_sucesso: 'Cover successfully changed!',
    erro_img: 'Send a picture',
    titulo: 'Edit background cover',
    foto_capa: 'Cover photo'
  },
  editarImagem: {
    titulo: 'Edit profile picture',
    foto_perfil: 'Profile picture'
  },
  incubadora: {
    modal_titulo: 'Choose the desire methodology:',
    modal_place: 'Choose methodology',
    modal_confirm: 'Link methodology'
  },
  usuarios: {
    modal_info: "Won't be possible to restore this user!",
    modal_confirm: 'Yes, delete this user!',
    modal_sucesso: 'User successfully deleted!',
    titulo: 'Participants',
    bt_vincular: 'Link participant',
    nome: 'Name',
    perfil: 'Profile',
    acao: 'Option',
    erro_perfil: 'Does not have a profile',
    conversar: 'Chat',
    edit: 'Edit',
    del: 'Delete'
  },
  vincularusuario: {
    modal_confirm: 'Yes, link this user!',
    modal_sucesso: 'User successfully linked',
    msg_sucesso: 'Business successfully registered!',
    titulo: 'Link users',
    pesquisar: 'Search...',
    bt_pesquisa: 'Search',
    negocio: 'Business',
    bt_vincular: 'Link'
  }
}