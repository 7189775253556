export const portugues = {
  bt_salvar: 'Salvar',
  bt_cancelar: 'Cancelar',
  bt_excluir: 'Excluir',
  bt_editar: 'Editar',
  bt_ver: 'Ver',
  bt_voltar: 'Voltar',
  bt_cadastrar: 'Cadastrar',
  nome: 'Nome',
  acoes: 'Ações',
  data_alt: 'Data de alteração',
  data_cri: 'Data de criação',
  modal_del: 'Sim, excluir esse processo',
  erro: 'Erro',
  cadastrarMetodologia: {
    titulo: 'Cadastrar nova jornada de desenvolvimento',
    nome: 'Nome da Jornada',
    placeholder: 'Nome da jornada de desenvolvimento',
    cadastrar: 'Cadastrar nova jornada de desenvolvimento',
    msg_sucesso: 'Metodologia cadastrada com sucesso',
    colunas: 'Colunas',
    colunas_placeholder: 'Escolha a quantidade',
    coluna_1: '1 card por linha (Cada card ocupando a tela toda)',
    coluna_2: '2 cards por linha (Cada card ocupando 50% da tela)',
    coluna_3: '3 cards por linha (Cada card ocupando 33% da tela)',
    coluna_4: '4 cards por linha (Cada card ocupando 25% da tela)',
    campo_colunas: 'Quantidade de cards por linha (Capa de cada processo)',
  },
  editarMetodologia: {
    titulo: 'Editar jornada de desenvolvimento',
    msg_sucesso: 'Metodologia editada com sucesso!',
    colunas: 'Colunas',
    colunas_placeholder: 'Escolha a quantidade',
    coluna_1: '1 card por linha (Cada card ocupando a tela toda)',
    coluna_2: '2 cards por linha (Cada card ocupando 50% da tela)',
    coluna_3: '3 cards por linha (Cada card ocupando 33% da tela)',
    coluna_4: '4 cards por linha (Cada card ocupando 25% da tela)',
    campo_colunas: 'Exibição dos processos (cards dentro da jornada de desenvolvimento)',
  },
  listagemProcessos: {
    titulo: 'Listagem de processos',
    colunas: 'Colunas'
  },
  cadastrarProcesso: {
    titulo: 'Cadastrar novo processo',
    msg_sucesso: 'Processo cadastrado com sucesso',
    input_place: 'Nome do processo',
    erro: 'O nome precisa ser preenchido',
    campo_colunas: 'Exibição dos módulos (cards dentro do processo)',
    colunas_placeholder: 'Escolha a quantidade',
    coluna_1: '1 card por linha (Cada card ocupando a tela toda)',
    coluna_2: '2 cards por linha (Cada card ocupando 50% da tela)',
    coluna_3: '3 cards por linha (Cada card ocupando 33% da tela)',
    coluna_4: '4 cards por linha (Cada card ocupando 25% da tela)',
    erro_colunas: 'Selecione a quantidade de colunas'
  },
  editarProcesso: {
    titulo: 'Editar processo',
    msg_sucesso: 'Processo alterado com sucesso'
  },
  listagemEtapas: {
    titulo: 'Listagem de módulos',
    modal_del: 'Sim, excluir esse módulo',
    modal_titulo: 'Digite o nome do módulo',
    modal_titulo2: 'Editar módulo:',
    bt_editar: 'Editar',
  },
  cadastrarEtapa: {
    titulo: 'Cadastrar novo módulo',
    campo_colunas: 'Exibição dos estagios (cards dentro da módulo)',
    msg_sucesso: 'Módulo cadastrado com sucesso',
    nome_etapa: 'Nome do módulo',
    input_descricao: 'Descrição do módulo',
    etapa_placeholder: 'Selecione uma opção',
    etapa_inicial: 'Esse módulo será utilizada como radar inicial?',
    etapa_inicial1: 'Radar inicial',
    sim1: 'Sim',
    sim: 'Sim (Não poderá conter atividades dentro deste módulo)',
    nao: 'Não',
    erro_etapa: 'Escolha se o módulo será incial'
  },
  editarEtapa: {
    titulo: 'Editar módulo',
    msg_sucesso: 'Módulo editado com sucesso!'
  },
  listagemEstagio: {
    titulo: 'Listagem de atividades',
    modal_del: 'Sim, excluir essa atividade',
    modal_titulo: 'Digite o nome da atividade',
    modal_titulo2: 'Editar atividade:',
    bt_editar: 'Editar',
  },
  cadastrarEstagio: {
    titulo: 'Cadastrar nova atividade',
    msg_sucesso: 'Atividade cadastrado com sucesso',
    nome_estagio: 'Nome da atividade',
    input_descricao: 'Descrição da atividade',
  },
  editarEstagio: {
    titulo: 'Editar atividade',
    msg_sucesso: 'Atividade editada com sucesso!'
  },
  listagemEntregas: {
    titulo: 'Listagem de entregas',
    modal_del: 'Sim, excluir essa entrega',
    modal_titulo: 'Digite o nome da entrega',
    modal_titulo2: 'Editar entrega:',
    bt_editar: 'Editar',
  },
  cadastrarEntrega: {
    titulo: 'Cadastrar nova entrega',
    nome_placeholder: 'Nome da entrega',
    desc: 'Descrição',
    msg_sucesso: 'Entrega cadastrada com sucesso',
    tipo: 'Tipo',
    tipo_placeholder: 'Escolher o tipo',
    erro_tipo: 'O tipo precisa ser preenchido',
    erro_desc: 'A descrição precisa ser preenchida',
    img: 'Imagem',
    vid: 'Vídeo',
    text: 'Texto',
    arq: 'Arquivo',
    pdf: 'PDF',
    todos: 'Deixar o usuário escolher',
    sem_campo: 'Escolha do usuário'
  },
  editarEntrega: {
    titulo: 'Editar entrega',
    msg_sucesso: 'Entrega alterada com sucesso'
  },
}

export const ingles = {
  bt_salvar: 'Save',
  bt_cancelar: 'Cancel',
  bt_excluir: 'Delete',
  bt_editar: 'Edit',
  bt_ver: 'View',
  bt_voltar: 'Back',
  bt_cadastrar: 'Register',
  nome: 'Name',
  acoes: 'Options',
  data_alt: 'Update date',
  data_cri: 'Creation date',
  modal_del: 'Yes, delete this process',
  erro: 'Error',
  cadastrarMetodologia: {
    nome: 'Name',
    placeholder: 'Methodology name',
    cadastrar: 'Register new methodology',
    msg_sucesso: 'Methodology successfully registered',
    colunas: 'Colunas',
    campo_colunas: 'Display of methodologies (cards within the startup)',
  },
  editarMetodologia: {
    titulo: 'Edit ethodology',
    msg_sucesso: 'Methodology successfully edited!'
  },
  listagemProcessos: {
    titulo: 'Process listing',
    colunas: 'Columns'
  },
  cadastrarProcesso: {
    titulo: 'Register new process',
    msg_sucesso: 'Process registered successfully',
    input_place: 'Process name',
    erro: 'Name must be filled',
    campo_colunas: 'Display of steps (cards within the process)',
    colunas_placeholder: 'Choose quantity',
    coluna_1: '1 card per row (Each card taking up the entire screen)',
    coluna_2: '2 cards per row (Each card taking up 50% of the screen)',
    coluna_3: '3 cards per row (Each card taking up 33% of the screen)',
    coluna_4: '4 cards per row (Each card taking up 25% of the screen)',
    erro_colunas: 'Select the number of columns'
  },
  editarProcesso: {
    titulo: 'Edit process',
    msg_sucesso: 'Process successfully updated'
  },
  listagemEtapas: {
    titulo: 'List of steps',
    modal_del: 'Yes, delete this step',
    modal_titulo: 'Type the name of the step',
    modal_titulo2: 'Edit step:',
    bt_editar: 'Edit',
  },
  cadastrarEtapa: {
    titulo: 'Register new stage',
    campo_colunas: 'Display of stages (cards within the stage)',
    msg_sucesso: 'Stage successfully registered',
    nome_etapa: 'Stage name',
    etapa_placeholder: 'Select an option',
    etapa_inicial: 'Will this step be used as an initial step?',
    sim1: 'Yes',
    sim: 'Yes (Radar only step starts)',
    nao: 'No',
    erro_etapa: 'Choose whether the step will be initial',
    etapa_inicial1: 'Initial step',
  },
  editarEtapa: {
    titulo: 'Edit step',
    msg_sucesso: 'Step successfully updated!'
  },
  listagemEstagio: {
    titulo: 'List of activities',
    modal_del: 'Yes, delete this activitie',
    modal_titulo: 'Type the name of the stage',
    modal_titulo2: 'Edit stage:',
    bt_editar: 'Edit',
  },
  cadastrarEstagio: {
    titulo: 'Register new activitie',
    msg_sucesso: 'Activitie successfully registered',
    input_descricao: 'Activitie description',
  },
  editarEstagio: {
    titulo: 'Edit activitie',
    msg_sucesso: 'Activitie successfully edited!'
  },
  listagemEntregas: {
    titulo: 'Submit list',
    modal_del: 'Yes, delete this submit',
    modal_titulo: 'Type the name of the submit',
    modal_titulo2: 'Edit submit:',
    bt_editar: 'Edit',
  },
  cadastrarEntrega: {
    titulo: 'Register new submit',
    nome_placeholder: 'Name of the submit',
    desc: 'Description',
    msg_sucesso: 'Submit successfully registered',
    tipo: 'Type',
    tipo_placeholder: 'Choose the type',
    erro_tipo: 'Type must be filled',
    erro_desc: 'Description must be filled',
    img: 'Image',
    vid: 'Video',
    text: 'Text',
    arq: 'File',
    pdf: 'PDF',
    todos: 'Let user choose',
    sem_campo: 'User choice'
  },
  editarEntrega: {
    titulo: 'Editar submit',
    msg_sucesso: 'Submit successfully updated'
  },
}