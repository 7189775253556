import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Card, Row, Col } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import validator from 'validator';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class EditarTrilha extends Component {
    state = {
        id_trilha: "",
        nome: "",
        previa: "",
        tipo: "",
        descricao: "",
        data_publicacao: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        editorState: "",
        idioma: portugues,
        msg_backend: portuguese

    }
    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        //////////////console.log(id_trilha)
        this.props.getVerTrilha(id_trilha);

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    async componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.trilha && nextProps.trilha) this.props.getVerTrilha(id_trilha);
        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparTrilha()
    }

    receberDadosAPI() {
        const { id_trilha } = this.props.match.params;
        if (typeof this.props.trilha !== "undefined" && this.props.trilha !== null && this.props.trilha.id == id_trilha && !this.state.dadosAPI) {
            this.setState({ id_trilha: this.props.trilha.id })
            this.setState({ nome: this.props.trilha.nome })
            this.setState({ descricao: this.props.trilha.descricao })
            this.setState({ previa: this.props.trilha.previa })
            this.setState({ tipo: this.props.trilha?.visibilidade })
            this.setState({ data_publicacao: this.props.trilha.data_publicacao })
            this.onEditorStateChange(this.props.trilha.descricao)
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onEditorStateChangeInicial = (content) => {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(content)
                )

            ),
        })
    }

    async atualizarTrilha(id, nome, previa, editorState, tipo, data_publicacao) {
        var visibilidade = tipo;
        this.setState({ success: "" });
        this.setState({ erro: "" });
        var descricao = editorState;
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putTrilha({ id, nome, previa, descricao, visibilidade, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }


    receberDadosForm() {
        this.setState({ id_trilha: document.querySelector("#id_trilha").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ tipo: document.querySelector("#tipo").value })
        this.setState({ descricao: document.querySelector("#descricao").value })
    }
    validate() {
        const { nome, erro, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.editarTrilha.erro_nome } });
        return true;
    }
    render() {
        const { id_trilha, nome, previa, tipo, descricao, data_publicacao, editorState, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;
        //////////////console.log(this.props)
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        //////console.log(trilha)
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/",
            state: { msg: idioma.editarTrilha.msg_sucesso }
        }}></Redirect>


        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <h2 className="display-4 titulo" >{idioma.editarTrilha.titulo}</h2>
                    <Titulo titulo='Editar Trilha' />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosAPI} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.editarTrilha.nome}</Label>
                                            <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.editarTrilha.nome_trilha : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label for="tipo">{idioma.editarTrilha.visibilidade}</Label>
                                        <Input type="select" onChange={(ev) => this.onChangeInput("tipo", ev)} id="tipo" name="tipo" >
                                            <option>{idioma.editarTrilha.sel_visibilidade}</option>
                                            <option value="Público" selected={trilha?.visibilidade == "Público" ? true : false}>
                                                {idioma.editarTrilha.publico}
                                            </option>
                                            <option value="Privado" selected={trilha?.visibilidade == "Privado" ? true : false}>
                                                {idioma.editarTrilha.privado}
                                            </option>
                                        </Input>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="previa">{idioma.editarTrilha.previa}</Label>
                                            <Input type="text" value={previa} id="previa" name="previa" className="form-control" autoComplete="previa" placeholder={dadosAPI ? idioma.editarTrilha.previa : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("previa", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="data_publicacao">{idioma.editarAtividade.data_pub}</Label>
                                            <Input type="date" value={data_publicacao} id="data_publicacao" name="data_publicacao" className="form-control" autoComplete="data_publicacao" placeholder={dadosAPI ? idioma.editarAtividade.data_pub : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("data_publicacao", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="nome">{idioma.editarTrilha.descricao}</Label>
                                    <Card className="card-editor">
                                        <div id='editor'>
                                            <ReactQuill
                                                theme='snow'
                                                value={editorState}
                                                bounds={'.app'}
                                                onChange={
                                                    (content) => {
                                                        this.onEditorStateChange(content)
                                                    }}
                                                modules={{
                                                    toolbar: {
                                                        container: [
                                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                            ['bold', 'italic', 'underline'],
                                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                            [{ 'align': [] }],
                                                            ['link', 'image'],
                                                            ['clean'],
                                                            [{ 'color': [] }]
                                                        ],
                                                    },
                                                }}
                                            />
                                            {/* <Editor
                                                editorState={editorState}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={this.onEditorStateChange}
                                            /> */}
                                        </div>
                                    </Card>
                                </FormGroup>
                                <Link onClick={() => this.atualizarTrilha(id_trilha, nome, previa, editorState, tipo)} to="#">
                                    <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' classes="botao-responsivo botao-estilo" />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    trilha: state.trilhas.trilha

})
export default connect(mapStateToProps, actions)(EditarTrilha)