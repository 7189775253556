import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';

import { portugues, ingles } from '../../utils/paginaperfil_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';

class EditarSenhaPerfil extends Component {
    state = {
        id: "",
        senha: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }
    async atualizarUsuario() {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        this.setState({ carregando: true })
        const { senha } = this.state;
        this.props.putPerfilSenha({ senha }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })
    }
    receberDadosForm() {
        this.setState({ senha: document.querySelector("#senha").value })
    }
    validate() {
        const { senha, idioma } = this.state;
        if (!senha) return this.setState({ erro: { message: idioma.editar_senha.titulo_erro } });
        if (senha.length < 6) return this.setState({ erro: { message: idioma.editar_senha.info_erro } });
        return true;
    }
    render() {
        const { senha, dadosAPI, carregando, erro, formSuccess, idioma } = this.state;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + this.props.usuario?.personalizacao?.titulo + "/perfil/",
            state: { msg: idioma.editar_senha.sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editar_senha.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <Form>
                                <FormGroup>
                                    <Label for="nome">{idioma.editar_senha.label}</Label>
                                    <Input type="text" value={senha} id="senha" name="senha" className="form-control" autoComplete="senha" placeholder={idioma.editar_senha.info_erro} onChange={(ev) => this.onChangeInput("senha", ev)} />
                                </FormGroup>
                                <Link onClick={() => this.atualizarUsuario()} to="#">
                                    <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' classes="botao-responsivo botao-estilo" />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.bt_cancelar} classes="botao-responsivo botao-estilo" icone="times" posicao="E" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(EditarSenhaPerfil);