import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Botao from '../../componentes/Botoes';

import BlocoPerfil from '../../containers/blocos/perfilUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { portugues, ingles } from '../../utils/paginaperfil_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class Perfil extends Component {
    state = {
        usuario: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario, history } = this.props; 
        this.setState({ usuario: usuario });

        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state })
            this.sucessoAlerta()
        }

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
        Toast.fire({
            icon: 'success',
            title: idioma.modal_titulo2
        })
    }
    async editarFoto() {
        const { idioma } = this.state
        const { value: file } = await Swal.fire({
            title: idioma.modal_img,
            input: 'file',
            inputAttributes: {
                'accept': 'image/*',
                'aria-label': idioma.modal_info
            }
        })
        if (!file) {
            Swal.fire({
                title: idioma.modal_erro
            })
        }
        const reader = new FileReader()
        reader.onload = async (e) => {
            const formData = new FormData();
            formData.append('file', e.target.result);
            await this.props.putPerfilFoto(formData, (msg) => {
                if (msg.erro.error) {
                    this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                    this.setState({ carregando: false });
                    Swal.fire({
                        title: this.state.erro.message,
                        imageUrl: e.target.result,
                        imageAlt: idioma.modal_sucesso
                    })
                } else {
                    this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                    this.setState({ carregando: false });
                    this.setState({ formSuccess: true });
                    Swal.fire({
                        title: idioma.modal_info2,
                        imageUrl: e.target.result,
                        imageAlt: idioma.modal_sucesso
                    })
                }
            });
        }
        reader.readAsDataURL(file)
    }
    render() {
        const { id, nome, imagem } = this.state.usuario;
        const { idioma } = this.state;
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >

                    <BlocoPerfil usuario={this.state.usuario} path={path} credenciais={credenciais} />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" cor={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                                <div className="row pt-2 mt-3">
                                    <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.info}</strong></p>
                                    <p className="col-md-8 texto-centralizado"><strong>{nome}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

}
const mapStateToProps = state => ({
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(Perfil);