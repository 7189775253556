import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/conteudos';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import BlocoPerfil from '../../containers/blocos/perfilConteudo';

import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'

import { portugues, ingles } from '../../utils/paginaconteudo_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class CadastrarConteudo extends Component {
    state = {
        titulo: "",
        id_tipo: "",
        id_conteudo: "",
        id_usuario: "",
        descricao: "",
        previa: "",
        fonte: "",
        file: null,
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        editorState: EditorState.createEmpty(),
        idioma: portugues,
        msg_backend: portuguese,
    }

    componentDidMount() {
        if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }
        this.props.getTipoConteudo();

        const { usuario } = this.props;
        const { idioma } = this.state;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }


    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }


    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    cadConteudo() {
        const { id_usuario, fonte, file, titulo, id_tipo, editorState, previa, msg_backend } = this.state;
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        //////console.log(fonte)
        if (!this.validate()) return;
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('titulo', titulo)
        formData.append('id_usuario', id_usuario)
        formData.append('id_tipo', id_tipo)
        formData.append('descricao', descricao)
        formData.append('fonte', fonte)
        formData.append('previa', previa)

        this.setState({ carregando: true })
        this.props.postConteudo(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    listar() {
        const { tipo } = this.state;
        this.props.getConteudos(tipo)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
    }

    validate() {
        const { titulo, id_tipo, descricao, idioma } = this.state;
        if (!titulo) return this.setState({ erro: { message: idioma.error_nome } });
        if (!id_tipo) return this.setState({ erro: { message: idioma.error_tipo } });
        // if (!descricao) return this.setState({ erro: { message: "Preencha uma descrição" } });
        return true;
    }
    render() {
        const { titulo, id_usuario, previa, id_tipo, fonte, file, editorState, erro, success, carregando, iconSalvar, formSuccess, idioma } = this.state;
        // var tipos = [];
        // if (this.props.tipos) tipos = this.props.tipos.dados;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        ////console.log(tipos, credenciais)
        if (formSuccess) return <Redirect to={{
            pathname: "/conteudos",
            state: { msg: idioma.novo_conteudo.sucesso_msg }
        }}></Redirect>

        var tipos = [
            { id: 2, nome: idioma.novo_conteudo.conteudos },
            { id: 3, nome: idioma.novo_conteudo.insights },
            { id: 1, nome: idioma.novo_conteudo.noticias },
        ]
 

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.novo_conteudo.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Input type="hidden" value={id_usuario} id="id_usuario" name="id_usuario" />

                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="titulo">{idioma.novo_conteudo.label_titulo}</Label>
                                            <Input type="text" value={titulo} autoComplete="titulo" onChange={(ev) => this.onChangeInput("titulo", ev)} name="titulo" id="titulo" placeholder={idioma.novo_conteudo.place_titulo} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label for="id_tipo">{idioma.novo_conteudo.tipo}</Label>
                                        <Input type="select" onChange={(ev) => this.onChangeInput("id_tipo", ev)} id="id_tipo" name="id_tipo" >
                                            <option>{idioma.novo_conteudo.place_tipo}</option>
                                            {credenciais.length > 0 && tipos.length > 0 ?
                                                credenciais.map(credencial => ((credencial.id_componente === 68 && tipos[0].id === 2) ?
                                                    <option value={tipos[0].id}>
                                                        {tipos[0].nome}
                                                    </option>
                                                    : ""))
                                                : ""}
                                            {credenciais.length > 0 && tipos.length > 0 ?

                                                credenciais.map(credencial => ((credencial.id_componente === 67 && tipos[2].id === 1) ?
                                                    <option value={tipos[2].id}>
                                                        {tipos[2].nome}
                                                    </option>
                                                    : ""))
                                                : ""}

                                            {credenciais.length > 0 && tipos.length > 0 ?

                                                credenciais.map(credencial => ((credencial.id_componente === 69 && tipos[1].id === 3) ?
                                                    <option value={tipos[1].id}>
                                                        {tipos[1].nome}
                                                    </option>
                                                    : "")) : ""}
                                        </Input>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="fonte">{idioma.novo_conteudo.fonte}</Label>
                                    <Input type="text" value={fonte} autoComplete="fonte" onChange={(ev) => this.onChangeInput("fonte", ev)} name="fonte" id="fonte" placeholder={idioma.novo_conteudo.place_fonte} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="file">{idioma.novo_conteudo.imagem}</Label>
                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                </FormGroup>
                                <Row>
                                    <Col md={12} className="pb-2">
                                        <Label for="previa">{idioma.novo_conteudo.previa}</Label>
                                        <Input type="textarea" value={previa} autoComplete="previa" onChange={(ev) => this.onChangeInput("previa", ev)} name="previa" id="previa" placeholder={idioma.novo_conteudo.previa} />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="pb-2">
                                        <Label for="descricao">{idioma.novo_conteudo.descricao}</Label>
                                        <Card>
                                            <div>
                                                <Editor
                                                    editorState={editorState}
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName="editorClassName"
                                                    onEditorStateChange={this.onEditorStateChange}
                                                />
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                                <Link to="#" onClick={() => this.cadConteudo()}> <Botao loading={carregando} texto={idioma.novo_conteudo.bt_cadastar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>

                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.novo_conteudo.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                            </Form>
                        </div></div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    conteudos: state.conteudos.conteudos,
    tipos: state.conteudos.tipoConteudo

})

export default connect(mapStateToProps, actions)(CadastrarConteudo);