import axios from 'axios';
import {
    GET_INCUBADORAS, GET_INCUBADORA, GET_STARTUPS, GET_STARTUP,
    GET_USERS, GET_INCUBADORA_STARTUP, GET_SEARCH_INCUBADORA, GET_SEARCH,
    GET_SEARCH_STARTUP, GET_SEGMENTO, GET_USUARIOS_INCUBADORA,
    GET_FUTUROS_USUARIOS, GET_MINHAS_INCUBADORAS, GET_INCUBADORAS_ATIVAS, GET_STARTUPS_ATIVAS, LIMPAR_INCUBADORA, LIMPAR_INCUBADORAS,
    LIMPAR_INCUBADORAS_STARTUPS, LIMPAR_USERS, LIMPAR_INCUBADORAS_ATIVAS, LIMPAR_STARTUPS_ATIVAS, GET_SEARCH_INCUBADORAS,
    LIMPAR_USUARIOS_INCUBADORA, LIMPAR_FUTUROS_USUARIOS, LIMPAR_MINHAS_INCUBADORAS, LIMPAR_STARTUPS, GET_SEARCH_STARTUPS_INCUBADORA,
    GET_VERIFICAR, GET_PERSONALIZACAO_INCUBADORA, GET_PERSONALIZACOES, LIMPAR_PERSONALIZACAO, GET_PERSONALIZACAO,
    GET_PERSONALIZACOES_INCUBADORA, GET_INCUBADORAS_PERSONALIZACAO, GET_TODAS_PERSONALIZACOES, GET_JORNADAS_INCUBADORA
} from './types'
import { api } from '../../config';
import { getHeaders, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getIncubadorasAtivas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/ativos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_INCUBADORAS_ATIVAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getIncubadoras = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_INCUBADORAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerificarPertence = (id_usuario, id_startup, id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/pertence/verificar?id_usuario=${id_usuario}&id_startup=${id_startup}&id_incubadora=${id_incubadora}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_VERIFICAR, payload: response })
            })
            .catch(errorHandle)
    }
}


export const getSearch = (procura, paginaAtual, limite) => {
    ////console.log(procura)
    return function (dispatch) {
        axios.get(api + `/usuarios/buscar?procura=${procura}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_SEARCH, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPersonalizacoes = () => {
    return function (dispatch) {
        axios.get(api + `/personalizacoes-incubadoras/`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERSONALIZACOES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPersonalizacao = (id) => {
    return function (dispatch) {
        axios.get(api + `/personalizacao/${id}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERSONALIZACAO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getTodasPersonalizacoes = () => {
    return function (dispatch) {
        axios.get(api + `/personalizacoes`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_TODAS_PERSONALIZACOES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPersonalizacaoIncubadora = (id) => {
    return function (dispatch) {
        axios.get(api + `/personalizacao-incubadora/${id}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERSONALIZACOES_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPersonalizacoesIncubadora = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/personalizacao/${id_incubadora}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERSONALIZACAO_INCUBADORA, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getIncubadorasPorPersonalizacao = (id_personalizacao) => {
    return async function (dispatch) {
        await axios.get(api + `/personalizacao-incubadora/personalizacao/${id_personalizacao}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_INCUBADORAS_PERSONALIZACAO, payload: response })
            })
            .catch(errorHandle)
    }
}



export const postPersonalizacao = (novaPersonalizacao, callback) => {
    return function (dispatch) {
        axios.post(api + '/personalizacao', novaPersonalizacao, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postVinculoPersonalizacao = (novoVinculo, callback) => {
    return function (dispatch) {
        axios.post(api + '/personalizacao-incubadora', novoVinculo, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const postIncubadora = (dados, callback) => {
    return function (dispatch) {
        ////////////console.log(dados)
        axios.post(api + `/incubadora`, dados, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putIncubadora = (dadosIncubadora, callback) => {
    return function (dispatch) {
        axios.put(api + `/incubadora/editar`, dadosIncubadora, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const putPersonalizacao = (id, dadosIncubadora, callback) => {
    return function (dispatch) {
        axios.put(api + `/personalizacao/${id}/alterar`, dadosIncubadora, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}



export const putIncubadoraImagem = (dadosIncubadora, callback) => {
    return function (dispatch) {
        axios.put(api + `/incubadora/editar/imagem`, dadosIncubadora, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putIncubadoraCapa = (dadosIncubadora, callback) => {
    return function (dispatch) {
        axios.put(api + `/incubadora/editar/capa`, dadosIncubadora, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarIncubadora = (id_incubadora, callback) => {
    return function (dispatch) {
        axios.delete(api + `/incubadora/${id_incubadora}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarPersonalizacaoVinculo = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/personalizacao-incubadora/${id}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarPersonalizacao = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/personalizacao/${id}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getVerIncubadora = (id_incubadora) => {
    return function (dispatch) {

        axios.get(api + `/incubadora/${id_incubadora}`, getHeaders())
            .then((response) => {
                ////console.log(response.data)
                dispatch({ type: GET_INCUBADORA, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getStartups = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getStartupsAtivas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/ativos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS_ATIVAS, payload: response })
            })
            .catch(errorHandle)
    }
}


export const getVerStartup = (id) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUP, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const postStartup = (dadosStartup, callback) => {
    return function (dispatch) {
        axios.post(api + `/startup`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getUsuarios = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USERS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const desativarIncubadora = (id_incubadora, callback) => {
    return function (dispatch) {
        axios.put(api + `/incubadora/${id_incubadora}/desativar`, id_incubadora, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const ativarIncubadora = (id_incubadora, callback) => {
    return function (dispatch) {
        axios.put(api + `/incubadora/${id_incubadora}/ativar`, id_incubadora, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getIncubadoraStartups = (id_incubadora, paginaAtual, limite, jornadaSelecionada) => {
    return function (dispatch) {
        axios.get(api + `/incubadora/${id_incubadora}/startups?jornada=${jornadaSelecionada}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_INCUBADORA_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}


export const getJornadasIncubadora = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/incubadora/${id_incubadora}/jornadas`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_JORNADAS_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}


export const getSearchNameStartups = (pesquisa, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/buscar/todas?pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_SEARCH_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchNameStartupsIncubadora = (pesquisa, id_incubadora, paginaAtual, limite, jornadaSelecionada) => {
    return function (dispatch) {
        axios.get(api + `/incubadora/startups/buscar?id_incubadora=${id_incubadora}&jornada=${jornadaSelecionada}&pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_SEARCH_STARTUPS_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchIncubadoras = (pesquisa, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/ecossistema/buscar?pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_SEARCH_INCUBADORAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchNameIncubadoras = (pesquisa, id_usuario, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/buscar?id_usuario=${id_usuario}&pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_SEARCH_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuariosIncubadora = (id_incubadora, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadora/${id_incubadora}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USUARIOS_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postUsuarioIncubadora = (dadosStartup, callback) => {
    return function (dispatch) {
        axios.post(api + `/incubadora/participantes/cadastrar`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarUsuarioIncubadora = (id_incubadora, id_usuario, callback) => {
    return function (dispatch) {
        axios.delete(api + `/incubadora/${id_incubadora}/participantes/${id_usuario}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// export const getFuturosUsuarios = (id_incubadora, paginaAtual, limite) => {
//     return function (dispatch) {
//         axios.get(api + `/incubadora/${id_incubadora}/futuros?page=${paginaAtual}&size=${limite}`, getHeaders())
//             .then((response) => {
//                 dispatch({ type: GET_FUTUROS_USUARIOS, payload: response })
//             })
//             .catch(errorHandle)
//     }
// }

export const getFuturosUsuarios = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                // console.log(response)
                dispatch({ type: GET_FUTUROS_USUARIOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postVincularUsuario = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.post(api + `/incubadora/participantes/vincular`, dadosUsuario, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// export const getIncubadorasPesquisa = (pesquisa, paginaAtual, limite) => {
//     return function (dispatch) {
//         axios.get(api + `/incubadoras/buscar?pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
//             .then((response) => {
//                 ////////////console.log(response)
//                 dispatch({ type: GET_INCUBADORAS_PESQUISA, payload: response })
//             })
//             .catch(errorHandle)
//     }
// }


export const getSegmento = (segmento, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/segmento?segmento=${segmento}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_SEGMENTO, payload: response })
            })
            .catch(errorHandle)
    }
}

// export const getSegmentos = () => {
//     return function (dispatch) {
//         axios.get(api + `/startup/segmentos`, getHeaders())
//             .then((response) => {
//                 dispatch({ type: GET_SEGMENTOS, payload: response.data })
//             })
//             .catch(errorHandle)
//     }
// }

export const getMinhasIncubadoras = (id_usuario, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/usuario/${id_usuario}/listar?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_MINHAS_INCUBADORAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const limparIncubadora = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_INCUBADORA })
    }
}
export const limparIncubadoras = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_INCUBADORAS })
    }
}

export const limparIncubadorasStartups = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_INCUBADORAS_STARTUPS })
    }
}

export const limparUsuarios = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_USERS })
    }
}

export const limparIncubadorasAtivas = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_INCUBADORAS_ATIVAS })
    }
}

export const limparStartupsAtivas = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_STARTUPS_ATIVAS })
    }
}

export const limparUsuariosIncubadora = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_USUARIOS_INCUBADORA })
    }
}

export const limparFuturosUsuarios = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_FUTUROS_USUARIOS })
    }
}

export const limparMinhasIncubadoras = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MINHAS_INCUBADORAS })
    }
}

export const limparStartups = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_STARTUPS })
    }
}

export const limparPersonalizacao = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PERSONALIZACAO })
    }
}