import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/perfis';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner, Table } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg';
import { format, differenceInDays } from 'date-fns';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { ingles, portugues } from '../../utils/paginaperfilacesso_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';


class PerfilAcesso extends Component {
    state = {
        currentPage: 1,
        size: 8,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        this.listarPerfis();

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.acessos && nextProps.acessos) this.listarPerfis();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparPerfil();
        this.props.limparPerfis();
    }
    receberDadosAPI() {
        if (typeof this.props.acessos != "undefined" && this.props.acessos != null && !this.state.dadosApi && this.props.acessos.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }
    perfilNovo(data) {
        var result = differenceInDays(
            new Date(),
            data
        )
    }
    listarPerfis() {
        const { currentPage, size } = this.state;
        this.props.getPerfis(currentPage, size)
        const { acessos } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (acessos === "undefined") return null
    }
    changePageAtual(currentPage) {
        this.props.limparPerfis();
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarPerfis();
        })
    }
    apagarPerfil(id) {
        const { msg_backend } = this.state;
        this.props.limparPerfis();
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarPerfil(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarPerfis();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }
    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.modal_titulo,
            html: idioma.modal_info,
            input: 'text',
            inputPlaceholder: idioma.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.confirm) {
                this.apagarPerfil(id);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.modal_erro
                })
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao?.cor_secundaria
    }

    render() {
        const { msg, carregando, erro, success, dadosApi, idioma } = this.state;
        const { usuario } = this.props;
        var acessos = [];

        if (this.props.acessos) acessos = this.props.acessos.dados;
        var temPaginaAnterior = false;
        if (typeof this.props.acessos != "undefined" && this.props.acessos != null && this.props.acessos.currentPage !== "" && this.props.acessos.currentPage !== 1) {
            temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (typeof this.props.acessos != "undefined" && this.props.acessos !== null && this.props.acessos.nextPage <= this.props.acessos.totalPages && this.props.acessos.nextPage != null) {
            proximaPagina = true;
            temProximaPagina = true;
        }

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        ////////console.log(credenciais)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="list-group-item bloco-card">
                    <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                    <Link to={"/" + usuario?.personalizacao?.titulo + "/perfil/cadastrar"}>
                        <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                            {idioma.perfil}
                        </button>
                    </Link>
                    <div className="d-flex">
                        <div className="col-md-12 p-2">
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            {dadosApi ?
                                <div className="row">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th >{idioma.nome}</th>
                                                <th className="row-nome">{idioma.acao}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {acessos.map(acesso => (
                                                <tr key={acesso.id}>
                                                    <td>{acesso.nome}</td>
                                                    <td>
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/perfil/" + acesso.id + "/editar"} className="mr-2">
                                                            <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline-primary btn-sm mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}` }}>{idioma.perfil_input}</button>
                                                        </Link>
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/perfil/" + acesso.id + "/credenciais/editar"} className="mr-2">
                                                            <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline-primary btn-sm mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}` }}>{idioma.credenciais}</button>
                                                        </Link>
                                                        {credenciais.map(credencial => ((credencial.id_componente == 62) ?
                                                            <span onClick={() => this.chamarAlerta(acesso.id)} key={credencial.id_componente}>
                                                                <Botao loading={carregando} texto={idioma.bt_deletar} cor="danger" tamanho="sm" />
                                                            </span>
                                                            : ""))}
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                : <div className="row">
                                    <div className="col-md-3">
                                        <div className="card directory-card">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <h5 className="m-t-10 font-18 mb-1">
                                                            <Skeleton count={1} width={80} />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card directory-card">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <h5 className="m-t-10 font-18 mb-1">
                                                            <Skeleton count={1} width={80} />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card directory-card">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <h5 className="m-t-10 font-18 mb-1">
                                                            <Skeleton count={1} width={80} />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="card directory-card">
                                            <div className="card-body">
                                                <div className="media">
                                                    <div className="media-body">
                                                        <h5 className="m-t-10 font-18 mb-1">
                                                            <Skeleton count={1} width={80} />
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            {dadosApi ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.acessos.prevPage ? this.props.acessos.prevPage : 1)}>{this.props.acessos.prevPage ? this.props.acessos.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.acessos ? this.props.acessos.currentPage : "1"}</span></li>

                                        {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.acessos.nextPage ? this.props.acessos.nextPage : 1)}>{this.props.acessos.nextPage ? this.props.acessos.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.acessos ? this.props.acessos.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                        </div>
                    </div>
                </div >
            </>
        )
    }
}
const mapStatetoProps = state => ({
    acessos: state.perfis.acessos
})
export default connect(mapStatetoProps, actions)(PerfilAcesso);