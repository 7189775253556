export const portugues = {
  titulo: 'Meus Negócios',
  search: 'Pesquisar...',
  bt_search: 'Pesquisar',
  bt_cancelar: 'Cancelar',
  page1: 'Primeira',
  page2: 'Última',
  sem_dados: 'Não há negócios para exibir',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  modal_titulo: 'Você tem certeza?',
  modal_texto: 'Não será possível restaurar esse negócio!',
  modal_confirm: 'Sim',
  modal_cancel: 'Cancelar',
  modal_sucesso: 'Usuário excluído com sucesso!',
  metodologia: {
    titulo: 'Acompanhamento',
    bt_voltar: 'Voltar',
    sem_dados: 'Jornada de Desenvolvimento',
    sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
    modal_titulo: 'Escolha o usuário desejado:',
    modal_titulo2: 'Escolha a Jornada de Desenvolvimento:',
    bt_cancelar: 'Cancelar',
    bt_salvar: 'Vincular Jornada de Desenvolvimento'
  },
  processo: {
    titulo: 'Acompanhamento',
    bt_voltar: 'Voltar',
    badge1: 'Radar aplicado',
    badge2: 'Radar não aplicado',
    radar: 'Radar incial',
    imersao: 'Imersão maturidade',
    editar_radar: 'Editar radar',
    aplicar: 'Aplicar radar inicial',
    ver_etapa: 'Ver módulo',
    ver: 'Ver radar',
    sem_dados_titulo: 'Processos',
    sem_dados: 'Acesso negado',
    sem_dados_sub: 'Não há informações para serem exibidas.',
  }
}

export const ingles = {
  titulo: 'My Business',
  search: 'Search...',
  bt_search: 'Search',
  bt_cancelar: 'Cancel',
  page1: 'First',
  page2: 'Last',
  sem_dados: 'No business to show',
  sem_dados_sub: 'What a pity! There is no information to display.',
  modal_titulo: 'Are you sure?',
  modal_texto: 'It will not be possible to restore this business!',
  modal_confirm: 'Yes',
  modal_cancel: 'Cancel',
  modal_sucesso: 'User successfully deleted!',
  metodologia: {
    titulo: 'Follow-up',
    bt_voltar: 'Back',
    sem_dados: 'Methodology',
    sem_dados_sub: 'What a pity! There is no information to display.',
    modal_titulo: 'Choose a user:',
    modal_titulo2: 'Choose a Methodology:',
    bt_cancelar: 'Cancel',
    bt_salvar: 'Link methodology',
  },
  processo: {
    titulo: 'Follow up',
    bt_voltar: 'Back',
    badge1: 'Applied radar',
    badge2: 'Radar not applied',
    radar: 'Initial radar',
    imersao: 'Maturity immersion',
    editar_radar: 'Edit radar',
    aplicar: 'Apply initial radar',
    ver_etapa: 'See step',
    ver: 'See radar',
    sem_dados_titulo: 'Processes',
    sem_dados: 'Access denied',
    sem_dados_sub: 'There is no information to display.',
  }
}