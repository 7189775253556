import axios from 'axios';
import { api } from '../../config';
import { LOGIN_USER, LOGOUT_USER, LOGIN_ERRO_USER, GET_LANGUAGE, GET_PERSONALIZACAO_INCUBADORA, GET_PERSONALIZACAO_USUARIO, LIMPAR_PERSONALIZACAO_USUARIO, ALT_SIT_MENU, GET_NOTIFICACOES } from './types';
import { saveToken, getToken, getHeaders, clearToken, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle'

export const getPersonalizacaoUsuario = (personalizacao) => {
    return async function (dispatch) {
        await axios.get(api + `/personalizacao/titulo/buscar?titulo=${personalizacao}`)
            .then((response) => {
                dispatch({ type: GET_PERSONALIZACAO_USUARIO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const limparPersonalizacaoUsuario = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PERSONALIZACAO_USUARIO })
    }
}
export const handleSitMenu = (sitMenu) => {
    return function (dispatch) {
        dispatch({ type: ALT_SIT_MENU, payload: sitMenu })
    }
}

export const handleLogin = ({ email, senha }, callback) => {
    return function (dispatch) {
        axios.post(api + '/login', { email, senha })
            .then((response) => {
                ////////////console.log(response.data.error)
                if (response.data.error) {
                    callback(errorHandle(response))
                    // dispatch({ type: LOGIN_ERRO_USER, payload: response.data })
                } else {
                    saveToken(response.data);
                    dispatch({ type: LOGIN_USER, payload: response.data })

                }
            }).catch((err) => callback(errorHandle(err)))
    }
}

export const getUsuario = () => {
    return async function (dispatch) {
        if (getToken()) {
            await axios.get(api + '/perfil', getHeaders())
                .then((response) => {
                    ////console.log(response)
                    saveToken(response.data);
                    dispatch({ type: LOGIN_USER, payload: response.data });
                })
                .catch((err) => {
                    clearToken();
                    dispatch({ type: LOGOUT_USER })
                })
        } else {
            clearToken();
            dispatch({ type: LOGOUT_USER })
        }
    }
}

export const getNotificacoes = (id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/chat/buscar/naolidas/${id_usuario}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_NOTIFICACOES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const putPermissao = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/usuario/permissao`, dadosUsuario, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const handleLogout = () => {
    // return function (dispatch) {
    //     axios.put(api + `/logout`, id_usuario, getHeadersPost())
    //         .then((response) => {
    //             clearToken();
    //             //console.log(response)
    //         })
    // }
    clearToken();
    const perso = sessionStorage.getItem('url-perso');

    if (perso) {
        window.location = `/${perso}/login`;
    } else {
        window.location = '/';
    }

    return { type: LOGOUT_USER };
}