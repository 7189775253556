import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfil';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import MyDropzone from "../../componentes/Formulario/dropzone"
import urlParser from "js-video-url-parser";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Row from 'reactstrap/lib/Row';
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Entrega extends Component {
    state = {
        id_entrega: "",
        id_startup: "",
        id_incubadora: "",
        id_etapa: "",
        id_processo: "",
        id_metodologia: "",
        resposta: "",
        editorState: EditorState.createEmpty(),
        file: null,
        tipo: "",
        link: "",
        erro: "",
        success: "",
        pertence: "",
        dadosApi: false,
        carregando: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup, id_incubadora, id_etapa, id_entrega, id_metodologia, id_processo } = this.props.match.params;
        this.setState({ id_entrega: id_entrega });
        this.setState({ id_processo })
        this.setState({ id_metodologia })
        this.setState({ id_incubadora })
        this.setState({ id_startup });
        this.setState({ id_etapa: id_etapa })
        this.listarStartup(id_startup);
        this.listarEntrega(id_entrega, id_startup)
        this.verificaPertence(this.props.usuario.id, id_startup)

        if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }

    componentDidUpdate(nextProps) {
        const { id_entrega, id_startup } = this.props.match.params;
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.entrega && nextProps.entrega) {
            this.listarEntrega(id_entrega, id_startup)
        }
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_startup);
        }
        this.receberDadosAPI();
    }


    componentWillUnmount() {
        this.props.limparStartup()
    }



    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }
    listarEntrega(id_entrega, id_startup) {
        this.props.getEntrega(id_entrega, id_startup);
        const { entrega } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (entrega === "undefined") return null
    }
    receberDadosAPI() {

        if (typeof this.props.entrega != "undefined" && this.props.entrega != null && !this.state.dadosApi) {
            if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
                if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {

                    this.setState({ dadosApi: true });
                }
            }
        }
    }
    onChangeFileInput = (field, ev) => {
        this.setState({ [field]: ev.target.files[0] });
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }
    async realizarEntrega(linkYT) {
        const { id_entrega, id_startup, editorState, tipo } = this.state;
        if (linkYT != undefined && tipo == "link") {
            if (linkYT.includes("youtube")) {
                var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id
            } else if (linkYT.includes("youtu.be")) {
                var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id
            }
        }
        if (tipo == "outros") {
            var link = linkYT
        }
        var resposta = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        var tipo_campo = tipo
        this.setState({ success: "" });
        this.setState({ erro: "" });
        this.setState({ carregando: true })
        const formData = new FormData();
        formData.append('id_entrega', id_entrega)
        formData.append('id_startup', id_startup)
        formData.append('link', link)
        formData.append('resposta', resposta)
        formData.append('tipo_campo', tipo_campo)
        formData.append('file', this.state.file);

        await this.props.postEntrega(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ formSuccess: true });
            }
        });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
        if (usuarios.startups.length > 0) {
            for (let u in usuarios.startups) {
                if (usuarios.startups[u].id_startup == id_startup) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (usuarios.incubadoras.length > 0) {
            for (let u in usuarios.incubadoras) {
                if (usuarios.incubadoras[u].id_incubadora == id_incubadora) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }

    }

    verificaPertence(id_usuario, id_startup) {
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }


    render() {
        const { link, file, pertence, resposta, editorState, tipo, carregando, erro, formSuccess, id_entrega, id_metodologia, id_processo, id, id_etapa, id_startup, dadosApi, idioma } = this.state;
        var startup = null;
        var usuario = [];
        var entrega = [];
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (this.props.usuario) usuario = this.props.usuario;
        if (this.props.startup) startup = this.props.startup;
        if (this.props.entrega) entrega = this.props.entrega.dados.entrega;

        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (formSuccess) {
            return <Redirect to={{
                pathname: '/startups/' + id_startup + '/acompanhamento/' + id_metodologia + '/processos/' + id_processo + '/etapa/' + id_etapa + '/entrega/' + id_entrega + '/evidencias',
                state: { msg: idioma.entrega.msg_sucesso }
            }} />
        }
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.entrega.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        usuarioPertence.pertence &&
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {usuarioPertence.pertence ?
                        <div className="col-md-12 mb-2">
                            <AlertaErro erros={erro} />
                            <Form>
                                {entrega.tipo_campo == null ?

                                    <Form>
                                        <FormGroup>
                                            <Label for="tipo">{idioma.entrega.tipo}</Label>
                                            <Input type="select" onChange={(ev) => this.onChangeInput("tipo", ev)} id="tipo" name="tipo" >
                                                <option>Escolher o tipo</option>
                                                <option value="pdf">
                                                    PDF
                                                </option>
                                                <option value="imagem">
                                                    {idioma.entrega.img}
                                                </option>
                                                <option value="link">
                                                    {idioma.entrega.vid}
                                                </option>
                                                <option value="texto">
                                                    {idioma.entrega.tex}
                                                </option>
                                                <option value="arquivo">
                                                    {idioma.entrega.arq}
                                                </option>
                                            </Input>
                                        </FormGroup>
                                        {tipo == "arquivo" || tipo == "imagem" || tipo == "pdf" ?
                                            <FormGroup className="mt-3">
                                                <Label for="file">{idioma.entrega.doc}</Label>
                                                <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeFileInput("file", ev)} />
                                            </FormGroup>
                                            : tipo == "link" ?
                                                <FormGroup className="mt-3">
                                                    <Label for="link">{idioma.entrega.link}</Label>
                                                    <Input type="text" id="link" name="link" className="form-control" autoComplete="link" onChange={(ev) => this.onChangeInput("link", ev)} />
                                                </FormGroup>
                                                : tipo == "texto" ?
                                                    <FormGroup className="mt-3">
                                                        <Editor
                                                            editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={this.onEditorStateChange}
                                                        />
                                                    </FormGroup>
                                                    : ""
                                        }
                                    </Form>
                                    :

                                    entrega.tipo_campo == "file" ?
                                        <FormGroup>
                                            <Label for="file">{idioma.entrega.doc}</Label>
                                            <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeFileInput("file", ev)} />
                                        </FormGroup>
                                        :
                                        <FormGroup>
                                            <Label for="link">{idioma.entrega.link}</Label>
                                            <Input type="text" id="link" name="link" className="form-control" autoComplete="link" onChange={(ev) => this.onChangeInput("link", ev)} />
                                        </FormGroup>
                                }
                                <Link onClick={() => this.realizarEntrega(link)} to="#">
                                    <Botao loading={carregando} texto={idioma.entrega.bt_enviar} cor="success" icone="rocket" posicao="E" />
                                </Link>
                            </Form>
                        </div>
                        : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.participantes.erro_acesso} />}
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario,
    startup: state.startups.startup,
    pertence: state.startups.pertence


})
export default connect(mapStateToProps, actions)(Entrega);