import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import BotaoIcon from '../../../componentes/BotaoIcon';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input, Button, Table } from 'reactstrap';
import iconeUsuario from '../../../arquivos/imagens/avatar.jpg'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Toast from '../../../componentes/Alertas/Toast';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

class ListarEntregas extends Component {
    state = {
        currentPage: 1,
        size: 20,
        trilha: "",
        msg: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        this.listarEntregas();

        const { usuario } = this.props;

        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state });
        }

        if (usuario.idioma.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.entregas && nextProps.entregas) this.listarEntregas();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
    }
    receberDadosAPI() {
        if (typeof this.props.entregas != "undefined" && this.props.entregas != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    chamarAlertaExcluir(id_estagio) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemEntregas.modal_del,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.listagemEntregas.modal_del
        }).then((result) => {
            if (result.value) {
                this.excluirEntrega(id_estagio);
            }
        })
    }

    excluirEntrega(id_entrega) {
        this.setState({ carregando: true })
        this.props.excluirEntrega(id_entrega, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ carregando: false });
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
                this.listarEntregas();
            }
        })
    }

    sucessoAlerta(msg) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: msg
        })
    }

    listarEntregas() {
        const { id_estagio } = this.props.match.params;
        this.props.getEntregas(id_estagio)
        const { entregas } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state })
            this.props.location.state = "";
        }
        if (entregas === "undefined") return null
    }


    render() {
        const { msg, carregando, erro, success, dadosApi, idioma } = this.state;
        const { id_metodologia, id_processo, id_etapa, id_estagio } = this.props.match.params;

        var entregas = [];
        if (this.props.entregas) entregas = this.props.entregas.entregas;
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.listagemEntregas.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            {erro && <AlertaErro erros={erro} />}
                            <Link to={`/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/estagios/listar`} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
                            <Link to={`/${this.props?.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/estagio/${id_estagio}/entrega/cadastrar`} className="pr-2"> <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color="green" /> </Link>
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.nome}</th>
                                                            <th className="coluna-tabela">{idioma.cadastrarEntrega.desc}</th>
                                                            <th className="coluna-tabela">{idioma.cadastrarEntrega.tipo}</th>
                                                            {/* <th className="coluna-tabela">{idioma.data_cri}</th> */}
                                                            <th className="coluna-tabela">{idioma.data_alt}</th>
                                                            <th className="coluna-tabela">{idioma.acoes}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {entregas.map(entrega => (
                                                            <tr key={entrega.id}>

                                                                <td>{entrega.nome}</td>
                                                                <td>
                                                                    <a data-tip data-for={`desc${entrega.id}`}>
                                                                        <p style={{ maxWidth: '400px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{entrega.descricao}</p>
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a data-tip data-for={`tipo${entrega.id}`}>
                                                                        <p style={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{entrega.tipo_campo ? entrega.tipo_campo.toUpperCase() : idioma.cadastrarEntrega.sem_campo}</p>
                                                                    </a>
                                                                </td>
                                                                {/* <td>{entrega.datahora_criacao ? format(new Date(entrega.datahora_criacao), 'dd/MM/yyyy', { locale: pt }) : '--'}</td> */}
                                                                <td>{entrega.datahora_alteracao ? format(new Date(entrega.datahora_alteracao), 'dd/MM/yyyy', { locale: pt }) : entrega.datahora_criacao ? format(new Date(entrega.datahora_criacao), 'dd/MM/yyyy', { locale: pt }) : '--'}</td>
                                                                <td>
                                                                    <a data-tip data-for='editar'>
                                                                        <Link to={`/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/estagio/${id_estagio}/entrega/${entrega.id}/editar`} className="mr-2">
                                                                            <BotaoIcon loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="btn btn-sm ml-1" icone="pencil-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='excluir'>
                                                                        <Link to="#" onClick={() => this.chamarAlertaExcluir(entrega.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash" />
                                                                        </Link>
                                                                    </a>

                                                                    <ReactTooltip id={`desc${entrega.id}`} backgroundColor={this.props.usuario?.personalizacao?.cor_terciaria}>
                                                                        <span>{entrega.descricao}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id={`tipo${entrega.id}`} backgroundColor={this.props.usuario?.personalizacao?.cor_terciaria}>
                                                                        <span>{entrega.tipo_campo ? entrega.tipo_campo : idioma.cadastrarEntrega.sem_campo}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' backgroundColor={this.props.usuario?.personalizacao?.cor_terciaria}>
                                                                        <span>{idioma.bt_editar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='excluir' type='error'>
                                                                        <span>{idioma.bt_excluir}</span>
                                                                    </ReactTooltip>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    entregas: state.startups.entregasEstagio,

})
export default connect(mapStatetoProps, actions)(ListarEntregas);