import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Row, Col, Card, CardBody, UncontrolledCollapse, Table } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import SemDados from '../../componentes/Mensagens/semDados';
import Swal from 'sweetalert2';

import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class EditarQuestao extends Component {
    state = {
        titulo: "",
        tipo_campo: "",
        id_materia: "",
        id_atividade: "",
        id_trilha: "",
        id_curso: "",
        ordem: "",
        descricao: "",
        conteudo: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha, id_modulo, id_materia, id_questao, id_atividade } = this.props.match.params;
        this.setState({ id_questao })
        this.setState({ id_trilha })
        this.setState({ id_modulo })
        this.setState({ id_materia })
        this.setState({ id_atividade })

        ////////////console.log(id_questao)
        this.listarTrilha(id_trilha)
        this.listarOpcoes(id_questao)
        this.props.getVerQuestao(id_questao)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        const { id_trilha, id_modulo, id_questao, id_atividade } = this.props.match.params;
        if (!this.props.questao && nextProps.questao) this.props.getVerQuestao(id_questao);
        if (!this.props.opcoes && nextProps.opcoes) this.listarOpcoes(id_questao);

        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparQuestao()
        this.props.limparOpcoes()
    }

    receberDadosAPI() {
        const { id_questao } = this.props.match.params;
        if (typeof this.props.questao !== "undefined" && this.props.questao !== null && this.props.questao.id == id_questao && !this.state.dadosAPI) {
            this.setState({ id_questao: this.props.questao.id })
            this.setState({ titulo: this.props.questao.titulo })
            this.setState({ ordem: this.props.questao.ordem })
            this.setState({ tipo_campo: this.props.questao.tipo_campo })
            this.setState({ dadosAPI: true });

        }
        if (typeof this.props.opcoes != "undefined" && this.props.opcoes != null && !this.state.dadosApi) {

        }

    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    listarOpcoes(id_questao) {
        this.props.getOpcoes(id_questao)
        const { opcoes } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (opcoes === "undefined") return null
    }


    async atualizarQuestao(id_questao, titulo, ordem, tipo_campo, id_trilha, id_modulo, id_materia, id_atividade) {
        var id = id_questao
        var id_curso = id_trilha
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        // if (!this.validate()) return;
        this.setState({ carregando: true })
        ////////////console.log(id, titulo, ordem, tipo_campo)
        this.props.putQuestao({ id, titulo, ordem, tipo_campo, id_curso, id_modulo, id_materia, id_atividade }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }


    receberDadosForm() {
        this.setState({ id_questao: document.querySelector("#id_questao").value })
        this.setState({ id_trilha: document.querySelector("#id_trilha").value })
        this.setState({ id_modulo: document.querySelector("#id_modulo").value })
        this.setState({ id_materia: document.querySelector("#id_materia").value })
        this.setState({ id_atividade: document.querySelector("#id_atividade").value })
        this.setState({ titulo: document.querySelector("#titulo").value })
        this.setState({ ordem: document.querySelector("#ordem").value })
        this.setState({ tipo_campo: document.querySelector("#tipo_campo").value })
    }
    // validate() {
    //     const { nome, ordem, descricao, erro } = this.state;
    //     if (!nome) return this.setState({ erro: { message: "Preencha o nome" } });
    //     if (!ordem) return this.setState({ erro: { message: "Preencha uma ordem" } });
    //     if (!descricao) return this.setState({ erro: { message: "Preencha uma descrição" } });
    //     return true;
    // }

    //cadastrar opção
    cadOpcao(id_questao, opcao, ordem) {
        const { id_trilha, id_modulo, id_materia, id_atividade } = this.state;

        var id_curso = id_trilha
        this.setState({ carregando: true })
        this.props.postOpcao({ opcao, id_questao, ordem, id_curso, id_modulo, id_materia, id_atividade }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.props.limparOpcoes()
                this.props.limparQuestao()

                this.listarOpcoes(id_questao)
                this.props.getVerQuestao(id_questao)

                this.sucessoCadOpcao()


            }
        }
        )
    }

    chamarCadOpcao(id_questao) {
        const { idioma } = this.state
        Swal.mixin({

            confirmButtonText: 'Next &rarr;',
            showCancelButton: true,
            progressSteps: ['1', '2']
        }).queue([
            {
                title: idioma.editarQuestao.titulo_op,
                input: 'text',
            },
            {
                title: idioma.editarQuestao.ordem_op,
                input: 'text',
            },

        ]).then((result) => {
            if (result.value) {
                ////////console.log(result.value)
                if (result.value.length < 2) {
                    Swal.fire({
                        title: idioma.editarQuestao.titulo_info,
                        confirmButtonText: idioma.editarQuestao.bt_fechar
                    })
                } else {
                    this.cadOpcao(id_questao, result.value[0], result.value[1])
                }
            }
        })
    }
    sucessoCadOpcao() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarQuestao.modal_sucesso
        })
    }
    render() {
        const { id_materia, id_trilha, id_modulo, id_atividade, id_questao, titulo, ordem, tipo_campo, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;

        var materias = [];
        if (this.props.materias) materias = this.props.materias.dados;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;

        var opcoes = [];
        if (this.props.opcoes) opcoes = this.props.opcoes.dados;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        ////////console.log(opcoes)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editarQuestao.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosAPI} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="titulo">{idioma.editarQuestao.titulo_inp}</Label>
                                            <Input type="text" value={titulo} id="titulo" name="titulo" className="form-control" autoComplete="titulo" placeholder={dadosAPI ? idioma.editarQuestao.titulo_que : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("titulo", ev)} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <Label for="tipo_campo">{idioma.editarQuestao.tipo_campo}</Label>
                                        <Input type="select" value={tipo_campo} id="tipo_campo" name="tipo_campo" className="form-control" autoComplete="tipo_campo" placeholder={dadosAPI ? idioma.editarQuestao.tipo_campo : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("tipo_campo", ev)} >
                                            <option>{idioma.editarAtividade.tipo_quest}</option>
                                            <option value="multipla">
                                                {idioma.editarAtividade.multipla}
                                            </option>
                                            <option value="unica">
                                                {idioma.editarAtividade.unica}
                                            </option>
                                            <option value="curta">
                                                {idioma.editarAtividade.curta}
                                            </option>
                                            <option value="longa">
                                                {idioma.editarAtividade.paragrafo}
                                            </option>
                                        </Input>
                                    </Col>
                                    <Col md={4}>
                                        <Label for="Ordem">{idioma.editarAtividade.sel_ordem}</Label>
                                        <Input type="number" value={ordem} id="ordem" name="ordem" className="form-control" autoComplete="ordem" placeholder={dadosAPI ? idioma.editarAtividade.sel_ordem : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("ordem", ev)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Input type="hidden" value={id_questao} id="id_questao" name="id_questao" />
                                        <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                        <Input type="hidden" value={id_modulo} id="id_modulo" name="id_modulo" />
                                        <Input type="hidden" value={id_materia} id="id_materia" name="id_materia" />
                                        <Input type="hidden" value={id_atividade} id="id_atividade" name="id_atividade" />

                                    </Col>
                                </Row>
                                <Link to="#" onClick={() => { this.chamarCadOpcao(id_questao) }} className="mt-3">
                                    <Botao loading={carregando} texto={idioma.editarQuestao.bt_cadastar} cor="success" icone="plus" posicao="E" />
                                </Link>
                                {opcoes.length != 0 ?

                                    <Table className="mt-3">
                                        <thead>
                                            <tr>
                                                <th className="row-nome">{idioma.editarQuestao.titulo_inp}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {opcoes.map(opcao => (

                                                <tr key={opcao.id}>
                                                    <td>
                                                        {opcao.opcao}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    : <SemDados titulo="Opcões " />}
                                <Link onClick={() => this.atualizarQuestao(id_questao, titulo, ordem, tipo_campo)} to="#">
                                    <Botao loading={carregando} texto="Salvar" />
                                </Link>
                                {credenciais.map(credencial => ((credencial.id_componente == 65) ?

                                    <span onClick={() => this.chamarAlerta(this.props.match.params.id)}>
                                        <Botao loading={carregando} texto={idioma.editarQuestao.bt_del} classes="btn-danger btn-md ml-1" icone="trash-alt" />
                                    </span>
                                    : ""))}
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    questao: state.trilhas.questao,
    trilha: state.trilhas.trilha,
    opcoes: state.trilhas.opcoes



})
export default connect(mapStateToProps, actions)(EditarQuestao)