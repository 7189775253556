export const LOGIN_USER = 'LOGIN_USER',
    LOGIN_ERRO_USER = "LOGIN_ERRO_USER",
    LOGOUT_USER = 'LOGOUT_USER',
    ERRO_USER = "ERRO_USER",
    GET_USERS = 'GET_USERS',
    GET_USER = 'GET_USER',
    GET_LANGUAGE = 'GET_LANGUAGE',
    LIMPAR_USER = 'LIMPAR_USER',
    LIMPAR_USERS = 'LIMPAR_USERS',
    GET_STARTUPS = 'GET_STARTUPS',
    GET_STARTUPS_ATIVAS = "GET_STARTUPS_ATIVAS",
    GET_VERIFICACAO = "GET_VERIFICACAO",
    GET_STARTUPS_GESTAO = "GET_STARTUPS_GESTAO",
    GET_MINHAS_STARTUPS = 'GET_MINHAS_STARTUPS',
    GET_STARTUP = 'GET_STARTUP',
    GET_METODOLOGIAS_STARTUP = "GET_METODOLOGIAS_STARTUP",
    GET_ETAPAS_STARTUP = "GET_ETAPAS_STARTUP",
    GET_ESTAGIOS_STARTUP = "GET_ESTAGIOS_STARTUP",
    GET_INCUBADORAS_ATIVAS = "GET_INCUBADORAS_ATIVAS",
    GET_SEARCH_STARTUPS_INCUBADORA = "GET_SEARCH_STARTUPS_INCUBADORA",
    GET_INCUBADORAS = "GET_INCUBADORAS",
    GET_SEARCH_INCUBADORAS = "GET_SEARCH_INCUBADORAS",
    GET_SEARCH_STARTUP_CONFIG = "GET_SEARCH_STARTUP_CONFIG",
    GET_INCUBADORA = "GET_INCUBADORA",
    GET_TRILHAS = "GET_TRILHAS",
    GET_AUTORES = 'GET_AUTORES',
    LIMPAR_AUTORES = 'LIMPAR_AUTORES',
    GET_TRILHAS_PUBLICAS = "GET_TRILHAS_PUBLICAS",
    GET_TRILHA = "GET_TRILHA",
    GET_SEARCH_TODOS = "GET_SEARCH_TODOS",
    GET_SEARCH_MEUS = "GET_SEARCH_MEUS",
    GET_SEARCH_CURSOS = "GET_SEARCH_CURSOS",
    GET_VERIFICAR = "GET_VERIFICAR",
    GET_SEARCH = "GET_SEARCH",
    GET_PERFIS = "GET_PERFIS",
    GET_PARTICIPANTE_STARTUP = "GET_PARTICIPANTE_STARTUP",
    GET_SEARCH_MINHAS_STARTUP = "GET_SEARCH_MINHAS_STARTUP",
    GET_PARTICIPANTES = "GET_PARTICIPANTES",
    GET_METODOLOGIAS = "GET_METODOLOGIAS",
    GET_PROCESSOS = 'GET_PROCESSOS',
    GET_PROCESSO = 'GET_PROCESSO',
    GET_ETAPAS = 'GET_ETAPAS',
    GET_ESTAGIOS = ' GET_ESTAGIOS',
    GET_ESTAGIO = ' GET_ESTAGIO',
    LIMPAR_ENTREGA_ESTAGIO = 'LIMPAR_ENTREGA_ESTAGIO',
    LIMPAR_PROCESSO_METODOLOGIA = 'LIMPAR_PROCESSO_METODOLOGIA',
    LIMPAR_ETAPA = 'LIMPAR_ETAPA',
    LIMPAR_ESTAGIO = 'LIMPAR_ESTAGIO',
    GET_ENTREGAS = 'GET_ENTREGAS',
    GET_ENTREGA_ESTAGIO = 'GET_ENTREGA_ESTAGIO',
    GET_PERGUNTAS_RADAR = "GET_PERGUNTAS_RADAR",
    GET_RESPOSTAS_RADAR = "GET_RESPOSTAS_RADAR",
    GET_PILARES_RADAR = "GET_PILARES_RADAR",
    GET_DETALHES_RADAR = "GET_DETALHES_RADAR",
    GET_EVIDENCIA = "GET_EVIDENCIA",
    GET_PROGRESSO_PROCESSO = "GET_PROGRESSO_PROCESSO",
    GET_AVALIACAO_TRILHA = 'GET_AVALIACAO_TRILHA',
    GET_ENTREGA = "GET_ENTREGA",
    GET_STATUS_ETAPA = 'GET_STATUS_ETAPA',
    GET_MODULOS = "GET_MODULOS",
    GET_MODULO = "GET_MODULO",
    GET_MATERIAS = "GET_MATERIAS",
    GET_MATERIA = "GET_MATERIA",
    GET_METODOLOGIA = 'GET_METODOLOGIA',
    GET_AULAS = "GET_AULAS",
    GET_TIPO_AULA = "GET_TIPO_AULA",
    GET_AULA = "GET_AULA",
    GET_ATIVIDADES = "GET_ATIVIDADES",
    GET_ATIVIDADE = "GET_ATIVIDADE",
    GET_QUESTOES = "GET_QUESTOES",
    GET_QUESTAO = "GET_QUESTAO",
    GET_OPCOES = "GET_OPCOES",
    GET_OPCAO = "GET_OPCAO",
    GET_MATRICULAS = "GET_MATRICULAS",
    GET_CURSOS_ALUNO = "GET_CURSOS_ALUNO",
    GET_ALUNOS_CURSO = "GET_ALUNOS_CURSO",
    GET_MEUS_CURSOS = "GET_MEUS_CURSOS",
    GET_EVENTOS = "GET_EVENTOS",
    GET_EVENTO = "GET_EVENTO",
    GET_SEARCH_EVENT = "GET_SEARCH_EVENT",
    GET_CONTEUDOS = "GET_CONTEUDOS",
    GET_CONTEUDO = "GET_CONTEUDO",
    GET_TIPO_CONTEUDO = "GET_TIPO_CONTEUDO",
    GET_SEARCH_CONTENT = "GET_SEARCH_CONTENT",
    GET_DESAFIOS = "GET_DESAFIOS",
    GET_DESAFIO = "GET_DESAFIO",
    GET_SOLUCOES = "GET_SOLUCOES",
    GET_SOLUCAO = "GET_SOLUCAO",
    GET_INCUBADORA_STARTUP = "GET_INCUBADORA_STARTUP",
    GET_MINHAS_INCUBADORAS_2 = "GET_MINHAS_INCUBADORAS_2",
    GET_STARTUP_INCUBADORA = "GET_STARTUP_INCUBADORA",
    GET_TODAS_INCUBADORAS = "GET_TODAS_INCUBADORAS",
    GET_RELATORIOS = "GET_RELATORIOS",
    GET_RELATORIOS_DESAFIOS = "GET_RELATORIOS_DESAFIOS",
    GET_RELATORIOS_MENTORIAS = "GET_RELATORIOS_MENTORIAS",
    LIMPAR_PARTICIPANTE = "LIMPAR_PARTICIPANTE",
    LIMPAR_PARTICIPANTES = "LIMPAR_PARTICIPANTES",
    GET_PERFIL_ACESSO = "GET_PERFIL_ACESSO",
    GET_PERFIS_ACESSO = "GET_PERFIS_ACESSO",
    LIMPAR_PERFIL_ACESSO = "LIMPAR_PERFIL_ACESSO",
    LIMPAR_PERFIS_ACESSO = "LIMPAR_PERFIS_ACESSO",
    LIMPAR_STARTUP = "LIMPAR_STARTUP",
    LIMPAR_STARTUPS = "LIMPAR_STARTUPS",
    LIMPAR_ESTAGIOS_STARTUPS = "LIMPAR_ESTAGIOS_STARTUPS",
    LIMPAR_INCUBADORA = "LIMPAR_INCUBADORA",
    LIMPAR_INCUBADORAS = "LIMPAR_INCUBADORAS",
    LIMPAR_INCUBADORAS_STARTUPS = "LIMPAR_INCUBADORAS_STARTUPS",
    LIMPAR_INCUBADORAS_ATIVAS = "LIMPAR_INCUBADORAS_ATIVAS",
    LIMPAR_STARTUPS_ATIVAS = "LIMPAR_STARTUPS_ATIVAS",
    LIMPAR_USUARIOS_INCUBADORA = "LIMPAR_USUARIOS_INCUBADORA",
    LIMPAR_FUTUROS_USUARIOS = "LIMPAR_FUTUROS_USUARIOS",
    LIMPAR_MINHAS_INCUBADORAS = "LIMPAR_MINHAS_INCUBADORAS",
    LIMPAR_ENTREGA = "LIMPAR_ENTREGA",
    LIMPAR_METOLOGIAS = "LIMPAR_METOLOGIAS",
    LIMPAR_MINHAS_STARTUPS = "LIMPAR_MINHAS_STARTUPS",
    LIMPAR_ETAPAS = "LIMPAR_ETAPAS",
    LIMPAR_PERGUNTAS = "LIMPAR_PERGUNTAS",
    LIMPAR_RESPOSTAS = "LIMPAR_RESPOSTAS",
    LIMPAR_METODOLOGIA = 'LIMPAR_METODOLOGIA',
    LIMPAR_MATERIAS = "LIMPAR_MATERIAS",
    LIMPAR_MODULOS = "LIMPAR_MODULOS",
    LIMPAR_MODULO = "LIMPAR_MODULO",
    LIMPAR_AULA = "LIMPAR_AULA",
    LIMPAR_MATERIA = "LIMPAR_MATERIA",
    LIMPAR_TRILHA = "LIMPAR_TRILHA",
    LIMPAR_TRILHAS = "LIMPAR_TRILHAS",
    LIMPAR_CONTEUDOS = "LIMPAR_CONTEUDOS",
    LIMPAR_CONTEUDO = "LIMPAR_CONTEUDO",
    LIMPAR_EVENTO = "LIMPAR_EVENTO",
    LIMPAR_EVENTOS = "LIMPAR_EVENTOS",
    LIMPAR_QUESTOES = "LIMPAR_QUESTOES",
    LIMPAR_QUESTAO = "LIMPAR_QUESTAO",
    LIMPAR_OPCOES = "LIMPAR_OPCOES",
    LIMPAR_ALUNOS = "LIMPAR_ALUNOS",
    LIMPAR_DESAFIOS = "LIMPAR_DESAFIOS",
    LIMPAR_DESAFIO = "LIMPAR_DESAFIO",
    LIMPAR_SOLUCOES = "LIMPAR_SOLUCOES",
    LIMPAR_STARTUP_METODOLOGIA = "LIMPAR_STARTUP_METODOLOGIA",
    GET_VER_PROCESSO = "GET_VER_PROCESSO",
    GET_FUTUROS_PARTICIPANTES = "GET_FUTUROS_PARTICIPANTES",
    GET_CREDENCIAIS = "GET_CREDENCIAIS",
    GET_COMPONENTES = "GET_COMPONENTES",
    GET_SEARCH_STARTUP = "GET_SEARCH_STARTUP",
    GET_SEARCH_INCUBADORA = "GET_SEARCH_INCUBADORA",
    GET_SEGMENTO = "GET_SEGMENTO",
    GET_USUARIOS_INCUBADORA = "GET_USUARIOS_INCUBADORA",
    GET_FUTUROS_USUARIOS = "GET_FUTUROS_USUARIOS",
    GET_MINHAS_INCUBADORAS = "GET_MINHAS_INCUBADORAS",
    GET_RELATORIOS_DESENVOLVIMENTO = "GET_RELATORIOS_DESENVOLVIMENTO",
    GET_RELATORIOS_CONTEUDOS = "GET_RELATORIOS_CONTEUDOS",
    GET_RELATORIOS_INCUBADORA = "GET_RELATORIOS_INCUBADORA",
    GET_RELATORIOS_METODOLOGIA = "GET_RELATORIOS_METODOLOGIA",
    GET_RELATORIOS_STARTUPS = "GET_RELATORIOS_STARTUPS",
    GET_FEITO = "GET_FEITO",
    GET_CHATS = "GET_CHATS",
    GET_CHATS_BUSCA = "GET_CHATS_BUSCA",
    GET_POR_CHAT = "GET_POR_CHAT",
    GET_ULTIMA = "GET_ULTIMA",
    GET_MENSAGENS = "GET_MENSAGENS",
    GET_USUARIOS_PERFIL = "GET_USUARIOS_PERFIL",
    LIMPAR_CHATS = "LIMPAR_CHATS",
    LIMPAR_POR_CHAT = "LIMPAR_POR_CHAT",
    LIMPAR_ULTIMA = "LIMPAR_ULTIMA",
    LIMPAR_MENSAGENS = "LIMPAR_MENSAGENS",
    LIMPAR_USUARIOS_CHAT = "LIMPAR_USUARIOS_CHAT",
    GET_ETAPA = "GET_ETAPA",
    GET_ARQUIVOS_DESAFIO = "GET_ARQUIVOS_DESAFIO",
    GET_ARQUIVOS_SOLUCAO = "GET_ARQUIVOS_SOLUCAO",
    GET_METODOLOGIAS_RADAR = "GET_METODOLOGIAS_RADAR",
    GET_RADARES = "GET_RADARES",
    GET_RADAR = 'GET_RADAR',
    GET_RADARES_METODOLOGIA = "GET_RADARES_METODOLOGIA",
    GET_AVALIADORES = "GET_AVALIADORES",
    LIMPAR_AVALIADORES = "LIMPAR_AVALIADORES",
    GET_AVALIACOES = "GET_AVALIACOES",
    GET_AVALIACOES_GESTOR = "GET_AVALIACOES_GESTOR",
    GET_AVALIADOR = "GET_AVALIADOR",
    GET_PILARES = "GET_PILARES",
    GET_TODOS_ALUNOS = "GET_TODOS_ALUNOS",
    GET_TODOS_ALUNOS_CURSO = "GET_TODOS_ALUNOS_CURSO",
    GET_INCUBADORAS_COMPLETO = "GET_INCUBADORAS_COMPLETO",
    GET_STARTUPS_COMPLETO = "GET_STARTUPS_COMPLETO",
    GET_AVALIACAO = "GET_AVALIACAO",
    LIMPAR_RADARES = "LIMPAR_RADARES",
    LIMPAR_PILARES = "LIMPAR_PILARES",
    GET_SEARCH_TODOS_CURSOS = "GET_SEARCH_TODOS_CURSOS",
    GET_PERSONALIZACAO_INCUBADORA = 'GET_PERSONALIZACAO_INCUBADORA',
    GET_PERSONALIZACOES = 'GET_PERSONALIZACOES',
    GET_PERSONALIZACAO = 'GET_PERSONALIZACAO',
    LIMPAR_PERSONALIZACAO = 'LIMPAR_PERSONALIZACAO',
    GET_PERSONALIZACOES_INCUBADORA = 'GET_PERSONALIZACOES_INCUBADORA',
    GET_INCUBADORAS_PERSONALIZACAO = 'GET_INCUBADORAS_PERSONALIZACAO',
    GET_TODAS_PERSONALIZACOES = 'GET_TODAS_PERSONALIZACOES',
    GET_PERSONALIZACAO_USUARIO = 'GET_PERSONALIZACAO_USUARIO',
    LIMPAR_PERSONALIZACAO_USUARIO = 'LIMPAR_PERSONALIZACAO_USUARIO',
    ALT_SIT_MENU = 'ALT_SIT_MENU',
    GET_NOTIFICACOES = 'GET_NOTIFICACOES',
    GET_JORNADA_GESTAO = 'GET_JORNADA_GESTAO',
    GET_JORNADAS_INCUBADORA = 'GET_JORNADAS_INCUBADORA',
    GET_VINCULOS_METODOLOGIA = 'GET_VINCULOS_METODOLOGIA',
    GET_VINCULOS_RADAR = 'GET_VINCULOS_RADAR',
    GET_METODOLOGIAS_POR_STARTUP = 'GET_METODOLOGIAS_POR_STARTUP',
    GET_RADARES_POR_STARTUP = 'GET_RADARES_POR_STARTUP'
