import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import SemDados from '../../componentes/Mensagens/semDados';

import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/astronaut.png'
import LoadingSkeleton from '../../componentes/Skeleton/index';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { apiImagem } from '../../config/';
import { portugues, ingles } from '../../utils/paginaincubadora_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';
class Startup extends Component {
    state = {
        id_usuario: "",
        currentPage: 1,
        pesquisandoStartup: false,
        filtrandoJornada: false,
        size: 6,
        jornadaSelecionada: 0,
        msg: "",
        erro: "",
        success: "",
        procura: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        keyPress: null,
        keyDown: null,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;
        this.listarStartups();
        if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }
        this.listarJornadas()
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        if (!this.props.startupsGestao && nextProps.startupsGestao) this.listarStartups();
        if (!this.props.jornadasGestao && nextProps.jornadasGestao) this.listarJornadas();
        this.receberDadosAPI();
    }



    componentWillUnmount() {
        this.props.limparMinhasStartups();
        // window.removeEventListener();

    }

    receberDadosAPI() {
        if (typeof this.props.startupsGestao != "undefined" && this.props.startupsGestao != null && !this.state.dadosApi
            && this.props.startupsGestao.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarStartups();
        })
    }
    apagarstartup(id) {
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarStartup(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarStartups();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }
    listarStartups() {
        const { id_usuario, currentPage, size, jornadaSelecionada } = this.state;
        var id;
        if (this.props.usuario) id = this.props.usuario.id
        this.props.getStartupsIncubadoras(id, currentPage, size, jornadaSelecionada)
        const { startupsGestao } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startupsGestao === "undefined") return null
    }
    listarJornadas() {
        var id;
        if (this.props.usuario) id = this.props.usuario.id
        this.props.getJornadasIncubadoras(id)
        const { jornadasGestao } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (jornadasGestao === "undefined") return null
    }
    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.gestao.alerta_titulo,
            text: idioma.gestao.alerta_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.gestao.bt_alerta
        }).then((result) => {
            if (result.value) {
                this.apagarstartup(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.gestao.user_alerta
        })
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }
    onChangeFilter() {
        const { filtrandoJornada, pesquisandoStartup, procura } = this.state;
        if (filtrandoJornada) {
            this.setState({ filtrandoJornada: false })
            this.setState({ jornadaSelecionada: 0 })
            this.props.limparMinhasStartups();
            if (pesquisandoStartup) {
                this.handleSearchNomeStartup(procura)
            } else {
                this.listarStartups()
            }
        } else {
            this.setState({ filtrandoJornada: true })
            if (pesquisandoStartup) {
                this.handleSearchNomeStartup(procura)
            } else {
                this.listarStartups()
            }
        }

    }

    onChangeJornada(campo, id) {
        this.setState({ [campo]: id })
        this.props.limparMinhasStartups();
        this.setState({ dadosApi: false });
        const { pesquisandoStartup, procura } = this.state
        if (pesquisandoStartup) {
            this.handleSearchNomeStartup(procura)
        } else {
            this.listarStartups()
        }
    }

    handleSearchNomeStartup(procura) {
        this.setState({ procura: procura })
        this.setState({ pesquisandoStartup: true })
        const { currentPage, size, id_usuario, jornadaSelecionada } = this.state;
        this.props.getSearchStartupsIncubadoras(procura, id_usuario, currentPage, size, jornadaSelecionada)
    }

    eraseSearchStartup(campo) {
        this.setState({ pesquisandoStartup: false })
        this.setState({ [campo]: "" })
        this.listarStartups();


    }

    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            this.handleSearchNomeStartup(procura)
        }
    }

    render() {
        const { id, msg, procura, pesquisandoStartup, carregando, erro, success, dadosApi, idioma, jornadaSelecionada, filtrandoJornada } = this.state;
        var startups = [];
        var credenciais = [];
        var jornadas = [];
        var usuario = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.jornadasGestao) jornadas = this.props.jornadasGestao.dados
        if (pesquisandoStartup == false) {
            if (this.props.startupsGestao) startups = this.props.startupsGestao.dados
            if (this.props.usuario) usuario = this.props.usuario
            var temPaginaAnterior = false;
            if (typeof this.props.startupsGestao != "undefined" && this.props.startupsGestao != null && this.props.startupsGestao.currentPage !== "" && this.props.startupsGestao.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.startupsGestao != "undefined" && this.props.startupsGestao !== null && this.props.startupsGestao.nextPage <= this.props.startupsGestao.totalPages && this.props.startupsGestao.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }

        } else {
            if (this.props.startupsGestao) startups = this.props.startupsGestao.dados;
            var temPaginaAnteriorStartup = false;
            if (typeof this.props.startupsGestao != "undefined" && this.props.startupsGestao != null && this.props.startupsGestao.currentPage !== "" && this.props.startupsGestao.currentPage !== 1) {
                temPaginaAnteriorStartup = true;
            }
            var proximaPaginaStartup = false;
            var temPaginaAnteriorStartup = false;
            if (typeof this.props.startupsGestao != "undefined" && this.props.startupsGestao !== null && this.props.startupsGestao.nextPage <= this.props.startupsGestao.totalPages && this.props.startupsGestao.nextPage != null) {
                proximaPaginaStartup = true;
                temPaginaAnteriorStartup = true;
            }
        }
        var semImagem = apiImagem + "fundo_startup.jpg";
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.gestao.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <div>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisa} disabled={!dadosApi} />
                                    </div>
                                    <div className="col-lg-4">
                                        <div>
                                            <span className="pr-3" onClick={() => this.handleSearchNomeStartup(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                            <span className="pr-3" value={procura} onClick={() => this.eraseSearchStartup("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                        </div>
                                    </div>
                                    {/* {jornadas.length != 0 ? */}
                                    <div className="col-lg-12 mt-3">
                                        <span onClick={() => this.onChangeFilter()} id="filtrar" name="filtrar"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={!filtrandoJornada ? this.props.usuario?.personalizacao?.cor_terciaria : ""} cor={filtrandoJornada ? "danger" : ""} texto={!filtrandoJornada ? idioma.bt_filtrar : idioma.bt_cancelar} icone={!filtrandoJornada ? "filter" : "times"} posicao="E" /></span>
                                        {filtrandoJornada &&
                                            <div>
                                                <span className={jornadaSelecionada == 0 ? "badge badge-success cursor-pointer mr-3 mt-4 py-2 px-3" : "badge badge-secondary cursor-pointer mr-3 mt-4 py-2 px-3"} onClick={() => this.onChangeJornada("jornadaSelecionada", 0)}>TODAS</span>
                                                {jornadas.map(jornada => (
                                                    <span className={jornadaSelecionada == jornada.id ? "badge badge-success cursor-pointer mr-3 mt-4 py-2 px-3" : "badge badge-secondary cursor-pointer mr-3 mt-4 py-2 px-3"} onClick={() => this.onChangeJornada("jornadaSelecionada", jornada.id)}>{jornada.nome}</span>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                    {/* : ""} */}
                                </div>
                                {dadosApi ?
                                    <div>
                                        {startups.length != 0 ?

                                            <div className="row">
                                                {startups.map(startup => (
                                                    <div className="col-md-4" key={startup.id}>
                                                        <div className="directory-card  card-startups">
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/acompanhamento"}>
                                                                <div className="directory-card card">
                                                                    <div>
                                                                        <div className="text-center directory-bg" style={(startup.fileNameCapa !== null) ? { backgroundImage: `url(${apiImagem}${startup.fileNameCapa})` } : { backgroundImage: `url(${semImagem})` }}>

                                                                            <div className="directory-overlay">
                                                                                <img className="rounded-circle avatar-lg img-thumbnail" src={startup.fileName ? apiImagem + startup.fileName : iconeUsuario} alt={startup.nome}></img>
                                                                            </div>
                                                                        </div>
                                                                        <div className="directory-content text-center">
                                                                            <div className="titulo-startup">{startup.nome}</div>
                                                                            <span className="texto-cidade"> <FontAwesomeIcon icon="map-marker-alt" color="black" className="mr-2" />{" "} {startup.cidade}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.gestao.info_titulo} />
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="directory-card  card-startups">
                                                    <div className="directory-card card">
                                                        <div>
                                                            <div className="directory-skeleton-bg text-center">
                                                                <div className="directory-overlay">
                                                                    <Skeleton circle={true} height={100} width={100} />
                                                                </div>
                                                            </div>
                                                            <div className="directory-content text-center">
                                                                <div className="titulo-startup">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                                <span className="texto-cidade">
                                                                    <Skeleton count={1} width={60} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="directory-card  card-startups">
                                                    <div className="directory-card card">
                                                        <div>
                                                            <div className="directory-skeleton-bg text-center">
                                                                <div className="directory-overlay">
                                                                    <Skeleton circle={true} height={100} width={100} />
                                                                </div>
                                                            </div>
                                                            <div className="directory-content text-center">
                                                                <div className="titulo-startup">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                                <span className="texto-cidade">
                                                                    <Skeleton count={1} width={60} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="directory-card  card-startups">
                                                    <div className="directory-card card">
                                                        <div>
                                                            <div className="directory-skeleton-bg text-center">
                                                                <div className="directory-overlay">
                                                                    <Skeleton circle={true} height={100} width={100} />
                                                                </div>
                                                            </div>
                                                            <div className="directory-content text-center">
                                                                <div className="titulo-startup">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                                <span className="texto-cidade">
                                                                    <Skeleton count={1} width={60} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>
                            {dadosApi && startups.length != 0 ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                            <span className="page-link" onClick={() => this.changePageAtual(1)} >{idioma.paginacao1}</span>
                                        </li>

                                        {temPaginaAnterior ? <li className="page-item"><span className="page-link" onClick={() => this.changePageAtual(this.props.startupsGestao.prevPage ? this.props.startupsGestao.prevPage : 1)}>{this.props.startupsGestao.prevPage ? this.props.startupsGestao.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link">{this.props.startupsGestao ? this.props.startupsGestao.currentPage : "1"}</span></li>

                                        {proximaPagina ? <li className="page-item"><span className="page-link" onClick={() => this.changePageAtual(this.props.startupsGestao.nextPage ? this.props.startupsGestao.nextPage : 1)}>{this.props.startupsGestao.nextPage ? this.props.startupsGestao.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                            <span className="page-link" onClick={() => this.changePageAtual(this.props.startupsGestao ? this.props.startupsGestao.totalPages : 0)}>{idioma.paginacao2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    startupsGestao: state.startups.startupsGestao,
    jornadasGestao: state.startups.jornadasGestao,
    usuario: state.auth.usuario
})
export default connect(mapStatetoProps, actions)(Startup);