import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'

export default class YourApp extends Component {
    constructor(props) {
        super(props)
        this.idleTimer = null
        this.handleOnIdle = this.handleOnIdle.bind(this)
    }

    render() {
        return (
            <div>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 5}
                    onIdle={this.handleOnIdle}
                    debounce={250}
                />
            </div>
        )
    }

    handleOnIdle(event) {
        //////console.log('user is idle', event)
        //////console.log('last active', this.idleTimer.getLastActiveTime(), this.idleTimer)
    }
}

