import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';

class CadastrarEntrega extends Component {
  state = {
    nome_entrega: '',
    descricao: '',
    tipo_campo: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    msg_backend: portuguese,
    idioma: portugues,
    capa: '',
    capaPreview: ''
  }
  componentDidMount() {

    const { usuario } = this.props;

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }



  handleNovaEntrega() {
    const { nome_entrega, descricao, tipo_campo } = this.state;
    const { id_metodologia, id_processo, id_etapa, id_estagio } = this.props.match.params;
    if (!this.validate()) return;
    this.setState({ carregando: true })
    let newData;
    if (tipo_campo) {
      newData = {
        nome: nome_entrega,
        tipo_campo,
        descricao,
        id_estagio: Number(id_estagio),
        id_etapa: Number(id_etapa),
        id_processo: Number(id_processo),
        id_metodologia: Number(id_metodologia)
      }
    } else {
      newData = {
        nome: nome_entrega,
        descricao,
        id_estagio: Number(id_estagio),
        id_etapa: Number(id_etapa),
        id_processo: Number(id_processo),
        id_metodologia: Number(id_metodologia)
      }
    }
    this.props.postNovaEntrega(
      newData, (msg) => {
        if (msg.erro.error) {
          this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
          this.setState({ success: "" });
          this.setState({ carregando: false });
        } else {
          this.setState({ erro: "" });
          this.setState({ carregando: false });
          this.setState({ formSuccess: true });
        }
      }
    )
  }

  validate() {
    const { nome_entrega, idioma, descricao } = this.state;
    if (!nome_entrega) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro } });
    if (!descricao) return this.setState({ erro: { message: idioma.cadastrarEntrega.erro_desc } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }


  render() {
    const { carregando, erro, formSuccess, idioma, nome_entrega, descricao, tipo_campo } = this.state;
    const { id_metodologia, id_processo, id_etapa, id_estagio } = this.props.match.params;

    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/estagio/${id_estagio}/entregas/listar`,
      state: idioma.cadastrarEntrega.msg_sucesso
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.cadastrarEntrega.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome_entrega', e)} value={nome_entrega} autoComplete="nome_entrega" required name="nome_entrega" id="nome_entrega" placeholder={idioma.cadastrarEntrega.nome_placeholder} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="tipo">{idioma.cadastrarEntrega.tipo}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("tipo_campo", ev)} id="tipo_campo" name="tipo_campo" >
                      <option>{idioma.cadastrarEntrega.tipo_placeholder}</option>
                      <option value="pdf"> {idioma.cadastrarEntrega.pdf} </option>
                      <option value="imagem"> {idioma.cadastrarEntrega.img} </option>
                      <option value="link"> {idioma.cadastrarEntrega.vid} </option>
                      <option value="texto"> {idioma.cadastrarEntrega.text} </option>
                      <option value="arquivo"> {idioma.cadastrarEntrega.arq} </option>
                      <option value=""> {idioma.cadastrarEntrega.todos} </option>
                    </Input>
                  </FormGroup>
                  <FormGroup className='mb-5'>
                    <Label for='descricao'>{idioma.cadastrarEntrega.desc}</Label>
                    <Input type="textarea" value={descricao} autoComplete="descricao" onChange={(ev) => this.onChangeInput("descricao", ev)} name="descricao" id="descricao" placeholder={idioma.cadastrarEntrega.desc} />
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleNovaEntrega()}> <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  processos: state.startups.processos,
})
export default connect(mapStatetoProps, actions)(CadastrarEntrega);