export const env = process.env.REACT_APP_ENV;
export const api = process.env.REACT_APP_API;

export const apiImagem = api + "/arquivos/imagens/";
export const apiAula = api + "/arquivos/aula/";
export const apiEvidencia = api + "/arquivos/evidencia/";
export const apiTermoSigilo = api + "/arquivos/termo_de_sigilo.docx";
export const termosPrivacidade = api + "/arquivos/politica_privacidade.pdf";
export const termosUso = api + "/arquivos/termo_de_uso.pdf";
export const privacyPolicy = api + "/arquivos/privacy_policy.pdf";
export const termsUse = api + "/arquivos/terms_of_use.pdf";

