import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/radares';
import { Link } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import BotaoIcon from '../../../componentes/BotaoIcon';
import Swal from 'sweetalert2';
import { Table } from 'reactstrap';
import ReactTooltip from "react-tooltip";
import Toast from '../../../componentes/Alertas/Toast';
import Titulo from '../../../componentes/TituloPaginas';

import { ingles, portugues } from '../../../utils/paginaradar_bilingue';
import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { Skeleton } from '@material-ui/lab';

class ListarTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 20,
        trilha: "",
        id_radarmetodologia: "",
        id_pilar: "",
        id_radar: "",
        id_pergunta: '',
        trilha: "",
        msg: "",
        id_radar: "",
        erro: "",
        success: "",
        listaOpcoes: [],
        carregando: false,
        verificado: false,
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        const { id_radar, id_pilar, id_pergunta } = this.props.match.params
        this.setState({ id_radarmetodologia: id_radar })
        this.setState({ id_pilar })
        this.setState({ id_radar: id_pergunta })
        this.setState({ id_pergunta })
        this.listarOpcoes(id_pergunta)

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        const { id_radar, id_pilar, id_pergunta } = this.props.match.params
        if (!this.props.opcoes && nextProps.opcoes) {
            this.listarOpcoes(id_pergunta);
            this.arrayOpcoes(nextProps.opcoes.dados)
        }
        if (this.props.opcoes && !this.state.verificado) {
            this.arrayOpcoes(this.props.opcoes.dados)
        }
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparOpcoes()
    }
    receberDadosAPI() {
        if (typeof this.props.opcoes != "undefined" && this.props.opcoes != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }

    }

    listarOpcoes(id_radar) {
        this.props.getOpcoes(id_radar)
        const { opcoes } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (opcoes === "undefined") return null
    }

    arrayOpcoes(opcoes) {
        let lista = []
        this.setState({ verificado: true })
        lista.push({ posicao: 1, opcao: opcoes.opcao1, peso: opcoes.nota1 })
        lista.push({ posicao: 2, opcao: opcoes.opcao2, peso: opcoes.nota2 })
        lista.push({ posicao: 3, opcao: opcoes.opcao3, peso: opcoes.nota3 })
        lista.push({ posicao: 4, opcao: opcoes.opcao4, peso: opcoes.nota4 })
        lista.push({ posicao: 5, opcao: opcoes.opcao5, peso: opcoes.nota5 })
        lista.push({ posicao: 6, opcao: opcoes.opcao6, peso: opcoes.nota6 })
        lista.push({ posicao: 7, opcao: opcoes.opcao7, peso: opcoes.nota7 })
        lista.push({ posicao: 8, opcao: opcoes.opcao8, peso: opcoes.nota8 })
        lista.push({ posicao: 9, opcao: opcoes.opcao9, peso: opcoes.nota9 })
        lista.push({ posicao: 10, opcao: opcoes.opcao10, peso: opcoes.nota10 })
        const newLista = lista.filter((item) => item.opcao !== '');
        this.setState({ listaOpcoes: newLista })
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    chamarEdit(posicao, nome) {
        const { listaOpcoes, idioma } = this.state
        let lista = []
        lista = listaOpcoes
        Swal.fire({
            title: idioma.opcoes.modal_titulo,
            input: 'text',
            inputPlaceholder: nome,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.bt_cadastrar
        }).then((result) => {
            if (result.value) {
                for (let l in listaOpcoes) {
                    if (listaOpcoes[l].posicao == posicao) {
                        lista[l].opcao = result.value
                        this.setState({ listaOpcoes: lista })
                    }
                }

                this.editarOpcao();
            }
        })
    }

    editarOpcao() {
        const { id_radar, listaOpcoes, msg_backend, idioma } = this.state
        this.setState({ carregando: true })
        this.props.putOpcoes({ id_radar, listaOpcoes }, (msg) => {
            if (msg.erro.error) {
                Toast({ icon: 'error', title: idioma.erro, text: msg_backend[msg.erro.code] })
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                Toast({ icon: 'success', title: idioma.sucesso, text: msg_backend[msg.erro.code] })
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.listarOpcoes(id_radar)

            }
        })
    }


    render() {
        const { id_radarmetodologia, id_pilar, listaOpcoes, id_pergunta, msg, carregando, erro, success, dadosApi, formSuccess, idioma } = this.state;
        const { credenciais, usuario } = this.props

        var opcoes = [];
        if (this.props.opcoes) opcoes = this.props.opcoes.dados;
        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.opcoes.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + id_radarmetodologia + "/pilar/" + id_pilar + "/perguntas"} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.opcoes.op}</th>
                                                            <th className="coluna-tabela">{idioma.opcoes.peso}</th>
                                                            <th className="coluna-tabela">{idioma.opcoes.acao}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {listaOpcoes.map(opcao => (
                                                            <tr>
                                                                <td>{opcao.opcao}</td>
                                                                <td>{opcao.peso}</td>
                                                                <td>

                                                                    <a data-tip data-for='editar'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + '/radar/' + id_radarmetodologia + '/pilar/' + id_pilar + '/pergunta/' + id_pergunta + '/editar'} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-primary btn-sm ml-1" icone="edit" />
                                                                        </Link>
                                                                    </a>
                                                                    <ReactTooltip id='editar' type='dark'>
                                                                        <span>{idioma.opcoes.bt_editar}</span>
                                                                    </ReactTooltip>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    opcoes: state.radares.opcoes,

})
export default connect(mapStatetoProps, actions)(ListarTrilhas);