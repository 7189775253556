import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SemDados from '../../componentes/Mensagens/semDados';
import Swal from 'sweetalert2';
import { Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/astronaut.png'
import Skeleton from "react-loading-skeleton";
import { apiImagem } from '../../config/';

import { ingles, portugues } from '../../utils/paginaambientedeinovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';
class Incubadora extends Component {
    state = {
        id_usuario: "",
        currentPage: 1,
        pesquisandoIncubadora: false,
        size: 6,
        msg: "",
        erro: "",
        success: "",
        procura: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;
        if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }
        this.listarIncubadoras();

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.minhasIncubadoras && nextProps.minhasIncubadoras) this.listarIncubadoras();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparMinhasIncubadoras();
    }
    receberDadosAPI() {
        if (typeof this.props.minhasIncubadoras != "undefined" && this.props.minhasIncubadoras != null && !this.state.dadosApi && this.props.minhasIncubadoras.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarStartups();
        })
    }
    apagaIncubadora(id) {
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarIncubadora(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarIncubadoras();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }
    listarIncubadoras() {
        var id;
        if (this.props.usuario) { id = this.props.usuario.id }
        const { currentPage, size } = this.state;
        this.props.getMinhasIncubadoras(id, currentPage, size)
        const { minhasIncubadoras } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (minhasIncubadoras === "undefined") return null
    }
    chamarAlerta(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_titulo,
            text: idioma.modal_texto,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.bt_salvar
        }).then((result) => {
            if (result.value) {
                this.apagarIncubadoras()
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleSearchNomeIncubadora(procura) {
        this.setState({ procura: procura })
        this.setState({ pesquisandoIncubadora: true })
        const { currentPage, size, id_usuario } = this.state;
        this.props.getSearchNameIncubadoras(procura, id_usuario, currentPage, size)
    }

    eraseSearchIncubadora(campo) {
        this.setState({ pesquisandoIncubadora: false })
        this.setState({ [campo]: "" })


    }

    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            this.handleSearchNomeIncubadora(procura)
        }
    }

    render() {
        const { msg, procura, pesquisandoIncubadora, carregando, erro, success, dadosApi, idioma } = this.state;
        const { usuario } = this.props;
        var incubadoras = [];
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;

        if (pesquisandoIncubadora == false) {
            if (this.props.minhasIncubadoras) incubadoras = this.props.minhasIncubadoras.incubadoras
            var temPaginaAnterior = false;
            if (typeof this.props.minhasIncubadoras != "undefined" && this.props.minhasIncubadoras != null && this.props.minhasIncubadoras.currentPage !== "" && this.props.minhasIncubadoras.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.minhasIncubadoras != "undefined" && this.props.minhasIncubadoras !== null && this.props.minhasIncubadoras.nextPage <= this.props.minhasIncubadoras.totalPages && this.props.minhasIncubadoras.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        } else {
            if (this.props.searchIncubadora) incubadoras = this.props.searchIncubadora.dados
            var temPaginaAnterior = false;
            if (typeof this.props.searchIncubadora != "undefined" && this.props.searchIncubadora != null && this.props.searchIncubadora.currentPage !== "" && this.props.searchIncubadora.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.searchIncubadora != "undefined" && this.props.searchIncubadora !== null && this.props.searchIncubadora.nextPage <= this.props.searchIncubadora.totalPages && this.props.searchIncubadora.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        }
        var semImagem = apiImagem + "fundo_startup.jpg";
        ////////console.log(incubadoras)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />

                            {/* {credenciais ? credenciais.map(credencial => ((credencial.id_componente == 38) ? */}
                            <div>
                                {dadosApi ?

                                    <div>
                                        <div className="row">
                                            <div className="col-md-8">
                                                <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisa} />
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <span className="pr-3" onClick={() => this.handleSearchNomeIncubadora(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="botao-pesquisa-responsivo" texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                                    <span value={procura} onClick={() => this.eraseSearchIncubadora("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" classes="botao-pesquisa-responsivo" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                                </div>
                                            </div>
                                        </div>
                                        {incubadoras.length != 0 ?

                                            <div className="row">
                                                {incubadoras.map(incubadora => (
                                                    <div className="col-md-4" key={incubadora.id}>
                                                        <div className="directory-card  card-startups">
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/incubadoras/" + incubadora.id + "/startups"}>
                                                                <div className="directory-card card">
                                                                    <div>
                                                                        <div className="directory-bg text-center" style={(incubadora.fileNameCapa !== null) ? { backgroundImage: `url(${apiImagem}${incubadora.fileNameCapa})` } : { backgroundImage: `url(${semImagem})` }}>
                                                                            <div className="directory-overlay">
                                                                                <img className="rounded-circle avatar-lg img-thumbnail" src={incubadora.fileName != null ? apiImagem + incubadora.fileName : iconeUsuario} alt={incubadora.nome}></img>
                                                                            </div>
                                                                        </div>
                                                                        <div className="directory-content text-center">
                                                                            <div className="titulo-startup">{incubadora.nome}</div>
                                                                            <span className="texto-cidade"> <FontAwesomeIcon icon="map-marker-alt" color="black" className="mr-2" />{" "} {incubadora.cidade}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.sem_dados} />
                                        }
                                    </div>


                                    :
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="directory-card  card-startups">
                                                    <div className="directory-card card">
                                                        <div>
                                                            <div className="directory-skeleton-bg text-center">
                                                                <div className="directory-overlay">
                                                                    <Skeleton circle={true} height={100} width={100} />
                                                                </div>
                                                            </div>
                                                            <div className="directory-content text-center">
                                                                <div className="titulo-startup">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                                <span className="texto-cidade">
                                                                    <Skeleton count={1} width={60} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="directory-card  card-startups">
                                                    <div className="directory-card card">
                                                        <div>
                                                            <div className="directory-skeleton-bg text-center">
                                                                <div className="directory-overlay">
                                                                    <Skeleton circle={true} height={100} width={100} />
                                                                </div>
                                                            </div>
                                                            <div className="directory-content text-center">
                                                                <div className="titulo-startup">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                                <span className="texto-cidade">
                                                                    <Skeleton count={1} width={60} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="directory-card  card-startups">
                                                    <div className="directory-card card">
                                                        <div>
                                                            <div className="directory-skeleton-bg text-center">
                                                                <div className="directory-overlay">
                                                                    <Skeleton circle={true} height={100} width={100} />
                                                                </div>
                                                            </div>
                                                            <div className="directory-content text-center">
                                                                <div className="titulo-startup">
                                                                    <Skeleton count={1} width={80} />
                                                                </div>
                                                                <span className="texto-cidade">
                                                                    <Skeleton count={1} width={60} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>
                            {/* : "")) : ""} */}
                            {
                                dadosApi && incubadoras.length != 0 ?
                                    <nav aria- label="Page navigation example" >
                                        <ul className="pagination justify-content-center">
                                            <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                                            </li>

                                            {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadoras.prevPage ? this.props.incubadoras.prevPage : 1)}>{this.props.incubadoras.prevPage ? this.props.incubadoras.prevPage : ""}</span></li> : ""}

                                            <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.incubadoras ? this.props.incubadoras.currentPage : "1"}</span></li>

                                            {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadoras.nextPage ? this.props.incubadoras.nextPage : 1)}>{this.props.incubadoras.nextPage ? this.props.incubadoras.nextPage : ""}</span></li> : ""}

                                            <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadoras ? this.props.incubadoras.totalPages : 0)}>{idioma.pasge2}</span>
                                            </li>
                                        </ul>
                                    </nav>
                                    : ""}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    minhasIncubadoras: state.incubadoras.minhasIncubadoras,
    searchIncubadora: state.incubadoras.searchIncubadora,
    usuario: state.auth.usuario
})
export default connect(mapStatetoProps, actions)(Incubadora);