import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import SemDados from '../../componentes/Mensagens/semDadosEditavel';

import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/astronaut.png'
import LoadingSkeleton from '../../componentes/Skeleton/index';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { apiImagem } from '../../config/';

import { ingles, portugues } from '../../utils/paginabeneficios_bilingue';

import Titulo from '../../componentes/TituloPaginas';
class Beneficios extends Component {
    state = {
        id_usuario: "",
        currentPage: 1,
        pesquisandoStartup: false,
        size: 6,
        msg: "",
        erro: "",
        success: "",
        procura: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        keyPress: null,
        keyDown: null,
        idioma: portugues
    }
    componentDidMount() {
        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
        } else {
            this.setState({ idioma: portugues })
        }
    }
    // componentDidUpdate(nextProps) {
    //     this.receberDadosAPI();
    // }



    // componentWillUnmount() {

    // }

    // receberDadosAPI() {
    // }

    render() {
        const { dadosApi, idioma } = this.state;
        const { usuario } = this.props;

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    {/* <h2 className="display-4 titulo" style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_titulo }} >Benefícios</h2> */}
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
                            <div >
                                <div>
                                    <SemDados titulo={idioma.body} texto={idioma.text} subtexto={idioma.subtexto} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    usuario: state.auth.usuario
})
export default connect(mapStatetoProps, actions)(Beneficios);