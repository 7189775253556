import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/radares';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaradar_bilingue';
import Toast from '../../../componentes/Alertas/Toast';

class CadastrarRadar extends Component {
  state = {
    nome: '',
    peso_maximo: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    msg_backend: portuguese,
    idioma: portugues,
  }
  componentDidMount() {

    const { usuario } = this.props;

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }


  validate() {
    const { nome, idioma, peso_maximo } = this.state;
    if (!nome) return this.setState({ erro: { message: idioma.cadastrarRadar.nome_placeholder } });
    if (!peso_maximo) return this.setState({ erro: { message: idioma.cadastrarRadar.nota_maxima_placeholder } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  handleNovoRadar() {
    const { msg_backend, idioma, nome, peso_maximo } = this.state
    if (!this.validate()) return;
    this.setState({ carregando: true })
    this.props.postRadar({ nome, peso_maximo }, (msg) => {
      if (msg.erro.error) {
        Toast({ icone: 'error', titulo: idioma.erro, texto: msg_backend[msg.erro.code] })
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ carregando: false });
      } else {
        Toast({ icone: 'success', titulo: idioma.sucesso, texto: msg_backend[msg.erro.code] })
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
      }
    })
  }

  render() {
    const { carregando, erro, formSuccess, idioma, nome, peso_maximo } = this.state;

    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/radar`,
      state: { msg: idioma.cadastrarRadar.msg_sucesso }
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.cadastrarRadar.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.cadastrarRadar.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome', e)} value={nome} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarRadar.nome_placeholder} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="peso_maximo">{idioma.cadastrarRadar.nota_maxima}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("peso_maximo", ev)} id="peso_maximo" name="peso_maximo" >
                      <option>{idioma.cadastrarRadar.nota_maxima_placeholder}</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                    </Input>
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleNovoRadar()}> <Botao loading={carregando} texto={idioma.bt_cadastrar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({})
export default connect(mapStatetoProps, actions)(CadastrarRadar);