import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Col, Row, Button, Form, FormGroup, Label, Input, DropdownButton, Dropdown } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { MenuItem, Select } from '@material-ui/core';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginausuario_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class Cadastrar extends Component {
    state = {
        nome: "",
        senha: "",
        email: "",
        id_perfil: "",
        cod_idioma: '',
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { usuario } = this.props;
        this.props.getPerfis();

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }
    cadUsuario() {
        const { nome, email, senha, id_perfil, cod_idioma } = this.state;
        if (!this.validate()) return;
        const id_idioma = cod_idioma === 'portugues' ? 1 : 3;
        this.setState({ carregando: true })
        this.props.postUsuario({ nome, email, senha, id_perfil, id_idioma }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { nome, email, senha, id_perfil, cod_idioma, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrar.validate.nome } });
        if (!email) return this.setState({ erro: { message: idioma.cadastrar.validate.email } });
        if (!validator.isEmail(email)) return this.setState({ erro: { message: idioma.cadastrar.validate.email2 } });
        if (!senha) return this.setState({ erro: { message: idioma.cadastrar.validate.senha } });
        if (senha.length < 6) return this.setState({ erro: { message: idioma.cadastrar.validate.senha_length } });
        if (!id_perfil) return this.setState({ erro: { message: idioma.cadastrar.validate.credencial } });
        if (!cod_idioma) return this.setState({ erro: { message: idioma.cadastrar.validate.idioma } });
        return true;
    }
    render() {
        const { nome, email, senha, erro, success, carregando, iconSalvar, formSuccess, id_perfil, idioma, cod_idioma } = this.state;
        var perfis = [];

        var credenciais = []
        var selIdiomaDisable = true;
        if (this.props.credenciais) credenciais = this.props.credenciais;
        for (let c in credenciais) {
            if (credenciais[c].id_componente === 98) selIdiomaDisable = false;
        }
        if (this.props.perfis) perfis = this.props.perfis.dados;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + this.props.usuario?.personalizacao?.titulo + "/usuarios/",
            state: { msg: idioma.cadastrar.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrar.titulo} />
                </div>
                <div className="list-group-item bloco-card">
                    <div>
                        <AlertaErro erros={erro} />
                        <AlertaSuccess erros={success} />
                        <Form>
                            <FormGroup>
                                <Label for="nome">{idioma.cadastrar.label_nome}</Label>
                                <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrar.label_nome} />
                            </FormGroup>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">{idioma.cadastrar.label_email}</Label>
                                        <Input type="email" value={email} autoComplete="email" onChange={(ev) => this.onChnageInput("email", ev)} name="email" id="exampleEmail" placeholder={idioma.cadastrar.label_email} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="senha">{idioma.cadastrar.label_senha}</Label>
                                        <Input type="password" value={senha} autoComplete="senha" onChange={(ev) => this.onChnageInput("senha", ev)} name="senha" id="senha" placeholder={idioma.cadastrar.label_senha} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/*select bar com as credenciais*/}
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="perfil">{idioma.cadastrar.label_perfil}</Label>
                                        <Input type="select" onChange={(ev) => this.onChnageInput("id_perfil", ev)} id="id_perfil" name="id_perfil">
                                            <option>{idioma.cadastrar.place_perfil}</option>
                                            {perfis.map(perfil => (
                                                <option value={perfil.id}>
                                                    {perfil.nome}
                                                </option>))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="idioma">{idioma.cadastrar.label_idioma}</Label>
                                        <Input type='select' onChange={(ev) => this.onChnageInput('cod_idioma', ev)} id='cod_idioma' name='cod_idioma'>
                                            <option value=''>{idioma.cadastrar.selecioneIdioma}</option>
                                            <option value='portugues'>{idioma.cadastrar.portugues}</option>
                                            {credenciais.map(credencial => ((credencial.id_componente === 98) ?
                                                <option value='ingles'>{idioma.cadastrar.ingles}</option>
                                                : ""))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Link to="#" onClick={() => this.cadUsuario()}> <Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.cadastrar.bt_cadastrar} icone={iconSalvar} /> </Link>
                        </Form>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario,
    perfis: state.usuarios.perfis
})

export default connect(mapStateToProps, actions)(Cadastrar);