import React, { Component } from 'react';
import { Form, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import Botao from '../../componentes/Botoes'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { apiImagem } from '../../config/';
import { Link, Redirect } from 'react-router-dom';
import validator from 'validator';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { portugues, ingles } from '../../utils/paginalogin_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
library.add(fas);

class RecuperarSenha extends Component {
    state = {
        email: "",
        erro: "",
        msg: "",
        success: "",
        carregando: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg });
            this.props.location.state.msg = "";
        }

        const idiomaLocal = localStorage.getItem('idioma');
        if (idiomaLocal) {
            if (idiomaLocal === 'ingles') {
                this.setState({ idioma: ingles })
                this.setState({ msg_backend: english })
            } else {
                this.setState({ idioma: portugues })
                this.setState({ msg_backend: portuguese })
            }
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value })
    }
    recuperarSenha() {
        const { email } = this.state;
        if (!this.validate()) return;
        this.setState({ carregando: true });
        this.props.recupSenha({ email }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })
    }

    validate() {
        const { email, idioma } = this.state;
        if (!email) return this.setState({ erro: { message: idioma.erro_email } })
        if (!validator.isEmail(email)) return this.setState({ erro: { message: idioma.erro_senha } })
        return true;
    }

    handleLanguage(tipo, ev) {
        ev.preventDefault();
        if (tipo === 'ingles') {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
            localStorage.setItem('idioma', 'ingles')
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
            localStorage.setItem('idioma', 'portugues')
        }
    }

    render() {
        const { email, erro, msg, success, carregando, formSuccess, idioma } = this.state;
        const { usuarioPersonalizacao } = this.props;
        const { personalizacao } = this.props.match.params;
        if (formSuccess) {
            return <Redirect to={{
                pathname: '/',
                state: { msg: idioma.msg_sucesso }
            }} />
        }
        return (
            <>
                <div className="tela-login container-fluid d-flex h-100 flex-column">
                    <div className="row" style={{ height: '98vh' }}>
                        <div className="col-md-3 col-sm-12 sem-padding">
                            <div className="login">
                                <Form className="form-signin text-center">
                                    <img className="mb-4" src={usuarioPersonalizacao?.data ? `${apiImagem}${usuarioPersonalizacao?.data?.dados[0]?.logo_dark_arquivo}` : "imagens/LOGO_EVOLUCAO_BLACK.png"} alt="Via Evolução" width="100%" style={{ maxHeight: "300px" }} />
                                    <div style={{ display: 'flex', marginBottom: '30px', justifyContent: 'center' }}>
                                        <button onClick={(e) => this.handleLanguage('portugues', e)} style={{ marginRight: '10px', border: `${idioma === portugues ? '2px solid gray' : 'none'}`, borderRadius: '2px', backgroundColor: 'transparent' }}>
                                            <img src='/imagens/brazil-flag.png' alt='Bandeira Brasil' style={{ width: '30px', height: 'auto' }} />
                                        </button>
                                        <button onClick={(e) => this.handleLanguage('ingles', e)} style={{ border: `${idioma === ingles ? '2px solid gray' : 'none'}`, borderRadius: '2px', backgroundColor: 'transparent' }}>
                                            <img src='/imagens/usa-flag.png' alt='Bandeira EUA' style={{ width: '30px', height: 'auto' }} />
                                        </button>
                                    </div>
                                    <h2 className="h5 mb-3 font-weight-normal">{idioma.recuperar_senha}</h2>

                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    {msg ? <AlertaErro erros={{ message: msg }} /> : ""}
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon="user" /></InputGroupText></InputGroupAddon>
                                        <Input type="email" value={email} name="email" id="email" placeholder="E-mail" onChange={(ev) => this.onChangeInput("email", ev)} />
                                    </InputGroup>
                                    <br />
                                    <span onClick={() => this.recuperarSenha()}> <Botao loading={carregando} texto={idioma.recuperar_senha} cor="success" tamanho="md" posicao="E" classes="btn-block" icone="key" /> </span>
                                    <p className="text-center mt-2"><Link to={`/${personalizacao}/login`}>{idioma.tem_login}</Link></p>
                                </Form>
                            </div>
                        </div>
                        <div className="col-md-9 fundo-img-login">
                            <img className="img-fluid w-100" src={usuarioPersonalizacao ? `${apiImagem}${usuarioPersonalizacao?.data?.dados[0]?.banner_arquivo}` : "imagens/fundo_via_evolucao.png"} style={{ minHeight: '100%' }} alt="Evolução" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = state => ({
    usuarioPersonalizacao: state.usuarios.usuarioPersonalizacao
})

export default connect(mapStatetoProps, actions)(RecuperarSenha);
