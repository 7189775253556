import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton from "react-loading-skeleton";
import { apiImagem } from '../../config/';
import Botao from '../../componentes/Botoes';
import CarregandoBotoes from '../../componentes/Skeleton/CarregandoBotoes';

import { portugues, ingles } from '../../utils/blocoPerfilTrilha_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import { Rating } from '@material-ui/lab';

class PerfilTrilha extends Component {
    state = {
        id_usuario: "",
        dadosAPI: false,
        mostrarBotao: true,
        trilha: "",
        path: "",
        matriculado: false,
        rateIsOpen: false,
        avaliacao: '',
        usuario: "",
        currentPage: 1,
        size: 20,
        idioma: portugues,
        msg_backend: portuguese,
        avaliado: false,
        visibilidade: false,
        formSuccess: false,
        id_avaliacao: ''
    }

    componentDidMount() {
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });
        this.receberDadosAPI();

        const { usuario } = this.props;

        if (this.props.showMenu) {
            this.props.getAvaliacaoTrilha(this.props.usuario.id, this.props.trilha.id);
        }

        this.setState({ visibilidade: this.props.trilha?.visibilidade === 'Público' })

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (this.props.modulos && this.props.usuario && this.props.trilha) {
            this.verificarMatricula(this.props.modulos);
        }
        this.receberDadosAPI();
    }

    receberDadosAPI() {
        if (typeof this.props.credenciais != "undefined" && this.props.credenciais != null &&
            typeof this.props.modulos != "undefined" && this.props.modulos != null &&
            typeof this.props.trilha != "undefined" && this.props.trilha != null &&
            typeof this.props.avaliacaoTrilha != "undefined" && this.props.avaliacaoTrilha && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
            if (this.props.avaliacaoTrilha?.dados?.nota) {
                this.setState({ avaliado: true });
                this.setState({ avaliacao: this.props.avaliacaoTrilha?.dados?.nota })
                this.setState({ id_avaliacao: this.props.avaliacaoTrilha?.dados?.id })
            }
            if (!this.state.visibilidade) {
                this.setState({ visibilidade: this.props.trilha.visibilidade === 'Público' })
            }

        }
    }

    componentWillUnmount() {
        this.props.limparModulos()
        this.setState({ matriculado: false })
    }



    // listarTrilha(id_trilha) {
    //     this.props.getVerTrilha(id_trilha);
    //     const { trilha } = this.props;
    //     if (this.props.location.state) {

    //         this.setState({ msg: this.props.location.state.msg })
    //         this.props.location.state.msg = "";
    //     }
    //     if (trilha === "undefined") return null
    // }



    desativandoStartup(id_startup) {
        const { idioma } = this.state;
        this.setState({ carregando: true })
        ////////////console.log(id_startup)
        this.props.desativarStartup(id_startup, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta(idioma.modal_sucesso);
            }
        })
    }

    chamarAlerta(id_startup) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.bt_salvar
        }).then((result) => {
            if (result.value) {
                this.desativandoStartup(id_startup);
            }
        })
    }


    avaliarTrilha(id_usuario, id_trilha) {
        const { avaliacao } = this.state;
        this.props.postAvaliacao({ id_curso: id_trilha, id_usuario, nota: avaliacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ avaliado: true })
                this.setState({ rateIsOpen: false });
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code])
            }
        }
        )
    }

    reAvaliarTrilha(id_usuario, id_trilha) {
        const { avaliacao, id_avaliacao } = this.state;
        this.props.putEditarAvaliacao({ id: id_avaliacao, id_curso: id_trilha, id_usuario, nota: avaliacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ avaliado: true });
                this.setState({ rateIsOpen: false });
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code])
            }
        }
        )
    }

    sucessoAlerta(msg) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: msg
        })
    }

    sucessoAlertaMatricula() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.matr_sucesso
        })
    }

    verificarMatricula(matriculado) {
        const { id_usuario, visibilidade, idioma } = this.state;
        const { trilha } = this.props;
        // if (matriculado && !visibilidade) return;
        if (!matriculado) {
            Swal.fire({
                title: trilha && trilha.nome,
                html: idioma.msg_inscricao,
                showCancelButton: true,
                confirmButtonText: idioma.bt_inscrever,
                cancelButtonText: idioma.bt_cancelar,
                confirmButtonColor: "green",
                cancelButtonColor: "red"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ matriculado: true });
                    this.props.postMatricula({ id_usuario, id_curso: trilha.id }, (msg) => {
                        if (msg.erro.error) {
                            this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                            this.setState({ success: "" });
                            this.setState({ carregando: false });
                        } else {
                            this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                            this.setState({ erro: "" });
                            this.setState({ carregando: false });
                            this.sucessoAlertaMatricula()
                            this.props.getModulos(trilha.id, id_usuario);
                        }
                    }
                    )
                }
            })
        }
        return;
    }

    render() {
        let { usuario, trilha, dadosApi, path, idioma, avaliacao, rateIsOpen, avaliado, formSuccess } = this.state;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.usuario) usuario = this.props.usuario;


        var listaAutores = [];
        if (this.props.trilha) {
            trilha = this.props.trilha;
            listaAutores = this.props.trilha.autores
        }
        var matriculado = false;
        if (this.props.modulos) matriculado = this.props.modulos.dados.matriculado;

        var avaliacaoTrilha = false;
        if (this.props.modulos) avaliacaoTrilha = this.props.modulos.dados.avaliacaoTrilha;
        if (this.props.dadosApi) dadosApi = this.props.dadosApi;
        if (this.props.path) path = this.props.path;
        var isAuthor = false;
        for (const l in listaAutores) {
            if (!isAuthor) isAuthor = (listaAutores[l].id_autor === usuario.id)
        }
        var isAdmin = (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9);
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                        {dadosApi ?
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-auto centralizado">
                                        <div className="img-perfil">
                                            <img className=" mt-2" src={trilha.fileName != null ? apiImagem + trilha.fileName : apiImagem + "fundo_startup.jpg"} width="240" height="120" alt='Banner' />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil">
                                            {/* {trilha ? trilha.nome.toLowerCase().replace(/\b\w/g, l => l.toUpperCase()) : ""} */}
                                            {trilha ? trilha.nome : ""}
                                            {/* {this.props.nomeMateria && <span style={{ fontWeight: 'normal' }}> - {this.props.nomeMateria.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}</span>} */}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            : <div className="row">
                                <div className="col-auto centralizado" >
                                    <div className="img-perfil">
                                        <Skeleton rectangle={true} height={120} width={120} />
                                    </div>
                                </div>
                                <div className="col-md-8 centralizado texto-centralizado">
                                    <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                </div>
                            </div>}
                    </div>
                </div>
                {dadosApi ?
                    <div className="col-md-12 mb-3">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-auto texto-centralizado botao-responsivo d-flex justify-content-center align-items-center">
                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/"} >
                                                    <span className="text-perfil">
                                                        <FontAwesomeIcon icon="arrow-left" size="1x" color="black" className="mr-3" />
                                                        {idioma.bt_voltar}
                                                    </span>
                                                </Link>
                                            </div>
                                            {trilha?.visibilidade === 'Público' || isAuthor || isAdmin ?
                                                !matriculado ?
                                                    <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                        <Link to="#" onClick={() => this.verificarMatricula(matriculado)}>
                                                            <span className="text-perfil">
                                                                <FontAwesomeIcon icon="user-graduate" size="1x" color="green" className="mr-3" />
                                                                {idioma.inscreva}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className="col-auto texto-centralizado botao-responsivo d-flex justify-content-center align-items-center">
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="check" size="1x" color="blue" className="mr-3" />
                                                            {idioma.inscrito}
                                                        </span>
                                                    </div>
                                                : ""
                                            }
                                            {trilha?.visibilidade === 'Público' || isAuthor || isAdmin || matriculado ?
                                                <>
                                                    <div className={path === "/trilhas/:id_trilha/descricao" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/descricao/"}>
                                                            <span className="text-perfil">
                                                                <FontAwesomeIcon icon="file-alt" size="1x" color="black" className="mr-3" />
                                                                {idioma.aprender}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div className={path === "/trilhas/:id_trilha/modulos" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/modulos/"}>
                                                            <span className="text-perfil">
                                                                <FontAwesomeIcon icon="paste" size="1x" color="black" className="mr-3" />
                                                                {idioma.conteudo}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </>
                                                : ""}
                                            {trilha?.visibilidade === 'Público' || isAuthor || isAdmin ?
                                                <>
                                                    {credenciais.map(credencial => ((credencial.id_componente === 50) ?
                                                        <div className={path === "/trilhas/:id_trilha/participantes" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/participantes"}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="user-astronaut" size="1x" color="black" className="mr-3" />{idioma.partici}</span>
                                                            </Link>
                                                        </div>
                                                        : ""))}
                                                    {credenciais.map(credencial => ((credencial.id_componente === 51) ?
                                                        <div className={path === "/trilhas/:id_trilha/configuracoes" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/configuracoes"}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="cogs" size="1x" color="black" className="mr-3" />{idioma.config}</span>
                                                            </Link>
                                                        </div>
                                                        : ""))}

                                                </>
                                                : ""}
                                            {trilha?.visibilidade === 'Público' || isAuthor || isAdmin ||
                                                matriculado ?
                                                    <>
                                                        <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                            <Link to="#" onClick={() => this.setState({ rateIsOpen: !rateIsOpen })}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon={['fas', 'star-half-alt']} size="1x" color="orange" className="mr-3" />
                                                                    {idioma.avaliar}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                        {rateIsOpen && (
                                                            <div className="rate-active">
                                                                <Rating name='simple-controlled' value={avaliacao} onChange={(ev, newValue) => this.setState({ avaliacao: newValue })} />
                                                                {!avaliado ?
                                                                    <button onClick={() => this.avaliarTrilha(usuario.id, trilha.id)} style={{ marginLeft: '10px', border: 'none', borderRadius: '5px' }}>Ok</button>
                                                                    :
                                                                    <button onClick={() => this.reAvaliarTrilha(usuario.id, trilha.id)} style={{ marginLeft: '10px', border: 'none', borderRadius: '5px' }}>Ok</button>
                                                                }
                                                            </div>
                                                        )}
                                                    </>
                                                    : ""
                                                }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <CarregandoBotoes />
                }
            </>
        )
    }
}
const mapStatetoProps = state => ({
    trilha: state.trilhas.trilha,
    usuarios: state.startups.usuarios,
    modulos: state.trilhas.modulos,
    avaliacaoTrilha: state.trilhas.avaliacaoTrilha
})
export default connect(mapStatetoProps, actions)(PerfilTrilha);