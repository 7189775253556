import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import DetalheUsuario from './detalheUsuario'
import { Link, Redirect } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfilUsuario';
import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Button } from 'reactstrap';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginausuario_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class Ver extends Component {
    state = {
        msg: "",
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;
        const { id } = this.props.match.params;
        this.getUsuario(id);

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id } = this.props.match.params;
        if (!this.props.usuario && nextProps.usuario) this.getUsuario(id);
        this.receberDadosAPI();

    }
    componentWillUnmount() {
        this.props.limparUsuario();
    }

    receberDadosAPI() {
        if (typeof this.props.usuarioDetalhes != "undefined" && this.props.usuarioDetalhes != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }


    getUsuario(id) {
        this.props.getVerUsuario(id);
        if (this.props.location.state) {
            this.setState({ msg: { message: this.props.location.state.msg } })
        }
    }
    apagarUsuario(id) {
        this.setState({ carregando: true })
        this.props.deletarUsuario(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }


    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.modal_excluir.titulo,
            text: idioma.modal_excluir.info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.modal_excluir.bt_cancelar,
            confirmButtonText: idioma.modal_excluir.bt_sucesso,
        }).then((result) => {
            if (result.value) {
                this.apagarUsuario(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_excluir.info_sucesso
        })
    }
    render() {
        const { id, erro, success, carregando, formSuccess, dadosApi, idioma } = this.state;
        const { usuario } = this.props;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var usuarioDetalhes = null;
        if (this.props.usuarioDetalhes) usuarioDetalhes = this.props.usuarioDetalhes;
        var path = this.props.match.path
        if (formSuccess) return <Redirect to={{
            pathname: "/" + this.props.usuario?.personalizacao?.titulo + "/usuarios/",
            state: { msg: idioma.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                {usuarioDetalhes != null ?
                    <BlocoPerfil usuarioDetalhes={usuarioDetalhes} dadosApi={dadosApi} path={path} credenciais={credenciais} history={this.props.history} />
                    :
                    <div className="list-group-item bloco-card" >
                        <div className="row">
                            <div className="col-md-4" >
                                <div className="img-perfil">
                                    <Skeleton circle={true} height={120} width={120} />
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                            </div>
                        </div>
                    </div>
                }
                <div className="list-group-item bloco-card">

                    <AlertaSuccess erros={success} />
                    <AlertaErro erros={erro} />
                    <DetalheUsuario credenciais={credenciais} />
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario
})


export default connect(mapStateToProps, actions)(Ver);