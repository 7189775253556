import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import ProgressBar from 'react-customizable-progressbar'
import Swal from 'sweetalert2';
import Titulo from '../../componentes/TituloPaginas';
import CarregandoTabela from '../../componentes/Skeleton/CarregandoTabela';
import CarregandoPerfil from '../../componentes/Skeleton/CarregandoPerfil';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class AutoresTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 10,
        progresso: "",
        id_usuario: "",
        id_trilha: "",
        id_modulo: "",
        id_usuario: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha })
        this.listarAutores(id_trilha);
        this.listar(id_trilha, this.props.usuario.id);
        this.listarTrilha(id_trilha)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.autores && nextProps.autores) {
            this.listarAutores(id_trilha);
            this.listarTrilha(id_trilha)
        }
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha)
        }
        if (!this.props.modulos && nextProps.modulos) {
            this.listar(id_trilha, this.props.usuario.id);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparAutores()
    }

    receberDadosAPI() {
        if (typeof this.props.autores != "undefined" && this.props.autores != null && !this.state.dadosApi) {
            if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
                if (typeof this.props.modulos != "undefined" && this.props.modulos != null && !this.state.dadosApi) {

                    this.setState({ dadosApi: true });
                }
            }
        }

    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listar(id_trilha, id_usuario) {
        this.props.getModulos(id_trilha, id_usuario)
        const { modulos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (modulos === "undefined") return null
    }

    listarAutores(id_trilha) {
        this.props.getAutores(id_trilha)
        const { autores } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (autores === "undefined") return null
    }

    //deletar vinculo participante
    deletandoVinculo(id_usuario, id_trilha) {
        this.setState({ carregando: true })
        this.props.deletarAutor(id_usuario, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
                this.props.limparAutores();
                this.setState({ dadosApi: false });
                this.listarAutores(id_trilha);
            }
        })
    }

    chamarAlertaDeletar(id_usuario, id_trilha) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.autores.modal_titulo,
            html: idioma.autores.modal_info,
            input: 'text',
            inputPlaceholder: idioma.autores.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.autores.confirm) {
                this.deletandoVinculo(id_usuario, id_trilha);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.autores.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.autores.modal_sucesso
        })
    }

    render() {
        const { msg, erro, success, dadosApi, id_trilha, id_usuario, carregando, idioma } = this.state;
        var autores = [];
        if (this.props.autores) autores = this.props.autores.dados;
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = null;

        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var matriculado = [];
        if (this.props.modulos) matriculado = this.props.modulos.dados.matriculado;
        var isAuthor = false;
        for (const l in trilha?.autores) {
            if (!isAuthor) isAuthor = (trilha?.autores[l].id_autor === usuario.id)
        }
        var isAdmin = (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9);
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.autores.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha matriculado={matriculado} dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <CarregandoPerfil />
                    }
                    {dadosApi ?
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                {isAuthor || isAdmin ?
                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/autores/" + id_usuario + "/vincular"} >
                                        <Botao texto={idioma.autores.bt_vincular} classes="botao-responsivo" icone="link" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                    </Link>
                                    : ""}
                                <div className="d-flex">
                                    <div className="col-md-12 p-2">
                                        {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                        <AlertaErro erros={erro} />
                                        <AlertaSuccess erros={success} />
                                        <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{idioma.autores.nome}</th>
                                                        {/* {isAuthor || isAdmin ?  */}
                                                        <th>{idioma.autores.acao}</th>
                                                        {/* : ""} */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{trilha.autor}</td>
                                                        <td><Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/ver/" + trilha.id_autor} >
                                                            <Botao loading={carregando} cor="success" texto={idioma.participantes.perfil} icone="user" posicao="E" tamanho="sm" />
                                                        </Link>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/chat/" + trilha.id_autor} className="ml-2 mr-2" >
                                                                <Botao loading={carregando} cor="info" texto={idioma.participantes.bt_conversar} icone="comment" posicao="E" tamanho="sm" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                    {autores ? autores.map(autor => (
                                                        <tr key={autor.id_autor}>
                                                            <td>
                                                                {autor.autor}
                                                            </td>
                                                            <td>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/ver/" + autor.id_autor} >
                                                                    <Botao loading={carregando} cor="success" texto={idioma.participantes.perfil} icone="user" posicao="E" tamanho="sm" />
                                                                </Link>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/chat/" + autor.id_autor} className="ml-2 mr-2" >
                                                                    <Botao loading={carregando} cor="info" texto={idioma.participantes.bt_conversar} icone="comment" posicao="E" tamanho="sm" />
                                                                </Link>
                                                                {isAuthor || isAdmin ?
                                                                    <span onClick={() => this.chamarAlertaDeletar(autor.id, trilha.id)} id="pesquisa" name="pesquisa">
                                                                        <Botao loading={carregando} cor="danger" texto={idioma.autores.bt_excluir} icone="times" posicao="E" tamanho="sm" />
                                                                    </span>
                                                                    : ""}
                                                            </td>
                                                        </tr>
                                                    )) : ""}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <CarregandoTabela />
                    }
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    trilha: state.trilhas.trilha,
    modulos: state.trilhas.modulos,
    autores: state.trilhas.autores

})
export default connect(mapStatetoProps, actions)(AutoresTrilhas);