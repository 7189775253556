import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfilDesafio';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner, Table, Input } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg';
import { format, differenceInDays } from 'date-fns';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SemDados from '../../componentes/Mensagens/semDados';

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


import Titulo from '../../componentes/TituloPaginas';


class VincularUsuario extends Component {
    state = {
        id_desafio: "",
        currentPage: 1,
        size: 20,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pesquisando: false,
        pertence: false,
        procura: "",
        modal: false,
        dadosApi: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_desafio } = this.props.match.params;
        this.setState({ id_desafio })
        this.listarUsuarios();
        this.listarDesafio(id_desafio);
        this.listarAvaliadores(id_desafio);

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id_desafio } = this.props.match.params;
        if (!this.props.usuarios && nextProps.usuarios) this.listarUsuarios();
        if (!this.props.avaliadores && nextProps.avaliadores) this.listarAvaliadores(id_desafio);
        if (!this.props.desafio && nextProps.desafio) {
            this.listarDesafio(id_desafio);
        }

        this.receberDadosAPI();
    }
    componentWillUnmount() {
    }
    receberDadosAPI() {
        if (typeof this.props.usuarios != "undefined" && this.props.usuarios != null && !this.state.dadosApi && this.props.usuarios.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.desafio != "undefined" && this.props.desafio != null && !this.state.dadosApi) {
            if (typeof this.props.avaliadores != "undefined" && this.props.avaliadores != null && !this.state.dadosApi && this.props.avaliadores.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        }
    }

    listarDesafio(id_desafio) {
        this.props.getVerDesafio(id_desafio);
        const { desafio } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (desafio === "undefined") return null
    }
    usuarioNovo(data) {
        var result = differenceInDays(
            new Date(),
            data
        )
    }

    listarAvaliadores(id_desafio) {
        const { currentPage, size } = this.state;
        this.props.getAvaliadores(id_desafio, currentPage, size)
        const { avaliadores } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (avaliadores === "undefined") return null

    }
    listarUsuarios() {
        const { currentPage, size } = this.state;
        this.props.getUsuarios(currentPage, size)
        const { usuarios } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (usuarios === "undefined") return null
    }

    changePageAtual(currentPage) {
        this.props.limparUsuarios();
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarUsuarios();
        })
    }

    changePageAtualSearch(currentPage) {
        const { procura, size } = this.state
        this.props.limparUsuarios();
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.props.getSearch(procura, currentPage, size)
        })
    }

    apagarusuario(id_incubadora) {
        this.props.limparUsuarios();
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarUsuarioIncubadora(id_incubadora, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: 'Usuário deletado com sucesso!' } });
                this.setState({ carregando: false });
                this.listarUsuarios();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }

    vincularUsuario(id_usuario) {
        const { id_desafio } = this.state;
        this.setState({ carregando: true })
        this.props.postAvaliador({ id_usuario, id_desafio }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta()
            }
        }
        )
    }


    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.vincularAvaliador.modal_titulo,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.vincularAvaliador.bt_cancelar,
            confirmButtonText: idioma.vincularAvaliador.modal_info
        }).then((result) => {
            if (result.value) {
                this.vincularUsuario(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.vincularAvaliador.modal_sucesso
        })
    }


    handleSearch(procura) {
        this.setState({ procura: procura })
        this.setState({ pesquisando: true })
        // this.props.limparUsuarios()
        const { currentPage, size } = this.state;
        this.setState({ currentPage: 1 })
        this.props.getSearch(procura, currentPage, size)
    }

    eraseSearch(campo) {
        this.setState({ pesquisando: false })
        this.setState({ [campo]: "" })

    }


    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key === 'Enter') {
            this.handleSearch(procura)
        }
    }
    verificaIncubadora(usuario, credenciais, id_incubadora) {
        if (credenciais[0].id_perfil === 1 || credenciais[0].id_perfil === 9) {
            this.setState({ pertence: true })
        }
        for (let i in usuario.incubadoras) {
            if (usuario.incubadoras[i].id_incubadora === id_incubadora) {
                this.setState({ pertence: true })
            }
        }
    }


    verificaPertence(id_usuario, id_incubadora) {
        this.props.getVerificarPertence(id_usuario, 0, id_incubadora);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { id_desafio, pertence, msg, carregando, erro, success, dadosApi, formSuccess, pesquisando, procura, idioma } = this.state;

        var usuarios = [];
        if (pesquisando == false) {

            if (this.props.usuarios) usuarios = this.props.usuarios.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.usuarios != "undefined" && this.props.usuarios != null && this.props.usuarios.currentPage !== "" && this.props.usuarios.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.usuarios != "undefined" && this.props.usuarios !== null && this.props.usuarios.nextPage <= this.props.usuarios.totalPages && this.props.usuarios.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        } else {
            if (this.props.search) usuarios = this.props.search.dados;
            var temPaginaAnteriorSearch = false;
            if (typeof this.props.search != "undefined" && this.props.search != null && this.props.search.currentPage !== "" && this.props.search.currentPage !== 1) {
                temPaginaAnteriorSearch = true;
            }
            var proximaPaginaSearch = false;
            var temProximaPaginaSearch = false;
            if (typeof this.props.search != "undefined" && this.props.search !== null && this.props.search.nextPage <= this.props.search.totalPages && this.props.search.nextPage != null) {
                proximaPaginaSearch = true;
                temProximaPaginaSearch = true;
            }
        }
        var avaliadores = [];
        if (this.props.avaliadores) avaliadores = this.props.avaliadores.dados;
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        var desafio = null;
        if (this.props.desafio) desafio = this.props.desafio;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;

        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        if (this.props.avaliadores && this.props.usuarios) {
            if (avaliadores.length > 0) {
                for (let u in usuarios) {
                    let vinculado = false
                    for (let a in avaliadores) {
                        if (usuarios[u].id == avaliadores[a].id_usuario) {
                            vinculado = true
                        }
                    }
                    usuarios[u].vinculado = vinculado

                }
            }
        }


        if (formSuccess) return <Redirect to={{
            pathname: "/hub/avaliadores/" + id_desafio,
            state: { msg: idioma.vincularAvaliador.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.vincularAvaliador.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {desafio != null && usuario != null ?
                        <BlocoPerfil dadosApi={dadosApi} desafio={desafio} credenciais={credenciais} usuario={usuario} path={path} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.vincularAvaliador.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <div className="row mt-3 mb-3">
                                <div className="col-lg-8">
                                    <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.vincularAvaliador.pesquisar_place} />
                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <span className="pr-3" onClick={() => this.handleSearch(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.vincularAvaliador.bt_pesquisar} icone="search" posicao="E" /></span>
                                        <span value={procura} onClick={() => this.eraseSearch("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" cor="danger" texto={idioma.vincularAvaliador.bt_cancelar} icone="times" posicao="E" /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="col-md-12 p-2">

                                    {dadosApi ?
                                        // usuarioPertence.pertence ?
                                        <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{idioma.vincularAvaliador.nome}</th>
                                                        {/* <th>Incubadora</th>
                                                        <th>Startups</th> */}
                                                        <th>{idioma.vincularAvaliador.acao}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {usuarios.map(usuario => (
                                                        <tr key={usuario.id}>

                                                            <td>{usuario.nome}</td>
                                                            <td>
                                                                {
                                                                    usuario.vinculado ?
                                                                        <span className="pr-3" id="pesquisa" name="pesquisa">
                                                                            <Botao loading={carregando} cor="success" texto={idioma.vincularAvaliador.vinculado} icone="check" posicao="E" />
                                                                        </span>
                                                                        :
                                                                        <span className="pr-3" onClick={() => this.chamarAlerta(usuario.id)} id="pesquisa" name="pesquisa">
                                                                            <Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.vincularAvaliador.vincular} icone="sign-in-alt" posicao="E" />
                                                                        </span>
                                                                }
                                                            </td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        // : <SemDados subtitulo={idioma.sem_dados_sub} titulo="Acesso negado!" />
                                        : <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <Skeleton count={1} width={80} />
                                                        </th>
                                                        <th>
                                                            <Skeleton count={1} width={80} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Skeleton count={1} width={80} />
                                                        </td>
                                                        <td>
                                                            <Skeleton count={1} width={60} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>}
                                    {dadosApi ?
                                        pesquisando == false ?
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center">
                                                    <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                                                    </li>

                                                    {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.usuarios.prevPage ? this.props.usuarios.prevPage : 1)}>{this.props.usuarios.prevPage ? this.props.usuarios.prevPage : ""}</span></li> : ""}

                                                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.usuarios ? this.props.usuarios.currentPage : "1"}</span></li>

                                                    {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.usuarios.nextPage ? this.props.usuarios.nextPage : 1)}>{this.props.usuarios.nextPage ? this.props.usuarios.nextPage : ""}</span></li> : ""}

                                                    <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.usuarios ? this.props.usuarios.totalPages : 0)}>Última</span>
                                                    </li>
                                                </ul>
                                            </nav>
                                            :
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center">
                                                    <li className={temPaginaAnteriorSearch ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearch(1)} >{idioma.page1}</span>
                                                    </li>

                                                    {temPaginaAnteriorSearch ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearch(this.props.search.prevPage ? this.props.search.prevPage : 1)}>{this.props.search.prevPage ? this.props.search.prevPage : ""}</span></li> : ""}

                                                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.search ? this.props.search.currentPage : "1"}</span></li>

                                                    {proximaPaginaSearch ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearch(this.props.search.nextPage ? this.props.search.nextPage : 1)}>{this.props.search.nextPage ? this.props.search.nextPage : ""}</span></li> : ""}

                                                    <li className={temProximaPaginaSearch ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearch(this.props.search ? this.props.search.totalPages : 0)}>{idioma.page2}</span>
                                                    </li>
                                                </ul>
                                            </nav>
                                        : ""}
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    usuarios: state.hub.usuarios,
    desafio: state.hub.desafio,
    search: state.startups.search,
    avaliadores: state.hub.avaliadores,
    // pertence: state.incubadoras.pertence



})
export default connect(mapStatetoProps, actions)(VincularUsuario);