import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Button, Spinner } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SemDados from '../../componentes/Mensagens/semDados';
import { apiEvidencia } from '../../config/';
import parse from 'html-react-parser'
import Swal from 'sweetalert2';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Validar extends Component {
    state = {
        id_evidencia: "",
        aprovado: "",
        id_startup: "",
        id_usuario: "",
        id_processo: "",
        id_metodologia: "",
        id_incubadora: "",
        id_etapa: "",
        observacao: "",
        displayBotao: "",
        pertence: "",
        file: null,
        erro: "",
        success: "",
        pertence: false,
        carregando: false,
        formSuccess: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }



    componentDidMount() {
        const { id_evidencia, id_incubadora, id_startup, id_etapa, id_entrega, id_processo, id_metodologia } = this.props.match.params;
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id })
        this.setState({ id_evidencia });
        this.setState({ id_startup });
        this.setState({ id_etapa });
        this.setState({ id_entrega })
        this.setState({ id_incubadora })
        this.setState({ id_processo })
        this.setState({ id_metodologia })
        this.setState({ displayBotao: false })
        this.listarStartup(id_startup)
        this.verificaPertence(this.props.usuario.id, id_startup)

        // if (this.props.usuario && this.props.startup) this.handlePertence(this.props.usuario, this.props.startup)

        this.buscarEntrega(id_entrega, id_startup);

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        const { id_entrega, id_startup } = this.props.match.params;
        if (!this.props.entrega && nextProps.entrega) {
            this.buscarEntrega(id_entrega, id_startup);
        }
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_startup);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparEntrega()
    }
    receberDadosAPI() {
        if (typeof this.props.entrega != "undefined" && this.props.entrega != null && !this.state.dadosApi) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }
    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }
    async buscarEntrega(id_entrega, id_startup) {
        await this.props.getEntrega(id_entrega, id_startup, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
            }
        });
    }
    async enviarEntrega(id_evidencia) {
        const { id_usuario, id_startup, aprovado, observacao, displayBotao } = this.state;
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.postValidacao({ id_usuario, id_evidencia, id_startup, aprovado, observacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })
    }
    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }
    baixarEntrega(fileName) {
        window.open(apiEvidencia + fileName, "_blank")
    }

    handleBotao(displayBotao) {
        displayBotao == false ? this.setState({ displayBotao: true }) : this.setState({ displayBotao: false });
    }

    validate() {
        const { aprovado, idioma } = this.state;
        if (!aprovado) return this.setState({ erro: { message: idioma.evidencias.erro_validacao } });
        return true;
    }
    deletar(id) {
        const { id_startup, id_entrega } = this.state
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarEvidencia(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.buscarEntrega(id_entrega, id_startup);
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }

    chamarDeletar(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.evidencias.modal_titulo,
            text: idioma.evidencias.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.participantes.bt_cancelar,
            confirmButtonText: idioma.evidencias.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.deletar(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.evidencias.modal_sucesso
        })
    }


    verificaPertence(id_usuario, id_startup) {
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao?.cor_secundaria
    }

    render() {
        const { file, carregando, erro, pertence, id_incubadora, formSuccess, id_entrega, id_startup, id_processo, id_metodologia, id_evidencia, dadosApi, success, id_etapa, displayBotao, idioma } = this.state;
        var entrega = [];
        var credenciais = [];
        var startup = null;
        var usuario = [];
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (this.props.startup) startup = this.props.startup;
        if (this.props.entrega) entrega = this.props.entrega.dados;
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
 
        if (formSuccess) {
            return <Redirect to={{
                pathname: "/" + this.props.usuario?.personalizacao?.titulo + '/startups/' + id_startup + '/acompanhamento/' + id_metodologia + '/processos/' + id_processo + '/etapa/' + id_etapa + "/entrega/" + id_entrega + "/evidencias",
                state: { msg: success.message }
            }} />
        }
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.evidencias.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            {credenciais.map(credencial => ((credencial.id_componente == 35) ?
                                <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + id_metodologia + "/processos/" + id_processo + "/etapa/" + id_etapa + "/entrega/" + id_entrega + "/evidencias/nova_entrega"}>
                                    <Botao texto={idioma.evidencias.nova_entre} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                    {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline botao-responsivo" style={{ border: `1px solid ${this.props.usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                        {idioma.evidencias.nova_entre}
                                    </button> */}
                                </Link>
                                : ""))}
                            {dadosApi ?
                                usuarioPertence.pertence ?
                                    <div>
                                        <AlertaErro erros={erro} />
                                        <div>
                                            <div className="p-4">
                                                <h4>{entrega.entrega &&
                                                    entrega.entrega.nome
                                                }</h4>
                                            </div>
                                            {entrega.evidencias.length != 0 ?
                                                <div className="row">
                                                    <div className="col-12">
                                                        <section id="cd-timeline" className="cd-container">
                                                            {entrega.evidencias.map(evidencia => (
                                                                <div className="cd-timeline-block" key={evidencia.id}>
                                                                    <div className={(evidencia.mentorias.length != 0) ? evidencia.mentorias.map(mentoria => ((mentoria.aprovado == "Sim") ? "cd-timeline-img bg-success" : "cd-timeline-img bg-danger")) : "cd-timeline-img bg-warning"}>
                                                                        <i className="fas fa-check"></i>
                                                                    </div>
                                                                    <div className="cd-timeline-content">
                                                                        {entrega.entrega.tipo_campo == "file" ?
                                                                            <>
                                                                                {(evidencia.mentorias.length != 0) ? evidencia.mentorias.map(mentoria => ((mentoria.aprovado == "Sim") ? <span className="badge badge-pill badge-success mb-1">{idioma.evidencias.validado}</span> : <span className="badge badge-pill badge-danger mb-1">{idioma.evidencias.nao_validade}</span>)) : <span className="badge badge-pill badge-warning mb-1">{idioma.evidencias.aguar_validado}</span>}
                                                                                {evidencia.evidencias.originalName ? <div className="pl-2"><strong>{idioma.evidencias.nome_arq} </strong>{evidencia.evidencias.originalName}</div> : ""}
                                                                                {evidencia.evidencias.datahora ? <div className="text-muted pl-2">{format(new Date(evidencia.evidencias.datahora), 'dd/MM/yyyy hh:mm:ss', { locale: pt })}</div> : ""}
                                                                                {evidencia.evidencias.tipo_campo == "texto" ? <div className="p-2"><strong>{idioma.evidencias.titulo}: </strong>{parse("" + evidencia.evidencias.resposta)}</div> : ""}<br></br>
                                                                                {evidencia.evidencias.fileName ? <span className="p-2" onClick={() => this.baixarEntrega(evidencia.evidencias.fileName)}><Button type="button" color="primary">{idioma.evidencias.baixar_arq}</Button></span> : ""}

                                                                                {credenciais.map(credencial => ((credencial.id_componente == 12) ?
                                                                                    evidencia.mentorias.length == 0 ?

                                                                                        <div className="pt-3">
                                                                                            <span className=" p-2" onClick={() => this.handleBotao(displayBotao)}><Button type="button" color="warning">{idioma.evidencias.val_evidencia}</Button></span>
                                                                                        </div>

                                                                                        : ""

                                                                                    : ""))}
                                                                                {evidencia.evidencias.fileName != null &&
                                                                                    evidencia.evidencias.tipo_campo != "arquivo" ?
                                                                                    <>
                                                                                        {evidencia.evidencias.tipo_campo == "imagem" ?
                                                                                            <strong>{idioma.evidencias.imagens}</strong>
                                                                                            : evidencia.evidencias.tipo_campo == "pdf" &&
                                                                                            <strong>{idioma.evidencias.arquivos}</strong>
                                                                                        }                                                                                        <div class="embed-responsive embed-responsive-16by9 mb-4 mt-5">
                                                                                            <iframe class="embed-responsive-item" src={apiEvidencia + evidencia.evidencias.fileName} allowfullscreen></iframe>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <div className="mb-2">
                                                                                        <strong>{idioma.evidencias.arquivos}</strong>
                                                                                        <span className="pl-2" ><Link onClick={() => this.baixarEntrega(evidencia.evidencias.fileName)}>{evidencia.evidencias.originalName}</Link></span>
                                                                                    </div>
                                                                                }
                                                                                {usuarioPertence.pertence && credenciais.map(credencial => ((credencial.id_componente == 81) &&
                                                                                    <span className="p-2" onClick={() => this.chamarDeletar(evidencia.evidencias.id)}><Button type="button" color="danger">{idioma.evidencias.del_evidencia}</Button></span>
                                                                                ))}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {(evidencia.mentorias.length != 0) ? evidencia.mentorias.map(mentoria => ((mentoria.aprovado == "Sim") ? <span className="badge badge-pill badge-success mb-1">{idioma.evidencias.validado}</span> : <span className="badge badge-pill badge-danger mb-1">{idioma.evidencias.nao_validade}</span>)) : <span className="badge badge-pill badge-warning mb-1">{idioma.evidencias.aguar_validado}</span>}
                                                                                {evidencia.evidencias.datahora ? <div className="text-muted pl-2">{format(new Date(evidencia.evidencias.datahora), 'dd/MM/yyyy hh:mm:ss', { locale: pt })}</div> : ""}
                                                                                {evidencia.evidencias.tipo_campo == "texto" ? <div className="p-2"><strong>{idioma.evidencias.titulo}: </strong>{parse("" + evidencia.evidencias.resposta)}</div> : ""}<br></br>


                                                                                {evidencia.evidencias.fileName != null &&
                                                                                    evidencia.evidencias.tipo_campo != "arquivo" ?
                                                                                    <>
                                                                                        {evidencia.evidencias.tipo_campo == "imagem" ?
                                                                                            <strong>{idioma.evidencias.imagens}</strong>
                                                                                            : evidencia.evidencias.tipo_campo == "pdf" &&
                                                                                            <strong>{idioma.evidencias.arquivos}</strong>
                                                                                        }
                                                                                        <div class="embed-responsive embed-responsive-16by9 mb-4 mt-5">
                                                                                            <iframe class="embed-responsive-item" src={apiEvidencia + evidencia.evidencias.fileName} allowfullscreen></iframe>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <div className="mb-2">
                                                                                        <strong>{idioma.evidencias.arquivos}</strong>
                                                                                        <span className="pl-2" ><Link onClick={() => this.baixarEntrega(evidencia.evidencias.fileName)}>{evidencia.evidencias.originalName}</Link></span>
                                                                                    </div>
                                                                                }

                                                                                {evidencia.evidencias.link != "undefined" &&
                                                                                    <div class="embed-responsive embed-responsive-16by9 mb-4 mt-5">
                                                                                        <iframe class="embed-responsive-item" src={evidencia.evidencias.link} allowfullscreen></iframe>
                                                                                    </div>
                                                                                }
                                                                                {usuarioPertence.pertence && credenciais.map(credencial => ((credencial.id_componente == 81) &&
                                                                                    <span className="p-2" onClick={() => this.chamarDeletar(evidencia.evidencias.id)}><Button type="button" color="danger">{idioma.evidencias.del_evidencia}</Button></span>
                                                                                ))}
                                                                                {credenciais.map(credencial => ((credencial.id_componente == 12) ?
                                                                                    evidencia.mentorias.length == 0 ?

                                                                                        <div className="pt-3">
                                                                                            < span className=" p-2" onClick={() => this.handleBotao(displayBotao)}><Button type="button" color="warning">{idioma.evidencias.val_evidencia}</Button></span>
                                                                                        </div>

                                                                                        : ""

                                                                                    : ""))}
                                                                            </>}
                                                                        <br /><br />
                                                                        <Form>
                                                                            {credenciais.map(credencial => ((credencial.id_componente == 12) ?
                                                                                evidencia.mentorias.length == 0 && displayBotao == true ?
                                                                                    <div key={credencial.id_componente}>
                                                                                        <div className="p-4">
                                                                                            <Label>{idioma.evidencias.apro_evidencia}</Label>
                                                                                            <FormGroup check>
                                                                                                <Label check>
                                                                                                    <Input type="radio" name="aprovado" id="aprovado" value="Sim" onChange={(ev) => this.onChangeInput("aprovado", ev)} />{' '}{idioma.evidencias.sim}</Label>
                                                                                            </FormGroup>
                                                                                            <FormGroup check>
                                                                                                <Label check>
                                                                                                    <Input type="radio" name="aprovado" id="aprovado" value="Não" onChange={(ev) => this.onChangeInput("aprovado", ev)} />{' '}{idioma.evidencias.nao}</Label>
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label for="exampleText">{idioma.evidencias.mentorias}</Label>
                                                                                                <Input type="textarea" name="observacao" id="observacao" onChange={(ev) => this.onChangeInput("observacao", ev)} />
                                                                                                <Input type="hidden" value={evidencia.evidencias.id} id="id_evidencia" name="id_evidencia" />

                                                                                            </FormGroup>
                                                                                            <span className="d-none d-md-block">
                                                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + id_metodologia + "/processos/" + id_processo + "/etapa/" + id_etapa} onClick={() => this.enviarEntrega(evidencia.evidencias.id)}> <Botao loading={carregando} texto={idioma.participantes.bt_salvar} icone="check" posicao="E" cor="success" /> </Link>
                                                                                                {displayBotao == true ? < span className=" p-2" onClick={() => this.handleBotao(displayBotao)}><Button type="button" color="danger">Cancelar</Button></span> : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="observacoes">
                                                                                        {evidencia.mentorias.map(mentoria => (
                                                                                            <div className="p-2 pl-4">
                                                                                                <strong>{idioma.evidencias.mentorias} </strong>{mentoria.observacao}
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                : ""))}
                                                                        </Form>

                                                                    </div>

                                                                </div>
                                                            ))}
                                                        </section>
                                                    </div>
                                                </div>
                                                : <div>
                                                    <div className="row">
                                                        <div className="col-md-4" >
                                                            <div className="img-perfil">
                                                                <img className="rounded-circle mt-2" src={"/imagens/astronauta_sem_dados.png"} width="100%" ></img>
                                                            </div>
                                                        </div>
                                                        <div className="col mensagem-dados">
                                                            <h4>{idioma.evidencias.evidencia}</h4>
                                                            <h5>{idioma.evidencias.info_evidencia}</h5>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                    : <SemDados subtitulo={idioma.sem_dados_sub} titulo="Acesso negado!" />
                                : <div className="row">
                                    <div className="col-12">
                                        <div className="card-body">
                                            <section id="cd-timeline" className="cd-container">
                                                <div className="cd-timeline-block">
                                                    <div>
                                                        <Skeleton circle={true} height={60} width={60} />
                                                    </div>
                                                    <div className="cd-timeline-content">
                                                        <Skeleton count={1} width={80} />
                                                        <Skeleton count={1} width={80} />
                                                        <Skeleton count={1} width={80} />
                                                        <Skeleton count={1} width={80} />
                                                        <Skeleton count={1} width={80} />
                                                        <Skeleton count={1} width={80} />
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div >
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario,
    entrega: state.startups.entrega,
    startup: state.startups.startup,
    pertence: state.startups.pertence

})
export default connect(mapStateToProps, actions)(Validar);