export const portugues = {
  modal_titulo: 'Deseja deletar o evento?',
  modal_info: 'Ao fazer isso você concorda na exclusão do evento </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
  modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
  confirm: 'CONFIRMAR',
  bt_deletar: 'Deletar',
  modal_erro: 'Mensagem de confirmação inválida!',
  modal_sucesso: 'Evento excluído com sucesso!',
  dia: 'Dia',
  de: 'De',
  ate: 'Até',
  criado: 'Criado por:',
  participe: 'Participe em',
  bt_editar: 'Editar',
  bt_editar_capa: 'Editar Capa'
}

export const ingles = {
  modal_titulo: 'Do you want to delete the event?',
  modal_info: 'By doing so you agree to the deletion of the event </br>Type <strong>CONFIRM</strong> to perform the deletion',
  modal_confirm: 'Type CONFIRM to perform the deletion',
  confirm: 'CONFIRM',
  bt_deletar: 'Delete',
  modal_erro: 'Invalid confirmation message!',
  modal_sucesso: 'Event successfully deleted!',
  dia: 'Day',
  de: 'From',
  ate: 'To',
  criado: 'Created by:',
  participe: 'Participate in',
  bt_editar: 'Edit',
  bt_editar_capa: 'Edit Cover'
}