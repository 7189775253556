export const portugues = {
  modal_titulo: 'Bem-vindo(a) à Cebbi! <br> O acesso desta plataforma está condicionado à aceitação e ao cumprimento dos nossos ',
  modal_titulo2: ' e da ',
  modal_titulo3: '. Ao clicar em “aceito”, você está automaticamente concordando com as condições descritas.  ',
  aceitar: 'Aceitar',
  recusar: 'Recusar',
  erro: 'Erro!',
  sucesso: 'Sucesso!',
  termos: 'Termos',
  politica: 'Política de Privacidade'
}

export const ingles = {
  modal_titulo: 'Welcome to "Cebbi"!! <br> The access to this platform is subjected to acceptance and compliance with our ',
  modal_titulo2: ' and ',
  modal_titulo3: 'By clicking "I accept", you automatically agree with the conditions described.',
  aceitar: 'Accept',
  recusar: 'Decline',
  erro: 'Error!',
  sucesso: 'Success!',
  termos: 'Terms',
  politica: 'Privacy Policy. '
}