import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/perfis';
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  DropdownButton,
  Dropdown,
  CustomInput,
} from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
// import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import { ingles, portugues } from '../../utils/paginaperfilacesso_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class Cadastrar extends Component {
  state = {
    nome: '',
    descricao: '',
    credenciais: '',
    erro: '',
    success: '',
    carregando: '',
    formSuccess: false,
    idioma: portugues,
    msg_backend: portuguese,
  };

  componentDidMount() {
    this.props.getPerfis();
    this.listarComponentes();
    this.listarCredenciais();

    const { usuario } = this.props;
    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }

  receberDadosAPI() {
    const { id_perfil, id_componente, id_credenciais } =
      this.props.match.params;
    if (
      typeof this.props.componentes !== 'undefined' &&
      this.props.componentes !== null &&
      this.props.componentes.id_componente == id_componente &&
      !this.state.dadosAPI
    ) {
      this.setState({ id_componente: this.props.componentes.id_componente });
      this.setState({ dadosAPI: true });
    }
    if (
      typeof this.props.credenciais !== 'undefined' &&
      this.props.credenciais.id_credenciais !== null &&
      this.props.credenciais.id == id_credenciais &&
      !this.state.dadosAPI
    ) {
      this.setState({ id_credenciais: this.props.credenciais.id });
      this.setState({ dadosAPI: true });
    }
  }

  listarComponentes() {
    this.props.getComponentes();
    const { componentes } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (componentes === 'undefined') return null;
  }

  listarCredenciais() {
    this.props.getCredenciais();
    const { credenciais } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (credenciais === 'undefined') return null;
  }

  onChnageInput(campo, ev) {
    this.setState({ [campo]: ev.target.value });
  }
  cadPerfil() {
    const { nome, descricao } = this.state;
    if (!this.validate()) return;
    this.setState({ carregando: true });
    this.props.postPerfil({ nome, descricao }, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
      }
    });
  }
  validate() {
    const { nome, descricao, idioma } = this.state;
    if (!nome)
      return this.setState({
        erro: { message: idioma.cadastrarPerfil.erro_nome },
      });
    if (!descricao)
      return this.setState({
        erro: { message: idioma.cadastrarPerfil.erro_descricao },
      });
    return true;
  }

  salvar(id_componente, ev) {
    const listarComponentes = this.props.componentes;
    const listaCredenciais = this.props.credenciais;
    const id_perfil = this.props.usuario.credenciais[0].id_perfil;

    this.setState({ id_componente });
    this.setState({ carregando: true });
    if (id_componente == listaCredenciais.id_componente) {
      // this.props.deletarCredencial({ id_componente, id_perfil }, (msg) => {
      //     if (msg.erro.error) {
      //         this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
      //         this.setState({ success: "" });
      //         this.setState({ carregando: false });
      //     } else {
      //         this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
      //         this.setState({ erro: "" });
      //         this.setState({ carregando: false });
      //         this.alerta("success", msg.erro.message);
      //         this.checked(listarComponentes, id_componente);
      //     }
      // });
    } else {
      // this.props.postCredencial({ id_componente, }, (msg) => {
      //     if (msg.erro.error) {
      //         this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
      //         this.setState({ success: "" });
      //         this.setState({ carregando: false });
      //     } else {
      //         this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
      //         this.setState({ erro: "" });
      //         this.setState({ carregando: false });
      //         this.alerta("success", msg.erro.message);
      //         this.checked(listarComponentes, id_componente);
      //     }
      // });
    }
  }

  checked(credenciais, id_componente) {
    let checked = false;

    for (const cont in credenciais) {
      if (credenciais[cont].id_componente == id_componente) {
        checked = true;
      }
    }
    return checked;
  }

  render() {
    const {
      nome,
      descricao,
      erro,
      success,
      carregando,
      iconSalvar,
      formSuccess,
      id_perfil,
      idioma,
    } = this.state;
    var perfis = [];
    var componentes = [];
    var credenciais = [];

    if (this.props.perfis) perfis = this.props.perfis.dados;
    if (this.props.componentes) componentes = this.props.componentes.dados;
    if (this.props.credenciais) credenciais = this.props.credenciais.dados;
    if (formSuccess)
      return (
        <Redirect
          to={{
            pathname: '/perfis',
            state: { msg: idioma.cadastrarPerfil.msg_sucesso },
          }}
        ></Redirect>
      );
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario?.personalizacao?.cor_principal,
          }}
        >
          <Titulo titulo={idioma.cadastrarPerfil.titulo} />
        </div>
        <div className="list-group-item bloco-card">
          <div>
            <AlertaErro erros={erro} />
            <AlertaSuccess erros={success} />
            <Form>
              <FormGroup>
                <Label for="nome">{idioma.cadastrarPerfil.nome}</Label>
                <Input
                  type="text"
                  value={nome}
                  autoComplete="nome"
                  onChange={(ev) => this.onChnageInput('nome', ev)}
                  name="nome"
                  id="nome"
                  placeholder="Nome completo"
                />
              </FormGroup>
              <FormGroup>
                <Label for="descricao">
                  {idioma.cadastrarPerfil.descricao}
                </Label>
                <Input
                  type="textarea"
                  value={descricao}
                  autoComplete="descricao"
                  onChange={(ev) => this.onChnageInput('descricao', ev)}
                  name="descricao"
                  id="descricao"
                  placeholder={idioma.cadastrarPerfil.descricao}
                />
              </FormGroup>
              <Link to="#" onClick={() => this.cadPerfil()}>
                {' '}
                <Botao
                  loading={carregando}
                  texto={idioma.bt_cadastrar}
                  icone={iconSalvar}
                />{' '}
              </Link>
            </Form>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  usuarioDetalhes: state.usuarios.usuarioDetalhes,
  usuario: state.auth.usuario,
  perfis: state.usuarios.perfis,
  componentes: state.perfis.componentes,
  credenciais: state.perfis.credenciais,
});

export default connect(mapStateToProps, actions)(Cadastrar);
