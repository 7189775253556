import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import validator from 'validator'
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';

import { portugues, ingles } from '../../utils/paginaperfil_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';

class EditarPerfil extends Component {
    state = {
        id: "",
        nome: "",
        email: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        this.receberDadosApi();

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        if (!this.props.usuario && nextProps.usuario) this.props.getVerUsuario(this.state.id);
        await this.receberDadosApi();
    }
    receberDadosApi() {
        if (typeof this.props.usuario != "undefined" && this.props.usuario != null && !this.state.dadosAPI) {
            this.setState({ id: this.props.usuario.id })
            this.setState({ nome: this.props.usuario.nome })
            this.setState({ email: this.props.usuario.email })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }
    async atualizarUsuario() {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        this.setState({ carregando: true })
        const { nome, email } = this.state;
        this.props.putPerfil({ nome, email }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.atualizarPerfil();
            }
        })

    }
    async atualizarPerfil() {
        await this.props.verPerfil(() => {
            this.setState({ carregando: false });
            this.setState({ formSuccess: true });
        })
    }
    receberDadosForm() {
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ email: document.querySelector("#email").value })
    }
    validate() {
        const { nome, email, erro, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.editar_perfil.erro_nome } });
        if (!email) return this.setState({ erro: { message: idioma.editar_perfil.erro_email } });
        if (!validator.isEmail(email)) return this.setState({ erro: { message: idioma.editar_perfil.erro2_email } });
        return true;
    }
    render() {
        const { nome, email, carregando, erro, formSuccess, idioma } = this.state;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + this.props.usuario?.personalizacao?.titulo + "/perfil/",
            state: { msg: idioma.editar_senha.sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editar_perfil.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>

                            <AlertaErro erros={erro} />
                            <Form>
                                <FormGroup>
                                    <Label for="nome">{idioma.editar_perfil.label}</Label>
                                    <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" onChange={(ev) => this.onChangeInput("nome", ev)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="nome">E-mail</Label>
                                    <Input type="text" value={email} id="email" name="email" className="form-control" autoComplete="nome" onChange={(ev) => this.onChangeInput("email", ev)} />
                                </FormGroup>
                                <Link onClick={() => this.atualizarUsuario()} to="#">
                                    <Botao loading={carregando} texto={idioma.bt_salvar} classes="botao-responsivo botao-estilo" cor='success' />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.bt_cancelar} classes="botao-responsivo botao-estilo" icone="times" posicao="E" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div></div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(EditarPerfil);