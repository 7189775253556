import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form, Card } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';
import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';
import Toast from '../../../componentes/Alertas/Toast';
class cadastrarEstagio extends Component {
  state = {
    nome_estagio: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    // editorState: EditorState.createEmpty(),
    descricao: '',
    msg_backend: portuguese,
    idioma: portugues,
  }
  componentDidMount() {

    const { usuario } = this.props;

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }


  validate() {
    const { nome_estagio, idioma } = this.state;
    if (!nome_estagio) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  handleNovoEstagio() {
    const { id_processo, id_metodologia, id_etapa } = this.props.match.params;
    const { nome_estagio, descricao } = this.state;

    this.props.postNovoEstagio({ id_processo, id_metodologia, id_etapa, nome: nome_estagio, descricao }, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: false });
      } else {
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        Toast({ icon: 'success', title: 'Sucesso!', text: this.state.msg_backend[msg.erro.code] })
      }
    }
    )
  }



  render() {
    const { carregando, erro, formSuccess, idioma, nome_estagio, descricao } = this.state;
    const { id_metodologia, id_processo, id_etapa } = this.props.match.params;

    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/estagios/listar`,
      state: idioma.cadastrarEstagio.msg_sucesso
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.cadastrarEstagio.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome_estagio', e)} value={nome_estagio} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarEstagio.nome_estagio} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="nome">{idioma.cadastrarEstagio.input_descricao}</Label>
                    <Input type="textarea" onChange={(e) => this.onChangeInput('descricao', e)} value={descricao} autoComplete="descricao" required name="descricao" id="descricao" placeholder={idioma.cadastrarEstagio.input_descricao} />

                    {/* <Card className="card-editor">
                      <div>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onEditorStateChange}
                        />
                      </div>
                    </Card> */}

                  </FormGroup>
                  <Link to="#" onClick={() => this.handleNovoEstagio()}> <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  processos: state.startups.processos,
})
export default connect(mapStatetoProps, actions)(cadastrarEstagio);