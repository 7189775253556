import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Col, Row, Form, FormGroup, Label, Input, Table, Spinner } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { ChromePicker } from 'react-color';
import { apiImagem } from '../../config';
import Titulo from '../../componentes/TituloPaginas';
import Swal from 'sweetalert2';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

class EditarPersonalizacao extends Component {
  state = {
    titulo: "",
    logo: '',
    logoPreview: '',
    logoSize: '',
    logo_dark: '',
    logoDark_preview: '',
    logoDark_size: '',
    banner: '',
    bannerPreview: '',
    bannerSize: '',
    id_personalizacao: '',
    erro: "",
    success: "",
    formSuccess: false,
    carregando: "",
    cor_principal: '#fff',
    cor_secundaria: '#fff',
    cor_terciaria: '#fff',
    incubadorasPerso: '',
    msg_backend: portuguese,
    cor_quaternaria: '#fff',
    cor_texto_menu: '#fff',
    cor_texto_menu_hover: '#fff',
    cor_titulo: '#fff',
    spinner: true,
    idioma: portugues
  }

  componentDidMount() {
    this.listarDadosPersonalizacao();
    this.verIncubadorasVinculos();

    const { usuario } = this.props;

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }

  componentDidUpdate(nextProps) {

    if (!this.props.personalizacao && nextProps.personalizacao) {
      this.listarDadosPersonalizacao();
      this.verIncubadorasVinculos();
    }
    this.receberDadosAPI();
  }

  componentWillUnmount() {
    const { limparPersonalizacao } = this.props;

    limparPersonalizacao();
  }

  receberDadosAPI() {
    if (typeof this.props.personalizacao != "undefined" && this.props.personalizacao != null && !this.state.dadosApi) {
      const { personalizacao } = this.props;
      this.verIncubadorasVinculos();
      this.setState({ titulo: personalizacao.dados.titulo })
      this.setState({ logo: personalizacao.dados.logo_arquivo })
      this.setState({ logo_dark: personalizacao.dados.logo_dark_arquivo })
      this.setState({ banner: personalizacao.dados.banner_arquivo })
      this.setState({ cor_principal: personalizacao.dados.cor_principal })
      this.setState({ cor_secundaria: personalizacao.dados.cor_secundaria })
      this.setState({ cor_terciaria: personalizacao.dados.cor_terciaria })
      this.setState({ cor_quaternaria: personalizacao.dados.cor_quaternaria })
      this.setState({ cor_texto_menu: personalizacao.dados.cor_texto_menu })
      this.setState({ cor_texto_menu_hover: personalizacao.dados.cor_texto_menu_hover })
      this.setState({ cor_titulo: personalizacao.dados.cor_titulo })
      this.setState({ dadosApi: true });
    }

  }

  listarDadosPersonalizacao() {
    const id = this.props.match.params.id_personalizacao;
    const { getPersonalizacao, getPersonalizacaoIncubadora } = this.props;

    getPersonalizacao(id);
    getPersonalizacaoIncubadora(id);
    // if (personalizacao) {
    //   this.setState({ titulo: personalizacao.dados.titulo })
    //   this.setState({ logo: personalizacao.dados.logo_arquivo })
    //   this.setState({ banner: personalizacao.dados.banner_arquivo })
    //   this.setState({ cor_principal: personalizacao.dados.cor_principal })
    //   this.setState({ cor_secundaria: personalizacao.dados.cor_secundaria })
    //   this.setState({ cor_terciaria: personalizacao.dados.cor_terciaria })
    // }
  }

  onChnageInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  async verIncubadorasVinculos() {
    const id = this.props.match.params.id_personalizacao;
    const { incubadorasPersonalizacao, getIncubadorasPorPersonalizacao } = this.props;

    await getIncubadorasPorPersonalizacao(id);
    this.setState({ spinner: false })

  }

  handlePersonalizar() {
    const id = this.props.match.params.id_personalizacao;
    const { titulo, logo, banner, cor_principal, cor_secundaria, cor_terciaria, cor_quaternaria, cor_texto_menu, cor_titulo, logo_dark, cor_texto_menu_hover, msg_backend } = this.state;

    if (!this.validate()) return;
    const formData = new FormData();

    formData.append('titulo', titulo);
    formData.append('cor_principal', cor_principal);
    formData.append('cor_secundaria', cor_secundaria);
    formData.append('cor_terciaria', cor_terciaria);
    formData.append('cor_quaternaria', cor_quaternaria);
    formData.append('cor_texto_menu', cor_texto_menu);
    formData.append('cor_texto_menu_hover', cor_texto_menu_hover);
    formData.append('cor_titulo', cor_titulo);
    formData.append('logo', logo);
    formData.append('logo_dark', logo_dark);
    formData.append('banner', banner);


    this.setState({ carregando: true })
    this.props.putPersonalizacao(id, formData, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.receberDadosAPI();
        this.setState({ formSuccess: true })
      }
    }
    )
  }

  deletarVinculo(id_vinculo) {
    const { deletarPersonalizacaoVinculo } = this.props;



    deletarPersonalizacaoVinculo(id_vinculo, async (msg) => {
      const { msg_backend } = this.state;
      this.setState({ spinner: true })

      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        await this.verIncubadorasVinculos();
        this.setState({ spinner: false })
      }
    }
    )

  }

  onChangeInputFile(campo, ev) {
    this.setState({ [campo]: ev.target.files[0] })

    const file = ev.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      if (campo === 'logo') {
        fileReader.addEventListener('load', () => this.setState({ logoPreview: fileReader.result }))
        const logo = document.getElementById('logo-preview');
        logo.onload = () => this.setState({ logoSize: `${logo.offsetWidth} x ${logo.offsetHeight}` })
      } else if (campo === 'logo_dark') {
        fileReader.addEventListener('load', () => this.setState({ logoDark_preview: fileReader.result }))
        const logoDark = document.getElementById('logoDark-preview');
        logoDark.onload = () => this.setState({ logoDark_size: `${logoDark.offsetWidth} x ${logoDark.offsetHeight}` })
      } else {
        fileReader.addEventListener('load', () => this.setState({ bannerPreview: fileReader.result }))
        const banner = document.getElementById('banner-preview');
        banner.onload = () => this.setState({ bannerSize: `${banner.offsetWidth} x ${banner.offsetHeight}` })
      }
    }
  }

  validarTitulo(value) {
    return value.indexOf(' ') >= 0;
  }

  validate() {
    const { titulo, idioma } = this.state;
    const tituloInvalido = this.validarTitulo(titulo);
    if (!titulo) return this.setState({ erro: { message: idioma.novaPersonalizacao.erro_titulo } });
    if (tituloInvalido) return this.setState({ erro: { message: idioma.novaPersonalizacao.erro_titulo2 } });
    return true;
  }
  render() {
    const { titulo, erro, success, carregando, iconSalvar, logo, banner, logo_dark, formSuccess, spinner, idioma } = this.state;
    const { usuario } = this.props;
    const id = this.props.match.params.id_personalizacao;
    var credenciais = []
    if (this.props.credenciais) credenciais = this.props.credenciais;

    if (formSuccess) return <Redirect to={{
      pathname: '/configuracoes/personalizacao',
      state: { msg: idioma.novaPersonalizacao.msg_sucesso }
    }}></Redirect>

    let incubadorasPerso = [];
    if (this.props.incubadorasPersonalizacao) incubadorasPerso = this.props.incubadorasPersonalizacao.dados;


    return (
      <>
        {/* Recuperar dimensões da imagem */}
        <img id='logo-preview' alt='' src={this.state.logoPreview} style={{ position: 'absolute', left: '-999em' }} />
        <img id='logoDark-preview' alt='' src={this.state.logoDark_preview} style={{ position: 'absolute', left: '-999em' }} />
        <img id='banner-preview' alt='' src={this.state.bannerPreview} style={{ position: 'absolute', left: '-999em' }} />
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.editarPersonalizacao.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>

              <Link to={"/" + usuario?.personalizacao?.titulo + '/configuracoes/personalizacao'} className="pr-2"> <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
              <Link to="#" onClick={() => this.handlePersonalizar()} > <Botao loading={carregando} texto={idioma.editarPersonalizacao.bt_salvar} icone={iconSalvar} cor="success" /> </Link>
              <div>
                <AlertaErro erros={erro} />
                <AlertaSuccess erros={success} />
                <Form className="mt-4 mb-4">
                  <FormGroup>
                    <Label for="nome">{idioma.personalizacao.titulo2}</Label>
                    <Input type="text" value={titulo} autoComplete="titulo" onChange={(ev) => this.onChnageInput("titulo", ev)} name="titulo" id="titulo" placeholder="Titulo" />
                  </FormGroup>
                  <Col md={6}>
                    <FormGroup>
                      <img src={this.state.logoPreview !== '' ? this.state.logoPreview : apiImagem + logo} alt="..." class="img-thumbnail mr-3" style={{ width: "100px" }} />
                      <Label for="logo">Logo</Label>
                      <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resolucao} 500 x 300</p>
                      {this.state.logoSize && <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resol_atual} {this.state.logoSize}</p>}
                      <Input type="file" id="logo" name="file" accept='image/png, image/jpeg, image/jpg' className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("logo", ev)} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <img src={this.state.logoDark_preview !== '' ? this.state.logoDark_preview : apiImagem + logo_dark} alt="..." class="img-thumbnail mr-3" style={{ width: "100px" }} />
                      <Label for="logo_dark">Logo dark</Label>
                      <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resolucao} 500 x 300</p>
                      {this.state.logoDark_size && <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resol_atual} {this.state.logoDark_size}</p>}
                      <Input type="file" id="logo_dark" name="file" accept='image/png, image/jpeg, image/jpg' className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("logo_dark", ev)} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <img src={this.state.bannerPreview !== '' ? this.state.bannerPreview : apiImagem + banner} alt="..." class="img-thumbnail mr-3" style={{ width: "100px" }} />
                      <Label for="banner">Banner</Label>
                      <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resolucao} 1400 x 900</p>
                      {this.state.bannerSize && <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resol_atual} {this.state.bannerSize}</p>}
                      <Input type="file" id="banner" name="banner" accept='image/png, image/jpeg, image/jpg' className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("banner", ev)} />
                    </FormGroup>
                  </Col>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor-primaria">{idioma.novaPersonalizacao.cor_pri}</Label>
                        <p style={{ fontSize: '11px' }}>Cor principal do sistema, fundo do menu e cabeçalho</p>
                        <ChromePicker id="cor-primaria" disableAlpha color={this.state.cor_principal} onChangeComplete={(color) => this.setState({ cor_principal: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor-secundaria">{idioma.novaPersonalizacao.cor_sec}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_sec_info}</p>
                        <ChromePicker id="cor-secundaria" disableAlpha color={this.state.cor_secundaria} onChangeComplete={(color) => this.setState({ cor_secundaria: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor-terciaria">{idioma.novaPersonalizacao.cor_ter}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_ter_info}</p>
                        <ChromePicker id="cor-terciaria" disableAlpha color={this.state.cor_terciaria} onChangeComplete={(color) => this.setState({ cor_terciaria: color.hex })} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor_quaternaria">{idioma.novaPersonalizacao.cor_qua}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_qua_info}</p>
                        <ChromePicker id="cor_quaternaria" disableAlpha color={this.state.cor_quaternaria ? this.state.cor_quaternaria : '#FFF'} onChangeComplete={(color) => this.setState({ cor_quaternaria: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor_texto_menu">{idioma.novaPersonalizacao.cor_tex}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_tex_info}</p>
                        <ChromePicker id="cor_texto_menu" disableAlpha color={this.state.cor_texto_menu ? this.state.cor_texto_menu : '#FFF'} onChangeComplete={(color) => this.setState({ cor_texto_menu: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor_texto_menu">{idioma.novaPersonalizacao.cor_tex_sec}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_tex_sec_info}</p>
                        <ChromePicker id="cor_texto_menu" disableAlpha color={this.state.cor_texto_menu_hover ? this.state.cor_texto_menu_hover : '#FFF'} onChangeComplete={(color) => this.setState({ cor_texto_menu_hover: color.hex })} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor_titulo">{idioma.novaPersonalizacao.cor_titu}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_titu_info}</p>
                        <ChromePicker id="cor_titulo" disableAlpha color={this.state.cor_titulo ? this.state.cor_titulo : '#FFF'} onChangeComplete={(color) => this.setState({ cor_titulo: color.hex })} />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <br />
                <h4 className='mb-2 mr-3'>{idioma.editarPersonalizacao.incub_vinc}</h4>

                {credenciais.map(credencial => ((credencial.id_componente === 93) ?
                  <Link to={`/${usuario?.personalizacao?.titulo}/personalizacao/${id}/editar/vincular`}> <Botao texto={idioma.criarVinculoPersonalizacao.bt_vincular} color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                  : ""))}
                {spinner ? (
                  <Table>
                    <thead>
                      <th >{idioma.personalizacao.incubadora}</th>
                      <th>{idioma.personalizacao.acoes}</th>
                    </thead>
                    <div className="row d-flex justify-content-center mt-5">
                      <Spinner />
                    </div>
                  </Table>
                )
                  : (
                    <Table className="mt-3">
                      <thead>
                        <th >{idioma.personalizacao.incubadora}</th>
                        <th>{idioma.personalizacao.acoes}</th>
                      </thead>
                      <tbody>
                        {incubadorasPerso && incubadorasPerso.map((incub) => (
                          <tr key={incub.id}>
                            <td>{incub.nome}</td>
                            <td>
                              {credenciais.map(credencial => ((credencial.id_componente === 94) ?
                                <Link to="#" onClick={() => Swal.fire({
                                  title: idioma.personalizacao.modal_titulo,
                                  icon: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: 'green',
                                  cancelButtonColor: '#d33',
                                  confirmButtonText: idioma.personalizacao.modal_confirm
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    this.deletarVinculo(incub.id)
                                  }
                                })}> <Botao loading={carregando} texto={idioma.personalizacao.bt_excluir} icone={iconSalvar} cor="danger" /> </Link>
                                : ""))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>)}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  usuarioDetalhes: state.usuarios.usuarioDetalhes,
  usuario: state.auth.usuario,
  personalizacao: state.incubadoras.personalizacaoIncubadora,
  personalizacoes: state.incubadoras.personalizacoesIncubadora,
  incubadorasPersonalizacao: state.incubadoras.incubadorasPersonalizacao
})

export default connect(mapStateToProps, actions)(EditarPersonalizacao);