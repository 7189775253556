import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import incubadora from '../Ecossistema/incubadora';
import InputMask from 'react-input-mask'
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class Cadastrar extends Component {
    state = {
        id_incubadora: "",
        nome: "",
        cnpj: "",
        segmento: "",
        cep: "",
        endereco: "",
        cidade: "",
        estado: "",
        siteHTTP: "",
        email: "",
        pitchYT: "",
        clientes: "",
        facebook: "",
        instagram: "",
        linkedin: "",
        twitter: "",
        erro: "",
        success: "",
        id_usuario: "",
        file: "",
        currentPage: 1,
        size: 100,
        carregando: "",
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }


    componentDidMount() {
        this.listarIncubadoras();
        if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        if (!this.props.todasIncubadoras && nextProps.todasIncubadoras) this.listarIncubadoras();
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparMinhasIncubadoras()
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    ReplaceTextFunction = (pitch) => {

        var EmbedPitch = this.state.pitch.toString();
        ////////////console.log(EmbedPitch)

        pitch = EmbedPitch.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/");


    }


    receberDadosAPI() {
        if (typeof this.props.startups != "undefined" && this.props.startups != null && !this.state.dadosApi && this.props.startups.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.todasIncubadoras != "undefined" && this.props.todasIncubadoras != null && !this.state.dadosApi && this.props.todasIncubadoras.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    listarIncubadoras() {
        const { currentPage, size } = this.state;
        this.props.getTodasIncubadoras(currentPage, size)
        const { minhasIncubadoras } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (minhasIncubadoras === "undefined") return null
    }


    cadStartup(id_incubadora, nome, cnpj, cep, endereco, pitchYT, cidade, estado, siteHTTP, segmento, clientes, facebook, instagram, linkedin, twitter, file) {
        if (!this.validate()) return;
        this.setState({ carregando: true })

        var facebookURL = "https://www.facebook.com/"
        var instagramURL = "https://www.instagram.com/"
        var linkedinURL = "https://www.linkedin.com/in/"
        var twitterURL = "https://twitter.com/"

        var pitch = pitchYT.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/");
        var site = siteHTTP.replace("www.", "https://www.");

        if (!facebook.includes("https://www.facebook.com/") || !facebook.includes("facebook.com/") || !facebook.includes("www.facebook.com/")) {
            facebook = facebookURL + facebook
        }

        if (!linkedin.includes("https://www.linkedin.com/in/")) {
            linkedin = linkedinURL + linkedin
        }

        if (!instagram.includes("https://www.instagram.com/")) {
            instagram = instagramURL + instagram
        }

        if (!twitter.includes("https://twitter.com/")) {
            twitter = twitterURL + twitter
        }

        const { id_usuario } = this.state
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('id_incubadora', id_incubadora);
        formData.append('nome', nome)
        formData.append('cnpj', cnpj)
        formData.append('cep', cep)
        formData.append('endereco', endereco)
        formData.append('cidade', cidade)
        formData.append('estado', estado)
        formData.append('site', site)
        formData.append('segmento', segmento)
        formData.append('pitch', pitch)
        formData.append('clientes', clientes)
        formData.append('facebook', facebook)
        formData.append('instagram', instagram)
        formData.append('linkedin', linkedin)
        formData.append('twitter', twitter)
        formData.append('id_usuario', id_usuario)

        this.props.postStartup(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { id_incubadora, nome, cidade, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrar.erro_nome } });
        if (!cidade) return this.setState({ erro: { message: idioma.cadastrar.erro_cidade } });
        if (!id_incubadora) return this.setState({ erro: { message: idioma.cadastrar.erro_incub } });

        return true;
    }
    render() {
        const { id_incubadora, nome, cnpj, cep, endereco, file, cidade, estado, siteHTTP, segmento, pitchYT, clientes, facebook, instagram, linkedin, twitter, erro, success, carregando, iconSalvar, formSuccess, idioma } = this.state;
        var incubadoras = []
        if (this.props.todasIncubadoras) incubadoras = this.props.todasIncubadoras.dados;
        ////////console.log(incubadoras)

        if (formSuccess) return <Redirect to={{
            pathname: "/incubadoras/" + id_incubadora + "/startups",
            state: { msg: idioma.cadastrar.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrar.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.cadastrar.nome}</Label>
                                            <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChangeInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrar.nome2} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cnpj">{idioma.cadastrar.cnpj}</Label>
                                            <Input type="text" maxLength="14" value={cnpj} autoComplete="cnpj" onChange={(ev) => this.onChangeInput("cnpj", ev)} name="cnpj" id="cnpj" placeholder={idioma.cadastrar.cnpj} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="segmento">{idioma.cadastrar.segmento}</Label>
                                            <Input type="text" value={segmento} autoComplete="segmento" onChange={(ev) => this.onChangeInput("segmento", ev)} name="segmento" id="segmento" placeholder={idioma.cadastrar.segmento2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cep">{idioma.cadastrar.cep}</Label>
                                            <Input type="text" data-inputmask="'mask':'00000-000'" maxLength="8" value={cep} autoComplete="cep" onChange={(ev) => this.onChangeInput("cep", ev)} name="cep" id="cep" />
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="endereco">{idioma.cadastrar.end}</Label>
                                            <Input type="text" value={endereco} autoComplete="endereco" onChange={(ev) => this.onChangeInput("endereco", ev)} name="endereco" id="endereco" placeholder={idioma.cadastrar.end} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="file">{idioma.cadastrar.img}</Label>
                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                </FormGroup>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cidade">{idioma.cadastrar.cidade}</Label>
                                            <Input type="text" value={cidade} autoComplete="cidade" onChange={(ev) => this.onChangeInput("cidade", ev)} name="cidade" id="cidade" placeholder={idioma.cadastrar.cidade} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="estado">{idioma.cadastrar.estado}</Label>
                                            <Input type="text" value={estado} autoComplete="estado" onChange={(ev) => this.onChangeInput("estado", ev)} name="estado" id="estado" placeholder={idioma.cadastrar.estado} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="siteHTTP">{idioma.cadastrar.site}</Label>
                                            <Input type="text" value={siteHTTP} autoComplete="siteHTTP" onChange={(ev) => this.onChangeInput("siteHTTP", ev)} name="siteHTTP" id="siteHTTP" placeholder={idioma.cadastrar.site2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="perfil">{idioma.cadastrar.incubadora}</Label>
                                            <Input type="select" onChange={(ev) => this.onChangeInput("id_incubadora", ev)} id="id_incubadora" name="id_incubadora">
                                                <option>{idioma.cadastrar.incubadora2}</option>
                                                {incubadoras.map(incubadora => (
                                                    <option value={incubadora.id} key={incubadora.id}>
                                                        {incubadora.nome}
                                                    </option>))}
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="pitchYT">{idioma.cadastrar.pitch}</Label>
                                            <Input type="text" value={pitchYT} autoComplete="pitchYT" onChange={(ev) => this.onChangeInput("pitchYT", ev)} name="pitchYT" id="pitchYT" placeholder={idioma.cadastrar.pitch2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="clientes">{idioma.cadastrar.num_clientes}</Label>
                                    <Input type="number" value={clientes} autoComplete="clientes" onChange={(ev) => this.onChangeInput("clientes", ev)} name="clientes" id="clientes" placeholder={idioma.cadastrar.num_clientes} />
                                </FormGroup>
                                <Row form>
                                    <Col md={6}>
                                        <Label for="facebook">Facebook</Label>
                                        <Input type="text" value={facebook} autoComplete="facebook" onChange={(ev) => this.onChangeInput("facebook", ev)} name="facebook" id="facebook" placeholder="Facebook" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="instagram">Instagram</Label>
                                        <Input type="text" value={instagram} autoComplete="instagram" onChange={(ev) => this.onChangeInput("instagram", ev)} name="instagram" id="instagram" placeholder="Instagram" />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <Label for="linkedin">LinkedIn</Label>
                                        <Input type="text" value={linkedin} autoComplete="linkedin" onChange={(ev) => this.onChangeInput("linkedin", ev)} name="linkedin" id="linkedin" placeholder="LinkedIn" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="twitter">Twitter</Label>
                                        <Input type="text" value={twitter} autoComplete="twitter" onChange={(ev) => this.onChangeInput("twitter", ev)} name="twitter" id="twitter" placeholder="Twitter" />
                                    </Col>
                                </Row>
                                <Link to="#" onClick={() => this.cadStartup(id_incubadora, nome, cnpj, cep, endereco, pitchYT, cidade, estado, siteHTTP, segmento, clientes, facebook, instagram, linkedin, twitter, file)}> <Botao loading={carregando} classes="botao-responsivo botao-estilo" texto={idioma.participantes.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>

                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.participantes.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario,
    todasIncubadoras: state.startups.todasIncubadoras,

})

export default connect(mapStateToProps, actions)(Cadastrar);