import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfil';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Form, FormGroup, Label, Input, Row, Col, Table } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import { faBoxTissue } from '@fortawesome/free-solid-svg-icons';
import BotaoIcon from '../../componentes/BotaoIcon';
import ReactTooltip from "react-tooltip";
import { tr } from 'date-fns/locale';
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Metodologia extends Component {
    state = {
        id_startup: "",
        id_incubadora: "",
        id_metodologia: "",
        id_metodologia_selecionada: "",
        id_processo: "",
        id_radar: "",
        nova: false,
        pertence: false,
        editar: false,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup, id_incubadora } = this.props.match.params;
        this.setState({ id_startup })
        this.setState({ id_incubadora })
        this.listarMetodologiasStartup(id_startup);
        this.listarMetodologias(id_startup);
        this.listarRadares(id_startup)
        this.listarStartup(id_startup)
        this.verificaPertence(this.props.usuario.id, id_startup)

        if (this.props.usuario && this.props.credenciais) this.handlePertence(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }

    componentWillUnmount() {
        this.props.limparMetodologias();
        this.props.limparIncubadoras();
        this.props.limparStartup()
    }


    chamarAlertaParticipante(listaUsuarios) {
        const { idioma } = this.state
        var lista = [];
        var listaUsuarios = [];
        for (var i in listaUsuarios) {
            listaUsuarios = listaUsuarios[i].usuarios.nome
        }

        Swal.fire({
            title: idioma.perfil.modal_titulo2,
            input: 'select',
            inputOptions: lista,

            inputPlaceholder: idioma.perfil.modal_info,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.participantes.bt_cancelar,
            confirmButtonText: idioma.perfil.modal_confirm
        })
        return new Promise((resolve) => {
            resolve()
        })
    }

    componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;
        if (!this.props.listaMetodologias && nextProps.listaMetodologias) {
            this.listarMetodologiasStartup(id_startup);
            this.listarMetodologias(id_startup);
        }
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.metodologias && nextProps.metodologias) {
            this.listarStartup(id_startup);
        }
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_startup);
        }
        if (!this.props.radaresPorStartup && nextProps.radaresPorStartup) this.listarRadares(id_startup);
        this.receberDadosAPI();
    }


    receberDadosAPI() {
        if (typeof this.props.metodologiasPorStartup != "undefined" && this.props.metodologiasPorStartup != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.metodologias != "undefined" && this.props.metodologias != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                if (typeof this.props.radares != "undefined" && this.props.radares != null && !this.state.dadosApi) {
                    this.setState({ dadosApi: true });
                }
            }
        }
    }

    listarMetodologiasStartup(id_startup) {
        this.props.getMetodologiasPorStartup(id_startup);
        const { metodologiasPorStartup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (metodologiasPorStartup === "undefined") return null
    }

    listarRadares(id_startup) {
        this.props.getRadaresPorStartup(id_startup)
        const { radaresPorStartup } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (radaresPorStartup === "undefined") return null
    }

    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }

    // listarMetodologias() {
    //     this.props.getMetodologias();
    //     const { metodologias } = this.props;
    //     if (this.props.location.state) {

    //         this.setState({ msg: this.props.location.state.msg })
    //         this.props.location.state.msg = "";
    //     }
    //     if (metodologias === "undefined") return null
    // }

    listarMetodologias(id_startup) {
        this.props.getMetodologiasStartup(id_startup);
        const { metodologias } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (metodologias === "undefined") return null
    }

    listarUsuarios() {
        this.props.getUsuarios();
        const { usuarios } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (usuarios === "undefined") return null
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })

    }

    handleSelectedMetodology(campo, ev) {
        this.setState({ [campo]: ev.target.value })
        this.setState({ id_metodologia_selecionada: ev.target.value })
    }

    handleNovaMetodologia(nova) {
        if (nova == false) {
            this.setState({ nova: true })
        } else { this.setState({ nova: false }) }
    }

    handleEditarMetodologia(editar) {
        if (editar == false) {
            this.setState({ editar: true })
        } else { this.setState({ editar: false }) }
    }


    vincularMetodologia() {
        const { id_metodologia, id_startup, id_processo, id_radar } = this.state;
        let id_radarmetodologia = id_radar
        this.setState({ carregando: true })
        this.props.postVincularMetodologia({ id_metodologia, id_startup, id_processo, id_radarmetodologia }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.setState({ nova: false })
                this.listarMetodologias(id_startup);
            }
        }
        )
    }

    //deletar startup
    deletandoMetodologia(id_metodologia, id_startup, id_processo) {
        ////console.log(id_metodologia, id_startup, id_processo)
        this.setState({ carregando: true })
        this.props.deletarMetodologia(id_startup, id_metodologia, id_processo, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
                this.props.limparStartupMetodologia();
                this.listarMetodologias(id_startup);

            }
        })
    }

    chamarAlertaDeletar(id_metodologia, id_startup, id_processo) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.vincularMetodologia.modal_titulo,
            html: idioma.vincularMetodologia.modal_info,
            input: 'text',
            inputPlaceholder: idioma.vincularMetodologia.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.participantes.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.vincularMetodologia.confirm) {
                this.deletandoMetodologia(id_metodologia, id_startup, id_processo);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.vincularMetodologia.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.vincularMetodologia.modal_sucesso
        })
    }

    handlePertence(usuario, credenciais, id_startup, id_incubadora) {
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }
        if (usuario.incubadoras.length > 0) {
            for (let i in usuario.incubadoras) {
                if (usuario.incubadoras[i].id_incubadora == id_incubadora) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (usuario.startups.length > 0) {
            for (let s in usuario.startups) {
                if (usuario.startups[s].id_startup == id_startup) {
                    this.setState({ pertence: true })
                }
            }
        }
    }

    verificaPertence(id_usuario, id_startup) {
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { msg, carregando, id_radar, pertence, erro, success, dadosApi, id_metodologia_selecionada, id_startup, nova, editar, formSuccess, idioma } = this.state; var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var metodologias = [];
        if (this.props.metodologias) metodologias = this.props.metodologias.dados;
        var listaMetodologias = [];
        if (this.props.metodologiasPorStartup) listaMetodologias = this.props.metodologiasPorStartup.dados;
        var listaUsuarios = [];
        if (this.props.listaUsuarios) listaUsuarios = this.props.listaUsuarios.dados;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        let aux = []
        for (let m in metodologias) {
            if (metodologias[m].metodologiaAtiva == true) {
                aux.push(true)
            }
        }
        var radares = [];
        if (this.props.radaresPorStartup) radares = this.props.radaresPorStartup.dados;
        ////console.log(metodologias)
        // if (formSuccess) return <Redirect to={{
        //     pathname: "/conteudos",
        // }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.perfil.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        usuarioPertence.pertence &&
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>

                            <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <Link onClick={() => this.handleNovaMetodologia(nova)} > <Botao texto={idioma.editarMetodologia.bt_vincular} classes="botao-responsivo" icone={nova == true ? "minus" : "plus"} posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />

                                    {dadosApi ?
                                        usuarioPertence.pertence ?
                                            <div className="">
                                                {metodologias.length > 0 && aux.length > 0 ?
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th className="row-nome">{idioma.editarMetodologia.metodologia}</th>
                                                                <th>{idioma.editarMetodologia.processo}</th>
                                                                <th>{idioma.editarMetodologia.radar}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {metodologias.length > 0 ? metodologias.map(metodologia => (metodologia.metodologiaAtiva == true) ?
                                                                <tr key={metodologia.id}>

                                                                    <td>{metodologia.nome}</td>
                                                                    <td>
                                                                        <div>{metodologia.listaProcessos.map(processo => (processo.ativo == true) ?
                                                                            processo.id == metodologia.listaProcessos.length ?
                                                                                <>
                                                                                    {processo.nome}
                                                                                    <a data-tip data-for='deletar'>
                                                                                        <Link to="#" onClick={() => this.chamarAlertaDeletar(metodologia.id, id_startup, processo.id)} className="mr-2">
                                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                                        </Link>
                                                                                    </a>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {processo.nome}
                                                                                    <a data-tip data-for='deletar'>
                                                                                        <Link to="#" onClick={() => this.chamarAlertaDeletar(metodologia.id, id_startup, processo.id)} className="mr-2">
                                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                                        </Link>
                                                                                    </a>
                                                                                    {"-"}
                                                                                </>
                                                                            : "")}
                                                                        </div>

                                                                    </td>
                                                                    {metodologia.radar != null ?
                                                                        <td>{metodologia.radar.nome}</td>
                                                                        : <td><span className="badge badge-pill badge-danger mb-1">X</span></td>}
                                                                    <td>

                                                                        <ReactTooltip id='deletar' type='error'>
                                                                            <span>{idioma.participantes.bt_deletar}</span>
                                                                        </ReactTooltip>
                                                                    </td>


                                                                </tr>
                                                                : "")
                                                                : ""}
                                                        </tbody>
                                                    </Table>
                                                    :
                                                    <Form>
                                                        <Row form>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="metodologia">{idioma.editarMetodologia.metodologia}</Label>
                                                                    <Input type="select" onChange={(ev) => this.handleSelectedMetodology("id_metodologia", ev)} id="id_metodologia" name="id_metodologia">
                                                                        <option>{idioma.editarMetodologia.escolher}</option>
                                                                        {metodologias.map(metodologias => (
                                                                            <option value={metodologias.id} key={metodologias.id}>
                                                                                {metodologias.nome}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="processo">{idioma.editarMetodologia.processo}</Label>
                                                                    <Input type="select" onChange={(ev) => this.onChnageInput("id_processo", ev)} id="id_processo" name="id_processo">
                                                                        <option>Selecionar processo</option>
                                                                        {metodologias.map(dados => (
                                                                            dados.id == id_metodologia_selecionada ?
                                                                                dados.listaProcessos.map(processos => (
                                                                                    <option value={processos.id} key={processos.id}>
                                                                                        {processos.nome}
                                                                                    </option>
                                                                                ))
                                                                                : ""
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="radar">{idioma.editarMetodologia.radar}</Label>
                                                                    <Input type="select" onChange={(ev) => this.onChnageInput("id_radar", ev)} id="id_radar" name="id_radar">
                                                                        <option>{idioma.vincularMetodologia.radar}</option>
                                                                        {radares.map(radar => (
                                                                            <option value={radar.id} key={radar.id}>
                                                                                {radar.nome}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Link to="#" onClick={() => this.vincularMetodologia()}> <Botao loading={carregando} texto={idioma.editarMetodologia.bt_vincular2} /> </Link>

                                                    </Form>
                                                }
                                                {nova == true ?
                                                    <Form>
                                                        < div>
                                                            <h5>{idioma.editarMetodologia.bt_vincular}</h5>
                                                        </div>
                                                        <Row form>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="metodologia">{idioma.editarMetodologia.metodologia}</Label>
                                                                    <Input type="select" onChange={(ev) => this.handleSelectedMetodology("id_metodologia", ev)} id="id_metodologia" name="id_metodologia">
                                                                        <option>{idioma.editarMetodologia.escolher}</option>
                                                                        {metodologias.map(metodologias => (
                                                                            <option value={metodologias.id} key={metodologias.id}>
                                                                                {metodologias.nome}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="processo">{idioma.editarMetodologia.processo}</Label>
                                                                    <Input type="select" onChange={(ev) => this.onChnageInput("id_processo", ev)} id="id_processo" name="id_processo">
                                                                        <option>{idioma.editarMetodologia.escolher2}</option>
                                                                        {metodologias.map(dados => (
                                                                            dados.id == id_metodologia_selecionada ?
                                                                                dados.listaProcessos.map(processos => (
                                                                                    <option value={processos.id} key={processos.id}>
                                                                                        {processos.nome}
                                                                                    </option>
                                                                                ))
                                                                                : ""
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="radar">{idioma.editarMetodologia.radar}</Label>
                                                                    <Input type="select" onChange={(ev) => this.onChnageInput("id_radar", ev)} id="id_radar" name="id_radar">
                                                                        <option>{idioma.vincularMetodologia.radar}</option>
                                                                        {radares.map(radar => (
                                                                            <option value={radar.id} key={radar.id}>
                                                                                {radar.nome}
                                                                            </option>
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Link to="#" onClick={() => this.vincularMetodologia()}> <Botao loading={carregando} texto={idioma.editarMetodologia.bt_vincular2} color='#000000' /> </Link>
                                                    </Form>
                                                    : ""}
                                            </div>
                                            : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.participantes.erro_acesso} />
                                        : <Form>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={150} />

                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={600} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={150} />

                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={600} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Link to="#" onClick={() => this.vincularMetodologia()}>  <Botao loading={carregando} texto={idioma.editarMetodologia.bt_vincular2} color={this.props.usuario?.personalizacao?.cor_principal} /> </Link>
                                        </Form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    metodologias: state.startups.metodologias,
    startup: state.startups.startup,
    listaMetodologias: state.startups.todasMetodologias,
    usuarios: state.usuarios.usuarios,
    pertence: state.startups.pertence,
    radares: state.startups.radares,
    metodologiasPorStartup: state.startups.metodologiasPorStartup,
    radaresPorStartup: state.startups.radaresPorStartup,
})
export default connect(mapStatetoProps, actions)(Metodologia);