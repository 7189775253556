import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import BlocoPerfil from '../../containers/blocos/perfil';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Swal from 'sweetalert2';
import { text } from '@fortawesome/fontawesome-svg-core';
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class Configuracoes extends Component {
    state = {
        id_startup: "",
        id_incubadora: "",
        startup: "",
        msg: "",
        pertence: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup, id_incubadora } = this.props.match.params;
        this.setState({ id_startup });
        this.setState({ id_incubadora });
        this.verificaPertence(this.props.usuario.id, id_startup)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

        // if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

    }


    componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_startup);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparStartup()
    }


    receberDadosAPI() {
        if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }

    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }

    desativandoStartup(id_startup) {
        this.setState({ carregando: true })
        ////////////console.log(id_startup)
        this.props.desativarStartup(id_startup, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }


    //desativar startup
    chamarAlerta(id_startup) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.config.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.participantes.bt_cancelar,
            confirmButtonText: idioma.config.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.desativandoStartup(id_startup);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.config.modal_sucesso
        })
    }
    //deletar startup
    deletandoStartup(id_startup) {
        this.setState({ carregando: true })
        this.props.deletarStartup(id_startup, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_startup) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.config.modal_titulo2,
            html: idioma.config.modal_info,
            input: 'text',
            inputPlaceholder: idioma.config.modal_confirm2,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.config.bt_del,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value == idioma.config.confirm) {
                this.deletandoStartup(id_startup);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.config.erro_confirm
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.config.modal_sucesso2
        })
    }

    handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
        if (usuarios.startups.length > 0) {
            for (let u in usuarios.startups) {
                if (usuarios.startups[u].id_startup == id_startup) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (usuarios.incubadoras.length > 0) {
            for (let u in usuarios.incubadoras) {
                if (usuarios.incubadoras[u].id_incubadora == id_incubadora) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }

    }

    verificaPertence(id_usuario, id_startup) {
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { id_startup, dadosApi, formSuccess, pertence, idioma } = this.state
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;


        var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/ecossistema",
            state: { msg: idioma.config.modal_sucesso2 }
        }}></Redirect>
        ////////console.log(usuario)
        ////////console.log(startup)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.config.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        usuarioPertence.pertence &&
                        < BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {dadosApi ?
                        usuarioPertence.pertence ?
                            <div className="col-md-12 mb-2">
                                <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                    <div className="row">
                                        {credenciais.map(credencial => ((credencial.id_componente == 13) ?
                                            <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/metodologia/"}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="chalkboard-teacher" size="3x" color="black" />
                                                                <div className="titulo-menu-perfil">{idioma.config.metodologia}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            : ""))}
                                        {credenciais.map(credencial => ((credencial.id_componente == 37) ?
                                            <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/incubadora"}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="building" size="3x" color="black" />
                                                                <div className="titulo-menu-perfil">{idioma.config.incubadoras}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            : ""))}
                                        {credenciais.length > 0 ?
                                            (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 20) ?
                                                <>
                                                    <div className="col-md-2 card-menu-perfil" key={credenciais.id_componente} >
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/editar"}>
                                                            <Card className="card-dash">
                                                                <CardBody className="img-text-wrapper">
                                                                    <div className="logo-wrapper">
                                                                        <FontAwesomeIcon icon="pencil-alt" size="3x" color="black" />
                                                                        <div className="titulo-menu-perfil">{idioma.config.editar}</div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-2 card-menu-perfil" key={credenciais.id_componente} >
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/foto"}>
                                                            <Card className="card-dash">
                                                                <CardBody className="img-text-wrapper">
                                                                    <div className="logo-wrapper">
                                                                        <FontAwesomeIcon icon="camera" size="3x" color="black" />
                                                                        <div className="titulo-menu-perfil">{idioma.config.editar_foto}</div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-2 card-menu-perfil"  >
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/capa"}>
                                                            <Card className="card-dash">
                                                                <CardBody className="img-text-wrapper">
                                                                    <div className="logo-wrapper">
                                                                        <FontAwesomeIcon icon="image" size="3x" color="black" />
                                                                        <div className="titulo-menu-perfil">{idioma.config.editar_capa}</div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                </>
                                                :
                                                usuario.startups.map(minhaStartup => ((minhaStartup.id_startup == startup.id) ?
                                                    <>
                                                        <div className="col-md-2 card-menu-perfil" key={minhaStartup.id_startup} >
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/editar"}>
                                                                <Card className="card-dash">
                                                                    <CardBody className="img-text-wrapper">
                                                                        <div className="logo-wrapper">
                                                                            <FontAwesomeIcon icon="pencil-alt" size="3x" color="black" />
                                                                            <div className="titulo-menu-perfil">{idioma.config.editar}</div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-2 card-menu-perfil" key={minhaStartup.id_startup} >
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/foto"}>
                                                                <Card className="card-dash">
                                                                    <CardBody className="img-text-wrapper">
                                                                        <div className="logo-wrapper">
                                                                            <FontAwesomeIcon icon="camera" size="3x" color="black" />
                                                                            <div className="titulo-menu-perfil">{idioma.config.editar_foto}</div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </div>
                                                        <div className="col-md-2 card-menu-perfil" key={minhaStartup.id_startup} >
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/capa"}>
                                                                <Card className="card-dash">
                                                                    <CardBody className="img-text-wrapper">
                                                                        <div className="logo-wrapper">
                                                                            <FontAwesomeIcon icon="image" size="3x" color="black" />
                                                                            <div className="titulo-menu-perfil">{idioma.config.editar_capa}</div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Link>
                                                        </div>
                                                    </>
                                                    : ""))
                                            : ""}
                                        {credenciais.map(credencial => ((credencial.id_componente == 14) ?
                                            <div className="col-md-2 card-menu-perfil" key={credencial.id_componente} >
                                                <Link to="#" onClick={() => this.chamarAlerta(id_startup)}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="power-off" size="3x" color="red" />
                                                                <div className="titulo-menu-perfil">{idioma.config.desativar}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            : ""))}

                                        {credenciais.map(credencial => ((credencial.id_componente == 45) ?
                                            <div className="col-md-2 card-menu-perfil"  >
                                                <Link to="#" onClick={() => this.chamarAlertaDeletar(id_startup)}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="trash-alt" size="3x" color="red" />
                                                                <div className="titulo-menu-perfil">{idioma.config.bt_del}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            : ""))}
                                    </div>
                                </div>
                            </div>
                            : <SemDados subtitulo={idioma.sem_dados_sub} titulo="Acesso negado!" />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    startup: state.startups.startup,
    pertence: state.startups.pertence,
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(Configuracoes);