import axios from 'axios';
import {
  GET_DESAFIOS,
  GET_DESAFIO,
  GET_MINHAS_STARTUPS,
  GET_MINHAS_INCUBADORAS,
  GET_SOLUCOES,
  GET_SOLUCAO,
  GET_USERS,
  LIMPAR_DESAFIOS,
  LIMPAR_DESAFIO,
  LIMPAR_SOLUCOES,
  GET_ARQUIVOS_DESAFIO,
  GET_ARQUIVOS_SOLUCAO,
  GET_AVALIADORES,
  LIMPAR_AVALIADORES,
  GET_AVALIACOES,
  GET_AVALIACOES_GESTOR,
  LIMPAR_USERS,
  GET_SEARCH,
  GET_AVALIADOR,
  GET_PILARES,
  GET_AVALIACAO,
} from './types';
import { api } from '../../config';
import { getHeaders, clearToken, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getDesafios = (paginaAtual, limite) => {
  return function (dispatch) {
    axios
      .get(api + `/desafios?page=${paginaAtual}&size=${limite}`, getHeaders())
      .then((response) => {
        ////console.log(response)
        dispatch({ type: GET_DESAFIOS, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getSearch = (procura, paginaAtual, limite) => {
  ////console.log(procura)
  return function (dispatch) {
    axios
      .get(
        api +
        `/usuarios/buscar?procura=${procura}&page=${paginaAtual}&size=${limite}`,
        getHeaders(),
      )
      .then((response) => {
        //console.log(response)
        dispatch({ type: GET_SEARCH, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getAvaliacoes = (id_solucao) => {
  return function (dispatch) {
    axios
      .get(api + `/avaliacoes?id_solucao=${id_solucao}`, getHeaders())
      .then((response) => {
        dispatch({ type: GET_AVALIACOES, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getAvaliacoesGestor = (id_solucao) => {
  return function (dispatch) {
    axios
      .get(api + `/avaliacoes-gestor?id_solucao=${id_solucao}`, getHeaders())
      .then((response) => {
        // console.log(response)
        dispatch({ type: GET_AVALIACOES_GESTOR, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getAvaliadores = (id_desafio) => {
  return function (dispatch) {
    axios
      .get(api + `/avaliadores/${id_desafio}`, getHeaders())
      .then((response) => {
        // console.log(response)
        dispatch({ type: GET_AVALIADORES, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getAvaliador = (id_usuario, id_desafio) => {
  return function (dispatch) {
    axios
      .get(
        api + `/avaliador/verifica/${id_usuario}?id_desafio=${id_desafio}`,
        getHeaders(),
      )
      .then((response) => {
        // console.log(response)
        dispatch({ type: GET_AVALIADOR, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getPilares = (id_desafio) => {
  return function (dispatch) {
    axios
      .get(api + `/pilares/${id_desafio}`, getHeaders())
      .then((response) => {
        dispatch({ type: GET_PILARES, payload: response });
      })
      .catch(errorHandle);
  };
};

export const aceitarAvaliador = (dados, callback) => {
  return function (dispatch) {
    axios
      .put(api + `/avaliador/termos/aceitar`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const aprovarTermoAvaliador = (dados, callback) => {
  return function (dispatch) {
    axios
      .put(api + `/avaliador/aprovar`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const reprovarTermoAvaliador = (dados, callback) => {
  return function (dispatch) {
    axios
      .put(api + `/avaliador/reprovar`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postAvaliador = (dados, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/avaliador`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postDesafioEmailAceite = (dados, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/desafio/solucao/aceitar/email`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postDesafioEmailRejeitar = (dados, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/desafio/solucao/rejeitar/email`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postNovoAvaliador = (dados, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/avaliador/novo`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postAvaliacao = (dados, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/avaliacao`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postAvaliacaoGestor = (dados, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/avaliacao-gestor`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const deletarAvaliador = (id, callback) => {
  ////console.log(id_solucao)
  return function (dispatch) {
    axios
      .delete(api + `/avaliador/${id}/deletar`, getHeaders())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const putAvaliacaoGestor = (dados, callback) => {
  return function (dispatch) {
    axios
      .put(api + `/avaliacao-gestor/alterar`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};
export const putAvaliacao = (dados, callback) => {
  return function (dispatch) {
    axios
      .put(api + `/avaliacao/alterar`, dados, getHeadersPost())
      .then((response) => {
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const getArquivosDesafio = (id_desafio) => {
  return function (dispatch) {
    axios
      .get(
        api + `/arquivos/hub/buscar/desafio?id_desafio=${id_desafio}`,
        getHeaders(),
      )
      .then((response) => {
        ////console.log(response)
        dispatch({ type: GET_ARQUIVOS_DESAFIO, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getArquivosSolucao = (id_solucao) => {
  return function (dispatch) {
    axios
      .get(
        api + `/arquivos/hub/buscar/solucao?id_solucao=${id_solucao}`,
        getHeaders(),
      )
      .then((response) => {
        // console.log(response)
        dispatch({ type: GET_ARQUIVOS_SOLUCAO, payload: response });
      })
      .catch(errorHandle);
  };
};

export const postDesafio = (dadosDesafio, callback) => {
  return function (dispatch) {
    ////////////console.log(dadosDesafio)
    axios
      .post(api + `/desafio`, dadosDesafio, getHeadersPost())
      .then((response) => {
        ////////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postArquivo = (dadosDesafio, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/arquivos/hub/inserir`, dadosDesafio, getHeadersPost())
      .then((response) => {
        // console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postEnviarDesafio = (dadosDesafio, callback) => {
  return function (dispatch) {
    axios
      .post(api + `/desafio/email`, dadosDesafio, getHeadersPost())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const putDesafio = (dadosDesafio, callback) => {
  return function (dispatch) {
    axios
      .put(api + `/desafio/editar`, dadosDesafio, getHeadersPost())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const getVerDesafio = (id_desafio) => {
  return function (dispatch) {
    axios
      .get(api + `/desafio/${id_desafio}`, getHeaders())
      .then((response) => {
        ////console.log(response)
        dispatch({ type: GET_DESAFIO, payload: response.data });
      })
      .catch(errorHandle);
  };
};

export const getVerAvaliacao = (id) => {
  return function (dispatch) {
    axios
      .get(api + `/avaliacao/${id}`, getHeaders())
      .then((response) => {
        dispatch({ type: GET_AVALIACAO, payload: response.data });
      })
      .catch(errorHandle);
  };
};

export const deletarDesafio = (id_desafio, callback) => {
  return function (dispatch) {
    axios
      .delete(api + `/desafio/${id_desafio}/deletar`, getHeaders())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const getSolucoes = (id_desafio, paginaAtual, limite) => {
  ////console.log(id_desafio, paginaAtual, limite)

  return function (dispatch) {
    axios
      .get(
        api +
        `/desafio/${id_desafio}/solucoes?page=${paginaAtual}&size=${limite}`,
        getHeaders(),
      )
      .then((response) => {
        ////console.log(response)
        dispatch({ type: GET_SOLUCOES, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getVerSolucao = (id_desafio, id_solucao) => {
  return function (dispatch) {
    axios
      .get(api + `/desafio/${id_desafio}/solucao/${id_solucao}`, getHeaders())
      .then((response) => {
        dispatch({ type: GET_SOLUCAO, payload: response.data });
      })
      .catch(errorHandle);
  };
};

export const postSolucao = (dadosSolucao, callback) => {
  return function (dispatch) {
    ////console.log(dadosSolucao)
    axios
      .post(api + `/desafio/solucao`, dadosSolucao, getHeadersPost())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const putSolucao = (dadosDesafio, callback) => {
  return function (dispatch) {
    axios
      .put(api + `/desafio/solucao/editar`, dadosDesafio, getHeadersPost())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const getMinhasStartups = (id_usuario, paginaAtual, limite) => {
  return function (dispatch) {
    axios
      .get(
        api +
        `/startups/usuario/${id_usuario}/listar?page=${paginaAtual}&size=${limite}`,
        getHeaders(),
      )
      .then((response) => {
        ////console.log(response)
        dispatch({ type: GET_MINHAS_STARTUPS, payload: response });
      })
      .catch(errorHandle);
  };
};

export const getMinhasIncubadoras = (id_usuario, paginaAtual, limite) => {
  return function (dispatch) {
    axios
      .get(
        api +
        `/incubadoras/usuario/${id_usuario}/listar?page=${paginaAtual}&size=${limite}`,
        getHeaders(),
      )
      .then((response) => {
        ////console.log(response)
        dispatch({ type: GET_MINHAS_INCUBADORAS, payload: response });
      })
      .catch(errorHandle);
  };
};

export const postAceitarSolucao = (dadosDesafio, callback) => {
  return function (dispatch) {
    ////console.log(dadosDesafio)
    axios
      .put(api + `/desafio/solucao/aceitar`, dadosDesafio, getHeadersPost())
      .then((response) => {
        // console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const postCancelarSolucao = (dadosDesafio, callback) => {
  return function (dispatch) {
    ////console.log(dadosDesafio)
    axios
      .put(api + `/desafio/solucao/rejeitar`, dadosDesafio, getHeadersPost())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const deletarSolucao = (id_solucao, callback) => {
  ////console.log(id_solucao)
  return function (dispatch) {
    axios
      .delete(api + `/desafio/solucao/${id_solucao}/deletar`, getHeaders())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const deletarArquivo = (id, callback) => {
  ////console.log(id_solucao)
  return function (dispatch) {
    axios
      .delete(api + `/arquivos/hub/deletar/${id}`, getHeaders())
      .then((response) => {
        ////console.log(response)
        callback({ erro: response.data });
      })
      .catch((err) => callback(errorHandle(err)));
  };
};

export const getUsuarios = (paginaAtual, limite) => {
  return function (dispatch) {
    axios
      .get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
      .then((response) => {
        ////console.log(response)
        dispatch({ type: GET_USERS, payload: response });
      })
      .catch(errorHandle);
  };
};

export const limparDesafios = () => {
  return function (dispatch) {
    dispatch({ type: LIMPAR_DESAFIOS });
  };
};

export const limparDesafio = () => {
  return function (dispatch) {
    dispatch({ type: LIMPAR_DESAFIO });
  };
};

export const limparSolucoes = () => {
  return function (dispatch) {
    dispatch({ type: LIMPAR_SOLUCOES });
  };
};

export const limparAvaliadores = () => {
  return function (dispatch) {
    dispatch({ type: LIMPAR_AVALIADORES });
  };
};

export const limparUsuarios = () => {
  return function (dispatch) {
    dispatch({ type: LIMPAR_USERS });
  };
};
