export const portugues = {
  perfil: 'Perfil',
  participantes: 'Participantes',
  evolucao: 'Jornada de desenvolvimento',
  config: 'Configurações',
  modal_titulo: 'Você tem certeza?',
  bt_cancelar: 'Cancelar',
  bt_salvar: 'Sim, desativar esse negócio!',
  modal_sucesso: 'Negócio desativado com sucesso!'
}

export const ingles = {
  perfil: 'Profile',
  participantes: 'Participants',
  evolucao: 'Development journey',
  config: 'Settings',
  modal_titulo: 'Are you sure?',
  bt_cancelar: 'Cancel',
  bt_salvar: 'Yes, disable this business!',
  modal_sucesso: 'Business successfully disabled!'
}