import React from 'react';

const AlertaErro = (props) => {
    if (props.erros == "") return null;
    return (
        <div className="alert alert-danger">
            {props.erros.message}
        </div>
    )
}
export default AlertaErro;