import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess'; 
import Swal from 'sweetalert2';
import { Table, Input } from 'reactstrap'; 
import { differenceInDays } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import BotaoIcon from '../../componentes/BotaoIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Titulo from '../../componentes/TituloPaginas';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import { portugues, ingles } from '../../utils/paginausuario_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import '../../styles/usuarios/index.css';

class Usuario extends Component {
  state = {
    currentPage: 1,
    size: 5,
    msg: '',
    erro: '',
    success: '',
    procura: '',
    carregando: false,
    pesquisando: false,
    filtrado: false,
    tipo: '',
    tipo_escolha: '',
    id_usuario: '',
    filtro: false,
    modal: false,
    dadosApi: false,
    idioma: portugues,
    msg_backend: portuguese,
  };
  componentDidMount() {
    const { usuario } = this.props;
    this.listarUsuarios();
    this.listarIncubadoras();
    this.listarStartups();
    this.listarPerfis();

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }
  componentDidUpdate(nextProps) {
    if (!this.props.usuarios && nextProps.usuarios) this.listarUsuarios();
    if (!this.props.incubadoras && nextProps.incubadoras)
      this.listarIncubadoras();
    if (!this.props.startups && nextProps.startups) this.listarStartups();
    if (!this.props.perfis && nextProps.perfis) this.listarPerfis();
    this.receberDadosAPI();
  }
  componentWillUnmount() {
    this.props.limparUsuarios();
  }
  receberDadosAPI() {
    if (
      typeof this.props.usuarios != 'undefined' &&
      this.props.usuarios != null &&
      !this.state.dadosApi &&
      this.props.usuarios.currentPage === this.state.currentPage
    ) {
      if (
        typeof this.props.incubadoras != 'undefined' &&
        this.props.incubadoras != null &&
        !this.state.dadosApi
      ) {
        if (
          typeof this.props.startups != 'undefined' &&
          this.props.startups != null &&
          !this.state.dadosApi
        ) {
          if (
            typeof this.props.perfis != 'undefined' &&
            this.props.perfis != null &&
            !this.state.dadosApi
          ) {
            this.setState({ dadosApi: true });
          }
        }
      }
    }
  }

  usuarioNovo(data) {
    var result = differenceInDays(new Date(), data);
  }

  listarIncubadoras() {
    const { currentPage, size } = this.state;
    this.props.getIncubadorasCompleto(currentPage, size);
    const { incubadoras } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (incubadoras === 'undefined') return null;
  }

  listarStartups() {
    const { currentPage, size } = this.state;
    this.props.getStartupsCompleto(currentPage, size);
    const { startups } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (startups === 'undefined') return null;
  }

  listarPerfis() {
    const { currentPage, size } = this.state;
    this.props.getPerfisAcesso(currentPage, size);
    const { perfis } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (perfis === 'undefined') return null;
  }

  listarUsuarios() {
    this.props.limparUsuarios();
    const { currentPage, size } = this.state;
    this.props.getUsuarios(currentPage, size);
    const { usuarios } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (usuarios === 'undefined') return null;
  }
  changePageAtual(currentPage) {
    this.props.limparUsuarios();
    const { tipo_escolha, size, filtrado, tipo } = this.state;
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      if (tipo == 'Incubadora' && filtrado) {
        this.props.getUsuariosIncubadora(tipo_escolha, currentPage, size);
      } else if (tipo == 'Startup' && filtrado) {
        this.props.getParticipantes(tipo_escolha, currentPage, size);
      } else if (tipo == 'Perfil' && filtrado) {
        this.props.getUsuariosPerfil(tipo_escolha, currentPage, size);
      } else {
        this.listarUsuarios();
      }
    });
  }

  changePageAtualSearch(currentPage) {
    const { procura, size } = this.state;
    this.props.limparUsuarios();
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.props.getSearch(procura, currentPage, size);
    });
  }
  apagarUsuario(id) {
    this.props.limparUsuarios();
    this.setState({ dadosApi: false });
    this.setState({ carregando: true });
    this.props.deletarUsuario(id, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
        this.listarUsuarios();
        this.sucessoAlerta();
        // this.setState({ formSuccess: true });
      }
    });
  }
  chamarAlerta(id) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.modal_excluir.titulo,
      text: idioma.modal_excluir.info,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: idioma.modal_excluir.bt_cancelar,
      confirmButtonText: idioma.modal_excluir.bt_sucesso,
    }).then((result) => {
      if (result.value) {
        this.apagarUsuario(id);
      }
    });
  }
  sucessoAlerta() {
    const { idioma } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: idioma.modal_excluir.info_sucesso,
    });
  }

  handleSearch(procura) {
    this.setState({ procura: procura });
    this.setState({ pesquisando: true });
    this.props.limparUsuarios();
    const { currentPage, size } = this.state;
    this.setState({ currentPage: 1 });
    this.props.getSearch(procura, currentPage, size);
  }

  eraseSearch(campo) {
    this.setState({ pesquisando: false });
    this.setState({ [campo]: '' });
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value });
  }

  //ativar usuario
  ativarUsuario(id) {
    this.setState({ dadosApi: false });
    this.setState({ carregando: true });
    this.props.putAtivarUsuario(id, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
        this.sucessoAlertaAtivar();
        if (this.state.pesquisando == true) {
          this.handleSearch(this.state.procura);
        } else {
          this.listarUsuarios();
        }
      }
    });
  }

  chamarAlertaAtivar(id) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.modal_ativar.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: idioma.modal_ativar.bt_cancelar,
      confirmButtonText: idioma.modal_ativar.bt_sucesso,
    }).then((result) => {
      if (result.value) {
        this.ativarUsuario(id);
      }
    });
  }

  sucessoAlertaAtivar() {
    const { idioma } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: idioma.modal_ativar.info_sucesso,
    });
  }

  //desativar usuario
  desativarUsuario(id) {
    this.setState({ dadosApi: false });
    this.setState({ carregando: true });
    this.props.putDesativarUsuario(id, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
        this.sucessoAlerta();
        if (this.state.pesquisando == true) {
          this.handleSearch(this.state.procura);
        } else {
          this.listarUsuarios();
        }
      }
    });
  }

  chamarAlerta(id) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.modal_desativar.titulo,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: idioma.modal_desativar.bt_cancelar,
      confirmButtonText: idioma.modal_desativar.bt_sucesso,
    }).then((result) => {
      if (result.value) {
        this.desativarUsuario(id);
      }
    });
  }
  sucessoAlerta() {
    const { idioma } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: idioma.modal_desativar.info_sucesso,
    });
  }

  handleEnter(ev) {
    const { procura } = this.state;
    if (ev.key == 'Enter') {
      this.handleSearch(procura);
    }
  }

  handleUsers() {
    const { tipo, tipo_escolha, currentPage, size, filtrado } = this.state;
    this.setState({ filtrado: true });
    this.setState({ dadosApi: false });
    if (tipo == 'Incubadora') {
      this.props.limparUsuarios();
      this.setState({ dadosApi: false });
      this.props.getUsuariosIncubadora(tipo_escolha);
    } else if (tipo == 'Startup') {
      this.props.limparUsuarios();
      this.setState({ dadosApi: false });
      this.props.getParticipantes(tipo_escolha);
    } else if (tipo == 'Perfil') {
      this.props.limparUsuarios();
      this.setState({ dadosApi: false });
      this.props.getUsuariosPerfil(tipo_escolha);
    }
    const { usuarios } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (usuarios === 'undefined') return null;
  }

  cancelFiltro() {
    const { filtrado, filtro } = this.state;
    this.setState({ filtrado: false });
    this.setState({ filtro: false });
    this.listarUsuarios();
  }

  onHover(ev) {
    ev.target.style.backgroundColor =
      this.props.usuario?.personalizacao.cor_secundaria;
    ev.target.style.color =
      this.props.usuario?.personalizacao.cor_texto_menu_hover;
  }

  cleanHover(ev, isActive) {
    ev.target.style.backgroundColor = isActive
      ? this.props.usuario?.personalizacao.cor_secundaria
      : 'transparent';
    ev.target.style.color = this.props.usuario?.personalizacao.cor_secundaria;
  }

  render() {
    const {
      msg,
      carregando,
      erro,
      tipo,
      filtrado,
      tipo_escolha,
      filtro,
      id_usuario,
      success,
      pesquisando,
      procura,
      dadosApi,
      idioma,
    } = this.state;

    var usuario = [];
    if (this.props.usuario) usuario = this.props.usuario;
    var usuarios = [];
    var usuariosIncubadora = [];
    var participantes = [];
    var usuariosPerfil = [];
    if (pesquisando == false) {
      if (filtrado) {
        if (tipo == 'Incubadora') {
          if (this.props.usuariosIncubadora)
            usuariosIncubadora = this.props.usuariosIncubadora.usuarios;
        }
        if (tipo == 'Startup') {
          if (this.props.participantes)
            participantes = this.props.participantes.usuarios;
        }
        if (tipo == 'Perfil') {
          if (this.props.usuariosPerfil)
            usuariosPerfil = this.props.usuariosPerfil.dados;
        }
      } else {
        if (this.props.usuarios) usuarios = this.props.usuarios.dados;
        var temPaginaAnterior = false;
        if (
          typeof this.props.usuarios != 'undefined' &&
          this.props.usuarios != null &&
          this.props.usuarios.currentPage !== '' &&
          this.props.usuarios.currentPage !== 1
        ) {
          temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (
          typeof this.props.usuarios != 'undefined' &&
          this.props.usuarios !== null &&
          this.props.usuarios.nextPage <= this.props.usuarios.totalPages &&
          this.props.usuarios.nextPage != null
        ) {
          proximaPagina = true;
          temProximaPagina = true;
        }
      }
    } else {
      if (this.props.search) usuarios = this.props.search.dados;
      var temPaginaAnteriorSearch = false;
      if (
        typeof this.props.search != 'undefined' &&
        this.props.search != null &&
        this.props.search.currentPage !== '' &&
        this.props.search.currentPage !== 1
      ) {
        temPaginaAnteriorSearch = true;
      }
      var proximaPaginaSearch = false;
      var temProximaPaginaSearch = false;
      if (
        typeof this.props.search != 'undefined' &&
        this.props.search !== null &&
        this.props.search.nextPage <= this.props.search.totalPages &&
        this.props.search.nextPage != null
      ) {
        proximaPaginaSearch = true;
        temProximaPaginaSearch = true;
      }
    }
    var incubadoras = [];
    if (this.props.incubadoras) incubadoras = this.props.incubadoras.dados;
    var startups = [];
    if (this.props.startups) startups = this.props.startups.dados;
    var perfis = [];
    if (this.props.perfis) perfis = this.props.perfis.dados;
    var credenciais = [];
    if (this.props.credenciais) credenciais = this.props.credenciais;
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario.personalizacao.cor_principal,
          }}
        >
          <Titulo titulo={idioma.titulo} />
        </div>
        <div className="container-fluid bloco-topo">
          <div className="col-md-12 mb-2">
            <div
              className="list-group-item mb-2"
              style={{
                backgroundColor:
                  this.props.usuario &&
                  this.props.usuario?.personalizacao?.cor_quaternaria,
              }}
            >
              <Link
                onClick={() => this.props.history.goBack()}
                className="pr-2"
              >
                {' '}
                <Botao
                  texto={idioma.bt_voltar}
                  classes="botao-responsivo"
                  icone="arrow-left"
                  posicao="E"
                  color={this.props.usuario?.personalizacao.cor_terciaria}
                />{' '}
              </Link>

              <Link
                to={
                  '/' +
                  this.props.usuario?.personalizacao?.titulo +
                  '/usuarios/cadastrar'
                }
                className="mr-2"
              >
                <Botao
                  texto={idioma.cadastrar.bt_cadastrar}
                  classes="botao-responsivo"
                  icone="link"
                  posicao="E"
                  color={this.props.usuario?.personalizacao?.cor_terciaria}
                />
                {/* <button className="btn btn-outline btn-md botao-responsivo" onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: usuario?.personalizacao?.cor_secundaria }}>
                                    {idioma.cadastrar.bt_cadastrar}
                                </button> */}
              </Link>
              <Link to="#" onClick={() => this.setState({ filtro: !filtro })}>
                <Botao
                  loading={carregando}
                  classes="botao-responsivo botao-estilo "
                  posicao={'E'}
                  color={this.props.usuario?.personalizacao?.cor_terciaria}
                  icone="filter"
                  texto={idioma.bt_filtrar}
                />
              </Link>

              <div className="row m-2">
                {filtro == true ? (
                  <>
                    <Link to="#" onClick={() => this.cancelFiltro()}>
                      {' '}
                      <Botao
                        loading={carregando}
                        classes="botao-responsivo botao-estilo ml-2"
                        posicao={'E'}
                        cor="danger"
                        icone="times"
                        texto={idioma.bt_cancel_filtro}
                      />
                    </Link>

                    <Input
                      type="select"
                      value={tipo}
                      onChange={(ev) => this.onChangeInput('tipo', ev)}
                      className="mt-2"
                    >
                      <option>{idioma.filtro.escolha_filtro}</option>
                      <option value="Incubadora">
                        {idioma.filtro.inovacao}
                      </option>
                      <option value="Startup">{idioma.filtro.negocio}</option>
                      <option value="Perfil">{idioma.filtro.perfil}</option>
                    </Input>
                    <Input
                      type="select"
                      value={tipo_escolha}
                      onChange={(ev) => this.onChangeInput('tipo_escolha', ev)}
                      className="mt-2"
                      disabled={tipo == '' ? true : false}
                    >
                      {tipo == '' ? (
                        <option>{idioma.filtro.escolha_filtro}</option>
                      ) : (
                        ''
                      )}
                      {tipo == 'Incubadora' ? (
                        <>
                          <option>{idioma.filtro.escolha_inovacao}</option>
                          {incubadoras
                            ? incubadoras.map((incubadora) => (
                                <option value={incubadora.id}>
                                  {incubadora.nome}
                                </option>
                              ))
                            : ''}
                        </>
                      ) : tipo == 'Startup' ? (
                        <>
                          <option>{idioma.filtro.escolha_negocio}</option>
                          {startups
                            ? startups.map((startup) => (
                                <option value={startup.id}>
                                  {startup.nome}
                                </option>
                              ))
                            : ''}
                        </>
                      ) : tipo == 'Perfil' ? (
                        <>
                          <option>{idioma.filtro.escolha_perfil}</option>
                          {perfis
                            ? perfis.map((perfil) => (
                                <option value={perfil.id}>{perfil.nome}</option>
                              ))
                            : ''}
                        </>
                      ) : (
                        <option>{idioma.filtro.escolha_todo}</option>
                      )}
                    </Input>

                    {filtro == true ? (
                      <Link to="#" onClick={() => this.handleUsers()}>
                        {' '}
                        <Botao
                          loading={carregando}
                          texto={idioma.bt_filtrar}
                          classes="botao-responsivo botao-estilo"
                          color={
                            this.props.usuario?.personalizacao?.cor_terciaria
                          }
                        />{' '}
                      </Link>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="row mt-3 mb-3">
                <div className="col-lg-8">
                  <Input
                    type="text"
                    value={procura}
                    onKeyPress={(ev) => this.handleEnter(ev)}
                    autoComplete="procura"
                    onChange={(ev) => this.onChangeInput('procura', ev)}
                    name="procura"
                    id="procura"
                    placeholder={idioma.filtro.pesquisar}
                  />
                </div>
                <div className="col-lg-4">
                  <div>
                    <span
                      className="pr-3"
                      onClick={() => this.handleSearch(procura)}
                      id="pesquisa"
                      name="pesquisa"
                    >
                      <Botao
                        loading={carregando}
                        classes="botao-pesquisa-responsivo"
                        color={
                          this.props.usuario?.personalizacao?.cor_terciaria
                        }
                        texto={idioma.filtro.bt_pesquisar}
                        icone="search"
                        posicao="E"
                      />
                    </span>
                    <span
                      value={procura}
                      onClick={() => this.eraseSearch('procura')}
                      id="pesquisa"
                      name="pesquisa"
                    >
                      <Botao
                        loading={carregando}
                        classes="botao-pesquisa-responsivo"
                        cor="danger"
                        texto={idioma.modal_password.bt_cancelar}
                        icone="times"
                        posicao="E"
                      />
                    </span>
                  </div>
                </div>
              </div>
              {msg ? <AlertaSuccess erros={{ message: msg }} /> : ''}
              <AlertaErro erros={erro} />
              <AlertaSuccess erros={success} />
              {dadosApi ? (
                <Table>
                  <thead>
                    <th className={'w-25'}>{idioma.tabela.nome}</th>
                    <th className={'w-25'}>{idioma.tabela.inovacao}</th>
                    <th className={'w-25'}>{idioma.tabela.negocio}</th>
                    <th className={'w-25'}>{idioma.tabela.acao}</th>
                  </thead>
                  <tbody>

                  
                    {tipo == 'Incubadora' && filtrado
                      ? usuariosIncubadora &&
                        usuariosIncubadora.length > 0 &&
                        usuariosIncubadora.map((usuario) => (
                          <tr>
                            <td>{usuario.nome}</td>
                            <td>
                              {usuario.incubadoras.length > 0 ? (
                                usuario.incubadoras.length == 1 ? (
                                  usuario.incubadoras.map(
                                    (incubadora) => incubadora,
                                  )
                                ) : (
                                  usuario.incubadoras.map(
                                    (incubadora) => incubadora + ', ',
                                  )
                                )
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  {' '}
                                  <FontAwesomeIcon
                                    icon="times"
                                    size="lg"
                                  />{' '}
                                </span>
                              )}
                            </td>
                            <td>
                              {usuario.startups.length > 0 ? (
                                usuario.startups.length == 1 ? (
                                  usuario.startups.map((startup) => startup)
                                ) : (
                                  usuario.startups.map(
                                    (startup) => startup + ', ',
                                  )
                                )
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  {' '}
                                  <FontAwesomeIcon
                                    icon="times"
                                    size="lg"
                                  />{' '}
                                </span>
                              )}
                            </td>
                            <td>
                              <Link
                                to={
                                  '/' +
                                  this.props.usuario?.personalizacao?.titulo +
                                  '/usuarios/ver/' +
                                  usuario.id
                                }
                                className="mr-2"
                              >
                                <button className="btn btn-outline-primary btn-sm">
                                  {idioma.tabela.bt_perfil}
                                </button>
                              </Link>
                              {usuario.dataDesativacao === null ? (
                                <Tippy
                                  theme="#C82333"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_desativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlerta(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-danger btn-sm ml-1"
                                      icone="ban"
                                    />
                                  </Link>
                                </Tippy>
                              ) : (
                                <Tippy
                                  theme="#28883E"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_ativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlertaAtivar(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-success btn-sm ml-1"
                                      icone="check"
                                    />
                                  </Link>
                                </Tippy>
                              )}
                              {/* <ReactTooltip id='desativar' type='error'>
                                                            <span>{idioma.tabela.bt_desativar}</span>
                                                        </ReactTooltip>
                                                        <ReactTooltip id='ativar' type='success'>
                                                            <span>{idioma.tabela.bt_ativar}</span>
                                                        </ReactTooltip> */}
                            </td>
                          </tr>
                        )) || usuariosIncubadora.length <= 0 && (
                          <p className={'p-2'}>Sem Resultados, Pesquise novamente!</p>
                        )
                      : tipo == 'Startup' && filtrado
                      ? participantes &&
                        participantes.length > 0 &&
                        participantes.map((usuario) => (
                          <tr>
                            <td>{usuario.nome}</td>
                            <td>
                              {usuario.incubadoras.length > 0 ? (
                                usuario.incubadoras.length == 1 ? (
                                  usuario.incubadoras.map(
                                    (incubadora) => incubadora,
                                  )
                                ) : (
                                  usuario.incubadoras.map(
                                    (incubadora) => incubadora + ', ',
                                  )
                                )
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  {' '}
                                  <FontAwesomeIcon
                                    icon="times"
                                    size="lg"
                                  />{' '}
                                </span>
                              )}
                            </td>
                            <td>
                              {usuario.startups.length > 0 ? (
                                usuario.startups.length == 1 ? (
                                  usuario.startups.map((startup) => startup)
                                ) : (
                                  usuario.startups.map(
                                    (startup) => startup + ', ',
                                  )
                                )
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  {' '}
                                  <FontAwesomeIcon
                                    icon="times"
                                    size="lg"
                                  />{' '}
                                </span>
                              )}
                            </td>
                            <td>
                              <Link
                                to={
                                  '/' +
                                  this.props.usuario?.personalizacao?.titulo +
                                  '/usuarios/ver/' +
                                  usuario.id
                                }
                                className="mr-2"
                              >
                                <button className="btn btn-outline-primary btn-sm">
                                  {idioma.tabela.bt_perfil}
                                </button>
                              </Link>
                              {usuario.dataDesativacao === null ? (
                                <Tippy
                                  theme="#C82333"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_desativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlerta(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-danger btn-sm ml-1"
                                      icone="ban"
                                    />
                                  </Link>
                                </Tippy>
                              ) : (
                                <Tippy
                                  theme="#28883E"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_ativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlertaAtivar(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-success btn-sm ml-1"
                                      icone="check"
                                    />
                                  </Link>
                                </Tippy>
                              )}
                              {/* <ReactTooltip id="desativar" type="error">
                                <span>{idioma.tabela.bt_desativar}</span>
                              </ReactTooltip>
                              <ReactTooltip id="ativar" type="success">
                                <span>{idioma.tabela.bt_ativar}</span>
                              </ReactTooltip> */}
                            </td>
                          </tr>
                        ))  ||  participantes.length <= 0 && (
                          <p className={'p-2'}>Sem Resultados, Pesquise novamente!</p>
                        )
                      : tipo == 'Perfil' && filtrado
                      ? usuariosPerfil &&
                        usuariosPerfil.length > 0 &&
                        usuariosPerfil.map((usuario) => (
                          <tr>
                            <td>{usuario.nome}</td>
                            <td>
                              {usuario.incubadoras.length > 0 ? (
                                usuario.incubadoras.length == 1 ? (
                                  usuario.incubadoras.map(
                                    (incubadora) => incubadora,
                                  )
                                ) : (
                                  usuario.incubadoras.map(
                                    (incubadora) => incubadora + ', ',
                                  )
                                )
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  {' '}
                                  <FontAwesomeIcon
                                    icon="times"
                                    size="lg"
                                  />{' '}
                                </span>
                              )}
                            </td>
                            <td>
                              {usuario.startups.length > 0 ? (
                                usuario.startups.length == 1 ? (
                                  usuario.startups.map((startup) => startup)
                                ) : (
                                  usuario.startups.map(
                                    (startup) => startup + ', ',
                                  )
                                )
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  {' '}
                                  <FontAwesomeIcon
                                    icon="times"
                                    size="lg"
                                  />{' '}
                                </span>
                              )}
                            </td>
                            <td>
                              <Link
                                to={
                                  '/' +
                                  this.props.usuario?.personalizacao?.titulo +
                                  '/usuarios/ver/' +
                                  usuario.id
                                }
                                className="mr-2"
                              >
                                <button className="btn btn-outline-primary btn-sm">
                                  {idioma.tabela.perfil}
                                </button>
                              </Link>
                              {usuario.dataDesativacao === null ? (
                                <Tippy
                                  theme="#C82333"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_desativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlerta(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-danger btn-sm ml-1"
                                      icone="ban"
                                    />
                                  </Link>
                                </Tippy>
                              ) : (
                                <Tippy
                                  theme="#28883E"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_ativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlertaAtivar(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-success btn-sm ml-1"
                                      icone="check"
                                    />
                                  </Link>
                                </Tippy>
                              )}
                              {/* <ReactTooltip id="desativar" type="error">
                                <span>{idioma.tabela.bt_desativar}</span>
                              </ReactTooltip>
                              <ReactTooltip id="ativar" type="success">
                                <span>{idioma.bt_ativar}</span>
                              </ReactTooltip> */}
                            </td>
                          </tr>
                        ))  || usuariosPerfil.length <= 0 && (
                          <p className={'p-2'}>Sem Resultados, Pesquise novamente!</p>
                        )
                      : usuarios &&
                        usuarios.length > 0 &&
                        usuarios.map((usuario) => (
                          <tr>
                            <td>{usuario.nome}</td>
                            {!filtrado && (
                              <>
                                <td>
                                  {usuario.incubadoras.length > 0 ? (
                                    usuario.incubadoras.length == 1 ? (
                                      usuario.incubadoras.map(
                                        (incubadora) => incubadora,
                                      )
                                    ) : (
                                      usuario.incubadoras.map(
                                        (incubadora) => incubadora + ', ',
                                      )
                                    )
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      {' '}
                                      <FontAwesomeIcon
                                        icon="times"
                                        size="lg"
                                      />{' '}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {usuario.startups.length > 0 ? (
                                    usuario.startups.length == 1 ? (
                                      usuario.startups.map((startup) => startup)
                                    ) : (
                                      usuario.startups.map(
                                        (startup) => startup + ', ',
                                      )
                                    )
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      {' '}
                                      <FontAwesomeIcon
                                        icon="times"
                                        size="lg"
                                      />{' '}
                                    </span>
                                  )}
                                </td>
                              </>
                            )}
                            <td>
                              <Link
                                to={
                                  '/' +
                                  this.props.usuario?.personalizacao?.titulo +
                                  '/usuarios/ver/' +
                                  usuario.id
                                }
                                className="mr-2"
                              >
                                <button className="btn btn-outline-primary btn-sm">
                                  {idioma.tabela.bt_perfil}
                                </button>
                              </Link>
                              {usuario.dataDesativacao === null ? (
                                <Tippy
                                  theme="#C82333"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_desativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlerta(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-danger btn-sm ml-1"
                                      icone="ban"
                                    />
                                  </Link>
                                </Tippy>
                              ) : (
                                <Tippy
                                  theme="#28883E"
                                  placement="top"
                                  arrow={true}
                                  content={
                                    <span>{idioma.tabela.bt_ativar}</span>
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      this.chamarAlertaAtivar(usuario.id)
                                    }
                                    className="mr-2"
                                  >
                                    <BotaoIcon
                                      loading={carregando}
                                      classes="btn-success btn-sm ml-1"
                                      icone="check"
                                    />
                                  </Link>
                                </Tippy>
                              )}
                              {/* <ReactTooltip id="desativar" type="error">
                                <span>{idioma.tabela.bt_desativar}</span>
                              </ReactTooltip>
                              <ReactTooltip id="ativar" type="success">
                                <span>{idioma.tabela.bt_ativar}</span>
                              </ReactTooltip> */}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </Table>
              ) : (
                <div className="row">
                  <Table>
                    <thead>
                      <tr>
                        <th>{idioma.tabela.nome}</th>
                        <th>{idioma.tabela.acao}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton count={1} width={80} />
                        </td>
                        <td>
                          <Skeleton count={1} width={80} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}

              {dadosApi ? (
                pesquisando == false && !filtrado ? (
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li
                        className={
                          temPaginaAnterior ? 'page-item' : 'page-item disabled'
                        }
                      >
                        <span
                          className="page-link cursor-pointer"
                          onClick={() => this.changePageAtual(1)}
                        >
                          {idioma.page1}
                        </span>
                      </li>

                      {temPaginaAnterior ? (
                        <li className="page-item">
                          <span
                            className="page-link cursor-pointer"
                            onClick={() =>
                              this.changePageAtual(
                                this.props.usuarios.prevPage
                                  ? this.props.usuarios.prevPage
                                  : 1,
                              )
                            }
                          >
                            {this.props.usuarios.prevPage
                              ? this.props.usuarios.prevPage
                              : ''}
                          </span>
                        </li>
                      ) : (
                        ''
                      )}

                      <li
                        className="page-item active"
                        style={{
                          backgroundColor:
                            usuario?.personalizacao?.cor_principal,
                        }}
                      >
                        <span className="page-link cursor-pointer">
                          {this.props.usuarios
                            ? this.props.usuarios.currentPage
                            : '1'}
                        </span>
                      </li>

                      {proximaPagina ? (
                        <li className="page-item">
                          <span
                            className="page-link cursor-pointer"
                            onClick={() =>
                              this.changePageAtual(
                                this.props.usuarios.nextPage
                                  ? this.props.usuarios.nextPage
                                  : 1,
                              )
                            }
                          >
                            {this.props.usuarios.nextPage
                              ? this.props.usuarios.nextPage
                              : ''}
                          </span>
                        </li>
                      ) : (
                        ''
                      )}

                      <li
                        className={
                          temProximaPagina ? 'page-item' : 'page-item disabled'
                        }
                      >
                        <span
                          className="page-link cursor-pointer"
                          onClick={() =>
                            this.changePageAtual(
                              this.props.usuarios
                                ? this.props.usuarios.totalPages
                                : 0,
                            )
                          }
                        >
                          {idioma.page2}
                        </span>
                      </li>
                    </ul>
                  </nav>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => ({
  usuarios: state.usuarios.usuarios,
  usuario: state.auth.usuario,
  search: state.usuarios.search,
  incubadoras: state.usuarios.incubadorasCompleto,
  startups: state.usuarios.startupsCompleto,
  perfis: state.usuarios.perfis,
  usuariosPerfil: state.usuarios.usuariosPerfil,
  usuariosIncubadora: state.usuarios.usuariosIncubadora,
  participantes: state.usuarios.participantes,
});
export default connect(mapStatetoProps, actions)(Usuario);