import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import Swal from 'sweetalert2';
import Alert from '@material-ui/lab/Alert';
import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';

class CadastrarDesafio extends Component {
    state = {
        id_desafio: "",
        titulo: "",
        descricao: "",
        id_startup: false,
        id_incubadora: false,
        outros: "",
        autor: "",
        cargo: "",
        empresa: "",
        resumo: "",
        file: "",
        id_autor: "",
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        editorState: EditorState.createEmpty(),
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_desafio } = this.props.match.params
        this.setState({ id_desafio })
        this.setState({ id_autor: this.props.usuario.id })
        if (this.props.usuario) this.listarStartups(this.props.usuario.id)
        if (this.props.usuario) this.listarIncubadoras(this.props.usuario.id)

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }
    listarStartups(id_usuario) {
        this.props.getMinhasStartups(id_usuario);
        const { startups } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startups === "undefined") return null
    }

    listarIncubadoras(id_usuario) {
        this.props.getMinhasIncubadoras(id_usuario);
        const { incubadoras } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadoras === "undefined") return null
    }


    cadSolucao() {
        const { id_desafio, titulo, editorState, id_startup, id_incubadora, outros, id_autor, file, resumo, autor, idioma } = this.state;
        if (!this.validate()) return;
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        this.setState({ carregando: true })
        const formData = new FormData();

        formData.append('id_desafio', id_desafio)
        formData.append('titulo', titulo)
        formData.append('descricao', descricao)
        if (autor === "Startup") {
            formData.append('id_startup', id_startup)
        } else if (autor === "Incubadora") {
            formData.append('id_incubadora', id_incubadora)
        } else if (autor === "Outros") {
            formData.append('outros', outros)
        }
        formData.append('id_autor', id_autor)
        formData.append('resumo', resumo)
        for (let f in file) {
            formData.append('file', file[f]);
        }
        this.props.postSolucao(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                Swal.fire({
                    icon: 'success',
                    title: idioma.cadastrarSolucao.info_sucesso,
                    text: idioma.cadastrarSolucao.info
                })
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { titulo, descricao, responsavel, empresa, cargo } = this.state;
        // if (!titulo) return this.setState({ erro: { message: "Preencha o titulo" } });
        // if (!descricao) return this.setState({ erro: { message: "Preencha uma descrição" } });
        // if (!responsavel) return this.setState({ erro: { message: "Preencha um responsável" } });
        // if (!cargo) return this.setState({ erro: { message: "Preencha uma cargo" } });
        // if (!empresa) return this.setState({ erro: { message: "Preencha uma empresa" } });
        return true;
    }

    handleAutor(radio) {
        this.setState({ autor: radio })
    }

    render() {
        const { id_desafio, idioma, titulo, descricao, editorState, file, resumo, id_incubadora, id_startup, outros, autor, empresa, cargo, id_autor, erro, success, carregando, iconSalvar, formSuccess } = this.state;

        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var startups = [];
        if (this.props.startups) startups = this.props.startups.startups;
        var incubadoras = [];
        if (this.props.incubadoras) incubadoras = this.props.incubadoras.incubadoras;
        ////console.log(incubadoras)
        if (formSuccess) return <Redirect to={{
            pathname: "/hub/" + id_desafio + "/ver",
            state: { msg: idioma.cadastrarSolucao.info_sucesso2 }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarSolucao.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="titulo">{idioma.cadastrarSolucao.titulo}</Label>
                                            <Input type="text" value={titulo} autoComplete="titulo" onChange={(ev) => this.onChnageInput("titulo", ev)} name="titulo" id="titulo" placeholder={idioma.cadastrarSolucao.titulo3} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="resumo">{idioma.cadastrarSolucao.resumo}</Label>
                                            <Input type="textarea" value={resumo} autoComplete="resumo" onChange={(ev) => this.onChnageInput("resumo", ev)} name="resumo" id="resumo" placeholder={idioma.cadastrarSolucao.resumo2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descricao">{idioma.cadastrarSolucao.descricao}</Label>
                                            <Card>
                                                <div>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />
                                                </div>
                                            </Card>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Label>{idioma.cadastrarSolucao.autor}</Label>
                                    </Col>
                                </Row>
                                <Row className="mb-2">

                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio1" onChange={() => this.handleAutor("Startup")} />{' '}
                                            {idioma.cadastrarSolucao.negocio}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio1" onChange={() => this.handleAutor("Incubadora")} />{' '}
                                            {idioma.cadastrarSolucao.ambiente}
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="radio1" onChange={() => this.handleAutor("Outros")} />{' '}
                                            {idioma.cadastrarSolucao.outros}
                                        </Label>
                                    </FormGroup>
                                </Row>
                                <Row form>
                                    {autor == "Startup" ?
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="id_startup">{idioma.cadastrarSolucao.autor}</Label>
                                                <Input type="select" onChange={(ev) => this.onChnageInput("id_startup", ev)} id="id_startup" name="id_startup">
                                                    <option>{idioma.cadastrarSolucao.autor2}</option>
                                                    {startups.map(startup => (
                                                        <option value={startup.id}>
                                                            {startup.nome}
                                                        </option>))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        : ""}
                                </Row>
                                <Row form>
                                    {autor == "Incubadora" ?
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="id_incubadora">{idioma.cadastrarSolucao.autor}</Label>
                                                <Input type="select" onChange={(ev) => this.onChnageInput("id_incubadora", ev)} id="id_incubadora" name="id_incubadora">
                                                    <option>{idioma.cadastrarSolucao.autor2}</option>
                                                    {incubadoras.map(incubadora => (
                                                        <option value={incubadora.id}>
                                                            {incubadora.nome}
                                                        </option>))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        : ""}
                                </Row>
                                <Row form>
                                    {autor == "Outros" ?
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="outros">{idioma.cadastrarSolucao.autor}</Label>
                                                <Input type="text" value={outros} autoComplete="outros" onChange={(ev) => this.onChnageInput("outros", ev)} name="outros" id="outros" placeholder={idioma.cadastrarSolucao.autor3} />
                                            </FormGroup>
                                        </Col>
                                        : ""}
                                </Row>
                                <FormGroup>
                                    <Label for="file">{idioma.cadastrarSolucao.docs}</Label>
                                    <div className="col-md-12">
                                        <Alert severity="warning">{idioma.cadastrarSolucao.docs_info}</Alert>
                                    </div>
                                    <Input type="file" id="file" name="file" multiple className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                </FormGroup>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="hidden" value={id_autor} id="id_autor" name="id_autor" />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Link to="#" onClick={() => this.cadSolucao()}> <Botao loading={carregando} texto={idioma.cadastrarDesafio.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarios: state.auth.usuarios,
    startups: state.hub.minhasStartups,
    incubadoras: state.hub.minhasIncubadoras
})

export default connect(mapStateToProps, actions)(CadastrarDesafio);