import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import { termosUso, termsUse } from '../../config';


import { portugues, ingles } from '../../utils/paginaperfil_bilingue';

class Dashboard extends Component {
    state = {
        idioma: portugues
    }

    componentDidMount() {
        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
        } else {
            this.setState({ idioma: portugues })
        }
    }

    render() {
        const { usuario } = this.props;
        const { idioma } = this.state;

        return (
            <>

                <div className="list-group-item ">
                    <Link to={"/" + usuario?.personalizacao?.titulo + "/dashboard"}><Botao color={this.props.usuario?.personalizacao.cor_terciaria} texto={idioma.bt_voltar} icone="arrow-left" posicao="E" classes="mb-2 mt-2" /></Link>
                    <div className="col-md-12 mb-3">
                        <div className="card erpcard">
                            <div className="card-header card-header-large bg-header d-flex align-items-center" style={{ backgroundColor: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                <div className="flex">
                                    <h5 className="margin-zero titulo-header-card">{idioma.termos}</h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <iframe class="embed-responsive-item" src={usuario?.idioma?.id === 1 ? termosUso : termsUse} allowfullscreen height="720px" width="100%" title={idioma.termos}></iframe>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
})
export default connect(mapStateToProps, actions)(Dashboard);