import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/conteudos';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import SemDados from '../../componentes/Mensagens/semDados';
import { Spinner, CardBody, Card, Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import Carregando from '../../componentes/BotaoIcon';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TextTruncate from 'react-text-truncate';
import { apiImagem } from '../../config/';
import { portugues, ingles } from '../../utils/paginaconteudo_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';



class Conteudos extends Component {
    state = {
        id_conteudo: "",
        nome: "",
        procura: "",
        currentPage: 1,
        size: 3,
        id_tipo: 0,
        conteudosAtv: false,
        noticiasAtv: false,
        insightsAtv: false,
        todosAtv: true,
        msg: "",
        erro: "",
        success: "",
        filtro: "",
        ev: "",
        pesquisandoContent: false,
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { user, history, usuario } = this.props;
        if (user) this.receberNomePersonalizacao();
        this.listar(0);
        this.props.getTipoConteudo();
        sessionStorage.setItem('url-perso', '')

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        if (!this.props.conteudos && nextProps.conteudos) this.listar(0);
        if (this.props.user && !nextProps.user) this.receberNomePersonalizacao();
        this.receberDadosAPI();
    }

    receberNomePersonalizacao() {
        const { history, user } = this.props;
        if (localStorage.getItem('urlChat')) {
            history.push(`/${user?.personalizacao?.titulo}/chat/${localStorage.getItem('urlChat')}`)
        } else if (localStorage.getItem('urlStartup')) {
            history.push(`/${user?.personalizacao?.titulo}/startups/${localStorage.getItem('urlStartup')}/acompanhamento/${localStorage.getItem('urlMetodologia')}/processos/${localStorage.getItem('urlProcesso')}/etapa`)
        } else {
            history.push(`/${user?.personalizacao.titulo}/conteudos`);
        }
    }



    componentWillUnmount() {
        this.props.limparConteudos();
    }

    receberDadosAPI() {
        if (typeof this.props.conteudos != "undefined" && typeof this.props.usuario !== 'undefined' && this.props.conteudos != null && !this.state.dadosApi && this.props.conteudos.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });

            if (this.props.usuario?.idioma?.id === 3) {
                this.setState({ idioma: ingles })
                this.setState({ msg_backend: english })
            } else {
                this.setState({ idioma: portugues })
                this.setState({ msg_backend: portuguese })
            }
        }
    }

    changePageAtual(currentPage, tipo) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listar(tipo);
        })
    }

    clicarMenu(info) {
        if (info == "conteudos") {
            this.setState({ conteudosAtv: true })
            this.setState({ todosAtv: false })
            this.setState({ noticiasAtv: false })
            this.setState({ insightsAtv: false })
            this.setState({ changePageAtual: 1 })
            this.listar(2);

        }
        if (info == "todos") {
            this.setState({ todosAtv: true })
            this.setState({ conteudosAtv: false })
            this.setState({ noticiasAtv: false })
            this.setState({ insightsAtv: false })
            this.setState({ changePageAtual: 1 })

            this.listar(0);

        }
        if (info == "noticias") {
            this.setState({ conteudosAtv: false })
            this.setState({ todosAtv: false })
            this.setState({ noticiasAtv: true })
            this.setState({ insightsAtv: false })
            this.setState({ changePageAtual: 1 })

            this.listar(1);

        }
        if (info == "insights") {
            this.setState({ conteudosAtv: false })
            this.setState({ noticiasAtv: false })
            this.setState({ todosAtv: false })
            this.setState({ insightsAtv: true })
            this.setState({ changePageAtual: 1 })

            this.listar(3);

        }
    }


    apagarConteudo(id_conteudo) {
        this.setState({ carregando: true })
        this.props.deletarConteudo(id_conteudo, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }

    handleSearchContent(procura, id_tipo) {
        this.setState({ procura: procura })
        this.setState({ pesquisandoContent: true })
        const { currentPage, size } = this.state;
        this.props.getSearchContent(procura, id_tipo, currentPage, size)
    }
    eraseSearchContent(campo) {
        this.setState({ pesquisandoContent: false })
        this.setState({ [campo]: "" })
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    chamarAlerta(id_conteudo) {
        Swal.fire({
            title: 'Você tem certeza?',
            text: "Não será possível restaurar esse conteúdo!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, excluir este conteúdo!'
        }).then((result) => {
            if (result.value) {
                this.apagarConteudo(id_conteudo);
            }
        })
    }
    sucessoAlerta() {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: 'Conteúdo excluído com sucesso!'
        })
    }

    listar(id_tipo) {
        const { currentPage, size } = this.state;
        this.props.getConteudos(id_tipo, currentPage, size)
        const { conteudos } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (conteudos === "undefined") return null
    }

    editSearchTerm = (ev) => {
        this.setState({ filtro: ev.target.value })
    }


    handleEnter(ev, tipo) {
        const { procura } = this.state;
        if (ev.key === 'Enter') {
            this.handleSearchContent(procura, tipo)
        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = isActive ? this.props.usuario?.personalizacao.cor_texto_menu_hover : this.props.usuario?.personalizacao?.cor_secundaria
    }

    render() {
        const { msg, carregando, erro, success, procura, todosAtv, conteudosAtv, noticiasAtv, pesquisandoContent, insightsAtv, dadosApi, idioma } = this.state;
        const { usuario } = this.props;
        var conteudos = [];

        if (pesquisandoContent == false) {
            if (this.props.conteudos) conteudos = this.props.conteudos.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.conteudos != "undefined" && this.props.conteudos != null && this.props.conteudos.currentPage !== "" && this.props.conteudos.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.conteudos != "undefined" && this.props.conteudos !== null && this.props.conteudos.nextPage <= this.props.conteudos.totalPages && this.props.conteudos.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        } else {
            if (this.props.search) conteudos = this.props.search.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.search != "undefined" && this.props.search != null && this.props.search.currentPage !== "" && this.props.search.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.search != "undefined" && this.props.search !== null && this.props.search.nextPage <= this.props.search.totalPages && this.props.search.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        }

        ////////console.log(this.props.search)
        ////////console.log(this.props.conteudos)

        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var tipos = [];
        if (this.props.tipos) tipos = this.props.tipos.dados;
        return (
            <>
                {!this.props.usuario &&
                    <div className='d-flex justify-content-center align-items-center' style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white', zIndex: 999 }}>
                        <Spinner />
                    </div>
                }
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div style={{ backgroundColor: todosAtv && usuario?.personalizacao?.cor_secundaria, color: todosAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, todosAtv)} className={todosAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("todos")}>
                                                {idioma.todos}
                                            </div>
                                            <div style={{ backgroundColor: conteudosAtv && usuario?.personalizacao?.cor_secundaria, color: conteudosAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, conteudosAtv)} className={conteudosAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("conteudos")}>
                                                {idioma.conteudos}
                                            </div>
                                            <div style={{ backgroundColor: noticiasAtv && usuario?.personalizacao?.cor_secundaria, color: noticiasAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, noticiasAtv)} className={noticiasAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("noticias")}>
                                                {idioma.noticias}
                                            </div>
                                            <div style={{ backgroundColor: insightsAtv && usuario?.personalizacao?.cor_secundaria, color: insightsAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, insightsAtv)} className={insightsAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("insights")}>
                                                {idioma.insights}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            {credenciais.map(credencial => ((credencial.id_componente == 29) ?
                                // <Link to={"/" + usuario?.personalizacao?.titulo + "/conteudos/cadastrar"}>
                                //     <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: usuario?.personalizacao?.cor_secundaria }}>
                                //         {idioma.bt_cadastrar}
                                //     </button>
                                // </Link>
                                <Link to={"/" + usuario?.personalizacao?.titulo + "/conteudos/cadastrar"}>
                                    <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                </Link>
                                : ""))}
                            {dadosApi ?
                                <div>

                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12 pt-4">
                                                <div>
                                                    <div className="row py-3">
                                                        <div className="col-md-8">
                                                            <Input type="text" value={procura} onKeyPress={(ev) =>
                                                                conteudosAtv == true ?
                                                                    this.handleEnter(ev, 2)
                                                                    : noticiasAtv == true ?
                                                                        this.handleEnter(ev, 1)
                                                                        : insightsAtv == true ?
                                                                            this.handleEnter(ev, 3)
                                                                            : todosAtv == true ?
                                                                                this.handleEnter(ev, 0)
                                                                                : ""
                                                            } autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.search} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div>
                                                                <span className="pr-3" onClick={() =>
                                                                    conteudosAtv == true ?
                                                                        this.handleSearchContent(procura, 2)
                                                                        : noticiasAtv == true ?
                                                                            this.handleSearchContent(procura, 1)
                                                                            : insightsAtv == true ?
                                                                                this.handleSearchContent(procura, 3)
                                                                                : todosAtv == true ?
                                                                                    this.handleSearchContent(procura, 0)
                                                                                    : ""
                                                                } id="pesquisa" name="pesquisa"><Botao loading={carregando} color={usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_search} icone="search" classes="botao-pesquisa-responsivo" posicao="E" /></span>
                                                                <span value={procura} onClick={() => this.eraseSearchContent("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" classes="botao-pesquisa-responsivo" texto="Cancelar" icone="times" posicao="E" /></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {conteudos.length != 0 ?
                                                        conteudos.map(conteudo => (
                                                            <div className="pb-3">
                                                                <Card className="container-fluid class-card">
                                                                    <div className="card-body-news">
                                                                        <div className="tab-content mo-mt-2 media row" id="v-pills-tabContent">
                                                                            <div className="col-md-2 zero-padding">
                                                                                <img className="card-imagem-news img-news-list" src={conteudo.fileName != null ? apiImagem + conteudo.fileName : apiImagem + "fundo_startup.jpg"} alt="Capa do conteúdo" />
                                                                            </div>
                                                                            <div className="col">
                                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/conteudo/" + conteudo.id}>
                                                                                    <div className="media-body ">
                                                                                        <div className="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                                                            <p className="text-news  mb-0 pl-4 pt-2">{conteudo.titulo}</p>

                                                                                            <div className="text-dark  mb-0 pl-4">
                                                                                                <TextTruncate
                                                                                                    line={3}
                                                                                                    element="span"
                                                                                                    truncateText=" [Ler mais]"
                                                                                                    text={conteudo.previa ? conteudo.previa : "Acessar conteúdo"}
                                                                                                />
                                                                                            </div>
                                                                                            <p className="text-muted pl-4">{format(new Date(conteudo.dataCriacao), 'dd/MM/yyyy', { locale: pt })}</p>
                                                                                        </div>
                                                                                    </div>

                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div>

                                                        ))
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-2 pt-4">
                                            <Card className="news-card">
                                                <CardBody>
                                                    <div className="row">
                                                        <div className="nav-news flex-column nav-pills text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                            <Skeleton count={1} width={80} />
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </div>
                                        <div className="col-md-10 pt-4">
                                            <Card className="class-card">
                                                <div className="card-body-news">
                                                    <div className="tab-content mo-mt-2 media" id="v-pills-tabContent">
                                                        <Skeleton rectangle={true} height={60} width={120} className="mr-2" />
                                                        <div className="media-body">
                                                            <div className="tab-pane fade active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <div>
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <div>
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="row pt-3">
                                <div className="col-md-12">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination justify-content-center">
                                            <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                <span className="page-link cursor-pointer" onClick={() =>
                                                    todosAtv ? this.changePageAtual(1, 0)
                                                        : conteudosAtv ? this.changePageAtual(1, 2)
                                                            : noticiasAtv ? this.changePageAtual(1, 1)
                                                                : insightsAtv ? this.changePageAtual(1, 3)
                                                                    :
                                                                    ""
                                                } >{idioma.page1}</span>
                                            </li>

                                            {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() =>
                                                todosAtv ? this.changePageAtual(this.props.conteudos.prevPage ? this.props.conteudos.prevPage : 1, 0)
                                                    : conteudosAtv ? this.changePageAtual(this.props.conteudos.prevPage ? this.props.conteudos.prevPage : 1, 2)
                                                        : noticiasAtv ? this.changePageAtual(this.props.conteudos.prevPage ? this.props.conteudos.prevPage : 1, 1)
                                                            : insightsAtv ? this.changePageAtual(this.props.conteudos.prevPage ? this.props.conteudos.prevPage : 1, 3)
                                                                :
                                                                ""
                                            }>{this.props.conteudos.prevPage ? this.props.conteudos.prevPage : ""}</span></li> : ""}

                                            <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.conteudos ? this.props.conteudos.currentPage : "1"}</span></li>

                                            {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() =>
                                                todosAtv ? this.changePageAtual(this.props.conteudos.nextPage ? this.props.conteudos.nextPage : 1, 0)
                                                    : conteudosAtv ? this.changePageAtual(this.props.conteudos.nextPage ? this.props.conteudos.nextPage : 1, 2)
                                                        : noticiasAtv ? this.changePageAtual(this.props.conteudos.nextPage ? this.props.conteudos.nextPage : 1, 1)
                                                            : insightsAtv ? this.changePageAtual(this.props.conteudos.nextPage ? this.props.conteudos.nextPage : 1, 3)
                                                                :
                                                                ""
                                            }>{this.props.conteudos.nextPage ? this.props.conteudos.nextPage : ""}</span></li> : ""}

                                            <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                <span className="page-link cursor-pointer" onClick={() =>
                                                    todosAtv ? this.changePageAtual(this.props.conteudos ? this.props.conteudos.totalPages : 0, 0)
                                                        : conteudosAtv ? this.changePageAtual(this.props.conteudos ? this.props.conteudos.totalPages : 0, 2)
                                                            : noticiasAtv ? this.changePageAtual(this.props.conteudos ? this.props.conteudos.totalPages : 0, 1)
                                                                : insightsAtv ? this.changePageAtual(this.props.conteudos ? this.props.conteudos.totalPages : 0, 3)
                                                                    :
                                                                    ""
                                                }>{idioma.page2}</span>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    conteudos: state.conteudos.conteudos,
    tipos: state.conteudos.tipoConteudo,
    search: state.conteudos.searchConteudo,
    usuario: state.auth.usuario,
    user: state.auth.usuario
})
export default connect(mapStatetoProps, actions)(Conteudos);