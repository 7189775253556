import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, fab);

const Carregando = (props) => {
    // if (props.loading) return (
    //     <Button color={props.cor} size={props.tamanho} className={`${props.classes}`} disabled >
    //         Carregando..
    //         <Spinner className="ml-1" size="sm" color="light" />
    //     </Button>
    // )

    return (
        <Button color={props.cor} style={{ backgroundColor: props.color }} size={props.tamanho} className={`${props.classes}`} >
            <FontAwesomeIcon icon={props.icone} />
        </Button>
    )
}
export default Carregando;