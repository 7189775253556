import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/perfis';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Switch from 'react-switch';
import Swal from 'sweetalert2';

// import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import { ingles, portugues } from '../../utils/paginaperfilacesso_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class EditarCredenciais extends Component {
  state = {
    id_perfil: '',
    id_credenciais: '',
    id_componente: '',
    nome: '',
    descricao: '',
    onoff: false,
    erro: '',
    success: '',
    carregando: false,
    dadosAPI: false,
    formSuccess: false,
    idioma: portugues,
    msg_backend: portuguese,
  };
  componentDidMount() {
    const { id_perfil } = this.props.match.params;
    this.setState({ id_perfil });
    this.listarPerfil(id_perfil);
    this.listarCredenciais(id_perfil);
    this.listarComponentes();

    const { usuario } = this.props;
    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }
  async componentDidUpdate(nextProps) {
    const { id_perfil } = this.props.match.params;
    if (!this.props.acesso && nextProps.acesso)
      this.props.getVerPerfil(id_perfil);
    if (!this.props.credenciais && nextProps.credenciais)
      this.listarCredenciais(id_perfil);
    await this.receberDadosAPI();
  }

  componentWillUnmount() {
    this.props.limparPerfil();
  }

  receberDadosAPI() {
    const { id_perfil, id_componente, id_credenciais } =
      this.props.match.params;
    if (
      typeof this.props.acesso !== 'undefined' &&
      this.props.acesso !== null &&
      this.props.acesso.id == id_perfil &&
      !this.state.dadosAPI
    ) {
      this.setState({ id_perfil: this.props.acesso.id });
      this.setState({ nome: this.props.acesso.nome });
      this.setState({ descricao: this.props.acesso.descricao });
      this.setState({ dadosAPI: true });
    }
    if (
      typeof this.props.credenciais !== 'undefined' &&
      this.props.credenciais !== null &&
      this.props.credenciais.id_credenciais == id_credenciais &&
      !this.state.dadosAPI
    ) {
      this.setState({ id_credenciais: this.props.credenciais.id_credenciais });
      this.setState({ dadosAPI: true });
    }
    if (
      typeof this.props.componentes !== 'undefined' &&
      this.props.componentes !== null &&
      this.props.componentes.id_componente == id_componente &&
      !this.state.dadosAPI
    ) {
      this.setState({ id_componente: this.props.componentes.id_componente });
      this.setState({ dadosAPI: true });
    }
  }

  listarPerfil(id_perfil) {
    this.props.getVerPerfil(id_perfil);
    const { perfil } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (perfil === 'undefined') return null;
  }

  listarCredenciais(id_perfil) {
    this.props.getCredenciais(id_perfil);
    const { credenciais } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (credenciais === 'undefined') return null;
  }

  listarComponentes() {
    this.props.getComponentes();
    const { componentes } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (componentes === 'undefined') return null;
  }

  onChangeInput = (field, ev) => {
    this.setState({ [field]: ev.target.value });
  };

  salvar(id_perfil, id_componente) {
    const listarComponentes = this.props.componentes;
    const listaCredenciais = this.props.credenciaisPerfil.dados;
    var alterado = false;

    this.setState({ id_componente });
    // this.setState({ id_perfil });
    this.setState({ carregando: true });

    for (var k in listaCredenciais) {
      if (id_componente == listaCredenciais[k].id_componente) {
        alterado = true;
        this.props.deletarCredencial(id_perfil, id_componente, (msg) => {
          if (msg.erro.error) {
            this.setState({
              erro: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ success: '' });
            this.setState({ carregando: false });
          } else {
            this.setState({
              success: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ erro: '' });
            this.setState({ carregando: false });
            this.checked(listarComponentes, id_componente);
            this.listarCredenciais(id_perfil);
          }
        });
      }
    }
    if (alterado == false) {
      this.props.postCredencial({ id_perfil, id_componente }, (msg) => {
        if (msg.erro.error) {
          this.setState({
            erro: { message: this.state.msg_backend[msg.erro.code] },
          });
          this.setState({ success: '' });
          this.setState({ carregando: false });
        } else {
          this.setState({
            success: { message: this.state.msg_backend[msg.erro.code] },
          });
          this.setState({ erro: '' });
          this.setState({ carregando: false });
          this.checked(listarComponentes, id_componente);
          this.listarCredenciais(id_perfil);
        }
      });
    }
  }

  receberDadosForm() {
    this.setState({ id_perfil: document.querySelector('#id_perfil').value });
    this.setState({ nome: document.querySelector('#nome').value });
    this.setState({ descricao: document.querySelector('#descricao').value });
  }
  validate() {
    const { nome, descricao, erro, idioma } = this.state;
    if (!nome)
      return this.setState({
        erro: { message: idioma.cadastrarPerfil.erro_nome },
      });
    if (!descricao)
      return this.setState({
        erro: { message: idioma.cadastrarPerfil.erro_descricao },
      });
    return true;
  }

  handleCredencial(onoff) {
    this.setState({ onoff: { onoff } });
  }

  checked(credenciais, id_componente) {
    let checked = false;

    for (const cont in credenciais) {
      if (credenciais[cont].id_componente == id_componente) {
        checked = true;
      }
    }
    return checked;
  }

  render() {
    const {
      id_perfil,
      onoff,
      nome,
      descricao,
      id_credenciais,
      id_componente,
      carregando,
      dadosAPI,
      erro,
      success,
      formSuccess,
      idioma,
    } = this.state;
    var acesso = [];
    var credenciais = [];
    var componentes = [];

    if (this.props.acesso) acesso = this.props.acesso;
    if (this.props.credenciaisPerfil)
      credenciais = this.props.credenciaisPerfil.dados;
    if (this.props.componentes) componentes = this.props.componentes.dados;

    // console.log(componentes)
    if (formSuccess)
      return (
        <Redirect
          to={{
            pathname: '/perfis' + id_perfil,
            state: { msg: idioma.editarCredenciais.msg_sucesso },
          }}
        ></Redirect>
      );
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario?.personalizacao?.cor_principal,
          }}
        >
          <Titulo titulo={idioma.editarCredenciais.titulo} />
        </div>
        <div className="list-group-item bloco-card">
          <div>
            {/* <img className="rounded-circle mt-2" src={imagem} width="80" height="80" alt={nome}></img> */}
          </div>
          <Link onClick={() => this.props.history.goBack()} className="pr-2">
            {' '}
            <Botao
              texto={idioma.bt_voltar}
              classes="botao-responsivo"
              icone="arrow-left"
              posicao="E"
              color={this.props.usuario?.personalizacao.cor_terciaria}
            />{' '}
          </Link>

          <Link onClick={() => this.props.history.goBack()}>
            <button className="ml-2 btn btn-outline-danger btn-sm">
              {idioma.editarCredenciais.cancelar}
            </button>
          </Link>
          <AlertaErro erros={erro} />
          <AlertaSuccess erros={success} />
          <Form>
            <h4 className="mt-3">
              {idioma.editarCredenciais.perfil}: {acesso.nome}
            </h4>
            <Input
              type="hidden"
              value={id_perfil}
              id="id_perfil"
              name="id_perfil"
            />
            <FormGroup>
              <Label for="credencial">
                <strong>{idioma.editarCredenciais.componentes}</strong>
              </Label>
              {componentes.map((componente) => (
                <div key={componente.id}>
                  <Switch
                    checked={this.checked(credenciais, componente.id)}
                    onChange={() => this.salvar(id_perfil, componente.id)}
                    size="sm"
                    className="mr-3"
                  />
                  <label>{componente.nome}</label>
                </div>
              ))}
            </FormGroup>
          </Form>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  usuarioDetalhes: state.usuarios.usuarioDetalhes,
  acesso: state.perfis.acesso,
  credenciaisPerfil: state.perfis.credenciais,
  componentes: state.perfis.componentes,
});
export default connect(mapStateToProps, actions)(EditarCredenciais);
