import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form, Card } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';

import Toast from '../../../componentes/Alertas/Toast';
import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';

class EditarEstagio extends Component {
  state = {
    nome_estagio: '',
    formSuccess: false,
    erro: "",
    success: "",
    descricao: "",
    carregando: false,
    // editorState: EditorState.createEmpty(),
    msg_backend: portuguese,
    idioma: portugues,
  }
  componentDidMount() {

    const { usuario } = this.props;
    this.listarEstagio();

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }

  componentDidUpdate(nextProps) {
    if (!this.props.estagio && nextProps.estagio) this.listarEstagio();
    this.receberDadosAPI();
  }

  componentWillUnmount() {
    const { limparEstagio } = this.props;

    limparEstagio();
  }


  receberDadosAPI() {
    if (typeof this.props.estagio != "undefined" && this.props.estagio != null && !this.state.dadosApi) {
      this.setState({ nome_estagio: this.props.estagio.nome });
      this.setState({ descricao: this.props.estagio.descricao });
      this.setState({ dadosApi: true });
    }
  }

  listarEstagio() {
    const { id_estagio } = this.props.match.params;
    this.props.getVerEstagio(id_estagio)
    const { estagio } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state })
      this.props.location.state = "";
    }
    if (estagio === "undefined") return null
  }


  validate() {
    const { nome_estagio, idioma } = this.state;
    if (!nome_estagio) return this.setState({ erro: { message: idioma.cadastrarProcesso.erro } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }


  handleEditarEstagio() {
    this.setState({ carregando: true })
    const { nome_estagio, descricao } = this.state;
    const { id_estagio } = this.props.match.params;
    this.props.putEditarEstagio({ id: id_estagio, nome: nome_estagio, descricao }, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: false });
      } else {
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        Toast({ icon: 'success', title: 'Sucesso!', text: this.state.msg_backend[msg.erro.code] })
      }
    }
    )
  }

  render() {
    const { carregando, erro, formSuccess, idioma, nome_estagio, descricao } = this.state;
    const { id_metodologia, id_processo, id_etapa } = this.props.match.params;
    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/estagios/listar`,
      state: idioma.editarEstagio.msg_sucesso
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.editarEstagio.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome_estagio">{idioma.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome_estagio', e)} value={nome_estagio} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarEstagio.nome_estagio} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="nome">{idioma.cadastrarEstagio.input_descricao}</Label>
                    <Input type="textarea" onChange={(e) => this.onChangeInput('descricao', e)} value={descricao} autoComplete="descricao" required name="descricao" id="descricao" placeholder={idioma.cadastrarEstagio.input_descricao} />
                    {/* <Card className="card-editor">
                      <div>
                        <Editor
                          editorState={editorState}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={this.onEditorStateChange}
                        />
                      </div>
                    </Card> */}
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleEditarEstagio()}> <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  estagio: state.startups.estagio,
})
export default connect(mapStatetoProps, actions)(EditarEstagio);