import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, UncontrolledCollapse, Card, CardBody, Table, CardHeader } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';

import Swal from 'sweetalert2';
import BotaoIcon from '../../componentes/BotaoIcon';
import Botao from '../../componentes/Botoes';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Progress } from 'reactstrap';
import SemDados from '../../componentes/Mensagens/semDados';
import parse from 'html-react-parser'
import { Collapse } from "react-collapse";
import { apiAula } from '../../config/';
import Titulo from '../../componentes/TituloPaginas';
import CarregandoTabela from '../../componentes/Skeleton/CarregandoTabela';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'

import CarregandoPerfil from '../../componentes/Skeleton/CarregandoPerfil';
import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';



class CadastrarMetodologia extends Component {
    state = {
        id_usuario: "",
        id_trilha: "",
        id_modulo: "",
        id_materia: "",
        id_aula_selecionada: 0,
        matriculado: false,
        id_materia_selecionada: 0,
        tipo_aula: "video",
        todosAtv: true,
        selecionadoAtv: false,
        progresso: "",
        isOpened: false,
        materiaAberta: 0,
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese,
        nome_materia: '',
        show_bt_proximo: true,
        show_bt_anterior: true
    }

    componentDidMount() {
        const { id_trilha, id_modulo, id_materia } = this.props.match.params;
        this.setState({ id_trilha: id_trilha })
        this.setState({ id_modulo: id_modulo })
        this.setState({ id_materia: id_materia })
        if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }
        if (this.props.usuario) this.listar(id_trilha, id_modulo, this.props.usuario.id)
        if (this.props.usuario) this.listarModulos(id_trilha, this.props.usuario.id)
        this.listarParticipantes(id_trilha);
        this.listarTrilha(id_trilha);
        // if (this.props.alunos) this.checarMatricula(this.props.alunos.usuarios, this.props.usuario.id)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id_trilha, id_modulo } = this.props.match.params;
        if (!this.props.materias && nextProps.materias) this.listar(id_trilha, id_modulo, this.props.usuario.id);
        if (!this.props.modulos && nextProps.modulos) this.listarModulos(id_trilha, this.props.usuario.id);
        if (!this.props.alunos && nextProps.alunos) {
            this.listarParticipantes(id_trilha);
            // this.checarMatricula(this.props.alunos.usuarios, this.props.usuario.id)

        }
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha);
            // this.checarMatricula(this.props.alunos.usuarios, this.props.usuario.id)

        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparMaterias()
    }

    receberDadosAPI() {
        if (typeof this.props.materias != "undefined" && this.props.materias != null
            && typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    checarMatricula(alunos, id_usuario) {
        for (const a in alunos) {
            if (alunos[a].id == id_usuario) {
                this.setState({ matriculado: true })
                ////////console.log(alunos[a].id, id_usuario)
            }
        }
    }


    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    apagarAula(id_conteudo) {
        this.setState({ carregando: true })
        this.props.deletarAula(id_conteudo, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }

    chamarAlerta(id_conteudo) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_title,
            text: idioma.aulas.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.aulas.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.apagarConteudo(id_conteudo);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.aulas.modal_sucesso
        })
    }

    listar(id_trilha, id_modulo, id_usuario) {
        ////////////console.log(id_trilha, id_modulo, id_usuario)
        this.props.getMaterias(id_trilha, id_modulo, id_usuario)
        const { materias } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha, id_modulo)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (materias === "undefined") return null
    }

    listarParticipantes(id_trilha) {
        this.props.getTodosAlunos(id_trilha)
        const { alunos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (alunos === "undefined") return null
    }


    handleSelectedClass(id_materia, id_aula) {
        this.setState({ id_aula_selecionada: id_aula })
        this.setState({ id_materia_selecionada: id_materia })
        this.setState({ selecionadoAtv: true })
        this.setState({ todosAtv: false })
        // this.setState({ tipo_aula });
    }

    handleProximaAula() {
        const { id_aula_selecionada, id_materia_selecionada } = this.state;
        const materias = this.props.materias.dados.map((materia) => materia.materia.id);
        const index_materia = materias.indexOf(id_materia_selecionada)
        const aulas = this.props.materias.dados[index_materia].aulas.map((aula) => aula.aula.id);
        const index = aulas.findIndex((item) => item === id_aula_selecionada);

        if (index + 2 >= aulas.length) {
            this.setState({ show_bt_proximo: false });
        }
        this.verificarBtAnterior(id_materia_selecionada, index + 1)
        this.verificarBtProximo(id_materia_selecionada, index + 1)
        this.handleSelectedClass(id_materia_selecionada, aulas[index + 1])
    }
    handleAulaAnterior() {
        const { id_aula_selecionada, id_materia_selecionada } = this.state;
        const materias = this.props.materias.dados.map((materia) => materia.materia.id);
        const index_materia = materias.indexOf(id_materia_selecionada)
        const aulas = this.props.materias.dados[index_materia].aulas.map((aula) => aula.aula.id);
        const index = aulas.findIndex((item) => item === id_aula_selecionada);
        if (index <= 0) {
            this.setState({ show_bt_anterior: false });
        }
        this.verificarBtAnterior(id_materia_selecionada, index - 1)
        this.verificarBtProximo(id_materia_selecionada, index - 1)
        this.handleSelectedClass(id_materia_selecionada, aulas[index - 1])
    }

    verificarBtAnterior(id_materia, index) {
        this.setState({ show_bt_anterior: true })
        const materias = this.props.materias.dados.map((materia) => materia.materia.id);
        const index_materia = materias.indexOf(id_materia)
        const aulas = this.props.materias.dados[index_materia].aulas.map((aula) => aula.aula.id);
        if (index <= 0) {
            this.setState({ show_bt_anterior: false })
        }
    }
    verificarBtProximo(id_materia, index) {
        this.setState({ show_bt_proximo: true })
        const materias = this.props.materias.dados.map((materia) => materia.materia.id);
        const index_materia = materias.indexOf(id_materia)
        const aulas = this.props.materias.dados[index_materia].aulas.map((aula) => aula.aula.id);
        if (index + 1 === aulas.length) {
            this.setState({ show_bt_proximo: false })
        }
    }

    handleProgressAula(id, id_usuario, id_trilha, id_modulo, id_materia) {
        var id_aula = id
        var id_curso = id_trilha
        this.setState({ carregando: true })
        this.props.postProgressoAula({ id_aula, id_usuario, id_curso, id_modulo, id_materia }, (msg) => {
            if (msg.erro.error) {
                this.setState({ carregando: false });
            } else {
                this.setState({ carregando: false });
            }
        }
        )
    }

    handleProgressAtividade(id, id_usuario, id_trilha, id_modulo, id_materia) {
        var id_atividade = id
        var id_curso = id_trilha
        this.setState({ carregando: true })
        this.props.postProgressoAtividade({ id_atividade, id_usuario, id_modulo, id_materia, id_curso }, (msg) => {
            if (msg.erro.error) {
                this.setState({ carregando: false });
            } else {
                this.setState({ carregando: false });

            }
        })
        this.props.history.push("/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id + "/resultado/")
    }

    handleMateria(id_trilha, id_modulo, id_materia) {
        this.setState({ carregando: true })

        this.props.history.push("/trilhas/" + id_trilha + "/modulos/" + id_modulo + "/materias/" + id_materia + "/editar")


    }

    handleisOpened(id_materia, nome) {
        // if (isOpened == true) {
        //     this.setState({ isOpened: false })
        //     //////console.log(isOpened)
        // } else {
        //     this.setState({ isOpened: true })
        //     //////console.log(isOpened)
        // }
        if (this.state.materiaAberta == id_materia) {
            this.setState({ materiaAberta: 0 })
        } else {
            this.setState({ materiaAberta: id_materia })
            this.setState({ nome_materia: nome })
        }

    }

    clicarMenu(info) {
        if (info == "todos") {
            this.setState({ todosAtv: true })
            this.setState({ selecionadoAtv: false })
        }
        if (info == "selecionado") {
            this.setState({ todosAtv: false })
            this.setState({ selecionadoAtv: true })

        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive, el) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = !isActive && this.props.usuario?.personalizacao?.cor_secundaria
    }

    listarModulos(id_trilha, id_usuario) {
        this.props.getModulos(id_trilha, id_usuario)
        const { modulos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (modulos === "undefined") return null
    }


    render() {
        const { erro, success, idioma, nome_materia, todosAtv, selecionadoAtv, carregando, show_bt_proximo, show_bt_anterior, materiaAberta, isOpened, dadosApi, id_aula_selecionada, id_trilha, id_modulo, id_usuario, id_materia_selecionada } = this.state;
        var materias = [];
        var matriculado = [];

        if (this.props.materias) {
            materias = this.props.materias.dados;
        }
        var matriculado = false;
        if (this.props.modulos) matriculado = this.props.modulos.dados.matriculado;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var trilha = [];
        var listaAutores = [];
        if (this.props.trilha) {
            trilha = this.props.trilha;
            listaAutores = this.props.trilha.autores
        }
        var alunos = [];
        if (this.props.alunos) alunos = this.props.alunos.usuarios;

        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario

        var isAuthor = false;
        isAuthor = (trilha?.id_autor === usuario.id)
        for (const l in trilha?.autores) {
            if (!isAuthor) isAuthor = (trilha?.autores[l].id_autor === usuario.id)
        }
        var isAdmin = (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9);
        var path = this.props.match.path
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.aulas.titulo} />
                </div>

                <div className="container-fluid bloco-topo">
                    {dadosApi ?
                        <>
                            <BlocoTrilha showMenu={true} matriculado={matriculado} dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                            {trilha?.visibilidade === 'Público' || isAuthor || isAdmin || matriculado ?
                                <div className="col-md-12">
                                    <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div style={{ backgroundColor: todosAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_quaternaria, color: todosAtv ? usuario?.personalizacao?.cor_texto_menu_hover : "black", borderRadius: '5px' }} className={todosAtv ? "col-md-6 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-6 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("todos")}>
                                                    <FontAwesomeIcon icon="file-alt" size="1x" className="mr-3 icon-mouse-none" />
                                                    {idioma.aulas.materias}
                                                </div>
                                                <div style={{ backgroundColor: selecionadoAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_quaternaria, color: selecionadoAtv ? usuario?.personalizacao?.cor_texto_menu_hover : "black", borderRadius: '5px' }} className={selecionadoAtv ? "col-md-6 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-6 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("selecionado")}>
                                                    <FontAwesomeIcon icon="tv" size="1x" className="mr-3 icon-mouse-none" />
                                                    {idioma.aulas.conteudo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </>
                        :
                        <CarregandoPerfil />
                    }
                    {todosAtv ?
                        <>
                            {dadosApi ?
                                <div className="col-md-12 col-sm-12">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        {trilha?.visibilidade === 'Público' || isAuthor || isAdmin || matriculado ?
                                            <>
                                                {isAuthor || isAdmin ?
                                                    <Link to={{ pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/materias/cadastrar", state: { id_modulo } }}> <Botao texto={idioma.aulas.materia} classes="botao-responsivo mb-3" icone="plus" posicao="E" cor="success" /> </Link>
                                                    : ""}
                                                {materias.length !== 0 ?
                                                    materias.map(materia =>
                                                        <div className="row cursor-clique">
                                                            <div className="col-md-12 col-sm-12">
                                                                <div className="pb-3">
                                                                    <Card onClick={() => this.handleisOpened(materia.materia.id, materia.materia.nome)} >
                                                                        <div className="card-body-news">
                                                                            <div className="tab-content mo-mt-2 media row" id="v-pills-tabContent">
                                                                                <div className="col">
                                                                                    <CardHeader className="titulo-generico">
                                                                                        <div className="row">
                                                                                            <div className="col-lg-8 py-2">{materia.materia.nome}</div>
                                                                                            <div className="col">
                                                                                                {isAuthor || isAdmin ?
                                                                                                    <>
                                                                                                        <span onClick={() => this.handleMateria(id_trilha, id_modulo, materia.materia.id)} >
                                                                                                            <Botao texto={idioma.aulas.bt_editar} icone="cogs" posicao="E" cor="warning" classes="btn-sm mb-2 mx-1  botao-responsivo" />
                                                                                                        </span>
                                                                                                        <span>
                                                                                                            <Link to={{ pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/aulas/cadastrar", state: { id_modulo, id_materia: id_materia_selecionada } }}>
                                                                                                                <Botao texto={idioma.aulas.aula} icone="plus" posicao="E" cor="success" classes="btn-sm mb-2 mx-1 botao-responsivo" />
                                                                                                            </Link>
                                                                                                        </span>
                                                                                                        <span>
                                                                                                            <Link to={{ pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/atividades/cadastrar", state: { id_modulo, id_materia: id_materia_selecionada } }}>
                                                                                                                <Botao texto={idioma.aulas.atividade} icone="plus" posicao="E" cor="success" classes="btn-sm mb-2 mx-1 botao-responsivo" />
                                                                                                            </Link>
                                                                                                        </span>
                                                                                                    </>
                                                                                                    : ""}
                                                                                            </div>
                                                                                        </div>
                                                                                    </CardHeader>
                                                                                </div>
                                                                                <div className="container-fluid">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                            <Collapse isOpened={materiaAberta == materia.materia.id ? true : false} className="m-3">
                                                                                                <div className="m-3 titulo-generico">{idioma.aulas.aulas}</div>
                                                                                                <Table striped>
                                                                                                    <tbody>
                                                                                                        {materia.aulas ? materia.aulas.map((aula, index) => (
                                                                                                            <tr key={aula.aula.id}>
                                                                                                                {matriculado || isAuthor || isAdmin ?
                                                                                                                    <td className="row-progress cursor-clique nome-aula" onClick={() => {
                                                                                                                        this.verificarBtAnterior(materia.materia.id, index);
                                                                                                                        this.verificarBtProximo(materia.materia.id, index);
                                                                                                                        this.handleSelectedClass(materia.materia.id, aula.aula.id)
                                                                                                                        this.handleProgressAula(aula.aula.id, id_usuario, id_trilha, id_modulo, materia.materia.id)
                                                                                                                    }
                                                                                                                    }>
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-lg-9 ml-3">
                                                                                                                                {aula.progresso == true ?
                                                                                                                                    <FontAwesomeIcon icon="check" size="1x" color="green" className="mr-3" />
                                                                                                                                    :
                                                                                                                                    <FontAwesomeIcon icon="clock" size="1x" color="grey" className="mr-3" />}
                                                                                                                                {aula.aula.nome}
                                                                                                                            </div>
                                                                                                                            <div className="col ml-3">
                                                                                                                                {isAuthor || isAdmin ?
                                                                                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/" + id_modulo + "/materias/" + materia.materia.id + "/aulas/" + aula.aula.id + "/editar"}>
                                                                                                                                        <Botao texto={idioma.aulas.edit_aula} icone="cogs" posicao="E" cor="warning" classes="btn-sm mb-2 botao-responsivo" />
                                                                                                                                    </Link>
                                                                                                                                    : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    :
                                                                                                                    <td className="nome-aula">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-lg-9 ml-3">
                                                                                                                                {aula.aula.nome}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>}
                                                                                                            </tr>
                                                                                                        )) :
                                                                                                            <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.erro_dados} />}
                                                                                                    </tbody>
                                                                                                </Table>
                                                                                                <div className="m-3 titulo-generico">{idioma.aulas.atividades}</div>
                                                                                                <Table striped>
                                                                                                    <tbody>
                                                                                                        {materia.atividades.map(atividade => (
                                                                                                            <tr key={atividade.atividade.id}>
                                                                                                                {matriculado || isAuthor || isAdmin ?
                                                                                                                    <td className="row-progress nome-aula">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-lg-9 ml-3" onClick={() => { this.handleProgressAtividade(atividade.atividade.id, id_usuario, id_trilha, id_modulo, materia.materia.id) }}>
                                                                                                                                {atividade.progresso == true ?
                                                                                                                                    <FontAwesomeIcon icon="check" size="1x" color="green" className="mr-3" />
                                                                                                                                    :
                                                                                                                                    <FontAwesomeIcon icon="clock" size="1x" color="grey" className="mr-3" />}
                                                                                                                                {atividade.atividade.nome}
                                                                                                                            </div>
                                                                                                                            <div className="col ml-3">
                                                                                                                                {isAuthor || isAdmin ?
                                                                                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + materia.materia.id + "/atividade/" + atividade.atividade.id + "/editar"}>
                                                                                                                                        <Botao texto={idioma.aulas.edit_atividade} icone="cogs" posicao="E" cor="warning" classes="btn-sm mb-2 botao-responsivo" />
                                                                                                                                    </Link>
                                                                                                                                    : ""}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    :
                                                                                                                    <td className="nome-aula">
                                                                                                                        <div className="row">
                                                                                                                            <div className="col-lg-9 ml-3">
                                                                                                                                {atividade.atividade.nome}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>}
                                                                                                            </tr>
                                                                                                        ))}
                                                                                                    </tbody>
                                                                                                </Table>
                                                                                            </Collapse>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                    : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.aulas.erro_materias} />}
                                            </>
                                            : <SemDados subtitulo={idioma.privado} titulo={idioma.conteudo_privado} />}
                                    </div>
                                </div>
                                : <CarregandoTabela />}
                        </>
                        : ""
                    }

                    {selecionadoAtv ?
                        dadosApi ?
                            <div className="col-md-12 col-sm-12">
                                <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                                    {materias.length !== 0 ?
                                        <>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                {/* <Link onClick={() => {
                                            this.setState({ selecionadoAtv: false })
                                            this.setState({ todosAtv: true })
                                        }}>
                                            <Botao texto={idioma.aulas.voltar} icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} classes="btn-md mb-2 botao-responsivo" />
                                        </Link> */}
                                                <Link onClick={() => this.handleAulaAnterior()}>
                                                    <Botao texto={idioma.aulas.voltar} icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} classes="btn-md mb-2 botao-responsivo" disabled={!show_bt_anterior} />
                                                </Link>
                                                <Link onClick={() => this.handleProximaAula()}>
                                                    <Botao texto={idioma.aulas.prox} icone="arrow-right" posicao="D" color={this.props.usuario?.personalizacao.cor_terciaria} classes="btn-md mb-2 botao-responsivo" disabled={!show_bt_proximo} />
                                                </Link>
                                            </div>
                                            {(id_aula_selecionada !== 0 && id_materia_selecionada !== 0) ?
                                                materias.map(materia => (materia.aulas.map(aula => (aula.aula.id == id_aula_selecionada) ?
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="pb-3">
                                                                <Card>
                                                                    <div className="card-body-news">
                                                                        <div className="tab-content mo-mt-2 media row" id="v-pills-tabContent">
                                                                            <div className="col">
                                                                                <CardHeader >
                                                                                    <div>
                                                                                        <div >{idioma.aulas.assist}<span className="titulo-generico">{aula.aula.nome}</span></div>
                                                                                        <p className="materia-aula">{idioma.aulas.em + materia.materia.nome}</p>
                                                                                    </div>
                                                                                </CardHeader>
                                                                            </div>
                                                                            <div className="container-fluid">
                                                                                <div className="row">
                                                                                    <div className="col-md-12 padding-content-class">
                                                                                        {aula.aula.id_tipo == 3 ?
                                                                                            <div class="text-justify mt-3">
                                                                                                {parse("" + aula.aula.conteudo)}
                                                                                            </div>
                                                                                            : aula.aula.id_tipo == 2 ?
                                                                                                <div class="embed-responsive embed-responsive-16by9 mb-4">
                                                                                                    <iframe class="embed-responsive-item" src={aula.aula.link} allowfullscreen></iframe>
                                                                                                </div>
                                                                                                : aula.aula.id_tipo == 1 ?
                                                                                                    <iframe src={aula.aula.link} width="100%" height="500px"></iframe>
                                                                                                    : aula.aula.id_tipo == 4 ?
                                                                                                        <iframe src={"https://open.spotify.com/embed-podcast/episode/" + aula.aula.link} width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                                                                                                        : ""}
                                                                                        <div className="row">
                                                                                            <div className="col-auto">
                                                                                                {aula.aula.descricao}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : "")))
                                                :
                                                (materias[0].aulas[0]) ?
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="pb-3">
                                                                <Card>
                                                                    <div className="card-body-news">
                                                                        <div className="tab-content mo-mt-2 media row" id="v-pills-tabContent">
                                                                            <div className="col">
                                                                                <CardHeader className="titulo-generico">
                                                                                    <div className="row">
                                                                                        <div className="px-2 py-1">{materias[0].aulas[0].aula.nome}</div>
                                                                                    </div>
                                                                                </CardHeader>
                                                                            </div>
                                                                            <div className="container-fluid">
                                                                                <div className="row">
                                                                                    <div className="col-md-12 padding-content-class">
                                                                                        {materias[0].aulas[0].aula.id_tipo == 3 ?
                                                                                            <div class="text-justify mt-3">
                                                                                                {parse("" + materias[0].aulas[0].aula.conteudo)}
                                                                                            </div>
                                                                                            :
                                                                                            materias[0].aulas[0].aula.id_tipo == 2 ?
                                                                                                <div class="embed-responsive embed-responsive-16by9 mb-4">
                                                                                                    <iframe class="embed-responsive-item" src={materias[0].aulas[0].aula.link} allowfullscreen></iframe>
                                                                                                </div>
                                                                                                : materias[0].aulas[0].aula.id_tipo == 1 ?
                                                                                                    <iframe src={materias[0].aulas[0].aula.link} width="100%" height="500px">
                                                                                                    </iframe>
                                                                                                    : materias[0].aulas[0].aula.id_tipo == 4 ?

                                                                                                        <iframe src={materias[0].aulas[0].aula.link} width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                                                                                                        :
                                                                                                        materias[0].aulas[0].aula.id_tipo == 5 ?
                                                                                                            <div class="embed-responsive embed-responsive-16by9 mb-4">
                                                                                                                <iframe class="embed-responsive-item" src={materias[0].aulas[0].aula.link} allowfullscreen></iframe>
                                                                                                            </div>
                                                                                                            : ""}
                                                                                        <div className="row">
                                                                                            <div className="col-auto">
                                                                                                {materias[0].aulas[0].aula.descricao}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.aulas.erro_dados} />}
                                        </>
                                        : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.aulas.erro_materias} />}
                                </div>
                            </div>
                            : <div><Skeleton rectangle={true} height={336} width={600} /></div>
                        : ""
                    }
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    trilha: state.trilhas.trilha,
    alunos: state.trilhas.todosAlunos,
    usuarios: state.auth.usuarios,
    materias: state.trilhas.materias,
    modulos: state.trilhas.modulos
})

export default connect(mapStateToProps, actions)(CadastrarMetodologia);