import {
    GET_USERS, GET_USER, LIMPAR_USER, LIMPAR_USERS, GET_PERFIS, GET_SEARCH,
    GET_INCUBADORAS_COMPLETO, GET_PARTICIPANTES, GET_PERFIS_ACESSO, GET_USUARIOS_PERFIL,
    GET_STARTUPS_COMPLETO, GET_USUARIOS_INCUBADORA, GET_LANGUAGE, GET_PERSONALIZACAO_USUARIO, LIMPAR_PERSONALIZACAO_USUARIO
} from '../actions/types';
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_LANGUAGE:
            return {
                ...state,
                idioma: actions.payload
            }
        case GET_USERS:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case GET_USER:
            return {
                ...state,
                usuarioDetalhes: actions.payload.dados
            }
        case GET_SEARCH:
            return {
                ...state,
                search: actions.payload.data
            }
        case GET_INCUBADORAS_COMPLETO:
            return {
                ...state,
                incubadorasCompleto: null,
                incubadorasCompleto: actions.payload.data
            }
        case GET_PERSONALIZACAO_USUARIO:
            return {
                ...state,
                usuarioPersonalizacao: actions.payload
            }
        case LIMPAR_PERSONALIZACAO_USUARIO:
            return {
                usuarioPersonalizacao: null
            }
        case GET_PARTICIPANTES:
            return {
                ...state,
                participantes: actions.payload.data
            }
        case GET_PERFIS_ACESSO:
            return {
                ...state,
                perfis: actions.payload.data
            }
        case GET_USUARIOS_PERFIL:
            return {
                ...state,
                usuariosPerfil: actions.payload.data
            }
        case GET_USUARIOS_INCUBADORA:
            return {
                ...state,
                usuariosIncubadora: actions.payload.data
            }
        case GET_STARTUPS_COMPLETO:
            return {
                ...state,
                startupsCompleto: null,
                startupsCompleto: actions.payload.data
            }
        case GET_PERFIS:
            return {
                ...state,
                perfis: actions.payload.data
            }
        case LIMPAR_USER:
            return {
                ...state,
                usuarioDetalhes: null
            }
        case LIMPAR_USERS:
            return {
                ...state,
                usuarios: null
            }
        default:
            return state;
    }
}