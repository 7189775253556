import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfil';
import SemDados from '../../componentes/Mensagens/semDados';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import Skeleton from "react-loading-skeleton";
import { apiImagem } from '../../config/';
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginanegocios_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Metodologia extends Component {
    state = {
        id_startup: "",
        id_incubadora: "",
        id_metodologia: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pertence: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        const { usuario } = this.props;
        this.setState({ id_startup: id })
        this.listarMetodologiasStartup(id);
        this.listarStartup(id)
        this.verificaPertence(this.props.usuario.id, id)

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

        // if (this.props.usuario && this.props.credenciais) this.handlePertence(this.props.usuario, this.props.credenciais, id, id_incubadora)

    }




    chamarAlertaParticipante(listaUsuarios) {
        const { idioma } = this.state;
        var lista = [];
        var listaUsuarios = [];
        for (var i in listaUsuarios) {
            listaUsuarios = listaUsuarios[i].usuarios.nome
        }

        Swal.fire({
            title: idioma.metodologia.modal_titulo,
            input: 'select',
            inputOptions: lista,

            inputPlaceholder: idioma.metodologia.modal_titulo2,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.metodologia.bt_cancelar,
            confirmButtonText: idioma.metodologia.bt_salvar
        })
        return new Promise((resolve) => {
            resolve()
        })
    }

    componentDidUpdate(nextProps) {
        const { id } = this.props.match.params;
        if (!this.props.metodologias && nextProps.metodologias) {
            this.listarMetodologiasStartup(id);
        }
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id);
        }
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparMetodologias()
        this.props.limparStartup()
        this.setState({ pertence: false })
    }

    receberDadosAPI() {
        if (typeof this.props.metodologias != "undefined" && this.props.metodologias != null && !this.state.dadosApi) {
            if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
                if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {

                    this.setState({ dadosApi: true });
                }
            }

        }
    }

    listarMetodologiasStartup(id) {
        this.props.getMetodologiasStartup(id);
        const { metodologias } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (metodologias === "undefined") return null
    }

    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null

    }



    // handlePertence(usuario, credenciais, id_startup, id_incubadora) { 
    //     if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
    //         this.setState({ pertence: true })
    //     }
    //     if (usuario.incubadoras.length > 0) {
    //         for (let i in usuario.incubadoras) {
    //             if (usuario.incubadoras[i].id_incubadora == id_incubadora) {
    //                 this.setState({ pertence: true })
    //             }
    //         }
    //     }
    //     if (usuario.startups.length > 0) {
    //         for (let s in usuario.startups) {
    //             if (usuario.startups[s].id_startup == id_startup) {
    //                 this.setState({ pertence: true })
    //             }
    //         }
    //     }
    // }
    verificaPertence(id_usuario, id_startup) {
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { msg, erro, success, dadosApi, pertence, id_startup, idioma } = this.state;
        var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var metodologias = [];
        if (this.props.metodologias) metodologias = this.props.metodologias.dados;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        let aux = []
        for (let m in metodologias) {
            if (metodologias[m].metodologiaAtiva == true) {
                aux.push(true)
            }
        }


        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.metodologia.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        usuarioPertence.pertence &&
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />

                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.metodologia.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    {dadosApi ?
                                        usuarioPertence.pertence ?
                                            metodologias && aux.length > 0 ? metodologias.map(metodologia => (
                                                <div className='row'>
                                                    {metodologia.metodologiaAtiva ?
                                                        metodologia.listaProcessos.map(processo => (
                                                            // <div className='p-4' key={processo.id} style={{ width: `calc(100% / ${colunasMetodologia})` }}>
                                                            <div key={processo.id} className={metodologia.colunas === 1 ? "col-md-12" : metodologia.colunas == 4 ? "col-md-3" : metodologia.colunas === 3 ? "col-md-4" : metodologia.colunas == 2 ? "col-md-6" : "col-md-4"}>
                                                                {processo.ativo ?
                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + metodologia.id + "/processos/" + processo.id + "/etapa/"}>
                                                                        <div className="directory-card card-startups ">
                                                                            <div className="">
                                                                                <img className="card-imagem-processo img-fluid" src={apiImagem + processo.fileName} />
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                    : <div className="directory-card card-startups opacity-50">
                                                                        <div className="">
                                                                            <img className="card-imagem-processo img-fluid" src={apiImagem + processo.fileName} />
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                        ))

                                                        : ""}
                                                </div>
                                            )) : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.metodologia.sem_dados} />
                                            : <SemDados subtitulo={idioma.sem_dados_sub} titulo="Acesso Negado!" />
                                        : <div className="row"><div className="col-md-4" >
                                            <div className="directory-card card-startups">
                                                <div className="card-imagem-processo img-fluid">
                                                    <Skeleton rectangle={true} height={169} width={318} />
                                                </div>
                                            </div>
                                        </div>
                                            <div className="col-md-4" >
                                                <div className="directory-card card-startups">
                                                    <div className="card-imagem-processo img-fluid">
                                                        <Skeleton rectangle={true} height={169} width={318} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4" >
                                                <div className="directory-card card-startups">
                                                    <div className="card-imagem-processo img-fluid">
                                                        <Skeleton rectangle={true} height={169} width={318} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    metodologias: state.startups.metodologias,
    startup: state.startups.startup,
    listaMetodologia: state.startups.todasMetodologias,
    usuarios: state.usuarios.usuarios,
    pertence: state.startups.pertence,
    usuario: state.auth.usuario

})
export default connect(mapStatetoProps, actions)(Metodologia);