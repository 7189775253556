import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import BotaoIcon from '../../componentes/BotaoIcon';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import { Form, FormGroup, Label, Input, Row, Col, Card, CardBody, UncontrolledCollapse, Table } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import SemDados from '../../componentes/Mensagens/semDados';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import ReactTooltip from "react-tooltip";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class EditarAula extends Component {
    state = {
        nome: "",
        duracao: "",
        id_materia: "",
        id_atividade: "",
        id_trilha: "",
        id_curso: "",
        data_publicacao: "",
        ordem: "",
        descricao: "",
        conteudo: "",
        //questao
        titulo: "",
        ordem_questao: "",
        tipo_campo: "",
        //opcao

        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        formSuccessEdit: false,
        formSuccessDelete: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha, id_modulo, id_materia, id_atividade } = this.props.match.params;
        this.setState({ id_trilha })
        this.setState({ id_atividade })
        this.setState({ id_modulo })
        this.setState({ id_materia })
        ////////console.log(id_trilha, id_atividade)
        this.listarTrilha(id_trilha)
        this.listarQuestoes(id_atividade)
        this.props.getVerAtividade(id_atividade)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        const { id_trilha, id_modulo, id_materia, id_atividade } = this.props.match.params;
        if (!this.props.atividade && nextProps.atividade) {
            this.props.getVerAtividade(id_atividade);
            this.listarTrilha(id_trilha)

        }
        if (!this.props.questoes && nextProps.questoes) this.listarQuestoes(id_atividade);

        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparQuestoes()
        this.props.limparOpcoes()
    }

    receberDadosAPI() {
        const { id_atividade } = this.props.match.params;
        if (typeof this.props.atividade !== "undefined" && this.props.atividade !== null && this.props.atividade.id == id_atividade &&
            typeof this.props.questoes != "undefined" && this.props.questoes != null && this.props.questoes.currentPage === this.state.currentPage && !this.state.dadosAPI) {
            this.setState({ id_atividade: this.props.atividade.id })
            this.setState({ nome: this.props.atividade.nome })
            this.setState({ ordem: this.props.atividade.ordem })
            this.setState({ conteudo: this.props.atividade.descricao })
            this.setState({ duracao: this.props.atividade.tempo_maximo })
            this.setState({ data_publicacao: this.props.atividade.data_publicacao })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    listarQuestoes(id_atividade) {
        this.props.getQuestoesLista(id_atividade)
        const { questoes } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (questoes === "undefined") return null
    }


    async atualizarAtividade(id_atividade, nome, ordem, duracao, data_publicacao) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        const { conteudo } = this.state;
        var id = id_atividade;
        var tempo_maximo = duracao;
        var descricao = conteudo;
        // await this.receberDadosForm();
        // if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putAtividade({ id, nome, ordem, descricao, tempo_maximo, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccessEdit: true });
            }
        })

    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }


    receberDadosForm() {
        this.setState({ id_atividade: document.querySelector("#id_atividade").target.value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ ordem: document.querySelector("#ordem").value })
        this.setState({ conteudo: document.querySelector("#conteudo").value })
        this.setState({ duracao: document.querySelector("#duracao").value })

    }

    //deletar questao
    deletandoQuestao(id_questao) {
        this.setState({ carregando: true })
        this.props.deletarQuestao(id_questao, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend["S201"] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.props.limparQuestoes()
                this.listarQuestoes(this.state.id_atividade)
                this.sucessoAlertaExcluirQuestao()
            }
        })
    }
    chamarAlertaQuestao(id_questao) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.editarAtividade.modal_titulo,
            html: idioma.editarAtividade.modal_info,
            input: 'text',
            inputPlaceholder: idioma.editarAtividade.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.editarAtividade.confirm) {
                this.deletandoQuestao(id_questao);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.editarAtividade.modal_erro
                })
            }
        })
    }
    sucessoAlertaExcluirQuestao() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarAtividade.modal_sucesso
        })
    }

    //deletar atividade
    deletandoAtividade(id_atividade) {
        this.setState({ carregando: true })
        this.props.deletarAtividade(id_atividade, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccessDelete: true });
                this.props.limparQuestoes()
                this.listarQuestoes(id_atividade)
                this.sucessoAlertaExcluirAtividade();
            }
        })
    }
    chamarAlertaAtividade(id_atividade) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.editarAtividade.modal_titulo2,
            html: idioma.editarAtividade.modal_info,
            input: 'text',
            inputPlaceholder: idioma.editarAtividade.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value == 'CONFIRMAR') {
                this.deletandoAtividade(id_atividade);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.editarAtividade.modal_erro
                })
            }
        })
    }
    sucessoAlertaExcluirAtividade() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarAtividade.modal_sucesso2
        })
    }

    //cadastrar questão
    cadQuestao(id_atividade, titulo, ordem, tipo_campo, gabarito) {
        const { id_trilha, id_modulo, id_materia } = this.state;
        this.setState({ carregando: true })
        var id_curso = id_trilha
        this.props.postQuestao({ id_atividade, titulo, ordem, tipo_campo, gabarito, id_curso, id_modulo, id_materia }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.setState({ id_questao: msg.erro.id_questao })
                this.listarQuestoes(id_atividade)
                this.sucessoAlertaQuestao()

            }
        }
        )
    }
    chamarCadQuestao(id_atividade) {
        const { idioma } = this.state;
        Swal.mixin({

            confirmButtonText: 'Next &rarr;',
            showCancelButton: true,
            progressSteps: ['1', '2', '3', '4']
        }).queue([
            {
                title: idioma.editarAtividade.titulo_que,
                input: 'text',
            },
            {
                title: idioma.editarAtividade.ordem,
                input: 'text',
            },
            {
                title: idioma.editarAtividade.tipo_quest,
                input: 'select',
                inputOptions: {
                    longa: idioma.editarAtividade.paragrafo,
                    curta: idioma.editarAtividade.curta,
                    multipla: idioma.editarAtividade.multipla,
                    unica: idioma.editarAtividade.unica

                },
            },
            {
                title: idioma.editarAtividade.gabaritos,
                input: 'text',
            },
        ]).then((result) => {
            if (result.value) {
                ////////console.log(result.value)
                if (result.value.length < 3) {
                    Swal.fire({
                        title: idioma.editarAtividade.falta_info,
                        confirmButtonText: idioma.editarAtividade.bt_fechar
                    })
                } else {
                    this.cadQuestao(id_atividade, result.value[0], result.value[1], result.value[2], result.value[3])
                }
            }
        })
    }
    sucessoAlertaQuestao() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarAtividade.quest_sucesso
        })
    }
    onEditorStateChange = (conteudo) => {
        this.setState({
            conteudo
        })
    }


    render() {
        const { id_materia, id_modulo, id_trilha, id_atividade, nome, ordem,formSuccessEdit, descricao, conteudo, duracao, carregando, dadosAPI, data_publicacao, erro, success, formSuccess, formSuccessDelete, idioma } = this.state;

        var materias = [];
        if (this.props.materias) materias = this.props.materias.dados;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;

        var questoes = [];
        if (this.props.questoes) questoes = this.props.questoes.dados;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var atividade = [];
        if (this.props.atividade) atividade = this.props.atividade;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path

        if (formSuccessEdit) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/" +id_modulo + "/aulas",
            state: { msg: idioma.editarAtividade.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editarAtividade.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosAPI} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>

                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.editarAtividade.nome}</Label>
                                            <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.editarAtividade.nome_mate : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="duracao">{idioma.editarAtividade.tempo_atv}</Label>
                                            <Input type="text" value={duracao} id="duracao" name="duracao" className="form-control" autoComplete="duracao" placeholder={dadosAPI ? idioma.editarAtividade.tempo_atv : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("duracao", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label for="Ordem">{idioma.editarAtividade.sel_ordem}</Label>
                                        <Input type="number" value={ordem} id="ordem" name="ordem" className="form-control" autoComplete="ordem" placeholder={dadosAPI ? idioma.editarAtividade.ordem_atv : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("ordem", ev)} />
                                    </Col>
                                    <Col md={4}>
                                        <Label for="data_publicacao">{idioma.editarAtividade.sel_publi}</Label>
                                        <Input type="date" value={data_publicacao} id="data_publicacao" name="data_publicacao" className="form-control" autoComplete="data_publicacao" placeholder={dadosAPI ? idioma.editarAtividade.data_pub : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("data_publicacao", ev)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            {/* <Label for="conteudo">{idioma.editarAtividade.conteudo}</Label>
                                            <Input type="textarea" value={conteudo} id="conteudo" name="conteudo" className="form-control" autoComplete="conteudo" placeholder={dadosAPI ? idioma.editarAtividade.conteudo : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("conteudo", ev)} /> */}
                                            <Card className="card-editor">
                                                <div id='editor'>
                                                    <ReactQuill
                                                        theme='snow'
                                                        value={conteudo}
                                                        bounds={'.app'}
                                                        onChange={(ev) => this.onEditorStateChange(ev)}
                                                        modules={{
                                                            toolbar: {
                                                                container: [
                                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                    ['bold', 'italic', 'underline'],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    [{ 'align': [] }],
                                                                    ['link', 'image'],
                                                                    ['clean'],
                                                                    [{ 'color': [] }]
                                                                ],
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Card>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Input type="hidden" value={id_materia} id="id_materia" name="id_materia" />
                                    </Col>
                                </Row>
                                <Link to="#" onClick={() => { this.chamarCadQuestao(id_atividade) }}>
                                    <Botao loading={carregando} texto="Cadastrar questões" cor="success" icone="plus" posicao="E" />
                                </Link>
                                {questoes.length != 0 ?

                                    <Table className="mt-3">
                                        <thead>
                                            <tr>
                                                <th className="row-nome">{idioma.editarAtividade.nome}</th>
                                                <th>{idioma.editarAtividade.tipo}</th>
                                                <th>{idioma.editarAtividade.status}</th>
                                                <th>{idioma.editarAtividade.acao}</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {questoes.map(questao => (

                                                <tr key={questao.questao.id}>
                                                    <td>
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/questoes/" + questao.questao.id + "/editar/"}>

                                                            {questao.questao.titulo}
                                                        </Link>
                                                    </td>
                                                    {questao.questao.tipo_campo == "multipla" ?
                                                        <td>
                                                            {idioma.editarAtividade.multipla}
                                                        </td>
                                                        : questao.questao.tipo_campo == "unica" ?
                                                            <td>
                                                                {idioma.editarAtividade.unica}
                                                            </td>
                                                            : questao.questao.tipo_campo == "curta" ?
                                                                <td>
                                                                    {idioma.editarAtividade.curta}
                                                                </td>
                                                                : questao.questao.tipo_campo == "longa" ?
                                                                    <td>
                                                                        {idioma.editarAtividade.paragrafo}
                                                                    </td>
                                                                    :
                                                                    <td>{idioma.editarAtividade.sem_tipo}</td>}



                                                    {questao.questao.dataDesativacao == null ?
                                                        <td><span className="badge badge-pill badge-success mb-1">{idioma.editarAtividade.ativo}</span></td>
                                                        : <td><span className="badge badge-pill badge-danger mb-1">{idioma.editarAtividade.desat}</span></td>}


                                                    <td>
                                                        <a data-tip data-for='deletar'>
                                                            <Link to="#" onClick={() => this.chamarAlertaQuestao(questao.questao.id)} className="mr-2">
                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                            </Link>
                                                        </a>
                                                        {questao.questao.tipo_campo == 'multipla' || questao.questao.tipo_campo == 'unica' ?
                                                            <a data-tip data-for='ver'>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/questoes/" + questao.questao.id + "/editar/"}>
                                                                    <BotaoIcon loading={carregando} classes="btn-secondary btn-sm ml-1" icone="eye" />
                                                                </Link>
                                                            </a>
                                                            : ""}
                                                    </td>
                                                    <ReactTooltip id='deletar' type='error'>
                                                        <span>{idioma.bt_deletar}</span>
                                                    </ReactTooltip>
                                                    <ReactTooltip id='ver' type='dark'>
                                                        <span>{idioma.editarAtividade.ver_op}</span>
                                                    </ReactTooltip>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </Table>
                                    : <SemDados titulo="Questões " />}

                                <Link onClick={() => this.atualizarAtividade(id_atividade, nome, ordem, duracao)} to="#">
                                    <Botao loading={carregando} texto="Salvar" classes="botao-responsivo botao-estilo" />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                {credenciais.map(credencial => ((credencial.id_componente == 64) ?
                                    <Link to="#" onClick={() => this.chamarAlertaAtividade(id_atividade)} className="mr-2">

                                        <Botao loading={carregando} texto={idioma.bt_deletar} classes="btn-danger btn-md ml-1" icone="trash-alt" />
                                    </Link>
                                    : ""))}
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    atividade: state.trilhas.atividade,
    trilha: state.trilhas.trilha,
    questoes: state.trilhas.questoes



})
export default connect(mapStateToProps, actions)(EditarAula)