export const portugues = {
  modal_titulo: 'Deseja deletar o evento?',
  modal_info:
    'Ao fazer isso você concorda na exclusão do evento </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
  modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
  confirm: 'CONFIRMAR',
  bt_deletar: 'Deletar',
  modal_erro: 'Mensagem de confirmação inválida!',
  modal_sucesso: 'Evento excluído com sucesso!',
  bt_editar: 'Editar',
  sel_solucao: 'Selecionar solução',
  canc_solucao: 'Cancelar solucao',
  bt_prox: 'Próximo',
  tec: 'TECNOLOGIA',
  mercado: 'MERCADO',
  capital: 'CAPITAL',
  gestao: 'GESTÃO',
  empreendedor: 'EMPREENDEDOR',
  doc: 'Adicione o documento da avaliação',
  nota_final: 'Nota final: ',
  bt_avancar: 'Avançar',
  refazer: 'Refazer avaliação',
  erro: 'Erro!',
  sucesso: 'Sucesso!',
  apr_solucao: 'Deseja aprovar a solução?',
  nota_media: 'Nota média dos avaliadores: ',
  repro: 'Reprovar',
  apro: 'Aprovar',
  obs: 'Observações',
  falta_ava: 'Ainda faltam avaliações!',
  aguard_aval: 'Aguardando avaliações: ',
  de: 'de ',
  msg_sucesso: 'Solucao editado com sucesso!',
  bt_voltar: 'Voltar',
  sele: 'Etapa 01 (selecionar)',
  cancel_sel: 'Cancelar seleção',
  avaliar: 'Etapa 02 (avaliação)',
  msg_erro: 'É permitida apenas uma avaliação por avaliador!',
  msg_info: 'O número de avaliações permitidas já foi alcançado.',
  aval_gestor: 'Etapa 03 (gestor)',
  msg_erro2: 'É permitida apenas uma avaliação de gestor por solução!',
  etapa1_error_msg: 'É necessário o envio de arquivo!',
  info_nota_corte: 'Atenção gestor, esta solução não alcançou a nota de corte mínima estipulada para esse processo.'
};

export const ingles = {
  modal_titulo: 'Do you want to delete the event?',
  modal_info:
    'By doing so you agree to the deletion of the event </br>Type <strong>CONFIRM</strong> to perform the deletion',
  modal_confirm: 'Type CONFIRM to perform the deletion',
  confirm: 'CONFIRM',
  bt_deletar: 'Delete',
  modal_erro: 'Invalid confirmation message!',
  modal_sucesso: 'Event successfully deleted!',
  bt_editar: 'Edit',
  sel_solucao: 'Select solution',
  canc_solucao: 'Cancel solution',
  bt_prox: 'Next',
  tec: 'TECHNOLOGY',
  mercado: 'MARKET',
  capital: 'CAPITAL',
  gestao: 'MANAGEMENT',
  empreendedor: 'ENTREPRENEUR',
  doc: 'Add the assessment document',
  nota_final: 'Final grade: ',
  bt_avancar: 'Go',
  refazer: 'Redo review',
  erro: 'Error!',
  sucesso: 'Success!',
  apr_solucao: 'Do you want to approve the solution?',
  nota_media: 'Average score of the evaluators: ',
  repro: 'Disapprove',
  apro: 'Approve',
  obs: 'Observations',
  falta_ava: 'Reviews are still missing!',
  aguard_aval: 'Waiting for reviews: ',
  de: 'of 3',
  msg_sucesso: 'Solution edited successfully!',
  bt_voltar: 'Back',
  sele: 'Select',
  cancel_sel: 'Deselect',
  avaliar: 'Evaluate',
  msg_erro: 'Only one review per reviewer is allowed!',
  msg_info: 'The number of allowed evaluations has already been reached.',
  aval_gestor: 'Manager evaluation',
  msg_erro2: 'Only one manager rating per solution is allowed!',
  etapa1_error_msg: 'File upload required!',
  info_nota_corte: 'Attention manager, this solution did not reach the minimum cutoff score stipulated for this process.'

};
