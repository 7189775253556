import axios from 'axios';
import { GET_PERGUNTAS_RADAR, GET_PILARES, GET_RADARES, GET_RADAR, GET_OPCOES, LIMPAR_RADARES, LIMPAR_PILARES, LIMPAR_PERGUNTAS, LIMPAR_OPCOES } from './types'
import { api } from '../../config';
import { getHeaders, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getRadares = () => {
    return function (dispatch) {
        axios.get(api + `/radar-metodologia/todos`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_RADARES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRadar = (id_radar) => {
    return function (dispatch) {
        axios.get(api + `/radar-metodologia/${id_radar}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPilares = (id_radarmetodologia) => {
    return function (dispatch) {
        axios.get(api + `/pilar/todos?id_radarmetodologia=${id_radarmetodologia}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_PILARES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPerguntas = (id_radarmetodologia, id_pilar) => {
    return function (dispatch) {
        axios.get(api + `/perguntas/todos?id_radarmetodologia=${id_radarmetodologia}&id_pilar=${id_pilar}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_PERGUNTAS_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getOpcoes = (id_radar) => {
    return function (dispatch) {
        axios.get(api + `/radar/opcoes/?id_radar=${id_radar}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_OPCOES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postRadar = (dados, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosPerfil)
        axios.post(api + `/radar-metodologia`, dados, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postPilar = (dados, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosPerfil)
        axios.post(api + `/pilar`, dados, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postPergunta = (dados, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosPerfil)
        axios.post(api + `/radar/pergunta`, dados, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putRadar = (dados, callback) => {
    return function (dispatch) {
        axios.put(api + `/radar-metodologia/alterar`, dados, getHeadersPost())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putPilar = (dados, callback) => {
    return function (dispatch) {
        axios.put(api + `/pilar/alterar`, dados, getHeadersPost())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putOpcoes = (dados, callback) => {
    return function (dispatch) {
        axios.put(api + `/radar/opcoes/alterar`, dados, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putPergunta = (dados, callback) => {
    return function (dispatch) {
        axios.put(api + `/radar/alterar`, dados, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const deletarPergunta = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/radar/${id}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarPilar = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/pilar/${id}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarRadar = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/radar-metodologia/${id}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const limparRadares = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_RADARES })
    }
}
export const limparPilares = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PILARES })
    }
}
export const limparPerguntas = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PERGUNTAS })
    }
}
export const limparOpcoes = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_OPCOES })
    }
}