import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Spinner } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import Swal from 'sweetalert2';
import SemDados from '../../componentes/Mensagens/semDados';
import Toast from '../../componentes/Alertas/Toast';
import Titulo from '../../componentes/TituloPaginas';


import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import { Link } from 'react-router-dom';

class Radar extends Component {
	state = {
		resposta: null,
		id_radar: null,
		id_pilar: null,
		id_usuario: null,
		id_startup: null,
		id_etapa: null,
		primeiroAtv: true,
		segundoAtv: false,
		terceiroAtv: false,
		quartoAtv: false,
		quintoAtv: false,
		id_incubadora: "",
		id_metodologia: "",
		id_processo: "",
		resposta: "",
		perguntaAtual: "",
		pertence: false,
		verificado: false,
		listaAbertas: [],
		listaPilares: [],
		respAbertas: {},
		esperandoApi: false,
		msg: "",
		erro: "",
		success: "",
		carregando: "",
		paginacao: false,
		formSuccess: false,
		dadosApi: false,
		pilarAtv: 1,
		pilarId: 0,
		contador: 0,
		idioma: portugues,
		msg_backend: portuguese
	}

	componentDidMount() {
		// Recupera os params da url para usar na classe
		if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });

		const { id_startup, id_metodologia, id_incubadora, id_etapa, id_processo } = this.props.match.params;
		this.setState({ id_incubadora })
		this.setState({ id_metodologia })
		this.setState({ id_startup });
		this.setState({ id_processo });
		this.setState({ id_etapa });

		this.listarPerguntasRadar(id_metodologia, id_startup, id_etapa);
		this.listarRespostasRadar(id_metodologia);
		this.verificaPertence(this.props.usuario.id, id_startup)
		if (this.props.perguntas) {
			this.arrayRespostas(this.props.perguntas.dados)
			this.arrayPilares(this.props.perguntas.dados)
		}
		if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

		const { usuario } = this.props;
		if (usuario?.idioma?.id === 3) {
			this.setState({ idioma: ingles })
			this.setState({ msg_backend: english })
		} else {
			this.setState({ idioma: portugues })
			this.setState({ msg_backend: portuguese })
		}

	}

	componentDidUpdate(nextProps) {
		const { id_startup, id_metodologia, id_etapa } = this.props.match.params;

		if (!this.props.perguntas && nextProps.perguntas) {
			this.listarPerguntasRadar(id_metodologia, id_startup, id_etapa);
			this.arrayRespostas(nextProps.perguntas.dados)
			this.arrayPilares(nextProps.perguntas.dados)

		}

		if (this.props.perguntas && !this.state.paginacao) {
			this.arrayRespostas(this.props.perguntas.dados)
			this.arrayPilares(this.props.perguntas.dados)

		}

		if (!this.props.respostas && nextProps.respostas) {
			this.listarRespostasRadar(id_metodologia);
		}
		if (!this.props.pertence && nextProps.pertence) {
			this.verificaPertence(this.props.usuario.id, id_startup);
		}
		this.receberDadosAPI();
	}

	componentWillUnmount() {
		this.props.limparPerguntas()
		this.props.limparRespostas()
	}

	onChangeInput(campo, ev, id_radar) {
		const { perguntaAtual } = this.state
		if (id_radar === perguntaAtual) {
			this.setState({ [campo]: ev.target.value })
		}
	}

	receberDadosAPI() {
		if (typeof this.props.perguntas != "undefined" && this.props.perguntas != null && !this.state.dadosApi) {
			if (typeof this.props.respostas != "undefined" && this.props.respostas != null && !this.state.dadosApi) {
				if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
					// if (this.state.pilarId != 0) {
					return this.setState({ dadosApi: true });
					// }
				}
			}
		}
	}

	listarPerguntasRadar(id_metodologia, id_startup, id_etapa) { // action
		this.props.getPerguntasRadar(id_metodologia, id_startup, id_etapa); // Solicita à API a lista de perguntas para o form 

		const { perguntas } = this.props; // Recupera a lista de perguntas da API

		// Limpa o estado da msg (popup)
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}

		// Valida se retornou dados da API
		if (perguntas === "undefined") {
			return null;
		}
	}

	listarRespostasRadar(id_metodologia) {
		const { id_startup, id_etapa } = this.props.match.params;

		this.props.getRespostasRadar(id_startup, id_etapa, id_metodologia); // Solicita à API a lista de respostas para o form 
		const { respostas } = this.props; // Recupera a lista de respostas da API 

		// Limpa o estado da msg (popup)
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}

		// Valida se retornou dados da API
		if (respostas === "undefined") {
			return null;
		}
	}

	alerta(icon, title) {
		const Toast = Swal.mixin({
			toast: true,
			position: 'bottom-end',
			showConfirmButton: false,
			timer: 3000,
			timerProgressBar: true,
			onOpen: (toast) => {
				toast.addEventListener('mouseenter', Swal.stopTimer)
				toast.addEventListener('mouseleave', Swal.resumeTimer)
			}
		})

		Toast.fire({
			icon: icon,
			title: title
		})
	}

	checked(listaRespostas, id_radar, value, opcao) {
		let valor = parseInt(value)
		let checked = "";
 
		for (const cont in listaRespostas) {
			if (listaRespostas[cont].id_radar === id_radar) {
				if (listaRespostas[cont].opcao) {
					if (listaRespostas[cont].opcao === Number(opcao)) {
						checked = "checked";
					}
				} else {
					if (listaRespostas[cont].resposta === valor) {
						checked = "checked";
					}
				}
			}
		}
		return checked;
	}

	salvar(id_radar, id_pilar, ev, opcao) {
		var { id_usuario, id_startup, id_etapa } = this.state;

		//FIXME willunmount() para dar refresh no componente
		const listaRespostas = this.props.respostas.dados;
		let resposta = ev.target.value.split(',');
		// this.checked(listaRespostas, id_radar, resposta[0]);

		console.log(resposta[0], 'resposta')

		this.setState({ resposta: resposta[0] });
		this.setState({ id_radar });
		this.setState({ id_pilar });
		this.setState({ carregando: true });

		if (id_etapa === 0) { id_etapa = null }
		this.props.postRadar({ resposta, id_radar, id_pilar, id_usuario, id_startup, id_etapa, opcao }, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ success: "" });
				this.setState({ carregando: false });
			} else {
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ erro: "" });
				this.setState({ carregando: false });
				this.alerta("success", this.state.msg_backend[msg.erro.code]);
				this.listarRespostasRadar();
				// this.checked(listaRespostas, id_radar, resposta);
			}
		});
	}

	salvarTexto(id_radar, id_pilar, listaPerguntas) {
		var { id_usuario, id_startup, id_etapa, resposta, id_metodologia } = this.state;

		//placeholder
		for (let l in listaPerguntas) {
			for (let r in listaPerguntas[l].radares) {
				if (listaPerguntas[l].radares[r].id === id_radar && listaPerguntas[l].radares[r].id_pilar === id_pilar) {
					listaPerguntas[l].radares[r].respostaAberta = resposta
				}
			}
		}
		let aberta = resposta
		this.setState({ resposta: "" })
		this.setState({ perguntaAtual: "" })
		this.setState({ id_radar });
		this.setState({ id_pilar });
		this.setState({ carregando: true });
		if (id_etapa == 0) { id_etapa = null }
		this.props.postRadar({ aberta, id_radar, id_pilar, id_usuario, id_startup, id_etapa }, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ success: "" });
				this.setState({ carregando: false });
			} else {
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ erro: "" });
				this.setState({ carregando: false });
				this.alerta("success", this.state.msg_backend[msg.erro.code]);
				this.listarPerguntasRadar(id_metodologia, id_startup, id_etapa);
			}
		});
	}

	handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
		if (usuarios.startups.length > 0) {
			for (let u in usuarios.startups) {
				if (usuarios.startups[u].id_startup === id_startup) {
					this.setState({ pertence: true })
				}
			}
		}
		if (usuarios.incubadoras.length > 0) {
			for (let u in usuarios.incubadoras) {
				if (usuarios.incubadoras[u].id_incubadora === id_incubadora) {
					this.setState({ pertence: true })
				}
			}
		}
		if (credenciais[0].id_perfil === 1 || credenciais[0].id_perfil === 9) {
			this.setState({ pertence: true })
		}

	}


	verificaPertence(id_usuario, id_startup) {
		this.props.getVerificarPertence(id_usuario, id_startup);
		const { pertence } = this.props;
		if (this.props.location.state) {

			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (pertence === "undefined") return null

	}


	arrayRespostas(perguntas) {
		let respostasAbertas = []
		for (let r in perguntas) {
			for (let ra in perguntas[r].radares) {
				if (perguntas[r].radares[ra].tipo === "aberta" && perguntas[r].radares[ra].respostaAberta != null) {
					respostasAbertas.push({ id_radar: perguntas[r].radares[ra].id, aberta: perguntas[r].radares[ra].respostaAberta })
				}
			}
		}

		this.setState({ listaAbertas: respostasAbertas })

	}

	arrayPilares(perguntas) {
		let lista = []
		for (let p in perguntas) {
			lista.push(perguntas[p].pilares.id)
		}
		this.setState({ listaPilares: lista })
		this.setState({ paginacao: true })
	}

	verificaResposta(id_radar, respostas, tipo) {
		let respostaBanco = null
		for (let r in respostas) {
			if (tipo === "placeholder") {
				if (respostas[r].aberta != null && respostas[r].id_radar === id_radar && respostas[r].verificado === false) {
					respostaBanco = respostas[r].aberta
					respostas[r].verificado = true
				}
			}
			if (tipo === "value") {
				if (respostas[r].aberta != null && respostas[r].id_radar === id_radar) {
					respostaBanco = respostas[r].aberta
				}
			}
		}
		if (respostaBanco != null) {
			this.setState({ resposta: respostaBanco })
			return true
		}
	}

	voltarResp(id_radar) {
		const { listaAbertas } = this.state
		for (let r in listaAbertas) {
			if (listaAbertas[r].aberta != null && listaAbertas[r].id_radar === id_radar) {
				return listaAbertas[r].aberta.aberta
			}
		}
	}

	handleList(ev, id_radar) {
		const { listaAbertas } = this.state
		for (let l in listaAbertas) {
			if (listaAbertas[l].id_radar == id_radar) {
				listaAbertas[l].aberta = ev.target.value
			}
		}
	}

	setInicio(perguntas) {
		this.setState({ pilarId: perguntas[0].pilares.id })
		this.setState({ pilarAtv: perguntas[0].pilares.id })
	}

	setContador(lista, id) {
		for (let l in lista) {
			// console.log(lista[l].pilares.id)
			if (lista[l].pilares.id === id) {

				this.setState({ contador: l })
			}
		}
	}



	render() {
		// Form names 
		const { id_startup, dadosApi, contador, listaPilares, paginacao, carregando, listaAbertas, esperandoApi, pilarId, id_metodologia, id_processo, perguntaAtual, resposta, pilarAtv, primeiroAtv, segundoAtv, terceiroAtv, quartoAtv, quintoAtv, idioma } = this.state;
		const { usuario } = this.props;
		var listaPerguntas = [];
		var listaRespostas = [];
		//var radarAplicado = null;

		// reducer
		if (this.props.perguntas) { // acessa o perguntas dentro do mapToProps
			listaPerguntas = this.props.perguntas.dados; // salva em um novo array os dados da props radares
			if (pilarId === 0) {
				this.setInicio(listaPerguntas)
			}
		}
		var usuarioPertence = [];
		if (this.props.pertence) usuarioPertence = this.props.pertence;
		if (this.props.respostas) {
			listaRespostas = this.props.respostas.dados;
			//radarAplicado = this.props.respostas.radarAplicado;
		}



		return (
			<>
				<div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
					<Titulo titulo={idioma.radar.titulo} />
				</div>
				<div className="container-fluid bloco-topo" >
					<div className="col-md-12 mb-2">
						<div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
							<div className="row">
								<div className="col-md-12">
									<div className="container-fluid">
										{listaPerguntas.length > 0 &&
											<div className="row justify-content-center">
												{listaPerguntas.map(pilares => (

													<div className={pilarAtv === pilares.pilares.id ? "col-auto menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-auto menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => {
														this.setContador(listaPerguntas, pilares.pilares.id)
														this.setState({ pilarAtv: pilares.pilares.id })
														this.setState({ pilarId: pilares.pilares.id })
													}}>
														<span className="text-perfil">
															{pilares && pilares.pilares.nome}
														</span>
													</div>
												))}


											</div>
										}
									</div>
								</div>
							</div>
						</div>
						<div className="list-group-item ">

							<div>
								<Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + id_metodologia + "/processos/" + id_processo + "/etapa/"}> <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
								{dadosApi && pilarId !== 0 ?
									usuarioPertence.pertence ?
										<div>
											<Form>
												{listaPerguntas.map(perguntas => (pilarAtv === perguntas.pilares.id) &&
													<>
														<div className="etapa-card card m-4" >
															<div className="directory-content p-4">
																<div className="titulo-generico">{perguntas.pilares.nome}</div>
																<Row form>
																	<Col md={12}>
																		<FormGroup tag="fieldset">
																			{perguntas.radares.map(radar => (radar.id_pilar === pilarId) &&

																				radar.tipo === "multipla" ?
																				<div className="p-2" key={radar.id}>
																					<Label for={radar.id}><b>{radar.pergunta}</b></Label>
																					<div className="row ml-2">
																						{radar.info != null &&
																							<p className="texto-cidade">{radar.info}</p>
																						}
																					</div>
																					{radar.opcao1 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 1)} name={radar.id} value={`${radar.nota1},${radar.opcao1}`} checked={this.checked(listaRespostas, radar.id, radar.nota1, 1)} disabled={carregando ? true : false} /> {radar.opcao1} </Label>
																						</FormGroup>
																					}
																					{radar.opcao2 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 2)} name={radar.id} value={`${radar.nota2},${radar.opcao2}`} checked={this.checked(listaRespostas, radar.id, radar.nota2, 2)} disabled={carregando ? true : false} />{radar.opcao2}</Label>
																						</FormGroup>
																					}
																					{radar.opcao3 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 3)} name={radar.id} value={`${radar.nota3},${radar.opcao3}`} checked={this.checked(listaRespostas, radar.id, radar.nota3, 3)} disabled={carregando ? true : false} />{radar.opcao3}</Label>
																						</FormGroup>
																					}
																					{radar.opcao4 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 4)} name={radar.id} value={`${radar.nota4},${radar.opcao4}`} checked={this.checked(listaRespostas, radar.id, radar.nota4, 4)} disabled={carregando ? true : false} />{radar.opcao4}</Label>
																						</FormGroup>
																					}
																					{radar.opcao5 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 5)} name={radar.id} value={`${radar.nota5},${radar.opcao5}`} checked={this.checked(listaRespostas, radar.id, radar.nota5, 5)} disabled={carregando ? true : false} />{radar.opcao5}</Label>
																						</FormGroup>
																					}
																					{radar.opcao6 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 6)} name={radar.id} value={`${radar.nota6},${radar.opcao6}`} checked={this.checked(listaRespostas, radar.id, radar.nota6, 6)} disabled={carregando ? true : false} />{radar.opcao6}</Label>
																						</FormGroup>

																					}
																					{radar.opcao7 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 7)} name={radar.id} value={`${radar.nota7},${radar.opcao7}`} checked={this.checked(listaRespostas, radar.id, radar.nota7, 7)} disabled={carregando ? true : false} />{radar.opcao7}</Label>
																						</FormGroup>

																					}
																					{radar.opcao8 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 8)} name={radar.id} value={`${radar.nota8},${radar.opcao8}`} checked={this.checked(listaRespostas, radar.id, radar.nota8, 8)} disabled={carregando ? true : false} />{radar.opcao8}</Label>
																						</FormGroup>
																					}
																					{radar.opcao9 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 9)} name={radar.id} value={`${radar.nota9},${radar.opcao9}`} checked={this.checked(listaRespostas, radar.id, radar.nota9, 9)} disabled={carregando ? true : false} />{radar.opcao9}</Label>
																						</FormGroup>
																					}
																					{radar.opcao10 &&
																						<FormGroup check>
																							<Label check> <Input type="radio" onChange={(ev) => this.salvar(radar.id, radar.id_pilar, ev, 10)} name={radar.id} value={`${radar.nota10},${radar.opcao10}`} checked={this.checked(listaRespostas, radar.id, radar.nota10, 10)} disabled={carregando ? true : false} />{radar.opcao10}</Label>
																						</FormGroup>
																					}
																				</div>
																				: radar.tipo == "aberta" && radar.id_pilar === pilarId &&
																				<div className="p-2" key={radar.id} >
																					<Label for={radar.id}><b>{radar.pergunta}</b>: </Label>
																					<div className="row ml-2">
																						{radar.info != null &&
																							<p className="texto-cidade">{radar.info}</p>
																						}
																					</div>
																					<Input className="m-2" onFocus={() => {
																						this.verificaResposta(radar.id, listaRespostas)
																						this.setState({ perguntaAtual: radar.id })
																						this.setState({ resposta: radar.respostaAberta != null ? radar.respostaAberta : "" })
																					}} onBlur={() => this.salvarTexto(radar.id, radar.id_pilar, listaPerguntas)}
																						type="textarea"
																						value={perguntaAtual === radar.id ? resposta : ""}
																						onChange={(ev) => {
																							this.handleList(ev, radar.id)
																							this.onChangeInput("resposta", ev, radar.id)
																						}}
																						name={perguntaAtual === radar.id ? "resposta" : "resposta_" + radar.id}
																						id={perguntaAtual === radar.id ? "resposta" : "resposta_" + radar.id}
																						placeholder={
																							radar.respostaAberta != null ?
																								radar.respostaAberta :
																								idioma.radar.resposta}
																					/>
																				</div>
																			)}
																		</FormGroup>
																	</Col>
																</Row>
																{paginacao &&
																	<div className="pagination justify-content-center">

																		{contador !== 0 ?
																			<Link onClick={() => {
																				this.setState({ contador: contador - 1 })
																				this.setState({ pilarId: listaPilares[contador - 1] })
																				this.setState({ pilarAtv: listaPilares[contador - 1] })

																			}}> <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo mr-2" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
																			:
																			<span className="mr-2">
																				<Button classes="botao-responsivo" disabled style={{ backgroundColor: this.props.usuario?.personalizacao?.cor_terciaria }}>
																					{idioma.participantes.bt_voltar}
																				</Button>
																			</span>
																		}
																		<Link to="#"> <Botao texto={parseInt(contador) + 1} classes="botao-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
																		{contador < listaPilares.length - 1 || contador === 0 ?
																			<Link onClick={() => {
																				this.setState({ contador: contador + 1 })
																				this.setState({ pilarId: listaPilares[contador + 1] })
																				this.setState({ pilarAtv: listaPilares[contador + 1] })

																			}}> <Botao texto={idioma.radar.bt_avancar} classes="botao-responsivo ml-2" icone="arrow-right" posicao="D" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
																			:
																			<span className="ml-2">
																				<Button classes="botao-responsivo ml-2" disabled style={{ backgroundColor: this.props.usuario?.personalizacao?.cor_terciaria }}>
																					{idioma.radar.bt_avancar}
																				</Button>
																			</span>
																		}
																	</div>
																}
															</div>
														</div>

														<FormGroup check>
															<Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + id_metodologia + "/processos/" + id_processo + "/etapa/"} onClick={() => Toast({ icon: 'success', title: idioma.radar.alerta_titulo, text: idioma.radar.alerta_msg })}>
																<button className="mr-1 btn btn-success btn-sm btn-block">{idioma.participantes.bt_salvar}</button>
															</Link>
														</FormGroup>
													</>
												)}
											</Form>
										</div>
										: <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.participantes.erro_acesso} />
									: <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
const mapStateToProps = state => ({
	perguntas: state.startups.perguntas, // acessa o startupsReducer e joga para uma nova props radares
	respostas: state.startups.respostas,
	pertence: state.startups.pertence

})

export default connect(mapStateToProps, actions)(Radar);