import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input, Button, Table } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Titulo from '../../componentes/TituloPaginas';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

class ListarTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 20,
        trilha: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        this.listarTrilhas();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.trilhas && nextProps.trilhas) this.listarTrilhas();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparTrilha();
        this.props.limparTrilhas();
    }
    receberDadosAPI() {
        if (typeof this.props.trilhas != "undefined" && this.props.trilhas != null && !this.state.dadosApi && this.props.trilhas.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarTrilhas();
        })
    }

    //desativar trilhas
    desativarTrilha(id) {
        const { msg_backend } = this.state;
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.desativarTrilha(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }

    //ativar trilha
    ativarTrilha(id) {
        const { msg_backend } = this.state;
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.ativarTrilha(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.sucessoAlertaAtivar();
                // this.setState({ formSuccess: true });
            }
        })
    }
    chamarAlertaAtivar(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemTrilhas.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.ativarTrilha(id);
            }
        })
    }
    sucessoAlertaAtivar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemTrilhas.msg_sucesso
        })
    }

    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemTrilhas.modal_confirm2
        }).then((result) => {
            if (result.value) {
                this.desativarTrilha(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemTrilhas.msg_sucesso2
        })
    }

    //deletar trilha
    deletandoTrilha(id_trilha) {
        const { msg_backend } = this.state;
        this.setState({ carregando: true })
        this.props.deletarTrilha(id_trilha, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
                this.props.limparTrilhas();
                this.listarTrilhas();

            }
        })
    }

    chamarAlertaDeletar(id_trilha) {
        const { idioma } = this.state;
        Swal.fire({
            title: 'Deseja deletar a trilha?',
            html: 'Ao fazer isso você concorda na exclusão dos vínculos de participantes, módulos, aulas e atividades da trilha. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
            input: 'text',
            inputPlaceholder: idioma.listagemIncubadoras.modal_confirm3,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.listagemIncubadoras.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.listagemIncubadoras.confirm) {
                this.deletandoTrilha(id_trilha);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.listagemIncubadoras.erro_msg
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemTrilhas.modal_sucesso
        })
    }
    listarTrilhas() {
        const { currentPage, size } = this.state;
        this.props.getTrilhas(currentPage, size)
        const { trilhas } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilhas === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao?.cor_secundaria
    }


    render() {
        const { trilha, msg, carregando, erro, success, dadosApi, formSuccess, idioma } = this.state;
        const { credenciais, usuario } = this.props

        var trilhas = [];


        if (this.props.trilhas) trilhas = this.props.trilhas.dados;
        var temPaginaAnterior = false;
        if (typeof this.props.trilhas != "undefined" && this.props.trilhas != null && this.props.trilhas.currentPage !== "" && this.props.trilhas.currentPage !== 1) {
            temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (typeof this.props.trilhas != "undefined" && this.props.trilhas !== null && this.props.trilhas.nextPage <= this.props.trilhas.totalPages && this.props.trilhas.nextPage != null) {
            proximaPagina = true;
            temProximaPagina = true;
        }


        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.listagemTrilhas.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/cadastrar"}>
                                <Botao texto={idioma.listagemTrilhas.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                    {idioma.listagemTrilhas.bt_cadastrar}
                                </button> */}
                            </Link>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.nome}</th>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.status}</th>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.acao}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {trilhas.map(trilha => (
                                                            <tr key={trilha.id}>

                                                                <td>{trilha.nome}</td>
                                                                {trilha.dataDesativacao == null ?
                                                                    <td><span className="badge badge-pill badge-success mb-1">{idioma.listagemIncubadoras.atv}</span></td>
                                                                    : <td><span className="badge badge-pill badge-danger mb-1">{idioma.listagemIncubadoras.desatv}</span></td>}
                                                                <td>
                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/participantes/"} className="mr-2">
                                                                        <button className="btn btn btn-sm" style={{ backgroundColor: this.props.usuario?.personalizacao?.cor_terciaria, color: this.props.usuario?.personalizacao?.cor_texto_menu_hover }}>
                                                                            {idioma.listagemIncubadoras.participantes}
                                                                        </button>
                                                                    </Link>
                                                                    <a data-tip data-for='perfil'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/modulos"} className="mr-2">
                                                                            <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="eye" />
                                                                        </Link>
                                                                    </a>

                                                                    <a data-tip data-for='editar'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/editar/" + trilha.id} className="mr-2">
                                                                            <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="pencil-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    {trilha.dataDesativacao == null ?
                                                                        <a data-tip data-for='desativar'>
                                                                            <Link to="#" onClick={() => this.chamarAlerta(trilha.id)} className="mr-2">
                                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="ban" />
                                                                            </Link>
                                                                        </a>
                                                                        :
                                                                        <a data-tip data-for='ativar'>
                                                                            <Link to="#" onClick={() => this.chamarAlertaAtivar(trilha.id)} className="mr-2">
                                                                                <BotaoIcon loading={carregando} classes="btn-success btn-sm ml-1" icone="check" />
                                                                            </Link>
                                                                        </a>
                                                                    }
                                                                    {credenciais.map(credencial => ((credencial.id_componente == 60) ?
                                                                        <a data-tip data-for='deletar'>
                                                                            <Link to="#" onClick={() => this.chamarAlertaDeletar(trilha.id)} className="mr-2">
                                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                            </Link>
                                                                        </a>
                                                                        : ""))}

                                                                    <ReactTooltip id='perfil' type='dark'>
                                                                        <span>{idioma.listagemIncubadoras.perfil}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' type='dark'>
                                                                        <span>{idioma.listagemIncubadoras.bt_editar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='desativar' type='error'>
                                                                        <span>{idioma.listagemIncubadoras.bt_desativar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='ativar' type='success'>
                                                                        <span>{idioma.listagemIncubadoras.ativar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='deletar' type='error'>
                                                                        <span>{idioma.listagemIncubadoras.bt_deletar}</span>
                                                                    </ReactTooltip>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                        {dadosApi ?
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.criarVinculoPersonalizacao.page1}</span>
                                    </li>

                                    {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.trilhas.prevPage ? this.props.trilhas.prevPage : 1)}>{this.props.trilhas.prevPage ? this.props.trilhas.prevPage : ""}</span></li> : ""}

                                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.trilhas ? this.props.trilhas.currentPage : "1"}</span></li>

                                    {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.trilhas.nextPage ? this.props.trilhas.nextPage : 1)}>{this.props.trilhas.nextPage ? this.props.trilhas.nextPage : ""}</span></li> : ""}

                                    <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.trilhas ? this.props.trilhas.totalPages : 0)}>{idioma.criarVinculoPersonalizacao.page1}</span>
                                    </li>
                                </ul>
                            </nav>
                            : ""}
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    trilhas: state.trilhas.trilhas,

})
export default connect(mapStatetoProps, actions)(ListarTrilhas);