import axios from 'axios';
import {
    GET_STARTUPS, GET_STARTUP, GET_MINHAS_STARTUPS, GET_VINCULOS_METODOLOGIA, GET_VINCULOS_RADAR, GET_METODOLOGIAS_POR_STARTUP,
    GET_METODOLOGIAS_STARTUP, GET_ETAPAS_STARTUP, GET_ESTAGIOS_STARTUP, GET_ETAPA, GET_RADARES_POR_STARTUP,
    GET_PARTICIPANTES, GET_PARTICIPANTE_STARTUP, GET_PERFIS, GET_METODOLOGIAS, GET_RELATORIOS, GET_RELATORIOS_MENTORIAS,
    GET_PERGUNTAS_RADAR, GET_PROGRESSO_PROCESSO, GET_EVIDENCIA, GET_ENTREGA, LIMPAR_PARTICIPANTE, LIMPAR_PARTICIPANTES, GET_RESPOSTAS_RADAR, GET_PILARES_RADAR,
    LIMPAR_STARTUP, LIMPAR_STARTUPS, GET_FUTUROS_PARTICIPANTES, GET_SEARCH_STARTUP, GET_MINHAS_INCUBADORAS_2, GET_RELATORIOS_DESAFIOS,
    GET_TODAS_INCUBADORAS, GET_STARTUP_INCUBADORA, GET_INCUBADORA, LIMPAR_INCUBADORA, LIMPAR_ESTAGIOS_STARTUPS, LIMPAR_ESTAGIO,
    GET_STARTUPS_ATIVAS, LIMPAR_MINHAS_INCUBADORAS, LIMPAR_ENTREGA, LIMPAR_METOLOGIAS, LIMPAR_USERS, LIMPAR_STARTUP_METODOLOGIA, LIMPAR_METODOLOGIA,
    GET_USERS, LIMPAR_MINHAS_STARTUPS, LIMPAR_ETAPAS, LIMPAR_PERGUNTAS, LIMPAR_RESPOSTAS, LIMPAR_INCUBADORAS, GET_DETALHES_RADAR, GET_USER,
    GET_RELATORIOS_DESENVOLVIMENTO, GET_RELATORIOS_CONTEUDOS, GET_RELATORIOS_INCUBADORA, GET_RELATORIOS_STARTUPS, GET_RELATORIOS_METODOLOGIA, GET_VER_PROCESSO, GET_SEARCH,
    GET_SEARCH_MINHAS_STARTUP, GET_MENSAGENS, GET_STARTUPS_GESTAO, GET_VERIFICAR, GET_SEARCH_STARTUP_CONFIG, GET_STATUS_ETAPA, GET_METODOLOGIA,
    GET_METODOLOGIAS_RADAR, GET_RADARES, GET_RADARES_METODOLOGIA, GET_PROCESSOS, GET_PROCESSO, GET_ETAPAS, GET_ESTAGIOS, GET_ESTAGIO, GET_ENTREGAS,
    GET_ENTREGA_ESTAGIO, LIMPAR_ENTREGA_ESTAGIO, LIMPAR_PROCESSO_METODOLOGIA, LIMPAR_ETAPA, GET_JORNADA_GESTAO

} from './types'
import { api } from '../../config';
import { getHeaders, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getStartupsAtivas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/ativos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS_ATIVAS, payload: response })
            })
            .catch(errorHandle)
    }
}
export const postVinculoRadarIncubadora = (dadosVinculo, callback) => {
    return function (dispatch) {
        axios.post(api + `/incubadora-radar`, dadosVinculo, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getVinculosRadar = (id_radar) => {
    return function (dispatch) {
        axios.get(api + `/incubadora-radar/radar/${id_radar}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_VINCULOS_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getMetodologiasPorStartup = (id_startup) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id_startup}/metodologia/buscar`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_METODOLOGIAS_POR_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRadaresPorStartup = (id_startup) => {
    return function (dispatch) {
        axios.get(api + `/incubadora-radar/radares/startup/${id_startup}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_RADARES_POR_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const excluirVinculoRadar = (id_incubadora, callback) => {

    return function (dispatch) {
        axios.delete(api + `/incubadora-radar/${id_incubadora}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postVinculoMetodologiaIncubadora = (dadosVinculo, callback) => {
    return function (dispatch) {
        axios.post(api + `/incubadora-metodologia`, dadosVinculo, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getVinculosMetodologia = (id_metodologia) => {
    return function (dispatch) {
        axios.get(api + `/incubadora-metodologia/metodologia/${id_metodologia}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_VINCULOS_METODOLOGIA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const excluirVinculoMetodologia = (id_incubadora, callback) => {

    return function (dispatch) {
        axios.delete(api + `/incubadora-metodologia/${id_incubadora}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getEtapasStatus = (id_startup, id_etapa) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id_startup}/etapas/status?${id_etapa}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STATUS_ETAPA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getProgressoProcesso = (id_processo, id_startup) => {
    return function (dispatch) {
        axios.get(api + `/processo/${id_processo}/startup/${id_startup}/progresso`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PROGRESSO_PROCESSO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerificarPertence = (id_usuario, id_startup, id_incubadora) => {
    // console.log(id_usuario, id_startup, id_incubadora)
    return function (dispatch) {
        axios.get(api + `/pertence/verificar?id_usuario=${id_usuario}&id_startup=${id_startup}&id_incubadora=${id_incubadora}`, getHeaders())
            .then((response) => {
                // console.log(response)
                dispatch({ type: GET_VERIFICAR, payload: response })
            })
            .catch(errorHandle)
    }
}



export const getStartups = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getStartupsIncubadoras = (id_usuario, paginaAtual, limite, jornadaSelecionada) => {
    return function (dispatch) {
        axios.get(api + `/startups/gestao?id_usuario=${id_usuario}&jornada=${jornadaSelecionada}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS_GESTAO, payload: response })
            })
            .catch(errorHandle)
    }
}
export const getJornadasIncubadoras = (id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/startups/gestao/jornadas?id_usuario=${id_usuario}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_JORNADA_GESTAO, payload: response })
            })
            .catch(errorHandle)
    }
}
export const getSearchStartupsIncubadoras = (pesquisa, id_usuario, paginaAtual, limite, jornadaSelecionada) => {
    return function (dispatch) {
        axios.get(api + `/startups/gestao/buscar?pesquisa=${pesquisa}&id_usuario=${id_usuario}&jornada=${jornadaSelecionada}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS_GESTAO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuarios = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USERS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearch = (procura, paginaAtual, limite) => {
    ////console.log(procura)
    return function (dispatch) {
        axios.get(api + `/usuarios/buscar?procura=${procura}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_SEARCH, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getMinhasStartups = (id_usuario, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/usuario/${id_usuario}/listar?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_MINHAS_STARTUPS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getMinhasIncubadoras = (id_usuario, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/usuario/${id_usuario}/listar?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_MINHAS_INCUBADORAS_2, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerStartup = (id) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id}`, getHeaders())
            .then((response) => {
                // console.log(response)
                dispatch({ type: GET_STARTUP, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getVerProcesso = (id) => {
    return function (dispatch) {
        axios.get(api + `/processo/${id}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_VER_PROCESSO, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getMetodologiasStartup = (id_startup) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id_startup}/metodologia/`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_METODOLOGIAS_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getIncubadoraStartup = (id) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id}/incubadora/`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUP_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerIncubadora = (id_incubadora) => {
    return function (dispatch) {

        axios.get(api + `/incubadora/${id_incubadora}`, getHeaders())
            .then((response) => {
                ////////////console.log(response.data)
                dispatch({ type: GET_INCUBADORA, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getVerEtapa = (id_etapa) => {
    return function (dispatch) {

        axios.get(api + `/etapa/${id_etapa}`, getHeaders())
            .then((response) => {
                //console.log(response.data)
                dispatch({ type: GET_ETAPA, payload: response.data })
            })
            .catch(errorHandle)
    }
}
export const getVerEstagio = (id_estagio) => {
    return function (dispatch) {

        axios.get(api + `/estagio/${id_estagio}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_ESTAGIO, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getPerguntasRadar = (id_metodologia, id_startup, id_etapa) => {
    return function (dispatch) {
        axios.get(api + `/radares?id_metodologia=${id_metodologia}&id_startup=${id_startup}&id_etapa=${id_etapa}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERGUNTAS_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRespostasRadar = (id_startup, id_etapa, id_metodologia) => {
    return function (dispatch) {
        axios.get(api + `/radar/startup/${id_startup}/metodologia/${id_metodologia}/etapa/${id_etapa}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_RESPOSTAS_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getEtapasStartup = (id, id_metodologia, id_processo) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id}/metodologia/${id_metodologia}/processo/${id_processo}/`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_ETAPAS_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPilaresRadar = (id, id_metodologia, id_processo, id_etapa) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id}/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/resumo`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PILARES_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRadares = () => {
    return function (dispatch) {
        axios.get(api + `/radar-metodologia/todos`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_RADARES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const putVincularRadar = (dadosStartup, callback) => {
    return function (dispatch) {
        axios.put(api + `/metodologia/radar/vincular`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putDesvincularRadar = (dadosStartup, callback) => {
    return function (dispatch) {
        axios.put(api + `/metodologia/radar/desvincular`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getDetalhesRadar = (id, id_metodologia, id_processo, id_etapa) => {
    return async function (dispatch) {
        await axios.get(api + `/startup/${id}/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/detalhes`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_DETALHES_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getEstagiosStartup = (id, id_metodologia, id_processo, id_etapa) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id}/metodologia/${id_metodologia}/processo/${id_processo}/etapa/${id_etapa}/`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_ESTAGIOS_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postStartup = (dadosStartup, callback) => {
    return function (dispatch) {
        axios.post(api + `/startup`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postAvaliacao = (dadosAvaliacao, callback) => {
    return function (dispatch) {
        axios.post(api + `/curso_avaliacao`, dadosAvaliacao, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putStartup = (dadosStartup, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosStartup)
        axios.put(api + `/startup/editar`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const putStartupImagem = (dadosStartup, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosStartup)
        axios.put(api + `/startup/editar/imagem`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putStartupCapa = (dadosStartup, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosStartup)
        axios.put(api + `/startup/editar/capa`, dadosStartup, getHeadersPost())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postParticipante = (dadosStartup, callback) => {
    return function (dispatch) {
        axios.post(api + `/startup/participantes/cadastrar`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}



export const getParticipante = () => {
    return function (dispatch) {
        axios.get(api + `/startup/vincular`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PARTICIPANTE_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerificaChat = (id_usuario1, id_usuario2) => {
    return function (dispatch) {
        axios.get(api + `/chat/verificar?id_usuario1=${id_usuario1}&id_usuario2=${id_usuario2}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_MENSAGENS, payload: response })
            })
            .catch(errorHandle)
    }
}




export const getParticipantes = (id_startup, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id_startup}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_PARTICIPANTES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPerfis = () => {
    return function (dispatch) {
        axios.get(api + `/perfis/`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERFIS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postEntrega = (dadosStartup, callback) => {
    ////////////console.log(dadosStartup)
    return function (dispatch) {
        axios.post(api + `/evidencia`, dadosStartup, getHeadersPost())
            .then((response) => {

                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getEvidencia = (id) => {
    return function (dispatch) {
        axios.get(api + `/evidencia/${id}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_EVIDENCIA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getEntrega = (id, id_startup) => {
    return function (dispatch) {
        axios.get(api + `/entrega/${id}/startup/${id_startup}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_ENTREGA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getMetodologiasRadar = () => {
    return function (dispatch) {
        axios.get(api + `/metodologias/radar`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_METODOLOGIAS_RADAR, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postNovaMetodologia = (metodologia, callback) => {
    return function (dispatch) {
        axios.post(api + `/metodologia`, metodologia, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postNovoProcesso = (data, callback) => {
    return function (dispatch) {
        axios.post(api + `/processo`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postNovaEtapa = (data, callback) => {
    return function (dispatch) {
        axios.post(api + `/etapa`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postNovoEstagio = (data, callback) => {
    return function (dispatch) {
        axios.post(api + `/estagio`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postNovaEntrega = (data, callback) => {
    return function (dispatch) {
        axios.post(api + `/entrega`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEditarMetodologia = (data, callback) => {
    return function (dispatch) {
        axios.put(api + `/metodologia/editar`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEditarProcesso = (data, callback) => {
    return function (dispatch) {
        axios.put(api + `/processo/editar`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEditarEtapa = (data, callback) => {
    return function (dispatch) {
        axios.put(api + `/etapa/editar`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEditarEstagio = (data, callback) => {
    return function (dispatch) {
        axios.put(api + `/estagio/editar`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEditarEntrega = (data, callback) => {
    return function (dispatch) {
        axios.put(api + `/entrega/editar`, data, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getMetodologias = () => {
    return function (dispatch) {
        axios.get(api + `/metodologias`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_METODOLOGIAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getMetodologia = (id_metodologia) => {
    return function (dispatch) {
        axios.get(api + `/metodologia/${id_metodologia}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_METODOLOGIA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getProcessos = (id_metodologia) => {
    return function (dispatch) {
        axios.get(api + `/processo/${id_metodologia}/metodologia`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_PROCESSOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getEtapas = (id_processo) => {
    return function (dispatch) {
        axios.get(api + `/etapa/${id_processo}/processo`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_ETAPAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getEstagios = (id_etapa) => {
    return function (dispatch) {
        axios.get(api + `/estagio/${id_etapa}/etapa`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_ESTAGIOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getEntregas = (id_estagio) => {
    return function (dispatch) {
        axios.get(api + `/entrega/${id_estagio}/estagio`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_ENTREGAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getEntregaEstagio = (id_entrega) => {
    return function (dispatch) {
        axios.get(api + `/entrega/${id_entrega}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_ENTREGA_ESTAGIO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getProcesso = (id_processo) => {
    return function (dispatch) {
        axios.get(api + `/processo/${id_processo}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_PROCESSO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorio = (id_incubadora, id_metodologia, id_processo) => {
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/metodologia/${id_metodologia}/processo/${id_processo}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRadaresMetodologia = () => {
    return function (dispatch) {
        axios.get(api + `/radar-metodologia/todos`, getHeaders())
            .then((response) => {
                // console.log(response)
                dispatch({ type: GET_RADARES_METODOLOGIA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorioDesafios = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/desafios`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS_DESAFIOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorioMentorias = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/mentorias`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS_MENTORIAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorioDesenvolvimento = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/desenvolvimento`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS_DESENVOLVIMENTO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorioConteudos = (id_incubadora) => {
    ////console.log(id_incubadora)
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/conteudos`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS_CONTEUDOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorioIncubadora = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/incubadora`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorioMetodologia = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/metodologia`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS_METODOLOGIA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchNameStartupsConfig = (pesquisa, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/buscar/todas?pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_SEARCH_STARTUP_CONFIG, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getRelatorioStartups = (id_incubadora) => {
    return function (dispatch) {
        axios.get(api + `/relatorios/${id_incubadora}/startups`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_RELATORIOS_STARTUPS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postVincularMetodologia = (dadosMetodologia, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosMetodologia)
        axios.post(api + `/startup/metodologia/vincular`, dadosMetodologia, getHeadersPost())
            .then((response) => {

                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putMetodologia = (dadosMetodologia, callback) => {
    return function (dispatch) {
        axios.put(api + `/startup/metodologia/editar`, dadosMetodologia, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putDesvincularStartup = (id, callback) => {
    return function (dispatch) {
        axios.put(api + `/startup/${id}/incubadora/desvincular`, id, getHeadersPost())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const excluirMetodologia = (id_metodologia, callback) => {

    return function (dispatch) {
        axios.delete(api + `/metodologia/${id_metodologia}`, getHeaders())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const excluirProcesso = (id_processo, callback) => {

    return function (dispatch) {
        axios.delete(api + `/processo/${id_processo}`, getHeaders())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const excluirEtapa = (id_processo, callback) => {

    return function (dispatch) {
        axios.delete(api + `/etapa/${id_processo}`, getHeaders())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const excluirEstagio = (id_etapa, callback) => {

    return function (dispatch) {
        axios.delete(api + `/estagio/${id_etapa}`, getHeaders())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const excluirEntrega = (id_etapa, callback) => {

    return function (dispatch) {
        axios.delete(api + `/entrega/${id_etapa}`, getHeaders())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarMetodologia = (id_startup, id_metodologia, id_processo, callback) => {

    return function (dispatch) {
        axios.delete(api + `/startup/${id_startup}/metodologia/${id_metodologia}/processo/${id_processo}/desvincular/`, getHeaders())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarEvidencia = (id, callback) => {

    return function (dispatch) {
        axios.delete(api + `/evidencia/${id}/deletar`, getHeaders())
            .then((response) => {
                // console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postValidacao = (dadosStartup, callback) => {
    return function (dispatch) {
        ////////////console.log(dadosStartup)
        axios.post(api + `/evidencia/validacao`, dadosStartup, getHeadersPost())
            .then((response) => {

                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postRadar = (dadosStartup, callback) => {
    return function (dispatch) {
        //////////////console.log(dadosStartup);
        axios.post(api + `/radar`, dadosStartup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarParticipante = (id_startup, id_usuario, callback) => {
    return function (dispatch) {
        axios.delete(api + `/startup/${id_startup}/participantes/${id_usuario}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarStartup = (id_startup, callback) => {
    return function (dispatch) {
        axios.delete(api + `/startup/${id_startup}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}




export const desativarStartup = (id_startup, callback) => {
    return function (dispatch) {
        axios.put(api + `/startup/${id_startup}/desativar`, id_startup, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const ativarStartup = (id_startup, callback) => {
    return function (dispatch) {
        axios.put(api + `/startup/${id_startup}/ativar`, id_startup, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const getFuturosParticipantes = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_FUTUROS_PARTICIPANTES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postVincularParticipante = (dadosParticipante, callback) => {
    return function (dispatch) {
        axios.post(api + `/startup/participantes/vincular`, dadosParticipante, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const postVincularIncubadora = (dadosIncubadora, callback) => {
    return function (dispatch) {
        axios.post(api + `/startup/incubadora/vincular`, dadosIncubadora, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const getSearchNameStartups = (pesquisa, id_incubadora, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/buscar?pesquisa=${pesquisa}&id_incubadora=${id_incubadora}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_SEARCH_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchMinhasStartups = (pesquisa, id_usuario, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/minhas/buscar?pesquisa=${pesquisa}&id_usuario=${id_usuario}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {

                dispatch({ type: GET_SEARCH_MINHAS_STARTUP, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getTodasIncubadoras = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/ativos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_TODAS_INCUBADORAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerUsuario = (id) => {
    return function (dispatch) {
        axios.get(api + `/usuario/${id}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USER, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const limparParticipante = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PARTICIPANTE })
    }
}
export const limparParticipantes = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PARTICIPANTES })
    }
}
export const limparIncubadora = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_INCUBADORA })
    }
}

export const limparIncubadoras = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_INCUBADORAS })
    }
}

export const limparStartupMetodologia = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_STARTUP_METODOLOGIA })
    }
}

export const limparStartup = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_STARTUP })
    }
}
export const limparStartups = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_STARTUPS })
    }
}

export const limparEstagiosStartups = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ESTAGIOS_STARTUPS })
    }
}

export const limparEntrega = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ENTREGA })
    }
}


export const limparMinhasIncubadoras = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MINHAS_INCUBADORAS })
    }
}

export const limparMetodologias = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_METOLOGIAS })
    }
}

export const limparProcessoMetodologia = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PROCESSO_METODOLOGIA })
    }
}

export const limparEntregaEstagio = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ENTREGA_ESTAGIO })
    }
}

export const limparUsuarios = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_USERS })
    }
}

export const limparMinhasStartups = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MINHAS_STARTUPS })
    }
}


export const limparEtapas = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ETAPAS })
    }
}

export const limparEtapa = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ETAPA })
    }
}
export const limparEstagio = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ESTAGIO })
    }
}

export const limparMetodologia = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_METODOLOGIA })
    }
}

export const limparPerguntas = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_PERGUNTAS })
    }
}

export const limparRespostas = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_RESPOSTAS })
    }
}