import React, { Component, ReactDOM } from 'react';
import Chart from 'react-apexcharts'

class Radar extends React.Component {
    constructor(props) {
        super(props);

        const data = [];
        const categories = [];
        var name = "";
        const peso_maximo = this.props.pesoMaximo ? this.props.pesoMaximo : 5


        for (const p in this.props.pilares) {
            name = this.props.pilares[p].etapa;
            categories.push(this.props.pilares[p].pilar.nome);
            data.push(this.props.pilares[p].mediaPilar.mediaPilar);
        }

        this.state = {
            series: [{
                name: name,
                data: data,
            }],
            options: {
                chart: {
                    width: "100%",
                    type: 'radar',
                    dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                    }
                },
                stroke: {
                    width: 2
                },
                fill: {
                    opacity: 0.1
                },
                markers: {
                    size: 0
                },
                xaxis: { categories: categories },
                yaxis: {
                    min: 0,
                    max: Number(peso_maximo),
                    tickAmount: Number(peso_maximo),
                    forceNiceScale: false
                }
            },
        };
    }

    pilares() {
        const series = [];
        const data = [];
        const categories = [];

        for (const p in this.props.pilares) {
            categories.push(this.props.pilares[p].pilar.nome);
            data.push(this.props.pilares[p].mediaPilar);
        }
        series.name = "Etapa";
        series.data = data;
    }

    render() {
        return (
            <Chart options={this.state.options} series={this.state.series} type="radar" height={350} />
        );
    }
}
export default Radar;
