import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class EditarImagem extends Component {
    state = {
        id_startup: "",
        erro: "",
        success: "",
        file: null,
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup } = this.props.match.params;
        this.props.getVerStartup(id_startup);

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;
        if (!this.props.startup && nextProps.startup) this.props.getVerStartup(id_startup);
        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparStartup()
    }
    receberDadosAPI() {
        const { id_startup } = this.props.match.params;
        if (typeof this.props.startup !== "undefined" && this.props.startup !== null && this.props.startup.id == id_startup && !this.state.dadosAPI) {
            this.setState({ id_startup: this.props.startup.id })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    async atualizarStartup(id, file) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id);
        this.setState({ carregando: true })
        this.props.putStartupImagem(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    receberDadosForm() {
        this.setState({ id_startup: document.querySelector("#id_startup").value })
        this.setState({ file: document.querySelector("#file").value })

    }
    validate() {
        const { file, idioma } = this.state;
        if (!file) return this.setState({ erro: { message: idioma.editarCapa.info } });
        return true;
    }
    render() {
        const { id_startup, carregando, dadosAPI, erro, success, formSuccess, file, idioma } = this.state;
        var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (formSuccess) return <Redirect to={{
            pathname: "/ecossistema/startups/" + id_startup,
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editarImagem.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        <BlocoPerfil dadosApi={dadosAPI} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form className="pt-2">
                                <Input type="hidden" value={id_startup} id="id_startup" name="id_startup" />
                                <Row form>
                                    <FormGroup>
                                        <Label for="file">{idioma.editarImagem.foto}</Label>
                                        <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                    </FormGroup>
                                </Row>
                                <Link to="#" onClick={() => this.atualizarStartup(id_startup, file)}> <Botao loading={carregando} texto={idioma.participantes.bt_salvar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.participantes.bt_cancelar} classes="botao-responsivo botao-estilo" icone="times" posicao="E" cor="danger" /> </Link>

                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    startup: state.startups.startup

})
export default connect(mapStateToProps, actions)(EditarImagem)