import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input, Button, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Toast from '../../componentes/Alertas/Toast';
import Titulo from '../../componentes/TituloPaginas';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

class ListarTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 20,
        trilha: "",
        msg: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        this.listarMetodologias();

        const { usuario } = this.props;

        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state });
        }


        if (usuario.idioma.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.metodologias && nextProps.metodologias) this.listarMetodologias();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
    }
    receberDadosAPI() {
        if (typeof this.props.metodologias != "undefined" && this.props.metodologias != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarTrilhas();
        })
    }

    chamarAlertaExcluir(id_metodologia) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_del,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemMetodologia.modal_del
        }).then((result) => {
            if (result.value) {
                this.excluirMetodologia(id_metodologia);
            }
        })
    }

    excluirMetodologia(id_metodologia) {
        this.setState({ carregando: true })
        this.props.excluirMetodologia(id_metodologia, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ carregando: false });
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
                this.listarMetodologias();
            }
        })
    }


    listarMetodologias() {
        this.props.getMetodologiasRadar()
        const { metodologias } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state })
            this.props.location.state = "";
        }
        if (metodologias === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }


    render() {
        const { msg, carregando, erro, dadosApi, idioma } = this.state;
        const { credenciais } = this.props;

        var metodologias = [];
        if (this.props.metodologias) metodologias = this.props.metodologias.dados;
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.listagemMetodologia.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            {erro && <AlertaErro erros={erro} />}
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
                            <Link to={`/${this.props.usuario.personalizacao.titulo}/configuracoes/metodologia/cadastrar`} className="pr-2"> <Botao texto={idioma.personalizacao.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color="green" /> </Link>
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.listagemMetodologia.nome}</th>
                                                            <th className="coluna-tabela">{idioma.listagemMetodologia.vinculos}</th>
                                                            <th className="coluna-tabela">{idioma.listagemMetodologia.colunas}</th>
                                                            <th className="coluna-tabela">{idioma.listagemMetodologia.data_alt}</th>
                                                            <th className="coluna-tabela">{idioma.listagemMetodologia.acoes}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {metodologias.map(metodologia => (
                                                            <tr key={metodologia.id}>
                                                                <td>{metodologia.nome}</td>
                                                                <td>
                                                                    <a data-tip data-for='vinculos'>
                                                                        <Link to={`/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${metodologia?.id}/vinculos`} className="mr-2">
                                                                            <Botao texto={metodologia.numero_incubadoras} classes="botao-responsivo" icone="eye" posicao="D" color={this.props.usuario?.personalizacao?.cor_terciaria} />
                                                                        </Link>
                                                                    </a>
                                                                </td>
                                                                <td>{metodologia.colunas ? metodologia.colunas : '--'}</td>
                                                                {/* <td>{metodologia.datahora_criacao ? format(new Date(metodologia.datahora_criacao), 'dd/MM/yyyy', { locale: pt }) : '--'}</td> */}
                                                                <td>{metodologia.datahora_alteracao ? format(new Date(metodologia.datahora_alteracao), 'dd/MM/yyyy', { locale: pt }) : metodologia.datahora_criacao ? format(new Date(metodologia.datahora_criacao), 'dd/MM/yyyy', { locale: pt }) : '--'}</td>
                                                                <td>
                                                                    <a data-tip data-for='processos'>
                                                                        <Link to={`/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologia/${metodologia?.id}/processos/listar`} className="mr-2">
                                                                            <BotaoIcon loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="btn btn-sm ml-1" icone="eye" />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='editar'>
                                                                        <Link to={`/${this.props.usuario.personalizacao.titulo}/configuracoes/metodologia/${metodologia.id}/editar`} className="mr-2">
                                                                            <BotaoIcon loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="btn btn-sm ml-1" icone="pencil-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='excluir'>
                                                                        <Link to="#" onClick={() => this.chamarAlertaExcluir(metodologia.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash" />
                                                                        </Link>
                                                                    </a>

                                                                    <ReactTooltip id='vinculos' backgroundColor={this.props.usuario?.personalizacao?.cor_terciaria}>
                                                                        <span>{idioma.listagemMetodologia.tooltip_vinculos}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='processos' backgroundColor={this.props.usuario?.personalizacao?.cor_terciaria}>
                                                                        <span>{idioma.listagemMetodologia.processos}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' backgroundColor={this.props.usuario?.personalizacao?.cor_terciaria}>
                                                                        <span>{idioma.personalizacao.bt_editar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='excluir' type='error'>
                                                                        <span>{idioma.personalizacao.bt_excluir}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='processos' type='warning'>
                                                                        <span>{idioma.listagemMetodologia.processos}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' type='success'>
                                                                        <span>{idioma.listagemIncubadoras.bt_editar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='excluir' type='error'>
                                                                        <span>{idioma.personalizacao.bt_excluir}</span>
                                                                    </ReactTooltip>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    metodologias: state.startups.metodologiasRadar,
    radares: state.startups.radares,

})
export default connect(mapStatetoProps, actions)(ListarTrilhas);