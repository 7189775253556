import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { Table, Toast } from 'reactstrap';

const ReactLoadingSkeleton = () => (
  <div className="d-flex">
    <div className="col-md-12 p-2">
      <Table>
        <thead>
          <tr>
            <th><Skeleton count={1} width={80} /></th>
            <th><Skeleton count={1} width={80} /></th>
            <th><Skeleton count={1} width={80} /></th>
          </tr>
        </thead>
        <tbody>
          <tr>

            <td>
              <Skeleton count={1} width={80} />
            </td>
            <td>
              <Skeleton circle={true} height={40} width={40} />
            </td>
            <td>
              <Skeleton count={1} width={80} />
            </td>

          </tr>
        </tbody>
      </Table>
    </div>
  </div>
);

export default ReactLoadingSkeleton;