import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Table, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';


import Skeleton from "react-loading-skeleton";

import Botao from '../../componentes/Botoes';

import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Swal from 'sweetalert2';
import Titulo from '../../componentes/TituloPaginas';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

class Personalizacao extends Component {
  state = {
    usuario: "",
    msg: "",
    dadosApi: false,
    msg_backend: portuguese,
    idioma: portugues,
    spinner: true
  }
  componentDidMount() {
    const { usuario } = this.props;
    this.setState({ usuario: usuario });
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state })
    }

    this.listarPersonalizacoes()
    this.setState({ dadosApi: true })

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }

  }

  // componentDidUpdate(nextProps) {
  //   if (!this.props.personalizacoes && nextProps.personalizacoes) this.listarPersonalizacoes();
  //   this.receberDadosAPI();
  // }

  // receberDadosAPI() {
  //   if (typeof this.props.personalizacoes !== 'undefined') {
  //     this.setState({ dadosApi: true })
  //   }
  // }

  listarPersonalizacoes() {
    const { getTodasPersonalizacoes, personalizacoes } = this.props;

    getTodasPersonalizacoes();
    this.setState({ spinner: false })
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg })
      this.props.location.state.msg = "";
    }
    if (personalizacoes === "undefined") return null

  }

  handleExcluir(id_personalizacao) {
    const { msg_backend } = this.state
    const { deletarPersonalizacao } = this.props;

    deletarPersonalizacao(id_personalizacao, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.listarPersonalizacoes();
      }
    }
    )
  }

  onHover(ev) {
    ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
    ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
  }

  cleanHover(ev, isActive) {
    ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
    ev.target.style.color = this.props.usuario?.personalizacao.cor_secundaria
  }

  render() {
    const { dadosApi, spinner, idioma } = this.state;
    const { usuario } = this.props;
    var credenciais = []
    if (this.props.credenciais) credenciais = this.props.credenciais;
    let dados_personalizacoes = [];
    if (this.props.personalizacoes) dados_personalizacoes = this.props.personalizacoes.dados.slice(0).reverse();

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao?.cor_principal }}>
          <Titulo titulo={idioma.personalizacao.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao?.cor_quaternaria }}>
              <Link to={"/" + usuario?.personalizacao?.titulo + "/configuracoes"} className="pr-2"> <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
              {credenciais.map(credencial => ((credencial.id_componente === 90) ?
                <Link to={"/" + usuario?.personalizacao?.titulo + "/configuracoes/personalizacao/nova"}>
                  <Botao texto={idioma.personalizacao.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                  {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                  {idioma.personalizacao.bt_cadastrar}
                </button> */}
                </Link>
                : ""))}
              {/* <div className="row mt-3 mb-3">
                            <div className="col-lg-8">
                                <Input type="text" value='' onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder="Pesquisar.." />
                            </div>
                            <div className="col-lg-4">
                                <div>
                                    <span className="pr-3" onClick={() => this.handleSearch('procura')} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto="Pesquisar" icone="search" posicao="E" /></span>
                                    <span value='' onClick={() => this.eraseSearch("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" cor="danger" texto="Cancelar" icone="times" posicao="E" /></span>
                                </div>
                            </div>
                        </div>
                        {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                        <AlertaErro erros={erro} />
                        <AlertaSuccess erros={success} /> */}
              {dadosApi ?

                spinner ? (
                  <Table>
                    <thead>
                      <th >{idioma.personalizacao.incubadora}</th>
                      <th>{idioma.personalizacao.acoes}</th>
                    </thead>
                    <div className="row d-flex justify-content-center mt-5">
                      <Spinner />
                    </div>
                  </Table>
                ) : (

                  <Table style={{ marginTop: '20px' }}>
                    <thead>
                      <th >{idioma.personalizacao.titulo2}</th>
                      <th>{idioma.personalizacao.acoes}</th>
                    </thead>
                    <tbody>
                      {dados_personalizacoes ? dados_personalizacoes.map((perso, index) => (
                        <tr key={index}>
                          <td>{perso?.titulo}</td>
                          <td>
                            {credenciais.map(credencial => ((credencial.id_componente === 91) ?
                              <Link to={"/" + usuario?.personalizacao?.titulo + "/personalizacao/" + perso?.id + "/editar/"}>
                                <button className="mr-1 btn btn-outline-warning btn-sm botao-responsivo">{idioma.personalizacao.bt_editar}</button>
                              </Link>
                              : ""))}
                            {credenciais.map(credencial => ((credencial.id_componente === 92) ?
                              <Link to='#' onClick={() => Swal.fire({
                                title: idioma.personalizacao.modal_titulo,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: 'green',
                                cancelButtonColor: '#d33',
                                confirmButtonText: idioma.personalizacao.modal_confirm
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  this.handleExcluir(perso.id)
                                }
                              })}>
                                <button className="btn btn-outline-danger btn-sm botao-responsivo">{idioma.personalizacao.bt_excluir}</button>
                              </Link>
                              : ""))}
                          </td>
                        </tr>
                      )) : ''}
                    </tbody>
                  </Table>
                )

                : <div className="row">
                  <Table>
                    <thead>
                      <tr>
                        <th>{idioma.personalizacao.titulo2}</th>
                        <th>{idioma.personalizacao.acoes}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Skeleton count={1} width={80} />
                        </td>
                        <td>
                          <Skeleton count={1} width={80} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                </div>}
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  usuario: state.auth.usuario,
  personalizacoes: state.incubadoras.todasPersonalizacoes
})
export default connect(mapStateToProps, actions)(Personalizacao);