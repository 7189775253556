import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';
import BotaoIcon from '../../componentes/BotaoIcon';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import Swal from 'sweetalert2';
import MultiselectCheckox from 'react-multiselect-checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class Radar extends Component {
    state = {
        gabarito: "",
        resposta: "",
        resposta_texto: "",
        resposta_texto_longo: "",
        id_atividade: "",
        id_questao_selecionada: "",
        id_modulo: "",
        id_usuario: "",
        listaQuestoes: "",
        splitado: [],
        n: 100,
        step: 1,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        formSuccess: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {

        const { id_atividade, id_trilha, id_modulo, id_materia } = this.props.match.params;
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });
        this.setState({ id_atividade });
        this.setState({ id_modulo });
        this.setState({ id_materia });

        this.setState({ id_trilha });
        if (this.props.usuario) this.listarQuestoes(id_atividade, this.props.usuario.id);
        this.listarAtividade(id_atividade, this.props.usuario.id);
        if (this.props.questoes) {
            this.getIdQuestoes(this.props.questoes.dados)
            this.handleAnswers(this.props.questoes)
        }
        this.checarFeito(id_atividade, this.props.usuario.id)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }

    componentDidUpdate(nextProps) {
        const { id_trilha, id_atividade } = this.props.match.params;
        if (!this.props.questoes && nextProps.questoes) {
            this.listarQuestoes(id_atividade, this.props.usuario.id);


        }
        if (this.props.questoes) {
            ////console.log(this.props.questoes)
            this.handleAnswers(this.props.questoes)
        }
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparQuestoes()
    }

    receberDadosAPI() {
        if (typeof this.props.atividade != "undefined" && this.props.atividade != null && !this.state.dadosApi) {
            if (typeof this.props.questoes != "undefined" && this.props.questoes != null && !this.state.dadosApi) {
                return this.setState({ dadosApi: true });

            }
        }

    }

    getIdQuestoes(questoes) {
        var lista = []
        for (const q in questoes) {
            if (questoes[q].questao.tipo_campo == "longa" || questoes[q].questao.tipo_campo == "curta")
                lista = lista.concat(questoes[q].questao.id)
        }
        return this.setState({ listaQuestoes: lista })
    }

    listarAtividade(id_atividade, id_usuario) {

        this.props.getVerAtividade(id_atividade, id_usuario);
        const { questoes } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (questoes === "undefined") {
            return null;
        }
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listarQuestoes(id_atividade, id_usuario) {
        this.props.limparQuestoes()
        this.props.getQuestoes(id_atividade, id_usuario);
        const { questoes } = this.props;

        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (questoes === "undefined") {
            return null;
        }
    }


    alerta(icon, title) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,
            title: title
        })
    }

    checked(questoes, id_questao, id_opcao, value) {
        const { id_atividade, id_usuario } = this.state
        let checked = "";
        if (questoes.length > 0) {
            for (const cont in questoes) {
                if (questoes[cont].questao.id == id_questao) {
                    for (const cont2 in questoes[cont].opcoes) {
                        if (questoes[cont].opcoes[cont2].id == id_opcao) {
                            if (questoes[cont].opcoes[cont2].opcao == value) {
                                checked = "checked";
                            }
                        }
                    }
                }


            }
        }
        this.listarQuestoes(id_atividade, id_usuario)
        return checked;

    }

    checarFeito(id_atividade, id_usuario) {
        this.props.getValidarFeito(id_atividade, id_usuario);
        const { feito } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (feito === "undefined") {
            return null;
        }
    }

    onChangeInput = (field, ev) => {
        //////console.log(field, ev.target.value)
        this.setState({ [field]: ev.target.value });
    }

    cadRespostaTexto(id_questao, id_usuario) {
        const { n, resposta } = this.state
        this.setState({ carregando: true })
        this.props.postQuestaoEntrega({ id_questao, resposta, id_usuario }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ gabarito: msg.erro.gabarito })
                this.setState({ carregando: false });
                this.setState({ formSuccess: true })

            }
        }
        )
    }

    handleN(n, campo) {
        this.setState({ n: n + 1 })
        this.setState({ [campo]: "" })

    }

    salvar(id_questao, id_opcao, ev) {
        const { id_usuario, id_atividade, id_trilha, id_modulo, id_materia, step } = this.state;
        var id_curso = id_trilha
        const listaQuestoes = this.props.questoes;
        var resposta = ""
        if (this.props.questoes) {
            if (ev.target.checked == true) {
                resposta = resposta.concat(ev.target.value + ',')
            }
            this.setState({ id_questao });
            this.setState({ id_opcao });
            this.setState({ carregando: true });
            this.handleSelectedQuestion(id_questao)
        }
        ////console.log(resposta)
        this.props.postQuestaoEntrega({ id_questao, resposta, id_opcao, id_usuario }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ gabarito: msg.erro.gabarito })
                this.setState({ carregando: false });
                this.setState({ formSuccess: true })
                this.checked(listaQuestoes, id_questao, id_opcao, resposta)
            }
        }
        )
    }



    handlePage(step, action) {
        var questoes = []
        if (this.props.questoes) questoes = this.props.questoes.dados;

        if (action == "M") {
            this.setState({ step: step - 1 })
        } else if (step < questoes.length) {
            this.setState({ step: step + 1 })
        }
    }

    handleSelectedQuestion(id_questao_selecionada) {
        this.setState({ id_questao_selecionada: id_questao_selecionada })
    }

    handleAnswers(questoes) {
        var lista = questoes.dados
        const { splitado } = this.state
        //////console.log(lista)
        for (let i in lista) {
            if (lista[i].entrega) {
                splitado[i] = { id: lista[i].entrega.id_questao, respostas: lista[i].entrega.resposta.split(",") }
                //////console.log(lista[i].entrega, splitado)
            }
        }

    }

    checkAnswer(respostas, opcao) {
        let checked = ""
        //////console.log(respostas, opcao)
        for (let i in respostas) {
            if (respostas[i] == opcao) {
                checked = "checked"
            }
        }
        return checked

    }

    handleArray(id_questao, id_opcao, ev) {
        const { id_usuario, id_atividade, id_trilha, id_modulo, id_materia, step } = this.state;
        var id_curso = id_trilha
        const listaQuestoes = this.props.questoes;
        var array = ""
        if (this.props.questoes) {
            if (ev.target.checked == true) {
                array.concat(ev.target.value)
            }
            this.setState({ id_questao });
            this.setState({ id_opcao });
            this.setState({ carregando: true });
            this.handleSelectedQuestion(id_questao)
        }
        ////console.log(ev.target)
        this.setState({ resposta: array })
    }

    render() {
        // Form names
        const { gabarito, id_questao_selecionada, step, id_trilha, id_atividade, id_modulo, id_materia, idioma, dadosApi, listaQuestoes, resposta, splitado, resposta_texto, resposta_texto_longo, carregando, id_usuario, n, formSuccess } = this.state;
        var atividade = []
        if (this.props.atividade) atividade = this.props.atividade;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var trilha = [];
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = [];

        if (this.props.usuario) usuario = this.props.usuario;
        var questoes = []
        if (this.props.questoes) questoes = this.props.questoes.dados;

        var feito = []
        if (this.props.feito) feito = this.props.feito;

        var erro = { message: idioma.resumo.msg_erro }

        var success = { message: idioma.resumo.msg_sucesso }
        ////console.log(splitado, atividade, questoes, dadosApi)
        if (feito.message == true) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/ver",
            state: { msg: idioma.resumo.msg_info }
        }}></Redirect>
        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <h2 className="display-4 titulo">{idioma.resumo.titulo}</h2>
                    <Titulo titulo='Atividade' />
                </div>
                <div className="container-fluid bloco-topo">
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className=" mb-1 my-3 " ><b>{idioma.verAtividade.titulo}:</b> {atividade.nome}</div>
                                    <div className=" mb-1 my-3 " ><b>{idioma.verAtividade.desc}:</b><span dangerouslySetInnerHTML={{ __html: atividade.descricao }} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12">

                                    <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                                    {credenciais.map(credencial => ((credencial.id_componente == 52) ? usuario.id == trilha.id_autor ?
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/editar/"}>
                                            <Botao texto={idioma.resumo.bt_editar} icone="cogs" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} />
                                        </Link>
                                        : ""
                                        : ""))}
                                    {/* <h5 className="titulo-generico mb-1 mt-3 classes" >{atividade.nome}</h5> */}

                                    {dadosApi ?
                                        <div>

                                            {questoes.map(questao => (questao.entrega == null) ?
                                                <>
                                                    <Form className="mb-3">
                                                        <div key={questao.questao.id}>

                                                            {questao.questao.tipo_campo == "longa" ?
                                                                <div>
                                                                    <strong><h4><Label for="resposta">{questao.questao.titulo}</Label></h4></strong>
                                                                    <Input type="textarea" value={resposta} autoComplete="resposta" onClick={() => this.handleSelectedQuestion(questao.questao.id)} onChange={(ev) => this.onChangeInput("resposta", ev)} name="resposta" id="resposta" placeholder={idioma.resumo.resp} />

                                                                </div> :
                                                                questao.questao.tipo_campo == "curta" ?

                                                                    <div>
                                                                        <strong><h4><Label for="resposta">{questao.questao.titulo}</Label></h4></strong>
                                                                        <Input type="text" value={resposta} autoComplete="resposta" onClick={() => this.handleSelectedQuestion(questao.questao.id)} onChange={(ev) => { this.onChangeInput("resposta", ev) }} name="resposta" id="resposta" placeholder={idioma.resumo.resp} />

                                                                    </div>

                                                                    :
                                                                    questao.questao.tipo_campo == "multipla" ?
                                                                        <div>
                                                                            <strong><h4><Label for="resposta_texto">{questao.questao.titulo}</Label></h4></strong>

                                                                            {questao.opcoes.map(opcao => (
                                                                                <FormGroup check>
                                                                                    <Label check><Input type="checkbox" value={opcao.opcao} autoComplete="resposta" onChange={(ev) => {
                                                                                        this.handleArray(opcao.id_questao, opcao.id, ev)
                                                                                    }} name="resposta" id="resposta" key={opcao.id} />{opcao.opcao}</Label>
                                                                                </FormGroup>
                                                                            ))}
                                                                        </div> :
                                                                        questao.questao.tipo_campo == "unica" ?
                                                                            <div>
                                                                                <strong><h4><Label for="resposta_texto">{questao.questao.titulo}</Label></h4></strong>
                                                                                {questao.opcoes.map(opcao => (

                                                                                    <FormGroup check key={opcao.id}>
                                                                                        <Label check><Input type="radio" value={opcao.opcao} autoComplete="resposta" name={opcao.id_questao} id="resposta" onChange={(ev) => {
                                                                                            this.salvar(opcao.id_questao, opcao.id, ev)
                                                                                        }} key={opcao.id} />{opcao.opcao}</Label>
                                                                                    </FormGroup>
                                                                                ))}
                                                                            </div> : questao.questao.tipo_campo == null ? "" : ""}
                                                        </div>

                                                    </Form>
                                                </>

                                                :

                                                <Form className="mb-3">
                                                    <div key={questao.questao.id}>

                                                        {questao.questao.tipo_campo == "longa" ?
                                                            <div>
                                                                <strong><h4><Label for="resposta_texto">{questao.questao.titulo}</Label></h4></strong>
                                                                <Input type="textarea" value={questao.entrega.resposta} name="resposta" id="resposta" disabled />
                                                                <div className="row ml-1 mt-2">

                                                                    <Alert color={questao.entrega.resultado == "Errado" ? "danger" : "success"} >
                                                                        <span className="mr-2"><FontAwesomeIcon icon={questao.entrega.resultado == "Errado" ? "times" : "check"} ></FontAwesomeIcon></span>
                                                                        {questao.entrega.resultado == "Errado" ? idioma.resumo.msg_erro : idioma.resumo.msg_sucesso}
                                                                    </Alert>
                                                                </div>
                                                            </div> :
                                                            questao.questao.tipo_campo == "curta" ?
                                                                <div>
                                                                    <strong><h4><Label for="resposta_texto">{questao.questao.titulo}</Label></h4></strong>
                                                                    <Input type="text" value={questao.entrega.resposta} name="resposta_texto" id="resposta_texto" disabled />
                                                                    <div className="row ml-1 mt-2">

                                                                        <Alert color={questao.entrega.resultado == "Errado" ? "danger" : "success"} >
                                                                            <span className="mr-2"><FontAwesomeIcon icon={questao.entrega.resultado == "Errado" ? "times" : "check"} ></FontAwesomeIcon></span>
                                                                            {questao.entrega.resultado == "Errado" ? idioma.resumo.msg_erro : idioma.resumo.msg_sucesso}
                                                                        </Alert>
                                                                    </div>
                                                                </div> :
                                                                questao.questao.tipo_campo == "multipla" ?
                                                                    < div >

                                                                        <strong><h4><Label for="resposta_texto">{questao.questao.titulo}</Label></h4></strong>
                                                                        {questao.opcoes.map(opcao => (
                                                                            splitado.map(splitado => (opcao.id_questao == splitado.id) ?

                                                                                < FormGroup check >
                                                                                    <Label check><Input type="checkbox" value={opcao.opcao} autoComplete="resposta" onChange={(ev) => this.salvar(opcao.id_questao, opcao.id, ev)} name="resposta" id="resposta" checked={this.checkAnswer(splitado.respostas, opcao.opcao)} key={opcao.id} disabled />{opcao.opcao}</Label>
                                                                                </FormGroup>

                                                                                : "")
                                                                        ))}
                                                                        <div className="row ml-1 mt-2">

                                                                            <Alert color={questao.entrega.resultado == "Errado" ? "danger" : "success"} >
                                                                                <span className="mr-2"><FontAwesomeIcon icon={questao.entrega.resultado == "Errado" ? "times" : "check"} ></FontAwesomeIcon></span>
                                                                                {questao.entrega.resultado == "Errado" ? idioma.resumo.msg_erro : idioma.resumo.msg_sucesso}
                                                                            </Alert>
                                                                        </div>


                                                                    </div> :
                                                                    questao.questao.tipo_campo == "unica" ?
                                                                        <>
                                                                            <div>
                                                                                <strong><h4><Label for="resposta_texto">{questao.questao.titulo}</Label></h4></strong>
                                                                                {questao.opcoes.map(opcao => (opcao.id == questao.entrega.id_opcao) ?

                                                                                    <FormGroup check key={opcao.id}>
                                                                                        <Label check><Input type="radio" value={opcao.opcao} autoComplete="resposta" onChange={(ev) => this.salvar(opcao.id_questao, opcao.id, ev)} name={opcao.id} id="resposta" checked disabled key={opcao.id} />{opcao.opcao}</Label>
                                                                                    </FormGroup>
                                                                                    :
                                                                                    <FormGroup check key={opcao.id}>
                                                                                        <Label check><Input type="radio" value={opcao.opcao} autoComplete="resposta" onChange={(ev) => this.salvar(opcao.id_questao, opcao.id, ev)} name={opcao.id} id="resposta" disabled key={opcao.id} />{opcao.opcao}</Label>
                                                                                    </FormGroup>)}
                                                                            </div>
                                                                            <div className="row ml-1 mt-2">

                                                                                <Alert color={questao.entrega.resultado == "Errado" ? "danger" : "success"} >
                                                                                    <span className="mr-2"><FontAwesomeIcon icon={questao.entrega.resultado == "Errado" ? "times" : "check"} ></FontAwesomeIcon></span>
                                                                                    {questao.entrega.resultado == "Errado" ? idioma.resumo.msg_erro : idioma.resumo.msg_sucesso}
                                                                                </Alert>
                                                                            </div>
                                                                        </> : questao.questao.tipo_campo == null ? ""
                                                                            : ""}
                                                    </div>
                                                </Form>

                                            )}
                                        </div>
                                        : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}


                                    {step == questoes.length + 1 ?
                                        <Link to={"/trilhas/" + id_trilha + "/modulos/" + id_modulo + "/aulas"} onClick={() => {
                                            this.alerta("success", idioma.resumo.atv_finalizada)
                                        }}>
                                            <Botao texto={idioma.resumo.finalizar} icone="check" posicao="E" cor="success" />
                                        </Link>
                                        : ""}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    atividade: state.trilhas.atividade,
    questoes: state.trilhas.questoes,
    trilha: state.trilhas.trilha,
    feito: state.trilhas.feito

})

export default connect(mapStateToProps, actions)(Radar);