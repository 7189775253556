import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/astronaut.png'
import Skeleton from "react-loading-skeleton";
import { apiImagem } from '../../config/';

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';


import Titulo from '../../componentes/TituloPaginas';


class ListarEcossistema extends Component {
    state = {
        currentPage: 1,
        size: 6,
        incubadorasAtv: true,
        startupsAtv: false,
        incubadora: "",
        startup: "",
        procura: "",
        segmento: "",
        pesquisandoIncubadora: false,
        pesquisandoStartup: false,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;
        this.listarIncubadoras();
        this.listarStartups();

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.incubadorasAtivas && nextProps.incubadorasAtivas) this.listarIncubadoras();
        if (!this.props.startupsAtivas && nextProps.startupsAtivas) this.listarStartups();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparIncubadorasAtivas();
        this.props.limparStartupsAtivas();
    }
    receberDadosAPI() {
        if (this.state.pesquisandoIncubadora == false) {
            if (typeof this.props.incubadorasAtivas != "undefined" && this.props.incubadorasAtivas != null && !this.state.dadosApi && this.props.incubadorasAtivas.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        } else {
            if (typeof this.props.searchIncubadora != "undefined" && this.props.searchIncubadora != null && !this.state.dadosApi && this.props.searchIncubadora.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        }
        if (this.state.pesquisandoStartup == false) {
            if (typeof this.props.startupsAtivas != "undefined" && this.props.startupsAtivas != null && !this.state.dadosApi && this.props.startupsAtivas.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        } else {
            if (typeof this.props.searchStartup != "undefined" && this.props.searchStartup != null && !this.state.dadosApi && this.props.searchStartup.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarIncubadoras();
        })
    }
    changePageAtualPesquisandoIncubadora(currentPage) {
        const { procura } = this.state
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.handleSearchNomeIncubadora(procura);
        })
    }
    changePageAtualPesquisandoStartup(currentPage) {
        const { procura } = this.state
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.handleSearchNomeStartup(procura);
        })
    }

    changePageAtualStartup(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarStartups();
        })
    }

    apagarIncubadora(id) {
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarIncubadora(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarIncubadoras();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }

    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.modal_titulo,
            text: idioma.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.apagarIncubadora(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }

    listarIncubadoras() {
        const { currentPage, size } = this.state;
        this.props.getIncubadorasAtivas(currentPage, size)
        const { incubadoras } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadoras === "undefined") return null
    }

    listarStartups() {
        const { currentPage, size } = this.state;
        this.props.getStartupsAtivas(currentPage, size)
        const { startups } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startups === "undefined") return null
    }
    clicarMenu(info) {
        if (info == "incubadoras") {
            this.setState({ incubadorasAtv: true })
            this.setState({ startupsAtv: false })
        }
        if (info == "startups") {
            this.setState({ incubadorasAtv: false })
            this.setState({ startupsAtv: true })
        }
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleSearchNomeIncubadora(procura) {
        this.setState({ procura: procura })
        const { currentPage, size } = this.state;
        this.setState({ pesquisandoIncubadora: true })
        this.props.getSearchIncubadoras(procura, currentPage, size)
    }

    handleSearchNomeStartup(procura) {
        this.setState({ procura: procura })
        this.setState({ pesquisandoStartup: true })
        const { currentPage, size } = this.state;
        this.props.getSearchNameStartups(procura, currentPage, size)
    }

    handleSearchSegmento(segmento) {
        this.setState({ segmento: segmento })
        // const { currentPage, size } = this.state;
        // this.props.getSearchSegmento(segmento, currentPage, size)
    }

    eraseSearchIncubadora(campo) {
        this.setState({ pesquisandoIncubadora: false })
        this.setState({ [campo]: "" })


    }

    eraseSearchStartup(campo) {
        this.setState({ pesquisandoStartup: false })
        this.setState({ [campo]: "" })
    }


    handleEnter(ev, tipo) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            if (tipo == "Incubadora") {
                this.handleSearchNomeIncubadora(procura)
            } else {
                this.handleSearchNomeStartup(procura)
            }
        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive, el) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : this.props.usuario?.personalizacao.cor_principal
        ev.target.style.color = !isActive && this.props.usuario?.personalizacao?.cor_texto_menu
    }

    render() {
        const { pesquisandoIncubadora, pesquisandoStartup, procura, msg, carregando, erro, success, dadosApi, incubadorasAtv, startupsAtv, idioma, incubadorasHover, startupsHover } = this.state;
        const { usuario } = this.props;
        var incubadorasAtivas = [];
        var startupsAtivas = [];

        if (pesquisandoIncubadora == false) {
            if (this.props.incubadorasAtivas) incubadorasAtivas = this.props.incubadorasAtivas.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.incubadorasAtivas != "undefined" && this.props.incubadorasAtivas != null && this.props.incubadorasAtivas.currentPage !== "" && this.props.incubadorasAtivas.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.incubadorasAtivas != "undefined" && this.props.incubadorasAtivas !== null && this.props.incubadorasAtivas.nextPage <= this.props.incubadorasAtivas.totalPages && this.props.incubadorasAtivas.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }

        } else {
            if (this.props.searchIncubadora) incubadorasAtivas = this.props.searchIncubadora.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.searchIncubadora != "undefined" && this.props.searchIncubadora != null && this.props.searchIncubadora.currentPage !== "" && this.props.searchIncubadora.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.searchIncubadora != "undefined" && this.props.searchIncubadora !== null && this.props.searchIncubadora.nextPage <= this.props.searchIncubadora.totalPages && this.props.searchIncubadora.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        }

        if (pesquisandoStartup == false) {
            if (this.props.startupsAtivas) startupsAtivas = this.props.startupsAtivas.dados;
            var temPaginaAnteriorStartup = false;
            if (typeof this.props.startupsAtivas != "undefined" && this.props.startupsAtivas != null && this.props.startupsAtivas.currentPage !== "" && this.props.startupsAtivas.currentPage !== 1) {
                temPaginaAnteriorStartup = true;
            }
            var proximaPaginaStartup = false;
            var temProximaPaginaStartup = false;
            if (typeof this.props.startupsAtivas != "undefined" && this.props.startupsAtivas !== null && this.props.startupsAtivas.nextPage <= this.props.startupsAtivas.totalPages && this.props.startupsAtivas.nextPage != null) {
                proximaPaginaStartup = true;
                temProximaPaginaStartup = true;
            }

        } else {
            if (this.props.searchStartup) startupsAtivas = this.props.searchStartup.dados;
            var temPaginaAnteriorStartup = false;
            if (typeof this.props.searchStartup != "undefined" && this.props.searchStartup != null && this.props.searchStartup.currentPage !== "" && this.props.searchStartup.currentPage !== 1) {
                temPaginaAnteriorStartup = true;
            }
            var proximaPaginaStartup = false;
            var temPaginaAnteriorStartup = false;
            if (typeof this.props.searchStartup != "undefined" && this.props.searchStartup !== null && this.props.searchStartup.nextPage <= this.props.searchStartup.totalPages && this.props.searchStartup.nextPage != null) {
                proximaPaginaStartup = true;
                temPaginaAnteriorStartup = true;
            }
        }
        var semImagem = apiImagem + "fundo_startup.jpg";
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div id='incubadoras' style={{ backgroundColor: incubadorasAtv && usuario?.personalizacao?.cor_secundaria, color: incubadorasAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, incubadorasAtv)} className={incubadorasAtv ? "col-md-6 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-6 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("incubadoras")}>
                                                <FontAwesomeIcon id='incubadoras' icon="space-shuttle" size="1x" className="mr-3 icon-mouse-none" />
                                                {idioma.menu1}
                                            </div>
                                            <div id='startups' style={{ backgroundColor: startupsAtv && usuario?.personalizacao?.cor_secundaria, color: startupsAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, startupsAtv)} className={startupsAtv ? "col-md-6 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-md-6 menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("startups")}>
                                                <FontAwesomeIcon id='startups' icon="user-astronaut" size="1x" className="mr-3 icon-mouse-none" />
                                                {idioma.menu2}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <div className="col-lg-12">
                                <div className="tab-content container-fluid">
                                    <div className={incubadorasAtv ? "tab-pane p-3 active" : "tab-pane p-3"} id="incubadoras" role="tabpanel">
                                        <div className="row">
                                            <div className="col-lg-8">
                                                <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev, "Incubadora")} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisa_input} />
                                            </div>
                                            <div className="col-lg-4">
                                                <span className="pr-3" onClick={() => this.handleSearchNomeIncubadora(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="botao-pesquisa-responsivo" texto={idioma.bt_pesquisa} icone="search" posicao="E" /></span>
                                                <span value={procura} onClick={() => this.eraseSearchIncubadora("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" classes="botao-pesquisa-responsivo" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                            </div>
                                        </div>
                                        {dadosApi ?
                                            <div className="row">
                                                {incubadorasAtivas.length > 0 ?
                                                    incubadorasAtivas.map(incubadora => (
                                                        <div className="col-lg-4 col-md-4 col-sm-12" key={incubadora.id}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id}>
                                                                <div className="directory-card card">
                                                                    <div>
                                                                        <div className="directory-bg text-center" style={(incubadora.fileNameCapa !== null) ? { backgroundImage: `url(${apiImagem}${incubadora.fileNameCapa})` } : { backgroundImage: `url(${semImagem})` }}>
                                                                            <div className="directory-overlay">
                                                                                <img className="rounded-circle" src={incubadora.fileName ? apiImagem + incubadora.fileName : apiImagem + "astronaut.png"} width="120" height="120" ></img>
                                                                            </div>
                                                                        </div>
                                                                        <div className="directory-content text-center mt-3 ml-1 mr-1">
                                                                            <div className="titulo-startup">{incubadora.nome}</div>
                                                                            <p className="text-muted"><FontAwesomeIcon icon="map-marker-alt" color="black" className="mr-2" />{" "} {incubadora.cidade}</p>

                                                                            <span className="badge badge-pill badge-primary mb-1">{incubadora.segmento}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    ))
                                                    : ""}
                                            </div>
                                            : <div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="directory-card  card-startups">
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <div className="directory-skeleton-bg text-center">
                                                                        <div className="directory-overlay">
                                                                            <Skeleton circle={true} height={100} width={100} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="directory-content text-center">
                                                                        <div className="titulo-startup">
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                        <span className="texto-cidade">
                                                                            <Skeleton count={1} width={60} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="directory-card  card-startups">
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <div className="directory-skeleton-bg text-center">
                                                                        <div className="directory-overlay">
                                                                            <Skeleton circle={true} height={100} width={100} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="directory-content text-center">
                                                                        <div className="titulo-startup">
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                        <span className="texto-cidade">
                                                                            <Skeleton count={1} width={60} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="directory-card  card-startups">
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <div className="directory-skeleton-bg text-center">
                                                                        <div className="directory-overlay">
                                                                            <Skeleton circle={true} height={100} width={100} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="directory-content text-center">
                                                                        <div className="titulo-startup">
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                        <span className="texto-cidade">
                                                                            <Skeleton count={1} width={60} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>}
                                    </div>
                                    <div className={startupsAtv ? "tab-pane p-3 active" : "tab-pane p-3"} id="startups" role="tabpanel">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev, "Startup")} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisa_input} />
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <span className="pr-3" onClick={() => this.handleSearchNomeStartup(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisa} icone="search" posicao="E" /></span>
                                                    <span value={procura} onClick={() => this.eraseSearchStartup("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                                </div>
                                            </div>
                                        </div>
                                        {dadosApi ?
                                            <div className="row">
                                                {startupsAtivas.map(startup => (
                                                    <div className="col-lg-4 col-md-4 col-sm-12" key={startup.id}>
                                                        <Link to={"/ecossistema/startups/" + startup.id}>
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <div className="directory-bg text-center" style={(startup.fileNameCapa !== null) ? { backgroundImage: `url(${apiImagem}${startup.fileNameCapa})` } : { backgroundImage: `url(${semImagem})` }}>
                                                                        <div className="directory-overlay">
                                                                            <img className="rounded-circle" src={startup.fileName ? apiImagem + startup.fileName : apiImagem + "astronaut.png"} width="120" height="120" ></img>
                                                                        </div>
                                                                    </div>
                                                                    <div className="directory-content text-center mt-3 ml-1 mr-1">
                                                                        <div className="titulo-startup">{startup.nome}</div>
                                                                        <p className="text-muted"><FontAwesomeIcon icon="map-marker-alt" color="black" className="mr-2" />{" "} {startup.cidade}</p>
                                                                        <span className="badge badge-pill badge-primary mb-1">{startup.segmento}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                            : <div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="directory-card  card-startups">
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <div className="directory-skeleton-bg text-center">
                                                                        <div className="directory-overlay">
                                                                            <Skeleton circle={true} height={100} width={100} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="directory-content text-center">
                                                                        <div className="titulo-startup">
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                        <span className="texto-cidade">
                                                                            <Skeleton count={1} width={60} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="directory-card  card-startups">
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <div className="directory-skeleton-bg text-center">
                                                                        <div className="directory-overlay">
                                                                            <Skeleton circle={true} height={100} width={100} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="directory-content text-center">
                                                                        <div className="titulo-startup">
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                        <span className="texto-cidade">
                                                                            <Skeleton count={1} width={60} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                                        <div className="directory-card  card-startups">
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <div className="directory-skeleton-bg text-center">
                                                                        <div className="directory-overlay">
                                                                            <Skeleton circle={true} height={100} width={100} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="directory-content text-center">
                                                                        <div className="titulo-startup">
                                                                            <Skeleton count={1} width={80} />
                                                                        </div>
                                                                        <span className="texto-cidade">
                                                                            <Skeleton count={1} width={60} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>}
                                    </div>


                                </div>
                            </div>


                            {dadosApi && incubadorasAtv && !pesquisandoIncubadora ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.bt_pagina1}</span>
                                        </li>

                                        {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadorasAtivas.prevPage ? this.props.incubadorasAtivas.prevPage : 1)}>{this.props.incubadorasAtivas.prevPage ? this.props.incubadorasAtivas.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.incubadorasAtivas ? this.props.incubadorasAtivas.currentPage : "1"}</span></li>

                                        {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadorasAtivas.nextPage ? this.props.incubadorasAtivas.nextPage : 1)}>{this.props.incubadorasAtivas.nextPage ? this.props.incubadorasAtivas.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.incubadorasAtivas ? this.props.incubadorasAtivas.totalPages : 0)}>{idioma.bt_pagina2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                            {dadosApi && incubadorasAtv && pesquisandoIncubadora ?

                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoIncubadora(1)} >Primeira</span>
                                        </li>

                                        {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoIncubadora(this.props.searchIncubadora.prevPage ? this.props.searchIncubadora.prevPage : 1)}>{this.props.searchIncubadora.prevPage ? this.props.searchIncubadora.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.searchIncubadora ? this.props.searchIncubadora.currentPage : "1"}</span></li>

                                        {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoIncubadora(this.props.searchIncubadora.nextPage ? this.props.searchIncubadora.nextPage : 1)}>{this.props.searchIncubadora.nextPage ? this.props.searchIncubadora.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoIncubadora(this.props.searchIncubadora ? this.props.searchIncubadora.totalPages : 0)}>{idioma.bt_pagina2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                            {dadosApi && startupsAtv && !pesquisandoStartup ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorStartup ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualStartup(1)} >Primeira</span>
                                        </li>

                                        {temPaginaAnteriorStartup ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualStartup(this.props.startupsAtivas.prevPage ? this.props.startupsAtivas.prevPage : 1)}>{this.props.startupsAtivas.prevPage ? this.props.startupsAtivas.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.startupsAtivas ? this.props.startupsAtivas.currentPage : "1"}</span></li>

                                        {proximaPaginaStartup ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualStartup(this.props.startupsAtivas.nextPage ? this.props.startupsAtivas.nextPage : 1)}>{this.props.startupsAtivas.nextPage ? this.props.startupsAtivas.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPaginaStartup ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualStartup(this.props.startupsAtivas ? this.props.startupsAtivas.totalPages : 0)}>{idioma.bt_pagina2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                            {dadosApi && startupsAtv && pesquisandoStartup ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorStartup ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoStartup(1)} >{idioma.bt_pagina1}</span>
                                        </li>

                                        {temPaginaAnteriorStartup ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoStartup(this.props.searchStartup.prevPage ? this.props.searchStartup.prevPage : 1)}>{this.props.searchStartup.prevPage ? this.props.searchStartup.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.searchStartup ? this.props.searchStartup.currentPage : "1"}</span></li>

                                        {proximaPaginaStartup ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoStartup(this.props.searchStartup.nextPage ? this.props.searchStartup.nextPage : 1)}>{this.props.searchStartup.nextPage ? this.props.searchStartup.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPaginaStartup ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualPesquisandoStartup(this.props.searchStartup ? this.props.searchStartup.totalPages : 0)}>{idioma.bt_pagina2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    incubadorasAtivas: state.incubadoras.incubadorasAtivas,
    startupsAtivas: state.incubadoras.startupsAtivas,
    searchStartup: state.incubadoras.searchStartup,
    searchIncubadora: state.incubadoras.searchIncubadorasEcossistema,
    segmento: state.incubadoras.segmento

})
export default connect(mapStatetoProps, actions)(ListarEcossistema);