import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Col, Row, Form, FormGroup, Label, Input, Table } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { ChromePicker } from 'react-color';
import Titulo from '../../componentes/TituloPaginas';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

class NovaPersonalizacao extends Component {
  state = {
    titulo: "",
    logo: '',
    logo_dark: '',
    logoDark_preview: '',
    logoDark_size: '',
    logoPreview: '',
    logoSize: '',
    banner: '',
    bannerPreview: '',
    bannerSize: '',
    id_perfil: "",
    erro: "",
    success: "",
    carregando: "",
    formSuccess: false,
    cor_principal: '#fff',
    cor_secundaria: '#fff',
    cor_terciaria: '#fff',
    cor_quaternaria: '#fff',
    cor_texto_menu: '#FFF',
    cor_texto_menu_hover: '#FFF',
    cor_titulo: '#FFF',
    id_personalizacao: '',
    vinculo_ativo: false,
    id_vinculo: '',
    msg_backend: portuguese,
    idioma: portugues
  }

  componentDidMount() {
    const { usuario } = this.props;

    this.listarIncubadoras();

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }

  }

  listarIncubadoras(id_incub) {
    const { getIncubadorasAtivas } = this.props;

    getIncubadorasAtivas(1, 200);

  }

  onChnageInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }
  handlePersonalizar() {

    const { titulo, logo, banner, cor_principal, cor_secundaria, cor_terciaria, cor_quaternaria, cor_texto_menu, cor_titulo, logo_dark, cor_texto_menu_hover, msg_backend } = this.state;

    if (!this.validate()) return;
    const formData = new FormData();

    formData.append('titulo', titulo);
    formData.append('cor_principal', cor_principal);
    formData.append('cor_secundaria', cor_secundaria);
    formData.append('cor_terciaria', cor_terciaria);
    formData.append('cor_quaternaria', cor_quaternaria);
    formData.append('cor_texto_menu', cor_texto_menu);
    formData.append('cor_texto_menu_hover', cor_texto_menu_hover);
    formData.append('cor_titulo', cor_titulo);
    formData.append('logo', logo);
    formData.append('logo_dark', logo_dark);
    formData.append('banner', banner);


    this.setState({ carregando: true })
    this.props.postPersonalizacao(formData, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.setState({ id_personalizacao: msg.erro.id })
        this.setState({ vinculo_ativo: true });
      }
    }
    )

  }

  handleCriarVinculo(id_incubadora) {
    const { postVinculoPersonalizacao } = this.props;
    const { id_personalizacao } = this.state;

    this.setState({ carregando: true })
    const novoVinculo = {
      id_incubadora,
      id_personalizacao
    }

    postVinculoPersonalizacao(novoVinculo, (msg) => {
      const { msg_backend } = this.state;
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.listarIncubadoras(id_incubadora);
      }
    }
    )

  }

  onChangeInputFile(campo, ev) {
    this.setState({ [campo]: ev.target.files[0] })

    const file = ev.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      // fileReader.readAsDataURL(file);
      fileReader.readAsDataURL(file)
      if (campo === 'logo') {
        fileReader.addEventListener('load', () => this.setState({ logoPreview: fileReader.result }))
        const logo = document.getElementById('logo-preview');
        logo.onload = () => this.setState({ logoSize: `${logo.offsetWidth} x ${logo.offsetHeight}` })
      } else if (campo === 'logo_dark') {
        fileReader.addEventListener('load', () => this.setState({ logoDark_preview: fileReader.result }))
        const logoDark = document.getElementById('logoDark-preview');
        logoDark.onload = () => this.setState({ logoDark_size: `${logoDark.offsetWidth} x ${logoDark.offsetHeight}` })
      } else {
        fileReader.addEventListener('load', () => this.setState({ bannerPreview: fileReader.result }))
        const banner = document.getElementById('banner-preview');
        banner.onload = () => this.setState({ bannerSize: `${banner.offsetWidth} x ${banner.offsetHeight}` })
      }
    }


  }

  validarTitulo(value) {
    return value.indexOf(' ') >= 0;
  }

  validate() {
    const { titulo, idioma } = this.state;
    const tituloInvalido = this.validarTitulo(titulo);
    if (!titulo) return this.setState({ erro: { message: idioma.novaPersonalizacao.erro_titulo } });
    if (tituloInvalido) return this.setState({ erro: { message: idioma.novaPersonalizacao.erro_titulo2 } })
    return true;
  }
  render() {
    const { titulo, erro, success, carregando, iconSalvar, vinculo_ativo, id_personalizacao, idioma } = this.state;

    if (vinculo_ativo) return <Redirect to={{
      pathname: `/personalizacao/${id_personalizacao}/editar/vincular`,
      state: { msg: idioma.novaPersonalizacao.msg_sucesso }
    }}></Redirect>
    return (
      <>
        {/* Recuperar dimensões da imagem */}
        <img id='logo-preview' alt='' src={this.state.logoPreview} style={{ position: 'absolute', left: '-999em' }} />
        <img id='logoDark-preview' alt='' src={this.state.logoDark_preview} style={{ position: 'absolute', left: '-999em' }} />
        <img id='banner-preview' alt='' src={this.state.bannerPreview} style={{ position: 'absolute', left: '-999em' }} />

        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.novaPersonalizacao.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>

              <div>
                <AlertaErro erros={erro} />
                <AlertaSuccess erros={success} />
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.personalizacao.titulo2}</Label>
                    <Input type="text" value={titulo} autoComplete="titulo" onChange={(ev) => this.onChnageInput("titulo", ev)} name="titulo" id="titulo" placeholder={idioma.personalizacao.titulo2} />
                  </FormGroup>
                  <Col md={6}>
                    <FormGroup>
                      <img src={this.state.logoPreview} alt="..." class="img-thumbnail mr-3" style={{ width: "100px" }} />
                      <Label for="logo">Logo</Label>
                      <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resolucao} 500 x 300</p>
                      {this.state.logoSize && <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resol_atual} {this.state.logoSize}</p>}
                      <Input type="file" id="logo" name="file" accept='image/png, image/jpeg, image/jpg' className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("logo", ev)} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <img src={this.state.logoDark_preview} alt="..." class="img-thumbnail mr-3" style={{ width: "100px" }} />
                      <Label for="logo_dark">{idioma.novaPersonalizacao.logo_dark}</Label>
                      <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resolucao} 500 x 300</p>
                      {this.state.logoDark_size && <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resol_atual} {this.state.logoDark_size}</p>}
                      <Input type="file" id="logo_dark" name="file" accept='image/png, image/jpeg, image/jpg' className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("logo_dark", ev)} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <img src={this.state.bannerPreview} alt="..." class="img-thumbnail mr-3" style={{ width: "100px" }} />
                      <Label for="banner">Banner</Label>
                      <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resolucao} 1400 x 900</p>
                      {this.state.bannerSize && <p style={{ fontSize: '12px' }}>{idioma.novaPersonalizacao.resol_atual} {this.state.bannerSize}</p>}
                      <Input type="file" id="banner" name="banner" accept='image/png, image/jpeg, image/jpg' className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("banner", ev)} />
                    </FormGroup>
                  </Col>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor-primaria">{idioma.novaPersonalizacao.cor_pri}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_pri_info}</p>
                        <ChromePicker id="cor-primaria" disableAlpha color={this.state.cor_principal} onChangeComplete={(color) => this.setState({ cor_principal: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor-secundaria">{idioma.novaPersonalizacao.cor_sec}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_sec_info}</p>
                        <ChromePicker id="cor-secundaria" disableAlpha color={this.state.cor_secundaria} onChangeComplete={(color) => this.setState({ cor_secundaria: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor-terciaria">{idioma.novaPersonalizacao.cor_ter}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_ter_info}</p>
                        <ChromePicker id="cor-terciaria" disableAlpha color={this.state.cor_terciaria} onChangeComplete={(color) => this.setState({ cor_terciaria: color.hex })} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor-quaternaria">{idioma.novaPersonalizacao.cor_qua}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_qua_info}</p>
                        <ChromePicker id="cor-quaternaria" disableAlpha color={this.state.cor_quaternaria} onChangeComplete={(color) => this.setState({ cor_quaternaria: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor_texto_menu">{idioma.novaPersonalizacao.cor_tex}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_tex_info}</p>
                        <ChromePicker id="cor_texto_menu" disableAlpha color={this.state.cor_texto_menu} onChangeComplete={(color) => this.setState({ cor_texto_menu: color.hex })} />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor_texto_menu">{idioma.novaPersonalizacao.cor_tex_sec}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_tex_sec_info}</p>
                        <ChromePicker id="cor_texto_menu" disableAlpha color={this.state.cor_texto_menu_hover} onChangeComplete={(color) => this.setState({ cor_texto_menu_hover: color.hex })} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="cor_titulo">{idioma.novaPersonalizacao.cor_titu}</Label>
                        <p style={{ fontSize: '11px' }}>{idioma.novaPersonalizacao.cor_titu_info}</p>
                        <ChromePicker id="cor_titulo" disableAlpha color={this.state.cor_titulo} onChangeComplete={(color) => this.setState({ cor_titulo: color.hex })} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Link to="#" onClick={() => this.handlePersonalizar()}> <Botao loading={carregando} texto={idioma.novaPersonalizacao.bt_continuar} cor='success' icone={iconSalvar} /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.novaPersonalizacao.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  usuario: state.auth.usuario,
  personalizacao: state.incubadoras.personalizacaoIncubadora
})

export default connect(mapStateToProps, actions)(NovaPersonalizacao);