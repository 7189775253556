import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/conteudos';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row, Card } from 'reactstrap';
import BlocoPerfil from '../../containers/blocos/perfilConteudo';

import Botao from '../../componentes/Botoes';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import parse from 'html-react-parser'
import descricao from '../Trilhas/descricao';
import Skeleton from "react-loading-skeleton";

import { portugues, ingles } from '../../utils/paginaconteudo_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';


class EditarConteudo extends Component {
    state = {
        id_conteudo: "",
        id_usuario: "",
        titulo: "",
        descricao: "",
        id_tipo: "",
        fonte: "",
        previa: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        editorState: "",
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_conteudo } = this.props.match.params;
        this.props.getVerConteudo(id_conteudo);
        this.props.getTipoConteudo();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }


    async componentDidUpdate(nextProps) {
        const { id_conteudo } = this.props.match.params;
        if (!this.props.conteudo && nextProps.conteudo) this.props.getVerConteudo(id_conteudo);
        await this.receberDadosAPI();
    }


    componentWillUnmount() {
        this.props.limparConteudo();
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onEditorStateChangeInicial = (descricao) => {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(descricao)
                )

            ),
        })
    }


    receberDadosAPI() {
        const { id_conteudo } = this.props.match.params;
        if (typeof this.props.conteudo !== "undefined" && this.props.conteudo !== null && this.props.conteudo.id == id_conteudo && !this.state.dadosAPI) {
            this.setState({ id_conteudo: this.props.conteudo.id })
            this.setState({ titulo: this.props.conteudo.titulo })
            this.setState({ descricao: this.props.conteudo.descricao })
            this.setState({ id_tipo: this.props.conteudo.id_tipo })
            this.setState({ id_usuario: this.props.conteudo.id_usuario })
            this.setState({ previa: this.props.conteudo.previa })
            this.setState({ fonte: this.props.conteudo.fonte })
            this.onEditorStateChangeInicial(this.props.conteudo.descricao)
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    async atualizarConteudo(id_conteudo, id_usuario, titulo, editorState, id_tipo, previa, fonte) {
        const { msg_backend } = this.state
        this.setState({ success: "" });
        this.setState({ erro: "" });
        var id = id_conteudo
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putConteudo({ id, titulo, descricao, id_tipo, id_usuario, previa, fonte }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    validate() {
        const { titulo, descricao, id_tipo, erro, idioma } = this.state;
        if (!titulo) return this.setState({ erro: { message: idioma.editarConteudo.erro_titulo } });
        if (!descricao) return this.setState({ erro: { message: idioma.editarConteudo.erro_desc } });
        if (!id_tipo) return this.setState({ erro: { message: idioma.editarConteudo.erro_tipo } });
        return true;
    }
    render() {
        const { id_conteudo, id_usuario, titulo, fonte, previa, descricao, editorState, id_tipo, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;
        var tipos = [];
        var conteudo = null;
        if (this.props.conteudo) conteudo = this.props.conteudo;
        if (this.props.tipos) tipos = this.props.tipos.dados;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        var tipos = [
            { id: 2, nome: idioma.conteudos },
            { id: 3, nome: idioma.insights },
            { id: 1, nome: idioma.noticias },
        ]
        ////////console.log(editorState)
        if (formSuccess) return <Redirect to={{
            pathname: "/conteudo/" + id_conteudo,
            state: { msg: idioma.editarConteudo.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editarConteudo.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {conteudo != null ?
                        <BlocoPerfil conteudo={conteudo} dadosApi={dadosAPI} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" >
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    <Form>
                                        <Input type="hidden" value={id_conteudo} id="id_conteudo" name="id_conteudo" />
                                        <Input type="hidden" value={id_usuario} id="id_usuario" name="id_usuario" />

                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="titulo">{idioma.editarConteudo.input_titulo}</Label>
                                                    <Input type="text" value={titulo} id="titulo" name="titulo" className="form-control" autoComplete="titulo" placeholder={dadosAPI ? idioma.editarConteudo.input_titulo2 : idioma.editarConteudo.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("titulo", ev)} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Label for="id_tipo">{idioma.editarConteudo.tipo}</Label>
                                                <Input type="select" onChange={(ev) => this.onChangeInput("id_tipo", ev)} id="id_tipo" name="id_tipo" placeholder={dadosAPI ? idioma.editarConteudo.tipo : idioma.editarConteudo.carregando} disabled={dadosAPI ? false : true}>
                                                    {credenciais.length > 0 && tipos.length > 0 ?
                                                        credenciais.map(credencial => ((credencial.id_componente === 68 && tipos[0].id === 2) ?
                                                            <option value={tipos[0].id}>
                                                                {tipos[0].nome}
                                                            </option>
                                                            : ""))
                                                        : ""}
                                                    {credenciais.length > 0 && tipos.length > 0 ?

                                                        credenciais.map(credencial => ((credencial.id_componente === 67 && tipos[2].id === 1) ?
                                                            <option value={tipos[2].id}>
                                                                {tipos[2].nome}
                                                            </option>
                                                            : ""))
                                                        : ""}

                                                    {credenciais.length > 0 && tipos.length > 0 ?

                                                        credenciais.map(credencial => ((credencial.id_componente === 69 && tipos[1].id === 3) ?
                                                            <option value={tipos[1].id}>
                                                                {tipos[1].nome}
                                                            </option>
                                                            : "")) : ""}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="fonte">{idioma.editarConteudo.fonte}</Label>
                                            <Input type="text" value={fonte} id="fonte" name="fonte" className="form-control" autoComplete="fonte" placeholder={dadosAPI ? idioma.editarConteudo.fonte2 : idioma.editarConteudo.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("fonte", ev)} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="previa">{idioma.editarConteudo.previa}</Label>
                                            <Input type="text" value={previa} id="previa" name="previa" className="form-control" autoComplete="previa" placeholder={dadosAPI ? idioma.editarConteudo.previ2 : idioma.editarConteudo.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("previa", ev)} />
                                        </FormGroup>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="descricao">{idioma.editarConteudo.desc}</Label>
                                                    <Card className="card-editor">
                                                        {dadosAPI ?
                                                            <div>
                                                                <Editor
                                                                    editorState={editorState}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    onEditorStateChange={this.onEditorStateChange}

                                                                />
                                                            </div>
                                                            : ""}

                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Link onClick={() => this.atualizarConteudo(id_conteudo, id_usuario, titulo, editorState, id_tipo, previa, fonte)} to="#">
                                            <Botao loading={carregando} texto={idioma.editarConteudo.bt_salvar} classes="botao-responsivo botao-estilo" />
                                        </Link>

                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.editarConteudo.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    conteudo: state.conteudos.conteudo,
    tipos: state.conteudos.tipoConteudo


})
export default connect(mapStateToProps, actions)(EditarConteudo)