import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/login/index.css';

class BaseLogin extends React.Component {
    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
}
export default BaseLogin;