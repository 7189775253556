export const saveToken = (usuario) => {
    if (!usuario.token) return null;
    const [tk1, tk2, tk3] = usuario.token.split('.');
    localStorage.setItem("lblValue1", tk1);
    localStorage.setItem("lblValue2", tk2);
    localStorage.setItem("lblValue3", tk3);
}

export const clearToken = () => {
    localStorage.removeItem("lblValue1");
    localStorage.removeItem("lblValue2");
    localStorage.removeItem("lblValue3");
}

export const getToken = () => {
    const tk1 = localStorage.getItem("lblValue1");
    const tk2 = localStorage.getItem("lblValue2");
    const tk3 = localStorage.getItem("lblValue3");
    if (!tk1 || !tk2 || !tk3) return null;
    return `${tk1}.${tk2}.${tk3}`;
}

export const getHeaders = () => {
    return {
        'headers': {
            'Authorization': `Bearer ${getToken()}`,
        }
    }
}

export const getHeadersPost = () => {
    return {
        'headers': {
            'Authorization': `Bearer ${getToken()}`,
        }
    }
}