export const portugues = {
  titulo: 'Trilhas',
  todos: 'Todos os cursos',
  matriculado: 'Matriculado',
  cursos: 'Meus cursos',
  gerenciar: 'Gerenciar cursos',
  bt_cadastrar: 'Cadastrar Trilha',
  pesquisar: 'Pesquisar...',
  bt_pesquisar: 'Pesquisar',
  bt_cancelar: 'Cancelar',
  bt_deletar: 'Deletar',
  acesso: 'Acessar trilha',
  sem_dados: 'Não há trilhas para exibir',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  privado: 'Que pena! Essa trilha é privada.',
  conteudo_privado: 'Conteúdo bloqueado.',
  modal_title: 'Você tem certeza?',
  modal_texto: 'Não será possível restaurar essa incubadora!',
  bt_salvar: 'Salvar',
  modal_sucesso: 'Ambiente de inovação excluído com sucesso!',
  page1: 'Primeira',
  page2: 'Última',
  bt_voltar: 'Voltar',
  erro_dados: 'Não há aulas ou atividades para exibir',
  aulas: {
    modal_info: 'Não será possível restaurar essa aula!',
    modal_confirm: 'Sim, excluir esta aula!',
    modal_sucesso: 'Aula excluída com sucesso!',
    titulo: 'Aulas',
    assist: 'Aula ',
    em: 'em ',
    materias: 'Matérias e aulas',
    conteudo: 'Conteúdo',
    materia: 'Matéria',
    bt_editar: 'Editar',
    aula: 'Aula',
    aulas: 'Aulas',
    atividade: 'Atividade',
    atividades: 'Atividades',
    edit_aula: 'Editar',
    edit_atividade: 'Editar',
    erro_dados: 'Selecione uma aula da lista de aulas',
    erro_materias: 'Não há matérias cadastradas',
    voltar: 'Conteúdo anterior',
    prox: 'Próximo conteúdo'
  },
  autores: {
    modal_titulo: 'Deseja deletar o vínculo desse autor?',
    modal_info: 'Ao fazer isso você concorda na exclusão do vínculo desse autor com a trilha',
    modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
    modal_erro: 'Mensagem de confirmação inválida',
    confirm: 'CONFIRMAR',
    modal_sucesso: 'Vínculo excluído com sucesso!',
    titulo: 'Autores',
    bt_vincular: 'Vincular autor',
    nome: 'Autores',
    acao: 'Ação',
    bt_excluir: 'Excluir vinculo'
  },
  cadastrar: {
    erro_nome: 'Preencha o nome',
    erro_data: 'Preencha a data de publicação da trilha',
    msg_sucesso: 'Trilha cadastrada com sucesso',
    titulo: 'Cadastrar trilha',
    nome: 'Nome',
    nome2: 'Nome da trilha',
    visibilidade: 'Visibilidade do curso',
    escolher: 'Escolher a visibilidade do curso',
    publico: 'Público',
    privado: 'Privado',
    previa: 'Prévia da trilha',
    data: 'Data de publicação',
    desc: 'Descrição da Trilha'
  },
  cadastrarAtividade: {
    erro_duracao: 'Preencha a duração',
    erro_nome: 'Preencha o nome',
    erro_modulo: 'Selecione o módulo',
    erro_materia: 'Selecione uma matéria',
    erro_ordem: 'Preencha a ordem',
    erro_descricao: 'Preencha a descrição',
    erro_data_publicacao: 'Selecione uma data de publicação',
    msg_sucesso: "Módulo cadastrado com sucesso!",
    titulo: 'Cadastrar nova atividade',
    nome: 'Nome da atividade',
    tarefa: 'Tempo da atividade',
    tarefa2: 'Duração em minutos da atividade',
    selecione: 'Selecione a ordem',
    desc: 'Descrição da atividade',
    modulo: 'Módulo',
    escolher: 'Escolher módulo',
    materia: 'Matéria',
    escolher2: 'Escolher matéria',
    data: 'Data de publicação',
    bt_cadastrar: 'Cadastrar atividade'
  },
  cadastrarAula: {
    msg_sucesso: 'Aula cadastrada com sucesso!',
    titulo: 'Cadastrar nova aula',
    nome: 'Nome',
    nome2: 'Nome da aula',
    selecione_ord: 'Selecione a ordem',
    selecione_dur: 'Selecione a duração da aula',
    duracao: 'Duração em minutos',
    modulo: 'Módulo',
    escolher_mod: 'Escolher o módulo',
    data_pub: 'Data da publicação',
    materia: 'Matéria',
    escolher_mat: 'Escolher matéria',
    tipo_aula: 'Tipo da aula',
    escolher_aul: 'Escolher o tipo',
    link: 'Link (Youtube apenas)',
    conteudo_aul: 'Conteúdo da aula',
    bt_cadastar: 'Cadastrar aula',
    doc: 'Documento',
    link2: 'Link',
    link2_place: 'Link da aula (somente Spotify)',
    outros: 'Outros',
    pdf: 'PDF',
    podcast: 'Podcast',
    texto: 'Texto',
    video: 'Vídeo'
  },
  cadastrarMateria: {
    msg_sucesso: 'Matéria cadastrado com sucesso!',
    titulo: 'Cadastrar nova matéria',
    nome: 'Nome',
    nome2: 'Nome da matéria',
    selecione_ord: 'Selecione a ordem',
    ordem_mat: 'Ordem da matéria',
    conteudo_mat: 'Conteúdo da matéria',
    bt_cadastrar: 'Cadastrar matéria'
  },
  cadastrarModulo: {
    erro_nome: 'Preencha o nome',
    erro_ordem: 'Preencha a ordem',
    msg_sucesso: 'Módulo cadastrado com sucesso!',
    titulo: 'Cadastrar novo módulo',
    nome_mod: 'Nome do módulo',
    ordem_mod: 'Selecione a ordem do módulo'
  },
  cadastrarQuestoes: {
    titulo: 'Cadastrar nova questão',
    nome_quest: 'Nome da questão',
    selecione_ord: 'Selecione a ordem',
    ordem_ques: 'Ordem da questão',
    tipo_quest: 'Tipo da questão',
    escolher_tipo: 'Escolher o tipo da questão',
    mutipla: 'Multipla escolha',
    unica: 'Escolha única',
    curta: 'Resposta curta',
    paragrafo: 'Resposta parágrafo',
    descreva: 'Descreva a opção',
    ordem_op: 'Ordem da opção',
    opcao: 'Opção',
    bt_cadastrar: 'Cadastrar opção',
    bt_cancelar: 'Cancelar opção',
    bt_finalizar: 'Finalizar cadastro'
  },
  config: {
    modal_confirm: 'Sim, desativar esta trilha!',
    modal_confirm_ativar: 'Sim, ativar esta trilha!',
    msg_sucesso: 'Trilha desativada com sucesso!',
    modal_titulo: 'Deseja deletar a trilha?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de participantes, módulos, aulas e atividades da trilha. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_confirm2: 'Digite CONFIRMAR para realizar a exclusão',
    confirm: 'CONFIRMAR',
    modal_erro: 'Mensagem de confirmação inválida!',
    modal_sucesso: 'Trilha excluída com sucesso!',
    modal_confirm3: 'Sim, duplicar esta trilha!',
    modal_sucesso2: 'Trilha duplicada com sucesso!',
    titulo: 'Configurações',
    bt_cad_materia: 'Cadastrar matéria',
    bt_cad_modulo: 'Cadastrar módulo',
    bt_cad_aula: 'Cadastrar aula',
    bt_cad_atividade: 'Cadastrar atividade',
    autores: 'Autores',
    bt_edit_trilha: 'Editar trilha',
    bt_dup_trilha: 'Duplicar trilha',
    bt_edit_capa: 'Editar capa',
    bt_desativar: 'Desativar',
    bt_ativar: 'Ativar'
  },
  conteudos: {
    titulo: 'Módulos',
    bt_mod: 'Módulo',
    nome: 'Nome',
    progre: 'Progresso',
    inscreva: 'Inscreva-se para acessar',
    acao: 'Ação',
    bt_visu: 'Visualizar',
    bt_editar: 'Editar'
  },
  descricao: {
    titulo: 'Trilha',
    sem_dados: 'Descrição',
    sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  },
  editarAtividade: {
    modal_titulo: 'Deseja deletar a questão?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de opções. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
    confirm: 'CONFIRMAR',
    modal_erro: 'Mensagem de confirmação inválida!',
    modal_sucesso: 'Questão excluída com sucesso!',
    modal_titulo2: 'Deseja deletar a atividade?',
    modal_sucesso2: 'Atividade excluída com sucesso!',
    titulo_que: 'Título da questão: ',
    ordem: 'Ordem da questão: ',
    tipo_quest: 'Tipo da questão: ',
    paragrafo: 'Resposta parágrafo',
    curta: 'Resposta curta',
    multipla: 'Mútipla escolha',
    unica: 'Escolha única',
    gabaritos: 'Gabarito: ',
    falta_info: 'Falta de informação para o cadastro!',
    bt_fechar: 'Fechar',
    quest_sucesso: 'Questão cadastrada com sucesso',
    msg_sucesso: 'Edição realizada com sucesso!',
    titulo: 'Editar aula',
    carregando: 'Carregando...',
    nome: 'Nome',
    nome_mate: 'Nome da matéria',
    tempo_atv: 'Duração da atividade',
    sel_ordem: 'Selecione a ordem',
    ordem_atv: 'Ordem da matéria',
    sel_publi: 'Selecione a data de publicação',
    data_pub: 'Data de publicação',
    conteudo: 'Conteúdo da atividade',
    bt_cadastrar: 'Cadastrar questões',
    tipo: 'Tipo',
    status: 'Status',
    acao: 'Ação',
    sem_tipo: 'Não possui tipo',
    ativo: 'Ativo',
    desat: 'Desativada',
    ver_op: 'Ver opções'
  },
  editarAula: {
    modal_titulo: 'Deseja deletar a aula?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de aula. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_sucesso: 'Aula excluída com sucesso!',
    msg_sucesso: 'Edição realizada com sucesso!',
    titulo: 'Editar aula',
    nome_aula: 'Nome da aula',
    ordem_aula: 'Ordem da aula',
    duracao_aula: 'Duração da aula',
    materia: 'Materia',
    sel_materia: 'Escolher matéria',
    tipo_aula: 'Tipo da aula',
    sel_tipo: 'Escolher tipo',
    link: 'Link (YouTube apenas)',
    doc_aula: 'Documento de entrega',
    conteudo: 'Conteúdo da aula',
    link2: 'Link da aula (somente Spotify)'
  },
  editarCapa: {
    msg_sucesso: 'Entrega realizada com sucesso!',
    titulo: 'Editar capa',
    nava_capa: 'Nova capa',
    bt_enviar: 'Enviar'
  },
  editarMateria: {
    modal_titulo: 'Deseja deletar a matéria?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de aulas, progresso e atividades da matéria. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_sucesso: 'Matéria excluída com sucesso!',
    msg_sucesso: 'Edição realizada com sucesso!',
    bt_editar: 'Editar matéria',
    ordem_mat: 'Ordem da matéria',
    desc_materia: 'Descrição da materia',
    bt_deletar: 'Deletar materia'
  },
  editarModulo: {
    modal_titulo: 'Deseja deletar o módulo?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de matérias, aulas, progresso e atividades da matéria. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_erro: 'Mensagem de confirmação inválida!',
    modal_sucesso: 'Módulo excluído com sucesso!',
    msg_sucesso: "Módulo editado com sucesso!",
    titulo: 'Editar Módulo',
    nome_mod: 'Nome do módulo',
    ordem: 'Ordem',
    desc_modulo: 'Descrição do módulo',
    bt_deletar: 'Deletar módulo'
  },
  editarQuestao: {
    titulo_op: 'Título da opção: ',
    ordem_op: 'Ordem da opção: ',
    titulo_info: 'Falta de informações para o cadastro!',
    bt_fechar: 'Fechar',
    modal_sucesso: 'Opção cadastrada com sucesso!',
    titulo: 'Editar questão',
    titulo_inp: 'Título',
    titulo_que: 'Título da questão',
    tipo_campo: 'Tipo do campo',
    bt_cadastar: 'Cadastrar opções',
    titulo_input: 'Título',
    bt_del: 'Deletar questão'
  },
  editarTrilha: {
    erro_nome: 'Preencha o nome',
    msg_sucesso: 'Trilha editada com sucesso!',
    titulo: 'Editar trilha',
    nome: 'Nome',
    nome_trilha: 'Nome da trilha',
    visibilidade: 'Visibilidade do curso',
    sel_visibilidade: 'Escolher a visibilidade do curso',
    publico: 'Público',
    privado: 'Privado',
    previa: 'Prévia',
    descricao: 'Descrição'
  },
  participantes: {
    modal_titulo: 'Deseja deletar o vínculo desse participante?',
    modal_info: 'Ao fazer isso você concorda na exclusão do vínculo desse participante com a trilha',
    modal_sucesso: 'Vínculo excluído com sucesso!',
    titulo: 'Participantes',
    bt_vincular: 'Vincular participante',
    bt_conversar: 'Conversar',
    nome: 'Nome',
    perfil: 'Perfil',
    acoes: 'Ações',
    nao_possui: 'Não possui perfil',
    bt_excluir: 'Excluir vínculo',
    nota: 'Nota'
  },
  resumo: {
    msg_erro: 'Resposta incorreta!',
    msg_sucesso: 'Resposta certa!',
    msg_info: 'Ainda existem exercícios pendentes!',
    titulo: 'Atividade',
    bt_editar: 'Editar atividade',
    resp: 'Resposta',
    finalizar: 'Finalizar atividade',
    atv_finalizada: 'Atividade finalizada'
  },
  verAtividade: {
    resp_incorreta: 'Resposta incorreta',
    resp_correta: 'Resposta correta',
    titulo: 'Atividade',
    desc: 'Descrição',
    quest: 'Questão',
    resp: 'Resposta',
    prox: 'Próxima',
    ante: 'Anterior',
    bt_salvar: "Salvar",
    atv_fim: 'Atividade finalizada',
    bt_finalizar: 'Finalizar atividade',
    bt_editar: 'Editar atividade',
  },
  vincularAutor: {
    modal_titulo: 'Você tem certeza?',
    modal_info: 'Sim, vincular este participante!',
    modal_sucesso: 'Autor vinculado com sucesso!',
    msg_sucesso: 'Autor vinculado com sucesso!',
    titulo: 'Vincular Autores',
    bt_cancelar: 'Cancelar filtro',
    bt_filtrar: 'Filtrar',
    tipo_filtro: 'Escolha o tipo do filtro',
    ambiente: 'Ambiente de inovação',
    negocio: 'Negócio',
    perfil: 'Perfil',
    escolha_filtro: 'Escolha um filtro antes',
    escolha_amb: 'Escolha o ambiente de inovação',
    escolha_neg: 'Escolha o negócio',
    escolha_perfil: 'Escolha o perfil',
    escolha_info: 'Escolha o perfil, ambiente de inovação ou negócio',
    bt_pesquisar: 'Pesquisar',
    nome: 'Nome',
    acao: 'Ação',
    bt_vincular: 'Vincular',
    page1: 'Primeira',
    page2: 'Última'
  },
  vincularParticipante: {
    modal_confirm: 'Sim, vincular este participante!',
    modal_sucesso: 'Participante vinculado com sucesso!',
    msg_sucesso: 'Trilha cadastrada com sucesso',
    titulo: 'Vincular Participantes'
  }
}

export const ingles = {
  titulo: 'Training',
  todos: 'All courses',
  matriculado: 'Registered',
  cursos: 'My courses',
  gerenciar: 'Courses management',
  bt_cadastrar: 'Register Training',
  pesquisar: 'Search...',
  bt_pesquisar: 'Search',
  bt_cancelar: 'Cancel',
  bt_deletar: 'Delete',
  acesso: 'Access training',
  sem_dados: 'There are no Trainings to show!',
  sem_dados_sub: 'What a pity! There is no information to display.',
  privado: 'What a pity! This training is private.',
  conteudo_privado: 'Blocked content.',
  modal_title: 'Are you sure?',
  modal_texto: "Won't be possible to restore this incubator!",
  bt_salvar: 'Save',
  modal_sucesso: 'Innovation space successfully deleted',
  page1: 'First',
  page2: 'Last',
  bt_voltar: 'Back',
  erro_dados: 'There are no classes or activities to display',
  aulas: {
    modal_info: 'It will not be possible to restore this class!',
    modal_confirm: 'Yes, delete this class!',
    modal_sucesso: 'Class successfully deleted!',
    titulo: 'Classes',
    materias: 'Subjects and classes',
    conteudo: 'Content',
    assist: 'Class ',
    em: 'in ',
    materia: 'Subject',
    bt_editar: 'Edit',
    aula: 'Class',
    aulas: 'Classes',
    atividade: 'Activity',
    atividades: 'Activities',
    edit_aula: 'Edit',
    edit_atividade: 'Edit',
    erro_dados: 'Select a class from the list of classes',
    erro_materias: 'There is no content here',
    voltar: 'Previous content',
    prox: 'Next content'
  },
  autores: {
    modal_titulo: "Do you want to delete this author's link?",
    modal_info: "By doing so, you agree to the exclusion of this author's link with the track",
    modal_confirm: 'Type CONFIRM to perform the deletion',
    modal_erro: 'Invalid confirmation message',
    confirm: 'CONFIRM',
    modal_sucesso: 'Link successfully deleted!',
    titulo: 'Authors',
    bt_vincular: 'Link author',
    nome: 'Authors',
    acao: 'Option',
    bt_excluir: 'Delete link'
  },
  cadastrar: {
    erro_nome: 'Name must be filled',
    erro_data: "Fill in the track's publication date",
    msg_sucesso: 'Track successfully registered',
    titulo: 'Register trail',
    nome: 'Namel',
    nome2: 'Trail name',
    visibilidade: 'Course visibility',
    escolher: 'Choose course visibility',
    publico: 'Public',
    privado: 'Private',
    previa: 'Trail preview',
    data: 'Publication date',
    desc: 'Trail Description'
  },
  cadastrarAtividade: {
    erro_duracao: 'Fill in the duration',
    erro_materia: 'Select a matter',
    msg_sucesso: "Module successfully registered!",
    titulo: 'Register new activity',
    nome: 'Activity name',
    tarefa: 'Activity time',
    tarefa2: 'Activity duration in minutes',
    selecione: 'Select order',
    desc: 'Activity description',
    modulo: 'Module',
    escolher: 'Choose module',
    materia: 'Matter',
    escolher2: 'Choose matter',
    data: 'Publication date',
    bt_cadastrar: 'Register activity'
  },
  cadastrarAula: {
    msg_sucesso: 'Class successfully registered',
    titulo: 'Register new class',
    nome: 'Name',
    nome2: 'Class name',
    selecione_ord: 'Select order',
    selecione_dur: 'Select class duration',
    duracao: 'Duration in minutes',
    modulo: 'Module',
    escolher_mod: 'Choose module',
    data_pub: 'Publication date',
    materia: 'Matter',
    escolher_mat: 'Choose matter',
    tipo_aula: 'Class type',
    escolher_aul: 'Choose type',
    link: 'Link (only Youtube)',
    conteudo_aul: 'Class content',
    bt_cadastar: 'Register class',
    doc: 'Document',
    link2: 'Link',
    link2_place: 'Class link (Spotify only)',
    outros: 'Others',
    pdf: 'PDF',
    podcast: 'Podcast',
    texto: 'Text',
    video: 'Video'
  },
  cadastrarMateria: {
    msg_sucesso: 'Matter successfully registered!',
    titulo: 'Register new matter',
    nome: 'Name',
    nome2: 'Matter name',
    selecione_ord: 'Select order',
    ordem_mat: 'Matter order',
    conteudo_mat: 'Matter content',
    bt_cadastrar: 'Register matter'
  },
  cadastrarModulo: {
    erro_nome: 'Name must be filled',
    erro_ordem: 'Order must be filled',
    msg_sucesso: 'Module successfully registered!',
    titulo: 'Register new module',
    nome_mod: 'Module name',
    ordem_mod: 'Select module order'
  },
  cadastrarQuestoes: {
    titulo: 'Register new question',
    nome_quest: 'Question name',
    selecione_ord: 'Select order',
    ordem_ques: 'Question order',
    tipo_quest: 'Question type',
    escolher_tipo: 'Choose question type',
    mutipla: 'Multiple choice',
    unica: 'Single choice',
    curta: 'Short answer',
    paragrafo: 'Long answer',
    descreva: 'Describe the option',
    ordem_op: 'Option order',
    opcao: 'Option',
    bt_cadastrar: 'Register option',
    bt_cancelar: 'Cancel option',
    bt_finalizar: 'Finalize registration'
  },
  config: {
    modal_confirm: 'Yes, disable this business!',
    modal_confirm_ativar: 'Yes, enable this business!',
    msg_sucesso: 'Track disabled successfully!',
    modal_titulo: 'Do you want to delete the track?',
    modal_info: 'By doing so, you agree to the exclusion of participant links, modules, classes and activities from the trail. </br>Type <strong>CONFIRM</strong> to delete',
    modal_confirm2: 'Type CONFIRM to perform the deletion',
    confirm: 'CONFIRM',
    modal_erro: 'Invalid confirmation message!',
    modal_sucesso: 'Track deleted successfully!',
    modal_confirm3: 'Yes, duplicate this track!',
    modal_sucesso2: 'Track duplicated successfully!',
    titulo: 'Settings',
    bt_cad_materia: 'Register subject',
    bt_cad_modulo: 'Register module',
    bt_cad_aula: 'Register class',
    bt_cad_atividade: 'Register activity',
    autores: 'Authors',
    bt_edit_trilha: 'Edit track',
    bt_dup_trilha: 'Duplicate this track',
    bt_edit_capa: 'Edit cover photo',
    bt_desativar: 'Disable',
    bt_ativar: 'Enable',
  },
  conteudos: {
    titulo: 'Modules',
    bt_mod: 'Module',
    nome: 'Name',
    inscreva: 'Sign up to access',
    progre: 'Progress',
    acao: 'Option',
    bt_visu: 'Visualize',
    bt_editar: 'Edit'
  },
  descricao: {
    titulo: 'Training',
    sem_dados: 'Description',
    sem_dados_sub: 'What a pity! There is no information to display.',
  },
  editarAtividade: {
    modal_titulo: 'Do you want to delete the question?',
    modal_info: 'By doing so, you agree to the exclusion of option bindings. </br>Type <strong>CONFIRM</strong> to delete',
    modal_confirm: 'Type CONFIRM to perform the deletion',
    confirm: 'CONFIRM',
    modal_erro: 'Invalid confirmation message!',
    modal_sucesso: 'Question successfully deleted!',
    modal_titulo2: 'Do you want to delete the activity?',
    modal_sucesso2: 'Activity deleted successfully!',
    titulo_que: 'Question title: ',
    ordem: 'Order of question: ',
    tipo_quest: 'Question type: ',
    paragrafo: 'Long answer',
    curta: 'Short answer',
    multipla: 'Multiple choice',
    unica: 'Single choice',
    gabaritos: 'Feedback: ',
    falta_info: 'Lack of information for registration!',
    bt_fechar: 'Close',
    quest_sucesso: 'Question successfully registered',
    msg_sucesso: 'Editing done successfully!',
    titulo: 'Edit class',
    carregando: 'Loading...',
    nome: 'Name',
    nome_mate: 'Subject name',
    tempo_atv: 'Activity duration',
    sel_ordem: 'Select order',
    ordem_atv: 'Order of matter',
    sel_publi: 'Select publication date',
    data_pub: 'Publication date',
    conteudo: 'Activity content',
    bt_cadastrar: 'Register questions',
    tipo: 'Type',
    status: 'Status',
    acao: 'Option',
    sem_tipo: 'Does not have type',
    ativo: 'Active',
    desat: 'Disabled',
    ver_op: 'See options'
  },
  editarAula: {
    modal_titulo: 'Do you want to delete the class?',
    modal_info: 'By doing so, you agree to the deletion of class links. </br>Type <strong>CONFIRM</strong> to delete',
    modal_sucesso: 'Class successfully deleted!',
    msg_sucesso: 'Editing done successfully!',
    titulo: 'Edit class',
    nome_aula: 'Class name',
    ordem_aula: 'Class order',
    duracao_aula: 'Class duration',
    materia: 'Subject',
    sel_materia: 'Choose subject',
    tipo_aula: 'Class type',
    sel_tipo: 'Choose type',
    link: 'Link (YouTube only)',
    doc_aula: 'Delivery document',
    conteudo: 'Class content',
    link2: 'Class link (only Spotify)'
  },
  editarCapa: {
    msg_sucesso: 'Delivery made successfully!',
    titulo: 'Edit cover',
    nava_capa: 'New cover',
    bt_enviar: 'Send'
  },
  editarMateria: {
    modal_titulo: 'Do you want to delete the article?',
    modal_info: 'By doing so, you agree to the exclusion of course links, progress and subject activities. </br>Type <strong>CONFIRM</strong> to delete',
    modal_sucesso: 'Article successfully deleted!',
    msg_sucesso: 'Editing done successfully!',
    bt_editar: 'Edit subject',
    ordem_mat: 'Subject order',
    desc_materia: 'Subject description',
    bt_deletar: 'Delete subject'
  },
  editarModulo: {
    modal_titulo: 'Do you want to delete the module?',
    modal_info: 'By doing so, you agree to the exclusion of subject links, classes, progress and subject activities. </br>Type <strong>CONFIRM</strong> to delete',
    modal_erro: 'Invalid confirmation message!',
    modal_sucesso: 'Module successfully deleted!',
    msg_sucesso: "Module successfully edited",
    titulo: 'Edit module',
    nome_mod: 'Module name',
    ordem: 'Order',
    desc_modulo: 'Module description',
    bt_deletar: 'Delete module'
  },
  editarQuestao: {
    titulo_op: 'Option title: ',
    ordem_op: 'Option order: ',
    titulo_info: 'Lack of registration information!',
    bt_fechar: 'Close',
    modal_sucesso: 'Option registered successfully!',
    titulo: 'Edit question',
    titulo_inp: 'Title',
    titulo_que: 'Question title',
    tipo_campo: 'Field type',
    bt_cadastar: 'Register option',
    titulo_input: 'Title',
    bt_del: 'Delete question'
  },
  editarTrilha: {
    erro_nome: 'Name must be filled',
    msg_sucesso: 'Trail successfully edited!',
    titulo: 'Edit trail',
    nome: 'Name',
    nome_trilha: 'Trail name',
    visibilidade: 'Course visibility',
    sel_visibilidade: 'Choose course visibility',
    publico: 'Public',
    privado: 'Private',
    previa: 'Preview',
    descricao: 'Description'
  },
  participantes: {
    modal_titulo: "Do you want to delete this participant's link?",
    modal_info: "By doing so, you agree to the exclusion of this participant's link with the TextTrackList.",
    modal_sucesso: 'Link successfully deleted!',
    titulo: 'Participants',
    bt_vincular: 'Link participant',
    bt_conversar: 'Chat',
    nome: 'Name',
    perfil: 'Profile',
    acoes: 'Options',
    nao_possui: 'Does not have a profile',
    bt_excluir: 'Delete link',
    nota: 'Grade'
  },
  resumo: {
    msg_erro: 'Incorrect answer!',
    msg_sucesso: 'Correct answer!',
    msg_info: 'There are still outstanding exercises!',
    titulo: 'Activity',
    bt_editar: 'Edit activity',
    resp: 'Answer',
    finalizar: 'End activity',
    atv_finalizada: 'Activity completed'
  },
  verAtividade: {
    resp_incorreta: 'Incorrect answer',
    resp_correta: 'Right answer',
    titulo: 'Activity',
    desc: 'Description',
    quest: 'Questão',
    resp: 'Result',
    prox: 'Next',
    ante: 'Previous',
    bt_salvar: "Save",
    atv_fim: 'Activity completed',
    bt_finalizar: 'End activity',
    bt_editar: 'Edit activity',
  },
  vincularAutor: {
    modal_titulo: 'Are you sure?',
    modal_info: 'Yes, link this participant!',
    modal_sucesso: 'Author successfully linked!',
    titulo: 'Link Authors',
    bt_cancelar: 'Clean filter',
    bt_filtrar: 'Filter',
    tipo_filtro: 'Choose filter type',
    ambiente: 'Innovation space',
    negocio: 'Business',
    perfil: 'Profile',
    escolha_filtro: 'Choose a filter first',
    escolha_amb: 'Choose the innovation space',
    escolha_neg: 'Choose business',
    escolha_perfil: 'Choose profile',
    escolha_info: 'Choose your profile, innovation space or business',
    bt_pesquisar: 'Search',
    nome: 'Name',
    acao: 'Option',
    bt_vincular: 'Link',
    page1: 'First',
    page2: 'Last'
  },
  vincularParticipante: {
    modal_confirm: 'Yes, link this participant!',
    modal_sucesso: 'Participant successfully linked!',
    msg_sucesso: 'Track successfully registered',
    titulo: 'Link Participant'
  }
}