import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/conteudos';
import BlocoPerfil from '../../containers/blocos/perfilConteudo';
import SemDados from '../../componentes/Mensagens/semDados';

import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import parse from 'html-react-parser'
import Skeleton from "react-loading-skeleton";
import { apiImagem } from '../../config/';
import { portugues, ingles } from '../../utils/paginaconteudo_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';



class VerConteudo extends Component {
    state = {
        id_conteudo: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_conteudo } = this.props.match.params;
        this.setState({ id_conteudo })
        this.listarConteudo(id_conteudo)

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_conteudo } = this.props.match.params;
        if (!this.props.conteudo && nextProps.conteudo) {
            this.listarConteudo(id_conteudo);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparConteudo();
    }

    receberDadosAPI() {
        if (typeof this.props.conteudo != "undefined" && this.props.conteudo != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    listarConteudo(id_conteudo) {
        this.props.getVerConteudo(id_conteudo);
        const { conteudo } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (conteudo === "undefined") return null
    }




    render() {
        const { msg, carregando, erro, success, dadosApi, id_conteudo, idioma } = this.state;
        var conteudo = null;
        if (this.props.conteudo) conteudo = this.props.conteudo;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        ////////console.log(conteudo)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.paginaVer.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {conteudo != null ?
                        <BlocoPerfil conteudo={conteudo} dadosApi={dadosApi} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" >
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                        <AlertaErro erros={erro} />
                        <AlertaSuccess erros={success} />
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {dadosApi ?
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <img className="card-imagem-news img-news rounded mx-auto d-block" src={conteudo.fileName ? apiImagem + conteudo.fileName : apiImagem + "fundo_startup.jpg"} alt="Card image cap" />

                                        {conteudo.previa ?
                                            <div>
                                                <div className="text-muted text-justify mt-3">
                                                    {parse("" + conteudo.descricao)}
                                                </div>
                                            </div>
                                            : conteudo.descricao ?
                                                <div>
                                                    <div className="text-muted text-justify mt-3">
                                                        {parse("" + conteudo.descricao)}
                                                    </div>
                                                </div>
                                                : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.paginaVer.titulo} />
                                        }
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-12 mb-2 text-center">
                                        <Skeleton rectangle={true} height={100} width={100} />
                                        <div>
                                            <div className="text-muted text-justify mt-3">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    conteudo: state.conteudos.conteudo,
})
export default connect(mapStatetoProps, actions)(VerConteudo);