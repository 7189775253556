import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import minhasStartups from '../../paginas/Startup/minhasStartups';
import { min } from 'date-fns';
import { format, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import Toast from '../../componentes/Alertas/Toast';

import { portugues, ingles } from '../../utils/blocoPerfilSolucao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class PerfilSolucao extends Component {
  state = {
    id_usuario: '',
    dadosAPI: false,
    path: '',
    solucao: '',
    admin: false,
    usuario: '',
    jaAvaliou: false,
    currentPage: 1,
    size: 20,
    idioma: portugues,
    msg_backend: portuguese,
  };

  componentDidMount() {
    if (this.props.solucao) {
      this.listarAvaliadores(this.props.solucao.id_desafio);
      if (this.props.usuario) {
        this.verificaAvaliador(this.props.solucao, this.props.usuario);
      }
    }

    if (this.props.credenciais) this.checkAdmin(this.props.credenciais);

    const { usuario } = this.props;

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props.solucao) {
      if (!this.props.avaliadores && nextProps.avaliadores) {
        this.listarAvaliadores(this.props.solucao.id_desafio);
        if (this.props.usuario) {
          this.verificaAvaliador(this.props.solucao, this.props.usuario);
        }
      }
    }
    this.receberDadosAPI();
  }

  receberDadosAPI() {
    if (
      typeof this.props.avaliadores != 'undefined' &&
      this.props.avaliadores != null &&
      !this.state.dadosApi &&
      this.props.avaliadores.currentPage === this.state.currentPage
    ) {
      this.setState({ dadosApi: true });
    }
  }

  listarAvaliadores(id_desafio) {
    const { currentPage, size } = this.state;
    this.props.getAvaliadores(id_desafio, currentPage, size);
    const { avaliadores } = this.props;
    // if (this.props.location.state) {
    //     this.setState({ msg: this.props.location.state.msg })
    //     this.props.location.state.msg = "";
    // }
    if (avaliadores === 'undefined') return null;
  }

  verificaAvaliador(solucao, usuario) {
    for (let a in solucao.avaliacoes) {
      if (solucao.avaliacoes[a].id_usuario == usuario.id) {
        this.setState({ jaAvaliou: true });
      }
    }
  }

  checkAdmin(credenciais) {
    if (credenciais.length > 0) {
      if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
        this.setState({ admin: true });
      }
    }
  }

  //   if (solucao.avaliacoes.length >= avaliadores.length) {
  //     if (solucao.media >= solucao.nota_corte) {
  //       this.props.postDesafioEmailAceite({ id_solucao: solucao.id, etapa: 2 }, (msg) => {
  //         if (msg.erro.error) {
  //           this.setState({
  //             erro: { message: this.state.msg_backend[msg.erro.code] },
  //           });
  //           this.setState({ success: '' });
  //           this.setState({ carregando: false });
  //         } else {
  //           this.setState({
  //             success: { message: this.state.msg_backend[msg.erro.code] },
  //           });
  //           this.setState({ erro: '' });
  //           this.setState({ carregando: false });
  //         }
  //       })
  //     } else {
  //       this.props.postDesafioEmailRejeitar({ id_solucao: solucao.id, etapa: 2 }, (msg) => {
  //         if (msg.erro.error) {
  //           this.setState({
  //             erro: { message: this.state.msg_backend[msg.erro.code] },
  //           });
  //           this.setState({ success: '' });
  //           this.setState({ carregando: false });
  //         } else {
  //           this.setState({
  //             success: { message: this.state.msg_backend[msg.erro.code] },
  //           });
  //           this.setState({ erro: '' });
  //           this.setState({ carregando: false });
  //         }
  //       })
  //     }
  //   }
  // }

  //deletar incubadora
  deletandoDesafio(solucao) {
    this.setState({ carregando: true });
    this.props.deletarSolucao(solucao, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        this.sucessoAlertaDeletar();
      }
    });
  }

  chamarAlertaDeletar(solucao) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.modal_titulo,
      html: idioma.modal_info,
      input: 'text',
      inputPlaceholder: idioma.modal_confirm,
      confirmButtonColor: '#3085d6',
      icon: 'warning',
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonText: idioma.bt_deletar,
      showLoaderOnConfirm: true,
    }).then((texto) => {
      ////////console.log(texto)
      if (texto.value === idioma.confirm) {
        this.deletandoDesafio(solucao);
      } else {
        Swal.fire({
          icon: 'error',
          title: idioma.modal_erro,
        });
      }
    });
  }
  sucessoAlertaDeletar() {
    const { idioma } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: idioma.modal_sucesso,
    });
  }

  chamarAlertaSolucao(solucao) {
    const { id_desafio, id_solucao, idioma } = this.state;

    Swal.fire({
      title: idioma.sel_solucao,
      //input file
      input: 'file',
      showCloseButton: true,
      confirmButtonText: idioma.sel_solucao,
    }).then((result) => {
      if (result.value) {
        this.selectSolution(solucao.id_desafio, solucao.id, result.value);
      } else {
        Swal.fire(idioma.etapa1_error_msg)
      }
    });
  }

  selectSolution(id_desafio, id_solucao, files) {
    const formData = new FormData();
    formData.append('id_desafio', id_desafio);
    formData.append('id_solucao', id_solucao);
    formData.append('file', files);
    this.setState({ carregando: true });
    this.props.postAceitarSolucao(formData, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        this.props.postDesafioEmailAceite({ id_solucao, etapa: 1 }, (msg) => {
          if (msg.erro.error) {
            this.setState({
              erro: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ success: '' });
            this.setState({ carregando: false });
          } else {
            this.setState({
              success: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ erro: '' });
            this.setState({ carregando: false });
          }
        })
      }
    });
  }

  chamarAlertaCancelar(solucao) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.canc_solucao,

      showCloseButton: true,
      confirmButtonText: idioma.canc_solucao,
    }).then((result) => {
      if (result.isConfirmed) {
        this.cancelSolution(solucao.id_desafio, solucao.id);
      }
    });
  }

  cancelSolution(id_desafio, id_solucao) {
    this.setState({ carregando: true });
    this.props.postCancelarSolucao({ id_desafio, id_solucao }, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        this.props.postDesafioEmailRejeitar({ id_solucao, etapa: 1 }, (msg) => {
          if (msg.erro.error) {
            this.setState({
              erro: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ success: '' });
            this.setState({ carregando: false });
          } else {
            this.setState({
              success: { message: this.state.msg_backend[msg.erro.code] },
            });
            this.setState({ erro: '' });
            this.setState({ carregando: false });
          }
        })
      }
    });
  }

  cadAvaliacao(id_desafio, id_solucao, id_usuario) {
    const { idioma } = this.state;
    Swal.mixin({
      input: 'text',
      confirmButtonText: `${idioma.bt_prox} &rarr;`,
      showCancelButton: true,
      progressSteps: ['1', '2', '3', '4', '5', '6'],
    })
      .queue([
        {
          title: idioma.tec,
          text: 'De 0 a 50',
          input: 'number',
        },
        {
          title: idioma.mercado,
          text: 'De 0 a 50',
          input: 'number',
        },
        {
          title: idioma.capital,
          text: 'De 0 a 50',
          input: 'number',
        },
        {
          title: idioma.gestao,
          text: 'De 0 a 50',
          input: 'number',
        },
        {
          title: idioma.empreendedor,
          text: 'De 0 a 50',
          input: 'number',
        },
        {
          title: idioma.doc,
          input: 'file',
          inputAttributes: {
            multiple: 'multiple',
          },
        },
      ])
      .then((result) => {
        if (result.value) {
          if (result.value.length == 6) {
            let notas = result.value;
            let soma = 0;
            let nota_final = 0;
            let arquivos = null;
            for (let r in result.value) {
              if (r != 5) {
                soma = soma + parseFloat(result.value[r]);
              }
            }
            nota_final = (soma / 5).toFixed(2);
            arquivos = result.value[5];
            Swal.fire({
              title: `${idioma.nota_final} ${nota_final}`,
              showCancelButton: true,
              confirmButtonText: idioma.bt_avancar,
              cancelButtonText: idioma.refazer,
            }).then((result) => {
              if (result.isDismissed) {
                this.cadAvaliacao(id_desafio, id_solucao);
              }
              if (result.isConfirmed) {
                this.avaliar(
                  id_desafio,
                  id_solucao,
                  id_usuario,
                  notas,
                  nota_final,
                  arquivos,
                );
              }
            });
          }
        }
      });
  }

  avaliar(id_desafio, id_solucao, id_usuario, notas, nota_final, files) {
    const { idioma } = this.state;
    let nota1 = notas[0];
    let nota2 = notas[1];
    let nota3 = notas[2];
    let nota4 = notas[3];
    let nota5 = notas[4];
    const formData = new FormData();
    formData.append('id_desafio', id_desafio);
    formData.append('id_solucao', id_solucao);
    formData.append('id_usuario', id_usuario);
    formData.append('nota1', nota1);
    formData.append('nota2', nota2);
    formData.append('nota3', nota3);
    formData.append('nota4', nota4);
    formData.append('nota5', nota5);
    formData.append('nota_final', nota_final);
    for (let f in files) {
      formData.append('file', files[f]);
    }
    this.setState({ carregando: true });
    this.props.postAvaliacao(formData, (msg) => {
      if (msg.erro.error) {
        Toast({ icone: 'error', titulo: idioma.erro, texto: msg.erro.message });
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        Toast({
          icone: 'success',
          titulo: idioma.sucesso,
          texto: msg.erro.message,
        });
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
      }
    });
  }

  cadAprovar(id_desafio, id_solucao, id_usuario, media) {
    const { idioma } = this.state;
    const { solucao } = this.props;

    const notaCorteOk = Number(media) >= Number(solucao.nota_corte);

    Swal.mixin({
      confirmButtonText: `${idioma.bt_prox} &rarr;`,
      showCancelButton: true,
      progressSteps: ['1', '2', '3'],
    })
      .queue([
        {
          title: idioma.apr_solucao,
          html: `<p style=color:red;>${notaCorteOk ? `${idioma.nota_media} ${media.toFixed(2)}` : idioma.info_nota_corte}</p>`,
          input: 'radio',
          inputOptions: {
            Reprovado: idioma.repro,
            Aprovado: idioma.apro,
          },
        },
        {
          title: idioma.obs,
          input: 'textarea',
        },
        {
          title: idioma.doc,
          input: 'file',
          inputAttributes: {
            multiple: 'multiple',
          },
        },
      ])
      .then((result) => {
        if (result.value.length == 3) {
          this.avaliarGestor(
            id_desafio,
            id_solucao,
            id_usuario,
            result.value[0],
            result.value[1],
            result.value[2],
            media,
          );
        }
      });
  }



  avaliarGestor(
    id_desafio,
    id_solucao,
    id_usuario,
    resultado,
    observacao,
    files,
    nota_final,
  ) {
    const { idioma } = this.state;
    const formData = new FormData();
    formData.append('id_desafio', id_desafio);
    formData.append('id_solucao', id_solucao);
    formData.append('id_usuario', id_usuario);
    formData.append('resultado', resultado);
    formData.append('observacao', observacao);
    formData.append('nota_final', nota_final);
    
    for (let f in files) {
      formData.append('file', files[f]);
    }
    this.setState({ carregando: true });
    this.props.postAvaliacaoGestor(formData, (msg) => {
      if (msg.erro.error) {
        Toast({ icon: 'error', title: idioma.erro, text: msg.erro.message });
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        Toast({
          icon: 'success',
          title: idioma.sucesso,
          text: msg.erro.message,
        });
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        if (resultado === "Aprovado") {
          this.props.postDesafioEmailAceite({ id_solucao, etapa: 3 }, (msg) => {
            if (msg.erro.error) {
              this.setState({
                erro: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ success: '' });
              this.setState({ carregando: false });
            } else {
              this.setState({
                success: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ erro: '' });
              this.setState({ carregando: false });
            }
          })
        } else {
          this.props.postDesafioEmailRejeitar({ id_solucao, etapa: 3 }, (msg) => {
            if (msg.erro.error) {
              this.setState({
                erro: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ success: '' });
              this.setState({ carregando: false });
            } else {
              this.setState({
                success: { message: this.state.msg_backend[msg.erro.code] },
              });
              this.setState({ erro: '' });
              this.setState({ carregando: false });
            }
          })
        }
      }
    });
  }

  chamarAindaNao(avaliacoes, avaliadores) {
    const { idioma } = this.state;
    Swal.fire({
      icon: 'error',
      title: idioma.falta_ava,
      text: `${idioma.aguard_aval} ${avaliacoes.length} ${idioma.de} ${avaliadores.length}`,
    });
  }

  deleteSolution(id_desafio, id_solucao) {
    this.setState({ carregando: true });
    this.props.deletarSolucao(id_solucao, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
        this.props.limparSolucoes(id_solucao);
      }
    });
  }

  render() {
    let { usuario, solucao, jaAvaliou, dadosApi, path, formSuccess, idioma, admin } =
      this.state;
    var credenciais = [];
    if (this.props.credenciais) credenciais = this.props.credenciais;
    if (this.props.usuario) usuario = this.props.usuario;

    if (this.props.solucao) solucao = this.props.solucao;
    if (this.props.dadosApi) dadosApi = this.props.dadosApi;
    var avaliadores = [];
    if (this.props.avaliadores) avaliadores = this.props.avaliadores.dados;
    if (formSuccess)
      return (
        <Redirect
          to={{
            pathname: '/hub/' + solucao.id_desafio + '/ver',
            state: { msg: 'Solucao editado com sucesso!' },
          }}
        ></Redirect>
      );

    const countAvaliadores = avaliadores.filter((item) => item.avaliado);

    return (
      <>
        <div className="col-md-12 mb-2">
          <div className="list-group-item">
            {dadosApi ? (
              <div>
                <div className="row">
                  <div className="col-md-12 ">
                    <h2 className="">{solucao ? solucao.titulo : ''}</h2>
                  </div>
                  <div className="ml-4">
                    <span className="text-muted mr-1">Criado por: </span>
                    <span className="text-perfil ">{solucao.autor} </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12 ">
                  <h2 className="">
                    <Skeleton count={1} width={160} />
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 mb-3">
          <div className="list-group-item">
            <div className="row">
              <div className="col-md-12">
                <div className="container-fluid">
                  <div className="row">
                    <div
                      className={
                        path == '/hub/:id_desafio/ver'
                          ? 'col-auto menu-cabecalho-active texto-centralizado botao-responsivo'
                          : 'col-auto menu-cabecalho texto-centralizado botao-responsivo'
                      }
                    >
                      <Link
                        to={
                          '/' +
                          usuario?.personalizacao?.titulo +
                          '/hub/' +
                          solucao.id_desafio +
                          '/ver'
                        }
                      >
                        <span className="text-perfil">
                          <FontAwesomeIcon
                            icon="arrow-left"
                            size="1x"
                            color="black"
                            className="mr-3"
                          />
                          {idioma.bt_voltar}
                        </span>
                      </Link>
                    </div>
                    <div
                      className={
                        path ==
                          '/hub/desafio/:id_desafio/solucao/:id_solucao/editar'
                          ? 'col-auto menu-cabecalho-active texto-centralizado botao-responsivo'
                          : 'col-auto menu-cabecalho texto-centralizado botao-responsivo'
                      }
                    >
                      <Link
                        to={
                          '/' +
                          usuario?.personalizacao?.titulo +
                          '/hub/desafio/' +
                          solucao.id_desafio +
                          '/solucao/' +
                          solucao.id +
                          '/editar'
                        }
                      >
                        <span className="text-perfil">
                          <FontAwesomeIcon
                            icon="pencil-alt"
                            size="1x"
                            color="black"
                            className="mr-3"
                          />
                          {idioma.bt_editar}
                        </span>
                      </Link>
                    </div>

                    <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                      <Link
                        onClick={() =>
                          this.chamarAlertaDeletar(solucao.id)}
                      >
                        <span className="text-perfil">
                          <FontAwesomeIcon
                            icon="trash-alt"
                            size="1x"
                            color="red"
                            className="mr-3"
                          />
                          {idioma.bt_deletar}
                        </span>
                      </Link>
                    </div>

                    {admin || solucao.id_autor === usuario.id ? (
                      !solucao.datahora_aceite ? (<div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                        <Link
                          onClick={() =>
                            this.chamarAlertaSolucao(solucao, credenciais)
                          }
                        >
                          <span className="text-perfil">
                            <FontAwesomeIcon
                              icon="check"
                              size="1x"
                              color="green"
                              className="mr-3"
                            />
                            {idioma.sele}
                          </span>
                        </Link>
                      </div>) : (
                        <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                          <Link
                            onClick={() =>
                              this.chamarAlertaCancelar(solucao)
                            }
                          >
                            <span className="text-perfil">
                              <FontAwesomeIcon
                                icon="times"
                                size="1x"
                                color="red"
                                className="mr-3"
                              />
                              {idioma.canc_solucao}
                            </span>
                          </Link>
                        </div>
                      )
                    ) : ''}

                    {solucao.avaliacoes.length < avaliadores.length ? (
                      !jaAvaliou ? (
                        <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                          <Link
                            to={
                              '/hub/avaliar/' +
                              solucao.id_desafio +
                              '/solucao/' +
                              solucao.id
                            }
                          >
                            <span className="text-perfil">
                              <FontAwesomeIcon
                                icon="check"
                                size="1x"
                                color="green"
                                className="mr-3"
                              />
                              {idioma.avaliar}
                            </span>
                          </Link>
                        </div>
                      ) : (
                        <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                          <Link
                            onClick={() => {
                              Swal.fire({
                                icon: 'error',
                                title: idioma.msg_erro,
                              });
                            }}
                          >
                            <span className="text-perfil">
                              <FontAwesomeIcon
                                icon="check"
                                size="1x"
                                color="green"
                                className="mr-3"
                              />
                              {idioma.avaliar}
                            </span>
                          </Link>
                        </div>
                      )
                    ) : (
                      <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                        <Link
                          onClick={() => {
                            Swal.fire({
                              icon: 'error',
                              title: idioma.msg_info,
                            });
                          }}
                        >
                          <span className="text-perfil">
                            <FontAwesomeIcon
                              icon="check"
                              size="1x"
                              color="green"
                              className="mr-3"
                            />
                            {idioma.avaliar}
                          </span>
                        </Link>
                      </div>
                    )}

                    {(solucao.id_autor === usuario.id) || admin ? (
                      solucao.avaliacoes.length >= avaliadores.length ? (
                        solucao.avaliacoesGestor === null ? (
                          <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                            <Link
                              onClick={() =>
                                this.cadAprovar(
                                  solucao.id_desafio,
                                  solucao.id,
                                  usuario.id,
                                  solucao.media,
                                )
                              }
                            >
                              <span className="text-perfil">
                                <FontAwesomeIcon
                                  icon="clipboard-check"
                                  size="1x"
                                  color="green"
                                  className="mr-3"
                                />
                                {idioma.aval_gestor}
                              </span>
                            </Link>
                          </div>
                        ) : (
                          <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                            <Link
                              onClick={() =>
                                this.chamarAindaNao(solucao.avaliacoes, avaliadores)
                              }
                            >
                              <span className="text-perfil">
                                <FontAwesomeIcon
                                  icon="clipboard-check"
                                  size="1x"
                                  color="green"
                                  className="mr-3"
                                />
                                {idioma.aval_gestor}
                              </span>
                            </Link>
                          </div>
                        )
                      ) : ''
                    ) : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => ({
  solucao: state.hub.solucao,
  avaliadores: state.hub.avaliadores,
});
export default connect(mapStatetoProps, actions)(PerfilSolucao);
