import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/radares';
import { Link } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import BotaoIcon from '../../../componentes/BotaoIcon';
import Swal from 'sweetalert2';
import { Table } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Toast from '../../../componentes/Alertas/Toast';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaradar_bilingue';


class ListarTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 20,
        trilha: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        this.listarRadares()

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.radares && nextProps.radares) this.listarRadares();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparRadares()
    }
    receberDadosAPI() {
        if (typeof this.props.radares != "undefined" && this.props.radares != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }

    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarRadares();
        })
    }

    listarRadares() {
        this.props.getRadares()
        const { radares } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (radares === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    //deletar 
    deletar(id_radar) {
        const { msg_backend, idioma } = this.state;
        this.setState({ carregando: true })
        this.props.deletarRadar(id_radar, (msg) => {
            if (msg.erro.error) {
                Toast({ icon: 'error', title: idioma.erro, text: msg_backend[msg.erro.code] })
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                Toast({ icon: 'success', title: idioma.sucesso, text: msg_backend[msg.erro.code] })
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.props.limparRadares()
                this.listarRadares()


            }
        })
    }

    chamarDeletar(id_radar) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_titulo2,
            html: idioma.modal_info,
            input: 'text',
            inputPlaceholder: idioma.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_del,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value == idioma.confirm) {
                this.deletar(id_radar);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.msg_erro
                })
            }
        })
    }


    render() {
        const { trilha, msg, carregando, erro, success, dadosApi, formSuccess, idioma } = this.state;
        const { credenciais, usuario } = this.props

        var radares = [];
        if (this.props.radares) radares = this.props.radares.dados;

        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <Link to={`/${usuario?.personalizacao?.titulo}/radar/cadastrar`}> <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.nome}</th>
                                                            <th className="coluna-tabela">{idioma.ambientes}</th>
                                                            <th className="coluna-tabela">{idioma.acoes}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {radares.map(radar => (
                                                            <tr key={radar.id}>

                                                                <td>{radar.nome}</td>
                                                                <td>
                                                                    <a data-tip data-for='vinculos'>
                                                                        <Link to={`/${this.props.usuario?.personalizacao?.titulo}/radar/${radar.id}/vinculos`} className="mr-2">
                                                                            <Botao texto={radar.total_incubadoras} classes="botao-responsivo" icone="eye" posicao="D" color={this.props.usuario?.personalizacao?.cor_terciaria} />
                                                                        </Link>
                                                                    </a>
                                                                </td>
                                                                <td>
                                                                    <a data-tip data-for='pilares'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + radar.id + "/pilares"} className="mr-2">
                                                                            <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="eye" />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='editar'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + radar.id + "/editar"} className="mr-2">
                                                                            <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="edit" />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='deletar'>
                                                                        <Link to="#" onClick={() => this.chamarDeletar(radar.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    <ReactTooltip id='vinculos' type='dark'>
                                                                        <span>{idioma.tooltip_ambientes}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='pilares' type='dark'>
                                                                        <span>{idioma.pilares}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='deletar' type='error'>
                                                                        <span>{idioma.bt_del}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' type='dark'>
                                                                        <span>{idioma.editar}</span>
                                                                    </ReactTooltip>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    radares: state.radares.radares,

})
export default connect(mapStatetoProps, actions)(ListarTrilhas);