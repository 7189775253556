import { GET_PERFIS_ACESSO, GET_PERFIL_ACESSO, LIMPAR_PERFIL_ACESSO, LIMPAR_PERFIS_ACESSO, GET_CREDENCIAIS, GET_COMPONENTES } from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_PERFIS_ACESSO:
            return {
                ...state,
                acessos: actions.payload.data
            }
        case GET_PERFIL_ACESSO:
            return {
                ...state,
                acesso: actions.payload.dados
            }
        case GET_CREDENCIAIS:
            return {
                ...state,
                credenciais: actions.payload.data
            }
        case GET_COMPONENTES:
            return {
                ...state,
                componentes: actions.payload.data
            }
        case LIMPAR_PERFIL_ACESSO:
            return {
                ...state,
                acesso: null
            }
        case LIMPAR_PERFIS_ACESSO:
            return {
                ...state,
                acesso: null
            }
        default:
            return state;
    }
}