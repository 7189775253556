import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Col, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import Radar from '../../componentes/Graficos/Radar'
import RadarDetalhes from '../../componentes/Graficos/RadarDetalhes'
import Skeleton from "react-loading-skeleton";
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

class RadarResumo extends Component {
	state = {
		resposta: null,
		id_radar: null,
		id_metodologia: null,
		id_processo: null,
		id_pilar: null,
		id_usuario: null,
		id_startup: null,
		id_etapa: null,
		id_incubadora: "",
		pertence: false,
		menuResumo: true,
		menuGrafico: false,
		msg: "",
		erro: "",
		success: "",
		carregando: "",
		formSuccess: false,
		dadosApi: false,
		pilarAtv: 1,
		pilarId: 0,
		idioma: portugues,
		msg_backend: portuguese
	}

	componentDidMount() {
		// Recupera os params da url para usar na classe
		if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });

		const { id_startup, id_incubadora, id_etapa, id_metodologia, id_processo } = this.props.match.params;
		this.setState({ id_startup });
		this.setState({ id_etapa });
		this.setState({ id_metodologia })
		this.setState({ id_incubadora })
		this.setState({ id_processo })
		this.listarPilaresRadar(id_startup, id_metodologia, id_processo, id_etapa);
		this.listarDetalhesRadar(id_startup, id_metodologia, id_processo, id_etapa);
		this.listarStartup(id_startup)
		this.verificaPertence(this.props.usuario.id, id_startup)
		this.listarPerguntasRadar(id_metodologia, id_startup, id_etapa);
		this.listarRespostasRadar(id_metodologia);
		if (this.props.perguntas) {
			this.setInicio(this.props.perguntas.dados)
		}

		if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)


		const { usuario } = this.props;
		if (usuario?.idioma?.id === 3) {
			this.setState({ idioma: ingles })
			this.setState({ msg_backend: english })
		} else {
			this.setState({ idioma: portugues })
			this.setState({ msg_backend: portuguese })
		}


	}

	componentDidUpdate(nextProps) {

		const { id_startup, id_metodologia, id_processo, id_etapa } = this.props.match.params;

		if (!this.props.etapas && nextProps.etapas) {
			this.listarPilaresRadar(id_startup, id_metodologia, id_processo, id_etapa);
		}
		if (!this.props.detalhes && nextProps.detalhes) {
			this.listarDetalhesRadar(id_startup, id_metodologia, id_processo, id_etapa);
		}
		if (!this.props.startup && nextProps.startup) {
			this.listarStartup(id_startup);
		}
		if (!this.props.pertence && nextProps.pertence) {
			this.verificaPertence(this.props.usuario.id, id_startup);
		}
		if (!this.props.perguntas && nextProps.perguntas) {
			this.listarPerguntasRadar(id_metodologia, id_startup, id_etapa);
		}
		if (!this.props.respostas && nextProps.respostas) {
			this.listarRespostasRadar(id_metodologia);
		}
		if (this.props.perguntas && this.state.pilarId == 0) {
			this.setInicio(this.props.perguntas.dados)

		}
		this.receberDadosAPI();
	}

	componentWillUnmount() {
		this.props.limparPerguntas()
		this.props.limparRespostas()
		this.props.limparStartup()

	}

	receberDadosAPI() {
		if (typeof this.props.pilares != "undefined" && this.props.pilares != null && !this.state.dadosApi &&
			typeof this.props.detalhes != "undefined" && this.props.detalhes != null && !this.state.dadosApi &&
			typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
			if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
				if (typeof this.props.perguntas != "undefined" && this.props.perguntas != null && !this.state.dadosApi) {
					if (typeof this.props.respostas != "undefined" && this.props.respostas != null && !this.state.dadosApi) {
						if (this.state.pilarId != 0) {
							this.setState({ dadosApi: true });
						}
					}
				}
			}
		}
	}

	listarPerguntasRadar(id_metodologia, id_startup, id_etapa) { // action
		this.props.getPerguntasRadar(id_metodologia, id_startup, id_etapa); // Solicita à API a lista de perguntas para o form 

		const { perguntas } = this.props; // Recupera a lista de perguntas da API

		// Limpa o estado da msg (popup)
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}

		// Valida se retornou dados da API
		if (perguntas === "undefined") {
			return null;
		}
	}

	listarRespostasRadar(id_metodologia) {
		const { id_startup, id_etapa } = this.props.match.params;

		this.props.getRespostasRadar(id_startup, id_etapa, id_metodologia); // Solicita à API a lista de respostas para o form 
		const { respostas } = this.props; // Recupera a lista de respostas da API 

		// Limpa o estado da msg (popup)
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}

		// Valida se retornou dados da API
		if (respostas === "undefined") {
			return null;
		}
	}

	listarStartup(id_startup) {
		this.props.getVerStartup(id_startup);
		const { startup } = this.props;
		if (this.props.location.state) {

			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (startup === "undefined") return null
	}

	listarPilaresRadar(id, id_metodologia, id_processo, id_etapa) {
		this.props.getPilaresRadar(id, id_metodologia, id_processo, id_etapa);
		const { etapas } = this.props;
		if (this.props.location.state) {

			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (etapas === "undefined") return null
	}

	listarDetalhesRadar(id, id_metodologia, id_processo, id_etapa) {
		this.props.getDetalhesRadar(id, id_metodologia, id_processo, id_etapa); // Solicita à API a lista de perguntas para o form 

		const { detalhes } = this.props; // Recupera a lista de perguntas da API

		// Limpa o estado da msg (popup)
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}

		// Valida se retornou dados da API
		if (detalhes === "undefined") {
			return null;
		}
	}

	clicarMenu(info) {
		if (info === "resumo") {
			this.setState({ menuResumo: true })
			this.setState({ menuGrafico: false })
		}
		if (info === "grafico") {
			this.setState({ menuResumo: false })
			this.setState({ menuGrafico: true })
		}
	}


	handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
		if (usuarios.startups.length > 0) {
			for (let u in usuarios.startups) {
				if (usuarios.startups[u].id_startup == id_startup) {
					this.setState({ pertence: true })
				}
			}
		}
		if (usuarios.incubadoras.length > 0) {
			for (let u in usuarios.incubadoras) {
				if (usuarios.incubadoras[u].id_incubadora == id_incubadora) {
					this.setState({ pertence: true })
				}
			}
		}
		if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
			this.setState({ pertence: true })
		}

	}
	verificaPertence(id_usuario, id_startup) {
		this.props.getVerificarPertence(id_usuario, id_startup);
		const { pertence } = this.props;
		if (this.props.location.state) {

			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (pertence === "undefined") return null
		// console.log(pertence)

	}

	checked(listaRespostas, id_radar, value) {
		let valor = parseInt(value)
		let checked = "";
	 
		for (const cont in listaRespostas) {
			if (listaRespostas[cont].id_radar === id_radar) {
				if (listaRespostas[cont].opcao) {
					if (listaRespostas[cont].opcao === Number(valor)) {
						checked = "checked";
					}
				} else {
					if (listaRespostas[cont].resposta === valor) {
						checked = "checked";
					}
				}
			}
		}
		return checked;
	}

	setInicio(perguntas) {
		if (perguntas.length > 0) {
			this.setState({ pilarId: perguntas[0].pilares.id })
			this.setState({ pilarAtv: perguntas[0].pilares.id })
		}
	}


	render() {
		// Form names
		const { id_startup, id_incubadora, pilarAtv, pilarId, id_etapa, id_metodologia, id_processo, pertence, dadosApi, menuResumo, menuGrafico, idioma } = this.state;
		var listaPerguntas = [];
		var listaRespostas = [];
		var listaPilares = [];
		var listaDetalhes = [];
		var usuarioPertence = [];
		if (this.props.pertence) usuarioPertence = this.props.pertence;
		var startup = null;
		if (this.props.startup) startup = this.props.startup;

		if (this.props.detalhes) {
			listaDetalhes = this.props.detalhes.dados;
		}

		if (this.props.pilares) {
			listaPilares = this.props.pilares.dados;
		}
		if (this.props.respostas) {
			listaRespostas = this.props.respostas.dados;
			//radarAplicado = this.props.respostas.radarAplicado;
		}
		var credenciais = []
		if (this.props.credenciais) credenciais = this.props.credenciais;
		var usuario = [];
		if (this.props.usuario) usuario = this.props.usuario;
		var path = this.props.match.path
		if (this.props.perguntas) { // acessa o perguntas dentro do mapToProps
			listaPerguntas = this.props.perguntas.dados; // salva em um novo array os dados da props radares
		}
		return (
			<>
				<div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
					<Titulo titulo={idioma.radarResumo.titulo} />
				</div>
				<div className="container-fluid bloco-topo" >
					{startup != null ?
						usuarioPertence.pertence &&
						<BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
						:
						<div className="col-md-12 mb-2">
							<div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
								<div className="row">
									<div className="col-auto centralizado" >
										<div className="img-perfil">
											<Skeleton circle={true} height={120} width={120} />
										</div>
									</div>
									<div className="col-md-9 centralizado texto-centralizado">
										<h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
										<p className="text-muted"><Skeleton count={1} width={160} /></p>
									</div>
								</div>
							</div>
						</div>
					}
					{dadosApi ?
						usuarioPertence.pertence ?

							<div className="col-md-12 mb-2">

								<div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
									<Link to={`/${this.props.usuario?.personalizacao.titulo}/startups/${id_startup}/acompanhamento/${id_metodologia}/processos/${id_processo}/etapa`}> <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
									<Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + id_startup + "/acompanhamento/" + id_metodologia + "/processos/" + id_processo + "/etapa/" + id_etapa + "/radar/"}> <Botao texto={idioma.radarResumo.bt_editar} classes="botao-responsivo" icone="pencil" posicao="E" cor="success" /> </Link>

									<div>
										<div className="col-lg-12">
											<div className=" m-b-20">
												<div className="card-body">
													<ul className="nav nav-tabs nav-tabs-custom nav-justified" role="tablist">
														<li className="nav-item">
															<a className={menuResumo ? "nav-link active cursor-clique" : "nav-link cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("resumo")}>
																<span className="d-none d-md-block">{idioma.radarResumo.visao}</span><span className="d-block d-md-none"></span>
															</a>
														</li>
														<li className="nav-item">
															<a className={menuGrafico ? "nav-link active cursor-clique" : "nav-link cursor-clique"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("grafico")}>
																<span className="d-none d-md-block">{idioma.radarResumo.detalhes}</span><span className="d-block d-md-none"></span>
															</a>
														</li>
													</ul>

													<div className="tab-content">
														<div className={menuResumo ? "tab-pane p-3 active" : "tab-pane p-3"} id="incubadoras" role="tabpanel">
															{dadosApi ?
																// <div className="row">
																<div className="etapa-card card m-4">
																	<div className="directory-content p-4">
																		{/* ;<div className="titulo-generico">{pilares.nome}</div> */}
																		<div>
																			<div className="titulo-generico">
																				{listaPilares.data_ultima_resposta ? (
																					<p>última alteração em {format(new Date(listaPilares.data_ultima_resposta), 'dd/MM/yyyy HH:mm', { locale: pt })} </p>
																				) : ("")}
																			</div>
																			<Radar pesoMaximo={listaPilares.mediaEtapa.peso_maximo ? listaPilares.mediaEtapa.peso_maximo : 5} pilares={listaPilares.pilares} />
																			<div className={listaPilares.mediaEtapa.temMedia ? "text-center" : "display-none"}>
																				<span className={listaPilares.mediaEtapa.temMedia == 0 ? "badge badge-danger media-radar" : "badge badge-success media-radar"} >
																					{idioma.radarResumo.mediaGeral} {listaPilares.mediaEtapa.mediaEtapa}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
																// </div>
																: <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
														</div>

														<div className={menuGrafico ? "tab-pane p-3 active" : "tab-pane p-3"} id="incubadoras" role="tabpanel">
															<div className="row">
																<div className="col-md-12">
																	<div className="container-fluid">
																		{listaPerguntas.length > 0 &&
																			<div className="row justify-content-center">
																				{listaPerguntas.map(pilares => (
																					<div className={pilarAtv == pilares.pilares.id ? "col-auto menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique" : "col-auto menu-cabecalho menu-centralizado botao-responsivo cursor-clique"} data-toggle="tab" role="tab" onClick={() => {
																						this.setState({ pilarAtv: pilares.pilares.id })
																						this.setState({ pilarId: pilares.pilares.id })
																					}}>
																						<span className="text-perfil">
																							{pilares && pilares.pilares.nome}
																						</span>
																					</div>
																				))}
																			</div>
																		}
																	</div>
																</div>
															</div>
															{dadosApi ?
																<>
																	<div className="">
																		{listaDetalhes.pilares.map(pilar => (pilar.pilar.id == pilarId) &&
																			<div className="etapa-card card m-4" key={pilar.pilar.id}>
																				<div className="directory-content p-4">
																					<div className="titulo-generico">
																						{pilar.pilar.nome}
																						{/* {pilar.radares[0]?.radar?.datahora_alteracao ? (
																							<p>última alteração: {format(new Date(pilar.radares[0].radar.datahora_alteracao), 'dd/MM/yyyy', { locale: pt })} </p>
																						) : (
																							<p>última alteração: --</p>
																						)} */}
																					</div>
																					<RadarDetalhes pesoMaximo={listaPilares.mediaEtapa.peso_maximo ? listaPilares.mediaEtapa.peso_maximo : 10} pilares={pilar.radares} />
																					<div className={pilar.mediaPilar.temMedia ? "text-center" : "display-none"}>
																						<span className={pilar.mediaPilar.temMedia == 0 ? "badge badge-danger media-radar" : "badge badge-success media-radar"} >
																							{idioma.radarResumo.media} {pilar.mediaPilar.mediaPilar}
																						</span>
																					</div>
																				</div>
																			</div>
																		)}
																	</div>
																	<div>
																		<Form>
																			{listaPerguntas.map(perguntas => (pilarAtv == perguntas.pilares.id) &&
																				<div className="etapa-card card m-4" >
																					<div className="directory-content p-4">
																						<div className="titulo-generico">{perguntas.pilares.nome}</div>
																						<Row form>
																							<Col md={12}>
																								<FormGroup tag="fieldset">
																									{perguntas.radares.map(radar => (radar.id_pilar == pilarId) &&

																										radar.tipo == "multipla" ?
																										<div className="p-2" key={radar.id}>
																											<Label for={radar.id}>{radar.pergunta}</Label>
																											{radar.opcao1 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="1" checked={this.checked(listaRespostas, radar.id, 1)} disabled /> {radar.opcao1} </Label>
																												</FormGroup>
																											}
																											{radar.opcao2 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="2" checked={this.checked(listaRespostas, radar.id, 2)} disabled />{radar.opcao2}</Label>
																												</FormGroup>
																											}
																											{radar.opcao3 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="3" checked={this.checked(listaRespostas, radar.id, 3)} disabled />{radar.opcao3}</Label>
																												</FormGroup>
																											}
																											{radar.opcao4 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="4" checked={this.checked(listaRespostas, radar.id, 4)} disabled />{radar.opcao4}</Label>
																												</FormGroup>
																											}
																											{radar.opcao5 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="5" checked={this.checked(listaRespostas, radar.id, 5)} disabled />{radar.opcao5}</Label>
																												</FormGroup>
																											}
																											{radar.opcao6 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="6" checked={this.checked(listaRespostas, radar.id, 6)} disabled />{radar.opcao6}</Label>
																												</FormGroup>
																											}
																											{radar.opcao7 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="7" checked={this.checked(listaRespostas, radar.id, 7)} disabled />{radar.opcao7}</Label>
																												</FormGroup>
																											}
																											{radar.opcao8 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="8" checked={this.checked(listaRespostas, radar.id, 8)} disabled />{radar.opcao8}</Label>
																												</FormGroup>
																											}
																											{radar.opcao9 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="9" checked={this.checked(listaRespostas, radar.id, 9)} disabled />{radar.opcao9}</Label>
																												</FormGroup>
																											}
																											{radar.opcao10 &&
																												<FormGroup check>
																													<Label check> <Input type="radio" name={radar.id} value="10" checked={this.checked(listaRespostas, radar.id, 10)} disabled />{radar.opcao10}</Label>
																												</FormGroup>
																											}
																										</div>

																										: radar.tipo == "aberta" && radar.id_pilar == pilarId &&
																										<div className="p-2" key={radar.id} >
																											<Label for={radar.id}>{radar.pergunta}: </Label>
																											<Input className="m-2"
																												type="textarea"
																												value={radar.respostaAberta != null ? radar.respostaAberta : ""}
																												disabled
																											/>
																										</div>
																									)}

																								</FormGroup>
																							</Col>
																						</Row>
																					</div>
																				</div>
																			)}
																		</Form>
																	</div>
																</>
																: <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							: <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.participantes.erro_acesso} />
						: ""}
				</div>
			</>
		)
	}
}
const mapStateToProps = state => ({
	perguntas: state.startups.perguntas, // acessa o startupsReducer e joga para uma nova props radares
	respostas: state.startups.respostas,
	startup: state.startups.startup,
	usuarios: state.usuarios.usuarios,
	pilares: state.startups.pilares,
	detalhes: state.startups.detalhes,
	pertence: state.startups.pertence

})

export default connect(mapStateToProps, actions)(RadarResumo);