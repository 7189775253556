import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { portugues, ingles } from '../../utils/sidebar_bilingue';
import Titulo from '../../componentes/TituloPaginas';


import { Link } from 'react-router-dom';
class Dashboard extends Component {
    state = {
        usuario: "",
        msg: "",
        qntdeCredenciais: "",
        dadosApi: false,
        idioma: portugues
    }
    componentDidMount() {
        const { usuario } = this.props;
        this.setState({ usuario: usuario });
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state })
        }

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
        } else {
            this.setState({ idioma: portugues })
        }
    }

    componentDidUpdate(nextProps) {
        if (!this.props.credenciais && nextProps.credenciais) {
            this.props.getUsuarios();
        }
    }


    componentWillUnmount() {
        this.props.limparUsuario();
    }

    render() {
        const { credenciais, dadosApi, usuario } = this.props
        const { idioma } = this.state;
        ////////console.log(credenciais)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.dashboard} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {credenciais.length == 0 ?
                                <div className="row">
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton circle={true} height={100} width={100} />

                                                    <div className="subtitle">
                                                        <Skeleton count={1} width={80} />
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">

                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton circle={true} height={100} width={100} />

                                                    <div className="subtitle">
                                                        <Skeleton count={1} width={80} />
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">

                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton circle={true} height={100} width={100} />

                                                    <div className="subtitle">
                                                        <Skeleton count={1} width={80} />
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">

                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton circle={true} height={100} width={100} />

                                                    <div className="subtitle">
                                                        <Skeleton count={1} width={80} />
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton circle={true} height={100} width={100} />

                                                    <div className="subtitle">
                                                        <Skeleton count={1} width={80} />
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">

                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton circle={true} height={100} width={100} />

                                                    <div className="subtitle">
                                                        <Skeleton count={1} width={80} />
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                                :
                                <div className="row">
                                    {credenciais.map(credencial => ((credencial.id_componente == 10) ?
                                        <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                            <Link to={`/${usuario?.personalizacao?.titulo}/startups/painel`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/minhasStartups.png" />
                                                            <div className="subtitle">
                                                                {idioma.negocios}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 5) ?
                                        <div className="col-md-2 card-menu-perfil">
                                            <Link to={`/${usuario?.personalizacao?.titulo}/incubadoras/painel`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/minhaIncubadora.png" />
                                                            <div className="subtitle">
                                                                {idioma.ambiente_inovacao}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 1) ?
                                        <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                            <Link to={`/${usuario?.personalizacao?.titulo}/ecossistema`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/ecossistema.png" />
                                                            <div className="subtitle">
                                                                {idioma.ecossistema}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 2) ?
                                        <div className="col-md-2 card-menu-perfil" key={credencial.id_componente} >
                                            <Link to={`/${usuario?.personalizacao?.titulo}/eventos`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/evento.png" />
                                                            <div className="subtitle">
                                                                {idioma.eventos}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 3) ?
                                        <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                            <Link to={`/${usuario?.personalizacao?.titulo}/conteudos`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/conteudos.png" />
                                                            <div className="subtitle">
                                                                {idioma.conteudos}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 4) ?
                                        <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                            <Link to={`/${usuario?.personalizacao?.titulo}/trilhas`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/trilha.png" />
                                                            <div className="subtitle">
                                                                {idioma.trilhas}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 5) ?
                                        <div className="col-md-2 mt-2" key={credencial.id_componente}>
                                            <Link to={`/${usuario?.personalizacao?.titulo}/gestao`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/gestao.png" />
                                                            <div className="subtitle">
                                                                {idioma.gestao_negocios}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 6) ?
                                        <div className="col-md-2 mt-2" key={credencial.id_componente}>
                                            <Link to={`/${usuario?.personalizacao?.titulo}/hub`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/inovacoes.png" />
                                                            <div className="subtitle">
                                                                {idioma.hub_inovacao}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {/* {credenciais.map(credencial => ((credencial.id_componente == 76) ?
                                        <div className="col-md-2 mt-2" >
                                            <Link to="#">
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/mentores.png" />
                                                            <div className="subtitle">
                                                                Mentores
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))} */}
                                    {credenciais.map(credencial => ((credencial.id_componente == 79) ?
                                        <div className="col-md-2 mt-2" >
                                            <Link to={`/${usuario?.personalizacao?.titulo}/beneficios`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/beneficios.png" />
                                                            <div className="subtitle">
                                                                {idioma.beneficios}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 77) ?
                                        <div className="col-md-2 mt-2" >
                                            <Link to={`/${usuario?.personalizacao?.titulo}/relatorios`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/relatorios.png" />
                                                            <div className="subtitle">
                                                                {idioma.relatorios}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                    {credenciais.map(credencial => ((credencial.id_componente == 78) ?

                                        <div className="col-md-2 mt-2" >
                                            <Link to={`/${usuario?.personalizacao?.titulo}/chat`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/relatorios.png" />
                                                            <div className="subtitle">
                                                                {idioma.chat}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}

                                    {credenciais.map(credencial => ((credencial.id_componente == 7) ?
                                        <div className="col-md-2 mt-2" key={credencial.id_componente}>
                                            <Link to={`/${usuario?.personalizacao?.titulo}/configuracoes`}>
                                                <Card className="card-dash">
                                                    <CardBody className="img-text-wrapper">
                                                        <div className="logo-wrapper">
                                                            <img src="/imagens/configuracoes.png" />
                                                            <div className="subtitle">
                                                                {idioma.config}
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Link>
                                        </div>
                                        : ""))}
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(Dashboard);