import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import Titulo from '../../componentes/TituloPaginas';


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class CadastrarAula extends Component {
    state = {
        nome: "",
        duracao: "",
        id_materia: "",
        id_atividade: "",
        id_trilha: "",
        id_curso: "",
        id_modulo_selecionado: "",
        id_modulo: "",
        data_publicacao: "",
        tipo_questao: "",
        ordem: "",
        descricao: "",
        conteudo: "",
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        idioma: portugues,
        editorState: "",
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha })
        this.listar(id_trilha)
        if (this.props.location.state != undefined) {
            this.setState({ id_modulo: this.props.location.state.id_modulo })
            this.listarMateria(this.props.match.params.id_trilha, this.props.location.state.id_modulo)
            this.setState({ id_modulo_selecionado: this.props.location.state.id_modulo })
            this.setState({ id_materia: this.props.location.state.id_materia })

        }

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
        //////////////console.log(id_trilha)
    }

    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.aula && nextProps.aula) {
            this.listar(id_trilha);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparModulos()
    }

    receberDadosAPI() {
        if (typeof this.props.modulos != "undefined" && this.props.modulos != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.materias != "undefined" && this.props.materias != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }


    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    cadAtividade(ev) {
        ev.preventDefault();
        const { nome, ordem, id_trilha, id_modulo, id_materia, conteudo, duracao, editorState, data_publicacao } = this.state;
        var tempo_maximo = duracao;
        if (!this.validate()) return;

        var id_curso = id_trilha
        this.setState({ carregando: true })
        var descricao = editorState

        let newData = { nome, ordem, id_curso, id_modulo, descricao, tempo_maximo, data_publicacao, id_materia };

        this.props.postAtividade(newData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                //////console.log(msg)
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ id_atividade: msg.erro.id_atividade });
                this.setState({ formSuccess: true });

            }
        }
        )
    }

    validate() {
        const { nome, id_modulo, duracao, idioma, id_materia, descricao, ordem, data_publicacao } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrarAtividade.erro_nome } });
        if (!duracao) return this.setState({ erro: { message: idioma.cadastrarAtividade.erro_duracao } });
        if (!ordem) return this.setState({ erro: { message: idioma.cadastrarAtividade.erro_ordem } });
        if (!id_modulo) return this.setState({ erro: { message: idioma.cadastrarAtividade.erro_modulo } });
        if (!id_materia) return this.setState({ erro: { message: idioma.cadastrarAtividade.erro_materia } });
        if (!data_publicacao) return this.setState({ erro: { message: idioma.cadastrarAtividade.erro_data_publicacao } });
        return true;
    }

    listar(id_trilha) {
        this.props.getModulos(id_trilha)
        const { trilhas } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilhas === "undefined") return null
    }

    listarMateria(id_trilha, id_modulo) {
        ////////////console.log(id_modulo)
        this.props.getMaterias(id_trilha, id_modulo)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (id_trilha === "undefined") return null
    }


    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    handleGetBack(ev) {
        const { id_trilha } = this.state;
        ev.preventDefault();
        this.props.history.push(`/${this.props.usuario?.personalizacao?.titulo}/trilhas/${id_trilha}/modulos/`)
    }

    // onEditorStateChangeInicial = (content) => {
    //     this.setState({
    //         editorState: EditorState.createWithContent(
    //             ContentState.createFromBlockArray(
    //                 convertFromHTML(content)
    //             )

    //         ),
    //     })
    // }

    handleSelectedModulo(campo, ev) {
        const { id_modulo_selecionado } = this.state
        this.setState({ [campo]: ev.target.value })
        this.setState({ id_modulo_selecionado: ev.target.value })
        this.listarMateria(this.state.id_trilha, ev.target.value)
    }

    render() {
        const { nome, duracao, id_trilha, id_atividade, id_materia, id_modulo, idioma, id_aula, data_publicacao, descricao, id_modulo_selecionado, ordem, tipo_questao, erro, success, carregando, iconSalvar, formSuccess, editorState } = this.state;
        var materias = [];
        if (this.props.materias) materias = this.props.materias.dados;
        var modulos = [];
        if (this.props.modulos) modulos = this.props.modulos.dados.modulos;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/editar",
            state: { msg: idioma.cadastrarAtividade.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarAtividade.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <div>
                                <Form>
                                    <Row form>
                                        <Col md={8}>
                                            <FormGroup>
                                                <Label for="nome">{idioma.cadastrarAtividade.nome}</Label>
                                                <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrarAtividade.nome} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="duracao">{idioma.cadastrarAtividade.tarefa}</Label>
                                                <Input type="number" value={duracao} onChange={(ev) => this.onChnageInput("duracao", ev)} id="duracao" name="duracao" placeholder={idioma.cadastrarAtividade.tarefa2} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <Label for="ordem">{idioma.cadastrarAtividade.selecione}</Label>
                                            <Input type="number" value={ordem} onChange={(ev) => this.onChnageInput("ordem", ev)} id="ordem" name="ordem" />
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Label for="descricao">{idioma.cadastrarAtividade.desc}</Label>
                                        <Card className="card-editor">
                                            <div id='editor'>
                                                <ReactQuill
                                                    theme='snow'
                                                    value={editorState}
                                                    bounds={'.app'}
                                                    onChange={
                                                        (content) => {
                                                            this.onEditorStateChange(content)
                                                        }}
                                                    modules={{
                                                        toolbar: {
                                                            container: [
                                                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                ['bold', 'italic', 'underline'],
                                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                [{ 'align': [] }],
                                                                ['link', 'image'],
                                                                ['clean'],
                                                                [{ 'color': [] }]
                                                            ],
                                                        },
                                                    }}
                                                />
                                            </div>
                                        </Card>
                                        {/* <Input type="textarea" value={descricao} autoComplete="descricao" onChange={(ev) => this.onChnageInput("descricao", ev)} name="descricao" id="descricao" placeholder={idioma.cadastrarAtividade.desc} /> */}
                                    </FormGroup>
                                    <Row>
                                        <Col md={4}>
                                            <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <Label for="modulo">{idioma.cadastrarAtividade.modulo}</Label>
                                            <Input type="select" onChange={(ev) => this.handleSelectedModulo("id_modulo", ev)} id="id_modulo" name="id_modulo" >
                                                <option>{idioma.cadastrarAtividade.escolher}</option>
                                                {modulos.map(modulo => (
                                                    <option value={modulo.modulo.id} key={modulo.modulo.id} selected={modulo.modulo.id == id_modulo ? true : false}>
                                                        {modulo.modulo.nome}
                                                    </option>))}
                                            </Input>

                                        </Col>
                                        <Col md={4}>
                                            <Label for="materia">{idioma.cadastrarAtividade.materia}</Label>
                                            <Input type="select" onChange={(ev) => this.onChnageInput("id_materia", ev)} id="id_materia" name="id_materia" >
                                                <option>{idioma.cadastrarAtividade.escolher2}</option>
                                                {materias.map(materia => (
                                                    id_modulo_selecionado != "" ?
                                                        <option value={materia.materia.id} key={materia.materia.id} selected={materia.materia.id == id_materia ? true : false}>
                                                            {materia.materia.nome}
                                                        </option> : ""
                                                ))}
                                            </Input>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="data_publicacao">{idioma.cadastrarAtividade.data}</Label>
                                                <Input type="date" value={data_publicacao} autoComplete="data_publicacao" onChange={(ev) => this.onChnageInput("data_publicacao", ev)} name="data_publicacao" id="data_publicacao" placeholder={idioma.cadastrarAtividade.data} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Link to='' onClick={(e) => this.cadAtividade(e)}> <Botao loading={carregando} texto={idioma.cadastrarAtividade.bt_cadastrar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                    <Link to='' onClick={(e) => this.handleGetBack(e)}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    trilhas: state.trilhas.trilhas,
    modulos: state.trilhas.modulos,
    materias: state.trilhas.materias,
    aulas: state.trilhas.aulas
})

export default connect(mapStateToProps, actions)(CadastrarAula);