export const portugues = {
  titulo: 'Usuários',
  infos: 'Informações',
  bt_conversar: 'Conversar',
  editar: 'Editar',
  editar_senha: 'Editar senha',
  editar_idioma: 'Editar idioma',
  bt_editar: 'Editar usuário',
  bt_senha: 'Editar senha',
  bt_voltar: 'Voltar',
  bt_filtrar: 'Filtrar',
  bt_cancel_filtro: 'Cancelar filtro',
  excluir: 'Excluir',
  del: 'Deletar',
  nome: 'Nome:',
  email: 'E-mail:',
  data_criacao: 'Data de criação:',
  perfil: 'Perfil:',
  incubadora: 'Ambientes de inovação vinculados:',
  startups: 'Negócios vinculados:',
  page1: 'Primeira',
  page2: 'Última',
  msg_sucesso: 'Usuário apagado com sucesso!',
  filtro: {
    filtro_select: 'Escolha o tipo do filtro',
    inovacao: 'Ambiente de inovação',
    negocio: 'Negócio',
    perfil: 'Perfil',
    escolha_filtro: 'Escolha um filtro antes',
    escolha_inovacao: 'Escolha o ambiente de inovação',
    escolha_negocio: 'Escolha o negócio',
    escolha_perfil: 'Escolha o perfil',
    escolha_todo: 'Escolha o perfil, ambiente de inovação ou negócio',
    pesquisar: 'Pesquisar...',
    bt_pesquisar: 'Pesquisar',
  },
  tabela: {
    nome: 'Nome',
    inovacao: 'Ambiente de inovação',
    negocio: 'Negócio',
    acao: 'Ação',
    bt_perfil: 'Perfil',
    bt_desativar: 'Desativar',
    bt_ativar: 'Ativar'
  },
  modal_password: {
    titulo: 'Selecione um idioma',
    idioma: 'Idioma',
    portugues: 'Português',
    ingles: 'Inglês',
    bt_salvar: 'Salvar',
    bt_cancelar: 'Cancelar'
  },
  modal_excluir: {
    titulo: 'Você tem certeza?',
    info: 'Não será possível restaurar esse usuário!',
    bt_cancelar: 'Cancelar',
    bt_sucesso: 'Sim, desativar esse usuário!',
    info_sucesso: 'Usuário desativado com sucesso!'
  },
  modal_ativar: {
    titulo: 'Você tem certeza?',
    bt_cancelar: 'Cancelar',
    bt_sucesso: 'Sim, ativar este usuário!',
    info_sucesso: 'Usuário ativado com sucesso'
  },
  modal_desativar: {
    titulo: 'Você tem certeza?',
    bt_cancelar: 'Cancelar',
    bt_sucesso: 'Sim, desativar este usuário!',
    info_sucesso: 'Usuário desativado com sucesso'
  },
  cadastrar: {
    msg_sucesso: 'Usuário cadastrado com sucesso!',
    titulo: 'Cadastrar usuário',
    label_nome: 'Nome Completo',
    label_email: 'E-mail',
    label_senha: 'Senha',
    label_perfil: 'Perfil',
    place_perfil: 'Escolher Perfil',
    selecioneIdioma: 'Selecione o idioma',
    label_idioma: 'Idioma',
    portugues: 'Portugues',
    ingles: 'Inglês',
    bt_cadastrar: 'Cadastrar',
    validate: {
      nome: 'Preencha o nome completo',
      email: 'Preencha o e-mail',
      email2: 'Preencha um e-mail válido',
      senha: 'Preencha a senha',
      senha_length: 'Preencha a senha com no mínimo 6 caracteres',
      credencial: 'Preencha a credencial',
      idioma: 'Selecione um idioma'
    }
  },
  pagina_editar: {
    titulo: 'Editar usuário',
    nome: 'Nome Completo',
    email: 'E-mail',
    perfil: 'Perfil',
    bt_salvar: 'Salvar',
    erro_nome: 'Preencha o nome',
    erro_email: 'Preencha o e-mail',
    info_sucesso: 'Usuário editado com sucesso!',
    carregando: 'Carregando...'
  },
  pagina_editar_senha: {
    titulo: 'Editar senha',
    senha: 'Senha',
    bt_salvar: 'Salvar',
    erro_senha: 'Preencha a senha',
    info_senha: 'A senha deve ter pelo menos 6 caracteres',
    info_sucesso: 'Senha do usuário editado com sucesso!'
  }

}

export const ingles = {
  titulo: 'Users',
  infos: 'Informations',
  editar: 'Edit',
  editar_senha: 'Edit password',
  editar_idioma: 'Edit language',
  bt_conversar: 'Chat',
  bt_editar: 'Edit user',
  bt_senha: 'Edit password',
  bt_voltar: 'Back',
  bt_filtrar: 'Filter',
  bt_cancel_filtro: 'Cancel filter',
  excluir: 'Delete',
  del: 'Delete',
  nome: 'Name:',
  email: 'E-mail:',
  data_criacao: 'Creation date:',
  perfil: 'Profile:',
  incubadora: 'Linked Innovation space:',
  startups: 'Linked business:',
  page1: 'First',
  page2: 'Last',
  msg_sucesso: 'User successfully deleted!',
  filtro: {
    filtro_select: 'Select a filter type',
    inovacao: 'Innovation space',
    negocio: 'Business',
    perfil: 'Profile',
    escolha_filtro: 'Select a filter first',
    escolha_inovacao: 'Select a innovation space',
    escolha_negocio: 'Select a business',
    escolha_perfil: 'Select a profile',
    escolha_todo: 'Select a profile, innovation space or a business',
    pesquisar: 'Search...',
    bt_pesquisar: 'Search',
  },
  tabela: {
    nome: 'Name',
    inovacao: 'Innovation space',
    negocio: 'Business',
    acao: 'Options',
    bt_perfil: 'Profile',
    bt_desativar: 'Disable',
    bt_ativar: 'Activate'
  },
  modal_password: {
    titulo: 'Select a language',
    idioma: 'Language',
    portugues: 'Portuguese',
    ingles: 'English',
    bt_salvar: 'Save',
    bt_cancelar: 'Cancel'
  },
  modal_excluir: {
    titulo: 'Are you sure?',
    info: 'It will not be possible to restore this user!',
    bt_cancelar: 'Cancel',
    bt_sucesso: 'Yes, disable this user',
    info_sucesso: 'User successfully disabled!'
  },
  modal_ativar: {
    titulo: 'Are you sure?',
    bt_cancelar: 'Cancel',
    bt_sucesso: 'Yes, enable this user!',
    info_sucesso: 'User successfully enabled!'
  },
  modal_desativar: {
    titulo: 'Are you sure?',
    bt_cancelar: 'Cancel',
    bt_sucesso: 'Yes, disable this user!',
    info_sucesso: 'User successfully disabled!'
  },
  cadastrar: {
    msg_sucesso: 'User successfully registered',
    titulo: 'Register user',
    label_nome: 'Full Name',
    label_email: 'E-mail',
    label_senha: 'Password',
    label_perfil: 'Profile',
    place_perfil: 'Choose Profile',
    selecioneIdioma: 'Choose the language',
    label_idioma: 'Language',
    portugues: 'Portuguese',
    ingles: 'English',
    bt_cadastrar: 'Register',
    validate: {
      nome: 'Full name must be filled',
      email: 'E-mail must be filled',
      email2: 'Fill in a valid e-mail',
      senha: 'Password must be filled',
      senha_length: 'Password must be at least 6 characters long',
      credencial: 'Profile must be filled',
      idioma: 'Select a language'
    }
  },
  pagina_editar: {
    titulo: 'Edit user',
    nome: 'Full Name',
    email: 'E-mail',
    perfil: 'Profile',
    bt_salvar: 'Save',
    erro_nome: 'Name must be filled',
    erro_email: 'E-mail must be filled',
    info_sucesso: 'User successfully edited!',
    carregando: 'Loading...'
  },
  pagina_editar_senha: {
    titulo: 'Edit password',
    senha: 'Password',
    bt_salvar: 'Save',
    erro_senha: 'Password must be filled',
    info_senha: 'Password must be at least 6 characters long',
    info_sucesso: 'User password successfully edited!'
  }
}