import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/conteudos';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import BlocoPerfil from '../../containers/blocos/perfilConteudo';

import Botao from '../../componentes/Botoes';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import Skeleton from "react-loading-skeleton";

import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginaconteudo_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class EditarConteudo extends Component {
    state = {
        id_conteudo: "",
        id_usuario: "",
        titulo: "",
        descricao: "",
        id_tipo: "",
        erro: "",
        success: "",
        file: null,
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_conteudo } = this.props.match.params;
        this.props.getVerConteudo(id_conteudo);
        this.props.getTipoConteudo();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }


    async componentDidUpdate(nextProps) {
        const { id_conteudo } = this.props.match.params;
        if (!this.props.conteudo && nextProps.conteudo) this.props.getVerConteudo(id_conteudo);
        await this.receberDadosAPI();
    }


    componentWillUnmount() {
        this.props.limparConteudo();
    }

    receberDadosAPI() {
        const { id_conteudo } = this.props.match.params;
        if (typeof this.props.conteudo !== "undefined" && this.props.conteudo !== null && this.props.conteudo.id == id_conteudo && !this.state.dadosAPI) {
            this.setState({ id_conteudo: this.props.conteudo.id })
            this.setState({ titulo: this.props.conteudo.titulo })
            this.setState({ descricao: this.props.conteudo.descricao })
            this.setState({ id_tipo: this.props.conteudo.id_tipo })
            this.setState({ id_usuario: this.props.conteudo.id_usuario })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    async atualizarConteudo(id, file) {
        const { msg_backend } = this.state;

        this.setState({ success: "" });
        this.setState({ erro: "" });

        await this.receberDadosForm();

        if (!this.validate()) return;

        const formData = new FormData();

        formData.append('file', file);
        formData.append('id', id);

        this.setState({ carregando: true })

        this.props.putConteudoImagem(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }
    receberDadosForm() {
        this.setState({ file: document.querySelector("#file").value })
    }
    validate() {
        const { file, idioma } = this.state;
        if (!file) return this.setState({ erro: { message: idioma.editarCapa.erro_arq } });
        return true;
    }
    render() {
        const { id_conteudo, file, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;
        var tipos = [];
        var conteudo = null;
        if (this.props.conteudo) conteudo = this.props.conteudo;
        if (this.props.tipos) tipos = this.props.tipos.dados;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        if (formSuccess) return <Redirect to={{
            pathname: "/conteudo/" + id_conteudo,
            state: { msg: idioma.editarCapa.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editarCapa.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {conteudo != null ?
                        <BlocoPerfil conteudo={conteudo} dadosApi={dadosAPI} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" >
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    <Form>
                                        <Input type="hidden" value={id_conteudo} id="id_conteudo" name="id_conteudo" />
                                        <FormGroup>
                                            <Label for="file">{idioma.editarCapa.img}</Label>
                                            <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                        </FormGroup>
                                        <Link onClick={() => this.atualizarConteudo(id_conteudo, file)} to="#">
                                            <Botao loading={carregando} texto={idioma.editarCapa.bt_salvar} classes="botao-responsivo botao-estilo" />
                                        </Link>

                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.editarCapa.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    conteudo: state.conteudos.conteudo,
    tipos: state.conteudos.tipoConteudo


})
export default connect(mapStateToProps, actions)(EditarConteudo)