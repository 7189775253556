import axios from 'axios';
import {
    GET_USERS, GET_USER, LIMPAR_USER, LIMPAR_USERS, LOGIN_USER, LOGOUT_USER, GET_PERFIS, GET_SEARCH,
    GET_INCUBADORAS_COMPLETO, GET_USUARIOS_PERFIL, GET_STARTUPS_COMPLETO, GET_PERFIS_ACESSO,
    GET_PARTICIPANTES, GET_USUARIOS_INCUBADORA, GET_LANGUAGE
} from './types'
import { api } from '../../config';
import { getHeaders, clearToken, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getUsuarios = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USERS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuariosIncubadora = (id_incubadora, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadora/${id_incubadora}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_USUARIOS_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getIncubadorasCompleto = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/completo?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_INCUBADORAS_COMPLETO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuariosPerfil = (id_perfil, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/perfil/${id_perfil}/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_USUARIOS_PERFIL, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getStartupsCompleto = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/completo?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS_COMPLETO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPerfisAcesso = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/credenciais/perfis?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_PERFIS_ACESSO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getParticipantes = (id_startup, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id_startup}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_PARTICIPANTES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerUsuario = (id) => {
    return function (dispatch) {
        axios.get(api + `/usuario/${id}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USER, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getSearch = (procura, paginaAtual, limite) => {
    ////console.log(procura)
    return function (dispatch) {
        axios.get(api + `/usuarios/buscar?procura=${procura}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_SEARCH, payload: response })
            })
            .catch(errorHandle)
    }
}


export const postUsuario = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.post(api + `/usuario`, dadosUsuario, getHeadersPost())
            .then((response) => {

                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const recupSenha = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.post(api + `/recuperar-senha`, dadosUsuario, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const validarChave = (recuperarSenha, callback) => {
    return function (dispatch) {
        axios.get(api + `/recuperar-senha/${recuperarSenha}`)
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const atualizarSenha = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/recuperar-senha`, dadosUsuario)
            .then((response) => {
                clearToken();
                dispatch({ type: LOGOUT_USER })
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const putUsuario = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/usuario/editar`, dadosUsuario, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putAtivarUsuario = (id, callback) => {
    return function (dispatch) {
        axios.put(api + `/usuario/${id}/ativar`, id, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putDesativarUsuario = (id, callback) => {
    return function (dispatch) {
        axios.put(api + `/usuario/${id}/desativar`, id, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putUsuarioSenha = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/usuario/editar/senha`, dadosUsuario, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const putUserIdioma = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/usuario/editar/idioma`, dadosUsuario, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putUsuarioIdioma = (idioma) => {
    return function (dispatch) {
        dispatch({ type: GET_LANGUAGE, payload: idioma })
    }
}

export const putPerfil = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/perfil/editar`, dadosUsuario, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const putPerfilSenha = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/perfil/editar`, dadosUsuario, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const putPerfilFoto = (dadosUsuario, callback) => {
    return function (dispatch) {
        axios.put(api + `/perfil/imagem`, dadosUsuario, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const verPerfil = (callback) => {
    return function (dispatch) {
        axios.get(api + '/perfil', getHeaders())
            .then((response) => {
                dispatch({ type: LOGIN_USER, payload: response.data });
                callback({ erro: response.data });
            })
            .catch((err) => {
                dispatch({ type: LOGOUT_USER })
                callback(errorHandle(err))
            })

    }
}
export const deletarUsuario = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/usuario/${id}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const limparUsuario = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_USER })
    }
}
export const limparUsuarios = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_USERS })
    }
}



export const getPerfis = () => {
    return function (dispatch) {
        axios.get(api + `/perfis/`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PERFIS, payload: response })
            })
            .catch(errorHandle)
    }
}
