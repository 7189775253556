import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner, Table, Input } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg';
import { format, differenceInDays } from 'date-fns';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class VincularParticipantes extends Component {
  state = {
    id_startup: '',
    id_usuario: '',
    id_incubadora: '',
    currentPage: 1,
    size: 20,
    msg: '',
    erro: '',
    success: '',
    pertence: false,
    carregando: false,
    pesquisando: false,
    procura: '',
    modal: false,
    dadosApi: false,
    idioma: portugues,
    msg_backend: portuguese,
  };
  componentDidMount() {
    const { id_startup, id_incubadora, id_usuario } = this.props.match.params;
    this.setState({ id_startup });
    this.setState({ id_incubadora });

    this.listarUsuarios();
    this.listarStartup(id_startup);
    this.verificaPertence(this.props.usuario.id, id_startup);

    if (this.props.usuario && this.props.credenciais)
      this.handleButtons(
        this.props.usuario,
        this.props.credenciais,
        id_startup,
        id_incubadora,
      );

    const { usuario } = this.props;
    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }
  componentDidUpdate(nextProps) {
    const { id_startup } = this.props.match.params;
    if (!this.props.usuarios && nextProps.usuarios) this.listarUsuarios();
    if (!this.props.startup && nextProps.startup) {
      this.listarStartup(id_startup);
    }
    if (!this.props.pertence && nextProps.pertence) {
      this.verificaPertence(this.props.usuario.id, id_startup);
    }

    this.receberDadosAPI();
  }

  componentWillUnmount() {
    this.props.limparParticipantes();
  }

  receberDadosAPI() {
    if (
      typeof this.props.usuarios != 'undefined' &&
      this.props.usuarios != null &&
      !this.state.dadosApi &&
      this.props.usuarios.currentPage === this.state.currentPage
    ) {
      this.setState({ dadosApi: true });
    }
    if (
      typeof this.props.startup != 'undefined' &&
      this.props.startup != null &&
      !this.state.dadosApi
    ) {
      if (
        typeof this.props.pertence != 'undefined' &&
        this.props.pertence != null &&
        !this.state.dadosApi
      ) {
        this.setState({ dadosApi: true });
      }
    }
  }
  usuarioNovo(data) {
    var result = differenceInDays(new Date(), data);
  }
  listarUsuarios() {
    const { currentPage, size } = this.state;
    this.props.getUsuarios(currentPage, size);
    const { usuarios } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (usuarios === 'undefined') return null;
  }
  changePageAtual(currentPage) {
    this.props.limparParticipantes();
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.listarUsuarios();
    });
  }

  changePageAtualSearch(currentPage) {
    const { procura, size } = this.state;
    this.props.limparUsuarios();
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.props.getSearch(procura, currentPage, size);
    });
  }

  listarStartup(id_startup) {
    this.props.getVerStartup(id_startup);
    const { startup } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (startup === 'undefined') return null;
  }

  apagarParticipante(id) {
    this.props.limparParticipantes();
    this.setState({ dadosApi: false });
    this.setState({ carregando: true });
    this.props.deletarParticipante(id, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
        this.listarUsuarios();
        this.sucessoAlerta();
        // this.setState({ formSuccess: true });
      }
    });
  }

  vincularParticipante(id_usuario) {
    const { id_startup } = this.state;
    ////////////console.log(id_usuario, id_startup)
    this.setState({ carregando: true });
    this.props.postVincularParticipante({ id_usuario, id_startup }, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: 'Participante vinculado com sucesso!' },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.sucessoAlerta();
      }
    });
  }

  chamarAlerta(id) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.modal_deletar.titulo,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: idioma.participantes.bt_cancelar,
      confirmButtonText: idioma.vincularParticipante.modal_titulo,
    }).then((result) => {
      if (result.value) {
        this.vincularParticipante(id);
      }
    });
  }
  sucessoAlerta() {
    const { idioma } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: idioma.vincularParticipante.modal_sucesso,
    });
  }

  handleSearch(procura) {
    this.setState({ procura: procura });
    this.setState({ pesquisando: true });
    // this.props.limparUsuarios()
    const { currentPage, size } = this.state;
    this.setState({ currentPage: 1 });
    this.props.getSearch(procura, currentPage, size);
  }

  eraseSearch(campo) {
    this.setState({ pesquisando: false });
    this.setState({ [campo]: '' });
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value });
  }

  handleEnter(ev) {
    const { procura } = this.state;
    if (ev.key === 'Enter') {
      this.handleSearch(procura);
    }
  }

  handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
    if (usuarios.startups.length > 0) {
      for (let u in usuarios.startups) {
        if (usuarios.startups[u].id_startup == id_startup) {
          this.setState({ pertence: true });
        }
      }
    }
    if (usuarios.incubadoras.length > 0) {
      for (let u in usuarios.incubadoras) {
        if (usuarios.incubadoras[u].id_incubadora == id_incubadora) {
          this.setState({ pertence: true });
        }
      }
    }
    if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
      this.setState({ pertence: true });
    }
  }

  verificaPertence(id_usuario, id_startup) {
    this.props.getVerificarPertence(id_usuario, id_startup);
    const { pertence } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (pertence === 'undefined') return null;
    // console.log(pertence)
  }

  render() {
    const {
      id_startup,
      pertence,
      msg,
      carregando,
      erro,
      success,
      dadosApi,
      formSuccess,
      procura,
      pesquisando,
      idioma,
    } = this.state;
    var usuarios = [];
    if (pesquisando == false) {
      if (this.props.usuarios) usuarios = this.props.usuarios.dados;
      var temPaginaAnterior = false;
      if (
        typeof this.props.usuarios != 'undefined' &&
        this.props.usuarios != null &&
        this.props.usuarios.currentPage !== '' &&
        this.props.usuarios.currentPage !== 1
      ) {
        temPaginaAnterior = true;
      }
      var proximaPagina = false;
      var temProximaPagina = false;
      if (
        typeof this.props.usuarios != 'undefined' &&
        this.props.usuarios !== null &&
        this.props.usuarios.nextPage <= this.props.usuarios.totalPages &&
        this.props.usuarios.nextPage != null
      ) {
        proximaPagina = true;
        temProximaPagina = true;
      }
    } else {
      if (this.props.search) usuarios = this.props.search.dados;
      var temPaginaAnteriorSearch = false;
      if (
        typeof this.props.search != 'undefined' &&
        this.props.search != null &&
        this.props.search.currentPage !== '' &&
        this.props.search.currentPage !== 1
      ) {
        temPaginaAnteriorSearch = true;
      }
      var proximaPaginaSearch = false;
      var temProximaPaginaSearch = false;
      if (
        typeof this.props.search != 'undefined' &&
        this.props.search !== null &&
        this.props.search.nextPage <= this.props.search.totalPages &&
        this.props.search.nextPage != null
      ) {
        proximaPaginaSearch = true;
        temProximaPaginaSearch = true;
      }
    }

    var startup = null;
    if (this.props.startup) startup = this.props.startup;
    var usuario = [];
    if (this.props.usuario) usuario = this.props.usuario;
    var path = this.props.match.path;
    var credenciais = [];
    if (this.props.credenciais) credenciais = this.props.credenciais;
    var usuarioPertence = [];
    if (this.props.pertence) usuarioPertence = this.props.pertence;

    if (formSuccess)
      return (
        <Redirect
          to={{
            pathname: '/startups/' + id_startup + '/participantes',
            state: { msg: 'Negócio cadastrado com sucesso!' },
          }}
        ></Redirect>
      );

    //console.log(usuarios, this.props.search)
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario?.personalizacao?.cor_principal,
          }}
        >
          <Titulo titulo={idioma.vincularParticipante.titulo} />
        </div>

        <div className="container-fluid bloco-topo">
          {startup != null ? (
            usuarioPertence.pertence && (
              <BlocoPerfil
                dadosApi={dadosApi}
                startup={startup}
                usuario={usuario}
                path={path}
                credenciais={credenciais}
              />
            )
          ) : (
            <div className="col-md-12 mb-2">
              <div
                className="list-group-item"
                style={{
                  backgroundColor:
                    this.props.usuario &&
                    this.props.usuario.personalizacao?.cor_quaternaria,
                }}
              >
                <div className="row">
                  <div className="col-auto centralizado">
                    <div className="img-perfil">
                      <Skeleton circle={true} height={120} width={120} />
                    </div>
                  </div>
                  <div className="col-md-9 centralizado texto-centralizado">
                    <h2 className="nomeperfil">
                      <Skeleton count={1} width={160} />
                    </h2>
                    <p className="text-muted">
                      <Skeleton count={1} width={160} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 mb-2">
            <div
              className="list-group-item"
              style={{
                backgroundColor:
                  this.props.usuario &&
                  this.props.usuario.personalizacao?.cor_quaternaria,
              }}
            >
              <div className="row mt-3 mb-3">
                <div className="col-lg-8">
                  <Input
                    type="text"
                    value={procura}
                    onKeyPress={(ev) => this.handleEnter(ev)}
                    autoComplete="procura"
                    onChange={(ev) => this.onChangeInput('procura', ev)}
                    name="procura"
                    id="procura"
                    placeholder={idioma.vincularParticipante.pesquisar}
                  />
                </div>
                <div className="col-lg-4">
                  <div>
                    <span
                      className="pr-3"
                      onClick={() => this.handleSearch(procura)}
                      id="pesquisa"
                      name="pesquisa"
                    >
                      <Botao
                        loading={carregando}
                        classes="botao-pesquisa-responsivo"
                        color={
                          this.props.usuario?.personalizacao?.cor_terciaria
                        }
                        texto={idioma.vincularParticipante.bt_pesquisar}
                        icone="search"
                        posicao="E"
                      />
                    </span>
                    <span
                      value={procura}
                      onClick={() => this.eraseSearch('procura')}
                      id="pesquisa"
                      name="pesquisa"
                    >
                      <Botao
                        loading={carregando}
                        classes="botao-pesquisa-responsivo"
                        cor="danger"
                        texto={idioma.participantes.bt_cancelar}
                        icone="times"
                        posicao="E"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-12 p-2">
                  {msg ? <AlertaSuccess erros={{ message: msg }} /> : ''}
                  <AlertaErro erros={erro} />
                  <AlertaSuccess erros={success} />
                  {dadosApi ? (
                    usuarioPertence.pertence ? (
                      <div className="row table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th>{idioma.vincularParticipante.nome}</th>
                              <th>{idioma.vincularParticipante.ambiente}</th>
                              <th>{idioma.vincularParticipante.negocio}</th>
                              <th>{idioma.vincularParticipante.acao}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {usuarios.map((usuario) => (
                              <tr key={usuario.id}>
                                <td>{usuario.nome}</td>
                                <td>
                                  {usuario.incubadoras.length > 0 ? (
                                    usuario.incubadoras.length == 1 ? (
                                      usuario.incubadoras.map(
                                        (incubadora) => incubadora,
                                      )
                                    ) : (
                                      usuario.incubadoras.map(
                                        (incubadora) => incubadora + ', ',
                                      )
                                    )
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      {' '}
                                      <FontAwesomeIcon
                                        icon="times"
                                        size="lg"
                                      />{' '}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {usuario.startups.length > 0 ? (
                                    usuario.startups.length == 1 ? (
                                      usuario.startups.map((startup) => startup)
                                    ) : (
                                      usuario.startups.map(
                                        (startup) => startup + ', ',
                                      )
                                    )
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      {' '}
                                      <FontAwesomeIcon
                                        icon="times"
                                        size="lg"
                                      />{' '}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <span
                                    className="pr-3"
                                    onClick={() =>
                                      this.chamarAlerta(usuario.id)
                                    }
                                    id="pesquisa"
                                    name="pesquisa"
                                  >
                                    <Botao
                                      loading={carregando}
                                      color={
                                        this.props.usuario?.personalizacao
                                          ?.cor_terciaria
                                      }
                                      texto={
                                        idioma.editarMetodologia.bt_vincular2
                                      }
                                      icone="sign-in-alt"
                                      posicao="E"
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <SemDados
                        subtitulo={idioma.sem_dados_sub}
                        titulo={idioma.participantes.erro_acesso}
                      />
                    )
                  ) : (
                    <div className="row table-responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th>
                              <Skeleton count={1} width={80} />
                            </th>
                            <th>
                              <Skeleton count={1} width={80} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton count={1} width={80} />
                            </td>
                            <td>
                              <Skeleton count={1} width={60} />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  )}
                  {dadosApi ? (
                    pesquisando == false ? (
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          <li
                            className={
                              temPaginaAnterior
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() => this.changePageAtual(1)}
                            >
                              {idioma.vincularParticipante.page1}
                            </span>
                          </li>

                          {temPaginaAnterior ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtual(
                                    this.props.usuarios.prevPage
                                      ? this.props.usuarios.prevPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.usuarios.prevPage
                                  ? this.props.usuarios.prevPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className="page-item active"
                            style={{
                              backgroundColor:
                                usuario?.personalizacao?.cor_principal,
                            }}
                          >
                            <span className="page-link cursor-pointer">
                              {this.props.usuarios
                                ? this.props.usuarios.currentPage
                                : '1'}
                            </span>
                          </li>

                          {proximaPagina ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtual(
                                    this.props.usuarios.nextPage
                                      ? this.props.usuarios.nextPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.usuarios.nextPage
                                  ? this.props.usuarios.nextPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className={
                              temProximaPagina
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() =>
                                this.changePageAtual(
                                  this.props.usuarios
                                    ? this.props.usuarios.totalPages
                                    : 0,
                                )
                              }
                            >
                              Última
                            </span>
                          </li>
                        </ul>
                      </nav>
                    ) : (
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          <li
                            className={
                              temPaginaAnteriorSearch
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() => this.changePageAtualSearch(1)}
                            >
                              {idioma.vincularParticipante.page1}
                            </span>
                          </li>

                          {temPaginaAnteriorSearch ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtualSearch(
                                    this.props.search.prevPage
                                      ? this.props.search.prevPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.search.prevPage
                                  ? this.props.search.prevPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className="page-item active"
                            style={{
                              backgroundColor:
                                usuario?.personalizacao?.cor_principal,
                            }}
                          >
                            <span className="page-link cursor-pointer">
                              {this.props.search
                                ? this.props.search.currentPage
                                : '1'}
                            </span>
                          </li>

                          {proximaPaginaSearch ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtualSearch(
                                    this.props.search.nextPage
                                      ? this.props.search.nextPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.search.nextPage
                                  ? this.props.search.nextPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className={
                              temProximaPaginaSearch
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() =>
                                this.changePageAtualSearch(
                                  this.props.search
                                    ? this.props.search.totalPages
                                    : 0,
                                )
                              }
                            >
                              {idioma.vincularParticipante.page2}
                            </span>
                          </li>
                        </ul>
                      </nav>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => ({
  usuarios: state.startups.usuarios,
  search: state.startups.search,
  startup: state.startups.startup,
  pertence: state.startups.pertence,
});
export default connect(mapStatetoProps, actions)(VincularParticipantes);
