export const portugues = {
  titulo: 'Configurações',
  usuario: 'Usuários',
  ambiente_inovacao: 'Ambiente de inovação',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  negocios: 'Negócios',
  trilhas: 'Trilhas',
  perfil: 'Perfil',
  radares: 'Radares',
  metodologia: 'Jornadas de Desenvolvimento',
  termos: 'Termos de uso',
  versao: 'Versão',
  perso: 'Personalização',
  personalizacao: {
    titulo: 'Personalizações',
    bt_cadastrar: 'Cadastrar',
    bt_voltar: 'Voltar',
    incubadora: 'Incubadora',
    acoes: 'Ações',
    titulo2: 'Titulo',
    bt_editar: 'Editar',
    modal_titulo: 'Tem certeza que deseja excluir?',
    modal_confirm: 'Sim',
    bt_excluir: 'Excluir'
  },
  novaPersonalizacao: {
    erro_titulo: "Preencha o titulo",
    erro_titulo2: "O titulo não pode conter espaços",
    msg_sucesso: "Cadastro realizado com sucesso!",
    titulo: 'Nova personalização',
    resolucao: 'Resolução mínima recomendada:',
    resol_atual: 'Resolução atual:',
    logo_dark: 'Logo Dark',
    cor_pri: 'Cor primária:',
    cor_pri_info: 'Cor principal do sistema, fundo do menu e cabeçalho',
    cor_sec: 'Cor secundária:',
    cor_sec_info: 'Cor de fundo',
    cor_ter: 'Cor terciária',
    cor_ter_info: 'Cor dos botões e destaques dos menus',
    cor_qua: 'Cor quaternária',
    cor_qua_info: 'Cor de fundo principal',
    cor_tex: 'Cor texto menu',
    cor_tex_info: 'Cor do menu principal',
    cor_tex_sec: 'Cor secundaria textos menu:',
    cor_tex_sec_info: 'Cor dos textos do menu quando ativos',
    cor_titu: 'Cor títulos:',
    cor_titu_info: 'Cor dos títulos principais e nome do usuário',
    bt_continuar: 'Continuar',
    bt_cancelar: 'Cancelar'
  },
  criarVinculoPersonalizacao: {
    titulo: 'Vincular incubadoras',
    pesquisa: 'Pesquisar',
    pesquisar: 'Pesquisar...',
    bt_vincular: 'Vincular',
    sem_dados: 'Não há incubadoras para exibir',
    sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
    page1: 'Primeira',
    page2: 'Última'
  },
  editarPersonalizacao: {
    titulo: 'Editar Personalizações',
    incub_vinc: 'Ambientes de inovação vinculados',
    bt_salvar: 'Salvar',
  },
  listagemIncubadoras: {
    modal_titulo: 'Você tem certeza?',
    bt_cancelar: 'Cancelar',
    modal_confirm: 'Sim, ativar este ambiente de inovação!',
    modal_sucesso: 'Ambiente de inovação ativado com sucesso!',
    modal_confirm2: 'Sim, desativar este ambiente de inovação!',
    modal_sucesso2: 'Ambiente de inovação desativado com sucesso!',
    modal_titulo2: 'Deseja deletar o ambiente de inovação?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de participantes, negócios e informações do ambiente de inovação </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_confirm3: 'Digite CONFIRMAR para realizar a exclusão',
    bt_deletar: 'Deletar',
    confirm: 'CONFIRMAR',
    erro_msg: 'Mensagem de confirmação inválida!',
    modal_sucesso3: 'Ambiente de inovação excluído com sucesso!',
    titulo: 'Listagem de ambientes de inovação',
    bt_cadastrar: 'Cadastrar ambiente de inovação',
    nome: 'Nome',
    segmento: 'Segmento',
    cidade: 'Cidade',
    status: 'Status',
    acao: 'Ações',
    atv: 'Ativo',
    desatv: 'Desativada',
    perfil: 'Perfil',
    participantes: 'Participantes',
    bt_cadastrar_neg: 'Cadastrar negócio',
    bt_editar: 'Editar',
    bt_desativar: 'Desativar',
    ativar: 'Ativar',
  },
  listagemMetodologia: {
    erro: 'Erro!',
    sucesso: 'Sucesso',
    escolh_radar: 'Escolha um radar',
    escolh_radar2: 'Escolha o radar',
    modal_confirm: 'Sim, desvincular este radar!',
    titulo: 'Listagem de Jornada de Desenvolvimento',
    nome: 'Nome',
    radar: 'Radar',
    vinculos: 'Ambientes',
    tooltip_vinculos: 'Ver ambientes',
    acoes: 'Ações',
    bt_vincular: 'Vincular',
    desvi: 'Desvincular',
    processos: 'Ver',
    modal_del: 'Sim, excluir essa Jornada de desenvolvimento',
    modal_titulo: 'Digite o nome da Jornada de desenvolvimento',
    modal_titulo2: 'Editar Jornada de desenvolvimento:',
    bt_editar: 'Editar',
    data_alt: 'Data de alteração',
    data_cri: 'Data de criação',
    colunas: 'Colunas'
  },
  listagemVinculosMetodologia: {
    titulo: 'Ambientes de inovação vinculados',
    bt_vincular: 'Vincular',
    tabela_titulo: 'Ambiente',
    bt_desvincular: 'Remover vínculo',
    bt_cancelar: 'Cancelar',
    modal_excluir: 'Tem certeza que deseja remover o vínculo?',
    modal_excluir_confirmar: 'Sim, remover vínculo!',
    select_incubadora: 'Selecione um ambiente de inovação'
  },
  listagemStartups: {
    modal_confirm: 'Sim, desvincular este negócio!',
    modal_sucesso: 'Negócio desvinculadao com sucesso!',
    modal_confirm2: 'Sim, ativar este negócio!',
    modal_sucesso2: 'Negócio ativado com sucesso!',
    modal_confirm3: 'Sim, desativar esse negócio!',
    modal_sucesso3: 'Negócio desativado com sucesso!',
    modal_titulo: 'Deseja deletar o negócio?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de participantes, Jornadas de Desenvolvimento, evidências e informações do negócio. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    msg_sucesso: 'Negócio excluído com sucesso!',
    titulo: 'Listagem de negócios',
    bt_cadastrar: 'Cadastrar startup',
    bt_desvincular: 'Desvincular'
  },
  listagemTrilhas: {
    modal_confirm: 'Sim, ativar esta trilha!',
    msg_sucesso: 'Trilha ativada com sucesso!',
    modal_confirm2: 'Sim, desativar esta trilha!',
    msg_sucesso2: 'Trilha desativada com sucesso!',
    modal_titulo: 'Deseja deletar a trilha?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de participantes, módulos, aulas e atividades da trilha. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_sucesso: 'Trilha excluída com sucesso!',
    titulo: 'Listagem de trilhas',
    bt_cadastrar: 'Cadastrar trilha'
  }
}

export const ingles = {
  titulo: 'Settings',
  usuario: 'Users',
  ambiente_inovacao: 'Innovation space',
  sem_dados_sub: 'What a pity! There is no information to display.',
  negocios: 'Business',
  trilhas: 'Training',
  perfil: 'Profile',
  radares: 'Diagnosis',
  metodologia: 'Methodologies',
  termos: 'Terms of use',
  versao: 'Version',
  perso: 'Customization',
  personalizacao: {
    titulo: 'Customizations',
    bt_cadastrar: 'Register',
    bt_voltar: 'Back',
    incubadora: 'Incubator',
    acoes: 'Options',
    titulo2: 'Title',
    bt_editar: 'Edit',
    modal_titulo: 'Are you sure you want to delete?',
    modal_confirm: 'Yes',
    bt_excluir: 'Delete'
  },
  novaPersonalizacao: {
    erro_titulo: "Title must be filled",
    erro_titulo2: "Title cannot contain spaces",
    msg_sucesso: "Registration successfully completed!",
    titulo: 'New customization',
    resolucao: 'Minimum recommended resolution:',
    resol_atual: 'Current resolution:',
    logo_dark: 'Dark Logo',
    cor_pri: 'Primary color:',
    cor_pri_info: 'System main color, menu background and header',
    cor_sec: 'Secondary color:',
    cor_sec_info: 'Background color',
    cor_ter: 'Tertiary color',
    cor_ter_info: 'Color of buttons and menu highlights',
    cor_qua: 'Quaternary color',
    cor_qua_info: 'Main background color',
    cor_tex: 'Menu text color',
    cor_tex_info: 'Main menu color',
    cor_tex_sec: 'Secondary color menu texts:',
    cor_tex_sec_info: 'Color of menu texts when active',
    cor_titu: 'Color titles:',
    cor_titu_info: 'Color of main titles and username',
    bt_continuar: 'Continue',
    bt_cancelar: 'Cancel'
  },
  criarVinculoPersonalizacao: {
    titulo: 'Link incubators',
    pesquisa: 'Search',
    pesquisar: 'Search...',
    bt_vincular: 'Link',
    sem_dados: 'There are no incubators to display',
    sem_dados_sub: 'What a pity! There is no information to display.',
    page1: 'First',
    page2: 'Last'
  },
  editarPersonalizacao: {
    titulo: 'Edit Customizations',
    incub_vinc: 'Linked innovation environments',
    bt_salvar: 'Save'
  },
  listagemIncubadoras: {
    modal_titulo: 'Are you sure',
    bt_cancelar: 'Cancel',
    modal_confirm: 'Yes, activate this innovation space!',
    modal_sucesso: 'Innovation space successfully activated!',
    modal_confirm2: 'Yes, disable this innovation space!',
    modal_sucesso2: 'Innovation space successfully disabled',
    modal_titulo2: 'Do you want to delete the innovation space?',
    modal_info: 'By doing so, you agree to the deletion of participant, business and information links from the innovation space </br>Type <strong>CONFIRM</strong> to perform the deletion',
    modal_confirm3: 'Type CONFIRM to perform the deletion',
    bt_deletar: 'Delete',
    confirm: 'CONFIRM',
    erro_msg: 'Invalid confirmation message!',
    modal_sucesso3: 'Innovation space successfully deleted!',
    titulo: 'List of innovation spaces',
    bt_cadastrar: 'Register innovation space',
    nome: 'Name',
    segmento: 'Segment',
    cidade: 'City',
    status: 'Status',
    acao: 'Options',
    atv: 'Active',
    desatv: 'Disabled',
    perfil: 'Profile',
    participantes: 'Participants',
    bt_cadastrar_neg: 'Register business',
    bt_editar: 'Edit',
    bt_desativar: 'Disable',
    ativar: 'Enable',
  },
  listagemMetodologia: {
    erro: 'Error!',
    sucesso: 'Success',
    escolh_radar: 'Choose a radar',
    escolh_radar2: 'Choose the radar',
    modal_confirm: 'Yes, unlink this radar!',
    titulo: 'List of methodologies',
    nome: 'Name',
    radar: 'Radar',
    vinculos: 'Environments',
    tooltip_vinculos: 'Show environments',
    acoes: 'Options',
    bt_vincular: 'Link',
    desvi: 'Unlink',
    processos: 'View',
    modal_del: 'Yes, delete this methodology',
    modal_titulo: 'Type the name of the methodology',
    modal_titulo2: 'Edit methodology:',
    bt_editar: 'Edit',
    data_alt: 'Change date',
    data_cri: 'Creation date',
    colunas: 'Columns'
  },
  listagemVinculosMetodologia: {
    titulo: 'Linked environments',
    bt_vincular: 'Link',
    tabela_titulo: 'Environment',
    bt_desvincular: 'Unlink',
    bt_cancelar: 'Cancel',
    modal_excluir: 'Are you sure you want to unlink?',
    modal_excluir_confirmar: 'Yes, unlinke it!',
    select_incubadora: 'Select an innovation environments'
  },
  listagemStartups: {
    modal_confirm: 'Yes, unlink this deal!',
    modal_sucesso: 'Business unlinked successfully!',
    modal_confirm2: 'Yes, activate this business!',
    modal_sucesso2: 'Business activated successfully!',
    modal_confirm3: 'Yes, disable that business!',
    modal_sucesso3: 'Business successfully deactivated!',
    modal_titulo: 'Do you want to delete the deal?',
    modal_info: 'By doing so you agree to the exclusion of participant links, methodologies, evidence and business information. </br>Type <strong>CONFIRM</strong> to delete',
    msg_sucesso: 'Deal successfully deleted!',
    titulo: 'Business listing',
    bt_cadastrar: 'Register startup',
    bt_desvincular: 'Unlink'
  },
  listagemTrilhas: {
    modal_confirm: 'Yes, activate this track!',
    msg_sucesso: 'Track activated successfully!',
    modal_confirm2: 'Yes, disable this track!',
    msg_sucesso2: 'Track successfully deactivated!',
    modal_titulo: 'Do you want to delete the track?',
    modal_info: 'By doing so, you agree to the exclusion of participant links, modules, classes and activities from the trail. </br>Type <strong>CONFIRM</strong> to delete',
    modal_sucesso: 'Track deleted successfully!',
    titulo: 'Trail listing',
    bt_cadastrar: 'Register trail'
  }
}