export const portugues = {
  titulo: 'Chat',
  label_pesquisa: 'Busca por nome',
  sem_dados: 'Não há conversa selecionada',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  label_tipo: 'Escolha o tipo do filtro',
  ambiente: 'Ambiente de inovação',
  negocios: 'Negócios',
  perfil: 'Perfil',
  label_perfil: 'Escolha o perfil, ambiente de inovação ou negócio',
  label_usuario: 'Escolha o usuário',
  label_todos_usuarios: 'Enviar para todos os usuários',
  broadcastIncubadora: 'Enviar mensagem para todos os usuários de um ambiente de inovação',
  broadcast: 'Enviar mensagem para todos os usuários da plataforma',
  bt_filtrar: 'Filtrar',
  bt_filtrar_broadcast: 'Preparar mensagem',
  filtro_info: 'Escolha um filtro antes',
  label_negocio: 'Escolha o negócio',
  label_ambiente: 'Escolha o ambiente de inovação',
  label_perfil2: 'Escolha o perfil',
  inicio_chat: 'Iniciar conversa',
  bt_deletar: 'Deletar',
  conversas: 'Conversas',
  sem_chats: 'Não existem chats para essa pesquisa',
  sem_contatos: 'Não existem contatos para essa pesquisa',
  contatos: 'Contatos',
  digite_msg: 'Digite uma mensagem',
  modal_titulo: 'Com quem será a conversa?',
  modal_titulo2: 'Selecione um arquivo',
  modal_titulo3: 'Selecione um usuário:',
  modal_titulo4: 'Deletar resposta',
  modal4_info: 'Você está prestes a deletar essa resposta. Você confirma a operação?',
  bt_cancelar: 'Cancelar',
  bt_sucesso: 'Sim, quero continuar',
  bt_proximo: 'Próximo',
  msg: 'Mensagem',
  msg_info: 'Digite a mensagem que deseja enviar a todos os usuários',
  msg_info_incubadora: 'Digite a mensagem que deseja enviar a todos os usuários desta incubadora',
  arq_info: 'Selecionar arquivo',
  enviando: 'Enviando...'
}

export const ingles = {
  titulo: 'Chat',
  label_pesquisa: 'Search by name',
  sem_dados: 'No conversation selected',
  sem_dados_sub: 'What a pity! There is no information to display.',
  broadcastIncubadora: 'Send message for all users of an innovation space',
  broadcast: 'Send message for all users',
  label_tipo: 'Select a filter type',
  ambiente: 'Innovation space',
  negocios: 'Business',
  perfil: 'Profile',
  label_perfil: 'Select a profile, innovation space or business',
  label_usuario: 'Select a user',
  label_todos_usuarios: 'Send for all users',
  bt_filtrar: 'Filter',
  bt_filtrar_broadcast: 'Prepare message',
  filtro_info: 'Select a filter first',
  label_ambiente: 'Select a innovation space',
  label_negocio: 'Select a business',
  label_perfil2: 'Select a profile',
  inicio_chat: 'Start chat',
  bt_deletar: 'Delete',
  conversas: 'Chats',
  sem_chats: 'There are no chats for this research',
  sem_contatos: 'There are no contacts for this research',
  contatos: 'Contacts',
  digite_msg: 'Type a message',
  modal_titulo: 'Who will the conversation be with?',
  modal_titulo2: 'Select a file',
  modal_titulo3: 'Selecr a user:',
  modal_titulo4: 'Delete answer',
  modal4_info: 'You are about to delete this answer. Do you confirm the operation?',
  bt_cancelar: 'Cancel',
  bt_sucesso: 'Yes, i want to continue',
  bt_proximo: 'Next',
  msg: 'Message',
  msg_info: 'Enter the message you want to send to all users',
  msg_info_incubadora: 'Enter the message you want to send to all users',
  arq_info: 'Select file',
  enviando: 'Sending...'
}