import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './authReducer';
import usuariosReducer from './usuariosReducer';
import startupsReducer from './startupsReducer';
import incubadorasReducer from './incubadorasReducers';
import trilhasReducer from './trilhasReducer';
import conteudosReducer from './conteudosReducer';
import eventosReducer from './eventosReducer';
import hubReducer from './hubReducer';
import perfisReducer from './perfisReducer';
import chatReducer from './chatReducer';
import radaresReducer from './radaresReducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'usuarios', 'startups', 'incubadoras', 'radares', 'trilhas', 'conteudos', 'eventos', 'hub', 'perfis', 'chat']
}



const rootReducer = combineReducers({
    auth: authReducer,
    usuarios: usuariosReducer,
    startups: startupsReducer,
    incubadoras: incubadorasReducer,
    trilhas: trilhasReducer,
    conteudos: conteudosReducer,
    eventos: eventosReducer,
    hub: hubReducer,
    perfis: perfisReducer,
    chat: chatReducer,
    radares: radaresReducer,
})

export default persistReducer(persistConfig, rootReducer);