import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';

class CadastrarIncubadora extends Component {
    state = {
        id_incubadora: "",
        nome: "",
        cnpj: "",
        segmento: "",
        cep: "",
        endereco: "",
        cidade: "",
        estado: "",
        siteHTTP: "",
        email: "",
        pitchYT: "",
        incubados: "",
        facebook: "",
        instagram: "",
        linkedin: "",
        file: null,
        twitter: "",
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_incubadora } = this.props.match.params;
        this.setState({ id_trilha: id_incubadora })

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        if (!this.props.incubadoras && nextProps.incubadoras) {
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparIncubadoras();
    }


    receberDadosAPI() {
        if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    cadastrar(nome, cnpj, cep, endereco, cidade, estado, siteHTTP, segmento, pitchYT, incubados, facebook, instagram, linkedin, twitter, file) {
        var facebookURL = "https://www.facebook.com/"
        var instagramURL = "https://www.instagram.com/"
        var linkedinURL = "https://www.linkedin.com/in/"
        var twitterURL = "https://twitter.com/"
        if (!this.validate()) return;
        this.setState({ carregando: true })
        var pitch = pitchYT.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/");
        var site = siteHTTP.replace("www.", "https://www.");

        if (!facebook.includes("https://www.facebook.com/") || !facebook.includes("facebook.com/") || !facebook.includes("www.facebook.com/")) {
            facebook = facebookURL + facebook
        }

        if (!linkedin.includes("https://www.linkedin.com/in/")) {
            linkedin = linkedinURL + linkedin
        }

        if (!instagram.includes("https://www.instagram.com/")) {
            instagram = instagramURL + instagram
        }

        if (!twitter.includes("https://twitter.com/")) {
            twitter = twitterURL + twitter
        }


        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('nome', nome)
        formData.append('cnpj', cnpj)
        formData.append('cep', cep)
        formData.append('endereco', endereco)
        formData.append('cidade', cidade)
        formData.append('estado', estado)
        formData.append('site', site)
        formData.append('segmento', segmento)
        formData.append('pitch', pitch)
        formData.append('incubados', incubados)
        formData.append('facebook', facebook)
        formData.append('instagram', instagram)
        formData.append('linkedin', linkedin)
        formData.append('twitter', twitter)
        this.props.postIncubadora(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { nome, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrar.erro_nome } });
        return true;
    }
    render() {
        const { nome, cnpj, cep, endereco, cidade, file, estado, siteHTTP, segmento, pitchYT, incubados, facebook, instagram, linkedin, twitter, erro, success, carregando, iconSalvar, formSuccess, idioma } = this.state;
        if (formSuccess) return <Redirect to={{
            pathname: "/configuracoes/incubadoras/listar",
            state: { msg: idioma.cadastrar.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrar.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.acompanhamento.nome}</Label>
                                            <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.acompanhamento.nome2} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cnpj">{idioma.acompanhamento.cnpj}</Label>
                                            <Input type="text" maxLength="14" value={cnpj} autoComplete="cnpj" onChange={(ev) => this.onChnageInput("cnpj", ev)} name="cnpj" id="cnpj" placeholder={idioma.acompanhamento.cnpj} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="segmento">{idioma.acompanhamento.segmento}</Label>
                                            <Input type="text" value={segmento} autoComplete="segmento" onChange={(ev) => this.onChnageInput("segmento", ev)} name="segmento" id="segmento" placeholder={idioma.acompanhamento.segmento2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cep">{idioma.acompanhamento.cep}</Label>
                                            <Input type="text" maxLength="8" value={cep} autoComplete="cep" onChange={(ev) => this.onChnageInput("cep", ev)} name="cep" id="cep" placeholder={idioma.acompanhamento.cep} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="endereco">{idioma.acompanhamento.endereco}</Label>
                                            <Input type="text" value={endereco} autoComplete="endereco" onChange={(ev) => this.onChnageInput("endereco", ev)} name="endereco" id="endereco" placeholder={idioma.acompanhamento.endereco} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="file">{idioma.cadastrar.img}</Label>
                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                </FormGroup>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="cidade">{idioma.acompanhamento.cidade}</Label>
                                            <Input type="text" value={cidade} autoComplete="cidade" onChange={(ev) => this.onChnageInput("cidade", ev)} name="cidade" id="cidade" placeholder={idioma.acompanhamento.cidade} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="estado">{idioma.cadastrar.estado}</Label>
                                            <Input type="text" value={estado} autoComplete="estado" onChange={(ev) => this.onChnageInput("estado", ev)} name="estado" id="estado" placeholder={idioma.cadastrar.estado} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="siteHTTP">{idioma.acompanhamento.site}</Label>
                                            <Input type="text" value={siteHTTP} autoComplete="siteHTTP" onChange={(ev) => this.onChnageInput("siteHTTP", ev)} name="siteHTTP" id="siteHTTP" placeholder={idioma.acompanhamento.site2} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="pitchYT">{idioma.cadastrar.link}</Label>
                                            <Input type="text" value={pitchYT} autoComplete="pitchYT" onChange={(ev) => this.onChnageInput("pitchYT", ev)} name="pitchYT" id="pitchYT" placeholder={idioma.cadastrar.link} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="incubados">{idioma.cadastrar.num_incub}</Label>
                                            <Input type="number" value={incubados} autoComplete="incubados" onChange={(ev) => this.onChnageInput("incubados", ev)} name="incubados" id="incubados" placeholder={idioma.cadastrar.num_incub} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={6}>
                                        <Label for="facebook">Facebook</Label>
                                        <Input type="text" value={facebook} autoComplete="facebook" onChange={(ev) => this.onChnageInput("facebook", ev)} name="facebook" id="facebook" placeholder="Facebook" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="instagram">Instagram</Label>
                                        <Input type="text" value={instagram} autoComplete="instagram" onChange={(ev) => this.onChnageInput("instagram", ev)} name="instagram" id="instagram" placeholder="Instagram" />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={6}>
                                        <Label for="linkedin">LinkedIn</Label>
                                        <Input type="text" value={linkedin} autoComplete="linkedin" onChange={(ev) => this.onChnageInput("linkedin", ev)} name="linkedin" id="linkedin" placeholder="LinkedIn" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="twitter">Twitter</Label>
                                        <Input type="text" value={twitter} autoComplete="twitter" onChange={(ev) => this.onChnageInput("twitter", ev)} name="twitter" id="twitter" placeholder="Twitter" />
                                    </Col>
                                </Row>
                                <Link to="#" onClick={() => this.cadastrar(nome, cnpj, cep, endereco, cidade, estado, siteHTTP, segmento, pitchYT, incubados, facebook, instagram, linkedin, twitter, file)}> <Botao loading={carregando} classes="botao-responsivo botao-estilo" texto={idioma.cadastrar.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario,
    incubadoras: state.incubadoras.incubadoras
})

export default connect(mapStateToProps, actions)(CadastrarIncubadora);