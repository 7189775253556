import {
    GET_CHATS, GET_POR_CHAT, GET_ULTIMA, GET_USERS, GET_PERFIS_ACESSO, GET_STARTUPS_ATIVAS, GET_INCUBADORAS_ATIVAS, GET_USUARIOS_INCUBADORA, GET_USUARIOS_PERFIL,
    LIMPAR_CHATS, LIMPAR_POR_CHAT, LIMPAR_ULTIMA, GET_MENSAGENS, LIMPAR_MENSAGENS, GET_PARTICIPANTES, LIMPAR_USUARIOS_CHAT, GET_VERIFICACAO, LOGIN_USER, GET_CHATS_BUSCA
} from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_CHATS:
            return {
                ...state,
                chats: actions.payload.data
            }
        case GET_CHATS_BUSCA:
            return {
                ...state,
                chatsBusca: actions.payload.data
            }
        case GET_MENSAGENS:
            return {
                ...state,
                mensagens: actions.payload.data
            }
        case GET_VERIFICACAO:
            return {
                ...state,
                verificacao: actions.payload.data
            }

        case GET_POR_CHAT:
            return {
                ...state,
                porChat: actions.payload.data
            }
        case GET_ULTIMA:
            return {
                ...state,
                ultima: actions.payload.dados
            }
        case GET_USERS:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case GET_INCUBADORAS_ATIVAS:
            return {
                ...state,
                incubadoras: actions.payload.data
            }
        case GET_USUARIOS_INCUBADORA:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case GET_STARTUPS_ATIVAS:
            return {
                ...state,
                startups: actions.payload.data
            }
        case GET_PARTICIPANTES:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case GET_PERFIS_ACESSO:
            return {
                ...state,
                perfis: actions.payload.data
            }
        case GET_USUARIOS_PERFIL:
            return {
                ...state,
                usuariosPerfil: actions.payload.data
            }
        case LOGIN_USER:
            return {
                ...state,
                usuario: actions.payload.dados,
                authorized: true,
            }
        case LIMPAR_ULTIMA:
            return {
                ...state,
                ultima: null
            }
        case LIMPAR_CHATS:
            return {
                chats: null
            }
        case LIMPAR_POR_CHAT:
            return {
                porChat: null
            }
        case LIMPAR_MENSAGENS:
            return {
                mensagens: null
            }
        case LIMPAR_USUARIOS_CHAT:
            return {
                usuarios: null
            }
        default:
            return state;
    }
}