import axios from 'axios';
import { GET_EVENTOS, GET_EVENTO, GET_SEARCH_EVENT, LIMPAR_EVENTO, LIMPAR_EVENTOS } from './types'
import { api } from '../../config';
import { getHeaders, clearToken, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getEventos = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/eventos/buscar?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_EVENTOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postEvento = (dadosEvento, callback) => {
    return function (dispatch) {

        axios.post(api + `/evento`, dadosEvento, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEvento = (dadosEvento, callback) => {
    return function (dispatch) {
        axios.put(api + `/evento/editar`, dadosEvento, getHeadersPost())
            .then((response) => {
                ////////console.log(response, dadosEvento)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEventoImagem = (dadosEvento, callback) => {
    return function (dispatch) {
        axios.put(api + `/evento/editar/imagem`, dadosEvento, getHeadersPost())
            .then((response) => {
                ////////console.log(response, dadosEvento)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getVerEvento = (id_evento) => {
    return function (dispatch) {
        axios.get(api + `/evento/${id_evento}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_EVENTO, payload: response.data })
            })
            .catch(errorHandle)
    }
}


export const deletarEvento = (id_evento, callback) => {
    return function (dispatch) {
        axios.delete(api + `/evento/${id_evento}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const getSearchEvent = (pesquisa, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/eventos/buscar/pesquisa?pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_SEARCH_EVENT, payload: response })
            })
            .catch(errorHandle)
    }
}

export const limparEventos = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_EVENTOS })
    }
}

export const limparEvento = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_EVENTO })
    }
}