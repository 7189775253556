import React, { useState, useEffect } from 'react';

import { portugues, ingles } from '../../utils/paginaincubadora_bilingue';

const SemDados = ({ titulo, subtitulo }) => {
    // const [idioma, setIdioma] = useState();
    // useEffect(() => {
    //     setIdioma(user?.idioma?.id == 1 ? portugues : ingles);
    // }, [user])
    return (
        <div>
            <div className="row">
                <div className="col-md-4" >
                    <div className="img-perfil">
                        <img className="rounded-circle mt-2" src={"/imagens/astronauta_sem_dados.png"} width="100%" ></img>
                    </div>
                </div>
                <div className="col mensagem-dados">
                    <h4>{titulo}</h4>
                    <h5>{subtitulo}</h5>
                    {/* <h5>{idioma?.gestao.info_body}</h5> */}
                </div>
            </div>
        </div>
    )
}
export default SemDados;