import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import SemDados from '../../componentes/Mensagens/semDados';
import LoadTrilhas from '../../componentes/BlocoCarregando/trilhas';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'
import Skeleton from "react-loading-skeleton";
import TextTruncate from 'react-text-truncate';
import { apiImagem } from '../../config/';
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { Rating } from '@material-ui/lab';

class ListarTrilhas extends Component {
    state = {
        id_usuario: "",
        currentPage: 1,
        size: 6,
        meusCursosAtv: false,
        matriculadoAtv: false,
        todosCursosAtv: true,
        adminAtv: false,
        matriculado: "",
        meusCursos: "",
        todosCursos: "",
        procura: "",
        segmento: "",
        pesquisandoMeus: false,
        pesquisandoTodos: false,
        pesquisandoMatriculado: false,
        pesquisandoTodosCursos: false,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: ingles,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });
        if (this.props.usuario) {
            this.listarMeusCursos(this.props.usuario.id);
            this.listarMatriculado(this.props.usuario.id);
        }
        this.listarCursos();
        this.listarTodosCursos();

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        if (!this.props.trilhas && nextProps.trilhas) this.listarCursos();
        if (!this.props.todosCursos && nextProps.todosCursos) this.listarTodosCursos();
        if (!this.props.cursos && nextProps.cursos) this.listarMatriculado(this.props.usuario.id);
        if (!this.props.meusCursos && nextProps.meusCursos) this.listarMeusCursos(this.props.usuario.id);
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparTrilhas();
    }
    receberDadosAPI() {

        if (this.state.pesquisandoTodos === false) {
            if (typeof this.props.trilhas != "undefined" && this.props.trilhas != null && !this.state.dadosApi && this.props.trilhas.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        } else {
            if (typeof this.props.search != "undefined" && this.props.search != null && !this.state.dadosApi && this.props.search.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        }

        if (this.state.pesquisandoMatriculado === false) {
            if (typeof this.props.cursos != "undefined" && this.props.cursos != null && !this.state.dadosApi && this.props.cursos.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        } else {
            if (typeof this.props.searchCursos != "undefined" && this.props.searchCursos != null && !this.state.dadosApi && this.props.searchCursos.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        }

        if (this.state.pesquisandoMeus === false) {
            if (typeof this.props.meusCursos != "undefined" && this.props.meusCursos != null && !this.state.dadosApi && this.props.meusCursos.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        } else {
            if (typeof this.props.searchMeus != "undefined" && this.props.searchMeus != null && !this.state.dadosApi && this.props.searchMeus.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        }

        if (this.state.pesquisandoTodosCursos === false) {
            if (typeof this.props.todosCursos != "undefined" && this.props.todosCursos != null && !this.state.dadosApi && this.props.todosCursos.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        } else {
            if (typeof this.props.searchTodosCursos != "undefined" && this.props.searchTodosCursos != null && !this.state.dadosApi && this.props.searchTodosCursos.currentPage === this.state.currentPage) {
                this.setState({ dadosApi: true });
            }
        }
    }

    changePageAtualTodos(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarTodosCursos();
        })
    }

    changePageAtualSearchTodos(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.handleSearchGerenciarCursos(this.state.procura)
        })
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarCursos();
        })
    }

    changePageSearchAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.handleSearchTodosCursos(this.state.procura);
        })
    }

    changePageAtualMeus(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarMeusCursos(this.props.usuario.id);
        })
    }

    changePageAtualSearchMeus(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.handleSearchMeusCursos(this.state.procura);
        })
    }

    changePageAtualMatriculado(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarMatriculado(this.props.usuario.id);
        })
    }

    changePageAtualSearchMatriculado(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.handleSearchMatriculado(this.state.procura, this.props.usuario.id);
        })
    }

    apagarIncubadora(id) {
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarIncubadora(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarIncubadoras();
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }

    chamarAlerta(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_title,
            text: idioma.modal_texto,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.bt_salvar
        }).then((result) => {
            if (result.value) {
                this.apagarIncubadora(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }

    listarTodosCursos() {
        this.setState({ dadosApi: false });
        const { currentPage, size } = this.state;
        this.props.getTrilhas(currentPage, size)
        const { todosCursos } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (todosCursos === "undefined") return null
    }

    listarCursos() {
        this.setState({ dadosApi: false });
        const { currentPage, size } = this.state;
        this.props.getTrilhasPublicas(currentPage, size)
        const { trilhas } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilhas === "undefined") return null
    }

    listarMatriculado(id_usuario) {
        this.setState({ dadosApi: false });
        const { currentPage, size } = this.state;
        this.props.getCursosAluno(id_usuario, currentPage, size)
        const { matriculado } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (matriculado === "undefined") return null
    }

    listarMeusCursos(id_usuario) {
        this.setState({ dadosApi: false });
        const { currentPage, size } = this.state;
        this.props.getMeusCursos(id_usuario, currentPage, size)
        const { meusCursos } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (meusCursos === "undefined") return null
    }

    clicarMenu(info) {
        this.setState({ procura: '' })
        this.setState({ currentPage: 1 })
        this.setState({ pesquisandoTodos: false })
        this.setState({ pesquisandoMatriculado: false })
        this.setState({ pesquisandoMeus: false })
        this.setState({ pesquisandoTodosCursos: false })
        if (info === "meus") {
            this.setState({ meusCursosAtv: true })
            this.setState({ todosCursosAtv: false })
            this.setState({ matriculadoAtv: false })
            this.setState({ adminAtv: false })

        }
        if (info === "todos") {
            this.setState({ meusCursosAtv: false })
            this.setState({ todosCursosAtv: true })
            this.setState({ matriculadoAtv: false })
            this.setState({ adminAtv: false })

        }
        if (info === "matriculado") {
            this.setState({ meusCursosAtv: false })
            this.setState({ todosCursosAtv: false })
            this.setState({ matriculadoAtv: true })
            this.setState({ adminAtv: false })

        }
        if (info === "admin") {
            this.setState({ meusCursosAtv: false })
            this.setState({ todosCursosAtv: false })
            this.setState({ matriculadoAtv: false })
            this.setState({ adminAtv: true })

        }
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleSearchTodosCursos(procura) {
        this.setState({ dadosApi: false });
        this.setState({ procura: procura })
        this.setState({ pesquisandoTodos: true })
        const { currentPage, size } = this.state;
        this.props.getSearchTodos(procura, currentPage, size)
    }

    handleSearchMatriculado(procura, id_usuario) {
        this.setState({ dadosApi: false });
        this.setState({ procura: procura })
        this.setState({ pesquisandoMatriculado: true })
        const { currentPage, size } = this.state;
        this.props.getSearchCursos(procura, id_usuario, currentPage, size)
    }

    handleSearchMeusCursos(procura, id_usuario) {
        this.setState({ dadosApi: false });
        this.setState({ procura: procura })
        this.setState({ pesquisandoMeus: true })
        const { currentPage, size } = this.state;
        this.props.getSearchMeus(procura, id_usuario, currentPage, size)
    }

    handleSearchGerenciarCursos(procura) {
        this.setState({ dadosApi: false });
        this.setState({ procura: procura })
        this.setState({ pesquisandoTodosCursos: true })
        const { currentPage, size } = this.state;
        this.props.getSearchTodosCursos(procura, currentPage, size)
    }



    eraseSearchMeusCursos(campo) {
        this.setState({ pesquisandoMeus: false })
        this.setState({ [campo]: "" })
    }

    eraseSearchMatriculado(campo) {
        this.setState({ pesquisandoMatriculado: false })
        this.setState({ [campo]: "" })
    }

    eraseSearchTodosCursos(campo) {
        this.setState({ pesquisandoTodos: false })
        this.setState({ [campo]: "" })
    }

    eraseSearchGerenciarCursos(campo) {
        this.setState({ pesquisandoTodosCursos: false })
        this.setState({ [campo]: "" })
    }

    handleEnter(ev, tipo) {
        const { procura, id_usuario } = this.state;
        if (ev.key === 'Enter') {

            this.setState({ dadosApi: false });
            if (tipo === "Meus") {
                this.handleSearchMeusCursos(procura, id_usuario)
            } else if (tipo === "Todos") {
                this.handleSearchTodosCursos(procura)
            } else if (tipo === "Matriculado") {
                this.handleSearchMatriculado(procura, id_usuario)
            } else {
                this.handleSearchGerenciarCursos(procura)
            }


        }

    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive, el) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = !isActive && this.props.usuario?.personalizacao?.cor_secundaria
    }

    render() {
        const { id_usuario, pesquisandoMeus, pesquisandoMatriculado, pesquisandoTodosCursos, pesquisandoTodos, procura, msg, carregando, erro, success, dadosApi, adminAtv, meusCursosAtv, todosCursosAtv, matriculadoAtv, idioma } = this.state;
        const { usuario } = this.props; 
        var trilhas = [];
        var cursos = [];
        var meusCursos = [];
        var todosCursos = [];

        if (pesquisandoTodos === false) {
            if (this.props.trilhas) trilhas = this.props.trilhas.dados;
            var temPaginaAnteriorTodos = false;
            if (typeof this.props.trilhas !== "undefined" && this.props.trilhas !== null && this.props.trilhas.currentPage !== "" && this.props.trilhas.currentPage !== 1) {
                temPaginaAnteriorTodos = true;
            }
            var proximaPaginaTodos = false;
            var temProximaPaginaTodos = false;
            if (typeof this.props.trilhas !== "undefined" && this.props.trilhas !== null && this.props.trilhas.nextPage <= this.props.trilhas.totalPages && this.props.trilhas.nextPage != null) {
                proximaPaginaTodos = true;
                temProximaPaginaTodos = true;
            }

        } else {
            if (this.props.search) trilhas = this.props.search.dados;
            var temPaginaAnteriorTodos = false;
            if (typeof this.props.search !== "undefined" && this.props.search !== null && this.props.search.currentPage !== "" && this.props.search.currentPage !== 1) {
                temPaginaAnteriorTodos = true;
            }
            var proximaPaginaTodos = false;
            var temProximaPaginaTodos = false;
            if (typeof this.props.search != "undefined" && this.props.search !== null && this.props.search.nextPage <= this.props.search.totalPages && this.props.search.nextPage !== null) {
                proximaPaginaTodos = true;
                temProximaPaginaTodos = true;
            }
        }

        if (pesquisandoMatriculado === false) {
            if (this.props.cursos) cursos = this.props.cursos.trilhas;
            var temPaginaAnteriorMatriculados = false;
            if (typeof this.props.cursos !== "undefined" && this.props.cursos !== null && this.props.cursos.currentPage !== "" && this.props.cursos.currentPage !== 1) {
                temPaginaAnteriorMatriculados = true;
            }
            var proximaPaginaMatriculados = false;
            var temProximaPaginaMatriculados = false;
            if (typeof this.props.cursos !== "undefined" && this.props.cursos !== null && this.props.cursos.nextPage <= this.props.cursos.totalPages && this.props.cursos.nextPage !== null) {
                proximaPaginaMatriculados = true;
                temProximaPaginaMatriculados = true;
            }

        } else {
            if (this.props.searchCursos) cursos = this.props.searchCursos.dados;
            var temPaginaAnteriorMatriculados = false;
            if (typeof this.props.searchCursos !== "undefined" && this.props.searchCursos !== null && this.props.searchCursos.currentPage !== "" && this.props.searchCursos.currentPage !== 1) {
                temPaginaAnteriorMatriculados = true;
            }
            var proximaPaginaMatriculados = false;
            var temProximaPaginaMatriculados = false;
            if (typeof this.props.searchCursos !== "undefined" && this.props.searchCursos !== null && this.props.searchCursos.nextPage <= this.props.searchCursos.totalPages && this.props.searchCursos.nextPage !== null) {
                proximaPaginaMatriculados = true;
                temProximaPaginaMatriculados = true;
            }
        }

        if (pesquisandoMeus === false) {
            if (this.props.meusCursos) meusCursos = this.props.meusCursos.dados;
            var temPaginaAnteriorMeus = false;
            if (typeof this.props.meusCursos !== "undefined" && this.props.meusCursos !== null && this.props.meusCursos.currentPage !== "" && this.props.meusCursos.currentPage !== 1) {
                temPaginaAnteriorMeus = true;
            }
            var proximaPaginaMeus = false;
            var temProximaPaginaMeus = false;
            if (typeof this.props.meusCursos !== "undefined" && this.props.meusCursos !== null && this.props.meusCursos.nextPage <= this.props.meusCursos.totalPages && this.props.meusCursos.nextPage !== null) {
                proximaPaginaMeus = true;
                temProximaPaginaMeus = true;
            }

        } else {
            if (this.props.searchMeus) meusCursos = this.props.searchMeus.dados;
            var temPaginaAnteriorMeus = false;
            if (typeof this.props.searchMeus !== "undefined" && this.props.searchMeus !== null && this.props.searchMeus.currentPage !== "" && this.props.searchMeus.currentPage !== 1) {
                temPaginaAnteriorMeus = true;
            }
            var proximaPaginaMeus = false;
            var temProximaPaginaMeus = false;
            if (typeof this.props.searchMeus != "undefined" && this.props.search !== null && this.props.searchMeus.nextPage <= this.props.searchMeus.totalPages && this.props.searchMeus.nextPage != null) {
                proximaPaginaMeus = true;
                temProximaPaginaMeus = true;
            }
        }

        if (pesquisandoTodosCursos === false) {
            if (this.props.todosCursos) todosCursos = this.props.todosCursos.dados;
            var temPaginaAnteriorTodosCursos = false;
            if (typeof this.props.todosCursos !== "undefined" && this.props.todosCursos !== null && this.props.todosCursos.currentPage !== "" && this.props.todosCursos.currentPage !== 1) {
                temPaginaAnteriorTodosCursos = true;
            }
            var proximaPaginaTodosCursos = false;
            var temProximaPaginaTodosCursos = false;
            if (typeof this.props.todosCursos != "undefined" && this.props.todosCursos !== null && this.props.todosCursos.nextPage <= this.props.todosCursos.totalPages && this.props.todosCursos.nextPage != null) {
                proximaPaginaTodosCursos = true;
                temProximaPaginaTodosCursos = true;
            }
        } else {
            if (this.props.searchTodosCursos) todosCursos = this.props.searchTodosCursos.dados;
            var temPaginaAnteriorTodosCursos = false;
            if (typeof this.props.searchTodosCursos !== "undefined" && this.props.searchTodosCursos !== null && this.props.searchTodosCursos.currentPage !== "" && this.props.searchTodosCursos.currentPage !== 1) {
                temPaginaAnteriorTodosCursos = true;
            }
            var proximaPaginaTodos = false;
            var temProximaPaginaTodosCursos = false;
            if (typeof this.props.searchTodosCursos != "undefined" && this.props.search !== null && this.props.searchTodosCursos.nextPage <= this.props.searchTodosCursos.totalPages && this.props.searchTodosCursos.nextPage != null) {
                proximaPaginaTodosCursos = true;
                temProximaPaginaTodosCursos = true;
            }
        }

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
 

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        {credenciais.length > 0 ?
                                            credenciais[0].id_perfil === 1 || credenciais[0].id_perfil == 9 ?
                                                <div className="row">
                                                    <div id='todos' style={{ backgroundColor: todosCursosAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_principal, color: todosCursosAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu, borderRadius: '5px' }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, todosCursosAtv)} className={todosCursosAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique hover-this" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("todos")}>
                                                        <FontAwesomeIcon icon="space-shuttle" size="1x" className="mr-3 icon-mouse-none" style={{ position: 'relative', zIndex: '999' }} />
                                                        {idioma.todos}
                                                    </div>
                                                    <div id='matriculado' style={{ backgroundColor: matriculadoAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_principal, color: matriculadoAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu, borderRadius: '5px' }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, matriculadoAtv)} className={matriculadoAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique hover-this" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("matriculado")}>
                                                        <FontAwesomeIcon icon="user-astronaut" size="1x" className="mr-3 icon-mouse-none" style={{ position: 'relative', zIndex: '999' }} />
                                                        {idioma.matriculado}
                                                    </div>
                                                    <div id='meuscursos' style={{ backgroundColor: meusCursosAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_principal, color: meusCursosAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu, borderRadius: '5px' }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, meusCursosAtv)} className={meusCursosAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique hover-this" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("meus")}>
                                                        <FontAwesomeIcon icon="user-plus" size="1x" className="mr-3 icon-mouse-none" />
                                                        {idioma.cursos}
                                                    </div>
                                                    <div id='admin' style={{ backgroundColor: adminAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_principal, color: adminAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu, borderRadius: '5px' }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, adminAtv)} className={adminAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique hover-this" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("admin")}>
                                                        <FontAwesomeIcon icon="space-shuttle" size="1x" className="mr-3 icon-mouse-none" />
                                                        {idioma.gerenciar}
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div id='todos' style={{ backgroundColor: todosCursosAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_principal, color: todosCursosAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu, borderRadius: '5px' }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, todosCursosAtv)} className={todosCursosAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique hover-this" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("todos")}>
                                                        <FontAwesomeIcon icon="space-shuttle" size="1x" className="mr-3 icon-mouse-none" style={{ position: 'relative', zIndex: '999' }} />
                                                        {idioma.todos}
                                                    </div>
                                                    <div id='matriculado' style={{ backgroundColor: matriculadoAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_principal, color: matriculadoAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu, borderRadius: '5px' }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, matriculadoAtv)} className={matriculadoAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique hover-this" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("matriculado")}>
                                                        <FontAwesomeIcon icon="user-astronaut" size="1x" className="mr-3 icon-mouse-none" style={{ position: 'relative', zIndex: '999' }} />
                                                        {idioma.matriculado}
                                                    </div>
                                                    <div id='meuscursos' style={{ backgroundColor: meusCursosAtv ? usuario?.personalizacao?.cor_secundaria : usuario?.personalizacao?.cor_principal, color: meusCursosAtv ? usuario?.personalizacao?.cor_texto_menu_hover : usuario?.personalizacao?.cor_texto_menu }} onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, meusCursosAtv)} className={meusCursosAtv ? "col-md-3 menu-cabecalho-active menu-centralizado botao-responsivo cursor-clique hover-this" : "col-md-3 menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"} data-toggle="tab" role="tab" onClick={() => this.clicarMenu("meus")}>
                                                        <FontAwesomeIcon icon="user-plus" size="1x" className="mr-3 icon-mouse-none" />
                                                        {idioma.cursos}
                                                    </div>
                                                </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="col-lg-12">
                                {credenciais.map(credencial => ((credencial.id_componente == 21) ?
                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/cadastrar"} key={credencial.id_componente}>
                                        {/* <button className="btn btn-outline btn-md botao-responsivo botao-estilo" onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: usuario?.personalizacao?.cor_secundaria }}>
                                        {idioma.bt_cadastrar}
                                    </button> */}
                                        <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                    </Link>
                                    : ""))}
                            </div>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <div className="col-lg-12">
                                <div className="tab-content">
                                    <div className={meusCursosAtv ? "tab-pane active" : "tab-pane p-3"} id="meus" role="tabpanel">
                                        <div>
                                            <div className="row pt-3">
                                                <div className="col-md-8">
                                                    <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev, "Meus")} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisar} />
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <span className="pr-3" onClick={() => this.handleSearchMeusCursos(procura, id_usuario)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                                        <span value={procura} onClick={() => this.eraseSearchMeusCursos("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" classes="botao-responsivo" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                                    </div>
                                                </div>
                                            </div>

                                            {dadosApi ?
                                                meusCursos.length !== 0 ?
                                                    <div className="row">

                                                        {meusCursos.map(curso => (
                                                            <div className="col-md-6 col-xl-4" key={curso.id}>
                                                                <Link to={curso.visibilidade === 'Público' ?
                                                                    "/" + usuario?.personalizacao?.titulo + "/trilhas/" + curso.id + "/modulos/" :
                                                                    "/" + usuario?.personalizacao?.titulo + "/trilhas/" + curso.id + "/autores/"
                                                                }>
                                                                    <div className="directory-card card">
                                                                        <div>
                                                                            <img className="card-imagem-trilha img-fluid listagem-trilhas" src={curso.fileName != null ? apiImagem + curso.fileName : apiImagem + "fundo_startup.jpg"} alt="Capa da trilha" />
                                                                            <div className="directory-content p-4">
                                                                                <div className='card-title-access'>
                                                                                    <h5 className="titulo-startup">
                                                                                        <TextTruncate
                                                                                            line={1}
                                                                                            element="span"
                                                                                            truncateText="…"
                                                                                            text={curso.nome.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())}
                                                                                        />
                                                                                    </h5>
                                                                                    {curso?.visibilidade === 'Privado' ? (
                                                                                        <FontAwesomeIcon icon='lock' color='#FF0000' className="mr-2" />
                                                                                    ) : (
                                                                                        <FontAwesomeIcon icon='lock-open' color='#22A50D' className="mr-2" />
                                                                                    )}
                                                                                </div>
                                                                                <div className='card-author-name'>
                                                                                    <p>Criado por <span>{curso.autor}</span></p>
                                                                                </div>
                                                                                <p className="text-muted">
                                                                                    <TextTruncate
                                                                                        line={2}
                                                                                        element="span"
                                                                                        truncateText="…"
                                                                                        text={curso.previa ? curso.previa : idioma.acesso}
                                                                                    />
                                                                                </p>
                                                                                <div className='card-title-access row'>
                                                                                    <div className="col-md-12 col-lg-6 card-date">
                                                                                        <p className="text-muted">{format(new Date(curso.dataCriacao), 'dd/MM/yyyy', { locale: pt })}</p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-6 card-rate">
                                                                                        <Rating name='simple-controlled' value={curso.avaliacao_media ? curso.avaliacao_media : 0} disabled />
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.sem_dados} />
                                                : <LoadTrilhas />
                                            }
                                        </div>
                                    </div>
                                    <div className={todosCursosAtv ? "tab-pane active" : "tab-pane p-3"} id="todos" role="tabpanel">


                                        <div>
                                            <div className="row pt-3">
                                                <div className="col-md-8">
                                                    <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev, "Todos")} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisar} />
                                                </div>
                                                <div className="col-md-4">
                                                    <div>
                                                        <span className="pr-3" onClick={() => this.handleSearchTodosCursos(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                                        <span value={procura} onClick={() => this.eraseSearchTodosCursos("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-responsivo" cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            {dadosApi ?
                                                trilhas.length != 0 ?
                                                    <div className="row">
                                                        {trilhas.map(trilha => (
                                                            <div className="col-md-6 col-xl-4" key={trilha.id}>
                                                                <Link to={trilha.visibilidade === 'Público' ?
                                                                    "/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/modulos/" :
                                                                    "/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/autores/"
                                                                }>
                                                                    <div className="directory-card card">
                                                                        <div>
                                                                            <img className="card-imagem-trilha img-fluid listagem-trilhas" src={trilha.fileName != null ? apiImagem + trilha.fileName : apiImagem + "fundo_startup.jpg"} alt="Capa da trilha" />
                                                                            <div className="directory-content p-4">
                                                                                <div className='card-title-access'>
                                                                                    <h5 className="titulo-startup">{trilha.nome}</h5>
                                                                                    {trilha?.visibilidade === 'Privado' ? (
                                                                                        <FontAwesomeIcon icon='lock' color='#FF0000' className="mr-2" />
                                                                                    ) : (
                                                                                        <FontAwesomeIcon icon='lock-open' color='#22A50D' className="mr-2" />
                                                                                    )}
                                                                                </div>
                                                                                <div className='card-author-name'>
                                                                                    <p>Criado por <span>{trilha.autor}</span></p>
                                                                                </div>
                                                                                <p className="text-muted">
                                                                                    <TextTruncate
                                                                                        line={2}
                                                                                        element="span"
                                                                                        truncateText="…"
                                                                                        text={trilha.previa ? trilha.previa : idioma.acesso}
                                                                                    />
                                                                                </p>
                                                                                <div className='card-title-access row'>
                                                                                    <div className="col-md-12 col-lg-6 card-date">
                                                                                        <p className="text-muted">{format(new Date(trilha.dataCriacao), 'dd/MM/yyyy', { locale: pt })}</p>
                                                                                    </div>
                                                                                    <div className="col-md-12 col-lg-6 card-rate">
                                                                                        <Rating name='simple-controlled' value={trilha.avaliacao_media ? trilha.avaliacao_media : 0} disabled />
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.sem_dados} />
                                                : <LoadTrilhas />
                                            }
                                        </div>
                                    </div>
                                    <div className={matriculadoAtv ? "tab-pane active" : "tab-pane p-3"} id="meus" role="tabpanel">

                                        <div className="row pt-3">
                                            <div className="col-md-8">
                                                <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev, "Matriculado")} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisar} />
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <span className="pr-3" onClick={() => this.handleSearchMatriculado(procura, id_usuario)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                                    <span value={procura} onClick={() => this.eraseSearchMatriculado("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                                </div>
                                            </div>
                                        </div>

                                        {dadosApi ?
                                            <div className="row">
                                                {cursos.length !== 0 ? cursos.map(curso => (
                                                    <div className="col-md-6 col-lg-6 col-xl-4" key={curso.id}>
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + curso.id + "/modulos/"}>
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <img className="card-imagem-trilha img-fluid listagem-trilhas" src={curso.fileName != null ? apiImagem + curso.fileName : apiImagem + "fundo_startup.jpg"} alt="Capa da trilha" />
                                                                    <div className="directory-content p-4">
                                                                        <div className='card-title-access'>
                                                                            <h5 className="titulo-startup">{curso.nome}</h5>
                                                                            <FontAwesomeIcon icon='lock-open' color='#22A50D' className="mr-2" />
                                                                        </div>
                                                                        <div className='card-author-name'>
                                                                            <p>Criado por <span>{curso.autor}</span></p>
                                                                        </div>
                                                                        <p className="text-muted">
                                                                            <TextTruncate
                                                                                line={2}
                                                                                element="span"
                                                                                truncateText="…"
                                                                                text={curso.previa ? curso.previa : idioma.acesso}
                                                                            />
                                                                        </p>
                                                                        <div className='card-title-access row'>
                                                                            <div className="col-md-12 col-lg-6 card-date">
                                                                                <p className="text-muted">{format(new Date(curso.dataCriacao), 'dd/MM/yyyy', { locale: pt })}</p>
                                                                            </div>
                                                                            <div className="col-md-12 col-lg-6 card-rate">
                                                                                <Rating name='simple-controlled' value={curso.avaliacao_media ? curso.avaliacao_media : 0} disabled />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Link>
                                                    </div>
                                                )) : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.sem_dados} />}
                                            </div>
                                            : <LoadTrilhas />}
                                    </div>
                                    <div className={adminAtv ? "tab-pane active" : "tab-pane p-3"} id="meus" role="tabpanel">
                                        <div className="row pt-3">
                                            <div className="col-md-8">
                                                <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev, "Admin")} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisar} />
                                            </div>
                                            <div className="col-md-4">
                                                <div>
                                                    <span className="pr-3" onClick={() => this.handleSearchGerenciarCursos(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                                    <span value={procura} onClick={() => this.eraseSearchGerenciarCursos("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                                </div>
                                            </div>
                                        </div>
                                        {dadosApi ?
                                            <div className="row">
                                                {todosCursos.length != 0 ? todosCursos.map(curso => (
                                                    <div className="col-md-6 col-xl-4" key={curso.id}>
                                                        <Link to={curso.visibilidade === 'Público' ?
                                                            "/" + usuario?.personalizacao?.titulo + "/trilhas/" + curso.id + "/modulos/" :
                                                            "/" + usuario?.personalizacao?.titulo + "/trilhas/" + curso.id + "/autores/"
                                                        }>
                                                            <div className="directory-card card">
                                                                <div>
                                                                    <img className="card-imagem-trilha img-fluid listagem-trilhas" src={curso.fileName != null ? apiImagem + curso.fileName : apiImagem + "fundo_startup.jpg"} alt="Capa da trilha" />
                                                                    <div className="directory-content p-4">
                                                                        <div className='card-title-access'>
                                                                            <h5 className="titulo-startup">{curso.nome}</h5>
                                                                            {curso?.visibilidade === 'Privado' ? (
                                                                                <FontAwesomeIcon icon='lock' color='#FF0000' className="mr-2" />
                                                                            ) : (
                                                                                <FontAwesomeIcon icon='lock-open' color='#22A50D' className="mr-2" />
                                                                            )}
                                                                        </div>
                                                                        <div className='card-author-name'>
                                                                            <p>Criado por <span>{curso.autor}</span></p>
                                                                        </div>
                                                                        <p className="text-muted">
                                                                            <TextTruncate
                                                                                line={2}
                                                                                element="span"
                                                                                truncateText="…"
                                                                                text={curso.previa ? curso.previa : idioma.acesso}
                                                                            />
                                                                        </p>
                                                                        <div className='card-title-access row'>
                                                                            <div className="col-md-12 col-lg-6 card-date">
                                                                                <p className="text-muted">{format(new Date(curso.dataCriacao), 'dd/MM/yyyy', { locale: pt })}</p>
                                                                            </div>
                                                                            <div className="col-md-12 col-lg-6 card-rate">
                                                                                <Rating name='simple-controlled' value={curso.avaliacao_media ? curso.avaliacao_media : 0} disabled />
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </Link>
                                                    </div>
                                                )) : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.sem_dados} />}
                                            </div>
                                            : <LoadTrilhas />}
                                    </div>
                                </div>
                            </div>

                            {dadosApi && todosCursosAtv &&
                                !pesquisandoTodos ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorTodos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorTodos ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.trilhas.prevPage ? this.props.trilhas.prevPage : 1)}>{this.props.trilhas.prevPage ? this.props.trilhas.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.trilhas ? this.props.trilhas.currentPage : "1"}</span></li>

                                        {proximaPaginaTodos && this.props.trilhas.nextPage && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.trilhas.nextPage ? this.props.trilhas.nextPage : 1)}>{this.props.trilhas.nextPage ? this.props.trilhas.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaTodos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.trilhas ? this.props.trilhas.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                :
                                pesquisandoTodos && this.props.search &&
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorTodos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageSearchAtual(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorTodos ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageSearchAtual(this.props.search.prevPage ? this.props.search.prevPage : 1)}>{this.props.search.prevPage ? this.props.search.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.search ? this.props.search.currentPage : "1"}</span></li>

                                        {proximaPaginaTodos && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageSearchAtual(this.props.search.nextPage ? this.props.search.nextPage : 1)}>{this.props.search.nextPage ? this.props.search.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaTodos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageSearchAtual(this.props.search ? this.props.search.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                            }

                            {dadosApi && matriculadoAtv &&
                                !pesquisandoMatriculado ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorMatriculados ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualMatriculado(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorMatriculados ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualMatriculado(cursos.prevPage ? this.props.cursos.prevPage : 1)}>{this.props.cursos.prevPage ? this.props.cursos.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.cursos ? this.props.cursos.currentPage : "1"}</span></li>

                                        {proximaPaginaMatriculados && this.props.cursos.nextPage && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualMatriculado(this.props.cursos.nextPage ? this.props.cursos.nextPage : 1)}>{this.props.cursos.nextPage ? this.props.cursos.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaMatriculados ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualMatriculado(this.props.cursos ? this.props.cursos.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                :
                                pesquisandoMatriculado && this.props.searchCursos &&
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorMatriculados ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMatriculado(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorMatriculados ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMatriculado(this.props.searchCursos.prevPage ? this.props.searchCursos.prevPage : 1)}>{this.props.searchCursos.prevPage ? this.props.searchCursos.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.searchCursos ? this.props.searchCursos.currentPage : "1"}</span></li>

                                        {proximaPaginaMatriculados && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMatriculado(this.props.searchCursos.nextPage ? this.props.searchCursos.nextPage : 1)}>{this.props.searchCursos.nextPage ? this.props.searchCursos.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaMatriculados ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMatriculado(this.props.searchCursos ? this.props.searchCursos.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                            }

                            {dadosApi && meusCursosAtv &&
                                !pesquisandoMeus ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorMeus ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualMeus(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorMeus ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualMeus(this.props.meusCursos.prevPage ? this.props.meusCursos.prevPage : 1)}>{this.props.meusCursos.prevPage ? this.props.meusCursos.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.meusCursos ? this.props.meusCursos.currentPage : "1"}</span></li>

                                        {proximaPaginaMeus && this.props.meusCursos.nextPage && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualMeus(this.props.meusCursos.nextPage ? this.props.meusCursos.nextPage : 1)}>{this.props.meusCursos.nextPage ? this.props.meusCursos.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaMeus ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualMeus(this.props.meusCursos ? this.props.meusCursos.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                :
                                pesquisandoMeus && this.props.searchMeus &&
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorMeus ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMeus(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorMeus ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMeus(this.props.searchMeus.prevPage ? this.props.searchMeus.prevPage : 1)}>{this.props.searchMeus.prevPage ? this.props.searchMeus.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.searchMeus ? this.props.searchMeus.currentPage : "1"}</span></li>

                                        {proximaPaginaMeus && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMeus(this.props.searchMeus.nextPage ? this.props.searchMeus.nextPage : 1)}>{this.props.searchMeus.nextPage ? this.props.searchMeus.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaMeus ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchMeus(this.props.searchMeus ? this.props.searchMeus.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                            }


                            {dadosApi && adminAtv &&
                                !pesquisandoTodosCursos ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorTodosCursos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualTodos(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorTodosCursos ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualTodos(this.props.todosCursos.prevPage ? this.props.todosCursos.prevPage : 1)}>{this.props.todosCursos.prevPage ? this.props.todosCursos.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.todosCursos ? this.props.todosCursos.currentPage : "1"}</span></li>

                                        {proximaPaginaTodosCursos && this.props.todosCursos.nextPage && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualTodos(this.props.todosCursos.nextPage ? this.props.todosCursos.nextPage : 1)}>{this.props.todosCursos.nextPage ? this.props.todosCursos.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaTodosCursos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualTodos(this.props.todosCursos ? this.props.todosCursos.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                :
                                pesquisandoTodosCursos && this.props.searchTodosCursos &&
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnteriorTodosCursos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchTodos(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnteriorTodosCursos ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchTodos(this.props.searchTodosCursos.prevPage ? this.props.searchTodosCursos.prevPage : 1)}>{this.props.searchTodosCursos.prevPage ? this.props.searchTodosCursos.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.searchTodosCursos ? this.props.searchTodosCursos.currentPage : "1"}</span></li>

                                        {proximaPaginaTodosCursos && <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchTodos(this.props.searchTodosCursos.nextPage ? this.props.searchTodosCursos.nextPage : 1)}>{this.props.searchTodosCursos.nextPage ? this.props.searchTodosCursos.nextPage : ""}</span></li>}

                                        <li className={temProximaPaginaTodosCursos ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtualSearchTodos(this.props.searchTodosCursos ? this.props.searchTodosCursos.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                            }
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    trilhas: state.trilhas.trilhasPublicas,
    todosCursos: state.trilhas.trilhas,
    cursos: state.trilhas.cursos,
    search: state.trilhas.searchTodos,
    searchCursos: state.trilhas.searchCursos,
    searchMeus: state.trilhas.searchMeus,
    searchTodosCursos: state.trilhas.searchTodosCursos,
    meusCursos: state.trilhas.meusCursos
})
export default connect(mapStatetoProps, actions)(ListarTrilhas);