export const portugues = {
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  acompanhamento: {
    titulo: 'Acompanhamento',
    atividades: 'Atividades',
    pilares: 'Indicadores de desenvolvimento',
    bt_verentregas: 'ver todas as entregas',
    bt_recolherentregas: 'recolher',
    visao_geral: 'Diagnóstico do Módulo',
    comparativo: 'Análise comparativa dos módulos',
    mentorias: 'Mentoria: ',
    aplicar: 'Aplicar radar',
    detalhes: 'Clique para ver ou editar o radar',
    aplicado: 'Radar aplicado',
    media: 'Média',
    naoAplicado: 'Radar não aplicado',
    bt_aprovarevidencia: 'Validar evidência',
    modulos: 'Módulos',
    entregas: 'Entregas'
  },
  participantes: {
    titulo: 'Participantes',
    bt_voltar: 'Voltar',
    bt_cadastrar: 'Cadastrar',
    bt_cancelar: 'Cancelar',
    bt_vincular: 'Vincular participantes',
    bt_salvar: 'Salvar',
    nome: 'Nome',
    perfil: 'Perfil',
    acao: 'Ação',
    bt_conversar: 'Conversar',
    bt_editar: 'Editar',
    bt_deletar: 'Deletar',
    erro_acesso: 'Acesso negado!',
    sem_perfil: "Não possui perfil"
  },
  modal_deletar: {
    titulo: 'Você tem certeza?',
    info: 'Não será possível restaurar esse usuário!',
    bt_cancelar: 'Cancelar',
    bt_sucesso: 'Sim, excluir este usuário!',
    info_sucesso: 'Usuário excluído com sucesso!'
  },
  cadastrar: {
    erro_nome: 'Preencha o nome',
    erro_cidade: 'Preencha a cidade',
    erro_incub: 'Selecione uma incubadora',
    msg_sucesso: 'Negócio cadastrado com sucesso',
    titulo: 'Cadastrar negócio',
    nome: 'Nome',
    nome2: 'Nome completo',
    cnpj: 'CNPJ',
    segmento: 'Segmento',
    segmento2: 'Segmento de atuação',
    cep: 'CEP',
    end: 'Endereço',
    img: 'Imagem do negócio',
    cidade: 'Cidade',
    estado: 'Estado',
    site: 'Site',
    site2: 'Site do negócio',
    incubadora: 'Incubadora',
    incubadora2: 'Escolher incubadora',
    pitch: 'Link do pitch',
    pitch2: 'Link do pitch (apenas Youtube)',
    num_clientes: 'Número de clientes'
  },
  cadastrarParticipantes: {
    erro_nome: 'Preencha o nome',
    erro_idioma: 'Selecione um idioma',
    erro_email: 'Preencha o e-mail',
    erro_email2: 'Preencha um e-mail válido',
    erro_senha: 'Preencha a senha',
    erro_senha2: 'Preencha a senha com no mínimo 6 caracteres',
    msg_sucesso: 'Participante cadastrado com sucesso!',
    titulo: 'Adicionar participante',
    nome: 'Nome',
    nome2: 'Nome completo',
    idioma: 'Idioma',
    selecioneIdioma: 'Selecione o idioma',
    portugues: 'Português',
    ingles: 'Inglês',
    senha: 'Senha',
    email: 'E-mail'
  },
  config: {
    modal_titulo: 'Você tem certeza?',
    modal_confirm: 'Sim, desativar este negócio!',
    modal_sucesso: 'Negócio desativado com sucesso!',
    modal_titulo2: 'Deseja deletar o negócio?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de participantes, Jornadas de Desenvolvimento, evidências e informações do negócio. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_confirm2: 'Digite CONFIRMAR para realizar a exclusão',
    bt_del: 'Deletar',
    confirm: 'CONFIRMAR',
    erro_confirm: 'Mensagem de confirmação inválida',
    modal_sucesso2: 'Negócio excluído com sucesso!',
    msg_sucesso: 'Negócio cadastrado com sucesso!',
    titulo: 'Configurações',
    metodologia: 'Jornada de Desenvolvimento',
    incubadoras: 'Incubadoras',
    editar_foto: 'Editar foto',
    editar_capa: 'Editar capa',
    editar: 'Editar',
    desativar: 'Desativar'
  },
  editar: {
    erro_nome: 'Preencha o nome',
    carregando: 'Carregando...',
    titulo: 'Editar negócio',
    nome: 'Nome',
    nome2: 'Nome do negócio',
    cnpj: 'CNPJ',
    segmento: 'Segmento',
    segmento2: 'Segmento do negócio',
    cep: 'CEP',
    endereco: 'Endereço',
    endereco2: 'Endereço do negócio',
    cidade: 'Cidade',
    cidade2: 'Cidade do negócio',
    estado: 'Estado',
    estado2: 'Estado do negócio',
    site: 'Site',
    site2: 'Site do negócio',
    link: 'Link do pitch',
    num_clientes: 'Número de clientes',
    face: 'Facebook da incubadora',
    insta: 'Instagram da incubadora',
    linkedin: 'Linkedin da incubadora',
    twitter: 'Twitter da incubadora'
  },
  editarCapa: {
    titulo: 'Editar capa de fundo',
    foto: 'Foto de capa',
    msg_sucesso: 'Capa alterada com sucesso!',
    info: 'Envie uma foto'
  },
  editarImagem: {
    titulo: 'Editar foto do perfil',
    foto: 'Foto de perfil'
  },
  editarMetodologia: {
    titulo: 'Acompanhamento',
    bt_vincular: 'Vincular nova Jornada de Desenvolvimento',
    vincular: 'Vincular Jornada de Desenvolvimento',
    bt_vincular2: 'Vincular',
    escolher: 'Escolher Jornada de Desenvolvimento',
    metodologia: 'Jornada de Desenvolvimento',
    processo: 'Processo',
    vincular2: 'Vincular processo',
    escolher2: 'Selecionar processo',
    radar: 'Radar',
    deletar: 'Deletar',
    modal_titulo: 'Escolha o usuário desejado:',
    modal_info: 'Escolha a Jornada de Desenvolvimento',
    modal_confirm: 'Vincular Jornada de Desenvolvimento',
    bt_editar: 'Editar Jornada de Desenvolvimento'
  },
  entrega: {
    msg_sucesso: 'Entrega realizada com sucesso!',
    titulo: 'Realizar entrega',
    escolher: 'Escolher o tipo',
    tipo: 'Tipo da entrega',
    img: 'Imagem',
    vid: 'Video',
    tex: 'Texto',
    arq: 'Arquivo',
    doc: 'Documento de entrega',
    link: 'Link da entrega',
    bt_enviar: 'Enviar'
  },
  etapa: {
    titulo: 'Entregas do Negócio'
  },
  evidencias: {
    erro_validacao: 'Preencha a validação',
    erro_arquivo: 'Selecione um arquivo',
    erro_tipo: 'Selecione um tipo',
    erro_link: 'Insira sua resposta no campo',
    modal_titulo: 'Você tem certeza?',
    modal_info: 'Não será possível restaurar essa evidência, nem as mentorias relacionadas a evidência!',
    modal_confirm: 'Sim, excluir esta evidência!',
    modal_sucesso: 'Evidência excluída com sucesso!',
    titulo: 'Evidência',
    nova_entre: 'Realizar Nova Entrega',
    validado: 'Validado',
    nao_validade: 'Não validado',
    aguar_validado: 'Aguardando validação',
    nome_arq: 'Nome do arquivo:',
    evidencia: 'Evidência:',
    arquivos: 'Arquivos:',
    baixar_arq: 'Baixar arquivo',
    val_evidencia: 'Validar evidência',
    del_evidencia: 'Deletar evidência',
    apro_evidencia: 'Aprovar evidência?',
    sim: 'sim',
    nao: 'nao',
    mentorias: 'Mentorias:',
    info_evidencia: 'Realize a entrega deste módulo clicando no botão acima "Realizar Nova Entrega',
    imagens: 'Imagens:',
    sem_dados: 'Acesso negado!',
    sem_dados_sub: 'Não há informações para serem exibidas.',
  },
  perfil: {
    modal_titulo: 'Escolha a Jornada de Desenvolvimento desejada:',
    modal_info: 'Escolha a Jornada de Desenvolvimento',
    modal_confirm: 'Vincular Jornada de Desenvolvimento',
    modal_titulo2: 'Escolha o usuário desejado:',
    titulo: 'Acompanhamento',
    metodologia: 'Jornada de Desenvolvimento'
  },
  radar: {
    titulo: 'Aplicar radar',
    bt_avancar: 'Avançar',
    resposta: 'Digite a resposta',
    alerta_titulo: 'Sucesso!',
    alerta_msg: 'Informações salvas com sucesso!'
  },
  radarResumo: {
    titulo: 'Resumo do Radar',
    visao: 'Visão geral',
    detalhes: 'Detalhes',
    mediaGeral: 'Média:',
    media: 'Média do pilar:',
    bt_editar: 'Editar radar'
  },
  vincularIncubadora: {
    info: 'Este negócio está vinculada a incubadora:',
    vincular: 'Vincular incubadora',
    escolher: 'Escolher incubadora'
  },
  vincularMetodologia: {
    modal_titulo: 'Deseja deletar a Jornada de Desenvolvimento?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de Jornadas de Desenvolvimento, evidências. </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
    confirm: 'CONFIRMAR',
    modal_erro: 'Mensagem de confirmação inválida!',
    modal_sucesso: 'Jornada de Desenvolvimento excluída com sucesso!',
    radar: 'Selecionar radar'
  },
  vincularParticipante: {
    modal_titulo: 'Sim, vincular este participante!',
    modal_sucesso: 'Participante vinculado com sucesso!',
    titulo: 'Vincular Participantes',
    pesquisar: 'Pesquisar...',
    bt_pesquisar: 'Pesquisar',
    nome: 'Nome',
    ambiente: 'Ambiente de inovação',
    acao: 'Ação',
    negocio: 'Negócio',
    page1: 'Primeira',
    page2: 'Última'
  }
}

export const ingles = {
  sem_dados_sub: 'What a pity! There is no information to display.',
  acompanhamento: {
    titulo: 'Follow-up',
    atividades: 'Activities',
    pilares: 'Development indicators',
    bt_verentregas: 'see all deliveries',
    bt_recolherentregas: 'retract',
    visao_geral: 'Module Diagnostics',
    detalhes: 'Click to see or edit',
    comparativo: 'Comparative analysis of modules',
    mentorias: 'Mentorships',
    media: 'Average',
    aplicar: 'Apply radar',
    bt_aprovarevidencia: 'Validate evidence',
    modulos: 'Modules',
    entregas: 'Deliveries'
  },
  participantes: {
    titulo: 'Participants',
    bt_voltar: 'Back',
    bt_cadastrar: 'Register',
    bt_cancelar: 'Cancel',
    bt_vincular: 'Link participant',
    bt_salvar: 'Save',
    nome: 'Name',
    perfil: 'Profile',
    acao: 'Options',
    bt_conversar: 'Chat',
    bt_editar: 'Edit',
    bt_deletar: 'Delete',
    erro_acesso: 'Access denied!',
    sem_perfil: "No profile"
  },
  cadastrar: {
    erro_nome: 'Name must be filled',
    erro_cidade: 'City must be filled',
    erro_incub: 'Select a incubator',
    msg_sucesso: 'Business successfully registered',
    titulo: 'Register business',
    nome: 'Name',
    nome2: 'Full name',
    cnpj: 'CNPJ',
    segmento: 'Segment',
    segmento2: 'Operating segment',
    cep: 'CEP',
    end: 'Address',
    img: 'Business image',
    cidade: 'City',
    estado: 'State',
    site: 'Website',
    site2: 'Business website',
    incubadora: 'Incubator',
    incubadora2: 'Choose incubator',
    pitch: 'Pitch link',
    pitch2: 'Pitch link (only Youtube)',
    num_clientes: 'Number of clients'
  },
  cadastrarParticipantes: {
    erro_nome: 'Name must be filled',
    erro_idioma: 'Select a language',
    erro_email: 'E-mail must be filled',
    erro_email2: 'Fill in a valid email',
    erro_senha: 'Password must be filled',
    erro_senha2: 'Password must be at least 6 characters long',
    msg_sucesso: 'Participant successfully registered!',
    titulo: 'Add participant',
    nome: 'Name',
    nome2: 'Full name',
    idioma: 'Language',
    selecioneIdioma: 'Select the language',
    portugues: 'Portuguese',
    ingles: 'English',
    senha: 'Password',
    email: 'E-mail'
  },
  modal_deletar: {
    titulo: 'Are you sure?',
    info: 'It will not be possible to restore this user!',
    bt_cancelar: 'Cancel',
    bt_sucesso: 'Yes, delete this user!',
    info_sucesso: 'User successfully deleted!'
  },
  config: {
    modal_titulo: 'Are you sure?',
    modal_confirm: 'Yes, disable this business!',
    modal_sucesso: 'Business successfully disabled!',
    modal_titulo2: 'Do you want to delete the deal?',
    modal_info: 'By doing so you agree to the exclusion of participant links, methodologies, evidence and business information. </br>Type <strong>CONFIRM</strong> to delete',
    modal_confirm2: 'Type CONFIRM to perform the deletion',
    bt_del: 'Delete',
    confirm: 'CONFIRM',
    erro_confirm: 'Invalid confirmation message',
    modal_sucesso2: 'Business successfully deleted!',
    msg_sucesso: 'Business successfully registered!',
    titulo: 'Settings',
    metodologia: 'Methodology',
    incubadoras: 'Incubators',
    editar_foto: 'Edit photo',
    editar_capa: 'Edit cover photo',
    editar: 'Edit',
    desativar: 'Disable'
  },
  editar: {
    erro_nome: 'Name must be filled',
    carregando: 'Loading...',
    titulo: 'Edit business',
    nome: 'Name',
    nome2: 'Business name',
    cnpj: 'CNPJ',
    segmento: 'Segment',
    segmento2: 'Business segment',
    cep: 'CEP',
    endereco: 'Address',
    endereco2: 'Business address',
    cidade: 'City',
    cidade2: 'Business city',
    estado: 'State',
    estado2: 'Business state',
    site: 'Website',
    site2: 'Business website',
    link: 'Pitch link',
    num_clientes: 'Number of clients',
    face: 'Incubator Facebook',
    insta: 'Incubator Instagram',
    linkedin: 'Incubator Linkedin',
    twitter: 'Incubator Twitter'
  },
  editarCapa: {
    titulo: 'Edit cover photo',
    foto: 'Cover photo',
    msg_sucesso: 'Cover photo successfully edited!',
    info: 'Send a picture'
  },
  editarImagem: {
    titulo: 'Edit profile picture',
    foto: 'Profile picture'
  },
  editarMetodologia: {
    titulo: 'Follow-up',
    bt_vincular: 'Link new methodology',
    vincular: 'Link Methodology',
    bt_vincular2: 'Link',
    escolher: 'Select Methodology',
    metodologia: 'Methodology',
    processo: 'Process',
    vincular2: 'Link process',
    escolher2: 'Select process',
    radar: 'Radar',
    deletar: 'Delete',
    modal_titulo: 'Choose the desired user',
    modal_info: 'Select Methodology',
    modal_confirm: 'Link Methodology',
    bt_editar: 'Edit Methodology'
  },
  entrega: {
    msg_sucesso: 'Delivery made successfully!',
    titulo: 'Make delivery',
    escolher: 'Select type',
    tipo: 'Type of sending',
    img: 'Image',
    vid: 'Video',
    tex: 'Text',
    arq: 'File',
    doc: 'Delivery document',
    link: 'Delivery link',
    bt_enviar: 'Send'
  },
  etapa: {
    titulo: 'Business Deliverables'
  },
  evidencias: {
    erro_validacao: 'Fill in the validation',
    erro_arquivo: 'Select a file',
    erro_tipo: 'Select the type',
    erro_link: 'Fill the field with your answer',
    modal_titulo: 'Are you sure?',
    modal_info: 'It will not be possible to restore this evidence, nor the mentorships related to evidence!',
    modal_confirm: 'Yes, delete this evidence!',
    modal_sucesso: 'Evidence successfully deleted!',
    titulo: 'Evidence',
    nova_entre: 'Make New Delivery',
    validado: 'Validated',
    nao_validade: 'Not validated',
    aguar_validado: 'Waiting for validation',
    nome_arq: 'Filename:',
    evidencia: 'Evidence::',
    arquivos: 'Files:',
    baixar_arq: 'Download file',
    val_evidencia: 'Validate evidence',
    del_evidencia: 'Delete evidence',
    apro_evidencia: 'Approve evidence?',
    sim: 'Yes',
    nao: 'No',
    mentorias: 'Mentorships:',
    info_evidencia: 'Make the delivery of this module by clicking on the button above "Make New Delivery',
    imagens: 'Images:',
    sem_dados: 'Access denied!',
    sem_dados_sub: 'There is no information to display.',
  },
  perfil: {
    modal_titulo: 'Choose the desired methodology:',
    modal_info: 'Choose the Methodology',
    modal_confirm: 'Link Methodology',
    modal_titulo2: 'Choose the desired user:',
    titulo: 'Follow-up',
    metodologia: 'Methodology'
  },
  radar: {
    titulo: 'Apply radar',
    bt_avancar: 'Go forward',
    resposta: 'Type the answer',
    alerta_titulo: 'Success!',
    alerta_msg: 'Informations successfully saved!'
  },
  radarResumo: {
    titulo: 'Radar Summary',
    visao: 'Overview',
    mediaGeral: 'Average:',
    detalhes: 'Details',
    media: 'Pillar average:',
    bt_editar: 'Edit radar'
  },
  vincularIncubadora: {
    info: 'This business is linked to incubator:',
    vincular: 'Link incubator',
    escolher: 'Select the incubator'
  },
  vincularMetodologia: {
    modal_titulo: 'Do you want to delete the methodology?',
    modal_info: 'By doing so you agree to the exclusion of linkages from methodologies, evidence. </br>Type <strong>CONFIRM</strong> to delete',
    modal_confirm: 'Type CONFIRM to perform the deletion',
    confirm: 'CONFIRM',
    modal_erro: 'Invalid confirmation message!',
    modal_sucesso: 'Methodology successfully deleted!',
    radar: 'Select a radar'
  },
  vincularParticipante: {
    modal_titulo: 'Yes, link this participant!',
    modal_sucesso: 'Participant successfully linked!',
    titulo: 'Link Participants',
    pesquisar: 'Search...',
    bt_pesquisar: 'Search',
    nome: 'Name',
    ambiente: 'Innovation space',
    acao: 'Option',
    negocio: 'Business',
    page1: 'First',
    page2: 'Last'
  }
}