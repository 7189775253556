import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row, Card, Table } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import Swal from "sweetalert2"
import ReactTooltip from 'react-tooltip'
import Alert from '@material-ui/lab/Alert';


import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';

class EditarDesafio extends Component {
    state = {
        id_desafio: "",
        titulo: "",
        descricao: "",
        responsavel: "",
        nota_corte: "",
        cargo: "",
        empresa: "",
        id_autor: "",
        file: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_desafio } = this.props.match.params;
        this.props.getVerDesafio(id_desafio);
        this.listarArquivos(id_desafio);

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    async componentDidUpdate(nextProps) {
        const { id_desafio } = this.props.match.params;
        if (!this.props.desafio && nextProps.desafio) this.props.getVerDesafio(id_desafio);
        if (!this.props.arquivos && nextProps.arquivos) this.listarArquivos(id_desafio);
        await this.receberDadosAPI();
    }
    receberDadosAPI() {
        const { id_desafio } = this.props.match.params;
        if (typeof this.props.desafio !== "undefined" && this.props.desafio !== null && this.props.desafio.id == id_desafio && !this.state.dadosAPI) {
            this.setState({ id_desafio: this.props.desafio.id })
            this.setState({ titulo: this.props.desafio.titulo })
            this.onEditorStateChangeInicial(this.props.desafio.descricao)
            this.setState({ responsavel: this.props.desafio.responsavel })
            this.setState({ cargo: this.props.desafio.cargo })
            this.setState({ empresa: this.props.desafio.empresa })
            this.setState({ nota_corte: this.props.desafio.nota_corte })
            this.setState({ dadosAPI: true });
        }
    }

    listarArquivos(id_desafio) {
        this.props.getArquivosDesafio(id_desafio);
        const { arquivos } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (arquivos === "undefined") return null
    }


    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onEditorStateChangeInicial = (descricao) => {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(descricao)
                )

            ),
        })
    }


    async atualizarDesafio(id_desafio, titulo, editorState, responsavel, cargo, empresa, nota_corte) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        await this.receberDadosForm();
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putDesafio({ id_desafio, titulo, descricao, responsavel, cargo, empresa, nota_corte }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }
    receberDadosForm() {
        this.setState({ id_desafio: document.querySelector("#id_desafio").value })
        this.setState({ titulo: document.querySelector("#titulo").value })
        this.setState({ responsavel: document.querySelector("#responsavel").value })
        this.setState({ cargo: document.querySelector("#cargo").value })
        this.setState({ empresa: document.querySelector("#empresa").value })
    }
    validate() {
        const { titulo, erro, idioma } = this.state;
        if (!titulo) return this.setState({ erro: { message: idioma.cadastrarDesafio.erro_titulo } });
        return true;
    }

    chamerCadArquivo(id_desafio) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.cadastrarDesafio.erro_arq,
            input: 'file',
        })
            .then((result) => {
                if (result) {
                    this.cadArquivo(id_desafio, result.value)
                }
            })
    }

    async cadArquivo(id_desafio, file) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        this.setState({ carregando: true })
        const formData = new FormData();
        formData.append('id_desafio', id_desafio)
        formData.append('id_autor', this.props.usuario.id)
        formData.append('file', file);
        this.props.postArquivo(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarArquivos(id_desafio);

            }
        })

    }

    chamarDeletar(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.cadastrarDesafio.info_imagem,
            showCancelButton: true,
            confirmButtonText: idioma.cadastrarDesafio.bt_deletar,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.deletarArquivo(id)
            }
        })
    }

    async deletarArquivo(id) {
        const { id_desafio } = this.state
        this.setState({ success: "" });
        this.setState({ erro: "" });
        this.setState({ carregando: true })
        this.props.deletarArquivo(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarArquivos(id_desafio);

            }
        })

    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu
    }


    render() {
        const { id_desafio, idioma, titulo, nota_corte, file, editorState, responsavel, cargo, empresa, carregando, dadosAPI, erro, success, formSuccess } = this.state;
        ////console.log(titulo, cargo)
        const { usuario } = this.props;
        var arquivos = []
        if (this.props.arquivos) arquivos = this.props.arquivos.dados
        if (formSuccess) return <Redirect to={{
            pathname: "/hub/" + id_desafio + "/ver",
            state: { msg: idioma.cadastrarDesafio.edicao_suc }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarDesafio.titulo3} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <div>
                                {/* <img className="rounded-circle mt-2" src={imagem} width="80" height="80" alt={nome}></img> */}
                            </div>
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/" + id_desafio + "/ver"}>
                                <Botao texto={idioma.pagina_ver.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn mb-3 btn-outline btn-md mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}` }}>{idioma.pagina_ver.bt_voltar}</button> */}
                            </Link>

                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="titulo">{idioma.cadastrarDesafio.titulo}</Label>
                                            <Input type="text" value={titulo} id="titulo" name="titulo" className="form-control" autoComplete="titulo" placeholder={dadosAPI ? idioma.cadastrarDesafio.titulo2 : "Carregando..."} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("titulo", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={8}>

                                        <FormGroup>
                                            <Label for="empresa">{idioma.cadastrarDesafio.empresa}</Label>
                                            <Input type="text" value={empresa} id="empresa" name="empresa" className="form-control" autoComplete="empresa" placeholder={dadosAPI ? idioma.cadastrarDesafio.empresa : "Carregando..."} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("empresa", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>

                                        <FormGroup>
                                            <Label for="nota_corte">{idioma.cadastrarDesafio.nota}</Label>
                                            <Input type="number" step="0.1" value={nota_corte} id="nota_corte" name="nota_corte" className="form-control" autoComplete="nota_corte" placeholder={dadosAPI ? idioma.cadastrarDesafio.nota2 : "Carregando..."} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nota_corte", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={10}>
                                        <FormGroup>
                                            <Label for="responsavel">{idioma.cadastrarDesafio.responsavel}</Label>
                                            <Input type="text" value={responsavel} id="responsavel" name="responsavel" className="form-control" autoComplete="responsavel" placeholder={dadosAPI ? idioma.cadastrarDesafio.responsavel2 : "Carregando..."} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("responsavel", ev)} />
                                        </FormGroup>
                                    </Col>

                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="cargo">{idioma.cadastrarDesafio.cargo}</Label>
                                            <Input type="text" value={cargo} id="cargo" name="cargo" className="form-control" autoComplete="cargo" placeholder={dadosAPI ? idioma.cadastrarDesafio.cargo : "Carregando..."} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("cargo", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descricao">{idioma.cadastrarDesafio.descricao}</Label>
                                            <Card className="card-editor">
                                                {dadosAPI ?
                                                    <div>
                                                        <Editor
                                                            editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={this.onEditorStateChange}

                                                        />
                                                    </div>
                                                    : ""}

                                            </Card>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="hidden" value={id_desafio} id="id_desafio" name="id_desafio" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="col-md-12 mb-2">
                                    <Alert severity="warning">{idioma.cadastrarDesafio.info_docs}</Alert>
                                </div>
                                <Link onClick={() => this.chamerCadArquivo(id_desafio)} to="#">
                                    <Botao loading={carregando} texto={idioma.cadastrarDesafio.bt_cadastrar} color={this.props.usuario?.personalizacao?.cor_terciaria} classes="mb-2" />
                                </Link>

                                <Row>
                                    <Table>
                                        <thead>
                                            <th >{idioma.avaliadores.nome}</th>
                                            <th>{idioma.avaliadores.acao}</th>
                                        </thead>
                                        <tbody>
                                            {arquivos.map(arquivo => (
                                                <tr>
                                                    <td>{arquivo.originalName}</td>
                                                    <td>
                                                        <a data-tip data-for='deletar'>
                                                            <Link to="#" onClick={() => this.chamarDeletar(arquivo.id)} className="mr-2">
                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                            </Link>
                                                        </a>
                                                        <ReactTooltip id='deletar' type='error'>
                                                            <span>{idioma.cadastrarDesafio.bt_deletar}</span>
                                                        </ReactTooltip>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Row>

                                <Link onClick={() => this.atualizarDesafio(id_desafio, titulo, editorState, responsavel, cargo, empresa, nota_corte)} to="#">
                                    <Botao loading={carregando} texto={idioma.cadastrarDesafio.bt_salvar} cor="success" />
                                </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    desafio: state.hub.desafio,
    arquivos: state.hub.arquivosDesafio


})
export default connect(mapStateToProps, actions)(EditarDesafio)