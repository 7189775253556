import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaMetodologia_bilingue';

class EditarMetodolo extends Component {
  state = {
    nome: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    msg_backend: portuguese,
    idioma: portugues,
    colunas: ''
  }
  componentDidMount() {

    const { usuario } = this.props;
    this.listarMetodologia();

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }

  componentDidUpdate(nextProps) {
    if (!this.props.metodologia && nextProps.metodologia) this.listarMetodologia();
    this.receberDadosAPI();
  }

  componentWillUnmount() {
    const { limparMetodologia } = this.props;

    limparMetodologia();
  }


  receberDadosAPI() {
    if (typeof this.props.metodologia != "undefined" && this.props.metodologia != null && !this.state.dadosApi) {
      this.setState({ nome: this.props.metodologia.dados.nome });
      this.setState({ colunas: this.props.metodologia.dados.colunas });
      this.setState({ dadosApi: true });
    }
  }

  listarMetodologia() {
    const { id_metodologia } = this.props.match.params;
    this.props.getMetodologia(id_metodologia)
    const { metodologia } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state })
      this.props.location.state = "";
    }
    if (metodologia === "undefined") return null
  }


  validate() {
    const { nome, idioma, colunas } = this.state;
    if (!nome) return this.setState({ erro: { message: idioma.cadastrarMetodologia.erro } });
    if (!colunas) return this.setState({ erro: { message: idioma.cadastrarMetodologia.erro_colunas } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  handleEditarMetodologia() {
    const { id_metodologia } = this.props.match.params;
    const { nome, colunas } = this.state;
    if (!this.validate()) return;
    this.setState({ carregando: true })
    this.props.putEditarMetodologia({ id: id_metodologia, nome, colunas }, (msg) => {
      if (msg.erro.error) {
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ success: "" });
        this.setState({ carregando: false });
      } else {
        this.setState({ erro: "" });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
      }
    }
    )
  }

  render() {
    const { carregando, erro, formSuccess, idioma, nome, colunas } = this.state;

    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/configuracoes/metodologias/listar`,
      state: idioma.editarMetodologia.msg_sucesso
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.editarMetodologia.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome', e)} value={nome} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarMetodologia.placeholder} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="colunas">{idioma.cadastrarMetodologia.campo_colunas}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("colunas", ev)} id="colunas" name="colunas" >
                      <option>{idioma.cadastrarMetodologia.colunas_placeholder}</option>
                      <option value='1' selected={colunas === 1} >{idioma.cadastrarMetodologia.coluna_1}</option>
                      <option value='2' selected={colunas === 2}>{idioma.cadastrarMetodologia.coluna_2}</option>
                      <option value='3' selected={colunas === 3}>{idioma.cadastrarMetodologia.coluna_3}</option>
                      <option value='4' selected={colunas === 4}>{idioma.cadastrarMetodologia.coluna_4}</option>
                    </Input>
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleEditarMetodologia()}> <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  metodologia: state.startups.metodologia,
})
export default connect(mapStatetoProps, actions)(EditarMetodolo);