import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfilIncubadora';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Table } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { differenceInDays } from 'date-fns';
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';


import Titulo from '../../componentes/TituloPaginas';


class Usuarios extends Component {
    state = {
        id_incubadora: "",
        currentPage: 1,
        size: 20,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pertence: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        masg_backend: portuguese
    }
    componentDidMount() {
        const { id_incubadora } = this.props.match.params;
        this.setState({ id_incubadora })
        this.listarUsuarios(id_incubadora);
        this.listarIncubadora(id_incubadora);

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    componentDidUpdate(nextProps) {
        const { id_incubadora } = this.props.match.params;
        if (!this.props.usuariosIncubadora && nextProps.usuariosIncubadora) this.listarUsuarios(id_incubadora);
        if (!this.props.incubadora && nextProps.incubadora) {
            this.listarIncubadora(id_incubadora);
        }
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparUsuariosIncubadora();
        this.props.limparIncubadora();
    }
    receberDadosAPI() {
        if (typeof this.props.usuariosIncubadora != "undefined" && this.props.usuariosIncubadora != null && !this.state.dadosApi && this.props.usuariosIncubadora.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.incubadora != "undefined" && this.props.incubadora != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }
    usuarioNovo(data) {
        var result = differenceInDays(
            new Date(),
            data
        )
    }

    listarIncubadora(id_incubadora) {
        this.props.getVerIncubadora(id_incubadora);
        const { incubadora } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadora === "undefined") return null
        if (incubadora) {
            this.verificaIncubadora(this.props.usuario.incubadoras, incubadora)
        }
    }


    listarUsuarios(id_incubadora) {
        const { currentPage, size } = this.state;
        this.props.getUsuariosIncubadora(id_incubadora, currentPage, size)
        const { usuariosIncubadora } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (usuariosIncubadora === "undefined") return null

        if (usuariosIncubadora) {
            this.handleButtons(this.props.usuario.id, usuariosIncubadora.usuarios)
        }
    }
    changePageAtual(currentPage) {
        const { id_incubadora } = this.state;
        this.props.limparUsuarios();
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarUsuarios(id_incubadora);
        })
    }
    apagarUsuario(id_usuario) {
        this.props.limparUsuarios();
        const { id_incubadora } = this.state
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarUsuarioIncubadora(id_incubadora, id_usuario, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarUsuarios(id_incubadora);
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }
    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.modal_titulo,
            text: idioma.usuarios.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.usuarios.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.apagarUsuario(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.usuarios.modal_sucesso
        })
    }


    verificaIncubadora(incubadoras, incubadora) {
        for (let i in incubadoras) {
            if (incubadoras[i].id_incubadora == incubadora.id) {
                this.setState({ pertence: true })
            }
        }
    }
    handleButtons(id_usuario, usuarios) {
        for (let u in usuarios) {
            if (usuarios[u].id == id_usuario) {
                this.setState({ pertence: true })
            }
        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu
    }

    render() {
        const { id_incubadora, msg, carregando, erro, success, dadosApi, pertence, idioma } = this.state;

        var temPaginaAnterior = false;
        if (typeof this.props.usuariosIncubadora != "undefined" && this.props.usuariosIncubadora != null && this.props.usuariosIncubadora.currentPage !== "" && this.props.usuariosIncubadora.currentPage !== 1) {
            temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (typeof this.props.usuariosIncubadora != "undefined" && this.props.usuariosIncubadora !== null && this.props.usuariosIncubadora.nextPage <= this.props.usuariosIncubadora.totalPages && this.props.usuariosIncubadora.nextPage != null) {
            proximaPagina = true;
            temProximaPagina = true;
        }
        var usuariosIncubadora = [];
        if (this.props.usuariosIncubadora) usuariosIncubadora = this.props.usuariosIncubadora.usuarios;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var incubadora = null;
        if (this.props.incubadora) incubadora = this.props.incubadora;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path 
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.usuarios.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {incubadora != null && usuario != null ?
                        <BlocoPerfil dadosApi={dadosApi} incubadora={incubadora} credenciais={credenciais} usuario={usuario} path={path} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto="Voltar" classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            {credenciais.map(credencial => ((credencial.id_componente == 39) ?
                                pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadora/" + id_incubadora + "/usuarios/cadastrar"} className="pr-2" key={credencial.id_componente}>
                                        <Botao texto={idioma.cadastrar.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                        {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}` }}>
                                            {idioma.cadastrar.bt_cadastrar}
                                        </button> */}
                                    </Link>
                                    : ""
                                : ""))}

                            {credenciais.map(credencial => ((credencial.id_componente == 40) ?
                                pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadora/" + id_incubadora + "/usuarios/vincular"} key={credencial.id_componente}>
                                        <Botao texto={idioma.usuarios.bt_vincular} classes="botao-responsivo" icone="link" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                        {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}` }}>
                                            {idioma.usuarios.bt_vincular}
                                        </button> */}
                                    </Link>
                                    : ""
                                : ""))}

                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    {dadosApi ?
                                        <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th >{idioma.usuarios.nome}</th>
                                                        <th >{idioma.usuarios.perfil}</th>
                                                        <th>{idioma.usuarios.acao}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {usuariosIncubadora.map(usuario => (
                                                        <tr key={usuario.id}>

                                                            <td>{usuario.nome}</td>
                                                            <td>{usuario.perfil != null ? usuario.perfil.nome : idioma.usuarios.erro_perfil}</td>
                                                            <td>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/chat/" + usuario.id} className="mr-2" >
                                                                    <button className="btn btn-outline-info btn-sm botao-responsivo"><FontAwesomeIcon icon="comment" className="mr-1" />{idioma.usuarios.conversar}</button>
                                                                </Link>

                                                                {credenciais.map(credencial => ((credencial.id_componente == 15) ?
                                                                    pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/editar/" + usuario.id} className="mr-2" key={credencial.id_componente}>
                                                                            <button className="btn btn-outline-warning btn-sm botao-responsivo">{idioma.usuarios.edit}</button>
                                                                        </Link>
                                                                        : ""
                                                                    : ""))}
                                                                {credenciais.map(credencial => ((credencial.id_componente == 16) ?
                                                                    pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                                                        <span onClick={() => this.chamarAlerta(usuario.id)} key={credencial.id_componente}>
                                                                            <Botao loading={carregando} texto={idioma.usuarios.del} classes="botao-responsivo" cor="danger" tamanho="sm" />
                                                                        </span>
                                                                        : ""
                                                                    : ""
                                                                ))}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        : <div className="row table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th >{idioma.usuarios.nome}</th>
                                                        <th>{idioma.usuarios.acao}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Skeleton count={1} width={80} />
                                                        </td>
                                                        <td>
                                                            <Skeleton count={1} width={80} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>}
                                    {/* {dadosApi ?
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >Primeira</span>
                                                </li>

                                                {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.usuariosIncubadora.prevPage ? this.props.usuariosIncubadora.prevPage : 1)}>{this.props.usuariosIncubadora.prevPage ? this.props.usuariosIncubadora.prevPage : ""}</span></li> : ""}

                                                <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.usuariosIncubadora ? this.props.usuariosIncubadora.currentPage : "1"}</span></li>

                                                {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.usuariosIncubadora.nextPage ? this.props.usuariosIncubadora.nextPage : 1)}>{this.props.usuariosIncubadora.nextPage ? this.props.usuariosIncubadora.nextPage : ""}</span></li> : ""}

                                                <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                    <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.usuariosIncubadora ? this.props.usuariosIncubadora.totalPages : 0)}>Última</span>
                                                </li>
                                            </ul>
                                        </nav>
                                        : ""} */}
                                </div>
                            </div>
                        </div >
                    </div >
                </div >
            </>
        )
    }
}
const mapStatetoProps = state => ({
    usuariosIncubadora: state.incubadoras.usuariosIncubadora,
    incubadora: state.incubadoras.incubadora
})
export default connect(mapStatetoProps, actions)(Usuarios);