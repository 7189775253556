import axios from 'axios';
import {
    GET_TRILHAS, GET_TRILHA, GET_MODULOS, GET_MODULO, GET_MATERIAS,
    GET_MATERIA, GET_AULAS, GET_AULA, LIMPAR_MATERIAS, LIMPAR_MODULOS,
    LIMPAR_MATERIA, LIMPAR_AULA, LIMPAR_MODULO, LIMPAR_TRILHA, LIMPAR_TRILHAS,
    GET_TIPO_AULA, GET_ATIVIDADES, GET_ATIVIDADE, GET_QUESTOES, GET_QUESTAO,
    GET_OPCOES, GET_OPCAO, GET_MATRICULAS, GET_CURSOS_ALUNO, GET_FEITO,
    GET_ALUNOS_CURSO, GET_MEUS_CURSOS, GET_FUTUROS_PARTICIPANTES, GET_SEARCH_TODOS, GET_SEARCH,
    GET_SEARCH_CURSOS, GET_SEARCH_MEUS, GET_TRILHAS_PUBLICAS, LIMPAR_QUESTOES, LIMPAR_OPCOES,
    LIMPAR_ALUNOS, LIMPAR_QUESTAO, GET_TODOS_ALUNOS, GET_USUARIOS_INCUBADORA, GET_PARTICIPANTES,
    GET_INCUBADORAS_COMPLETO, GET_STARTUPS_COMPLETO, GET_PERFIS_ACESSO, GET_USUARIOS_PERFIL, GET_TODOS_ALUNOS_CURSO, GET_SEARCH_TODOS_CURSOS, GET_AUTORES, LIMPAR_AUTORES,
    GET_AVALIACAO_TRILHA
} from './types'
import { api } from '../../config';
import { getHeaders, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getTrilhasPublicas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/cursos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_TRILHAS_PUBLICAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getIncubadorasCompleto = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadoras/completo?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_INCUBADORAS_COMPLETO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getStartupsCompleto = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startups/completo?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_STARTUPS_COMPLETO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getPerfisAcesso = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/credenciais/perfis?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_PERFIS_ACESSO, payload: response })
            })
            .catch(errorHandle)
    }
}
export const getUsuariosPerfil = (id_perfil, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/perfil/${id_perfil}/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_USUARIOS_PERFIL, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuariosIncubadora = (id_incubadora, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/incubadora/${id_incubadora}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USUARIOS_INCUBADORA, payload: response })
            })
            .catch(errorHandle)
    }
}


export const getParticipantes = (id_startup, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/startup/${id_startup}/participantes?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_PARTICIPANTES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getAutores = (id_trilha) => {
    return function (dispatch) {
        axios.get(api + `/curso_autor/${id_trilha}/curso`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_AUTORES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getTrilhas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/cursos/todos?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_TRILHAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerTrilha = (id_curso) => {
    return function (dispatch) {
        axios.get(api + `/curso/${id_curso}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_TRILHA, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getSearch = (procura, paginaAtual, limite) => {
    ////console.log(procura)
    return function (dispatch) {
        axios.get(api + `/usuarios/buscar?procura=${procura}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                //console.log(response)
                dispatch({ type: GET_SEARCH, payload: response })
            })
            .catch(errorHandle)
    }
}

export const putTrilhaCapa = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.put(api + `/curso/editar/imagem`, dadosCurso, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putTrilha = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.put(api + `/curso/editar`, dadosCurso, getHeadersPost())
            .then((response) => {
                ////////console.log(response, dadosCurso)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const putModulo = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.put(api + `/curso/modulo/editar`, dadosCurso, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const getModulos = (id_curso, id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/curso/${id_curso}/modulos/usuario/${id_usuario}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_MODULOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getAvaliacaoTrilha = (id_usuario, id_curso) => {
    return function (dispatch) {
        axios.get(api + `/curso_avaliacao/${id_curso}/curso/${id_usuario}/usuario`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_AVALIACAO_TRILHA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerModulo = (id_curso, id_modulo) => {
    return function (dispatch) {
        axios.get(api + `/curso/${id_curso}/modulos/${id_modulo}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_MODULO, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const deletarModulo = (id_modulo, callback) => {
    return function (dispatch) {
        axios.delete(api + `/curso/modulo/deletar/${id_modulo}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getVerMateria = (id_materia) => {
    return function (dispatch) {
        axios.get(api + `/materias/${id_materia}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_MATERIA, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getMaterias = (id_trilha, id_modulo, id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/curso/${id_trilha}/modulos/${id_modulo}/materias/usuario/${id_usuario}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_MATERIAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postMateria = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.post(api + `/materias`, dadosCurso, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postDuplicarTrilha = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.post(api + `/curso/duplicar`, dadosCurso, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putMateria = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.put(api + `/materias/editar`, dadosCurso, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarMateria = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/materia/deletar/${id}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postTrilha = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.post(api + `/curso`, dadosCurso, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postAutor = (dadosMatricula, callback) => {
    ////////console.log(dadosMatricula)
    return function (dispatch) {
        axios.post(api + `/curso_autor`, dadosMatricula, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarTrilha = (id, callback) => {
    return function (dispatch) {
        axios.delete(api + `/curso/${id}/deletar`, getHeaders())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const desativarTrilha = (id_trilha, callback) => {
    return function (dispatch) {
        axios.put(api + `/cursos/${id_trilha}/desativar`, id_trilha, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const ativarTrilha = (id_trilha, callback) => {
    return function (dispatch) {
        axios.put(api + `/cursos/${id_trilha}/ativar`, id_trilha, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getFuturosParticipantes = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_FUTUROS_PARTICIPANTES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postModulo = (dadosCurso, callback) => {
    ////////////console.log(dadosCurso)
    return function (dispatch) {
        axios.post(api + `/curso/modulo`, dadosCurso, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// Aula

export const getAulas = () => {
    return function (dispatch) {
        axios.get(api + `/aulas`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_AULAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getTipoAula = () => {
    return function (dispatch) {
        axios.get(api + `/tipos`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_TIPO_AULA, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerAula = (id_aula) => {
    return function (dispatch) {
        axios.get(api + `/aula/${id_aula}`, getHeaders())
            .then((response) => {
                //////console.log(response)
                dispatch({ type: GET_AULA, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const postAula = (dadosCurso, callback) => {
    ////////////console.log(dadosCurso)
    return function (dispatch) {
        axios.post(api + `/aula`, dadosCurso, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putAula = (dadosCurso, callback) => {
    return function (dispatch) {
        axios.put(api + `/aula/editar`, dadosCurso, getHeaders())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarAula = (id_aula, callback) => {
    return function (dispatch) {
        axios.delete(api + `/aula/${id_aula}`, getHeaders())
            .then((response) => {
                //////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// Atividades

export const getAtividades = () => {
    return function (dispatch) {
        axios.get(api + `/atividades`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_ATIVIDADES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerAtividade = (id_atividade) => {
    return function (dispatch) {
        axios.get(api + `/atividade/${id_atividade}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_ATIVIDADE, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const getValidarFeito = (id_atividade, id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/atividade/${id_atividade}/usuario/${id_usuario}/checar`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_FEITO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postAtividade = (dadosAtividade, callback) => {
    ////////////console.log(dadosAtividade)
    return function (dispatch) {
        axios.post(api + `/atividade`, dadosAtividade, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putAtividade = (dadosAtividade, callback) => {
    return function (dispatch) {
        axios.put(api + `/atividade/editar`, dadosAtividade, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarAtividade = (id_atividade, callback) => {
    return function (dispatch) {
        axios.delete(api + `/atividade/${id_atividade}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}


export const getSearchTodos = (pesquisa, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/cursos/pesquisa?pesquisa=${pesquisa}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_SEARCH_TODOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchMeus = (pesquisa, id_autor, currentPage, size) => {
    return function (dispatch) {
        axios.get(api + `/cursos/autor/${id_autor}/pesquisa?pesquisa=${pesquisa}&page=${currentPage}&size=${size}`, getHeaders())
            .then((response) => {
                ////////console.log(response)

                dispatch({ type: GET_SEARCH_MEUS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchCursos = (pesquisa, id_user, currentPage, size) => {
    return function (dispatch) {
        axios.get(api + `/matricula/usuario/${id_user}/pesquisa?pesquisa=${pesquisa}&page=${currentPage}&size=${size}`, getHeaders())
            .then((response) => {
                ////////console.log(response)

                dispatch({ type: GET_SEARCH_CURSOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getSearchTodosCursos = (pesquisa, currentPage, size) => {
    return function (dispatch) {
        axios.get(api + `/cursos/todos/pesquisa?page=${currentPage}&size=${size}&pesquisa=${pesquisa}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_SEARCH_TODOS_CURSOS, payload: response })
            })
            .catch(errorHandle)
    }
}

// Questões

export const getQuestoes = (id_atividade, id_usuario) => {
    return function (dispatch) {
        axios.get(api + `/atividade/${id_atividade}/questoes/usuario/${id_usuario}`, getHeaders())
            .then((response) => {
                ////console.log(response)
                dispatch({ type: GET_QUESTOES, payload: response })
            })
            .catch(errorHandle)
    }
}
export const getQuestoesLista = (id_atividade) => {
    return function (dispatch) {
        axios.get(api + `/atividade/${id_atividade}/questoes`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_QUESTOES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerQuestao = (id_questao) => {
    return function (dispatch) {
        axios.get(api + `/questao/${id_questao}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_QUESTAO, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const postQuestao = (dadosQuestao, callback) => {
    return function (dispatch) {
        axios.post(api + `/questao`, dadosQuestao, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putQuestao = (dadosQuestao, callback) => {
    return function (dispatch) {
        axios.put(api + `/questao/editar`, dadosQuestao, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarQuestao = (id_questao, callback) => {
    return function (dispatch) {
        axios.delete(api + `/questao/${id_questao}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// Opções

export const getOpcoes = (id_questao) => {
    return function (dispatch) {
        axios.get(api + `/questao/${id_questao}/opcoes`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_OPCOES, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getVerOpcao = (id_opcao) => {
    return function (dispatch) {
        axios.get(api + `/opcao/${id_opcao}`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_OPCAO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postOpcao = (dadosOpcao, callback) => {
    ////////////console.log(dadosOpcao)
    return function (dispatch) {
        axios.post(api + `/opcao`, dadosOpcao, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putOpcao = (dadosOpcao, callback) => {
    return function (dispatch) {
        axios.put(api + `/opcao/editar`, dadosOpcao, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarOpcao = (id_opcao, callback) => {
    return function (dispatch) {
        axios.delete(api + `/opcao/${id_opcao}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// Progresso



export const postProgressoAula = (dadosProgressoAula, callback) => {
    return function (dispatch) {
        axios.post(api + `/progresso/aula`, dadosProgressoAula, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const postProgressoAtividade = (dadosProgressoAtividade, callback) => {
    return function (dispatch) {
        axios.post(api + `/progresso/atividade`, dadosProgressoAtividade, getHeadersPost())
            .then((response) => {
                ////////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarProgressoAula = (id_aula, callback) => {
    return function (dispatch) {
        axios.delete(api + `/progresso/aula/${id_aula}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarProgressoAtividade = (id_atividade, callback) => {
    return function (dispatch) {
        axios.delete(api + `/progresso/atividade/${id_atividade}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// Questao Entrega

export const postQuestaoEntrega = (dadosResposta, callback) => {
    //////////console.log(dadosResposta)
    return function (dispatch) {
        axios.post(api + `/entrega/questao`, dadosResposta, getHeadersPost())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

//cursos criados por mim
export const getMeusCursos = (id_usuario, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/cursos/autor/${id_usuario}?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_MEUS_CURSOS, payload: response })
            })
            .catch(errorHandle)
    }
}

//matriculas
export const getMatriculas = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/matriculas`, getHeaders())
            .then((response) => {
                ////////////console.log(response)
                dispatch({ type: GET_MATRICULAS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getCursosAluno = (id_usuario, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/matricula/usuario/${id_usuario}?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_CURSOS_ALUNO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getAlunosCurso = (id_curso, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/matricula/curso/${id_curso}?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
            
                dispatch({ type: GET_ALUNOS_CURSO, payload: response })
            })
            .catch(errorHandle)
    }
}
export const getTodosAlunos = (id_curso) => {
    return function (dispatch) {
        axios.get(api + `/matricula/curso/${id_curso}/todos`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_TODOS_ALUNOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postMatricula = (dadosMatricula, callback) => {
    ////////console.log(dadosMatricula)
    return function (dispatch) {
        axios.post(api + `/matricula`, dadosMatricula, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putMatricula = (dadosMatricula, callback) => {
    return function (dispatch) {
        axios.put(api + `/matricula/desativar`, dadosMatricula, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}
export const postAvaliacao = (dadosAvaliacao, callback) => {
    ////////console.log(dadosMatricula)
    return function (dispatch) {
        axios.post(api + `/curso_avaliacao`, dadosAvaliacao, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putEditarAvaliacao = (dadosMatricula, callback) => {
    return function (dispatch) {
        axios.put(api + `/curso_avaliacao/alterar`, dadosMatricula, getHeadersPost())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarMatricula = (id_usuario, id_curso, callback) => {
    return function (dispatch) {
        axios.put(api + `/matricula/desativar/curso/${id_curso}/usuario/${id_usuario}`, id_usuario, getHeaders())
            .then((response) => {
                ////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const deletarAutor = (id_autor, callback) => {
    return function (dispatch) {
        axios.delete(api + `/curso_autor/${id_autor}/deletar`, getHeaders())
            .then((response) => {
                callback({ erro: response.data })
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

// Actions de limpar

export const limparMaterias = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MATERIAS })
    }
}

export const limparModulos = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MODULOS })
    }
}

export const limparModulo = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MODULO })
    }
}

export const limparMateria = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_MATERIA })
    }
}

export const limparAula = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_AULA })
    }
}

export const limparTrilha = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_TRILHA })
    }
}

export const limparTrilhas = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_TRILHAS })
    }
}

export const limparQuestoes = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_QUESTOES })
    }
}

export const limparQuestao = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_QUESTAO })
    }
}

export const limparOpcoes = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_OPCOES })
    }
}

export const limparAlunos = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_ALUNOS })
    }
}

export const limparAutores = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_AUTORES })
    }
}