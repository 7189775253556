import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import BlocoPerfil from '../../containers/blocos/perfilUsuario';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginausuario_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class Editar extends Component {
    state = {
        id: "",
        nome: "",
        email: "",
        id_perfil: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        const { usuario } = this.props;
        this.props.getVerUsuario(id);
        this.props.getPerfis();

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    async componentDidUpdate(nextProps) {
        const { id } = this.props.match.params;
        if (!this.props.usuarioDetalhes && nextProps.usuarioDetalhes) this.props.getVerUsuario(id);
        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparUsuario()
    }

    receberDadosAPI() {
        const { id } = this.props.match.params;
        if (typeof this.props.usuarioDetalhes !== "undefined" && this.props.usuarioDetalhes !== null && this.props.usuarioDetalhes.id == id && !this.state.dadosAPI) {
            this.setState({ id: this.props.usuarioDetalhes.id })
            this.setState({ nome: this.props.usuarioDetalhes.nome })
            this.setState({ email: this.props.usuarioDetalhes.email })
            this.setState({ id_perfil: this.props.usuarioDetalhes.id_perfil })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }
    async atualizarUsuario() {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        this.setState({ carregando: true })
        const { id, nome, email, id_perfil } = this.state;
        ////////console.log(id_perfil)
        this.props.putUsuario({ id, nome, email, id_perfil }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })
    }
    receberDadosForm() {
        this.setState({ id: document.querySelector("#id").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ email: document.querySelector("#email").value })
        this.setState({ id_perfil: document.querySelector("#id_perfil").value })
    }
    validate() {
        const { nome, email, erro, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.pagina_editar.erro_nome } });
        if (!email) return this.setState({ erro: { message: idioma.pagina_editar.erro_email } });
        if (!validator.isEmail(email)) return this.setState({ erro: { message: idioma.pagina_editar.erro_nome } });
        return true;
    }
    render() {
        const { id, nome, email, carregando, dadosAPI, erro, success, formSuccess, id_perfil, idioma } = this.state;
        var perfis = [];
        if (this.props.perfis) perfis = this.props.perfis.dados;
        var usuarioDetalhes = null;
        if (this.props.usuarioDetalhes) usuarioDetalhes = this.props.usuarioDetalhes;
        var path = this.props.match.path
        var usuario = []
        if (this.props.usuario) usuario = this.props.usuario;
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/usuarios/ver/" + id,
            state: { msg: idioma.pagina_editar.info_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.pagina_editar.titulo} />
                </div>
                {usuarioDetalhes != null ?
                    <BlocoPerfil usuarioDetalhes={usuarioDetalhes} dadosApi={dadosAPI} path={path} credenciais={this.props.credenciais} history={this.props.history} />
                    :
                    <div className="list-group-item bloco-card" >
                        <div className="row">
                            <div className="col-md-4" >
                                <div className="img-perfil">
                                    <Skeleton circle={true} height={120} width={120} />
                                </div>
                            </div>
                            <div className="col-md-4 ">
                                <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                            </div>
                        </div>
                    </div>
                }

                <div className="list-group-item bloco-card">
                    <AlertaErro erros={erro} />
                    <AlertaSuccess erros={success} />
                    <Form>
                        <Input type="hidden" value={id} id="id" name="id" />
                        <FormGroup>
                            <Label for="nome">{idioma.pagina_editar.nome}</Label>
                            <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.pagina_editar.nome : idioma.pagina_editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="nome">{idioma.pagina_editar.email}</Label>
                            <Input type="text" value={email} id="email" name="email" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.pagina_editar.email : idioma.pagina_editar.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("email", ev)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="perfil">{idioma.pagina_editar.perfil}</Label>
                            <Input type="select" onChange={(ev) => this.onChangeInput("id_perfil", ev)} id="id_perfil" name="id_perfil" placeholder={dadosAPI ? idioma.pagina_editar.perfil : idioma.pagina_editar.carregando} disabled={dadosAPI ? false : true}>

                                {perfis.map(perfil => (
                                    <option value={perfil.id} selected={perfil.id == id_perfil ? true : false}>
                                        {perfil.nome}
                                    </option>))}
                            </Input>
                        </FormGroup>
                        <Link onClick={() => this.atualizarUsuario()} to="#">
                            <Botao loading={carregando} texto={idioma.pagina_editar.bt_salvar} cor='success' />
                        </Link>
                    </Form>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    perfis: state.usuarios.perfis

})
export default connect(mapStateToProps, actions)(Editar)