import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Row, Col, Card, CardBody, UncontrolledCollapse } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class EditarMateria extends Component {
    state = {
        nome: "",
        duracao: "",
        id_materia: "",
        id_aula: "",
        id_trilha: "",
        id_curso: "",
        ordem: "",
        data_publicacao: "",
        descricao: "",
        conteudo: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha, id_modulo, id_materia } = this.props.match.params;
        this.setState({ id_trilha })
        this.props.getVerMateria(id_materia);
        this.listarTrilha(id_trilha)


        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    async componentDidUpdate(nextProps) {
        const { id_trilha, id_modulo, id_materia } = this.props.match.params;
        if (!this.props.materia && nextProps.materia) {
            this.props.getVerMateria(id_materia);
            this.listarTrilha(id_trilha)
        }

        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparMateria()
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    receberDadosAPI() {
        const { id_materia } = this.props.match.params;
        if (typeof this.props.materia !== "undefined" && this.props.materia !== null && this.props.materia.id == id_materia && !this.state.dadosAPI) {
            this.setState({ id_materia: this.props.materia.id })
            this.setState({ nome: this.props.materia.nome })
            this.setState({ ordem: this.props.materia.ordem })
            this.setState({ descricao: this.props.materia.descricao })
            this.setState({ data_publicacao: this.props.materia.data_publicacao })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }
    async atualizarMateria(id_materia, nome, ordem, descricao, data_publicacao) {
        var id = id_materia
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        // if (!this.validate()) return;
        this.setState({ carregando: true })
        ////////////console.log(id, nome, ordem, descricao)
        this.props.putMateria({ id, nome, ordem, descricao, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    receberDadosForm() {
        this.setState({ id: document.querySelector("#id_materia").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ ordem: document.querySelector("#ordem").value })
        this.setState({ descricao: document.querySelector("#descricao").value })
    }
    // validate() {
    //     const { nome, ordem, descricao, erro } = this.state;
    //     if (!nome) return this.setState({ erro: { message: "Preencha o nome" } });
    //     if (!ordem) return this.setState({ erro: { message: "Preencha uma ordem" } });
    //     if (!descricao) return this.setState({ erro: { message: "Preencha uma descrição" } });
    //     return true;
    // }

    //deletar trilha
    deletandoMateria(id) {
        this.setState({ carregando: true })
        this.props.deletarMateria(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_materia) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.editarMateria.modal_titulo,
            html: idioma.editarMateria.modal_info,
            input: 'text',
            inputPlaceholder: idioma.editarAtividade.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.editarAtividade.confirm) {
                this.deletandoMateria(id_materia);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.editarAtividade.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarMateria.modal_sucesso
        })
    }
    render() {
        const { id_materia, id_trilha, id_aula, nome, ordem, data_publicacao, descricao, conteudo, duracao, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;

        var materias = [];
        if (this.props.materias) materias = this.props.materias.dados;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path

        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/",
            state: { msg: "Edição realizada com sucesso!" }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo='Editar matéria' />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosAPI} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>

                            <Form>

                                <Row form>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.editarAtividade.nome}</Label>
                                            <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.editarAtividade.nome_mate : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="data_publicacao">{idioma.editarAtividade.data_pub}</Label>
                                            <Input type="date" value={data_publicacao} id="data_publicacao" name="data_publicacao" className="form-control" autoComplete="data_publicacao" placeholder={dadosAPI ? idioma.editarAtividade.data_pub : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("data_publicacao", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="Ordem">{idioma.editarAtividade.sel_ordem}</Label>
                                        <Input type="number" value={ordem} id="ordem" name="ordem" className="form-control" autoComplete="ordem" placeholder={dadosAPI ? idioma.editarMateria.ordem_mat : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("ordem", ev)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descricao">{idioma.editarMateria.desc_materia}</Label>
                                            <Input type="textarea" value={descricao} id="descricao" name="descricao" className="form-control" autoComplete="descricao" placeholder={dadosAPI ? idioma.editarMateria.desc_materia : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("descricao", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <Input type="hidden" value={id_materia} id="id_materia" name="id_materia" />
                                    </Col>
                                </Row>
                                <Link onClick={() => this.atualizarMateria(id_materia, nome, ordem, descricao)} to="#">
                                    <Botao loading={carregando} texto={idioma.bt_salvar} classes="botao-responsivo botao-estilo" />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                {credenciais.map(credencial => ((credencial.id_componente == 63) ?

                                    <Link to="#" onClick={() => this.chamarAlertaDeletar(id_materia)}>
                                        <Botao loading={carregando} texto={idioma.editarMateria.bt_deletar} classes="btn-danger btn-md ml-1" icone="trash-alt" />
                                    </Link>
                                    : ""))}
                            </Form>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    materia: state.trilhas.materia,
    trilha: state.trilhas.trilha


})
export default connect(mapStateToProps, actions)(EditarMateria)