import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/eventos';
import BlocoPerfil from '../../containers/blocos/perfilEvento';
import parse from 'html-react-parser'
import Skeleton from "react-loading-skeleton";
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import { apiImagem } from '../../config/';

import { ingles, portugues } from '../../utils/paginaeventos_bilingue';

import Titulo from '../../componentes/TituloPaginas';


class VerEvento extends Component {
    state = {
        id_evento: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues
    }
    componentDidMount() {
        const { id_evento } = this.props.match.params;
        this.setState({ id_evento })
        this.listarEvento(id_evento)

        const { usuario } = this.props

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
        } else {
            this.setState({ idioma: portugues })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_evento } = this.props.match.params;
        if (!this.props.evento && nextProps.evento) {
            this.listarEvento(id_evento);
        }
        this.receberDadosAPI();


    }

    componentWillUnmount() {
        this.props.limparEvento();
    }

    receberDadosAPI() {
        if (typeof this.props.evento != "undefined" && this.props.evento != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    listarEvento(id_evento) {
        this.props.getVerEvento(id_evento);
        const { evento } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (evento === "undefined") return null
    }




    render() {
        const { msg, carregando, erro, success, dadosApi, id_evento, idioma } = this.state;
        var evento = null;
        if (this.props.evento) evento = this.props.evento;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.ver.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {evento != null ?
                        <BlocoPerfil evento={evento} dadosApi={dadosApi} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" >
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className=""><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.ver.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            {dadosApi ?
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <img className="card-imagem-news img-news  rounded mx-auto d-block" src={evento.fileName ? apiImagem + evento.fileName : apiImagem + "fundo_startup.jpg"} alt="Card image cap" />

                                        {parse("" + evento.descricao)}

                                    </div>
                                </div>
                                :
                                <div className="row">
                                    <div className="col-md-12 mb-2 text-center">
                                        <Skeleton rectangle={true} height={100} width={100} />
                                        <div>
                                            <div className="text-muted text-justify mt-3">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    evento: state.eventos.evento,
})
export default connect(mapStatetoProps, actions)(VerEvento);