import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'

import { env, apiImagem } from '../../config/';
import { portugues, ingles } from '../../utils/paginaperfil_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class EditarFotoPerfil extends Component {
    state = {
        id: "",
        file: null,
        erro: "",
        success: "",
        carregando: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.files[0] });

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async atualizarUsuario() {
        this.setState({ success: "" });
        this.setState({ erro: "" });

        if (!this.validate()) return;

        this.setState({ carregando: true })
        const formData = new FormData();
        formData.append('file', this.state.file);

        await this.props.putPerfilFoto(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.atualizarUser();
            }
        });
    }

    validate() {
        const { file, idioma } = this.state;
        if (!file) return this.setState({ erro: { message: idioma.editar_foto.info } });
        return true;
    }
    async atualizarUser() {
        await this.props.verPerfil(() => {
            this.setState({ carregando: false });
            this.setState({ formSuccess: true });
        })
    }
    render() {
        const { file, carregando, erro, formSuccess, idioma } = this.state;
        if (formSuccess) {
            return <Redirect to={{
                pathname: "/" + this.props.usuario?.personalizacao?.titulo + '/perfil',
                state: { msg: idioma.editar_foto.sucesso }
            }} />
        }
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editar_foto.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <Form>
                                <FormGroup>
                                    {file ? <img src={URL.createObjectURL(file)} alt={this.props.usuario.nome} height="150" width="150" className="rounded-circle" /> : this.props.usuario ? <img src={apiImagem + this.props.usuario.fileName} alt={this.props.usuario.nome} height="150" width="150" className="rounded-circle" /> : iconeUsuario}
                                </FormGroup>
                                <FormGroup>
                                    <Label for="file">{idioma.editar_foto.label}</Label>
                                    <p style={{ fontSize: '12px' }}>{idioma.editar_foto.recomendado}</p>
                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInput("file", ev)} />
                                </FormGroup>
                                <Link onClick={() => this.atualizarUsuario()} to="#">
                                    <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' classes="botao-responsivo botao-estilo" />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.bt_cancelar} classes="botao-responsivo botao-estilo" icone="times" posicao="E" cor="danger" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(EditarFotoPerfil);