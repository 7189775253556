import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ReactLoadingSkeleton = () => (
  <div className="col-md-12 mb-2">
    <div className="list-group-item">
      <div className="row">
        <div className="col-auto centralizado" >
          <div className="img-perfil">
            <Skeleton rectangle={true} height={120} width={120} />
          </div>
        </div>
        <div className="col-md-8 centralizado texto-centralizado">
          <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
        </div>
      </div>
    </div>
  </div>
);

export default ReactLoadingSkeleton;