export const portugues = {
  modal_titulo: 'Você tem certeza?',
  bt_cancelar: 'Cancelar',
  bt_salvar: 'Sim, desativar esse negócio!',
  modal_sucesso: 'Negócio desativado com sucesso!',
  matr_sucesso: 'Matrícula realizada com sucesso!',
  inscreva: 'Inscreva-se',
  aprender: 'O que você vai aprender?',
  conteudo: 'Conteúdo',
  partici: 'Participantes',
  config: 'Configurações',
  inscrito: 'Inscrito',
  avaliar: 'Avaliar',
  bt_voltar: 'Voltar',
  bt_inscrever: 'Inscrever-se',
  msg_inscricao: 'Você ainda não está inscrito nesta trilha, deseja se inscrever?'
}

export const ingles = {
  modal_titulo: 'Are you sure?',
  bt_cancelar: 'Cancel',
  bt_salvar: 'Yes, disable this business!',
  modal_sucesso: 'Business successfully disabled!',
  matr_sucesso: 'Enrollment successfully completed!',
  inscreva: 'Sign up',
  aprender: 'What will you learn?',
  conteudo: 'Content',
  partici: 'Participants',
  config: 'Settings',
  inscrito: 'Registered',
  avaliar: 'Rate',
  bt_voltar: 'Back',
  bt_inscrever: 'Sign up',
  msg_inscricao: 'You are not registered on this track yet, do you want to register?'
}