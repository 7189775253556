export const portugues = {
  dashboard: 'Dashboard',
  negocios: 'Meus negócios',
  ambiente_inovacao: 'Meu ambiente de inovação',
  gestao_negocios: 'Gestão de negócios',
  ecossistema: 'Ecossistema',
  trilhas: 'Trilhas',
  eventos: 'Eventos',
  conteudos: 'Conteúdos',
  hub_inovacao: 'Hub de inovação',
  relatorios: 'Relatórios',
  chat: 'Chat',
  beneficios: 'Benefícios',
  config: 'Configurações',
  sair: 'Sair',
  sair_modal: {
    info: 'Tem certeza que deseja sair?',
    bt1: 'Sim',
  }
}

export const ingles = {
  dashboard: 'Dashboard',
  negocios: 'My business',
  ambiente_inovacao: 'Innovation space',
  gestao_negocios: 'Business management',
  ecossistema: 'Ecosystem',
  trilhas: 'Training',
  eventos: 'Events',
  conteudos: 'Contents',
  hub_inovacao: 'Innovation hub',
  relatorios: 'Reports',
  chat: 'Chat',
  beneficios: 'Benefits',
  config: 'Settings',
  sair: 'Exit',
  sair_modal: {
    info: 'Are you sure you want to quit?',
    bt1: 'Yes',
  }
}