import React from 'react';
import Swal from 'sweetalert2';

// FIXME Corrigir o componente que está gerando erro ao ser chamado
const Toast = (props) => {
    //if (props.erros === "") return null;
    //////////////console.log(props.erros)
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    return (
        Toast.fire({
            icon: props.icon,
            title: props.title,
            text: props.text
        })
    )
}

export default Toast;