import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfilIncubadora';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import Swal from 'sweetalert2';
import Skeleton from "react-loading-skeleton";
import SemDados from '../../componentes/Mensagens/semDados';

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class EditarIncubadora extends Component {
    state = {
        id_incubadora: "",
        erro: "",
        success: "",
        file: null,
        carregando: false,
        pertence: false,
        idioma: portugues,
        dadosAPI: false,
        formSuccess: false,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_incubadora } = this.props.match.params;
        this.props.getVerIncubadora(id_incubadora);
        if (this.props.usuario && this.props.incubadora && this.props.credenciais) { this.verificaIncubadora(this.props.usuario, this.props.credenciais, id_incubadora) };
        this.verificaPertence(this.props.usuario.id, id_incubadora)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    async componentDidUpdate(nextProps) {
        const { id_incubadora } = this.props.match.params;
        if (!this.props.incubadora && nextProps.incubadora) this.props.getVerIncubadora(id_incubadora);
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_incubadora);
        }

        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparIncubadora();
    }

    receberDadosAPI() {
        const { id_incubadora } = this.props.match.params;
        if (typeof this.props.incubadora !== "undefined" && this.props.incubadora !== null && this.props.incubadora.id == id_incubadora && !this.state.dadosAPI) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                this.setState({ id_incubadora: this.props.incubadora.id })
                this.setState({ dadosAPI: true });
            }
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    async atualizarIncubadora(id, file) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id);
        this.setState({ carregando: true })
        this.props.putIncubadoraCapa(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })

    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarCapa.msg_sucesso
        })
    }
    receberDadosForm() {
        this.setState({ id_incubadora: document.querySelector("#id").value })
        this.setState({ file: document.querySelector("#file").value })

    }
    validate() {
        const { file, idioma } = this.state;
        if (!file) return this.setState({ erro: { message: idioma.editarCapa.erro_img } });

        return true;
    }

    verificaIncubadora(usuario, credenciais, id_incubadora) {
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }
        for (let i in usuario.incubadoras) {
            if (usuario.incubadoras[i].id_incubadora == id_incubadora) {
                this.setState({ pertence: true })
            }
        }
    }

    verificaPertence(id_usuario, id_incubadora) {
        this.props.getVerificarPertence(id_usuario, 0, id_incubadora);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { id_incubadora, pertence, file, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;

        var incubadora = null;
        if (this.props.incubadora) incubadora = this.props.incubadora;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = null;
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        if (formSuccess) return <Redirect to={{
            pathname: "/ecossistema/incubadoras/" + id_incubadora,
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editarCapa.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {incubadora != null && usuarioPertence.pertence && usuario != null ?
                        <BlocoPerfil dadosApi={dadosAPI} incubadora={incubadora} credenciais={credenciais} usuario={usuario} path={path} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>

                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            {usuarioPertence.pertence ?

                                <Form className="pt-4">
                                    <Input type="hidden" value={id_incubadora} id="id" name="id" />
                                    <FormGroup>
                                        <Label for="file">{idioma.editarCapa.foto_capa}</Label>
                                        <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                    </FormGroup>
                                    <Link onClick={() => this.atualizarIncubadora(id_incubadora, file)} to="#">
                                        <Botao loading={carregando} classes="botao-responsivo botao-estilo" texto={idioma.editar.bt_salvar} />
                                    </Link>
                                    <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                </Form>
                                : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.cadastrarStartup.erro_acesso} />}

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    incubadora: state.incubadoras.incubadora,
    pertence: state.incubadoras.pertence


})
export default connect(mapStateToProps, actions)(EditarIncubadora)