export const portuguese = {
  "E101": "Erro: Dados inválidos!",
  "E102": "Erro: URL inválida",
  "E103": "Erro: Erro ao buscar os arquivos",
  "E104": "Erro: Termos não aceitos",
  "E105": "Erro: Arquivo inválido",
  "E106": "Erro: Selecione uma imagem válida JPG ou PNG",
  "E107": "Erro: Token inválido!",
  "E108": "Erro: Não foi possível editar a imagem",
  "E109": "Erro: E-mail já cadastrado",
  "E110": "Erro: Usuário não encontrado",
  "E111": "Erro: Usuário/Senha inválidos",
  "E112": "Erro: Usuário sem acesso",
  "E113": "Erro: Usuário não autorizado",
  "E114": "Erro: Usuário não foi alterado",
  "E115": "Erro: Senha não foi alterada",
  "E116": "Erro: Não foi possível realizar o cadastro",
  "E117": "Erro: Tipo de aula não encontrado",
  "E118": "Erro: Arquivo não encontrado",
  "E119": "Erro: Arquivo não foi excluído",
  "E120": "Erro: Atividade não encontrada",
  "E121": "Erro: Questão não encontrada",
  "E122": "Erro: Atividade não foi alterada",
  "E123": "Erro: Atividade não foi excluída",
  "E124": "Erro: Atividade-Progresso não encontrada",
  "E125": "Erro: Atividade-Progresso não foi alterada",
  "E126": "Erro: Atividade-Progresso não foi excluída",
  "E127": "Erro: Aula não encontrada",
  "E128": "Erro: Aula não foi alterada",
  "E129": "Erro: Aula não foi excluída",
  "E130": "Erro: Aula-Progresso não encontrada",
  "E131": "Erro: Aula-Progresso não foi alterada",
  "E132": "Erro: Aula-Progresso não foi excluída",
  "E133": "Erro: Tipo de Aula não foi excluído",
  "E134": "Erro: Avaliação não encontrada",
  "E135": "Erro: Avaliação não foi alterada",
  "E136": "Erro: Avaliação não foi excluída",
  "E137": "Erro: Avaliação do gestor não encontrada",
  "E138": "Erro: Avaliação do gestor não foi alterada",
  "E139": "Erro: Avaliação do gestor não foi excluída",
  "E140": "Erro: Não foi possível realizar o vínculo do participante no ambiente de inovação",
  "E141": "Erro: Avaliador não encontrado",
  "E142": "Erro: Avaliador não foi desvinculado",
  "E143": "Erro: Chat já existe",
  "E144": "Erro: Chat não encontrado",
  "E145": "Erro: Chat não foi alterado",
  "E146": "Erro: Chat não foi excluído",
  "E147": "Erro: Componente não encontrado",
  "E148": "Erro: Componente não foi excluído",
  "E149": "Erro: Conteúdo não encontrado",
  "E150": "Erro: Conteúdo não foi alterado",
  "E151": "Erro: Conteúdo não foi excluído",
  "E152": "Erro: Tipo de conteúdo não encontrado",
  "E153": "Erro: Tipo de conteúdo não foi excluído",
  "E154": "Erro: Credencial já existe para esse perfil",
  "E155": "Erro: Credencial não encontrada",
  "E156": "Erro: Credencial não foi excluída",
  "E157": "Erro: Não foi possível inserir o desafio",
  "E158": "Erro: Desafio não encontrado",
  "E159": "Erro: Desafio não foi alterado",
  "E160": "Erro: Desafio não foi excluído",
  "E161": "Erro: Pilar não encontrado",
  "E162": "Erro: Não foi possível inserir a entrega",
  "E163": "Erro: Entrega não encontrada",
  "E164": "Erro: Entrega não foi alterada",
  "E165": "Erro: Entrega não foi excluída",
  "E166": "Erro: Evento não encontrado",
  "E167": "Erro: Evento não foi alterado",
  "E168": "Erro: Evento não foi excluído",
  "E169": "Erro: Não foi possível inserir a evidência",
  "E170": "Erro: Evidência não encontrada",
  "E171": "Erro: Evidência não foi alterada",
  "E172": "Erro: Evidência não foi excluída",
  "E173": "Erro: Idioma não encontrado",
  "E174": "Erro: Idioma não foi excluído",
  "E175": "Erro: Ambiente de inovação vinculado não encontrado",
  "E176": "Erro: Ambiente de inovação não encontrado",
  "E177": "Erro: Ambiente de inovação não foi alterado",
  "E178": "Erro: Ambiente de inovação não foi ativado",
  "E179": "Erro: Ambiente de inovação não foi desativado",
  "E180": "Erro: Ambiente de inovação não foi excluído",
  "E181": "Erro: Matéria não encontrada",
  "E182": "Erro: Matéria não foi alterada",
  "E183": "Erro: Matéria não foi excluída",
  "E184": "Erro: Usuário já está matriculado",
  "E185": "Erro: Não foi possível realizar o vínculo do participante na trilha",
  "E186": "Erro: Participante não encontrado",
  "E187": "Erro: Trilha não encontrada",
  "E188": "Erro: Matrícula não encontrada",
  "E189": "Erro: Matrícula não foi desativada",
  "E190": "Erro: Mensagem não encontrada",
  "E191": "Erro: Mensagem não foi alterada",
  "E192": "Erro: Mensagem não foi excluída",
  "E193": "Erro: Não foi possível inserir a mentoria",
  "E194": "Erro: Mentoria não encontrada",
  "E195": "Erro: Mentoria não foi alterada",
  "E196": "Erro: Mentoria não foi excluída",
  "E197": "Erro: Metodologia não encontrada",
  "E198": "Erro: Radar não foi vinculado",
  "E199": "Erro: Metodologia não foi alterada",
  "E200": "Erro: Metodologia não foi excluída",
  "E201": "Erro: Módulo não encontrado",
  "E202": "Erro: Módulo não foi alterado",
  "E203": "Erro: Módulo não foi excluído",
  "E204": "Erro: Opção não encontrada",
  "E205": "Erro: Opção não foi alterada",
  "E206": "Erro: Opção não foi excluída",
  "E207": "Erro: Perfil não encontrado",
  "E208": "Erro: Não foi possível executar solicitação",
  "E209": "Erro: Perfil não encontrado",
  "E210": "Erro: Perfil não foi alterado",
  "E211": "Erro: Perfil não foi excluído",
  "E212": "Erro: Pilar não encontrado",
  "E213": "Erro: Pilar não foi alterado",
  "E214": "Erro: Pilar não foi excluído",
  "E215": "Erro: Processo não encontrado",
  "E216": "Erro: Processo não foi alterado",
  "E217": "Erro: Processo não foi excluído",
  "E218": "Erro: Questão não encontrada",
  "E219": "Erro: Questão não foi alterada",
  "E220": "Erro: Questão não foi ativada",
  "E221": "Erro: Questão não foi desativada",
  "E222": "Erro: Questão não foi excluída",
  "E223": "Erro: Não foi possível salvar o radar",
  "E224": "Erro: Radar não foi alterado",
  "E225": "Erro: Radar não encontrado",
  "E226": "Erro: Respostas da etapa não encontradas",
  "E227": "Erro: Respostas dos radares não encontradas",
  "E228": "Erro: Resposta não encontrada",
  "E229": "Erro: Radar-Metodologia não foi alterado",
  "E230": "Erro: Radar-Metodologia não encontrado",
  "E231": "Erro: Radar-Metodologia não foi excluído",
  "E232": "Erro: Negócio não encontrado",
  "E233": "Erro: Não há usuarios cadastrados neste ambiente de inovação",
  "E234": "Erro: Desafio não encontrado",
  "E235": "Erro: Não há negócios cadastrados neste ambiente de inovação",
  "E236": "Erro: Solução não encontrada",
  "E237": "Erro: Solução não foi alterada",
  "E238": "Erro: Solução não foi aceita",
  "E239": "Erro: Validação da solução não foi cancelada",
  "E240": "Erro: Solução não foi excluída",
  "E241": "Erro: Não foi possível realizar o vínculo do participante no negócio",
  "E243": "Erro: Negócio não encontrado",
  "E244": "Erro: Não há negócios vinculados",
  "E245": "Erro: Negócio não foi alterado",
  "E246": "Erro: Negócio não foi ativado",
  "E247": "Erro: Negócio não foi desativado",
  "E248": "Erro: Negócio não foi excluído",
  "E249": "Erro: Etapa não encontrada",
  "E250": "Erro: Vínculo não encontrado",
  "E251": "Erro: Vínculo Negócio-Metodologia não foi excluído",
  "E252": "Erro: Status não encontrado",
  "E253": "Erro: Status não foi excluído",
  "E254": "Erro: Vínculo Trilha-Autor não encontrado",
  "E255": "Erro: Vínculo Trilha-Autor não foi alterado",
  "E256": "Erro: Vínculo Trilha-Autor não foi ativado",
  "E257": "Erro: Vínculo Trilha-Autor não foi desativado",
  "E258": "Erro: Vínculo Trilha-Autor não foi excluído",
  "E259": "Erro: Não foi possível duplicar a trilha",
  "E260": "Erro: Não foi possível duplicar o módulo",
  "E261": "Erro: Não foi possível duplicar o matéria",
  "E262": "Erro: Não foi possível duplicar a aula",
  "E263": "Erro: Não foi possível duplicar a atividade",
  "E264": "Erro: Não foi possível duplicar a questao",
  "E265": "Erro: Não foi possível duplicar a opção",
  "E266": "Erro: Trilha não encontrada",
  "E267": "Erro: Trilha não foi alterada",
  "E268": "Erro: Trilha não foi ativada",
  "E269": "Erro: Trilha não foi desativada",
  "E270": "Erro: Trilha não foi excluída",
  "E271": "Erro: Não foi possível vincular o perfil no cadastro",
  "E272": "Erro: Negócio do usuário não encontrado",
  "E273": "Erro: Ambiente de inovação do usuário não encontrado",
  "E274": "Erro: Usuário não foi alterado",
  "E275": "Erro: Usuário não foi ativado",
  "E276": "Erro: Usuário não foi desativado",
  "E277": "Erro: Usuário não foi excluído",
  "E278": "Erro: Participante não foi desvinculado",
  "E279": "Erro: Não foi possível enviar a solicitação",
  "E280": "Erro: Radar não foi desvinculado",
  "E281": "Erro: Entrega da questão não encontrada",
  "E282": "Erro: Entrega da questão não foi alterada",
  "E283": "Erro: Entrega da questão não foi excluída",
  "E284": "Erro: Vínculo Negócio-Metodologia não encontrado",
  "E285": "Erro: Metodologia não foi desativada",
  "E286": "Erro: Metodologia não foi ativada",
  "E287": "Erro: Processo não foi desativado",
  "E288": "Erro: Processo não foi ativado",
  "E290": "Erro: Etapa não foi excluída",
  "E291": "Erro: Etapa não foi desativada",
  "E292": "Erro: Etapa não foi ativada",
  "E293": "Erro: Etapa não foi alterada",
  "E294": "Erro: Estagio não encontrado",
  "E295": "Erro: Estagio não foi alterado",
  "E296": "Erro: Estagio não foi desativado",
  "E297": "Erro: Estagio não foi ativado",
  "E298": "Erro: Estagio não foi excluído",
  "E299": "Erro: Entrega não foi desativada",
  "E300": "Erro: Entrega não foi ativada",
  "E301": "Erro: usuário já avaliou a trilha!",
  "E302": "Erro: Avaliação de trilha não encontrada",
  "E303": "Erro: Avaliação de trilha não foi alterada",
  "E304": "Erro: Avaliação de trilha não foi excluída",
  "E305": "Erro: Arquivo de atividade não encontrado",
  "E306": "Erro: Arquivo de atividade não foi excluído",
  "E307": "Erro: Não foi possível realizar o vínculo de ambiente de inovação e jornada",
  "E308": "Erro: Vínculo de ambiente de inovação e jornada não encontrado",
  "E309": "Erro: Vínculo de ambiente de inovação e jornada não alterado",
  "E310": "Erro: Vínculo de ambiente de inovação e jornada não desativado",
  "E311": "Erro: Vínculo de ambiente de inovação e jornada não ativado",
  "E312": "Erro: Vínculo de ambiente de inovação e jornada não excluído",
  "E313": "Erro: Não foi possível realizar o vínculo de ambiente de inovação e radar",
  "E314": "Erro: Vínculo de ambiente de inovação e radar não encontrado",
  "E315": "Erro: Vínculo de ambiente de inovação e radar não alterado",
  "E316": "Erro: Vínculo de ambiente de inovação e radar não desativado",
  "E317": "Erro: Vínculo de ambiente de inovação e radar não ativado",
  "E318": "Erro: Vínculo de ambiente de inovação e radar não excluído",
  "E319": "Erro: Vínculo já existente!",

  "S101": "Enviamos no seu e-mail as instruções para recuperar senha, verifique na caixa de entrada",
  "S102": "Senha editada com sucesso!",
  "S103": "Arquivo cadastrado com sucesso",
  "S104": "Arquivo excluído com sucesso",
  "S105": "Atividade cadastrada com sucesso",
  "S106": "Atividade alterada com sucesso",
  "S107": "Atividade excluída com sucesso",
  "S108": "Progresso da Atividade cadastrado com sucesso",
  "S109": "Atividade já vista anteriormente",
  "S110": "Progresso da Atividade alterado com sucesso",
  "S111": "Progresso da Atividade excluído com sucesso",
  "S112": "Aula cadastrada com sucesso",
  "S113": "Aula alterada com sucesso",
  "S114": "Aula excluída com sucesso",
  "S115": "Progresso da Aula cadastrado com sucesso",
  "S116": "Aula já vista anteriormente",
  "S117": "Progresso da Aula alterado com sucesso",
  "S118": "Progresso da Aula excluído com sucesso",
  "S119": "Tipo de aula cadastrado com sucesso",
  "S120": "Tipo de Aula alterado com sucesso",
  "S121": "Tipo de aula excluído com sucesso",
  "S122": "Avaliação cadastrada com sucesso",
  "S123": "Avaliação alterada com sucesso",
  "S124": "Avaliação excluída com sucesso",
  "S125": "Avaliação do gestor cadastrada com sucesso",
  "S126": "Avaliação do gestor alterada com sucesso",
  "S127": "Avaliação do gestor excluída com sucesso",
  "S128": "Avaliador vinculado com sucesso",
  "S129": "Avaliador cadastrado com sucesso",
  "S130": "Termo enviado com sucesso",
  "S131": "Avaliador desvinculado com sucesso",
  "S132": "Chat cadastrado com sucesso",
  "S133": "Chat alterado com sucesso",
  "S134": "Chat excluído com sucesso",
  "S135": "Componente cadastrado com sucesso",
  "S136": "Componente excluído com sucesso",
  "S137": "Conteúdo cadastrado com sucesso",
  "S138": "Conteúdo alterado com sucesso",
  "S139": "Conteúdo excluído com sucesso",
  "S140": "Tipo de conteúdo cadastrado com sucesso",
  "S141": "Tipo de conteúdo alterado com sucesso",
  "S142": "Tipo de conteúdo excluído com sucesso",
  "S143": "Credencial cadastrada com sucesso",
  "S144": "Credencial excluída com sucesso",
  "S145": "Desafio inserido com sucesso",
  "S146": "Desafio alterado com sucesso",
  "S147": "Desafio excluído com sucesso",
  "S148": "Entrega inserida com sucesso",
  "S149": "Entrega alterada com sucesso",
  "S150": "Entrega excluída com sucesso",
  "S151": "Evento cadastrado com sucesso",
  "S152": "Evento alterado com sucesso",
  "S153": "Evento excluído com sucesso",
  "S154": "Evidência inserida com sucesso",
  "S155": "Evidência alterada com sucesso",
  "S156": "Evidência excluída com sucesso",
  "S157": "Idioma cadastrado com sucesso",
  "S158": "Idioma excluído com sucesso",
  "S159": "Ambiente de inovação cadastrado com sucesso",
  "S160": "Ambiente de inovação alterado com sucesso",
  "S161": "Ambiente de inovação ativado com sucesso",
  "S162": "Ambiente de inovação desativado com sucesso",
  "S163": "Ambiente de inovação excluído com sucesso",
  "S164": "Matéria cadastrada com sucesso",
  "S165": "Matéria alterada com sucesso",
  "S166": "Matéria excluída com sucesso",
  "S167": "Usuário matriculado com sucesso",
  "S168": "Matrícula desativada com sucesso",
  "S169": "Mensagem cadastrada com sucesso",
  "S170": "Mensagem alterada com sucesso",
  "S171": "Mensagem excluída com sucesso",
  "S172": "Mentoria inserida com sucesso",
  "S173": "Mentoria alterada com sucesso",
  "S174": "Mentoria excluída com sucesso",
  "S175": "Metodologia cadastrada com sucesso",
  "S176": "Radar vinculado com sucesso",
  "S177": "Radar desvinculado com sucesso",
  "S178": "Metodologia alterada com sucesso",
  "S179": "Metodologia excluída com sucesso",
  "S180": "Módulo cadastrado com sucesso",
  "S181": "Módulo alterado com sucesso",
  "S182": "Módulo excluído com sucesso",
  "S183": "Opção cadastrada com sucesso",
  "S184": "Opção alterada com sucesso",
  "S185": "Opção excluída com sucesso",
  "S187": "Perfil alterado com sucesso",
  "S188": "Perfil cadastrado com sucesso",
  "S190": "Perfil excluído com sucesso",
  "S191": "Pilar cadastrado com sucesso",
  "S192": "Pilar alterado com sucesso",
  "S193": "Pilar excluído com sucesso",
  "S194": "Processo cadastrado com sucesso",
  "S195": "Processo alterado com sucesso",
  "S196": "Processo excluída com sucesso",
  "S197": "Questão cadastrada com sucesso",
  "S198": "Questão alterada com sucesso",
  "S199": "Questão ativada com sucesso",
  "S200": "Questão desativada com sucesso",
  "S201": "Questão excluída com sucesso",
  "S202": "Entrega da questão cadastrada com sucesso",
  "S203": "Entrega da questão alterada com sucesso",
  "S204": "Entrega da questão excluída com sucesso",
  "S205": "Radar salvo com sucesso",
  "S206": "Radar alterado com sucesso",
  "S207": "Radar editado com sucesso",
  "S208": "Radar-Metodologia cadastrado com sucesso",
  "S209": "Radar-Metodologia alterado com sucesso",
  "S210": "Radar-Metodologia excluído com sucesso",
  "S211": "Solução inserida com sucesso",
  "S212": "Solução alterada com sucesso",
  "S213": "Solução aceita com sucesso",
  "S214": "Cancelamento da validação realizado com sucesso",
  "S215": "Solução excluída com sucesso",
  "S216": "Usuário cadastrado com sucesso",
  "S217": "Usuário vinculado com sucesso",
  "S218": "Negócio alterado com sucesso",
  "S219": "Negócio ativado com sucesso",
  "S220": "Negócio desativado com sucesso",
  "S221": "Negócio excluído com sucesso",
  "S222": "Vínculo Negócio-Metodologia realizado com sucesso",
  "S223": "Acesso autorizado",
  "S224": "Vínculo Negócio-Metodologia excluído com sucesso",
  "S225": "Status cadastrado com sucesso",
  "S226": "Status excluído com sucesso",
  "S227": "Vínculo Trilha-Autor cadastrado com sucesso",
  "S228": "Vínculo Trilha-Autor alterado com sucesso",
  "S229": "Vínculo Trilha-Autor ativado com sucesso",
  "S230": "Vínculo Trilha-Autor desativado com sucesso",
  "S231": "Vínculo Trilha-Autor excluído com sucesso",
  "S232": "Trilha cadastrada com sucesso",
  "S233": "Trilha alterada com sucesso",
  "S234": "Trilha ativada com sucesso",
  "S235": "Trilha desativada com sucesso",
  "S236": "Trilha excluída com sucesso",
  "S237": "Usuário cadastrado com sucesso e perfil vinculado",
  "S238": "Usuário alterado com sucesso",
  "S239": "Usuário ativado com sucesso",
  "S240": "Usuário desativado com sucesso",
  "S241": "Usuário excluído com sucesso",
  "S242": "Participante desvinculado com sucesso",
  "S243": "Metodologia desativada com sucesso",
  "S244": "Metodologia ativada com sucesso",
  "S245": "Processo desativado com sucesso",
  "S246": "Processo ativado com sucesso",
  "S247": "Etapa cadastrada com sucesso",
  "S248": "Etapa excluída com sucesso",
  "S249": "Etapa desativada com sucesso",
  "S250": "Etapa ativada com sucesso",
  "S251": "Etapa alterada com sucesso",
  "S252": "Estagio alterado com sucesso",
  "S253": "Estagio desativado com sucesso",
  "S254": "Estagio ativado com sucesso",
  "S255": "Estagio excluído com sucesso",
  "S256": "Entrega desativada com sucesso",
  "S257": "Entrega ativada com sucesso",
  "S258": "Trilha avaliada com sucesso",
  "S259": "Avaliação de trilha alterada com sucesso",
  "S260": "Avaliação de trilha excluída com sucesso",
  "S261": "Arquivo da atividade cadastrado com sucesso",
  "S262": "Arquivo excluído com sucesso",
  "S263": "Vínculo de ambiente de inovação e jornada inserido com sucesso",
  "S264": "Vínculo de ambiente de inovação e jornada alterado com sucesso",
  "S265": "Vínculo de ambiente de inovação e jornada desativado com sucesso",
  "S266": "Vínculo de ambiente de inovação e jornada ativado com sucesso",
  "S267": "Vínculo de ambiente de inovação e jornada excluído com sucesso",
  "S268": "Vínculo de ambiente de inovação e radar inserido com sucesso",
  "S269": "Vínculo de ambiente de inovação e radar alterado com sucesso",
  "S270": "Vínculo de ambiente de inovação e radar desativado com sucesso",
  "S271": "Vínculo de ambiente de inovação e radar ativado com sucesso",
  "S272": "Vínculo de ambiente de inovação e radar excluído com sucesso",
  "S273": 'Avaliador aprovado com sucesso',
  "S274": 'Avaliador reprovado com sucesso',

  "ST1": "Não entregue",
  "ST2": "Aguardando validação",
  "ST3": "Enviar nova evidência",
  "ST4": "Validado",
  "ST5": "Enviar nova atividade",
  "ST6": "Enviar nova etapa",
}

export const english = {
  "E101": "Error: Invalid data!",
  "E102": "Error: Invalid URL",
  "E103": "Error: Error to find files",
  "E104": "Error: Terms not accepted",
  "E105": "Error: Invalid file",
  "E106": "Error: Choose a valid JPG or PNG image",
  "E107": "Error: Invalid Token!",
  "E108": "Error: Not possible to edit the image",
  "E109": "Error: E-mail is already register",
  "E110": "Error: User not found",
  "E111": "Error: Invalid user/password",
  "E112": "Error: User has not access",
  "E113": "Error: User unauthorized",
  "E114": "Error: User was not changed",
  "E115": "Error: Password was not changed",
  "E116": "Error: Not possible to register",
  "E117": "Error: Class type not found",
  "E118": "Error: File not found",
  "E119": "Error: File was not deleted",
  "E120": "Error: Activity not found",
  "E121": "Error: Question not found",
  "E122": "Error: Activity was not changed",
  "E123": "Error: Activity was not deleted",
  "E124": "Error: Progress-Activity not found",
  "E125": "Error: Progress-Activity was not changed",
  "E126": "Error: Progress-Activity was not deleted",
  "E127": "Error: Class not found",
  "E128": "Error: Class was not changed",
  "E129": "Error: Class was not deleted",
  "E130": "Error: Progress-Class not found",
  "E131": "Error: Progress-Class was not changed",
  "E132": "Error: Progress-Class was not deleted",
  "E133": "Error: Class type was not deleted",
  "E134": "Error: Assessment not found",
  "E135": "Error: Assessment was not changed",
  "E136": "Error: Assessment was not deleted",
  "E137": "Error: Manager's assessment not found",
  "E138": "Error: Manager's assessment was not changed",
  "E139": "Error: Manager's assessment was not deleted",
  "E140": "Error: Not possible to link user in the innovation environment",
  "E141": "Error: Manager not found",
  "E142": "Error: Manager was not unlinked",
  "E143": "Error: Chat already exists",
  "E144": "Error: Chat not found",
  "E145": "Error: Chat was not changed",
  "E146": "Error: Chat was not deleted",
  "E147": "Error: Component not found",
  "E148": "Error: Component was not deleted",
  "E149": "Error: Content not found",
  "E150": "Error: Content was not changed",
  "E151": "Error: Content was not deleted",
  "E152": "Error: Content type not found",
  "E153": "Error: Content type was not deleted",
  "E154": "Error: Credential already exists for this profile",
  "E155": "Error: Credential not found",
  "E156": "Error: Credential was not deleted",
  "E157": "Error: Not possible to insert the challenge",
  "E158": "Error: Challenge not found",
  "E159": "Error: Challenge was not changed",
  "E160": "Error: Challenge was not deleted",
  "E162": "Error: Not possible to insert the submit",
  "E163": "Error: Submit not found",
  "E164": "Error: Submit was not changed",
  "E165": "Error: Submit was not deleted",
  "E166": "Error: Event not found",
  "E167": "Error: Event was not changed",
  "E168": "Error: Event was not deleted",
  "E169": "Error: Not possible to insert the evidence",
  "E170": "Error: Evidence not found",
  "E171": "Error: Evidence was not changed",
  "E172": "Error: Evidence was not deleted",
  "E173": "Error: Language not found",
  "E174": "Error: Language was not deleted",
  "E175": "Error: Linked innovation environment not found",
  "E176": "Error: Innovation environment not found",
  "E177": "Error: Innovation environment was not changed",
  "E178": "Error: Innovation environment was not enable",
  "E179": "Error: Innovation environment was not disable",
  "E180": "Error: Innovation environment was not deleted",
  "E181": "Error: Subject not found",
  "E182": "Error: Subject was not changed",
  "E183": "Error: Subject was not deleted",
  "E184": "Error: User already registered",
  "E185": "Error: Not possible to link user in the trail",
  "E188": "Error: Register not found",
  "E189": "Error: Register was not disable",
  "E190": "Error: Message not found",
  "E191": "Error: Message was not changed",
  "E192": "Error: Message was not disable",
  "E193": "Error: Not possible to insert mentorship",
  "E194": "Error: Mentorship not found",
  "E195": "Error: Mentorship was not changed",
  "E196": "Error: Mentorship was not deleted",
  "E197": "Error: Methodology not found",
  "E198": "Error: Radar was not linked",
  "E199": "Error: Methodology was not changed",
  "E200": "Error: Methodology was not deleted",
  "E201": "Error: Module not found",
  "E202": "Error: Module was not changed",
  "E203": "Error: Module was not deleted",
  "E204": "Error: Option not found",
  "E205": "Error: Option was not changed",
  "E206": "Error: Option was not deleted",
  "E208": "Error: Not possible to execute the solicitation",
  "E209": "Error: Profile not found",
  "E210": "Error: Profile was not changed",
  "E211": "Error: Profile was not deleted",
  "E212": "Error: Pilar not found",
  "E213": "Error: Pilar was not changed",
  "E214": "Error: Pilar was not deleted",
  "E215": "Error: Process not found",
  "E216": "Error: Process was not changed",
  "E217": "Error: Process was not deleted",
  "E218": "Error: Question not found",
  "E219": "Error: Question was not changed",
  "E220": "Error: Question was not enable",
  "E221": "Error: Question was not disable",
  "E222": "Error: Question was not deleted",
  "E223": "Error: Not possible to save radar",
  "E224": "Error: Radar was not changed",
  "E225": "Error: Radar not found",
  "E226": "Error: Answers of step not found",
  "E227": "Error: Answers of radar not found",
  "E228": "Error: Answer not found",
  "E229": "Error: Radar-Methodology was not changed",
  "E230": "Error: Radar-Methodology not found",
  "E231": "Error: Radar-Methodology was not deleted",
  "E232": "Error: Business not found",
  "E233": "Error: Not registered users in this innovation environment",
  "E235": "Error: Not registered business in this innovation environment",
  "E236": "Error: Solution not found",
  "E237": "Error: Solution was not changed",
  "E238": "Error: Solution was not accept",
  "E239": "Error: Solution validation was not canceled",
  "E240": "Error: Solution was not deleted",
  "E241": "Error: Not possible to link user in business",
  "E244": "Error: Not linked business",
  "E245": "Error: Business was not changed",
  "E246": "Error: Business was not enable",
  "E247": "Error: Business was not disable",
  "E248": "Error: Business was not deleted",
  "E249": "Error: Step not found",
  "E250": "Error: Link not found",
  "E251": "Error: Business-Methodology not found",
  "E252": "Error: Status not found",
  "E253": "Error: Status was not deleted",
  "E254": "Error: Trail-Author link not found",
  "E255": "Error: Trail-Author link was not changed",
  "E256": "Error: Trail-Author link was not enable",
  "E257": "Error: Trail-Author link was not disable",
  "E258": "Error: Trail-Author link was not deleted",
  "E259": "Error: Not possible to duplicate trail",
  "E260": "Error: Not possible to duplicate module",
  "E261": "Error: Not possible to dublicate subject",
  "E262": "Error: Not possible to dublicate class",
  "E263": "Error: Not possible to dublicate activity",
  "E264": "Error: Not possible to dublicate question",
  "E265": "Error: Not possible to dublicate option",
  "E266": "Error: Trail not found",
  "E267": "Error: Trail was not changed",
  "E268": "Error: Trail was not enable",
  "E269": "Error: Trail was not disable",
  "E270": "Error: Trail was not deleted",
  "E271": "Error: Not possible to link profile in register",
  "E272": "Error: User business not found",
  "E273": "Error: User innovation environment not found",
  "E274": "Error: User was not changed",
  "E275": "Error: User was not enable",
  "E276": "Error: User was not disable",
  "E277": "Error: User was not deleted",
  "E278": "Error: User was not unlinked",
  "E279": "Error: Not possible to send solicitation",
  "E280": "Error: Radar not unlinked",
  "E281": "Error: Question submit not found",
  "E282": "Error: Question submit was not changed",
  "E283": "Error: Question submit was not deleted",
  "E284": "Error: Business-Methodology not found",
  "E285": "Error: Methodology was not disable",
  "E286": "Error: Methodology was not enable",
  "E287": "Error: Process was not disable",
  "E288": "Error: Process was not enable",
  "E290": "Error: Step was not deleted",
  "E291": "Error: Step was not disable",
  "E292": "Error: Step was not enable",
  "E293": "Error: Step was not changed",
  "E294": "Error: Stage not found",
  "E295": "Error: Stage was not changed",
  "E296": "Error: Stage was not disable",
  "E297": "Error: Stage was not enable",
  "E298": "Error: Stage was not deleted",
  "E299": "Error: Submit was not disable",
  "E300": "Error: Submit was not enable",
  "E301": "Error: User already rate this trail!",
  "E302": "Error: Trial rating not found",
  "E303": "Error: Trial rating was not changed",
  "E304": "Error: Trial rating was not deleted",
  "E305": "Error: Activity file not found",
  "E306": "Error: Activity file was not deleted",
  "E307": "Error: Not possible to link innovation environment and methodology",
  "E308": "Error: Innovation environment and methodology link not found",
  "E309": "Error: Innovation environment and methodology link awas not changed",
  "E310": "Error: Innovation environment and methodology link awas not disable",
  "E311": "Error: Innovation environment and methodology link awas not enable",
  "E312": "Error: Innovation environment and methodology link awas not deleted",
  "E313": "Error: Not possible to link innovation environment and radar",
  "E314": "Error: Innovation environment and radar link not found",
  "E315": "Error: Innovation environment and radar link awas not changed",
  "E316": "Error: Innovation environment and radar link awas not disable",
  "E317": "Error: Innovation environment and radar link awas not enable",
  "E318": "Error: Innovation environment and radar link awas not deleted",
  "E319": "Error: Link already exists!",

  "S101": "We send you by e-mail the instructions to recover your password, verify your inbox",
  "S102": "Password successfully changed!",
  "S103": "File successfully register",
  "S104": "File successfully deleted",
  "S105": "Activity successfully register",
  "S106": "Activity successfully changed",
  "S107": "Activity successfully deleted",
  "S108": "Activity progress successfully register",
  "S109": "Previously seen activity",
  "S110": "Activity progress successfully changed",
  "S111": "Activity progress successfully deleted",
  "S112": "Class successfully register",
  "S113": "Class successfully changed",
  "S114": "Class successfully deleted",
  "S115": "Class progress successfully register",
  "S116": "Previously seen class",
  "S117": "Class progress successfully changed",
  "S118": "Class progress successfully deleted",
  "S119": "Class type successfully register",
  "S120": "Class type successfully changed",
  "S121": "Class tpe successfully deleted",
  "S122": "Assessment successfully register",
  "S123": "Assessment successfully changed",
  "S124": "Assessment successfully deleted",
  "S125": "Manager's assessment successfully register",
  "S126": "Manager's assessment successfully changed",
  "S127": "Manager's assessment successfully deleted",
  "S128": "Manager successfully linked",
  "S129": "Manager successfully register",
  "S130": "Term successfully sent",
  "S131": "Manager successfully unlinked",
  "S132": "Chat successfully register",
  "S133": "Chat successfully changed",
  "S134": "Chat successfully deleted",
  "S135": "Component successfully register",
  "S136": "Component successfully deleted",
  "S137": "Content successfully register",
  "S138": "Content successfully changed",
  "S139": "Content successfully deleted",
  "S140": "Content type successfully register",
  "S141": "Content type successfully changed",
  "S142": "Content type successfully deleted",
  "S143": "Credential successfully register",
  "S144": "Credential successfully deleted",
  "S145": "Challenge successfully register",
  "S146": "Challenge successfully changed",
  "S147": "Challenge successfully deleted",
  "S148": "Submit successfully insert",
  "S149": "Submit successfully changed",
  "S150": "Submit successfully deleted",
  "S151": "Event successfully register",
  "S152": "Event successfully changed",
  "S153": "Event successfully deleted",
  "S154": "Evidence successfully register",
  "S155": "Evidence successfully changed",
  "S156": "Evidence successfully deleted",
  "S157": "Language successfully register",
  "S158": "Language successfully deleted",
  "S159": "Innovation environment successfully register",
  "S160": "Innovation environment successfully changed",
  "S161": "Innovation environment successfully enable",
  "S162": "Innovation environment successfully disable",
  "S163": "Innovation environment successfully deleted",
  "S164": "Subject successfully register",
  "S165": "Subject successfully changed",
  "S166": "Subject successfully deleted",
  "S167": "User successfully registered",
  "S168": "Register successfully disable",
  "S169": "Message successfully register",
  "S170": "Message successfully changed",
  "S171": "Message successfully deleted",
  "S172": "Mentorship successfully insert",
  "S173": "Mentorship successfully changed",
  "S174": "Mentorship successfully deleted",
  "S175": "Methodology successfully register",
  "S176": "Radar successfully linked",
  "S177": "Radar successfully unlinked",
  "S178": "Methodology successfully register",
  "S179": "Methodology successfully deleted",
  "S180": "Module successfully register",
  "S181": "Module successfully changed",
  "S182": "Module successfully deleted",
  "S183": "Option successfully register",
  "S184": "Option successfully changed",
  "S185": "Option successfully deleted",
  "S187": "Profile successfully register",
  "S188": "Profile successfully changed",
  "S190": "Profile successfully deleted",
  "S191": "Pilar successfully register",
  "S192": "Pilar successfully changed",
  "S193": "Pilar successfully deleted",
  "S194": "Process successfully register",
  "S195": "Process successfully changed",
  "S196": "Process successfully deleted",
  "S197": "Question successfully register",
  "S198": "Question successfully changed",
  "S199": "Question successfully enable",
  "S200": "Question successfully disable",
  "S201": "Question successfully deleted",
  "S202": "Question submit successfully register",
  "S203": "Question submit successfully changed",
  "S204": "Question submit successfully deleted",
  "S205": "Radar successfully saved",
  "S206": "Radar successfully changed",
  "S208": "Radar-Methodology successfully register",
  "S209": "Radar-Methodology successfully changed",
  "S210": "Radar-Methodology successfully deleted",
  "S211": "Solution successfully insert",
  "S212": "Solution successfully changed",
  "S213": "Solution successfully accept",
  "S214": "Successfully cancelation of validation",
  "S215": "Solution successfully deleted",
  "S216": "User successfully register",
  "S217": "User successfully linked",
  "S218": "Business successfully changed",
  "S219": "Business successfully enable",
  "S220": "Business successfully disable",
  "S221": "Business successfully deleted",
  "S222": "Business-Methodology successfully linked",
  "S223": "Authorized access",
  "S224": "Business-Methodology link successfully deleted",
  "S225": "Status successfully register",
  "S226": "Status successfully deleted",
  "S227": "Trail-Author successfully linked",
  "S228": "Trail-Author link successfully changed",
  "S229": "Trail-Author link successfully enable",
  "S230": "Trail-Author link successfully disable",
  "S231": "Trail-Author link successfully deleted",
  "S232": "Trail successfully register",
  "S233": "Trail successfully changed",
  "S234": "Trail successfully enable",
  "S235": "Trail successfully disable",
  "S236": "Trail successfully deleted",
  "S237": "User successfully register and linked to profile",
  "S238": "User successfully changed",
  "S239": "User successfully enable",
  "S240": "User successfully disable",
  "S241": "User successfully deleted",
  "S242": "User successfully unlinked",
  "S243": "Methodology successfully disable",
  "S244": "Methodology successfully enable",
  "S245": "Process successfully disable",
  "S246": "Process successfully enable",
  "S247": "Step successfully register",
  "S248": "Step successfully deleted",
  "S249": "Step successfully disable",
  "S250": "Step successfully enable",
  "S251": "Step successfully changed",
  "S252": "Stage successfully changed",
  "S253": "Stage successfully disable",
  "S254": "Stage successfully enable",
  "S255": "Stage successfully deleted",
  "S256": "Submit successfully disable",
  "S257": "Submit successfully enable",
  "S258": "Trial successfully rated",
  "S259": "Trial rating successfully changed",
  "S260": "Trial successfully deleted",
  "S261": "Activity file successfully register",
  "S262": "Activity file successfully deleted",
  "S263": "Innovation environment and methodology link successfully register",
  "S264": "Innovation environment and methodology link successfully changed",
  "S265": "Innovation environment and methodology link successfully disable",
  "S266": "Innovation environment and methodology link successfully enable",
  "S267": "Innovation environment and methodology link successfully deleted",
  "S273": 'Appraiser successfully approveds',
  "S274": 'Evaluator failed successfully',

  "ST1": "Not ready",
  "ST2": "Waiting validation",
  "ST3": "Send new evidence",
  "ST4": "Validated",
}
