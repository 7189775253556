export const portugues = {
  pesquisa: 'Pesquisar...',
  bt_pesquisar: 'Pesquisar',
  bt_cancelar: 'Cancelar',
  bt_filtrar: 'Filtrar por jornada',
  paginacao1: 'Primeira',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  paginacao2: 'Última',
  gestao: {
    alerta_titulo: 'Você tem certeza?',
    alerta_info: 'Não será possível restaurar esse negócio!',
    bt_alerta: 'Sim, excluir este negócio?',
    user_alerta: 'Usuário excluído com sucesso',
    titulo: 'Negócios Incubados',
    info_titulo: 'Não há negócios para exibir',
    sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
    info_body: 'Que pena! Não há informações para serem exibidas.'
  },
  config: {
    modal_confirm: 'Sim, desativar esta incubadora!',
    modal_sucesso: 'Ambiente de inovação desativado com sucesso!',
    modal_titulo2: 'Deseja deletar o ambiente de inovação?',
    modal_info: 'Ao fazer isso você concorda na exclusão dos vínculos de participantes, negócios e informações do ambiente de inovação </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_msg: 'Digite CONFIRMAR para realizar a exclusão',
    deletar: 'Deletar',
    confirmar: 'CONFIRMAR',
    modal_error: 'Mensagem de confirmação inválida!',
    msg_sucesso: 'Ambiente de inovação excluído com sucesso!',
    titulo: 'Configurações',
    cadastrar_neg: 'Cadastrar negócio',
    editar: 'Editar',
    editar_foto: 'Editar foto',
    editar_capa: 'Editar capa',
    desativar: 'Desativar',
  },
  listarStartups: {
    titulo: 'Negócios incubados',
    erro: 'Este perfil não está vinculado a uma incubadora',
    erro_pesquisa: 'Insira um nome para a pesquisa',
    sem_dados: 'Hmm.. Pelo visto não há aeronaves nesta estação',
    sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  }
}

export const ingles = {
  pesquisa: 'Search...',
  bt_pesquisar: 'Search',
  bt_cancelar: 'Cancel',
  bt_filtrar: 'Filter by metodology',
  sem_dados_sub: 'What a pity! There is no information to display.',
  paginacao1: 'First',
  paginacao2: 'Last',
  gestao: {
    alerta_titulo: 'Are you sure?',
    alerta_info: 'Não será possível restaurar esse negócio!',
    bt_alerta: 'Yes, delete this business?',
    user_alerta: 'User successfully deleted.',
    titulo: 'Incubated Business',
    info_titulo: 'No business to show',
    info_body: 'What a pity! There is no information to display.',
    sem_dados_sub: 'What a pity! There is no information to display.',
  },
  config: {
    modal_confirm: 'Yes, disable this incubator!',
    modal_sucesso: 'Innovation space successfully disabled!',
    modal_titulo2: 'Do you wish to delete this innovation space?',
    modal_info: 'By doing so, you agree to the deletion of participant, business and information links from the innovation space </br>Type <strong>CONFIRM</strong> to perform the deletion',
    modal_msg: 'Type CONFIRM to perform the deletion',
    deletar: 'Delete',
    confirmar: 'CONFIRM',
    modal_error: 'Invalid confirmation message!',
    msg_sucesso: 'Innovation space successfully deleted!',
    titulo: 'Settings',
    cadastrar_neg: 'Register business',
    editar: 'Edit',
    editar_foto: 'Edit photo',
    editar_capa: 'Edit cover',
    desativar: 'Disable',
  },
  listarStartups: {
    titulo: 'Incubated businesses',
    erro: 'This profile is not linked to an incubator',
    erro_pesquisa: 'Enter a name for the search',
    sem_dados: 'Hmm.. Apparently there are no aircraft at this station',
    sem_dados_sub: 'What a pity! There is no information to display.',
  }
}
