import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfilTrilha';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import MyDropzone from "../../componentes/Formulario/dropzone"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class EditarCapa extends Component {
    state = {
        id_trilha: "",
        file: null,
        erro: "",
        success: "",
        dadosApi: false,
        carregando: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha })
        this.listarTrilha(id_trilha);

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha);
        }
        this.receberDadosAPI();
    }


    // componentWillUnmount() {
    //     this.props.limparStartup()
    // }



    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }
    receberDadosAPI() {
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }

    async atualizarCapa(id, file) {
        this.setState({ success: "" });
        this.setState({ erro: "" });



        this.setState({ carregando: true })
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id)
        ////////console.log(id)
        await this.props.putTrilhaCapa(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ formSuccess: true });
            }
        });
    }




    render() {
        const { file, carregando, erro, formSuccess, id_trilha, dadosApi, idioma } = this.state;
        var trilha = null;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        if (this.props.trilha) trilha = this.props.trilha;
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (formSuccess) {
            return <Redirect to={{
                pathname: '/trilhas/' + id_trilha + "/descricao",
                state: { msg: idioma.editarCapa.msg_sucesso }
            }} />
        }
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editarCapa.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoPerfil dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <Form>
                                <FormGroup>
                                    <Label for="file">{idioma.editarCapa.nava_capa}</Label>
                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                </FormGroup>
                                <Link onClick={() => this.atualizarCapa(id_trilha, file)} to="#">
                                    <Botao loading={carregando} texto={idioma.editarCapa.bt_enviar} cor="success" icone="rocket" classes="botao-responsivo botao-estilo" posicao="E" />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>

                            </Form>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario,
    trilha: state.trilhas.trilha,

})
export default connect(mapStateToProps, actions)(EditarCapa);