import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/eventos';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import BlocoPerfil from '../../containers/blocos/perfilEvento';
import Botao from '../../componentes/Botoes';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Skeleton from "react-loading-skeleton";

import { portugues, ingles } from '../../utils/paginaeventos_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class EditarEvento extends Component {
    state = {
        id_evento: "",
        erro: "",
        success: "",
        file: null,
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_evento } = this.props.match.params;
        ////////console.log(id_evento)
        this.props.getVerEvento(id_evento);

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        const { id_evento } = this.props.match.params;
        if (!this.props.evento && nextProps.evento) this.props.getVerEvento(id_evento);
        await this.receberDadosAPI();
    }
    receberDadosAPI() {
        const { id_evento } = this.props.match.params;
        if (typeof this.props.evento !== "undefined" && this.props.evento !== null && this.props.evento.id == id_evento && !this.state.dadosAPI) {
            this.setState({ id_evento: this.props.evento.id })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }


    async atualizarEvento(id_evento, file) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        var id = id_evento
        await this.receberDadosForm();
        if (!this.validate()) return;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('id', id)
        this.setState({ carregando: true })
        ////////console.log(formData)
        this.props.putEventoImagem(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }
    receberDadosForm() {
        this.setState({ id_evento: document.querySelector("#id_evento").value })
        this.setState({ file: document.querySelector("#file").value })
    }
    validate() {
        const { file, idioma } = this.state;
        if (!file) return this.setState({ erro: { message: idioma.editarCapa.erro_img } });
        return true;
    }
    render() {
        const { id_evento, file, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;
        var evento = null;
        if (this.props.evento) evento = this.props.evento;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        if (formSuccess) return <Redirect to={{
            pathname: "/eventos/" + id_evento + "/ver",
            state: { msg: idioma.editarCapa.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editarCapa.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {evento != null ?
                        <BlocoPerfil evento={evento} dadosApi={dadosAPI} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" >
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className=""><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <AlertaErro erros={erro} />
                        <AlertaSuccess erros={success} />
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <Form className="pt-4">
                                        <Input type="hidden" value={id_evento} id="id_evento" name="id_evento" />
                                        <FormGroup>
                                            <Label for="file">{idioma.cadastrarEvento.img}</Label>
                                            <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                        </FormGroup>
                                        <Link onClick={() => this.atualizarEvento(id_evento, file)} to="#">
                                            <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' classes="botao-responsivo botao-estilo" />
                                        </Link>

                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    evento: state.eventos.evento

})
export default connect(mapStateToProps, actions)(EditarEvento)