import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfilDesafio';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Table } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { differenceInDays } from 'date-fns';
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { apiImagem } from '../../config';

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';
import ReactTooltip from 'react-tooltip';

class Usuarios extends Component {
    state = {
        id_desafio: "",
        currentPage: 1,
        size: 20,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pertence: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_desafio } = this.props.match.params;
        this.setState({ id_desafio })
        this.listarAvaliadores(id_desafio);
        this.listarDesafio(id_desafio);

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }


    }
    componentDidUpdate(nextProps) {
        const { id_desafio } = this.props.match.params;
        if (!this.props.avaliadores && nextProps.avaliadores) this.listarAvaliadores(id_desafio);
        if (!this.props.desafio && nextProps.desafio) {
            this.listarDesafio(id_desafio);
        }
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparAvaliadores();
    }
    receberDadosAPI() {
        if (typeof this.props.avaliadores != "undefined" && this.props.avaliadores != null && !this.state.dadosApi && this.props.avaliadores.currentPage === this.state.currentPage) {

            if (typeof this.props.desafio != "undefined" && this.props.desafio != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }
    usuarioNovo(data) {
        var result = differenceInDays(
            new Date(),
            data
        )
    }

    listarDesafio(id_desafio) {
        this.props.getVerDesafio(id_desafio);
        const { desafio } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (desafio === "undefined") return null
    }


    listarAvaliadores(id_desafio) {
        const { currentPage, size } = this.state;
        this.props.getAvaliadores(id_desafio, currentPage, size)
        const { avaliadores } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (avaliadores === "undefined") return null

    }
    changePageAtual(currentPage) {
        const { id_desafio } = this.state;
        this.props.limparUsuarios();
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarAvaliadores(id_desafio);
        })
    }
    apagarUsuario(id) {
        this.props.limparUsuarios();
        const { id_desafio } = this.state
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarAvaliador(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarAvaliadores(id_desafio);
                this.sucessoAlerta();
                // this.setState({ formSuccess: true });
            }
        })
    }
    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.avaliadores.modal_titulo,
            text: idioma.avaliadores.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.avaliadores.bt_cancelar,
            confirmButtonText: idioma.avaliadores.bt_excluir
        }).then((result) => {
            if (result.value) {
                this.apagarUsuario(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.avaliadores.modal_sucesso
        })
    }

    aprovarTermoUsuario(id_usuario) {
        const { id_desafio } = this.state;
        this.setState({ carregando: true })
        this.props.aprovarTermoAvaliador({ id: id_usuario, id_desafio }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.listarAvaliadores(id_desafio);
            }
        }
        )
    }

    reprovarTermoUsuario(id_usuario) {
        const { id_desafio } = this.state;
        this.setState({ carregando: true })
        this.props.reprovarTermoAvaliador({ id: id_usuario, id_desafio }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.listarAvaliadores(id_desafio);
            }
        }
        )
    }


    verificaIncubadora(incubadoras, incubadora) {
        for (let i in incubadoras) {
            if (incubadoras[i].id_incubadora == incubadora.id) {
                this.setState({ pertence: true })
            }
        }
    }
    handleButtons(id_usuario, usuarios) {
        for (let u in usuarios) {
            if (usuarios[u].usuario.id == id_usuario) {
                this.setState({ pertence: true })
            }
        }
    }

    baixarEntrega(fileName) {
        window.open(apiImagem + fileName, "_blank")
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao.cor_secundaria
    }

    render() {
        const { id_desafio, msg, carregando, erro, success, dadosApi, pertence, idioma } = this.state;

        var temPaginaAnterior = false;
        if (typeof this.props.avaliadores != "undefined" && this.props.avaliadores != null && this.props.avaliadores.currentPage !== "" && this.props.avaliadores.currentPage !== 1) {
            temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (typeof this.props.avaliadores != "undefined" && this.props.avaliadores !== null && this.props.avaliadores.nextPage <= this.props.avaliadores.totalPages && this.props.avaliadores.nextPage != null) {
            proximaPagina = true;
            temProximaPagina = true;
        }
        var avaliadores = [];
        if (this.props.avaliadores) avaliadores = this.props.avaliadores.dados;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var desafio = null;
        if (this.props.desafio) desafio = this.props.desafio;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.avaliadores.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {desafio != null && usuario != null ?
                        <BlocoPerfil dadosApi={dadosApi} desafio={desafio} credenciais={credenciais} usuario={usuario} path={path} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {dadosApi ?
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto="Voltar" classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                                {credenciais.map(credencial => ((credencial.id_componente == 84) ?
                                    pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) || desafio.id_autor == this.props.usuario.id ?
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/avaliador/" + id_desafio + "/cadastrar"} key={credencial.id_componente} className='mr-2'>
                                            <Botao texto={idioma.avaliadores.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                            {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                                {idioma.avaliadores.bt_cadastrar}
                                            </button> */}
                                        </Link>
                                        : ""
                                    : ""))}

                                {credenciais.map(credencial => ((credencial.id_componente == 85) ?
                                    pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) || desafio.id_autor == this.props.usuario.id ?
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/avaliador/" + id_desafio + "/vincular"} key={credencial.id_componente}>
                                            <Botao texto={idioma.avaliadores.bt_vincular} classes="botao-responsivo" icone="link" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                            {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                                {idioma.avaliadores.bt_vincular}
                                            </button> */}
                                        </Link>
                                        : ""
                                    : ""))}

                                <div className="d-flex">
                                    <div className="col-md-12 p-2">
                                        {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                        <AlertaErro erros={erro} />
                                        <AlertaSuccess erros={success} />
                                        {dadosApi ?
                                            <div className="row table-responsive">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th >{idioma.avaliadores.nome}</th>
                                                            <th>{idioma.avaliadores.data}</th>
                                                            <th>{idioma.avaliadores.termo}</th>
                                                            <th>{idioma.avaliadores.aprovar}</th>
                                                            <th>{idioma.avaliadores.acao}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {avaliadores.map(usuario => (
                                                            <tr key={usuario.id}>

                                                                <td>{usuario.nome}</td>
                                                                <td>
                                                                    <span className="text-perfil">{usuario.data_aceite ? format(new Date(usuario.data_aceite), 'dd/MM/yyyy', { locale: pt }) : ""}</span>
                                                                </td>
                                                                <td>
                                                                    <Link onClick={() => this.baixarEntrega(usuario.fileName)}>
                                                                        {usuario.originalName}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {!usuario.data_aceite && (
                                                                        <a data-tip data-for="analise">
                                                                            <FontAwesomeIcon icon="clock" color="orange" />
                                                                        </a>
                                                                    )}
                                                                    {credenciais.map(credencial => ((credencial.id_componente == 16) ?
                                                                        pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                                                            usuario.data_aceite && !usuario.data_aprovacao && (
                                                                                <span onClick={() => this.aprovarTermoUsuario(usuario.id_usuario)} key={credencial.id_componente}>
                                                                                    <Botao loading={carregando} texto={idioma.avaliadores.aprovar} classes="botao-responsivo" cor="success" tamanho="sm" />
                                                                                </span>
                                                                            )
                                                                            : ""
                                                                        : ""
                                                                    ))}
                                                                    {credenciais.map(credencial => ((credencial.id_componente == 16) ?
                                                                        pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                                                            usuario.data_aceite && usuario.data_aprovacao && (
                                                                                <span onClick={() => this.reprovarTermoUsuario(usuario.id_usuario)} key={credencial.id_componente}>
                                                                                    <Botao loading={carregando} texto={idioma.avaliadores.reprovar} classes="botao-responsivo" cor="danger" tamanho="sm" />
                                                                                </span>
                                                                            )
                                                                            : ""
                                                                        : ""
                                                                    ))}
                                                                </td>
                                                                <td>
                                                                    {credenciais.map(credencial => ((credencial.id_componente == 16) ?
                                                                        pertence || (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                                                            <span onClick={() => this.chamarAlerta(usuario.id_usuario)} key={credencial.id_componente}>
                                                                                <Botao loading={carregando} texto={idioma.avaliadores.bt_deletar} classes="botao-responsivo" cor="danger" tamanho="sm" />
                                                                            </span>
                                                                            : ""
                                                                        : ""
                                                                    ))}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    <ReactTooltip id="analise" type="warning">
                                                        <span>{idioma.avaliadores.info_btaprovar}</span>
                                                    </ReactTooltip>
                                                </Table>
                                            </div>
                                            : <div className="row table-responsive">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th >{idioma.avaliadores.nome}</th>
                                                            <th>{idioma.avaliadores.acao}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Skeleton count={1} width={80} />
                                                            </td>
                                                            <td>
                                                                <Skeleton count={1} width={80} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                        {dadosApi ?
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination justify-content-center">
                                                    <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                                                    </li>

                                                    {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.avaliadores.prevPage ? this.props.avaliadores.prevPage : 1)}>{this.props.avaliadores.prevPage ? this.props.avaliadores.prevPage : ""}</span></li> : ""}

                                                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.avaliadores ? this.props.avaliadores.currentPage : "1"}</span></li>

                                                    {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.avaliadores.nextPage ? this.props.avaliadores.nextPage : 1)}>{this.props.avaliadores.nextPage ? this.props.avaliadores.nextPage : ""}</span></li> : ""}

                                                    <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.avaliadores ? this.props.avaliadores.totalPages : 0)}>{idioma.page2}</span>
                                                    </li>
                                                </ul>
                                            </nav>
                                            : ""}
                                    </div>
                                </div>
                            </div >
                        </div >
                        : ""}
                </div >
            </>
        )
    }
}
const mapStatetoProps = state => ({
    avaliadores: state.hub.avaliadores,
    desafio: state.hub.desafio

})
export default connect(mapStatetoProps, actions)(Usuarios);