export const portugues = {
  titulo: 'Beneficios',
  body: 'Olá, estamos trabalhando em nossa página de Benefícios!',
  text: 'Até tudo ficar pronto, por favor solicite os benefícios da Cebbi pelo contato@viaevolucao.com.br.',
  subtexto: 'Obrigada!'
}

export const ingles = {
  titulo: 'Benefits',
  body: 'Hi, we are working on our Benefits page!',
  text: "Until everything get ready, please request the benefits through Cebbi's email: contato@viaevolucao.com.br.",
  subtexto: 'Thank you!'
}