import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  DropdownButton,
  Dropdown,
} from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import SemDados from '../../componentes/Mensagens/semDados';

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class CadastrarParticipante extends Component {
  state = {
    nome: '',
    senha: '',
    email: '',
    id_perfil: '',
    id_desafio: '',
    pertence: false,
    erro: '',
    success: '',
    carregando: '',
    formSuccess: false,
    idioma: portugues,
    msg_backend: portuguese,
    select_idioma: 'portugues',
  };
  componentDidMount() {
    this.setState({ id_perfil: 10 });
    const { id_desafio } = this.props.match.params;
    this.setState({ id_desafio });
    // this.verificaPertence(this.props.usuario.id, id_startup)

    // if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

    const { usuario } = this.props;

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }
  onChnageInput(campo, ev) {
    this.setState({ [campo]: ev.target.value });
  }

  cadParticipante() {
    const { nome, email, senha, id_perfil, id_desafio, select_idioma } =
      this.state;
    if (!this.validate()) return;
    const id_idioma = select_idioma === 'portugues' ? 1 : 3;
    this.setState({ carregando: true });
    this.props.postNovoAvaliador(
      { nome, email, senha, id_perfil, id_desafio, id_idioma },
      (msg) => {
        if (msg.erro.error) {
          this.setState({
            erro: { message: this.state.msg_backend[msg.erro.code] },
          });
          this.setState({ success: '' });
          this.setState({ carregando: false });
        } else {
          this.setState({
            success: { message: 'Participante cadastrado com sucesso!' },
          });
          this.setState({ erro: '' });
          this.setState({ carregando: false });
          this.setState({ formSuccess: true });
        }
      },
    );
  }
  validate() {
    const { nome, email, senha, id_perfil, idioma } = this.state;
    if (!nome)
      return this.setState({
        erro: { message: idioma.cadastrarAvaliador.erro_nome },
      });
    if (!email)
      return this.setState({
        erro: { message: idioma.cadastrarAvaliador.erro_email },
      });
    if (!validator.isEmail(email))
      return this.setState({
        erro: { message: idioma.cadastrarAvaliador.erro_email2 },
      });
    if (!senha)
      return this.setState({
        erro: { message: idioma.cadastrarAvaliador.erro_senha },
      });
    if (senha.length < 6)
      return this.setState({
        erro: { message: idioma.cadastrarAvaliador.erro_senha2 },
      });
    return true;
  }

  render() {
    const {
      nome,
      email,
      senha,
      id_desafio,
      erro,
      success,
      carregando,
      iconSalvar,
      formSuccess,
      id_perfil,
      id_startup,
      idioma,
    } = this.state;
    var perfis = [];
    var usuarioPertence = [];
    var credenciais = [];
    var selIdiomaDisable = true;
    if (this.props.credenciais) credenciais = this.props.credenciais;
    for (let c in credenciais) {
      if (credenciais[c].id_componente === 97) selIdiomaDisable = false;
    }
    if (this.props.pertence) usuarioPertence = this.props.pertence;
    if (this.props.perfis) perfis = this.props.perfis.dados;
    if (formSuccess)
      return (
        <Redirect
          to={{
            pathname: '/hub/avaliadores/' + id_desafio,
            state: { msg: idioma.cadastrarAvaliador.info_sucesso },
          }}
        ></Redirect>
      );
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario.personalizacao.cor_principal,
          }}
        >
          <Titulo titulo={idioma.cadastrarAvaliador.titulo} />
        </div>

        <div className="container-fluid bloco-topo">
          <div className="col-md-12 mb-2">
            <div
              className="list-group-item"
              style={{
                backgroundColor:
                  this.props.usuario &&
                  this.props.usuario.personalizacao?.cor_quaternaria,
              }}
            >
              <AlertaErro erros={erro} />
              <AlertaSuccess erros={success} />

              <Form>
                <FormGroup>
                  <Label for="nome">{idioma.cadastrarAvaliador.nome}</Label>
                  <Input
                    type="text"
                    value={nome}
                    autoComplete="nome"
                    onChange={(ev) => this.onChnageInput('nome', ev)}
                    name="nome"
                    id="nome"
                    placeholder={idioma.cadastrarAvaliador.nome2}
                  />
                </FormGroup>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="email">
                        {idioma.cadastrarAvaliador.email}
                      </Label>
                      <Input
                        type="email"
                        value={email}
                        autoComplete="email"
                        onChange={(ev) => this.onChnageInput('email', ev)}
                        name="email"
                        id="exampleEmail"
                        placeholder={idioma.cadastrarAvaliador.email}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="senha">
                        {idioma.cadastrarAvaliador.senha}
                      </Label>
                      <Input
                        type="password"
                        value={senha}
                        autoComplete="senha"
                        onChange={(ev) => this.onChnageInput('senha', ev)}
                        name="senha"
                        id="senha"
                        placeholder={idioma.cadastrarAvaliador.senha}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {/*select bar com as credenciais*/}
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Input
                        type="hidden"
                        value={id_perfil}
                        id="id_perfil"
                        name="id_perfil"
                      />
                      <Input
                        type="hidden"
                        value={id_desafio}
                        id="id_desafio"
                        name="id_desafio"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="idioma">
                        {idioma.cadastrarAvaliador.label_idioma}
                      </Label>
                      <Input
                        type="select"
                        onChange={(ev) =>
                          this.onChnageInput('select_idioma', ev)
                        }
                        id="idioma"
                        name="idioma"
                      >
                        <option value="portugues">
                          {idioma.cadastrarAvaliador.portugues}
                        </option>
                        {credenciais.map((credencial) =>
                          credencial.id_componente === 97 ? (
                            <option value="ingles">
                              {idioma.cadastrarAvaliador.ingles}
                            </option>
                          ) : (
                            ''
                          ),
                        )}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Link to="#" onClick={() => this.cadParticipante()}>
                  {' '}
                  <Botao
                    loading={carregando}
                    classes="botao-responsivo botao-estilo"
                    cor="success"
                    texto={idioma.cadastrarAvaliador.bt_cadastrar}
                    icone={iconSalvar}
                  />{' '}
                </Link>
                <Link onClick={() => this.props.history.goBack()}>
                  {' '}
                  <Botao
                    texto={idioma.cadastrarAvaliador.bt_cancelar}
                    classes="botao-responsivo"
                    cor="danger"
                  />{' '}
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  usuarioDetalhes: state.usuarios.usuarioDetalhes,
  usuario: state.auth.usuario,
  perfis: state.usuarios.perfis,
  pertence: state.startups.pertence,
});

export default connect(mapStateToProps, actions)(CadastrarParticipante);
