import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class EditarModulo extends Component {
    state = {
        id: "",
        id_trilha: "",
        id_modulo: "",
        nome: "",
        data_publicacao: "",
        ordem: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha, id_modulo } = this.props.match.params;
        this.setState({ id_trilha })
        this.props.getVerModulo(id_trilha, id_modulo);


        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        const { id_trilha, id_modulo } = this.props.match.params;
        if (!this.props.modulo && nextProps.modulo) this.props.getVerModulo(id_trilha, id_modulo);
        await this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparModulo()
    }

    receberDadosAPI() {
        const { id_modulo } = this.props.match.params;
        if (typeof this.props.modulo !== "undefined" && this.props.modulo !== null && this.props.modulo.id == id_modulo && !this.state.dadosAPI) {
            this.setState({ id_modulo: this.props.modulo.id })
            this.setState({ nome: this.props.modulo.nome })
            this.setState({ ordem: this.props.modulo.ordem })
            this.setState({ data_publicacao: this.props.modulo.data_publicacao })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }
    async atualizarModulo(id_modulo, nome, ordem, data_publicacao) {
        var id = id_modulo
        ////////////console.log(id)
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putModulo({ id, nome, ordem, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }


    receberDadosForm() {
        this.setState({ id_modulo: document.querySelector("#id_modulo").value })
        this.setState({ nome: document.querySelector("#nome").value })
    }
    validate() {
        const { nome, erro } = this.state;
        if (!nome) return this.setState({ erro: { message: "Preencha o nome" } });
        return true;
    }

    //deletar modulo
    deletandoModulo(id_modulo) {
        this.setState({ carregando: true })
        this.props.deletarModulo(id_modulo, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_modulo) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.editarModulo.modal_titulo,
            html: idioma.editarModulo.modal_info,
            input: 'text',
            inputPlaceholder: idioma.editarAtividade.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.editarAtividade.confirm) {
                this.deletandoModulo(id_modulo);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.editarModulo.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarModulo.modal_sucesso
        })
    }
    render() {
        const { id_modulo, id_trilha, ordem, nome, carregando, dadosAPI, erro, success, formSuccess, data_publicacao, idioma } = this.state;
        //////////////console.log(this.props)
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos",
            state: { msg: "Módulo editado com sucesso!" }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editarModulo.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosAPI} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />

                            <Form>
                                <Input type="hidden" value={id_modulo} id="id_modulo" name="id_modulo" />
                                <FormGroup>
                                    <Label for="nome">{idioma.editarAtividade.titulo}</Label>
                                    <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.editarModulo.nome_mod : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                                </FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="ordem">{idioma.editarModulo.ordem}</Label>
                                            <Input type="number" value={ordem} id="ordem" name="ordem" className="form-control" autoComplete="ordem" placeholder={dadosAPI ? idioma.editarModulo.desc_modulo : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("ordem", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="data_publicacao">{idioma.editarAtividade.data_pub}</Label>
                                            <Input type="date" value={data_publicacao} id="data_publicacao" name="data_publicacao" className="form-control" autoComplete="data_publicacao" placeholder={dadosAPI ? idioma.editarAtividade.data_pub : idioma.editarAtividade.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("data_publicacao", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Link onClick={() => this.atualizarModulo(id_modulo, nome, ordem)} to="#">
                                    <Botao loading={carregando} texto={idioma.bt_salvar} classes="botao-responsivo botao-estilo" />
                                </Link>

                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                {credenciais.map(credencial => ((credencial.id_componente == 63) ?

                                    <Link to="#" onClick={() => this.chamarAlertaDeletar(id_modulo)}>
                                        <Botao loading={carregando} texto={idioma.editarModulo.bt_deletar} classes="btn-danger btn-md ml-1" icone="trash-alt" />
                                    </Link>
                                    : ""))}
                            </Form>

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    modulo: state.trilhas.modulo,
    trilha: state.trilhas.trilha


})
export default connect(mapStateToProps, actions)(EditarModulo)