export const portugues = {
  modal_titulo: 'Digite o nome do radar:',
  bt_cancelar: 'Cancelar',
  bt_cadastrar: 'Cadastrar',
  erro: 'Erro!',
  sucesso: 'Sucesso!',
  modal_titulo2: 'Deseja deletar o radar?',
  modal_info: 'Ao fazer isso você concorda na exclusão do radar, seus pilares e perguntas </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
  modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
  bt_del: 'Deletar',
  confirm: 'CONFIRMAR',
  msg_erro: 'Mensagem de confirmação inválida!',
  bt_voltar: 'Voltar',
  nome: 'Nome',
  acoes: 'Ações',
  editar: 'Editar',
  ambientes: 'Ambientes',
  tooltip_ambientes: 'Ver ambientes',
  pilares: 'Pilares',
  titulo: 'Listagem de radares',
  cadastrarRadar: {
    titulo: 'Cadastrar radar',
    nome: 'Radar',
    nome_placeholder: 'Digite o nome do radar',
    nota_maxima: 'Peso máximo',
    nota_maxima_placeholder: 'Selecione o peso máximo do radar',
    msg_sucesso: 'Radar cadastrado com sucesso!'
  },
  editarRadar: {
    titulo: 'Editar radar',
    msg_sucesso: 'Radar editado com sucesso',
    bt_salvar: 'Salvar'
  },
  cadastrarPerguntas: {
    msg_sucesso: "Avaliação cadastrada com sucesso!",
    assunto: 'Assunto',
    assunto_per: 'Assunto da pergunta',
    tipo: 'Tipo',
    escolh_tipo: 'Escolha um tipo',
    multi: 'Múltiplo',
    aberta: 'Aberta',
    pergunta: 'Pergunta',
    info_add: 'Informações adicionais',
    opcao: 'Opções',
    opcao1: 'Opção 1',
    peso: 'Peso 1',
    entre: '(Entre 1 e',
    opcao2: 'Opção 2',
    peso2: 'Peso 2',
    opcao3: 'Opção 3',
    peso3: 'Peso 3',
    opcao4: 'Opção 4',
    peso4: 'Peso 4',
    opcao5: 'Opção 5',
    peso5: 'Peso 5',
    opcao6: 'Opção 6',
    peso6: 'Peso 6',
    opcao7: 'Opção 7',
    peso7: 'Peso 7',
    opcao8: 'Opção 8',
    peso8: 'Peso 8',
    opcao9: 'Opção 9',
    peso9: 'Peso 9',
    opcao10: 'Opção 10',
    peso10: 'Peso 10',
    bt_salvar: 'Salvar',
    carregando: 'Carregando...',
    titulo: 'Cadastrar pergunta',
    erro_peso1: 'O peso máximo da opção é de: ',
    erro_peso2: 'Verifique os campos e tente novamente!',
  },
  opcoes: {
    modal_titulo: 'Digite o nome da opção:',
    titulo: 'Listagem de opções',
    op: 'Opção',
    peso: 'Peso',
    acao: 'Ações',
    bt_editar: 'Editar'
  },
  perguntas: {
    modal_titulo: 'Deseja deletar a pergunta?',
    modal_info: 'Ao fazer isso você concorda na exclusão da pergunta e suas opções </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
    assunto: 'Assunto',
    pergunta: 'Pergunta',
    tipo: 'Tipo',
    acoes: 'Ações',
    titulo: 'Listagem de perguntas'
  },
  pgPilares: {
    modal_titulo: 'Digite o nome do pilar:',
    modal_titulo2: 'Deseja deletar o pilar?',
    modal_info: 'Ao fazer isso você concorda na exclusão do pilar e suas perguntas </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
    perguntas: 'Perguntas',
    titulo: 'Listagem de pilares'
  }
}

export const ingles = {
  modal_titulo: 'Enter the radar name:',
  bt_cancelar: 'Cancel',
  bt_cadastrar: 'Register',
  erro: 'Error!',
  sucesso: 'Success!',
  modal_titulo2: 'Do you want to delete the radar?',
  modal_info: 'By doing this you agree to the deletion of the radar, its pillars and questions </br>Type <strong>CONFIRM</strong> to carry out the deletion',
  modal_confirm: 'Type CONFIRM to perform the deletion',
  bt_del: 'Delete',
  confirm: 'CONFIRM',
  msg_erro: 'Invalid confirmation message!',
  bt_voltar: 'Back',
  nome: 'Name',
  acoes: 'Options',
  editar: 'Edit',
  pilares: 'Pillars',
  titulo: 'List of radars',
  cadastrarRadar: {
    titulo: 'Register radar',
    nome: 'Radar',
    nome_placeholder: 'Enter the radar name',
    nota_maxima: 'Maximum heft',
    nota_maxima_placeholder: 'Select the maximum radar heft',
    msg_sucesso: 'Radar successfully registered!'
  },
  editarRadar: {
    titulo: 'Edit radar',
    msg_sucesso: 'Radar successfully edited',
    bt_salvar: 'Save'
  },
  cadastrarPerguntas: {
    msg_sucesso: "Rating successfully registered!",
    assunto: 'Subject',
    assunto_per: 'Question subject',
    tipo: 'Type',
    escolh_tipo: 'Choose type',
    multi: 'Multiple',
    aberta: 'Open',
    pergunta: 'Question',
    info_add: 'Additional Information',
    opcao: 'Options',
    opcao1: 'Option 1',
    peso: 'Heft 1',
    entre: '(Between 1 and',
    opcao2: 'Option 2',
    peso2: 'Heft 2',
    opcao3: 'Option 3',
    peso3: 'Heft 3',
    opcao4: 'Option 4',
    peso4: 'Heft 4',
    opcao5: 'Option 5',
    peso5: 'Heft 5',
    opcao6: 'Option 6',
    peso6: 'Heft 6',
    opcao7: 'Option 7',
    peso7: 'Heft 7',
    opcao8: 'Option 8',
    peso8: 'Heft 8',
    opcao9: 'Option 9',
    peso9: 'Heft 9',
    opcao10: 'Option 10',
    peso10: 'Heft 10',
    bt_salvar: 'Save',
    carregando: 'Loading...',
    titulo: 'Register question',
    erro_peso1: 'The maximum weight of the option is: ',
    erro_peso2: 'Check the fields and try again!',
  },
  opcoes: {
    modal_titulo: 'Enter the option name:',
    titulo: 'List of options',
    op: 'Option',
    peso: 'Heft',
    acao: 'Actions',
    bt_editar: 'Edit'
  },
  perguntas: {
    modal_titulo: 'Do you want to delete the question?',
    modal_info: 'By doing so you agree to the deletion of the question and your options </br>Type <strong>CONFIRM</strong> to perform the deletion',
    modal_confirm: 'Type CONFIRM to perform the deletion',
    assunto: 'Subject',
    pergunta: 'Question',
    tipo: 'Type',
    acoes: 'Options',
    titulo: 'List of questions'
  },
  pgPilares: {
    modal_titulo: 'Enter the pillar name:',
    modal_titulo2: 'Do you want to delete the pillar?',
    modal_info: 'By doing so you agree to the deletion of the pillar and its questions </br>Type <strong>CONFIRM</strong> to perform the deletion',
    perguntas: 'Questions',
    titulo: 'List of pillars'
  }
}