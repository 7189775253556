import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfil';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Metodologia extends Component {
    state = {
        id_startup: "",
        id_incubadora: "",
        id_metodologia: "",
        id_metodologia_selecionada: "",
        id_processo: "",
        pertence: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup, id_incubadora } = this.props.match.params;
        this.setState({ id_startup })
        this.setState({ id_incubadora: id_incubadora })
        this.listarMetodologiasStartup(id_startup);
        this.listarMetodologias();
        this.listarStartup(id_startup)
        if (this.props.usuario && this.props.credenciais) this.handlePertence(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }

    componentWillUnmount() {
        this.props.limparMetodologias();
        this.props.limparIncubadoras();
        this.props.limparStartup()
    }


    chamarAlertaParticipante(listaUsuarios) {
        const { idioma } = this.state
        var lista = [];
        var listaUsuarios = [];
        for (var i in listaUsuarios) {
            listaUsuarios = listaUsuarios[i].usuarios.nome
        }

        Swal.fire({
            title: idioma.editarMetodologia.modal_titulo,
            input: 'select',
            inputOptions: lista,

            inputPlaceholder: idioma.editarMetodologia.modal_info,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.participantes.bt_cancelar,
            confirmButtonText: idioma.editarMetodologia.modal_confirm
        })
        return new Promise((resolve) => {
            resolve()
        })
    }

    componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;
        if (!this.props.listaMetodologias && nextProps.listaMetodologias) {
            this.listarMetodologiasStartup(id_startup);
            this.listarMetodologias();
        }
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.metodologias && nextProps.metodologias) {
            this.listarStartup(id_startup);
        }
        this.receberDadosAPI();
    }


    receberDadosAPI() {
        if (typeof this.props.listaMetodologias != "undefined" && this.props.listaMetodologias != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.metodologias != "undefined" && this.props.metodologias != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    listarMetodologiasStartup(id_startup) {
        this.props.getMetodologiasStartup(id_startup);
        const { metodologias } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (metodologias === "undefined") return null
    }

    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }

    listarMetodologias() {
        this.props.getMetodologias();
        const { todasMetodologias } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (todasMetodologias === "undefined") return null
    }

    listarUsuarios() {
        this.props.getUsuarios();
        const { usuarios } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (usuarios === "undefined") return null
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })

    }

    handleSelectedMetodology(campo, ev) {
        this.setState({ [campo]: ev.target.value })
        this.setState({ id_metodologia_selecionada: ev.target.value })
    }

    vincularMetodologia() {
        const { id_metodologia, id_startup, id_processo } = this.state;
        this.setState({ carregando: true })
        this.props.postVincularMetodologia({ id_metodologia, id_startup, id_processo }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    handlePertence(usuario, credenciais, id_startup, id_incubadora) { 
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }
        if (usuario.incubadoras.length > 0) {
            for (let i in usuario.incubadoras) {
                if (usuario.incubadoras[i].id_incubadora == id_incubadora) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (usuario.startups.length > 0) {
            for (let s in usuario.startups) {
                if (usuario.startups[s].id_startup == id_startup) {
                    this.setState({ pertence: true })
                }
            }
        }
    }

    render() {
        const { msg, carregando, pertence, erro, success, dadosApi, id_metodologia_selecionada, idioma } = this.state;
        var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var metodologias = [];
        if (this.props.metodologias) metodologias = this.props.metodologias.dados;
        var listaMetodologias = [];
        if (this.props.listaMetodologias) listaMetodologias = this.props.listaMetodologias.dados;
        var listaUsuarios = [];
        if (this.props.listaUsuarios) listaUsuarios = this.props.listaUsuarios.dados;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editarMetodologia.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        pertence &&
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>

                            <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <Link to={""} > <Botao texto={idioma.editarMetodologia.bt_editar} icone="cogs" posicao="E" cor={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />

                                    {dadosApi ?
                                        pertence ?
                                            <div>
                                                <Form>
                                                    <Row form>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="metodologia">{idioma.editarMetodologia.vincular}</Label>
                                                                <Input type="select" onChange={(ev) => this.handleSelectedMetodology("id_metodologia", ev)} id="id_metodologia" name="id_metodologia">
                                                                    <option>{idioma.editarMetodologia.escolher}</option>
                                                                    {listaMetodologias.map(metodologias => (
                                                                        <option value={metodologias.metodologia.id} key={metodologias.id}>
                                                                            {metodologias.metodologia.nome}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={6}>
                                                            <FormGroup>
                                                                <Label for="processo">{idioma.editarMetodologia.vincular2}</Label>
                                                                <Input type="select" onChange={(ev) => this.onChnageInput("id_processo", ev)} id="id_processo" name="id_processo">
                                                                    <option>{idioma.editarMetodologia.escolher2}</option>
                                                                    {listaMetodologias.map(dados => (
                                                                        dados.metodologia.id == id_metodologia_selecionada ?
                                                                            dados.processos.map(processos => (
                                                                                <option value={processos.id} key={processos.id}>
                                                                                    {processos.nome}
                                                                                </option>
                                                                            ))
                                                                            : ""
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/acompanhamento/"} onClick={() => this.vincularMetodologia()}> <Botao loading={carregando} texto={idioma.editarMetodologia.bt_vincular2} cor="success" /> </Link>
                                                </Form>
                                            </div>
                                            : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.participantes.erro_acesso} />
                                        : <Form>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={150} />

                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={600} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={150} />

                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={600} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Link to="#" onClick={() => this.vincularMetodologia()}> <Botao loading={carregando} texto={idioma.editarMetodologia.bt_vincular2} cor="success" /> </Link>
                                        </Form>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    metodologias: state.startups.metodologias,
    startup: state.startups.startup,
    listaMetodologias: state.startups.todasMetodologias,
    usuarios: state.usuarios.usuarios
})
export default connect(mapStatetoProps, actions)(Metodologia);