import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/radares';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import BotaoIcon from '../../../componentes/BotaoIcon';
import AlertaSuccess from '../../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input, Button, Table } from 'reactstrap';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Toast from '../../../componentes/Alertas/Toast';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaradar_bilingue';

class ListarTrilhas extends Component {
    state = {
        currentPage: 1,
        size: 20,
        trilha: "",
        msg: "",
        id_radar: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        const { id_radar } = this.props.match.params
        this.setState({ id_radar })
        this.listarPilares(id_radar)

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id_radar } = this.props.match.params
        if (!this.props.pilares && nextProps.pilares) this.listarPilares(id_radar);
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparPilares()
    }
    receberDadosAPI() {
        if (typeof this.props.pilares != "undefined" && this.props.pilares != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarRadares();
        })
    }

    chamarCad() {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.pgPilares.modal_titulo,
            input: 'text',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.bt_cadastrar
        }).then((result) => {
            if (result.value) {
                this.cadastrarPilares(result.value);
            }
        })
    }

    cadastrarPilares(nome) {
        const { id_radar, msg_backend, idioma } = this.state
        let id_radarmetodologia = id_radar
        this.setState({ carregando: true })
        this.props.postPilar({ id_radarmetodologia, nome }, (msg) => {
            if (msg.erro.error) {
                Toast({ icone: 'error', titulo: idioma.erro, texto: msg_backend[msg.erro.code] })
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                Toast({ icone: 'success', titulo: idioma.sucesso, texto: msg_backend[msg.erro.code] })
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.listarPilares(id_radarmetodologia)

            }
        })
    }

    chamarEdit(id_pilar, nome) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.pgPilares.modal_titulo,
            input: 'text',
            inputPlaceholder: nome,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.bt_cadastrar
        }).then((result) => {
            if (result.value) {
                this.editarPilar(id_pilar, result.value);
            }
        })
    }

    editarPilar(id, nome) {
        const { id_radar, msg_backend, idioma } = this.state
        let id_radarmetodologia = id_radar
        this.setState({ carregando: true })
        this.props.putPilar({ id, id_radarmetodologia, nome }, (msg) => {
            if (msg.erro.error) {
                Toast({ icone: 'error', titulo: idioma.erro, texto: msg_backend[msg.erro.code] })
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                Toast({ icone: 'success', titulo: idioma.sucesso, texto: msg_backend[msg.erro.code] })
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.listarPilares(id_radarmetodologia)

            }
        })
    }

    listarPilares(id_radarmetodologia) {
        this.props.getPilares(id_radarmetodologia)
        const { pilares } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pilares === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    //deletar 
    deletar(id_pilar) {
        const { msg_backend, idioma } = this.state
        const { id_radar } = this.props.match.params
        this.setState({ carregando: true })
        this.props.deletarPilar(id_pilar, (msg) => {
            if (msg.erro.error) {
                Toast({ icon: 'error', title: idioma.erro, text: msg_backend[msg.erro.code] })
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                Toast({ icon: 'success', title: idioma.sucesso, text: msg_backend[msg.erro.code] })
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.props.limparPilares()
                this.listarPilares(id_radar)


            }
        })
    }

    chamarDeletar(id_pilar) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.pgPilares.modal_titulo2,
            html: idioma.pgPilares.modal_info,
            input: 'text',
            inputPlaceholder: idioma.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_del,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.confirm) {
                this.deletar(id_pilar);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.msg_erro
                })
            }
        })
    }


    render() {
        const { trilha, msg, carregando, erro, success, dadosApi, formSuccess, idioma } = this.state;
        const { credenciais, usuario } = this.props

        var pilares = [];
        if (this.props.pilares) pilares = this.props.pilares.dados;

        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.pgPilares.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/radar"} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <Link onClick={() => this.chamarCad()} className="pr-2"> <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" cor="success" /> </Link>
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.nome}</th>
                                                            <th className="coluna-tabela">{idioma.acoes}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {pilares.map(pilar => (
                                                            <tr key={pilar.id}>

                                                                <td>{pilar.nome}</td>
                                                                <td>
                                                                    <a data-tip data-for='perguntas'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/radar/" + pilar.id_radarmetodologia + "/pilar/" + pilar.id + "/perguntas"} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn btn-sm ml-1" icone="eye" color={this.props.usuario?.personalizacao.cor_terciaria} />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='editar'>
                                                                        <Link to="#" onClick={() => this.chamarEdit(pilar.id, pilar.nome)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn btn-sm ml-1" icone="edit" color={this.props.usuario?.personalizacao.cor_terciaria} />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='deletar'>
                                                                        <Link to="#" onClick={() => this.chamarDeletar(pilar.id)} className="mr-2">
                                                                            <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    <ReactTooltip id='perguntas' type='dark'>
                                                                        <span>{idioma.pgPilares.perguntas}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='deletar' type='error'>
                                                                        <span>{idioma.bt_del}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' type='dark'>
                                                                        <span>{idioma.editar}</span>
                                                                    </ReactTooltip>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    pilares: state.radares.pilares,

})
export default connect(mapStatetoProps, actions)(ListarTrilhas);