import axios from 'axios';
import { GET_CONTEUDOS, GET_CONTEUDO, GET_SEARCH_CONTENT, GET_TIPO_CONTEUDO, GET_USERS, LIMPAR_CONTEUDOS, LIMPAR_CONTEUDO } from './types'
import { api } from '../../config';
import { getHeaders, clearToken, getHeadersPost } from './localStorage';
import errorHandle from './errorHandle';

export const getConteudos = (id_tipo, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/conteudos/buscar?page=${paginaAtual}&size=${limite}&tipo=${id_tipo}`, getHeaders())
            .then((response) => {
                ////////console.log(response)
                dispatch({ type: GET_CONTEUDOS, payload: response })
            })
            .catch(errorHandle)
    }
}

export const postConteudo = (dadosConteudo, callback) => {
    return function (dispatch) {
        axios.post(api + `/conteudo/`, dadosConteudo, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getTipoConteudo = () => {
    return function (dispatch) {
        axios.get(api + `/conteudotipos`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_TIPO_CONTEUDO, payload: response })
            })
            .catch(errorHandle)
    }
}

export const putConteudo = (dadosConteudo, callback) => {
    return function (dispatch) {
        axios.put(api + `/conteudo/editar`, dadosConteudo, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const putConteudoImagem = (dadosConteudo, callback) => {
    return function (dispatch) {
        axios.put(api + `/conteudo/editar/imagem`, dadosConteudo, getHeadersPost())
            .then((response) => {
                ////////console.log(response)
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getVerConteudo = (id) => {
    return function (dispatch) {
        axios.get(api + `/conteudo/${id}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_CONTEUDO, payload: response.data })
            })
            .catch(errorHandle)
    }
}

export const deletarConteudo = (id_conteudo, callback) => {
    return function (dispatch) {
        axios.delete(api + `/conteudo/${id_conteudo}`, getHeaders())
            .then((response) => {
                callback({ erro: response.data });
            })
            .catch((err) => callback(errorHandle(err)));
    }
}

export const getSearchContent = (pesquisa, id_tipo, paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/conteudos/buscar/pesquisa?pesquisa=${pesquisa}&tipo=${id_tipo}&page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
              
                dispatch({ type: GET_SEARCH_CONTENT, payload: response })
            })
            .catch(errorHandle)
    }
}

export const getUsuarios = (paginaAtual, limite) => {
    return function (dispatch) {
        axios.get(api + `/usuarios?page=${paginaAtual}&size=${limite}`, getHeaders())
            .then((response) => {
                dispatch({ type: GET_USERS, payload: response })
            })
            .catch(errorHandle)
    }
}

// Actions de limpar

export const limparConteudos = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_CONTEUDOS })
    }
}

export const limparConteudo = () => {
    return function (dispatch) {
        dispatch({ type: LIMPAR_CONTEUDO })
    }
}