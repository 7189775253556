import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/conteudos';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import minhasStartups from '../../paginas/Startup/minhasStartups';
import { min } from 'date-fns';

import { ingles, portugues } from '../../utils/blocoPerfilConteudo_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class PerfilConteudo extends Component {
    state = {
        id_usuario: "",
        dadosAPI: false,
        path: "",
        credenciais: "",
        conteudo: "",
        usuario: "",
        currentPage: 1,
        size: 20,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        this.props.getUsuarios();

        this.receberDadosAPI();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.usuarios && nextProps.usuarios) {
            this.props.getUsuarios();
        }
        this.receberDadosAPI();
    }

    receberDadosAPI() {
        if (typeof this.props.credenciais != "undefined" && this.props.credenciais != null && !this.state.dadosAPI) {
            this.setState({ dadosAPI: true });
        }
        if (typeof this.props.usuarios != "undefined" && this.props.usuarios != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    // listarTrilha(id_trilha) {
    //     this.props.getVerTrilha(id_trilha);
    //     const { trilha } = this.props;
    //     if (this.props.location.state) {

    //         this.setState({ msg: this.props.location.state.msg })
    //         this.props.location.state.msg = "";
    //     }
    //     if (trilha === "undefined") return null
    // }




    //deletar incubadora
    deletandoConteudo(id_conteudo) {
        this.setState({ carregando: true })
        this.props.deletarConteudo(id_conteudo, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_conteudo) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_titulo,
            html: idioma.modal_info,
            input: 'text',
            inputPlaceholder: idioma.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value == idioma.confirm) {
                this.deletandoConteudo(id_conteudo);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }

    render() {
        let { usuario, conteudo, dadosApi, credenciais, path, formSuccess, idioma } = this.state;
        if (this.props.credenciais) {
            credenciais = this.props.credenciais;
        }
        if (this.props.usuario) usuario = this.props.usuario;

        if (this.props.conteudo) conteudo = this.props.conteudo;
        if (this.props.dadosApi) dadosApi = this.props.dadosApi;

        var usuarios = [];
        if (this.props.usuarios) usuarios = this.props.usuarios.dados;


        if (formSuccess) return <Redirect to={{
            pathname: "/conteudos",
        }}></Redirect>
        //////console.log(usuarios, conteudo)
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="list-group-item" >
                        {
                            dadosApi ?
                                <div>
                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <h2 className="">{conteudo ? conteudo.titulo : ""}</h2>
                                        </div>
                                        <div className="ml-3">
                                            {conteudo.id_tipo == 3 ?
                                                usuarios.map(usuario => (usuario.id == conteudo.id_usuario) ?
                                                    <div className=" text-justify mt-3">
                                                        {idioma.criado}
                                                        <strong className="ml-1">{usuario.nome}</strong>
                                                    </div>
                                                    : "")
                                                : ""}
                                            {conteudo.fonte &&
                                                <div>
                                                    {idioma.acesso} <a href={conteudo.fonte} className="ml-1" target="_blank" rel="noreferrer">{conteudo.fonte ? conteudo.fonte : ""}</a>
                                                </div>}

                                        </div>

                                    </div>
                                </div>
                                : <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>}
                    </div>
                </div>
                {dadosApi ?
                    usuario.id == conteudo.id_usuario ?
                        <div className="col-md-12 mb-3">
                            <div className="list-group-item" >

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-fluid">
                                            <div className="row">
                                                < div className={path == "/conteudos/:id_conteudo/editar" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/conteudos/" + conteudo.id + "/editar"}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                            {idioma.bt_editar}
                                                        </span>
                                                    </Link>
                                                </div>
                                                < div className={path == "/conteudos/:id_conteudo/editar-capa" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/conteudos/" + conteudo.id + "/editar-capa/"}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="camera" size="1x" color="black" className="mr-3" />
                                                            {idioma.bt_editar_capa}
                                                        </span>
                                                    </Link>
                                                </div>
                                                < div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                    <Link to="#" onClick={() => this.chamarAlertaDeletar(conteudo.id)}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="trash-alt" size="1x" color="red" className="mr-3" />
                                                            {idioma.bt_deletar}
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div >
                                        </div>
                                    </div >

                                </div >
                            </div>
                        </div>

                        :
                        credenciais.length > 0 ?
                            (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 20) ?

                                <div className="col-md-12 mb-3">
                                    <div className="list-group-item" >

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        < div className={path == "/conteudos/:id_conteudo/editar" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/conteudos/" + conteudo.id + "/editar"}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                                    {idioma.bt_editar}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                        < div className="col-auto menu-cabecalho">
                                                            <Link to="#" onClick={() => this.chamarAlertaDeletar(conteudo.id)}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="trash-alt" size="1x" color="red" className="mr-3" />
                                                                    {idioma.bt_deletar}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div >
                                                </div>
                                            </div >

                                        </div >
                                    </div>
                                </div>
                                : ""
                            : ""
                    :
                    <div className="col-md-12 mb-3">
                        <div className="list-group-item" >
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            < div className="col-auto menu-cabecalho">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho active">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho active">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho" >
                                                <Skeleton count={1} width={80} />
                                            </div>
                                        </div >
                                    </div >

                                </div >
                            </div>
                        </div>
                    </div >
                }
            </>
        )
    }
}
const mapStatetoProps = state => ({
    conteudo: state.conteudos.conteudo,
    usuarios: state.conteudos.usuarios,


})
export default connect(mapStatetoProps, actions)(PerfilConteudo);