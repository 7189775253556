import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfilIncubadora';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner, Table, Input } from 'reactstrap';
// import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg';
import { format, differenceInDays } from 'date-fns';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SemDados from '../../componentes/Mensagens/semDados';

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';

class VincularUsuario extends Component {
  state = {
    id_incubadora: '',
    currentPage: 1,
    size: 20,
    msg: '',
    erro: '',
    success: '',
    carregando: false,
    pesquisando: false,
    pertence: false,
    procura: '',
    modal: false,
    dadosApi: false,
    idioma: portugues,
    masg_backend: portuguese,
  };
  componentDidMount() {
    const { id_incubadora } = this.props.match.params;
    this.setState({ id_incubadora });
    this.listarUsuarios();
    this.listarIncubadora(id_incubadora);
    if (this.props.usuario && this.props.incubadora && this.props.credenciais) {
      this.verificaIncubadora(
        this.props.usuario,
        this.props.credenciais,
        id_incubadora,
      );
    }
    this.verificaPertence(this.props.usuario.id, id_incubadora);

    const { usuario } = this.props;
    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }
  componentDidUpdate(nextProps) {
    const { id_incubadora } = this.props.match.params;
    if (!this.props.futurosUsuarios && nextProps.futurosUsuarios)
      this.listarUsuarios();
    if (!this.props.incubadora && nextProps.incubadora) {
      this.listarIncubadora(id_incubadora);
    }
    if (!this.props.pertence && nextProps.pertence) {
      this.verificaPertence(this.props.usuario.id, id_incubadora);
    }

    this.receberDadosAPI();
  }
  componentWillUnmount() {
    this.props.limparIncubadora();
    this.props.limparFuturosUsuarios();
  }
  receberDadosAPI() {
    if (
      typeof this.props.futurosUsuarios != 'undefined' &&
      this.props.futurosUsuarios != null &&
      !this.state.dadosApi &&
      this.props.futurosUsuarios.currentPage === this.state.currentPage
    ) {
      this.setState({ dadosApi: true });
    }
    if (
      typeof this.props.incubadora != 'undefined' &&
      this.props.incubadora != null &&
      !this.state.dadosApi
    ) {
      if (
        typeof this.props.pertence != 'undefined' &&
        this.props.pertence != null &&
        !this.state.dadosApi
      ) {
        this.setState({ dadosApi: true });
      }
    }
  }

  listarIncubadora(id_incubadora) {
    this.props.getVerIncubadora(id_incubadora);
    const { incubadora } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (incubadora === 'undefined') return null;
  }
  usuarioNovo(data) {
    var result = differenceInDays(new Date(), data);
  }
  listarUsuarios() {
    const { currentPage, size } = this.state;
    this.props.getFuturosUsuarios(currentPage, size);
    const { futurosUsuarios } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (futurosUsuarios === 'undefined') return null;
  }
  changePageAtual(currentPage) {
    this.props.limparUsuarios();
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.listarUsuarios();
    });
  }

  changePageAtualSearch(currentPage) {
    const { procura, size } = this.state;
    this.props.limparUsuarios();
    this.setState({ currentPage }, () => {
      this.setState({ dadosApi: false });
      this.props.getSearch(procura, currentPage, size);
    });
  }

  apagarusuario(id_incubadora) {
    this.props.limparUsuarios();
    this.setState({ dadosApi: false });
    this.setState({ carregando: true });
    this.props.deletarUsuarioIncubadora(id_incubadora, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ carregando: false });
        this.listarUsuarios();
        this.sucessoAlerta();
        // this.setState({ formSuccess: true });
      }
    });
  }

  vincularUsuario(id_usuario) {
    const { id_incubadora, idioma } = this.state;
    this.setState({ carregando: true });
    ////////////console.log(id_usuario, id_incubadora)
    this.props.postVincularUsuario({ id_usuario, id_incubadora }, (msg) => {
      if (msg.erro.error) {
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        this.setState({
          success: { message: idioma.vincularusuario.msg_sucesso },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.sucessoAlerta();
      }
    });
  }

  chamarAlerta(id) {
    const { idioma } = this.state;
    Swal.fire({
      title: idioma.modal_titulo,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: idioma.bt_cancelar,
      confirmButtonText: idioma.vincularusuario.modal_confirm,
    }).then((result) => {
      if (result.value) {
        this.vincularUsuario(id);
      }
    });
  }
  sucessoAlerta() {
    const { idioma } = this.state;
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: 'success',
      title: idioma.vincularusuario.msg_sucesso,
    });
  }

  handleSearch(procura) {
    this.setState({ procura: procura });
    this.setState({ pesquisando: true });
    // this.props.limparUsuarios()
    const { currentPage, size } = this.state;
    this.setState({ currentPage: 1 });
    this.props.getSearch(procura, currentPage, size);
  }

  eraseSearch(campo) {
    this.setState({ pesquisando: false });
    this.setState({ [campo]: '' });
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value });
  }

  handleEnter(ev) {
    const { procura } = this.state;
    if (ev.key == 'Enter') {
      this.handleSearch(procura);
    }
  }
  verificaIncubadora(usuario, credenciais, id_incubadora) {
    if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
      this.setState({ pertence: true });
    }
    for (let i in usuario.incubadoras) {
      if (usuario.incubadoras[i].id_incubadora == id_incubadora) {
        this.setState({ pertence: true });
      }
    }
  }

  verificaPertence(id_usuario, id_incubadora) {
    this.props.getVerificarPertence(id_usuario, 0, id_incubadora);
    const { pertence } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (pertence === 'undefined') return null;
    // console.log(pertence)
  }

  render() {
    const {
      id_incubadora,
      pertence,
      msg,
      carregando,
      erro,
      success,
      dadosApi,
      formSuccess,
      pesquisando,
      procura,
      idioma,
    } = this.state;

    var futurosUsuarios = [];
    if (pesquisando == false) {
      if (this.props.futurosUsuarios)
        futurosUsuarios = this.props.futurosUsuarios.dados;
      var temPaginaAnterior = false;
      if (
        typeof this.props.futurosUsuarios != 'undefined' &&
        this.props.futurosUsuarios != null &&
        this.props.futurosUsuarios.currentPage !== '' &&
        this.props.futurosUsuarios.currentPage !== 1
      ) {
        temPaginaAnterior = true;
      }
      var proximaPagina = false;
      var temProximaPagina = false;
      if (
        typeof this.props.futurosUsuarios != 'undefined' &&
        this.props.futurosUsuarios !== null &&
        this.props.futurosUsuarios.nextPage <=
        this.props.futurosUsuarios.totalPages &&
        this.props.futurosUsuarios.nextPage != null
      ) {
        proximaPagina = true;
        temProximaPagina = true;
      }
    } else {
      if (this.props.search) futurosUsuarios = this.props.search.dados;
      var temPaginaAnteriorSearch = false;
      if (
        typeof this.props.search != 'undefined' &&
        this.props.search != null &&
        this.props.search.currentPage !== '' &&
        this.props.search.currentPage !== 1
      ) {
        temPaginaAnteriorSearch = true;
      }
      var proximaPaginaSearch = false;
      var temProximaPaginaSearch = false;
      if (
        typeof this.props.search != 'undefined' &&
        this.props.search !== null &&
        this.props.search.nextPage <= this.props.search.totalPages &&
        this.props.search.nextPage != null
      ) {
        proximaPaginaSearch = true;
        temProximaPaginaSearch = true;
      }
    }

    var usuarioPertence = [];
    if (this.props.pertence) usuarioPertence = this.props.pertence;
    var incubadora = null;
    if (this.props.incubadora) incubadora = this.props.incubadora;

    var usuario = null;
    if (this.props.usuario) usuario = this.props.usuario;

    var credenciais = [];
    if (this.props.credenciais) credenciais = this.props.credenciais;
    var path = this.props.match.path;
    if (formSuccess)
      return (
        <Redirect
          to={{
            pathname: '/ecossistema/incubadora/' + id_incubadora + '/usuarios',
            state: { msg: idioma.vincularusuario.msg_sucesso },
          }}
        ></Redirect>
      );
    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario.personalizacao.cor_principal,
          }}
        >
          <Titulo titulo={idioma.vincularusuario.titulo} />
        </div>
        <div className="container-fluid bloco-topo">
          {incubadora != null && usuarioPertence.pertence && usuario != null ? (
            <BlocoPerfil
              dadosApi={dadosApi}
              incubadora={incubadora}
              credenciais={credenciais}
              usuario={usuario}
              path={path}
            />
          ) : (
            <div className="col-md-12 mb-2">
              <div
                className="list-group-item"
                style={{
                  backgroundColor:
                    this.props.usuario &&
                    this.props.usuario.personalizacao?.cor_quaternaria,
                }}
              >
                <div className="row">
                  <div className="col-auto centralizado">
                    <div className="img-perfil">
                      <Skeleton circle={true} height={120} width={120} />
                    </div>
                  </div>
                  <div className="col-md-9 centralizado texto-centralizado">
                    <h2 className="nomeperfil">
                      <Skeleton count={1} width={160} />
                    </h2>
                    <p className="text-muted">
                      <Skeleton count={1} width={160} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 mb-2">
            <div
              className="list-group-item"
              style={{
                backgroundColor:
                  this.props.usuario &&
                  this.props.usuario.personalizacao?.cor_quaternaria,
              }}
            >
              <div className="row mt-3 mb-3">
                <div className="col-lg-8">
                  <Input
                    type="text"
                    value={procura}
                    onKeyPress={(ev) => this.handleEnter(ev)}
                    autoComplete="procura"
                    onChange={(ev) => this.onChangeInput('procura', ev)}
                    name="procura"
                    id="procura"
                    placeholder={idioma.vincularusuario.pesquisar}
                  />
                </div>
                <div className="col-lg-4">
                  <div>
                    <span
                      className="pr-3"
                      onClick={() => this.handleSearch(procura)}
                      id="pesquisa"
                      name="pesquisa"
                    >
                      <Botao
                        loading={carregando}
                        classes="botao-pesquisa-responsivo"
                        color={
                          this.props.usuario?.personalizacao?.cor_terciaria
                        }
                        texto={idioma.vincularusuario.bt_pesquisar}
                        icone="search"
                        posicao="E"
                      />
                    </span>
                    <span
                      value={procura}
                      onClick={() => this.eraseSearch('procura')}
                      id="pesquisa"
                      name="pesquisa"
                    >
                      <Botao
                        loading={carregando}
                        classes="botao-pesquisa-responsivo"
                        cor="danger"
                        texto={idioma.bt_cancelar}
                        icone="times"
                        posicao="E"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-md-12 p-2">
                  {msg ? <AlertaSuccess erros={{ message: msg }} /> : ''}
                  <AlertaErro erros={erro} />
                  <AlertaSuccess erros={success} />
                  {dadosApi ? (
                    usuarioPertence.pertence ? (
                      <div className="row table-responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th>{idioma.usuarios.nome}</th>
                              <th>{idioma.menu1}</th>
                              <th>{idioma.usuarios.negocio}</th>
                              <th>{idioma.vincularusuario.acao}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {futurosUsuarios.map((usuario) => (
                              <tr key={usuario.id}>
                                <td>{usuario.nome}</td>
                                <td>
                                  {usuario.incubadoras.length > 0 ? (
                                    usuario.incubadoras.length == 1 ? (
                                      usuario.incubadoras.map(
                                        (incubadora) => incubadora,
                                      )
                                    ) : (
                                      usuario.incubadoras.map(
                                        (incubadora) => incubadora + ', ',
                                      )
                                    )
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      {' '}
                                      <FontAwesomeIcon
                                        icon="times"
                                        size="lg"
                                      />{' '}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {usuario.startups.length > 0 ? (
                                    usuario.startups.length == 1 ? (
                                      usuario.startups.map((startup) => startup)
                                    ) : (
                                      usuario.startups.map(
                                        (startup) => startup + ', ',
                                      )
                                    )
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      {' '}
                                      <FontAwesomeIcon
                                        icon="times"
                                        size="lg"
                                      />{' '}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <span
                                    className="pr-3"
                                    onClick={() =>
                                      this.chamarAlerta(usuario.id)
                                    }
                                    id="pesquisa"
                                    name="pesquisa"
                                  >
                                    <Botao
                                      loading={carregando}
                                      color={
                                        this.props.usuario?.personalizacao
                                          ?.cor_terciaria
                                      }
                                      texto={idioma.vincularusuario.bt_vincular}
                                      icone="sign-in-alt"
                                      posicao="E"
                                    />
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <SemDados
                        subtitulo={idioma.sem_dados_sub}
                        titulo={idioma.cadastrarStartup.erro_acesso}
                      />
                    )
                  ) : (
                    <div className="row table-responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th>
                              <Skeleton count={1} width={80} />
                            </th>
                            <th>
                              <Skeleton count={1} width={80} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Skeleton count={1} width={80} />
                            </td>
                            <td>
                              <Skeleton count={1} width={60} />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  )}
                  {dadosApi ? (
                    pesquisando == false ? (
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          <li
                            className={
                              temPaginaAnterior
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() => this.changePageAtual(1)}
                            >
                              {idioma.page1}
                            </span>
                          </li>

                          {temPaginaAnterior ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtual(
                                    this.props.futurosUsuarios.prevPage
                                      ? this.props.futurosUsuarios.prevPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.futurosUsuarios.prevPage
                                  ? this.props.futurosUsuarios.prevPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className="page-item active"
                            style={{
                              backgroundColor:
                                usuario?.personalizacao?.cor_principal,
                            }}
                          >
                            <span className="page-link cursor-pointer">
                              {this.props.futurosUsuarios
                                ? this.props.futurosUsuarios.currentPage
                                : '1'}
                            </span>
                          </li>

                          {proximaPagina ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtual(
                                    this.props.futurosUsuarios.nextPage
                                      ? this.props.futurosUsuarios.nextPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.futurosUsuarios.nextPage
                                  ? this.props.futurosUsuarios.nextPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className={
                              temProximaPagina
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() =>
                                this.changePageAtual(
                                  this.props.futurosUsuarios
                                    ? this.props.futurosUsuarios.totalPages
                                    : 0,
                                )
                              }
                            >
                              {idioma.page2}
                            </span>
                          </li>
                        </ul>
                      </nav>
                    ) : (
                      <nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                          <li
                            className={
                              temPaginaAnteriorSearch
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() => this.changePageAtualSearch(1)}
                            >
                              {idioma.page1}
                            </span>
                          </li>

                          {temPaginaAnteriorSearch ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtualSearch(
                                    this.props.search.prevPage
                                      ? this.props.search.prevPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.search.prevPage
                                  ? this.props.search.prevPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className="page-item active"
                            style={{
                              backgroundColor:
                                usuario?.personalizacao?.cor_principal,
                            }}
                          >
                            <span className="page-link cursor-pointer">
                              {this.props.search
                                ? this.props.search.currentPage
                                : '1'}
                            </span>
                          </li>

                          {proximaPaginaSearch ? (
                            <li className="page-item">
                              <span
                                className="page-link cursor-pointer"
                                onClick={() =>
                                  this.changePageAtualSearch(
                                    this.props.search.nextPage
                                      ? this.props.search.nextPage
                                      : 1,
                                  )
                                }
                              >
                                {this.props.search.nextPage
                                  ? this.props.search.nextPage
                                  : ''}
                              </span>
                            </li>
                          ) : (
                            ''
                          )}

                          <li
                            className={
                              temProximaPaginaSearch
                                ? 'page-item'
                                : 'page-item disabled'
                            }
                          >
                            <span
                              className="page-link cursor-pointer"
                              onClick={() =>
                                this.changePageAtualSearch(
                                  this.props.search
                                    ? this.props.search.totalPages
                                    : 0,
                                )
                              }
                            >
                              {idioma.page2}
                            </span>
                          </li>
                        </ul>
                      </nav>
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStatetoProps = (state) => ({
  futurosUsuarios: state.incubadoras.futurosUsuarios,
  incubadora: state.incubadoras.incubadora,
  search: state.startups.search,
  pertence: state.incubadoras.pertence,
});
export default connect(mapStatetoProps, actions)(VincularUsuario);
