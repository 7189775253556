import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/eventos';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import minhasStartups from '../../paginas/Startup/minhasStartups';
import { min } from 'date-fns';
import { format, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { portugues, ingles } from '../../utils/blocoPerfilEvento_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class PerfilEvento extends Component {
    state = {
        id_usuario: "",
        dadosAPI: false,
        path: "",
        evento: "",
        usuario: "",
        currentPage: 1,
        size: 20,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {

        // if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }
        // this.listarTrilha();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    // componentDidUpdate(nextProps) {
    //     if (!this.props.trilha && nextProps.trilha) this.listarTrilha();
    //     this.receberDadosAPI();
    // }

    // receberDadosAPI() {
    //     if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi && this.props.trilha.currentPage === this.state.currentPage) {
    //         this.setState({ dadosApi: true });
    //     }
    // }

    // listarTrilha(id_trilha) {
    //     this.props.getVerTrilha(id_trilha);
    //     const { trilha } = this.props;
    //     if (this.props.location.state) {

    //         this.setState({ msg: this.props.location.state.msg })
    //         this.props.location.state.msg = "";
    //     }
    //     if (trilha === "undefined") return null
    // }



    //deletar incubadora
    deletandoEvento(id_evento) {
        this.setState({ carregando: true })
        this.props.deletarEvento(id_evento, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_evento) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_titulo,
            html: idioma.modal_info,
            input: 'text',
            inputPlaceholder: idioma.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.confirm) {
                this.deletandoEvento(id_evento);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }

    render() {
        let { usuario, evento, dadosApi, path, formSuccess, idioma } = this.state;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.usuario) usuario = this.props.usuario;

        if (this.props.evento) evento = this.props.evento;
        if (this.props.dadosApi) dadosApi = this.props.dadosApi;
        let eventoCerto = new Date(evento.data)
        eventoCerto = addDays(eventoCerto, 1)
        let eventoCertoFim = null
        if (evento.data_fim != null) {
            eventoCertoFim = new Date(evento.data_fim)
            eventoCertoFim = addDays(eventoCertoFim, 1)
        }

        if (formSuccess) return <Redirect to={{
            pathname: "/eventos",
        }}></Redirect>
        // console.log(eventoCertoFim)
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="list-group-item" >
                        {
                            dadosApi ?
                                <div>
                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <h2 className="">{evento ? evento.nome : ""}</h2>
                                        </div>
                                        <div className="ml-3">
                                            {evento.data_fim == evento.data || evento.data_fim == null ?
                                                <>
                                                    <span className="text-muted ml-2 mr-1">{idioma.dia}</span>
                                                    <span className="text-perfil">{evento.data ? format(new Date(eventoCerto), 'dd/MM/yyyy', { locale: pt }) : ""},  </span>
                                                </>
                                                :
                                                <>
                                                    <span className="text-muted ml-2 mr-1">{idioma.de}</span>
                                                    <span className="text-perfil">{evento.data ? format(new Date(eventoCerto), 'dd/MM/yyyy', { locale: pt }) : ""} </span>
                                                    <span className="text-muted ml-1 mr-1">{idioma.ate}</span>
                                                    <span className="text-perfil">{evento.data_fim ? format(new Date(eventoCertoFim), 'dd/MM/yyyy', { locale: pt }) : ""},  </span>
                                                </>
                                            }
                                            <span className="text-muted mr-1">{idioma.criado}</span>
                                            <span className="text-perfil ">{evento.responsavel}, </span>
                                            <span className="text-perfil"> {evento.cidade} </span>
                                        </div>
                                        <div className="col-md-12 ">
                                            <span className="text-muted ml-2">{idioma.participe} <a href={evento.link} className="ml-1">{evento.link}</a></span>
                                        </div>
                                    </div>
                                </div>
                                : <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className=""><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>}
                    </div>
                </div>
                {dadosApi ?
                    usuario.id == evento.id_usuario ?
                        <div className="col-md-12 mb-3">
                            <div className="list-group-item" >

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className={path == "/eventos/:id_evento/editar" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/eventos/" + evento.id + "/editar"}>

                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                            {idioma.bt_editar}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className={path == "/eventos/:id_evento/editar-capa" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/eventos/" + evento.id + "/editar-capa"}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="camera" size="1x" color="black" className="mr-3" />
                                                            {idioma.bt_editar_capa}
                                                        </span>
                                                    </Link>
                                                </div>
                                                < div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                    <Link to="#" onClick={() => this.chamarAlertaDeletar(evento.id)}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="trash-alt" size="1x" color="red" className="mr-3" />
                                                            {idioma.bt_deletar}
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div >
                                        </div>
                                    </div >

                                </div >
                            </div>
                        </div>

                        :
                        credenciais.length > 0 ?
                            (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                <div className="col-md-12 mb-3">
                                    <div className="list-group-item" >

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        {credenciais.map(credencial => ((credencial.id_componente == 28) ?

                                                            < div className={path === "/eventos/:id_conteudo/editar" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/eventos/" + evento.id + "/editar"}>

                                                                    <span className="text-perfil">
                                                                        <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                                        {idioma.bt_editar}
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                            : ""))}

                                                        <div className={path === "/eventos/:id_evento/editar-capa" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/eventos/" + evento.id + "/editar-capa"}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="camera" size="1x" color="black" className="mr-3" />
                                                                    {idioma.bt_editar_capa}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                        < div className="col-auto menu-cabecalho">
                                                            <Link to="#" onClick={() => this.chamarAlertaDeletar(evento.id)}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="trash-alt" size="1x" color="red" className="mr-3" />
                                                                    {idioma.bt_deletar}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div >
                                                </div>
                                            </div >

                                        </div >
                                    </div>
                                </div>
                                : ""
                            : ""
                    : <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <div className="row">
                                    < div className="col-auto menu-cabecalho">
                                        <Skeleton count={1} width={80} />
                                    </div>
                                    <div className="col-auto menu-cabecalho active">
                                        <Skeleton count={1} width={80} />
                                    </div>
                                    <div className="col-auto menu-cabecalho active">
                                        <Skeleton count={1} width={80} />
                                    </div>
                                    <div className="col-auto menu-cabecalho" >
                                        <Skeleton count={1} width={80} />
                                    </div>
                                </div >
                            </div >
                        </div >

                    </div >
                }
            </>
        )
    }
}
const mapStatetoProps = state => ({
    evento: state.eventos.evento,


})
export default connect(mapStatetoProps, actions)(PerfilEvento);