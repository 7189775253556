import React, { Component, ReactDOM } from 'react';
import Chart from 'react-apexcharts'

class RadarEtapa extends React.Component {
    constructor(props) {
        super(props);


        const categories = [];
        const peso_maximo = this.props.pesoMaximo ? this.props.pesoMaximo : 5;
        const etapasComparar = [];
        const etapas = this.props.etapas;
        const index = this.props.index;
        let idEtapaSelecionada = 0;
        for (const p in etapas[0].pilares) {
            categories.push(etapas[0].pilares[p].pilar.nome);
        }
        const normEtapas = [];
        for (const e in etapas) {
            if (etapas[e].etapa.inicial == 1) {
                normEtapas.push(etapas[e])
            }
        }
        for (const e in etapas) {
            if (etapas[e].etapa.inicial == 0) {
                normEtapas.push(etapas[e])
            }
        }
        for (const e in etapas) {
            if (index == e) {
                for (const n in normEtapas) {
                    if (etapas[e].etapa.id == normEtapas[n].etapa.id) {
                        idEtapaSelecionada = n;
                    }
                }
            }
        }
        for (let e = 0; e <= idEtapaSelecionada; e++) {
            const data = [];
            for (const p in normEtapas[e].pilares) {
                data.push(normEtapas[e].pilares[p].mediaPilar.mediaPilar);
            }
            etapasComparar.push({
                name: normEtapas[e].etapa.nome + " - MÉDIA: " + normEtapas[e].mediaEtapa.mediaEtapa,
                data: data
            });
        }

        this.state = {
            series: etapasComparar,
            options: {
                chart: {
                    height: 500,
                    type: 'radar',
                    dropShadow: {
                        enabled: true,
                        blur: 1,
                        left: 1,
                        top: 1
                    }
                },
                stroke: {
                    width: 2
                },
                fill: {
                    opacity: 0.1
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    categories: categories,
                    labels: {
                        show: true
                    }
                },
                yaxis: {
                    min: 0,
                    max: Number(peso_maximo),
                    tickAmount: Number(peso_maximo),
                    forceNiceScale: false,
                    labels: {
                        show: true
                    }
                }
            },
        };

    }

    render() {
        return (
            <Chart options={this.state.options} series={this.state.series} keys={this.state.keys} type="radar" height={350} />
        );
    }
}
export default RadarEtapa;