export const portugues = {
  titulo: 'Hub de inovações',
  bt_cadastrar: 'Cadastrar Desafio',
  page1: 'Primeira',
  page2: 'Última',
  proposta: 'Propostas',
  selecionadas: 'Selecionadas',
  erro_selecionar: 'É necessário o envio de arquivo!',
  info_selecionar: 'Selecione a solução',
  enviar: 'Enviar',
  pagina_ver: {
    empresa: 'Empresa',
    responsavel: 'Responsável',
    bt_voltar: 'Voltar',
    bt_avaliadores: 'Avaliadores',
    bt_editar: 'Editar',
    bt_deletar: 'Deletar',
    detalhes: 'Detalhes',
    envios: 'Envios',
    bt_nova_solucao: 'Cadastrar nova solucão',
    titulo_tabela: 'Titulo',
    autor: 'Autor',
    etapa1: 'Etapa1',
    etapa2: 'Etapa2',
    aprovado: 'Aprovado',
    reprovado: 'Reprovado',
    data: 'Data',
    acoes: 'Ações',
    info_termo: 'Para continuar a avaliar desafios, é necessário que baixe, preencha e envie o termo de sigilo.',
    info_termo2: 'O termo enviado foi recusado, para continuar envie-o novamente!',
    bt_confirmar: 'Aceitar',
    bt_recusar: 'Recusar',
    info_erro: 'Nenhum arquivo foi encontrado!',
    info_selecionar: 'Solução',
    info_arquivo: 'Baixe o arquivo aqui:',
    bt_cancelar_solucao: 'Cancelar solução',
    info_envios: 'As informações enviadas ainda estão sendo analisadas',
    sucesso_envios: 'Muito bem! As informações foram aprovadas',
    info_aprovado: 'Não aprovado',
    modal_titulo: 'Deseja deletar o evento?',
    modal_info1: 'Ao fazer isso você concorda na exclusão do evento',
    modal_info2: 'Digite',
    modal_info3: 'CONFIRMAR',
    modal_info4: 'para realizar a exclusão',
    modal_info: 'Digite CONFIRMAR para realizar a exclusão',
    erro_delete: 'Mensagem de confirmação inválida!',
    sucesso_delete: 'Evento excluído com sucesso!',
    modal_email: 'Deseja enviar esse desafio por e-mail?',
    modal_email_info: 'Ao fazer isso você concorda que todas as informações desse desafio serão enviadas ao seu e-mail',
    bt_enviar: 'Enviar',
    modal_email_sucesso: 'As informações do desafio foram enviadas para o seu email!',
    info_enviaremail: 'Envie as informçaões para o seu e-mail',
    aval_gestor_analise: 'Em processo de análise.',
    info_termo_analise: 'Aguarde. O termo enviado está em processo de análise!'
  },
  avaliadores: {
    titulo: 'Avaliadores',
    bt_cadastrar: 'Cadastrar avaliador',
    bt_vincular: 'Vincular avaliador',
    nome: 'Nome',
    data: 'Data de aceite',
    termo: 'Termo de sigilo',
    acao: 'Ação',
    modal_titulo: 'Voce tem certeza?',
    modal_info: 'Não será possível restuarar este usuário!',
    bt_cancelar: 'Cancelar',
    bt_excluir: 'Sim, excluir este usuário!',
    modal_sucesso: 'Usuário excluído com sucesso',
    bt_deletar: 'Deletar',
    aprovar: 'Aprovar',
    info_btaprovar: 'Aguardando envio do termo',
    reprovar: 'Reprovar'
  },
  avaliar: {
    titulo: 'Avaliar solução',
    tecnologia: 'TECNOLOGIA',
    mercado: 'MERCADO',
    capital: 'CAPITAL',
    gestao: 'GESTÃO',
    empreendedor: 'EMPREENDEDOR',
    info_doc: 'Adicione o documento da avaliação',
    bt_salvar: 'Salvar',
    info_sucesso: 'Avaliação cadastrada com sucesso!',
    sucesso: 'Sucesso!',
    erro: 'Erro!'
  },
  cadastrarAvaliador: {
    titulo: 'Adicionar participante',
    nome: 'Nome',
    nome2: 'Nome completo',
    email: 'E-mail',
    senha: 'Senha',
    bt_cadastrar: 'Cadastrar',
    bt_cancelar: 'Cancelar',
    erro_nome: 'Preencha o nome',
    erro_email: 'Preencha o e-mail',
    erro_email2: 'Preencha um e-mail válido',
    erro_senha: 'Preencha a senha',
    erro_senha2: 'Preencha a senha com no mínimo 6 caracteres',
    info_sucesso: 'Participante cadastrado com sucesso!',
    portugues: 'Portugues',
    ingles: 'Inglês',
    label_idioma: 'Idioma'
  },
  cadastrarDesafio: {
    tituloPagina: 'Cadastrar Desafio',
    titulo: 'Titulo',
    titulo2: 'Título do desafio',
    titulo3: 'Editar desafio',
    erro_titulo: 'Preencha o título',
    erro_arq: 'Selecione o arquivo',
    info_imagem: 'Deseja deletar a imagem do desafio?',
    bt_deletar: 'Deletar',
    edicao_suc: 'Desafio editado com sucesso!',
    empresa: 'Empresa',
    nota: 'Nota de corte',
    nota2: 'Nota (entre 0 e 5)',
    resposnável: 'Responsável',
    responsavel2: 'Responsável pelo desafio',
    cargo: 'Cargo',
    resumo: 'Resumo',
    resumo2: 'Resumo do desafio',
    descricao: 'Descrição',
    docs: 'Documentos do desafio',
    info_docs: 'Selecione todos os documentos necessário de uma vez só.',
    bt_cadastrar: 'Cadastrar',
    info_sucesso: 'Desafio cadastrado com sucesso',
    bt_salvar: 'Salvar'
  },
  cadastrarSolucao: {
    info_sucesso: 'Candidatura enviada com sucesso!',
    info: 'Aguarde mais informações no seu email',
    info_sucesso2: 'Solução cadastrada com sucesso!',
    titulo: 'Cadastrar solução',
    titulo2: 'Titulo',
    titulo3: 'Título da solução',
    resumo: 'Resumo',
    resumo2: 'Resumo da solução',
    autor: 'Autor',
    autor3: 'Nome do autor',
    negocio: 'Negocio',
    ambiente: 'Ambiente de inovação',
    outros: 'Outros',
    autor2: 'Escolher autor',
    docs: 'Documentos da solução',
    docs_info: 'Selecione todos os documentos necessários de uma vez só.'
  },
  verSolucao: {
    modal_prox: 'Próximo',
    modal_titulo: 'Deseja aprovar a solução?',
    reprovado: 'Reprovar',
    aprovado: 'Aprovar',
    obs: 'Observações',
    doc_info: 'Adicione o documento da avaliação',
    avaliador: 'Avaliador',
    nota: 'Nota Final',
    acoes: 'Ações',
    detalhes: 'Detalhes',
    editar: 'Editar',
    gestor: 'Gestor',
    resultado: 'Resultado',
    obs1: 'Observação',
    ler: '[Ler mais]',
    acessar_ava: 'Acessar avaliação',
    prev_apro: 'Previamente aprovado',
    pre_apro: 'Pré-aprovado',
    prev_rep: 'Previamente reprovado',
    situacao: 'Nota não atingida',
    titulo: 'Solução do desafio',
    resumo: 'Resumo',
    avaliacoes: 'Avaliações',
    aval_gestor: 'Avaliação do gestor',
    med_final: 'Média final',
    docs: 'Documentos',
    aguardando: 'Aguardando avaliações',
    situ: 'Situação'
  },
  vincularAvaliador: {
    modal_titulo: 'Você tem certeza?',
    bt_cancelar: 'Cancelar',
    modal_info: 'Sim, vincular este usuário',
    modal_sucesso: 'Usuário vinculado com sucesso',
    titulo: 'Vincular avaliador',
    bt_voltar: 'Voltar',
    pesquisar_place: 'Pesquisar...',
    bt_pesquisar: 'Pesquisar',
    nome: 'Nome',
    acao: 'Ação',
    vinculado: 'Vinculado',
    vincular: 'Vincular',
    msg_sucesso: 'Negócio cadastrada com sucesso!'
  }
}

export const ingles = {
  titulo: 'Innovation hub',
  bt_cadastrar: 'Register Challenge',
  page1: 'First',
  page2: 'Last',
  proposta: 'Proposals',
  selecionadas: 'Selected',
  erro_selecionar: 'File upload required!',
  info_selecionar: 'Select the solution',
  enviar: 'Send',
  pagina_ver: {
    empresa: 'Business',
    responsavel: 'Accountable',
    bt_voltar: 'Back',
    bt_avaliadores: 'Evaluators',
    bt_editar: 'Edit',
    bt_deletar: 'Delete',
    detalhes: 'Details',
    envios: 'Sendings',
    bt_nova_solucao: 'Register new solution',
    titulo_tabela: 'Title',
    autor: 'Author',
    etapa1: 'Step1',
    etapa2: 'Step2',
    aprovado: 'Approved',
    reprovado: 'Disapproved',
    data: 'Date',
    acoes: 'Options',
    info_termo: 'To continue evaluating challenges, you need to download, fill in and submit the confidentiality agreement.',
    info_termo2: 'The term sent was rejected, to continue send it again!',
    bt_confirmar: 'Accept',
    bt_recusar: 'Deny',
    info_erro: 'No files found!',
    info_selecionar: 'Solution',
    info_arquivo: 'Download file here:',
    bt_cancelar_solucao: 'Cancel solution',
    info_envios: 'The information submitted is still being analyzed',
    sucesso_envios: 'Very well! The information was approved',
    info_aprovado: 'Disapproved',
    modal_titulo: 'Do you want to delete the event?',
    modal_info1: 'By doing so you agree to the deletion of the event',
    modal_info2: 'Type',
    modal_info3: 'CONFIRM',
    modal_info4: 'to perform the deletion',
    modal_info: 'Type CONFIRM to perform the deletion',
    erro_delete: 'Invalid confirmation message!',
    sucesso_delete: 'Event successfully deleted!',
    modal_email: 'Do you want to send this challenge by email?',
    modal_email_info: 'By doing so you agree that all information in this challenge will be sent to your email',
    bt_enviar: 'Send',
    modal_email_sucesso: 'Challenge information has been sent to your email!',
    info_enviaremail: 'Send informations to your email',
    aval_gestor_analise: 'In process of analysis.',
    info_termo_analise: 'Wait. The term sent is in the process of being analyzed!'
  },
  avaliadores: {
    titulo: 'Evaluators',
    bt_cadastrar: 'Register evaluator',
    bt_vincular: 'Link evaluator',
    nome: 'Name',
    data: 'Accept date',
    termo: 'Confidentiality agreement',
    acao: 'Options',
    modal_titulo: 'Are you sure?',
    modal_info: "It won't be possible to restore this user!",
    bt_cancelar: 'Cancel',
    bt_excluir: 'Yes, delete this user!',
    modal_sucesso: 'User successfully deleted!',
    bt_deletar: 'Delete',
    aprovar: 'Approve',
    info_btaprovar: 'Awaiting submission of term',
    reprovar: 'Disapprove'
  },
  avaliar: {
    titulo: 'Evaluate solution',
    tecnologia: 'TECHNOLOGY',
    mercado: 'MARKET',
    capital: 'CAPITAL',
    gestao: 'MANAGEMENT',
    empreendedor: 'ENTREPRENEUR',
    info_doc: 'Add assessment document',
    bt_salvar: 'Save',
    info_sucesso: 'Evaluation successfully registered!',
    sucesso: 'Success!',
    erro: 'Error!'
  },
  cadastrarAvaliador: {
    titulo: 'Add participant',
    nome: 'Name',
    nome2: 'Full name',
    email: 'Email',
    senha: 'Password',
    bt_cadastrar: 'Register',
    bt_cancelar: 'Cancel',
    erro_nome: 'Name must be filled',
    erro_email: 'Email must be filled',
    erro_email2: 'Fill in a valid email',
    erro_senha: 'Password must be filled',
    erro_senha2: 'Password must be at least 6 characters long',
    info_sucesso: 'Participant successfully registered!',
    portugues: 'Portuguese',
    ingles: 'English',
    label_idioma: 'Language'
  },
  cadastrarDesafio: {
    tituloPagina: 'Register Challenge',
    titulo: 'Title',
    titulo2: 'Challenge title',
    titulo3: 'Edit challenge',
    erro_titulo: 'Title must be filled',
    erro_arq: 'Choose a file',
    info_imagem: 'Do you wish to delete the challenge image?',
    bt_deletar: 'Delete',
    edicao_suc: 'Challenge successfully edited!',
    empresa: 'Business',
    nota: 'Passing score',
    nota2: 'Score (between 0 and 5)',
    resposnável: 'Accountable',
    responsavel2: 'Responsible for the challenge',
    cargo: 'Cargo',
    resumo: 'Abstract',
    resumo2: 'Challenge summary',
    descricao: 'Description',
    docs: 'Challenge documents',
    info_docs: 'Select all required documents at once.',
    bt_cadastrar: 'Register',
    info_sucesso: 'Challenge successfully registered!',
    bt_salvar: 'Save'
  },
  cadastrarSolucao: {
    info_sucesso: 'Application successfully sent!',
    info: 'Wait for more information in your email',
    info_sucesso2: 'Wait for more information in your email!',
    titulo: 'Register solution',
    titulo2: 'Title',
    titulo3: "Solution's title",
    resumo: 'Abstract',
    resumo2: 'Solution summary',
    autor: 'Author',
    autor3: "Author's name",
    negocio: 'Business',
    ambiente: 'Innovation space',
    outros: 'Others',
    autor2: 'Select author',
    docs: 'Challenge documents',
    docs_info: 'Select all required documents at once.'
  },
  verSolucao: {
    modal_prox: 'Next',
    modal_titulo: 'Do you want to approve the solution?',
    reprovado: 'Disapprove',
    aprovado: 'Approve',
    obs: 'Observations',
    doc_info: 'Add the assessment document',
    avaliador: 'Evaluator',
    nota: 'Final grade',
    acoes: 'Options',
    detalhes: 'Details',
    editar: 'Edit',
    gestor: 'Manager',
    resultado: 'Result',
    obs1: 'Observation',
    ler: '[Read more]',
    acessar_ava: 'Access evaluation',
    prev_apro: 'Previously approved',
    pre_apro: 'Pre-approved',
    prev_rep: 'Previously disapproved',
    situacao: 'Grade not achieved',
    titulo: 'Challenge solution',
    resumo: 'Abstract',
    avaliacoes: 'Assessments',
    aval_gestor: 'Manager assessment',
    med_final: 'Final average',
    docs: 'Documents',
    aguardando: 'Awaiting evaluations',
    situ: 'Situation'
  },
  vincularAvaliador: {
    modal_titulo: 'Are you sure?',
    bt_cancelar: 'Cancel',
    modal_info: 'Yes, link this user',
    modal_sucesso: 'User successfully linked!',
    titulo: 'Link appraiser',
    bt_voltar: 'Back',
    pesquisar_place: 'Search...',
    bt_pesquisar: 'Search',
    nome: 'Name',
    acao: 'Option',
    vinculado: 'Linked',
    vincular: 'Link',
    msg_sucesso: 'Business successfully registered!'
  }
}