import {
    GET_INCUBADORAS, GET_INCUBADORA, GET_STARTUPS, GET_STARTUP,
    GET_USERS, GET_INCUBADORA_STARTUP, GET_SEARCH_STARTUP, GET_SEARCH_INCUBADORA, GET_SEARCH,
    GET_SEGMENTO, GET_USUARIOS_INCUBADORA, GET_FUTUROS_USUARIOS, GET_MINHAS_INCUBADORAS,
    GET_INCUBADORAS_ATIVAS, GET_STARTUPS_ATIVAS, LIMPAR_INCUBADORA, LIMPAR_INCUBADORAS,
    LIMPAR_INCUBADORAS_STARTUPS, LIMPAR_USERS, LIMPAR_INCUBADORAS_ATIVAS, LIMPAR_STARTUPS_ATIVAS,
    LIMPAR_USUARIOS_INCUBADORA, LIMPAR_FUTUROS_USUARIOS, LIMPAR_MINHAS_INCUBADORAS, LIMPAR_STARTUPS, GET_SEARCH_STARTUPS_INCUBADORA, GET_SEARCH_INCUBADORAS,
    GET_VERIFICAR, GET_PERSONALIZACAO_INCUBADORA, GET_PERSONALIZACOES, LIMPAR_PERSONALIZACAO, GET_PERSONALIZACAO,
    GET_PERSONALIZACOES_INCUBADORA, GET_INCUBADORAS_PERSONALIZACAO, GET_TODAS_PERSONALIZACOES, GET_JORNADAS_INCUBADORA
} from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_INCUBADORAS:
            return {
                ...state,
                incubadoras: null,
                incubadoras: actions.payload.data
            }
        case GET_INCUBADORAS_ATIVAS:
            return {
                ...state,
                incubadorasAtivas: null,
                incubadorasAtivas: actions.payload.data
            }
        case GET_VERIFICAR:
            return {
                ...state,
                pertence: actions.payload.data
            }
        case GET_INCUBADORA:
            return {
                ...state,
                incubadora: actions.payload.dados
            }
        case GET_PERSONALIZACAO_INCUBADORA:
            return {
                ...state,
                personalizacao: actions.payload.data
            }
        case GET_PERSONALIZACOES_INCUBADORA:
            return {
                ...state,
                personalizacoesIncubadora: actions.payload.dados
            }
        case GET_INCUBADORAS_PERSONALIZACAO:
            return {
                ...state,
                incubadorasPersonalizacao: actions.payload.data
            }
        case GET_PERSONALIZACOES:
            return {
                ...state,
                personalizacoes: actions.payload.data
            }
        case GET_TODAS_PERSONALIZACOES:
            return {
                ...state,
                todasPersonalizacoes: actions.payload.data
            }
        case GET_PERSONALIZACAO:
            return {
                ...state,
                personalizacaoIncubadora: actions.payload.data
            }
        case GET_STARTUPS:
            return {
                ...state,
                startups: null,
                startups: actions.payload.data
            }
        case GET_STARTUPS_ATIVAS:
            return {
                ...state,
                startupsAtivas: null,
                startupsAtivas: actions.payload.data
            }
        case GET_STARTUP:
            return {
                ...state,
                startup: actions.payload.dados
            }
        case GET_USERS:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case GET_INCUBADORA_STARTUP:
            return {
                ...state,
                incubadoraStartups: actions.payload.data
            }
        case GET_JORNADAS_INCUBADORA:
            return {
                ...state,
                jornadasIncubadora: actions.payload.data
            }
        case GET_SEARCH_STARTUP:
            return {
                ...state,
                searchStartup: actions.payload.data
            }
        case GET_SEARCH_STARTUPS_INCUBADORA:
            return {
                ...state,
                searchStartupIncubadora: actions.payload.data
            }
        case GET_SEARCH:
            return {
                ...state,
                search: actions.payload.data
            }
        case GET_SEARCH_INCUBADORA:
            return {
                ...state,
                searchIncubadora: actions.payload.data
            }
        case GET_SEARCH_INCUBADORAS:
            return {
                ...state,
                searchIncubadorasEcossistema: actions.payload.data
            }
        case GET_SEGMENTO:
            return {
                ...state,
                segmento: actions.payload.dados
            }
        case GET_USUARIOS_INCUBADORA:
            return {
                ...state,
                usuariosIncubadora: actions.payload.data
            }
        case GET_FUTUROS_USUARIOS:
            return {
                ...state,
                futurosUsuarios: actions.payload.data
            }
        case GET_MINHAS_INCUBADORAS:
            return {
                ...state,
                minhasIncubadoras: actions.payload.data
            }
        case LIMPAR_INCUBADORAS:
            return {
                incubadoras: null,
            }
        case LIMPAR_INCUBADORA:
            return {
                incubadora: null,
            }
        case LIMPAR_INCUBADORAS_STARTUPS:
            return {
                incubadoraStartups: null,
            }
        case LIMPAR_USERS:
            return {
                usuarios: null,
            }
        case LIMPAR_INCUBADORAS_ATIVAS:
            return {
                incubadorasAtivas: null,
            }
        case LIMPAR_STARTUPS_ATIVAS:
            return {
                startupsAtivas: null,
            }
        case LIMPAR_USUARIOS_INCUBADORA:
            return {
                usuariosIncubadora: null,
            }
        case LIMPAR_FUTUROS_USUARIOS:
            return {
                futurosUsuarios: null,
            }
        case LIMPAR_MINHAS_INCUBADORAS:
            return {
                minhasIncubadoras: null,
            }
        case LIMPAR_STARTUPS:
            return {
                startups: null,
            }
        case LIMPAR_PERSONALIZACAO:
            return {
                personalizacaoIncubadora: null
            }
        default:
            return state;
    }
}