import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import NumberFormat from "react-number-format";
import Alert from '@material-ui/lab/Alert';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class CadastrarDesafio extends Component {
    state = {
        titulo: "",
        descricao: "",
        responsavel: "",
        cargo: "",
        empresa: "",
        resumo: "",
        file: "",
        id_autor: "",
        nota_virgula: "",
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        editorState: EditorState.createEmpty(),
        idioma: portugues,
        msg_backend: portuguese

    }

    componentDidMount() {
        this.setState({ id_autor: this.props.usuario.id })

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }
    cadDesafio() {
        const { titulo, editorState, responsavel, nota_virgula, empresa, cargo, id_autor, file, resumo } = this.state;
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (!this.validate()) return;
        this.setState({ carregando: true })
        const formData = new FormData();
        let nota_corte = nota_virgula.replace(',', '.')
        formData.append('titulo', titulo)
        formData.append('descricao', descricao)
        formData.append('responsavel', responsavel)
        formData.append('empresa', empresa)
        formData.append('cargo', cargo)
        formData.append('id_autor', id_autor)
        formData.append('resumo', resumo)
        formData.append('nota_corte', nota_corte)
        for (let f in file) {
            formData.append('file', file[f]);
        }
        this.props.postDesafio(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { titulo, descricao, responsavel, empresa, cargo } = this.state;
        // if (!titulo) return this.setState({ erro: { message: "Preencha o titulo" } });
        // if (!descricao) return this.setState({ erro: { message: "Preencha uma descrição" } });
        // if (!responsavel) return this.setState({ erro: { message: "Preencha um responsável" } });
        // if (!cargo) return this.setState({ erro: { message: "Preencha uma cargo" } });
        // if (!empresa) return this.setState({ erro: { message: "Preencha uma empresa" } });
        return true;
    }
    render() {
        const { titulo, nota_virgula, idioma, file, resumo, editorState, responsavel, empresa, cargo, id_autor, erro, success, carregando, iconSalvar, formSuccess } = this.state;
        if (formSuccess) return <Redirect to={{
            pathname: `/${this.props.usuario?.personalizacao?.titulo}/hub`,
            state: { msg: idioma.cadastrarDesafio.info_sucesso }
        }}></Redirect>


        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarDesafio.tituloPagina} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="titulo">{idioma.cadastrarDesafio.titulo}</Label>
                                            <Input type="text" value={titulo} autoComplete="titulo" onChange={(ev) => this.onChnageInput("titulo", ev)} name="titulo" id="titulo" placeholder={idioma.cadastrarDesafio.titulo2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="empresa">{idioma.cadastrarDesafio.empresa}</Label>
                                            <Input type="text" value={empresa} autoComplete="empresa" onChange={(ev) => this.onChnageInput("empresa", ev)} name="empresa" id="empresa" placeholder={idioma.cadastrarDesafio.empresa} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="nota_virgula">{idioma.cadastrarDesafio.nota}</Label>
                                            <div className=" ml-3">
                                                <NumberFormat
                                                    className="input-nota"
                                                    value={nota_virgula}
                                                    decimalSeparator=","
                                                    onChange={(ev) => this.onChnageInput("nota_virgula", ev)}
                                                    placeholder={idioma.cadastrarDesafio.nota2}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={10}>
                                        <FormGroup>
                                            <Label for="responsavel">{idioma.cadastrarDesafio.resposnável}</Label>
                                            <Input type="text" value={responsavel} autoComplete="responsavel" onChange={(ev) => this.onChnageInput("responsavel", ev)} name="responsavel" id="responsavel" placeholder={idioma.cadastrarDesafio.responsavel2} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="cargo">{idioma.cadastrarDesafio.cargo}</Label>
                                            <Input type="text" value={cargo} autoComplete="cargo" onChange={(ev) => this.onChnageInput("cargo", ev)} name="cargo" id="cargo" placeholder={idioma.cadastrarDesafio.cargo} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="resumo">{idioma.cadastrarDesafio.resumo}</Label>
                                            <Input type="textarea" value={resumo} autoComplete="resumo" onChange={(ev) => this.onChnageInput("resumo", ev)} name="resumo" id="resumo" placeholder={idioma.cadastrarDesafio.resumo2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descricao">{idioma.cadastrarDesafio.descricao}</Label>
                                            <Card>
                                                <div>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={this.onEditorStateChange}
                                                    />
                                                </div>
                                            </Card>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="file">{idioma.cadastrarDesafio.docs}</Label>
                                    <div className="col-md-12">
                                        <Alert severity="warning">{idioma.cadastrarDesafio.info_docs}</Alert>
                                    </div>
                                    <Input type="file" id="file" name="file" multiple className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                </FormGroup>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="hidden" value={id_autor} id="id_autor" name="id_autor" />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Link to="#" onClick={() => this.cadDesafio()}> <Botao loading={carregando} texto={idioma.cadastrarDesafio.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarios: state.auth.usuarios,
    trilhas: state.trilhas.trilhas
})

export default connect(mapStateToProps, actions)(CadastrarDesafio);