import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfil';
import SemDados from '../../componentes/Mensagens/semDados';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';

import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Skeleton from "react-loading-skeleton";

import { ingles, portugues } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';


import Titulo from '../../componentes/TituloPaginas';


class Startup extends Component {
    state = {
        id_startup: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_startup } = this.props.match.params;
        const { usuario } = this.props;
        this.setState({ id_startup })
        this.listarStartup(id_startup)

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparStartup();
    }

    receberDadosAPI() {
        if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }




    render() {
        const { msg, carregando, erro, success, dadosApi, idioma } = this.state;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var usuarioDetalhes = [];
        if (this.props.usuarioDetalhes) usuarioDetalhes = this.props.usuarioDetalhes;
        var path = this.props.match.path
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.acompanhamento.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="d-flex row">
                            {dadosApi ?
                                <div className="col-lg-6 mb-3">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.acompanhamento.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                                        <div className="row pt-2 mt-3 mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.nome}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{startup ? startup.nome : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.segmento}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{startup ? startup.segmento : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.cidade}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{startup ? startup.cidade : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.cnpj}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{startup ? startup.cnpj : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.cep}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{startup ? startup.cep : ""}</p>
                                        </div>
                                        {startup.link ?
                                            <div className="row mb-3">
                                                <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.site}</strong></p>
                                                <p className="col-sm-8 texto-centralizado"> <a href={startup.link} className="ml-1">{idioma.acompanhamento.site_acesso}</a></p>
                                            </div>
                                            : ""}
                                        <div className="row mb-3">
                                            <p className="col-md-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.endereco}</strong></p>
                                            <p className="col-md-8 texto-centralizado">{startup ? startup.endereco : ""}</p>
                                        </div>
                                        <div className="row mb-3">
                                            <p className="col-sm-4 text-right texto-centralizado"><strong>{idioma.acompanhamento.redes}</strong></p>
                                            {startup ?
                                                <div className="col-sm-8 texto-centralizado">
                                                    {(startup.facebook) ?
                                                        <a href={startup ? startup.facebook : ""}><BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'facebook-square']} /></a> : ""}
                                                    {(startup.instagram) ?
                                                        <a href={startup ? startup.instagram : ""} ><BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'instagram']} /></a> : ""}
                                                    {(startup.linkedin) ?
                                                        <a href={startup ? startup.linkedin : ""}><BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'linkedin']} /></a> : ""}
                                                    {(startup.twitter) ?
                                                        <a href={startup ? startup.twitter : ""} ><BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm mr-1" icone={['fab', 'twitter-square']} /></a> : ""}
                                                </div> : ""}

                                        </div>
                                    </div>
                                </div>
                                : <div className="col-lg-6 mb-3">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.acompanhamento.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                                        <div className="row pt-2">
                                            <p className="col-sm-3">
                                                <strong>
                                                    <Skeleton count={1} width={80} />
                                                </strong>
                                            </p>
                                            <Skeleton count={1} width={80} />
                                        </div>
                                        <div className="row">
                                            <p className="col-sm-3">
                                                <strong>
                                                    <Skeleton count={1} width={80} />
                                                </strong>
                                            </p>
                                            <Skeleton count={1} width={80} />
                                        </div>
                                        <div className="row">
                                            <p className="col-sm-3">
                                                <strong>
                                                    <Skeleton count={1} width={80} />
                                                </strong>
                                            </p>
                                            <Skeleton count={1} width={80} />
                                        </div>
                                        <div className="row">
                                            <p className="col-sm-3">
                                                <strong>
                                                    <Skeleton count={1} width={80} />
                                                </strong>
                                            </p>
                                            <Skeleton count={1} width={80} />
                                        </div>
                                        <div className="row">
                                            <p className="col-sm-3">
                                                <strong>
                                                    <Skeleton count={1} width={80} />
                                                </strong>
                                            </p>
                                            <Skeleton count={1} width={80} />
                                        </div>
                                        <div className="row">
                                            <p className="col-sm-3">
                                                <strong>
                                                    <Skeleton count={1} width={80} />
                                                </strong>
                                            </p>
                                            <Skeleton count={1} width={80} />
                                        </div>
                                        <div className="row">
                                            <p className="col-sm-3">
                                                <strong>
                                                    <Skeleton count={1} width={80} />
                                                </strong>
                                            </p>
                                            <Skeleton count={1} width={80} />
                                        </div>
                                    </div>
                                </div>}

                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            {dadosApi ?
                                <div className="col">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        {startup.pitch ?
                                            <div class="embed-responsive embed-responsive-16by9">
                                                <iframe class="embed-responsive-item" src={startup.pitch} allowfullscreen></iframe>
                                            </div>
                                            : <SemDados subtitulo={idioma.sem_dados_sub} titulo="Pitch" />}
                                    </div>
                                </div>
                                :
                                <div className="col">
                                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                        <Skeleton rectangle={true} height={336} width={600} />
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    startup: state.startups.startup,
    usuario: state.auth.usuario
})
export default connect(mapStatetoProps, actions)(Startup);