import React from 'react';
import PermissaoDashboard from './PermissaoDashboard';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

const permissaoDashboard = Component => {
    class ComponentBaseDashboard extends React.Component {

        componentDidMount() {
            const { authorized, getUsuario, history } = this.props;
            getUsuario();
            if (!authorized) return history.replace(`/`)
        }
        componentDidUpdate(nextProps) {
            const { authorized, history } = this.props;
            if (!nextProps.authorized || !authorized) return history.replace(`/`)

        }
        render() {
            var credenciais = []
            if (this.props.usuario) credenciais = this.props.usuario.credenciais;
            return (
                <PermissaoDashboard {...this.props} credenciais={credenciais}>
                    <Component {...this.props} credenciais={credenciais} />
                </PermissaoDashboard>
            )
        }
    }
    const mapStatetoProps = state => ({
        authorized: state.auth.authorized,
        usuario: state.auth.usuario
    })
    return connect(mapStatetoProps, actions)(ComponentBaseDashboard)
}
export default permissaoDashboard;