import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/radares';
import { Col, Row, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../../componentes/Alertas/AlertaSuccess';
import Botao from '../../../componentes/Botoes';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NumberFormat from "react-number-format";
import Toast from '../../../componentes/Alertas/Toast';
import { portugues, ingles } from '../../../utils/paginaradar_bilingue';
import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import Titulo from '../../../componentes/TituloPaginas';

class CadastrarDesafio extends Component {
    state = {
        id_radarmetodologia: "",
        id_pilar: "",
        nome: "",
        pergunta: "",
        info: "",
        tipo: "",
        peso_max: '',
        opcao1: "",
        opcao2: "",
        opcao3: "",
        opcao4: "",
        opcao5: "",
        opcao6: "",
        opcao7: "",
        opcao8: "",
        opcao9: "",
        opcao10: "",
        nota1_virgula: "",
        nota2_virgula: "",
        nota3_virgula: "",
        nota4_virgula: "",
        nota5_virgula: "",
        nota6_virgula: "",
        nota7_virgula: "",
        nota8_virgula: "",
        nota9_virgula: "",
        nota10_virgula: "",
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }

    componentDidMount() {
        const { id_radar, id_pilar } = this.props.match.params
        this.setState({ id_radarmetodologia: id_radar })
        this.setState({ id_pilar })

        const { usuario } = this.props;
        this.listarRadar(id_radar);

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_radar } = this.props.match.params

        if (!this.props.radar && nextProps.radar) this.listarRadar(id_radar);
        this.receberDadosAPI();
    }

    receberDadosAPI() {
        if (typeof this.props.radar != "undefined" && this.props.radar != null && !this.state.dadosApi) {
            this.setState({ peso_max: this.props.radar.dados.peso_maximo });
            this.setState({ dadosApi: true });
        }

    }

    listarRadar(id_radar) {
        this.props.getRadar(id_radar)
        const { radar } = this.props;
        if (radar === "undefined") return null
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    validateHeft(data) {
        const { peso_max, idioma } = this.state;
        const newData = data.filter((peso) => Number(peso) > Number(peso_max));

        if (newData.length > 0) {
            this.setState({ erro: { message: `${idioma.cadastrarPerguntas.erro_peso1}${peso_max}. ${idioma.cadastrarPerguntas.erro_peso2}` } })
            window.scrollTo(0, 0);
            return false;
        } else {
            return true;
        }
    }

    cadPergunta() {
        const { nome, pergunta, info, opcao1, opcao2, opcao3, opcao4, opcao5, opcao6, opcao7, opcao8, opcao9, opcao10, nota1_virgula, nota2_virgula, nota3_virgula, nota4_virgula, nota5_virgula, nota6_virgula, nota7_virgula, nota8_virgula, nota9_virgula, nota10_virgula, id_radarmetodologia, id_pilar, tipo, msg_backend, idioma } = this.state;
        let nota1 = String(nota1_virgula).replace(',', '.')
        let nota2 = String(nota2_virgula).replace(',', '.')
        let nota3 = String(nota3_virgula).replace(',', '.')
        let nota4 = String(nota4_virgula).replace(',', '.')
        let nota5 = String(nota5_virgula).replace(',', '.')
        let nota6 = String(nota6_virgula).replace(',', '.')
        let nota7 = String(nota7_virgula).replace(',', '.')
        let nota8 = String(nota8_virgula).replace(',', '.')
        let nota9 = String(nota9_virgula).replace(',', '.')
        let nota10 = String(nota10_virgula).replace(',', '.')
        const verifyData = [nota1, nota2, nota3, nota4, nota5, nota6, nota7, nota8, nota9, nota10];
        if (!this.validateHeft(verifyData)) return;
        this.setState({ carregando: true })
        this.props.postPergunta({ nome, pergunta, info, opcao1, opcao2, opcao3, opcao4, opcao5, opcao6, opcao7, opcao8, opcao9, opcao10, nota1, nota2, nota3, nota4, nota5, nota6, nota7, nota8, nota9, nota10, tipo, id_radarmetodologia, id_pilar }, (msg) => {
            if (msg.erro.error) {
                Toast({ icon: 'error', title: idioma.erro, text: msg_backend[msg.erro.code] })
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                Toast({ icon: 'success', title: idioma.sucesso, text: msg_backend[msg.erro.code] })
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    render() {
        const { nome, pergunta, peso_max, info, opcao1, opcao2, opcao3, opcao4, opcao5, opcao6, opcao7, opcao8, opcao9, opcao10, nota1_virgula, nota2_virgula, nota3_virgula, nota4_virgula, nota5_virgula, nota6_virgula, nota7_virgula, nota8_virgula, nota9_virgula, nota10_virgula, tipo, id_pilar, id_radarmetodologia, dadosApi, id_autor, erro, success, carregando, iconSalvar, formSuccess, idioma } = this.state;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;


        if (formSuccess) return <Redirect to={{
            pathname: "/" + this.props.usuario?.personalizacao?.titulo + "/radar/" + id_radarmetodologia + "/pilar/" + id_pilar + "/perguntas",
            state: { msg: idioma.cadastrarPerguntas.msg_sucesso }
        }}></Redirect>


        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarPerguntas.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={8} className="">
                                        <FormGroup>
                                            <Label for="nome">{idioma.cadastrarPerguntas.assunto}</Label>
                                            <div className="">
                                                <Input
                                                    type="text"
                                                    value={nome}
                                                    onChange={(ev) => this.onChnageInput("nome", ev)}
                                                    placeholder={idioma.cadastrarPerguntas.assunto_per}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4} className="">
                                        <FormGroup>
                                            <Label for="tipo">{idioma.cadastrarPerguntas.tipo}</Label>
                                            <div className="">
                                                <Input
                                                    type="select"
                                                    onChange={(ev) => this.onChnageInput("tipo", ev)}
                                                    placeholder={idioma.cadastrarPerguntas.escolh_tipo}
                                                >
                                                    <option value="">{idioma.cadastrarPerguntas.escolh_tipo}</option>
                                                    <option value="multipla">{idioma.cadastrarPerguntas.multi}</option>
                                                    <option value="aberta">{idioma.cadastrarPerguntas.aberta}</option>
                                                </Input>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="pergunta">{idioma.cadastrarPerguntas.pergunta}</Label>
                                            <div className="">
                                                <Input
                                                    type="text"
                                                    value={pergunta}
                                                    onChange={(ev) => this.onChnageInput("pergunta", ev)}
                                                    placeholder={idioma.cadastrarPerguntas.pergunta}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="textarea">{idioma.cadastrarPerguntas.info_add}</Label>
                                            <div className="">
                                                <Input
                                                    type="textarea"
                                                    value={info}
                                                    onChange={(ev) => this.onChnageInput("info", ev)}
                                                    placeholder={idioma.cadastrarPerguntas.info_add}
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {tipo == "multipla" &&
                                    <>
                                        <h4 className="text-center" >{idioma.cadastrarPerguntas.opcao}</h4>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao1}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao1}
                                                            onChange={(ev) => this.onChnageInput("opcao1", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao1}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota1_virgula">{idioma.cadastrarPerguntas.peso}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota1_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota1_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao2}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao2}
                                                            onChange={(ev) => this.onChnageInput("opcao2", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao2}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota2_virgula">{idioma.cadastrarPerguntas.peso2}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota2_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota2_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao3}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao3}
                                                            onChange={(ev) => this.onChnageInput("opcao3", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao3}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota3_virgula">{idioma.cadastrarPerguntas.peso3}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota3_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota3_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao4}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao4}
                                                            onChange={(ev) => this.onChnageInput("opcao4", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao4}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota4_virgula">{idioma.cadastrarPerguntas.peso4}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota4_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota4_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao5}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao5}
                                                            onChange={(ev) => this.onChnageInput("opcao5", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao5}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota5_virgula">{idioma.cadastrarPerguntas.peso5}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota5_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota5_virgula", ev)}
                                                            placeholder={`Entre 1 e ${peso_max}`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao6}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao6}
                                                            onChange={(ev) => this.onChnageInput("opcao6", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao6}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota6_virgula">{idioma.cadastrarPerguntas.peso6}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota6_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota6_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao7}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao7}
                                                            onChange={(ev) => this.onChnageInput("opcao7", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao7}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota7_virgula">{idioma.cadastrarPerguntas.peso7}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota7_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota7_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao8}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao8}
                                                            onChange={(ev) => this.onChnageInput("opcao8", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao8}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota8_virgula">{idioma.cadastrarPerguntas.peso8}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota8_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota8_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao9}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao9}
                                                            onChange={(ev) => this.onChnageInput("opcao9", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao9}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota9_virgula">{idioma.cadastrarPerguntas.peso9}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota9_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota9_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarPerguntas.opcao10}</Label>
                                                    <div className="">
                                                        <Input
                                                            type="text"
                                                            value={opcao10}
                                                            onChange={(ev) => this.onChnageInput("opcao10", ev)}
                                                            placeholder={idioma.cadastrarPerguntas.opcao10}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4} className="">
                                                <FormGroup>
                                                    <Label for="nota10_virgula">{idioma.cadastrarPerguntas.peso10}</Label>
                                                    <div className="">
                                                        <NumberFormat
                                                            value={nota10_virgula}
                                                            className="input-nota"
                                                            decimalSeparator=","
                                                            onChange={(ev) => this.onChnageInput("nota10_virgula", ev)}
                                                            placeholder={`${idioma.cadastrarPerguntas.entre} ${peso_max})`}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <Link to="#" onClick={() => this.cadPergunta()}> <Botao loading={carregando} texto={idioma.cadastrarPerguntas.bt_salvar} cor="success" icone="check" posicao="E" /> </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    radar: state.radares.radar
})

export default connect(mapStateToProps, actions)(CadastrarDesafio);