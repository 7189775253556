import { GET_CONTEUDOS, GET_CONTEUDO, GET_SEARCH_CONTENT, GET_TIPO_CONTEUDO, GET_USERS, LIMPAR_CONTEUDOS, LIMPAR_CONTEUDO } from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_CONTEUDOS:
            return {
                ...state,
                conteudos: actions.payload.data
            }
        case GET_CONTEUDO:
            return {
                ...state,
                conteudo: actions.payload.dados
            }
        case GET_TIPO_CONTEUDO:
            return {
                ...state,
                tipoConteudo: actions.payload.data
            }

        case GET_SEARCH_CONTENT:
            return {
                ...state,
                searchConteudo: actions.payload.data
            }
        case GET_USERS:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case LIMPAR_CONTEUDOS:
            return {
                conteudos: null
            }
        case LIMPAR_CONTEUDO:
            return {
                conteudo: null
            }

        default:
            return state;
    }
}