export const portugues = {
  titulo: 'Eventos',
  bt_cadastrar: 'Cadastrar novo evento',
  pesquisar: 'Pesquisar...',
  bt_pesquisar: 'Pesquisar',
  bt_cancelar: 'Cancelar',
  page1: 'Primeira',
  page2: 'Última',
  sem_dados: 'Não há conteúdos para exibir',
  sem_dados_sub: 'Que pena! Não há informações para serem exibidas.',
  bt_salvar: 'Salvar',
  modal_titulo: 'Você tem certeza?',
  modal_info: 'Não será possível restaurar esse evento!',
  modal_confirm: 'Sim, excluir este evento!',
  modal_sucesso: 'Evento excluído com sucesso!',
  cadastrarEvento: {
    erro_nome: 'Preencha o nome',
    erro_cidade: 'Preencha uma cidade',
    erro_data: 'Preencha uma data',
    erro_responsavel: 'Preencha o nome de um responsável',
    msg_sucesso: 'Evento cadastrado com sucesso',
    titulo: 'Cadastrar evento',
    nome: 'Nome',
    nome_ev: 'Nome do evento',
    data: 'Data do evento',
    info_evento: 'O evento terá duração de mais de 24 horas?',
    sim: 'Sim',
    nao: 'Não',
    data_fim: 'Data de fim do evento',
    responsavel: 'Responsável pelo evento',
    cidade: 'Cidade sede do evento',
    link: 'Link para o evento',
    img: 'Imagem do evento',
    descricao: 'Descrição do evento'
  },
  editarCapa: {
    erro_img: 'Envie uma imagem',
    msg_sucesso: 'Evento editado com sucesso!',
    titulo: 'Editar capa do evento',
  },
  editarEvento: {
    erro_descricao: 'Preencha uma descrição',
    msg_sucesso: 'Evento editado com sucesso',
    titulo: 'Editar evento',
    carregando: 'Carregando...',
    nome2: 'Nome do evento',
    fonte: 'Fonte',
    fonte2: 'Fonte do evento'
  },
  ver: {
    titulo: 'Evento',
    bt_voltar: 'Voltar'
  }
}

export const ingles = {
  titulo: 'Events',
  bt_cadastrar: 'Register new event',
  pesquisar: 'Search...',
  bt_pesquisar: 'Search',
  bt_cancelar: 'Cancel',
  page1: 'First',
  page2: 'Last',
  sem_dados: "There's no contents to show.",
  sem_dados_sub: 'What a pity! There is no information to display.',
  bt_salvar: 'Save',
  modal_titulo: 'Are you sure?',
  modal_info: "Won't be possible to restore this event",
  modal_confirm: 'Yes, delete this event!',
  modal_sucesso: 'Event successfully deleted!',
  cadastrarEvento: {
    erro_nome: 'Name must be filled',
    erro_cidade: 'City must be filled',
    erro_data: 'Date must be filled',
    erro_responsavel: 'Fill in the name of a responsible person',
    msg_sucesso: 'Event successfully registered',
    titulo: 'Register event',
    nome: 'Name',
    nome_ev: 'Event name',
    data: 'Event date',
    info_evento: 'Will the event last longer than 24 hours?',
    sim: 'Yes',
    nao: 'No',
    data_fim: 'Event end date',
    responsavel: 'Responsible for the event',
    cidade: 'Event host city',
    link: 'Link to the event',
    img: 'Event image',
    descricao: 'Event description'
  },
  editarCapa: {
    erro_img: 'Send a picture',
    msg_sucesso: 'Event successfully edited!',
    titulo: 'Edit event cover',
  },
  editarEvento: {
    erro_descricao: 'Description must be filled',
    msg_sucesso: 'Event successfully edited',
    titulo: 'Edit event',
    carregando: 'Loading...',
    nome2: 'Event name',
    fonte: 'Source',
    fonte2: 'Event source'
  },
  ver: {
    titulo: 'Event',
    bt_voltar: 'Back'
  }
}