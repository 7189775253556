export const portugues = {
  modal_titulo: 'Você tem certeza?',
  modal_info: "Não será possível restaurar esse ambiente de inovação!",
  bt_cancelar: 'Cancelar',
  modal_confirm: 'Sim, desativar este ambiente de inovação!',
  modal_sucesso: 'Ambiente de inovação desativada com sucesso!',
  perfil: 'Perfil',
  negocio: 'Negócios',
  usuarios: 'Usuários',
  config: 'Configurações'
}

export const ingles = {
  modal_titulo: 'Are you sure?',
  modal_info: "It will not be possible to restore this space of innovation!",
  bt_cancelar: 'Cancel',
  modal_confirm: 'Yes, disable this innovation space!',
  modal_sucesso: 'Innovation space successfully deactivated!',
  perfil: 'Profile',
  negocio: 'Business',
  usuarios: 'Users',
  config: 'Settings'
}