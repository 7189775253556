import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Card, CardBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { versao } from '../../config/versao';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

import Titulo from '../../componentes/TituloPaginas';
class Configuracoes extends Component {
    state = {
        usuario: "",
        msg: "",
        idioma: ingles
    }
    componentDidMount() {
        const { usuario } = this.props;
        this.setState({ usuario: usuario });
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state })
        }

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
        } else {
            this.setState({ idioma: portugues })
        }
    }

    componentWillUnmount() {
        this.props.limparUsuario()
    }
    render() {
        const { credenciais, usuario } = this.props
        const { idioma } = this.state;
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
                            <div className="row">
                                {credenciais.map(credencial => ((credencial.id_componente === 55) ?
                                    <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/usuarios/"}>
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="user-astronaut" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.usuario}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>

                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 56) ?
                                    <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/configuracoes/incubadoras/listar"}>
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="space-shuttle" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.ambiente_inovacao}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 57) ?
                                    <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/configuracoes/startups/listar"}>
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="meteor" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.negocios}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 58) ?
                                    <div className="col-md-2 card-menu-perfil" >
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/configuracoes/trilhas/listar"}>
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="graduation-cap" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.trilhas}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 59) ?

                                    <div className="col-md-2 card-menu-perfil">
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/perfis"} >
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="user-astronaut" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.perfil}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 87) ?

                                    <div className="col-md-2 card-menu-perfil">
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/radar"} >
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="globe" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.radares}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 87) ?

                                    <div className="col-md-2 card-menu-perfil">
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/configuracoes/metodologias/listar"} >
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon={['fab', 'buffer']} size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.metodologia}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 89) ?
                                    <div className="col-md-2 card-menu-perfil">
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/configuracoes/personalizacao"} >
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="palette" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.perso}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}
                                {credenciais.map(credencial => ((credencial.id_componente === 86) ?
                                    <div className="col-md-2 card-menu-perfil">
                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/termos"} >
                                            <Card className="card-dash">
                                                <CardBody className="img-text-wrapper">
                                                    <div className="logo-wrapper">
                                                        <FontAwesomeIcon icon="file-alt" size="3x" color="black" />
                                                        <div className="titulo-menu-perfil">{idioma.termos}</div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Link>
                                    </div>
                                    : ""))}


                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-12 card-menu-perfil">
                                    <div className="">{idioma.versao} {versao}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuario: state.auth.usuario
})
export default connect(mapStateToProps, actions)(Configuracoes);