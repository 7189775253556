import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

const Carregando = (props) => {
    if (props.loading) return (
        <Button color={props.cor} style={{ backgroundColor: props.color, color: 'white' }} size={props.tamanho} className={`${props.classes}`} disabled >
            <Spinner className="ml-1" size="sm" color="light" />
        </Button>
    )
    return (
        <Button color={props.cor} style={{ backgroundColor: props.color, color: 'white' }} size={props.tamanho} className={`${props.classes}`} disabled={props.disabled}>
            {props.posicao === "E" ? <FontAwesomeIcon icon={props.icone} className="mr-2" /> : ""}
            {props.texto}
            {props.posicao === "D" ? <FontAwesomeIcon icon={props.icone} className="ml-2" /> : ""}
        </Button>
    )
}
export default Carregando;