import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';
import { Navbar, Nav, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'
import { Alert } from 'reactstrap';
import { env, apiImagem } from '../../config/';

import { portugues, ingles } from '../../utils/paginaperfil_bilingue';


const Header = ({ handleLogout, dadosUsuario }) => {
    const [idioma, setIdioma] = useState(portugues);
    useEffect(() => {
        if (dadosUsuario.usuario) {
            setIdioma(dadosUsuario.usuario.idioma.id === 3 ? ingles : portugues);
        }
    }, [dadosUsuario])
    var nome = "Usuário";
    if (dadosUsuario.children.props.usuario) {
        if (dadosUsuario.children.props.usuario.nome) {
            [nome] = dadosUsuario.children.props.usuario.nome.split(' ');
        }
    }
    const altSitMenu = () => {
        dadosUsuario.handleSitMenu(!dadosUsuario.sitMenu)
    }

    // color nav bar color={env == "hom" ? "dark bg-hom" : "dark navbar-dark"}

    return (
        < Navbar style={{ backgroundColor: dadosUsuario.usuario?.personalizacao ? dadosUsuario.usuario.personalizacao.cor_principal : '#EDEDED' }} light expand="md" >


            <Link className="navbar-brand" to={"/" + dadosUsuario?.usuario?.personalizacao?.titulo + "/dashboard"} >
                <img src={dadosUsuario.usuario?.personalizacao && apiImagem + dadosUsuario.usuario.personalizacao.logo_arquivo} alt="Logo" height="100%" style={{ maxWidth: '250px', maxHeight: "150px" }} />
            </Link>
            <span className="icon-burguer cursor-pointer" onClick={() => altSitMenu()} style={{ color: dadosUsuario?.usuario?.personalizacao && dadosUsuario.usuario.personalizacao.cor_texto_menu }}>☰</span>
            {env == "hom" ?
                <Alert color="danger alerta-hom">
                    Homologação
                </Alert>
                : ""}
            <Nav className="ml-auto header-logo" navbar>
                <NavItem className="mr-1">
                    <img className="rounded-circle mt-2" src={dadosUsuario.children.props.usuario && dadosUsuario.children.props.usuario.fileName != null ? apiImagem + dadosUsuario.children.props.usuario.fileName : apiImagem + "avatar.jpg"} width="50" height="50" alt={nome}></img>
                </NavItem>
                <UncontrolledDropdown setActiveFromChild className='d-flex justify-content-center align-items-center' >
                    <DropdownToggle tag="a" className="nav-link menu-header d-flex" >
                        <span style={{ color: dadosUsuario.usuario?.personalizacao && dadosUsuario.usuario.personalizacao.cor_titulo }}>{nome}</span>
                        <FontAwesomeIcon icon='sort-down' className='ml-2 icon-arrow' color={dadosUsuario.usuario?.personalizacao && dadosUsuario.usuario.personalizacao.cor_titulo} />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <Link className="dropdown-item" to={"/" + dadosUsuario?.usuario?.personalizacao?.titulo + "/perfil"}><FontAwesomeIcon icon="user-astronaut" className="mr-2" /> {idioma?.titulo}</Link>
                        <DropdownItem onClick={() =>
                            Swal.fire({
                                title: idioma?.modal_titulo,
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: 'green',
                                cancelButtonColor: '#d33',
                                confirmButtonText: idioma?.sair
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    sessionStorage.setItem('url-perso', dadosUsuario.usuario.personalizacao.titulo)
                                    handleLogout()
                                }
                            })}><FontAwesomeIcon icon="door-open" className="mr-2" /> {idioma?.sair}</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </Nav>
        </Navbar >
    )
}
export default Header;