import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Swal from 'sweetalert2';
import { Spinner, Input, Button, Table } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";
import Titulo from '../../componentes/TituloPaginas';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../utils/paginaconfig_bilingue';

class ListarEcossistema extends Component {
    state = {
        currentPage: 1,
        size: 20,
        startup: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pesquisandoStartup: false,
        procura: "",
        modal: false,
        dadosApi: false,
        msg_backend: portuguese,
        idioma: portugues
    }
    componentDidMount() {
        this.listarStartups();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.startups && nextProps.startups) this.listarStartups();
        this.receberDadosAPI();
    }
    componentWillUnmount() {
        this.props.limparStartup();
        this.props.limparStartups();
    }
    receberDadosAPI() {
        if (typeof this.props.startups != "undefined" && this.props.startups != null && !this.state.dadosApi && this.props.startups.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarStartups();
        })
    }

    //desativar startup
    desvincularStartup(id) {
        const { msg_backend } = this.state;
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        ////console.log(id)
        this.props.putDesvincularStartup(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarStartups()
                this.sucessoDesvincular();
                // this.setState({ formSuccess: true });
            }
        })
    }

    chamarDesvincular(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemStartups.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.desvincularStartup(id);
            }
        })
    }
    sucessoDesvincular() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemStartups.modal_sucesso
        })
    }

    //desativar startup
    desativarStartup(id) {
        const { msg_backend } = this.state
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.desativarStartup(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.sucessoAlerta();
                this.listarStartups()
                // this.setState({ formSuccess: true });
            }
        })
    }

    //ativar startup
    ativarStartup(id) {
        const { msg_backend } = this.state
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.ativarStartup(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.sucessoAlertaAtivar();
                this.listarStartups()
                // this.setState({ formSuccess: true });
            }
        })
    }
    chamarAlertaAtivar(id) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemStartups.modal_confirm2
        }).then((result) => {
            if (result.value) {
                this.ativarStartup(id);
            }
        })
    }
    sucessoAlertaAtivar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemStartups.modal_confirm2
        })
    }

    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.listagemIncubadoras.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.listagemIncubadoras.bt_cancelar,
            confirmButtonText: idioma.listagemStartups.modal_confirm3
        }).then((result) => {
            if (result.value) {
                this.desativarStartup(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemStartups.modal_sucesso3
        })
    }

    //deletar startup
    deletandoStartup(id_startup) {
        const { msg_backend } = this.state;
        this.setState({ carregando: true })
        this.props.deletarStartup(id_startup, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
                this.props.limparStartups();
                this.listarStartups();

            }
        })
    }

    chamarAlertaDeletar(id_startup) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.listagemStartups.modal_titulo,
            html: idioma.listagemStartups.modal_info,
            input: 'text',
            inputPlaceholder: idioma.listagemIncubadoras.modal_confirm3,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.listagemIncubadoras.bt_cancelar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.listagemIncubadoras.confirm) {
                this.deletandoStartup(id_startup);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.listagemIncubadoras.erro_msg
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.listagemStartups.msg_sucesso
        })
    }
    listarStartups() {
        const { currentPage, size } = this.state;
        this.props.getStartups(currentPage, size)
        const { startups } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startups === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleSearchNomeStartup(procura) {
        this.setState({ procura: procura })
        this.setState({ pesquisandoStartup: true })
        const { currentPage, size } = this.state;
        this.props.getSearchNameStartupsConfig(procura, currentPage, size)
    }

    eraseSearchStartup(campo) {
        this.setState({ pesquisandoStartup: false })
        this.setState({ [campo]: "" })
        this.listarStartups()
    }

    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            this.handleSearchNomeStartup(procura)

        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao?.cor_secundaria
    }

    render() {
        const { startup, msg, carregando, erro, success, dadosApi, formSuccess, pesquisandoStartup, procura, idioma } = this.state;
        const { credenciais, usuario } = this.props

        var startups = [];

        if (pesquisandoStartup == false) {

            if (this.props.startups) startups = this.props.startups.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.startups != "undefined" && this.props.startups != null && this.props.startups.currentPage !== "" && this.props.startups.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.startups != "undefined" && this.props.startups !== null && this.props.startups.nextPage <= this.props.startups.totalPages && this.props.startups.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        } else {
            if (this.props.searchStartup) startups = this.props.searchStartup.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.searchStartup != "undefined" && this.props.searchStartup != null && this.props.searchStartup.currentPage !== "" && this.props.searchStartup.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.searchStartup != "undefined" && this.props.searchStartup !== null && this.props.searchStartup.nextPage <= this.props.searchStartup.totalPages && this.props.searchStartup.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        }


        ////////////console.log(this.props)
        return (
            <>

                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.listagemStartups.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} className="pr-2"> <Botao texto={idioma.personalizacao.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/cadastrar"}>
                                <Botao texto={idioma.listagemStartups.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                {/* <button onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} className="btn btn-outline btn-md mr-3 botao-responsivo" style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: this.props.usuario?.personalizacao?.cor_secundaria }}>
                                    {idioma.listagemStartups.bt_cadastrar}
                                </button> */}
                            </Link>
                            <div className="row mt-4">
                                <div className="col-md-8">
                                    <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.criarVinculoPersonalizacao.pesquisar} />
                                </div>
                                <div className="col-md-4">
                                    <div>
                                        <span className="pr-3" onClick={() => this.handleSearchNomeStartup(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} color={this.props.usuario?.personalizacao?.cor_principal} texto={idioma.criarVinculoPersonalizacao.pesquisa} icone="search" posicao="E" /></span>
                                        <span value={procura} onClick={() => this.eraseSearchStartup("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} cor="danger" texto={idioma.listagemIncubadoras.bt_cancelar} icone="times" posicao="E" /></span>
                                    </div>
                                </div>
                            </div>
                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <div className="col-lg-12">
                                <div className=" m-b-20">
                                    <div className="card-body">
                                        {dadosApi ?
                                            <div className="row">
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.nome}</th>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.segmento}</th>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.cidade}</th>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.status}</th>
                                                            <th className="coluna-tabela">{idioma.listagemIncubadoras.acao}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {startups.map(startup => (
                                                            <tr key={startup.id}>

                                                                <td>{startup.nome}</td>
                                                                <td>{startup.segmento}</td>
                                                                <td>{startup.cidade}</td>
                                                                {startup.dataDesativacao == null ?
                                                                    <td><span className="badge badge-pill badge-success mb-1">{idioma.listagemIncubadoras.atv}</span></td>
                                                                    : <td><span className="badge badge-pill badge-danger mb-1">{idioma.listagemIncubadoras.desatv}</span></td>}
                                                                <td>
                                                                    <a data-tip data-for='participantes'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/participantes/"} className="mr-2">
                                                                            <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="user-friends" />
                                                                        </Link>
                                                                    </a>
                                                                    <a data-tip data-for='perfil'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/startups/" + startup.id} className="mr-2">
                                                                            <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="eye" />
                                                                        </Link>
                                                                    </a>

                                                                    <a data-tip data-for='editar'>
                                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/editar"} className="mr-2">
                                                                            <BotaoIcon color={this.props.usuario?.personalizacao?.cor_terciaria} loading={carregando} classes="btn btn-sm ml-1" icone="pencil-alt" />
                                                                        </Link>
                                                                    </a>
                                                                    {startup.dataDesativacao == null ?
                                                                        <a data-tip data-for='desativar'>
                                                                            <Link to="#" onClick={() => this.chamarAlerta(startup.id)} className="mr-2">
                                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="ban" />
                                                                            </Link>
                                                                        </a>
                                                                        :
                                                                        <a data-tip data-for='ativar'>
                                                                            <Link to="#" onClick={() => this.chamarAlertaAtivar(startup.id)} className="mr-2">
                                                                                <BotaoIcon loading={carregando} classes="btn-success btn-sm ml-1" icone="check" />
                                                                            </Link>
                                                                        </a>
                                                                    }
                                                                    {startup.id_incubadora != null ?
                                                                        <a data-tip data-for='desvincular'>
                                                                            <Link to="#" onClick={() => this.chamarDesvincular(startup.id)} className="mr-2">
                                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="unlink" />
                                                                            </Link>
                                                                        </a>
                                                                        :
                                                                        ""
                                                                    }
                                                                    {credenciais.map(credencial => ((credencial.id_componente == 45) ?
                                                                        <a data-tip data-for='deletar'>
                                                                            <Link to="#" onClick={() => this.chamarAlertaDeletar(startup.id)} className="mr-2">
                                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                                            </Link>
                                                                        </a>
                                                                        : ""))}

                                                                    <ReactTooltip id='perfil' type='dark'>
                                                                        <span>{idioma.listagemIncubadoras.perfil}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='participantes' type='dark'>
                                                                        <span>{idioma.listagemIncubadoras.participantes}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='editar' type='dark'>
                                                                        <span>{idioma.listagemIncubadoras.bt_editar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='desativar' type='error'>
                                                                        <span>{idioma.listagemIncubadoras.bt_desativar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='desvincular' type='error'>
                                                                        <span>{idioma.listagemStartups.bt_desvincular}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='ativar' type='success'>
                                                                        <span>{idioma.listagemIncubadoras.ativar}</span>
                                                                    </ReactTooltip>
                                                                    <ReactTooltip id='deletar' type='error'>
                                                                        <span>{idioma.listagemIncubadoras.bt_deletar}</span>
                                                                    </ReactTooltip>
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                            : <div className="row">
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <th>
                                                                <Skeleton count={1} width={80} />
                                                            </th>
                                                            <td>
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                                <Skeleton rectangle={true} height={30} width={30} className="mr-2" />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>}
                                    </div>


                                </div>
                            </div>
                        </div>
                        {dadosApi ?
                            <nav aria-label="Page navigation example">
                                <ul className="pagination justify-content-center">
                                    <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.criarVinculoPersonalizacao.page1}</span>
                                    </li>

                                    {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.startups.prevPage ? this.props.startups.prevPage : 1)}>{this.props.startups.prevPage ? this.props.startups.prevPage : ""}</span></li> : ""}

                                    <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.startups ? this.props.startups.currentPage : "1"}</span></li>

                                    {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.startups.nextPage ? this.props.startups.nextPage : 1)}>{this.props.startups.nextPage ? this.props.startups.nextPage : ""}</span></li> : ""}

                                    <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                        <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.startups ? this.props.startups.totalPages : 0)}>{idioma.criarVinculoPersonalizacao.page2}</span>
                                    </li>
                                </ul>
                            </nav>
                            : ""}
                    </div>

                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    startups: state.startups.startups,
    searchStartup: state.startups.searchStartup,

})
export default connect(mapStatetoProps, actions)(ListarEcossistema);