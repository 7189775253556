import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Link } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { apiImagem } from '../../config/';

import { portugues, ingles } from '../../utils/blocoPerfilIncubadora_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class PerfilIncubadora extends Component {
    state = {
        dadosApi: false,
        pertence: false,
        incubadora: "",
        path: "",
        usuario: "",
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        if (this.props.usuario && this.props.incubadora) { this.verificaIncubadora(this.props.usuario, this.props.incubadora) };

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        if (!this.props.incubadora && nextProps.incubadora) {
            this.verificaIncubadora(this.props.usuario, this.props.incubadora)
        }
        if (!this.props.usuario && nextProps.usuario) {
            this.verificaIncubadora(this.props.usuario, this.props.incubadora)
        }
        this.receberDadosAPI();
    }

    receberDadosAPI() {
        if (typeof this.props.credenciais != "undefined" && this.props.credenciais != null &&
            typeof this.props.usuario != "undefined" && this.props.usuario != null &&
            !this.state.dadosApi && typeof this.props.incubadora != "undefined" && this.props.incubadora != null) {
            this.setState({ dadosApi: true });
        }
    }
    desativarIncubadora(id_incubadora) {
        this.setState({ carregando: true })
        this.props.desativarIncubadora(id_incubadora, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }
    chamarAlerta(id_incubadora) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_titulo,
            text: idioma.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.desativarIncubadora(id_incubadora);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }
    verificaIncubadora(usuario, incubadora) {
        for (let i in usuario.incubadoras) {
            if (usuario.incubadoras[i].id_incubadora == incubadora.id) {
                this.setState({ pertence: true })
            }
        }
    }
    render() {
        let { usuario, incubadora, dadosApi, path, pertence, idioma } = this.state;
        var credenciais = []
        var usuarios = [];
        if (this.props.incubadora) incubadora = this.props.incubadora;
        if (this.props.dadosApi) dadosApi = this.props.dadosApi;
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.usuario) usuario = this.props.usuario;
        if (this.props.path) path = this.props.path;

        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                        {dadosApi ?
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <img className="rounded-circle mt-2" src={incubadora.fileName ? apiImagem + incubadora.fileName : apiImagem + "astronaut.png"} width="120" height="120" ></img>
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil">{incubadora ? incubadora.nome : ""}</h2>
                                        <p className="text-muted">{incubadora ? incubadora.segmento : ""}</p>
                                    </div>
                                </div>
                            </div>
                            : <div className="row">
                                <div className="col-auto centralizado" >
                                    <div className="img-perfil">
                                        <Skeleton circle={true} height={120} width={120} />
                                    </div>
                                </div>
                                <div className="col-md-9 centralizado texto-centralizado">
                                    <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                </div>
                            </div>}
                    </div>
                </div>
                {dadosApi ?
                    <div>
                        <div className="col-md-12 mb-3">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-fluid">
                                            <div className="row">
                                                < div className={path == "/ecossistema/incubadoras/:id_incubadora" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="id-card" size="1x" color="black" className="mr-3" />
                                                            {idioma.perfil}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className={path == "/incubadoras/:id_incubadora/startups" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/incubadoras/" + incubadora.id + "/startups"}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="meteor" size="1x" color="black" className="mr-3" />
                                                            {idioma.negocio}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className={path == "/ecossistema/incubadora/:id_incubadora/usuarios" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadora/" + incubadora.id + "/usuarios/"}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="user-astronaut" size="1x" color="black" className="mr-3" />
                                                            {idioma.usuarios}
                                                        </span>
                                                    </Link>
                                                </div>
                                                {credenciais.length > 0 ?
                                                    (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9 || pertence) ?
                                                        <>
                                                            {credenciais.map(credencial => ((credencial.id_componente == 54) ?
                                                                < div className={path == "/incubadoras/:id_incubadora/configuracoes" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/incubadoras/" + incubadora.id + "/configuracoes"}>
                                                                        <span className="text-perfil">
                                                                            <FontAwesomeIcon icon="cogs" size="1x" color="black" className="mr-3" />
                                                                            {idioma.config}
                                                                        </span>
                                                                    </Link>
                                                                </div>
                                                                : ""))}
                                                        </>
                                                        : ""
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-md-12 mb-3">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <span className="text-perfil">
                                                    <Skeleton count={1} width={80} />
                                                </span>
                                            </div>
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <span className="text-perfil">
                                                    <Skeleton count={1} width={80} />
                                                </span>
                                            </div>
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <span className="text-perfil">
                                                    <Skeleton count={1} width={80} />
                                                </span>
                                            </div>
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <span className="text-perfil">
                                                    <Skeleton count={1} width={80} />
                                                </span>
                                            </div>
                                        </div >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

const mapStatetoProps = state => ({
    minhasIncubadoras: state.incubadoras.minhasIncubadoras,
    usuarios: state.startups.usuarios,


})
export default connect(mapStatetoProps, actions)(PerfilIncubadora);