import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardBody, UncontrolledCollapse, CustomInput } from 'reactstrap';
import validator from 'validator';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { GET_MODULOS, GET_MATERIAS } from '../../store/actions/types';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class CadastrarQuestao extends Component {
    state = {
        nome: "",
        id_questao: "",
        id_materia: "",
        id_atividade: "",
        id_trilha: "",
        id_curso: "",
        id_modulo_selecionado: "",
        tipo_campo: "",
        opcao: "",
        ordem: "",
        ordem_opcao: "",
        descricao: "",
        conteudo: "",
        erro: "",
        success: "",
        carregando: "",
        dadosApi: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_trilha, id_atividade, id_modulo, id_materia } = this.props.match.params;
        this.setState({ id_trilha })
        this.setState({ id_modulo })
        this.setState({ id_materia })
        this.setState({ id_atividade })
        this.listar(id_trilha)
        this.listarTrilha(id_trilha)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }

    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.aula && nextProps.aula) {
            this.listar(id_trilha);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparModulos()
    }

    receberDadosAPI() {
        if (typeof this.props.questao != "undefined" && this.props.questao != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.materias != "undefined" && this.props.materias != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }


    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    cadQuestao() {
        const { id_questao, id_atividade, nome, ordem, tipo_campo, id_trilha, id_modulo, id_materia } = this.state;
        this.setState({ carregando: true })
        var titulo = nome
        var id_curso = id_trilha
        this.props.postQuestao({ id_atividade, titulo, ordem, tipo_campo, id_curso, id_modulo, id_materia }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.setState({ id_questao: msg.erro.id_questao })

            }
        }
        )
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    cadOpcao() {
        const { opcao, id_questao, ordem_opcao, id_trilha, id_modulo, id_materia } = this.state;
        var ordem = ordem_opcao
        var id_curso = id_trilha
        this.setState({ carregando: true })
        this.props.postOpcao({ opcao, id_questao, ordem, id_curso, id_modulo, id_materia }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.eraseOption("opcao")

            }
        }
        )
    }

    listar(id_trilha) {
        this.props.getModulos(id_trilha)
        const { trilhas } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilhas === "undefined") return null
    }

    listarMateria(id_trilha, id_modulo) {
        ////////////console.log(id_modulo)
        this.props.getMaterias(id_trilha, id_modulo)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (id_trilha === "undefined") return null
    }

    handleSelectedtipo(campo, ev) {
        this.setState({ [campo]: ev.target.value })
        this.setState({ tipo_campo: ev.target.value })

    }

    eraseOption(campo) {
        this.setState({ [campo]: "" })
    }

    render() {
        const { nome, opcao, id_questao, ordem_opcao, duracao, idioma, id_trilha, id_materia, id_modulo, id_aula, id_atividade, descricao, id_modulo_selecionado, ordem, tipo_campo, erro, success, carregando, iconSalvar, formSuccess, dadosApi } = this.state;
        var materias = [];
        if (this.props.materias) materias = this.props.materias.dados;
        var modulos = [];
        if (this.props.modulos) modulos = this.props.modulos.dados.modulos;
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;

        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path

        ////////console.log(formSuccess, tipo_campo)
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarQuestoes.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item">
                            <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" cor="primary" /> </Link>
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                                <AlertaErro erros={erro} />
                                <AlertaSuccess erros={success} />
                                <div>
                                    <Form>
                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarQuestoes.nome_quest}</Label>
                                                    <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrarQuestoes.nome_quest} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Label for="ordem">{idioma.cadastrarQuestoes.selecione_ord}</Label>
                                                <Input type="number" value={ordem} onChange={(ev) => this.onChnageInput("ordem", ev)} id="ordem" name="ordem" placeholder={idioma.cadastrarQuestoes.ordem_ques} />
                                            </Col>
                                            <Col md={4}>
                                                <Input type="hidden" value={id_atividade} id="id_atividade" name="id_atividade" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={6}>
                                                <Label for="tipo_campo">{idioma.cadastrarQuestoes.tipo_quest}</Label>
                                                <Input type="select" onChange={(ev) => this.onChnageInput("tipo_campo", ev)} id="tipo_campo" name="tipo_campo" >
                                                    <option>{idioma.cadastrarQuestoes.escolher_tipo}</option>
                                                    <option value="multipla">
                                                        {idioma.cadastrarQuestoes.mutipla}
                                                    </option>
                                                    <option value="unica">
                                                        {idioma.cadastrarQuestoes.escolher_tipo}
                                                    </option>
                                                    <option value="curta">
                                                        {idioma.cadastrarQuestoes.curta}
                                                    </option>
                                                    <option value="longa">
                                                        {idioma.cadastrarQuestoes.paragrafo}
                                                    </option>
                                                </Input>
                                            </Col>
                                        </Row>
                                        <Link to="#" onClick={() => this.cadQuestao()}> <Botao loading={carregando} texto="Cadastrar questão" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                    </Form>
                                    {formSuccess && tipo_campo == 'unica' ?
                                        <Form>
                                            {tipo_campo == "unica" ?
                                                <div className="mb-3">
                                                    <Label for="opcao">{idioma.cadastrarQuestoes.descreva}</Label>
                                                    <Input type="text" value={opcao} autoComplete="opcao" onChange={(ev) => this.onChnageInput("opcao", ev)} name="opcao" id="opcao" placeholder={idioma.cadastrarQuestoes.opcao} className="mb-3" />
                                                    <Label for="ordem_opcao">{idioma.CadastrarQuestao.selecione_ord}</Label>
                                                    <Input type="number" value={ordem_opcao} onChange={(ev) => this.onChnageInput("ordem_opcao", ev)} id="ordem_opcao" name="ordem_opcao" placeholder={idioma.cadastrarQuestoes.ordem_op} className="mb-3" />
                                                    <Link to="#" onClick={() => this.cadOpcao(id_questao, opcao)}> <Botao loading={carregando} texto={idioma.cadastrarQuestoes.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                                    <Link to="#" onClick={(ev) => this.eraseOption("opcao")}> <Botao loading={carregando} texto={idioma.cadastrarQuestoes.bt_cancelar} cor="danger" icone="times" posicao="E" /> </Link>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/editar"} className="mt-3"> <Botao loading={carregando} texto={idioma.cadastrarQuestoes.bt_finalizar} cor="success" icone="user-astronaut" posicao="E" /> </Link>

                                                </div>
                                                : ""}
                                        </Form>
                                        : formSuccess && tipo_campo == 'multipla' ?
                                            <Form>
                                                {tipo_campo == "multipla" ?
                                                    <div className="mb-3">
                                                        <Label for="opcao">{idioma.cadastrarQuestoes.descreva}</Label>
                                                        <Input type="text" value={opcao} autoComplete="opcao" onChange={(ev) => this.onChnageInput("opcao", ev)} name="opcao" id="opcao" placeholder={idioma.cadastrarQuestoes.opcao} className="mb-3" />
                                                        <Label for="ordem_opcao">{idioma.CadastrarQuestao.selecione_ord}</Label>
                                                        <Input type="number" value={ordem_opcao} onChange={(ev) => this.onChnageInput("ordem_opcao", ev)} id="ordem_opcao" name="ordem_opcao" placeholder={idioma.cadastrarQuestoes.ordem_op} className="mb-3" />
                                                        <Link to="#" onClick={() => this.cadOpcao(id_questao, opcao)}> <Botao loading={carregando} texto={idioma.cadastrarQuestoes.bt_cadastrar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                                        <Link to="#" onClick={(ev) => this.eraseOption("opcao")}> <Botao loading={carregando} texto={idioma.cadastrarQuestoes.bt_cancelar} cor="danger" icone="times" posicao="E" /> </Link>
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/editar"} className="mt-3"> <Botao loading={carregando} texto={idioma.cadastrarQuestoes.bt_finalizar} cor="success" icone="user-astronaut" posicao="E" /> </Link>

                                                    </div>
                                                    : ""}
                                            </Form>
                                            :
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/editar"} className="mt-3"> <Botao loading={carregando} texto={idioma.cadastrarQuestoes.bt_finalizar} cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    modulos: state.trilhas.modulos,
    materias: state.trilhas.materias,
    trilha: state.trilhas.trilha,
    questoes: state.trilhas.questoes
})

export default connect(mapStateToProps, actions)(CadastrarQuestao);