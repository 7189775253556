export const portugues = {
  titulo: 'Relatórios',
  bt_voltar: 'Voltar',
  label_select: 'Escolher incubadora',
  bt_gerar: 'Gerar relatório',
  total: 'Total',
  resolvidos: 'Resolvidos',
  solucoes: 'Solucoes',
  evidencias: 'Evidências',
  mentorias: 'Mentorias',
  atv: 'Atividades',
  aulas: 'Aulas',
  metodologia: 'Jornada de Desenvolvimento',
  nome: 'Nome',
  media: 'Média',
  hub: 'Hub de inovações',
  desenv: 'Desenvolvimento',
  entregas: 'Entregas',
  aceitas: 'Aceitas',
  geral: 'Geral',
  pilares: 'Pilares',
  negocios: 'Negócios',
  segmento: 'Segmento',
  faturamento: 'Faturamento',
  conteudos: 'Conteúdos Negócios',
  conteudos2: 'Conteúdos Incubadora'
}

export const ingles = {
  titulo: 'Reports',
  bt_voltar: 'Back',
  label_select: 'Select incubator',
  bt_gerar: 'Generate report',
  total: 'Total',
  resolvidos: 'Resolved',
  solucoes: 'Solutions',
  evidencias: 'Evidences',
  mentorias: 'Mentorships',
  atv: 'Activities',
  aulas: 'Classes',
  metodologia: 'Methodology',
  nome: 'Name',
  media: 'Media',
  hub: 'Innovations hub',
  desenv: 'Development',
  entregas: 'Deliveries',
  aceitas: 'Accepted',
  geral: 'General',
  pilares: 'Pillars',
  negocios: 'Business',
  segmento: 'Segment',
  faturamento: 'Revenues',
  conteudos: 'Business contents',
  conteudos2: 'Incubator contents'
}