import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BlocoPerfil from '../../containers/blocos/perfil';
import SemDados from '../../componentes/Mensagens/semDados';
import Swal from 'sweetalert2';
import Skeleton from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';
import parse from 'html-react-parser'
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import urlParser from "js-video-url-parser";

import ReactTooltip from "react-tooltip";
import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import { Button, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import RadarEtapa from '../../componentes/Graficos/RadarEtapa';
import RadarComparativo from '../../componentes/Graficos/RadarComparativo';
import { apiEvidencia } from '../../config';
import RadarDetalhes from '../../componentes/Graficos/RadarDetalhes';
import Chart from 'react-apexcharts'


class Processo extends Component {
    state = {
        id_usuario: '',
        id_startup: "",
        id_incubadora: "",
        id_metodologia: "",
        id_processo: "",
        id_entrega: '',
        tipo: '',
        link: '',
        resposta: '',
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        editorState: EditorState.createEmpty(),
        botaoRadar: false,
        pertence: false,
        modal: false,
        dadosApi: false,
        editarRadar: false,
        idioma: portugues,
        msg_backend: portuguese,
        tabActive: '',
        tabIndex: 0,
        etapaAtividades: '',
        showEvidences: false,
        pilarEtapa: [],
        etapasComparativo: [],
        displayBotao: false,
        aprovado: '',
        observacao: '',
        modalIsOpen: false,
        mentoriaIsOpen: false,
        series: [{
            name: 'Visão geral',
            data: [],
        },],
        options: {
            chart: {
                height: 250,
                type: 'radar',
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                }
            },
            stroke: {
                width: 2
            },
            fill: {
                opacity: 0.1
            },
            markers: {
                size: 0
            },
            xaxis: {
                categories: [],
                labels: {
                    show: true
                }
            },
            yaxis: {
                min: 0,
                max: 5,
                forceNiceScale: true
            }
        },
    }
    componentDidMount() {
        const { id_startup, id_incubadora, id_metodologia, id_processo } = this.props.match.params;
        if (typeof this.props.usuario === 'undefined') {
            localStorage.setItem('urlPerso', this.props.match.params.perso)
            localStorage.setItem('urlStartup', id_startup)
            localStorage.setItem('urlMetodologia', id_metodologia)
            localStorage.setItem('urlProcesso', id_processo)
        }
        if (this.props.authorized) {
            const { usuario } = this.props;
            this.setState({ id_usuario: usuario.id });
            this.setState({ id_startup })
            this.setState({ id_metodologia })
            this.setState({ id_processo })
            this.setState({ id_incubadora })
            this.listarEtapasStartup(id_startup, id_metodologia, id_processo);
            this.listarMetodologias();
            this.listarStartup(id_startup);
            this.listarProcesso(id_processo);
            this.verificaPertence(this.props.usuario.id, id_startup)
            this.getProgresso();
            if (this.props.credenciais) this.handleCredenciais(this.props.credenciais, this.state.botaoRadar)
            if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

            if (usuario?.idioma?.id === 3) {
                this.setState({ idioma: ingles })
                this.setState({ msg_backend: english })
            } else {
                this.setState({ idioma: portugues })
                this.setState({ msg_backend: portuguese })
            }
        }

    }
    componentDidUpdate(nextProps) {
        const { id_startup, id_metodologia, id_processo } = this.props.match.params;
        if (!this.props.etapas && nextProps.etapas) {
            this.listarEtapasStartup(id_startup, id_metodologia, id_processo);
            this.listarMetodologias();
        }
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id_startup);
        }
        if (!this.props.processo && nextProps.processo) {
            this.listarProcesso(id_processo);
        }
        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_startup);
        }


        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparEtapas()
        this.props.limparUsuarios()
    }

    listarStartup(id_startup) {
        this.props.getVerStartup(id_startup);
        const { startup } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }

    listarProcesso(id_processo) {
        this.props.getVerProcesso(id_processo);
        const { processo } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (processo === "undefined") return null
    }

    getProgresso() {
        const { id_startup, id_processo } = this.props.match.params;
        this.props.getProgressoProcesso(id_processo, id_startup);
        const { progressoProcesso } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (progressoProcesso === "undefined") return null
    }

    receberDadosAPI() {
        const { tabIndex } = this.state;
        if (typeof this.props.credenciais != "undefined" && this.props.credenciais != null && typeof this.props.etapas != "undefined" && this.props.etapas != null && !this.state.dadosApi) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                const ids = '';
                this.setState({ dadosApi: true });
                this.listarEstagios(this.props.etapas.dados[tabIndex].etapa.id);
                this.listarPilaresRadar(this.props.etapas.dados[tabIndex].etapa.id)
                this.getIdsEtapas();
            }
        }
    }

    getIdsEtapas() {
        let ids = '';
        for (let i = 0; i < this.props.etapas.dados.length; i++) {
            if (i + 1 === this.props.etapas.dados.length) {
                ids += `id=${this.props.etapas?.dados[i]?.etapa?.id}`
            } else {
                ids += `id=${this.props.etapas?.dados[i]?.etapa?.id}&`
            }
        }

        this.listarEtapasStatus(ids);
    }

    listarEtapasStartup(id, id_metodologia, id_processo) {
        this.props.getEtapasStartup(id, id_metodologia, id_processo);
        const { etapas } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (etapas === "undefined") return null

        if (etapas?.dados) this.setState({ tabActive: etapas?.dados[0]?.etapa?.nome });
    }

    listarEtapasStatus(id_etapas) {
        const { id_startup } = this.state;
        this.props.getEtapasStatus(id_startup, id_etapas);
        const { etapasStatus } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (etapasStatus === "undefined") return null
    }

    listarMetodologias() {
        this.props.getMetodologias();
        const { todasMetodologias } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (todasMetodologias === "undefined") return null
    }

    listarUsuarios() {
        this.props.getUsuarios();
        const { usuarios } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (usuarios === "undefined") return null
    }

    handleCredenciais(credenciais, botaoRadar) {
        for (let c in credenciais) {
            if (credenciais[c].id_componente === 66) {
                this.setState({ botaoRadar: true })
            }
        }
    }

    handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
        if (usuarios.startups.length > 0) {
            for (let u in usuarios.startups) {
                if (usuarios.startups[u].id_startup === id_startup) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (usuarios.incubadoras.length > 0) {
            for (let u in usuarios.incubadoras) {
                if (usuarios.incubadoras[u].id_incubadora === id_incubadora) {
                    this.setState({ pertence: true })
                }
            }
        }
        if (credenciais[0].id_perfil === 1 || credenciais[0].id_perfil === 9) {
            this.setState({ pertence: true })
        }

    }

    verificaPertence(id_usuario, id_startup) {
        // console.log(id_usuario, id_startup)
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)
    }

    listarEstagios(id_etapa) {
        const { id_startup, id_metodologia, id_processo } = this.props.match.params;
        this.props.getEstagiosStartup(id_startup, id_metodologia, id_processo, id_etapa);
        const { estagios } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (estagios === "undefined") return null
    }

    async listarPilaresRadar(id_etapa) {
        const { pilarEtapa } = this.state;
        const { id_startup, id_metodologia, id_processo } = this.props.match.params;
        await this.props.getDetalhesRadar(id_startup, id_metodologia, id_processo, id_etapa);
        const { detalhes } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (detalhes === "undefined") {
            return null
        }

    }

    handleSelectedTab(tabName, index, id_etapa) {
        this.setState({ tabActive: tabName });
        this.setState({ tabIndex: index });
        this.listarEstagios(id_etapa);
        this.listarPilaresRadar(id_etapa);

    }

    baixarEntrega(fileName) {
        window.open(apiEvidencia + fileName, "_blank")
    }

    handleCollapsed() {
        const docNode = document.querySelectorAll('#details-collapse[open]');
        if (docNode.length > 0) {
            docNode.forEach((item) => {
                item.removeAttribute('open')
            })
        }
    }

    handleBorderColor(code) {
        if (code === 'ST1') return '#007BFF'
        if (code === 'ST2') return '#FFC107'
        if (code === 'ST5' || code === 'ST6') return '#DC3545'
        if (code === 'ST4') return '#28A745'
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    handleBotao() {
        const { displayBotao } = this.state;
        this.setState({ displayBotao: !displayBotao });
    }

    deletar(id) {
        const { tabIndex } = this.state;
        this.setState({ dadosApi: false });
        this.setState({ carregando: true })
        this.props.deletarEvidencia(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarEstagios(this.props.etapas.dados[tabIndex].etapa.id)
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
            }
        })
    }

    sucessoAlerta(msg) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: msg
        })
    }

    chamarDeletar(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.evidencias.modal_titulo,
            text: idioma.evidencias.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.participantes.bt_cancelar,
            confirmButtonText: idioma.evidencias.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.deletar(id);
            }
        })
    }

    validate() {
        const { aprovado, idioma } = this.state;
        if (!aprovado) return this.setState({ erro: { message: idioma.evidencias.erro_validacao } });
        return true;
    }

    async enviarEntrega() {
        const { id_usuario, id_startup, aprovado, observacao, tabIndex, id_evidencia } = this.state;
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.postValidacao({ id_usuario, id_evidencia, id_startup: Number(id_startup), aprovado, observacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ mentoriaIsOpen: false });
                this.listarEstagios(this.props.etapas.dados[tabIndex].etapa.id);
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
                this.getIdsEtapas();
            }
        })
    }

    onChangeFileInput = (field, ev) => {
        this.setState({ [field]: ev.target.files[0] });
    }

    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    validateEntrega() {
        const { aprovado, idioma, tipo, file, link, editorState } = this.state;
        if (!tipo) return this.setState({ erro: { message: idioma.evidencias.erro_tipo } });
        if (tipo == "arquivo" || tipo == "imagem" || tipo == "pdf") {
            if (!file) return this.setState({ erro: { message: idioma.evidencias.erro_arquivo } });
        }
        if (tipo == "link") {
            if (!link) return this.setState({ erro: { message: idioma.evidencias.erro_link } });
        }
        if (tipo == "texto") {
            const texto = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            if (texto.length < 9) return this.setState({ erro: { message: idioma.evidencias.erro_link } });
        }
        return true;
    }
    async realizarNovaEntrega(linkYT) {
        const { id_startup, editorState, tipo, tabIndex, id_entrega, id_usuario } = this.state;
        if (!this.validateEntrega()) return;
        this.setState({ success: "" });
        this.setState({ erro: "" });
        this.setState({ carregando: true })
        // console.log(linkYT)
        if (linkYT != undefined && tipo == "link") {
            if (linkYT.includes("youtube")) {
                var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id
            } else if (linkYT.includes("youtu.be")) {
                var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id
            }
        }
        if (tipo == "outros") {
            var link = linkYT
        }
        var resposta = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        var tipo_campo = tipo
        const formData = new FormData();
        formData.append('id_entrega', id_entrega)
        formData.append('id_startup', id_startup)
        formData.append('id_usuario', id_usuario)
        formData.append('link', link)
        formData.append('resposta', resposta)
        formData.append('tipo_campo', tipo_campo)
        formData.append('file', this.state.file);

        await this.props.postEntrega(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.sucessoAlerta(this.state.msg_backend[msg.erro.code]);
                this.setState({ carregando: false });
                this.setState({ modalIsOpen: false });
                this.setState({ tipo: '' });
                this.getIdsEtapas();
                this.listarEstagios(this.props.etapas.dados[tabIndex].etapa.id);
            }
        });
    }

    render() {
        const { msg, erro, success, tabActive, pilarEtapa, editorState, tipo, link, modalIsOpen, mentoriaIsOpen, carregando, tabIndex, displayBotao, showEvidences, dadosApi, editarRadar, id_incubadora, id_startup, id_metodologia, id_processo, botaoRadar, idioma } = this.state;
        var etapas = [];
        var etapasStatus = [];
        var estagios = [];
        var pilares = [];
        var startup = null;
        var credenciais = [];
        var usuario = [];
        var progresso = 0;
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.startup) startup = this.props.startup;
        if (this.props.etapas) etapas = this.props.etapas.dados;
        if (this.props.usuario) usuario = this.props.usuario;
        if (this.props.estagios) estagios = this.props.estagios.dados;
        if (this.props.detalhes) pilares = this.props.detalhes.dados.pilares;
        if (this.props.progressoProcesso) progresso = this.props.progressoProcesso.entregas;
        if (this.props.etapasStatus) etapasStatus = this.props.etapasStatus.dados;
        var path = this.props.match.path;
        var ultimaAlteracao = null;
        for (let e in estagios) {
            if (estagios[e].estagio.ultima_evidencia > ultimaAlteracao || ultimaAlteracao == null) { ultimaAlteracao = estagios[e].estagio.ultima_evidencia }
        }
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.acompanhamento.titulo} />
                </div>
                <div className="container-fluid bloco-topo">
                    {startup != null ?
                        usuarioPertence.pertence &&
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {usuarioPertence.pertence ?
                        <div>
                            <div className='loadder-container'>
                                <div className='loadder-titles'>
                                    <p />
                                    <p>{idioma.acompanhamento.entregas}</p>
                                    <p />
                                </div>
                                <div className='loadder-solid' style={{ width: `${progresso.toFixed(2)}%` }} />
                                {/* <div className='loadder-solid' style={{ width: `${progresso.toFixed(2)}%` }}> <p style={{ fontSize: '12px', textAlign: '-webkit-right', marginRight: '10px' }}> {progresso.toFixed(0) > 5 || progresso.toFixed(0) < 49 || progresso.toFixed(0) < 60 ? progresso.toFixed(0) + '%' : ''} </p></div> */}
                            </div>
                            <div className="col-md-12 mb-2">
                                <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                    <div className="container-fluid">
                                        <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.1rem' }}>{idioma.acompanhamento.modulos}</p>
                                        <div className="row">
                                            {etapasStatus.map((etapa, index) => (
                                                etapa.etapa.inicial == 1 ?
                                                    <div
                                                        id={etapa.etapa.nome}
                                                        className="section-tabs menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"
                                                        style={
                                                            {
                                                                width: `calc(95% / ${etapas.length})`,
                                                                flexDirection: 'column',
                                                                backgroundColor: tabIndex === index ? usuario?.personalizacao?.cor_secundaria : '#F1F1F1',
                                                                color: tabIndex === index ? usuario?.personalizacao?.cor_texto_menu_hover : '',
                                                                borderRadius: '5px',
                                                                borderLeft: `6px solid ${this.handleBorderColor(etapa.etapa.status)}`,
                                                                fontSize: '0.9rem',
                                                                marginLeft: '5px'
                                                            }}
                                                        data-toggle="tab"
                                                        role="tab"
                                                        onClick={() =>
                                                            this.handleSelectedTab(etapa.etapa.nome, index, etapa.etapa.id)}
                                                    >
                                                        {etapa.etapa.nome}
                                                    </div>
                                                    : ""
                                            ))}
                                            {etapasStatus.map((etapa, index) => (
                                                etapa.etapa.inicial == 0 ?
                                                    <div
                                                        id={etapa.etapa.nome}
                                                        className="section-tabs menu-cabecalho menu-centralizado botao-responsivo cursor-clique hover-this"
                                                        style={
                                                            {
                                                                width: `calc(95% / ${etapas.length})`,
                                                                flexDirection: 'column',
                                                                backgroundColor: tabIndex === index ? usuario?.personalizacao?.cor_secundaria : '#F1F1F1',
                                                                color: tabIndex === index ? usuario?.personalizacao?.cor_texto_menu_hover : '',
                                                                borderRadius: '5px',
                                                                borderLeft: `6px solid ${this.handleBorderColor(etapa.etapa.status)}`,
                                                                fontSize: '0.9rem',
                                                                marginLeft: '5px'
                                                            }}
                                                        data-toggle="tab"
                                                        role="tab"
                                                        onClick={() =>
                                                            this.handleSelectedTab(etapa.etapa.nome, index, etapa.etapa.id)}
                                                    >
                                                        {etapa.etapa.nome}
                                                    </div>
                                                    : ""
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className='list-group-item' style={{ border: '4px solid #f1f1f1' }}>
                                    <p className='module-description'>
                                        <i>
                                            {etapas[tabIndex]?.etapa?.descricao ? parse("" + etapas[tabIndex]?.etapa?.descricao) : ""}
                                        </i>
                                    </p>
                                    {etapas[tabIndex]?.etapa?.inicial == 0 ?
                                        <div>
                                            {/* <p className='module-lastedit'>última alteração: {etapas[tabIndex]?.etapa?.datahora_alteracao ? format(new Date(etapas[tabIndex]?.etapa?.datahora_alteracao), 'dd/MM/yyyy', { locale: pt }) : '---'}</p> */}
                                            {/* <p className='module-lastedit'>última alteração: {ultimaAlteracao ? format(new Date(ultimaAlteracao), 'dd/MM/yyyy', { locale: pt }) : '---'}</p> */}
                                            <div className='section-labels_container'>
                                                <div className='section-labels_content'>
                                                    <div className='section-labels_circle' style={{ backgroundColor: '#007BFF' }} />
                                                    <p>{this.state.msg_backend['ST1']}</p>
                                                </div>
                                                <div className='section-labels_content'>
                                                    <div className='section-labels_circle' style={{ backgroundColor: '#FFC107' }} />
                                                    <p>{this.state.msg_backend['ST2']}</p>
                                                </div>
                                                <div className='section-labels_content'>
                                                    <div className='section-labels_circle' style={{ backgroundColor: '#DC3545' }} />
                                                    <p>{this.state.msg_backend['ST5']}</p>
                                                </div>
                                                <div className='section-labels_content'>
                                                    <div className='section-labels_circle' style={{ backgroundColor: '#28A745' }} />
                                                    <p>{this.state.msg_backend['ST4']}</p>
                                                </div>
                                            </div>
                                            <div className="titulo-principal-jornada">
                                                <h5 className='section-title-etapa'>{idioma.acompanhamento.atividades}</h5>
                                                {ultimaAlteracao ?
                                                    <div className="module-lastedit">
                                                        <span className='module-lastedit'>última atividade entregue em {format(new Date(ultimaAlteracao), 'dd/MM/yyyy', { locale: pt })} </span>
                                                    </div>
                                                    : ""
                                                }
                                            </div>

                                            {estagios.length <= 0 ? (
                                                <SemDados titulo={'Sem atividades'} subtitulo={'Não existem informações a serem exibidas'} />
                                            ) :
                                                estagios.map((estagio) => (
                                                    <details id='details-collapse' class="collapse">
                                                        <summary
                                                            class="collapse-title"
                                                            style={{ borderLeft: `6px solid ${this.handleBorderColor(estagio.estagio.status)}` }}
                                                            onClick={() => {
                                                                this.setState({ showEvidences: false });
                                                            }}>
                                                            <p>{estagio.estagio.nome}</p>
                                                            <p><i>{estagio.estagio.descricao}</i></p>
                                                        </summary>
                                                        <div class="collapse-body">
                                                            <div className='collapse-body_title'>
                                                                {estagio.entregas.map((entrega) => (
                                                                    <>
                                                                        <div style={{
                                                                            height: showEvidences ? 'auto' : '450px',
                                                                            overflow: 'hidden',
                                                                            transition: 'all 0.6s',
                                                                        }}>
                                                                            <div className='collapse-body_header'>
                                                                                <p className='collapse-section_title'>{entrega.entrega.nome}</p>
                                                                            </div>
                                                                            <p className='collapse-section_subtitle'><i>{entrega.entrega.descricao}</i></p>
                                                                            <Link onClick={() => {
                                                                                this.setState({ modalIsOpen: true });
                                                                                this.setState({ id_entrega: entrega.entrega.id });
                                                                                this.setState({ tipo: entrega.entrega.tipo_campo })
                                                                            }}>
                                                                                <Botao texto={idioma.evidencias.nova_entre} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                                                            </Link>
                                                                            <div>
                                                                                <Modal
                                                                                    centered
                                                                                    isOpen={modalIsOpen && this.state.id_entrega == entrega.entrega.id}
                                                                                >
                                                                                    <ModalHeader>
                                                                                        Nova entrega
                                                                                    </ModalHeader>
                                                                                    <ModalBody>
                                                                                        <AlertaErro erros={erro} />
                                                                                        <Form>
                                                                                            <FormGroup>
                                                                                                <Label for="tipo">{idioma.entrega.tipo}</Label>
                                                                                                <Input type="select" onChange={(ev) => this.onChangeInput("tipo", ev)} id="tipo" name="tipo" disabled={entrega.entrega.tipo_campo == null ? false : true}>
                                                                                                    <option>{idioma.entrega.escolher}</option>
                                                                                                    <option value="pdf" selected={entrega.entrega.tipo_campo == "pdf"}>
                                                                                                        PDF
                                                                                                    </option>
                                                                                                    <option value="imagem" selected={entrega.entrega.tipo_campo == "imagem"}>
                                                                                                        {idioma.entrega.img}
                                                                                                    </option>
                                                                                                    <option value="link" selected={entrega.entrega.tipo_campo == "link"}>
                                                                                                        {idioma.entrega.vid}
                                                                                                    </option>
                                                                                                    <option value="texto" selected={entrega.entrega.tipo_campo == "texto"}>
                                                                                                        {idioma.entrega.tex}
                                                                                                    </option>
                                                                                                    <option value="arquivo" selected={entrega.entrega.tipo_campo == "arquivo"}>
                                                                                                        {idioma.entrega.arq}
                                                                                                    </option>
                                                                                                </Input>
                                                                                            </FormGroup>
                                                                                            {tipo == "arquivo" || tipo == "imagem" || tipo == "pdf" ?
                                                                                                <FormGroup className="mt-3">
                                                                                                    <Label for="file">{idioma.entrega.doc}</Label>
                                                                                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeFileInput("file", ev)} accept={tipo == "imagem" ? "image/*" : tipo == "pdf" ? ".pdf" : ""} />
                                                                                                </FormGroup>
                                                                                                : tipo == "link" ?
                                                                                                    <FormGroup className="mt-3">
                                                                                                        <Label for="link">{idioma.entrega.link}</Label>
                                                                                                        <Input type="text" id="link" name="link" className="form-control" autoComplete="link" onChange={(ev) => this.onChangeInput("link", ev)} />
                                                                                                    </FormGroup>
                                                                                                    : tipo == "texto" ?
                                                                                                        <FormGroup className="mt-3">
                                                                                                            <Editor
                                                                                                                editorState={editorState}
                                                                                                                toolbarClassName="toolbarClassName"
                                                                                                                wrapperClassName="wrapperClassName"
                                                                                                                editorClassName="editorClassName"
                                                                                                                onEditorStateChange={this.onEditorStateChange}
                                                                                                            />
                                                                                                        </FormGroup>
                                                                                                        : ""
                                                                                            }
                                                                                            <Link onClick={() => this.realizarNovaEntrega(link)} to="#">
                                                                                                <Botao loading={carregando} classes="botao-responsivo botao-estilo" texto={idioma.entrega.bt_enviar} cor="success" icone="rocket" posicao="E" />
                                                                                            </Link>
                                                                                            <Link onClick={() => {
                                                                                                this.setState({ modalIsOpen: false })
                                                                                                this.setState({ tipo: '' })
                                                                                            }}> <Botao texto={idioma.participantes.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                                                                        </Form>
                                                                                    </ModalBody>
                                                                                </Modal>
                                                                            </div>
                                                                            {entrega.evidencias.map((evidencia => (
                                                                                <>
                                                                                    <section
                                                                                        id='cd-timeline'
                                                                                        className='cd-container section-gradient'>
                                                                                        <div className="cd-timeline-block" key={evidencia.evidencia.id} >
                                                                                            <div className={(evidencia.mentorias.length != 0) ? evidencia.mentorias.map(mentoria => ((mentoria.aprovado == "Sim") ? "cd-timeline-img bg-success" : "cd-timeline-img bg-danger")) : "cd-timeline-img bg-warning"}>
                                                                                                <i className="fas fa-check"></i>
                                                                                            </div>
                                                                                            <div className="cd-timeline-content">

                                                                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>

                                                                                                    {evidencia.evidencia.datahora ? <div className="module-lastedit"> <span className='module-lastedit'>Enviado em: {format(new Date(evidencia.evidencia.datahora), 'dd/MM/yyyy hh:mm:ss', { locale: pt })}</span></div> : ""}
                                                                                                    {(evidencia.mentorias.length != 0) ? evidencia.mentorias.map(mentoria => ((mentoria.aprovado == "Sim") ? <span className="badge badge-pill badge-success mb-1">Validado</span> : <span className="badge badge-pill badge-danger mb-1">{idioma.evidencias.nao_validade}</span>)) : <span className="badge badge-pill badge-warning mb-1">{idioma.evidencias.aguar_validado}</span>}
                                                                                                </div>
                                                                                                {evidencia.evidencia.tipo_campo == "texto" ? <div className="p-2">{parse("" + evidencia.evidencia.resposta)}</div> : ""}
                                                                                                {evidencia.evidencia.fileName != null &&
                                                                                                    evidencia.evidencia.tipo_campo != "arquivo" ?
                                                                                                    <>
                                                                                                        {evidencia.evidencia.tipo_campo == "imagem" ?
                                                                                                            <strong>{idioma.evidencias.imagens}</strong>
                                                                                                            : evidencia.evidencia.tipo_campo == "pdf" &&
                                                                                                            <strong>{idioma.evidencias.arquivos}</strong>
                                                                                                        }
                                                                                                        <div class="embed-responsive embed-responsive-16by9 mb-4 mt-5">
                                                                                                            <iframe class="embed-responsive-item" src={apiEvidencia + evidencia.evidencia.fileName} allowfullscreen></iframe>
                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    evidencia.evidencia.tipo_campo === 'arquivo' && (
                                                                                                        <div className="mb-2">
                                                                                                            {/* <strong>{idioma.evidencias.arquivos}</strong> */}
                                                                                                            <span className="pl-2" ><Link onClick={() => this.baixarEntrega(evidencia?.evidencia?.fileName)}>{evidencia?.evidencia?.originalName}</Link></span>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                                {evidencia.evidencia.link != "undefined" &&
                                                                                                    <div class="embed-responsive embed-responsive-16by9 mb-4 mt-5">
                                                                                                        <iframe title='evidecnia' class="embed-responsive-item" src={evidencia?.evidencia?.link} allowfullscreen></iframe>
                                                                                                    </div>
                                                                                                }
                                                                                                <div style={{ display: 'flex' }}>
                                                                                                    {credenciais.map(credencial => ((credencial.id_componente === 12) ?
                                                                                                        evidencia.mentorias.length === 0 &&
                                                                                                        <span className=" p-2" onClick={() => {
                                                                                                            this.setState({ mentoriaIsOpen: true });
                                                                                                            this.setState({ id_evidencia: evidencia.evidencia.id });
                                                                                                        }}><Button type="button" color="success">{idioma.acompanhamento.bt_aprovarevidencia}</Button></span>

                                                                                                        : ""))}
                                                                                                    {usuarioPertence.pertence && credenciais.map(credencial => ((credencial.id_componente == 81) &&
                                                                                                        <span className="p-2" onClick={() => this.chamarDeletar(evidencia.evidencia.id)}><Button type="button" color="danger">{idioma.evidencias.del_evidencia}</Button></span>
                                                                                                    ))}
                                                                                                </div>
                                                                                                <br /><br />
                                                                                                <AlertaErro erros={erro} />
                                                                                                {evidencia.mentorias.length === 0 ?
                                                                                                    <Modal
                                                                                                        centered
                                                                                                        isOpen={mentoriaIsOpen}
                                                                                                    >
                                                                                                        <ModalHeader>
                                                                                                            Nova mentoria
                                                                                                        </ModalHeader>
                                                                                                        <ModalBody>
                                                                                                            <Form>
                                                                                                                {credenciais.map(credencial => ((credencial.id_componente === 12) ?

                                                                                                                    <div key={credencial.id_componente}>
                                                                                                                        <div className="p-4">
                                                                                                                            <Label>{idioma.evidencias.apro_evidencia}</Label>
                                                                                                                            <FormGroup check>
                                                                                                                                <Label check>
                                                                                                                                    <Input type="radio" name="aprovado" id="aprovado" value="Sim" onChange={(ev) => this.onChangeInput("aprovado", ev)} />{' '}Sim</Label>
                                                                                                                            </FormGroup>
                                                                                                                            <FormGroup check>
                                                                                                                                <Label check>
                                                                                                                                    <Input type="radio" name="aprovado" id="aprovado" value="Não" onChange={(ev) => this.onChangeInput("aprovado", ev)} />{' '}Não</Label>
                                                                                                                            </FormGroup>
                                                                                                                            <FormGroup>
                                                                                                                                <Label for="exampleText">{idioma.acompanhamento.mentorias}</Label>
                                                                                                                                <Input type="textarea" name="observacao" id="observacao" onChange={(ev) => this.onChangeInput("observacao", ev)} />
                                                                                                                                <Input type="hidden" value={evidencia.evidencia.id} id="id_evidencia" name="id_evidencia" />

                                                                                                                            </FormGroup>
                                                                                                                            <span className="d-none d-md-block">
                                                                                                                                <Link onClick={() => this.enviarEntrega()}> <Botao loading={carregando} texto='Salvar' icone="check" posicao="E" cor="success" /> </Link>
                                                                                                                                <span className=" p-2" onClick={() => this.setState({ mentoriaIsOpen: false })}><Button type="button" color="danger">Cancelar</Button></span>
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    : ""))}
                                                                                                            </Form>
                                                                                                        </ModalBody>
                                                                                                    </Modal>
                                                                                                    :
                                                                                                    <div className="observacoes">
                                                                                                        {evidencia.mentorias.map(mentoria => (
                                                                                                            <div className="p-2 pl-4">
                                                                                                                <strong>{idioma.acompanhamento.mentorias} </strong>{mentoria.observacao}
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </section>
                                                                                </>
                                                                            )))
                                                                            }
                                                                        </div>
                                                                        {entrega.temEvidencia && <button onClick={() => this.setState({ showEvidences: !showEvidences })} className='show-evidences'>{showEvidences ? `${idioma.acompanhamento.bt_recolherentregas}▲` : `${idioma.acompanhamento.bt_verentregas}▼`}</button>}
                                                                    </>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </details>
                                                ))}
                                        </div>
                                        : ""}
                                    <div className="titulo-principal-jornada">
                                        <h5 className='section-title-etapa'>{idioma.acompanhamento.pilares}</h5>
                                        {etapas[tabIndex]?.mediaEtapa?.data_ultima_resposta ?
                                            <div className="module-lastedit">
                                                <span className='datahora-etapa'>última atualização em {format(new Date(etapas[tabIndex]?.mediaEtapa?.data_ultima_resposta), 'dd/MM/yyyy HH:mm', { locale: pt })}</span>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                    <details class="collapse">
                                        <summary class="collapse-title borderless">
                                            <p>{idioma.acompanhamento.visao_geral}</p>
                                        </summary>
                                        <div class="collapse-body">
                                            <div className='collapse-body_title'>
                                                <div className="text-center">
                                                    <span className={etapas[tabIndex]?.mediaEtapa?.radarAplicado ? "badge badge-pill badge-success" : "badge badge-pill badge-danger"}>
                                                        {etapas[tabIndex]?.mediaEtapa?.radarAplicado ? idioma.acompanhamento.aplicado : idioma.acompanhamento.naoAplicado}
                                                    </span>{' '}
                                                </div>
                                                <a data-tip data-for='radarAplicado'>
                                                    <Link style={{ cursor: etapas[tabIndex]?.mediaEtapa?.radarAplicado ? 'pointer' : 'not-allowed' }} to={`/${this.props.usuario?.personalizacao?.titulo}/startups/${id_startup}/acompanhamento/${id_metodologia}/processos/${id_processo}/etapa/${etapas[tabIndex]?.etapa?.id}/radar/resumo`} >
                                                        <div id='radarAplicado' style={{ opacity: etapas[tabIndex]?.mediaEtapa?.radarAplicado ? '1' : '0.3' }}>
                                                            {etapas.length > 0 && (
                                                                etapas.map((etapa, index) => (
                                                                    index == tabIndex ? (
                                                                        <RadarEtapa pesoMaximo={etapa.mediaEtapa.peso_maximo ? etapa.mediaEtapa.peso_maximo : 5} pilares={etapa.pilares} />
                                                                    ) : ""
                                                                ))
                                                            )}
                                                        </div>
                                                    </Link>
                                                </a>
                                                <ReactTooltip id='radarAplicado' type='dark'><span>{idioma.acompanhamento.detalhes}</span></ReactTooltip>
                                                {credenciais.map(credencial => ((credencial.id_componente === 38) ?
                                                    usuarioPertence.pertence ?
                                                        <div className={etapas[tabIndex]?.mediaEtapa?.radarAplicado ? "display-none" : "botao-radar pt-1"}>
                                                            <Link to={`/${this.props.usuario?.personalizacao?.titulo}/startups/${id_startup}/acompanhamento/${id_metodologia}/processos/${id_processo}/etapa/${etapas[tabIndex]?.etapa?.id}/radar/`} key={credencial.id_componente}>
                                                                <button className="mr-1 btn btn-success btn-sm btn-block">{idioma.acompanhamento.aplicar}</button>
                                                            </Link>
                                                        </div>
                                                        : ""
                                                    : ""))}
                                                {etapas[tabIndex]?.mediaEtapa?.radarAplicado ?
                                                    <div className="text-center">
                                                        <span className={etapas[tabIndex]?.mediaEtapa?.radarAplicado === 0 ? "badge badge-danger media-radar" : "badge badge-success media-radar"} >
                                                            {idioma.acompanhamento.media}: {etapas[tabIndex]?.mediaEtapa?.mediaEtapa}
                                                        </span>
                                                    </div>
                                                    : ""}

                                            </div>
                                        </div>
                                    </details>


                                    <details class="collapse">
                                        <summary class="collapse-title borderless">
                                            <p>{idioma.acompanhamento.comparativo}</p>
                                        </summary>
                                        <div class="collapse-body">
                                            <div className='collapse-body_title'>
                                                <div className='collapse-body_header_chart'>
                                                    {/* <Chart options={this.state.options} series={this.state.series} type="radar" height={500} width={900} /> */}
                                                    {etapas.length > 0 && (
                                                        etapas.map((etapa, index) => (
                                                            index == tabIndex ?
                                                                <RadarComparativo pesoMaximo={etapa.mediaEtapa.peso_maximo ? etapa.mediaEtapa.peso_maximo : 5} etapas={etapas} index={tabIndex} /> : ""
                                                        ))
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </details>


                                    {/* {pilares.map((pilar) => (
                                        <details class="collapse">
                                            <summary class="collapse-title borderless">
                                                <p>{pilar.pilar.nome}</p>
                                            </summary>
                                            {pilar.mediaPilar.temMedia ? (
                                                <div class="collapse-body">
                                                    <Link to={`/${this.props.usuario?.personalizacao?.titulo}/startups/${id_startup}/acompanhamento/${id_metodologia}/processos/${id_processo}/etapa/${etapas[tabIndex]?.etapa?.id}/radar/resumo`}>
                                                        <div className='collapse-body_title'>
                                                            <div className='collapse-body_header'>
                                                                <RadarDetalhes pesoMaximo={pilar.mediaPilar.peso_maximo ? pilar.mediaPilar.peso_maximo : 5} pilares={pilar.radares} />
                                                                <div className={pilar.mediaPilar.temMedia ? "text-center" : "display-none"}>
                                                                    <span className={pilar.mediaPilar.temMedia == 0 ? "badge badge-danger media-radar" : "badge badge-success media-radar"} >
                                                                        {idioma.radarResumo.media} {pilar.mediaPilar.mediaPilar}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className='collapse-body'>
                                                    <SemDados titulo={'Sem dados!'} subtitulo={'Gráfico disponível assim que todos os requisitos forem preenchidos.'} />
                                                </div>
                                            )}
                                        </details>
                                    ))} */}
                                </div>
                            </div>
                        </div>
                        : ""}
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    etapas: state.startups.etapas,
    estagios: state.startups.estagios,
    progressoProcesso: state.startups.progressoProcesso,
    entrega: state.startups.entrega,
    detalhes: state.startups.detalhes,
    etapasStatus: state.startups.etapasStatus,
    startup: state.startups.startup,
    listaMetodologia: state.startups.todasMetodologias,
    usuarios: state.usuarios.usuarios,
    processo: state.startups.processo,
    pertence: state.startups.pertence,
    usuario: state.auth.usuario
})
export default connect(mapStatetoProps, actions)(Processo);
