import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Swal from 'sweetalert2';
import { Badge } from 'reactstrap';

import { portugues, ingles } from '../../utils/sidebar_bilingue';

const Sidebar = ({ ativo, handleLogout, credenciais, notificacoes, usuario, dadosUsuario }) => {
    const [idioma, setIdioma] = useState()

    useEffect(() => {
        if (usuario) {
            setIdioma(usuario.idioma.id === 3 ? ingles : portugues)
        }
    }, [usuario]);

    const onHover = (ev) => {
        // if (usuario.personalizacao.id === 1) {
        //     ev.target.closest("a").style.backgroundColor = '#354358';
        //     ev.target.closest("a").className = "menu-hover"
        //     return
        // }
        ev.target.closest("a").style.backgroundColor = usuario?.personalizacao?.cor_secundaria
        ev.target.closest("a").style.color = usuario?.personalizacao?.cor_texto_menu_hover
        ev.target.closest("a").className = "menu-hover"
    }

    const cleanHover = (ev) => {
        ev.target.closest("a").style.backgroundColor = 'transparent'
        ev.target.closest("a").style.color = usuario?.personalizacao?.cor_texto_menu
        ev.target.closest("a").className = ""
    }

    return (


        <nav style={{ backgroundColor: usuario?.personalizacao && usuario.personalizacao.cor_principal }} className={ativo ? "sidebar" : "sidebar toggled"} >

            {
                credenciais ?
                    <ul className="list-unstyled">
                        {/* <div><li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} > <Link to={`/${usuario?.personalizacao?.titulo}/dashboard`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="space-shuttle" size='lg' /><span style={{ fontSize: '14px', marginLeft: '15px' }}>{idioma?.dashboard}</span></Link></li ></div> */}
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 10) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/startups/painel`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="user-astronaut" size='lg' style={{ marginLeft: '4px' }} /><span style={{ fontSize: '14px', marginLeft: '20px' }}>{idioma?.negocios}</span></Link></li>
                                </div> : ""
                            ))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 5) ?
                                <div>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/incubadoras/painel`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="warehouse" size='md' style={{ marginLeft: '4px' }} /><span style={{ fontSize: '14px', marginLeft: '20px' }}>{idioma?.ambiente_inovacao}</span></Link></li>
                                </div> : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 5) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/gestao`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="user-astronaut" size='lg' style={{ marginLeft: '4px' }} /><span style={{ fontSize: '14px', marginLeft: '20px' }}>{idioma?.gestao_negocios}</span></Link></li>
                                </div> : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 1) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/ecossistema`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="building" size='lg' style={{ marginLeft: '4px' }} /><span style={{ fontSize: '14px', marginLeft: '20px' }}>{idioma?.ecossistema}</span></Link></li>
                                </div> : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 4) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/trilhas`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="graduation-cap" size='lg' /><span style={{ fontSize: '14px', marginLeft: '15px' }}>{idioma?.trilhas}</span></Link></li>
                                </div> : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 2) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} > <Link to={`/${usuario?.personalizacao?.titulo}/eventos`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="satellite-dish" size='lg' style={{ marginLeft: '4px' }} /><span style={{ fontSize: '14px', marginLeft: '15px' }}>{idioma?.eventos}</span></Link></li>
                                </div> : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 3) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/conteudos`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="lightbulb" size='lg' style={{ marginLeft: '6px' }} /><span style={{ fontSize: '14px', marginLeft: '20px' }}>{idioma?.conteudos}</span></Link></li>
                                </div> : ""))
                        }

                        {
                            credenciais.map(credencial => ((credencial.id_componente == 6) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/hub`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="project-diagram" size='lg' /><span style={{ fontSize: '14px', marginLeft: '15px' }}>{idioma?.hub_inovacao}</span></Link></li>
                                </div> : ""))
                        }
                        {/* {credenciais.map(credencial => ((credencial.id_componente == 76) ?
                        <div >
                            <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to="#"><FontAwesomeIcon icon="user-astronaut" size='lg'/> Mentores</Link></li>
                        </div>
                        : ""))} */}
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 77) ?
                                <div>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/relatorios`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="chart-bar" size='lg' /><span style={{ fontSize: '14px', marginLeft: '20px' }}>{idioma?.relatorios}</span></Link></li>
                                </div>
                                : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 78) ?
                                <div>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/chat`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}>
                                        <FontAwesomeIcon icon="comments" size='lg' />
                                        {notificacoes && notificacoes > 0 &&
                                            <span class="notification position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                                                {notificacoes}
                                            </span>
                                        }
                                        <span style={{ fontSize: '14px', marginLeft: '18px' }}>
                                            Chat
                                        </span>
                                    </Link></li>
                                </div>
                                : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 79) ?
                                <div>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/beneficios`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="medal" size='lg' /><span style={{ fontSize: '14px', marginLeft: '20px' }}>{idioma?.beneficios}</span></Link></li>
                                </div>
                                : ""))
                        }
                        {
                            credenciais.map(credencial => ((credencial.id_componente == 7) ?
                                <div key={credencial.id_componente}>
                                    <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)} ><Link to={`/${usuario?.personalizacao?.titulo}/configuracoes`} style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }}><FontAwesomeIcon icon="cogs" size='lg' /><span style={{ fontSize: '14px', marginLeft: '15px' }}>{idioma?.config}</span></Link></li>
                                </div> : ""))
                        }
                        <li onMouseOver={(e) => onHover(e)} onMouseOut={(e) => cleanHover(e)}> <Link to="#" style={{ color: usuario?.personalizacao && usuario.personalizacao.cor_texto_menu }} onClick={() => Swal.fire({
                            title: idioma?.sair_modal.info,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: 'green',
                            cancelButtonColor: '#d33',
                            confirmButtonText: idioma?.sair_modal.bt1
                        }).then((result) => {
                            if (result.isConfirmed) {
                                sessionStorage.setItem('url-perso', usuario.personalizacao.titulo)
                                handleLogout()
                            }
                        })}> <FontAwesomeIcon icon="door-open" size='lg' /><span style={{ fontSize: '14px', marginLeft: '15px' }}> {idioma?.sair}</span></Link></li>
                    </ul >
                    :
                    <ul className="list-unstyled">
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                        <li>
                            <Skeleton circle={true} height={10} width={10} />
                            <div className="subtitle">
                                <Skeleton count={1} width={10} />
                            </div>
                        </li>
                    </ul>}
        </nav >
    )
}

export default Sidebar;