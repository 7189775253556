import { LOGIN_USER, LOGOUT_USER, ERRO_USER, LOGIN_ERRO_USER, ALT_SIT_MENU, GET_NOTIFICACOES } from '../actions/types';
export default (state = {}, actions) => {
    switch (actions.type) {
        case LOGIN_USER:
            return {
                ...state,
                usuario: actions.payload.dados,
                authorized: true,
            }
        case LOGOUT_USER:
            return {
                ...state,
                usuario: null,
                authorized: false,
                notificacoes: null,
            }
        case GET_NOTIFICACOES:
            return {
                ...state,
                notificacoes: actions.payload.data,
            }
        case ALT_SIT_MENU:
            return {
                ...state,
                sitMenu: actions.payload
            }
        case LOGIN_ERRO_USER:
            return {
                ...state,
                mensagem: actions.payload.dados,
            }
        default:
            return state;
    }
}