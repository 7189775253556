import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfil';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class VincularIncubadora extends Component {
    state = {
        id_startup: "",
        id_incubadora: "",
        id: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        dadosIncubadora: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        this.setState({ id })
        this.listarIncubadoras();
        this.listarStartup(id)

        if (!this.props.incubadora) {
            if (this.props.startup.id_incubadora != "") {
                // this.setState({ id_incubadora: this.props.startup.id_incubadora })
                this.listarIncubadora(this.props.startup.id_incubadora)

            }
        }


        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentWillUnmount() {
        this.props.limparIncubadora();
        this.props.limparIncubadoras();
        this.props.limparStartup()
    }



    chamarAlertaParticipante(listaUsuarios) {
        const { idioma } = this.state;
        var lista = [];
        var listaUsuarios = [];
        for (var i in listaUsuarios) {
            listaUsuarios = listaUsuarios[i].usuarios.nome
        }

        Swal.fire({
            title: idioma.perfil.modal_titulo2,
            input: 'select',
            inputOptions: lista,

            inputPlaceholder: idioma.perfil.modal_info,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.participantes.bt_cancelar,
            confirmButtonText: idioma.perfil.modal_confirm
        })
        return new Promise((resolve) => {
            resolve()
        })
    }

    componentDidUpdate(nextProps) {
        const { id } = this.props.match.params;
        if (!this.props.todasIncubadoras && nextProps.todasIncubadoras) {
            this.listarIncubadorasStartup(id);
            this.listarIncubadoras();
        }
        if (!this.props.startup && nextProps.startup) {
            this.listarStartup(id);

        }
        if (!this.props.incubadora && nextProps.incubadora) {
            this.listarIncubadora(this.props.startup.id_incubadora);

        }
        if (!this.props.incubadorasStartup && nextProps.incubadorasStartup) {
            this.listarStartup(id);
        }
        this.receberDadosAPI();
        this.receberDadosIncubadora();
    }


    receberDadosAPI() {
        if (typeof this.props.todasIncubadoras != "undefined" && this.props.todasIncubadoras != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.incubadora != "undefined" && this.props.incubadora != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
        if (typeof this.props.startup != "undefined" && this.props.startup != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    receberDadosIncubadora() {
        if (typeof this.props.incubadora != "undefined" && this.props.incubadora != null && !this.state.dadosIncubadora) {
            this.setState({ dadosIncubadora: true });
        }
    }


    listarIncubadora(id_incubadora) {
        this.props.getVerIncubadora(id_incubadora);
        const { incubadora } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadora === "undefined") return null
    }

    listarStartup(id) {
        this.props.getVerStartup(id);
        const { startup } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (startup === "undefined") return null
    }

    listarIncubadoras() {
        this.props.getTodasIncubadoras(1, 100);
        const { todasIncubadoras } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (todasIncubadoras === "undefined") return null
    }

    listarUsuarios() {
        this.props.getUsuarios();
        const { usuarios } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (usuarios === "undefined") return null
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })

    }


    vincularIncubadora() {
        const { id_incubadora, id } = this.state;
        this.setState({ carregando: true })
        ////////////console.log(id_incubadora + "" + id)
        this.props.putStartup({ id_incubadora, id }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    render() {
        const { msg, carregando, erro, success, dadosApi, dadosIncubadora, idioma } = this.state;
        var startup = null;
        if (this.props.startup) startup = this.props.startup;
        var todasIncubadoras = [];
        if (this.props.todasIncubadoras) todasIncubadoras = this.props.todasIncubadoras.dados;
        var listaUsuarios = [];
        if (this.props.listaUsuarios) listaUsuarios = this.props.listaUsuarios.dados;
        var incubadora = [];
        if (this.props.incubadora) incubadora = this.props.incubadora;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path


        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.perfil.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {startup != null ?
                        <BlocoPerfil dadosApi={dadosApi} startup={startup} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.participantes.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>
                            <div className="d-flex">
                                <div className="col-md-12 p-2">
                                    {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />

                                    {dadosApi ?
                                        <div>
                                            {dadosIncubadora ?
                                                < div >
                                                    <h5>{idioma.vincularIncubadora.info} {incubadora.nome}</h5>
                                                </div>
                                                :
                                                <Form>
                                                    <Row form>
                                                        <Col md={12}>
                                                            <FormGroup>
                                                                <Label for="metodologia">{idioma.vincularIncubadora.vincular}</Label>
                                                                <Input type="select" onChange={(ev) => this.onChnageInput("id_incubadora", ev)} id="id_incubadora" name="id_incubadora">
                                                                    <option>{idioma.vincularIncubadora.escolher}</option>
                                                                    {todasIncubadoras.map(incubadoras => (
                                                                        <option value={incubadoras.id} key={incubadoras.id}>
                                                                            {incubadoras.nome}
                                                                        </option>
                                                                    ))}
                                                                </Input>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/acompanhamento"} onClick={() => this.vincularIncubadora()}> <Botao loading={carregando} texto={idioma.editarMetodologia.bt_vincular2} cor="success" /> </Link>
                                                </Form>
                                            }
                                        </div>
                                        : <Form>
                                            <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={150} />

                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Skeleton count={1} width={600} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Link to="#" onClick={() => this.vincularIncubadora()}> <Botao loading={carregando} texto={idioma.editarMetodologia.bt_vincular2} cor="success" /> </Link>
                                        </Form>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    incubadora: state.startups.incubadora,
    startup: state.startups.startup,
    todasIncubadoras: state.startups.todasIncubadoras,
    usuarios: state.usuarios.usuarios
})
export default connect(mapStatetoProps, actions)(VincularIncubadora);
