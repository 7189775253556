import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/chat';
import { Link, StaticRouter } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import SemDados from '../../componentes/Mensagens/semDados';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { Spinner, Input, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Badge } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/astronaut.png'
import LoadingSkeleton from '../../componentes/Skeleton/index';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { addDays, format, isAfter, isBefore, isEqual, isToday, addHours } from 'date-fns';
import ScrollIntoView from 'react-scroll-into-view'
import pt from 'date-fns/locale/pt-BR';
import { apiImagem } from '../../config/';
import TextTruncate from 'react-text-truncate';
import Toast from '../../componentes/Alertas/Toast';
import parse from 'html-react-parser';

import { ingles, portugues } from '../../utils/paginachat_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

import ReactTooltip from "react-tooltip";
import Titulo from '../../componentes/TituloPaginas';

class Chat extends Component {

	chatContainer = React.createRef();


	state = {
		id_destinatario: "",
		mensagem: "",
		id_usuario: "",
		id_usuario2: "",
		id_chat: "",
		currentPage: 1,
		size: 100,
		sizeChat: 10,
		msg: "",
		erro: "",
		success: "",
		procura: "",
		broadcastIncubadora: false,
		carregando: false,
		pesquisando: false,
		previaMensgem: false,
		nome: "",
		tipo: "",
		tipo_escolha: "",
		menu: false,
		scroll: false,
		scrollDone: false,
		filtro: false,
		criar: false,
		modal: false,
		dadosApi: false,
		dadosApiChat: false,
		idioma: portugues,
		msg_backend: portuguese
	}
	componentDidMount() {
		if (typeof this.props.usuario === 'undefined') {
			localStorage.setItem('urlChat', this.props.match.params.id_usuario2)
			localStorage.setItem('urlPerso', this.props.match.params.perso)
		}
		this.props.getUsuario();
		const { id_usuario2 } = this.props.match.params
		if (this.props.authorized) {
			if (this.props.usuario) {
				this.setState({ id_usuario: this.props.usuario.id })
			}

			if (id_usuario2 !== undefined) {
				// console.log(id_usuario2)
				this.listarChats(this.props.usuario.id)
				this.verificaChat(this.props.usuario.id, id_usuario2)
			}
			if (this.props.usuario) { this.listarChats(this.props.usuario.id) }
			this.listarIncubadoras()
			this.listarStartups()
			this.listarPerfis()
		}

		const { usuario } = this.props;

		if (usuario) this.setState({ id_usuario: usuario.id })
		if (usuario?.idioma?.id === 3) {
			this.setState({ idioma: ingles })
			this.setState({ msg_backend: english })
		} else {
			this.setState({ idioma: portugues })
			this.setState({ msg_backend: portuguese })
		}
	}
	componentDidUpdate(nextProps) {
		const { id_usuario2 } = this.props.match.params;
		if (!this.props.chats && nextProps.chats && this.props.usuario) this.listarChats(this.props.usuario.id);
		if (!this.props.incubadoras && nextProps.incubadoras) this.listarIncubadoras();
		if (!this.props.startups && nextProps.startups) this.listarStartups();
		if (!this.props.perfis && nextProps.perfis) this.listarPerfis();
		if (id_usuario2 && !this.props.verificacao && nextProps.verificacao && !this.state.id_chat) {
			this.verificaChat(this.props.usuario.id, id_usuario2);
		}

		if (!this.props.mensagens && nextProps.mensagens && this.state.id_chat) {
			this.listarMensagens(this.state.id_chat, this.props.usuario.id)
		}

		localStorage.setItem('urlChat', '')
		localStorage.setItem('urlPerso', '')
		this.receberDadosAPI();
		this.receberDadosAPIChat();

	}

	componentWillUnmount() {
		this.props.limparChats();
		this.props.limparMensagens();
	}

	receberDadosAPIChat() {
		if (typeof this.props.mensagens != "undefined" && typeof this.props.usuario !== 'undefined' && this.props.mensagens != null && !this.state.dadosApiChat) {

			this.setState({ dadosApiChat: true });

			const { usuario } = this.props;

			if (usuario?.idioma?.id === 3) {
				this.setState({ idioma: ingles })
				this.setState({ msg_backend: english })
			} else {
				this.setState({ idioma: portugues })
				this.setState({ msg_backend: portuguese })
			}
		}
	}


	receberDadosAPI() {
		if (typeof this.props.chats != "undefined" && this.props.chats != null && !this.state.dadosApi) {
			this.setState({ dadosApi: true });
		}
		// if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && !this.state.dadosApi) {
		// 	if (typeof this.props.startups != "undefined" && this.props.startups != null && !this.state.dadosApi) {
		// 		if (typeof this.props.perfis != "undefined" && this.props.perfis != null && !this.state.dadosApi) {
		// if (typeof this.props.verificacao != "undefined" && this.props.verificacao != null && !this.state.dadosApi) {
		// }
		// 		}
		// 	}
		// }
	}

	listarChats(id_usuario) {
		const { page, sizeChat } = this.state;
		this.props.getChats(id_usuario, page, sizeChat)
		const { chats } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (chats === "undefined") return null
	}

	listarChatsBotao(id_usuario, page, sizeChat) {
		this.props.getChats(id_usuario, page, sizeChat)
		const { chats } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (chats === "undefined") return null
	}

	carregarMaisConversas() {
		const { page } = this.state;
		let atualSize = this.state.sizeChat;
		atualSize += 10;
		this.setState({ sizeChat: atualSize })
		this.setState({ dadosApi: false })
		this.listarChatsBotao(this.state.id_usuario, page, atualSize)
	}

	listarIncubadoras() {
		const { currentPage, size } = this.state
		this.props.getIncubadorasAtivas(currentPage, size)
		const { incubadoras } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (incubadoras === "undefined") return null
	}

	listarStartups() {
		const { currentPage, size } = this.state
		this.props.getStartupsAtivas(currentPage, size)
		const { startups } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (startups === "undefined") return null
	}

	verificaChat(id_usuario1, id_usuario2) {
		const { id_usuario } = this.state
		this.props.getVerificaChat(id_usuario1, id_usuario2, (msg) => {
			if (msg.erro.error) {
				// console.log(msg.erro.message)
				this.setState({ carregando: false });
			} else {
				// console.log(msg.erro)
				this.setState({ id_chat: msg.erro.id_chat })
				if (msg.erro.dados.id_usuario1 == id_usuario) {
					this.setState({ id_destinatario: msg.erro.dados.id_usuario2 })
				} else {
					this.setState({ id_destinatario: msg.erro.dados.id_usuario1 })
				}
				this.listarChats(this.props.usuario.id)
				this.listarMensagens(msg.erro.id_chat, this.props.usuario.id)
			}
		})
		const { verificacao } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (verificacao === "undefined") return null
	}

	listarPerfis() {
		const { currentPage, size } = this.state
		this.props.getPerfis(currentPage, size)
		const { perfis } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (perfis === "undefined") return null

	}

	listarMensagens(id_chat, id_usuario) {
		this.props.getMensagens(id_chat, id_usuario)
		const { mensagens } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (mensagens === "undefined") return null
		if (mensagens != null) {


			// if (mensagens.dados.length > 0) {
			// 	this.scrollToMyRef()

			// }
		}
	}


	onChangeInput(campo, ev) {
		this.setState({ [campo]: ev.target.value })
	}

	mandarMensagem(id_chat, previa) {
		const { id_usuario, id_destinatario, msg_backend } = this.state;
		this.setState({ scroll: false })
		var id_autor = id_usuario
		this.setState({ carregando: true })
		var novaPrevia = previa
		previa.split(" ").forEach(character2 => {
			if (character2.includes("http")) {
				let character2Novo = "<a target='_blank' href='" + character2 + "'>" + character2 + "</a>"

				novaPrevia = previa.replace(character2, character2Novo)


			}
		})


		var mensagem = novaPrevia
		this.setState({ previaMensgem: novaPrevia })

		this.setState({ mensagem: "" })
		this.props.postMensagem({ id_chat, id_autor, id_destinatario, mensagem }, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ success: "" });
				this.setState({ carregando: false });
			} else {
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ erro: "" });
				this.setState({ carregando: false });
				this.setState({ formSuccess: true });
				this.setState({ mensagem: "" })
				this.setState({ scroll: true })
				this.listarChats(id_usuario)
				this.scrollToMyRef()
				this.listarMensagens(id_chat, id_usuario)

			}
		}
		)
	}

	cadConversa(id_usuario2) {
		const { id_usuario, msg_backend } = this.state;
		var id_usuario1 = id_usuario
		this.setState({ carregando: true })
		this.props.postChat({ id_usuario1, id_usuario2 }, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ success: "" });
				this.setState({ carregando: false });
			} else {
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ erro: "" });
				this.setState({ carregando: false });

			}
		}
		)
	}

	chamarCadConversa(usuarios) {
		const { idioma } = this.state
		var lista = []
		for (let u in usuarios) {
			lista[usuarios[u].id] = usuarios[u].nome
		}
		Swal.fire({
			title: idioma.modal_titulo,
			input: 'select',
			inputOptions: lista,
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				this.cadConversa(result.value)
			}
		})
	}

	cadArquivo(id_chat, file, mensagem) {
		const { id_usuario, id_destinatario, msg_backend } = this.state;
		var id_autor = id_usuario
		this.setState({ carregando: true })

		var novaMensagem = mensagem
		mensagem.split(" ").forEach(character2 => {
			if (character2.includes("http")) {
				let character2Novo = "<a target='_blank' href='" + character2 + "'>" + character2 + "</a>"

				novaMensagem = mensagem.replace(character2, character2Novo)


			}
		})
		const formData = new FormData();
		formData.append('id_chat', id_chat)
		formData.append('id_autor', id_autor)
		formData.append('id_destinatario', id_destinatario)
		formData.append('mensagem', novaMensagem)
		for (let f in file) {
			formData.append('file', file[f]);
		}
		this.props.postMensagem(formData, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ success: "" });
				this.setState({ carregando: false });
			} else {
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ erro: "" });
				this.setState({ carregando: false });
				this.listarMensagens(id_chat, id_usuario)
				this.setState({ mensagem: "" })
				this.listarChats(id_usuario)
			}
		}
		)
	}


	chamarArquivo(id_chat, mensagem) {

		const { idioma } = this.state
		Swal.fire({
			title: idioma.modal_titulo2,
			input: 'file',
			inputAttributes: {
				'multiple': 'multiple',
			},
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				this.cadArquivo(id_chat, result.value, mensagem)
			}
		})
	}

	buscar(procura) {
		this.setState({ procura: procura })
		this.setState({ pesquisando: true })
		const { id_usuario } = this.state;
		this.props.getSearchNameChats(id_usuario, procura)
	}
	eraseSearch(campo) {
		const { id_usuario } = this.state
		this.setState({ pesquisando: false })
		this.setState({ [campo]: "" })
		this.listarChats(id_usuario)
	}


	handleUsers(campo, ev) {
		this.setState({ [campo]: ev.target.value })
		const { tipo, currentPage, size } = this.state

		if (tipo == "Incubadora") {
			this.props.limparUsuarios()
			this.props.getUsuariosIncubadora(ev.target.value, currentPage, size)

		} else if (tipo == "Startup") {
			this.props.limparUsuarios()
			this.props.getParticipantes(ev.target.value, currentPage, size)

		} else if (tipo == "Perfil") {
			this.props.limparUsuarios()
			this.props.getUsuariosPerfil(ev.target.value, currentPage, size)

		}
		const { usuarios } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (usuarios === "undefined") return null
	}

	cadChat(id_usuario2) {
		const { id_usuario, msg_backend } = this.state;
		var id_usuario1 = id_usuario
		// console.log(id_usuario2)
		this.setState({ pesquisando: false })
		this.props.postChat({ id_usuario1, id_usuario2 }, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ success: "" });
				this.setState({ carregando: false });
			} else {
				// console.log(msg)
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ erro: "" });
				this.setState({ id_chat: msg.erro.id_chat })
				this.setState({ id_destinatario: id_usuario2 })
				this.listarMensagens(msg.erro.id_chat, id_usuario)
				this.setState({ carregando: false });
				this.listarChats(id_usuario)
				this.forceUpdate()
			}
		}
		)
	}

	cadConversaPorNome(id_usuario1, procura) {
		this.props.getChatPorNome(id_usuario1, procura)
		const { usuarios } = this.props;
		if (this.props.location.state) {
			this.setState({ msg: this.props.location.state.msg })
			this.props.location.state.msg = "";
		}
		if (usuarios === "undefined") return null
		//console.log(usuarios)

		this.chamarCadConversaNome(id_usuario1, usuarios.dados)

	}

	chamarCadConversaNome(id_usuario1, usuarios) {
		const { idioma } = this.state
		//console.log(usuarios)
		let lista = []
		for (let u in usuarios) {
			lista[usuarios[u].id] = usuarios[u].nome
		}
		Swal.fire({
			title: idioma.modal_titulo3,
			input: 'select',
			inputOptions: lista,
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				this.cadChat(result.value)
			}
		})
	}

	filtrarChat(id_usuario2) {
		const { id_usuario } = this.state;
		var id_usuario1 = id_usuario
		this.setState({ pesquisando: true })
		this.props.getSearchChatsUser(id_usuario1, id_usuario2)
	}

	handleChat(id_chat, chats) {
		const { id_usuario } = this.state
		for (let c in chats) {
			if (chats[c] != null) {
				if (chats[c].chats.id == id_chat) {
					if (chats[c].chats.id_usuario1 == id_usuario) {
						this.setState({ id_destinatario: chats[c].chats.id_usuario2 })
					} else if (chats[c].chats.id_usuario2 == id_usuario) {
						this.setState({ id_destinatario: chats[c].chats.id_usuario1 })
					}
				}
			}
		}
		this.setState({ pesquisando: false })
		this.setState({ dadosApiChat: true })
		this.setState({ id_chat })
		this.props.getUsuario();
		this.listarChats(id_usuario)
		this.listarMensagens(id_chat, id_usuario)



	}

	deletarMensagem(id, id_chat) {
		const { id_usuario, msg_backend } = this.state

		this.setState({ dadosApi: false });
		this.setState({ carregando: true })
		this.props.deletarMensagem(id, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ carregando: false });
			} else {
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ carregando: false });
				this.listarMensagens(id_chat, id_usuario)

			}
		})
	}

	chamarDeletarMensagem(id_mensagem, id_chat) {
		const { idioma } = this.state
		Swal.fire({
			title: idioma.modal_titulo4,
			text: idioma.modal4_info,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: idioma.bt_cancelar,
			confirmButtonText: idioma.bt_sucesso
		}).then((result) => {
			if (result.isConfirmed) {
				this.deletarMensagem(id_mensagem, id_chat)

			}
		})
	}

	deletarChat(id_chat, id_usuario1, id_usuario2) {
		const { id_usuario, msg_backend } = this.state
		this.setState({ dadosApi: false });
		this.setState({ carregando: true })
		// console.log(id_usuario1, id_usuario2)
		this.props.deletarChat(id_chat, id_usuario1, id_usuario2, (msg) => {
			if (msg.erro.error) {
				this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ carregando: false });
			} else {
				this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
				this.setState({ carregando: false });
				this.setState({ id_chat: "" })
				this.listarChats(id_usuario)

			}
		})
	}

	chamarDeletarChat(id_chat, chats) {
		const { id_usuario, idioma } = this.state
		let id_usuario1 = null
		let id_usuario2 = null
		for (let c in chats) {
			if (chats[c] != null) {
				if (chats[c].chats.id_usuario1 == id_usuario && chats[c].chats.id == id_chat) {
					id_usuario1 = id_usuario
				} else id_usuario2 = id_usuario
			}
		}
		Swal.fire({
			title: idioma.modal_titulo4,
			text: idioma.modal4_info,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: idioma.bt_cancelar,
			confirmButtonText: idioma.bt_sucesso
		}).then((result) => {
			if (result.isConfirmed) {
				this.deletarChat(id_chat, id_usuario1, id_usuario2)

			}
		})
	}

	handleMensagem(ev, tipo) {
		// console.log(ev)
		const { id_chat, mensagem, procura } = this.state;
		if (ev.key == 'Enter') {
			if (tipo == "mensagem") {
				this.mandarMensagem(id_chat, mensagem)
			} else {
				this.buscar(procura)
			}
		}
	}

	handleScroll() {
		window.scroll({
			top: document.body.offsetHeight,
			left: 0,
			behavior: 'smooth',
		});
	}
	handleFile(arquivo) {
		window.open(apiImagem + arquivo, "_blank")
	}

	scrollToMyRef = () => {
		if (this.chatContainer.current != null) {
			setTimeout(() => {
				const scroll =
					this.chatContainer.current?.scrollHeight -
					this.chatContainer.current?.clientHeight + 10;
				this.chatContainer.current?.scrollTo(0, scroll);
			}, 1000)
		}
	};


	//Responder chamado

	enviarBroadcast(id_autor, mensagem, file) {
		const { msg_backend } = this.state
		const { usuario } = this.props;
		this.setState({ dadosApi: false });
		this.setState({ carregando: true })

		var novaMensagem = mensagem
		mensagem.split(" ").forEach(character2 => {
			if (character2.includes("http")) {
				let character2Novo = "<a target='_blank' href='" + character2 + "'>" + character2 + "</a>"

				novaMensagem = mensagem.replace(character2, character2Novo)


			}
		})
		const formData = new FormData();
		formData.append('id_autor', id_autor)
		formData.append('mensagem', novaMensagem)
		for (let f in file) {
			formData.append('file', file[f]);
		}

		this.props.postBroadcast(formData, (msg) => {
			if (msg.erro.error) {
				Toast({ icone: 'error', titulo: 'Erro!', texto: msg_backend[msg.erro.code] })
				this.setState({ carregando: false });
			} else {
				Toast({ icone: 'success', titulo: 'Sucesso!', texto: msg_backend[msg.erro.code] })
				this.setState({ carregando: false });
				this.listarChats(id_autor)
				this.scrollToMyRef()
			}
		})
	}

	chamarBroadcast(id_usuario) {
		const { idioma } = this.state
		Swal.mixin({
			input: 'text',
			confirmButtonText: idioma.bt_proximo + ' &rarr;',
			showCancelButton: true,
			progressSteps: ['1', '2']
		}).queue([
			{
				title: idioma.msg,
				input: 'textarea',
				inputPlaceholder: idioma.msg_info
			},
			{
				title: idioma.arq_info,
				input: 'file',
				inputAttributes: {
					'multiple': 'multiple',
					'accept': 'image/*',
					'aria-label': 'Upload your profile picture'
				}
			},
		]).then((result) => {
			if (result) {
				if (result.value) {
					this.enviarBroadcast(id_usuario, result.value[0], result.value[1])
				}
			}
		})
	}

	enviarTodosUsuarios(id_usuario) {
		this.chamarBroadcastIncubadora(id_usuario, this.state.tipo_escolha)
	}

	chamarBroadcastIncubadora(id_usuario, tipo_escolha) {
		const { idioma } = this.state
		Swal.mixin({
			input: 'text',
			confirmButtonText: idioma.bt_proximo + ' &rarr;',
			showCancelButton: true,
			progressSteps: ['1', '2']
		}).queue([
			{
				title: idioma.msg,
				input: 'textarea',
				inputPlaceholder: idioma.msg_info_incubadora
			},
			{
				title: idioma.arq_info,
				input: 'file',
				inputAttributes: {
					'multiple': 'multiple',
					'accept': 'image/*',
					'aria-label': 'Upload your profile picture'
				}
			},
		]).then((result) => {
			if (result) {
				if (result.value) {
					this.enviarBroadcastIncubadora(id_usuario, tipo_escolha, result.value[0], result.value[1])
				}
			}
		})
	}
	enviarBroadcastIncubadora(id_autor, id_incubadora, mensagem, file) {
		const { msg_backend } = this.state
		const { usuario } = this.props;
		this.setState({ dadosApi: false });
		this.setState({ carregando: true })

		var novaMensagem = mensagem
		mensagem.split(" ").forEach(character2 => {
			if (character2.includes("http")) {
				let character2Novo = "<a target='_blank' href='" + character2 + "'>" + character2 + "</a>"
				novaMensagem = mensagem.replace(character2, character2Novo)
			}
		})
		const formData = new FormData();
		formData.append('id_autor', id_autor)
		formData.append('id_incubadora', id_incubadora)
		formData.append('mensagem', novaMensagem)
		for (let f in file) {
			formData.append('file', file[f]);
		}

		this.props.postBroadcastIncubadora(formData, (msg) => {
			if (msg.erro.error) {
				Toast({ icon: 'error', title: 'Erro!', text: msg_backend[msg.erro.code] })
				this.setState({ carregando: false });
			} else {
				Toast({ icon: 'success', title: 'Sucesso!', text: msg_backend[msg.erro.code] })
				this.setState({ carregando: false });
				this.listarChats(id_autor)
				this.scrollToMyRef()
			}
		})
	}

	render() {
		const { tipo_escolha, idioma, scroll, id_chat, dadosApiChat, menu, id_usuario, previaMensgem, id_usuario2, mensagem, tipo, msg, sizeChat, carregando, pesquisando, erro, success, dadosApi, procura, criar, filtro, id_destinatario, broadcastIncubadora } = this.state;
		var qtde = []
		let atualizando = false;
		var chats = []
		if (pesquisando) {
			if (this.props.chatsBusca) {
				if (this.props.chatsBusca.error == false) {
					chats = this.props.chatsBusca.dados.chats
				}
			}

		} else {
			if (this.props.chats) {
				chats = this.props.chats.dados.chats
				qtde = this.props.chats.qtde
			}
		}
		var semChat = []
		if (pesquisando == true) {
			if (this.props.chatsBusca) {
				if (this.props.chatsBusca.error == false) {
					semChat = this.props.chatsBusca.dados.semChat
				}
			}
		}
		var usuarios = []
		if (this.props.usuarios) usuarios = this.props.usuarios.usuarios
		var usuariosPerfil = []
		if (this.props.usuariosPerfil) usuariosPerfil = this.props.usuariosPerfil.dados

		var incubadoras = []
		if (this.props.incubadoras) incubadoras = this.props.incubadoras.dados
		var minhasIncuba = []
		if (this.props.usuario) minhasIncuba = this.props.usuario.incubadoras
		var minhasIncubadoras = []
		for (const m in minhasIncuba) {
			for (const i in incubadoras) {
				if (incubadoras[i].id == minhasIncuba[m].id_incubadora) {
					minhasIncubadoras.push(incubadoras[i])
				}
			}
		}
		var startups = []
		if (this.props.startups) startups = this.props.startups.dados
		var perfis = []
		if (this.props.perfis) perfis = this.props.perfis.dados
		var mensagens = []
		let scrollDone = true
		if (this.props.mensagens) {
			mensagens = this.props.mensagens.dados
		}

		if (mensagens.length > 0) {
			scrollDone = true

			this.scrollToMyRef()
		}
		var credenciais = []
		if (this.props.credenciais) credenciais = this.props.credenciais;
		if (qtde != sizeChat) atualizando = true;
		var verificacao = []
		if (this.props.verificacao) verificacao = this.props.verificacao
		return (
			<>
				<div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
					<Titulo titulo={idioma.titulo} />
				</div>
				<div className="container-fluid bloco-topo" >
					<div className="col-md-12 mb-2">
						<div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
							<div className="row">
								<div className="col-md-4">
									{filtro == true || criar == true ?
										<>
											<Input type="select" value={tipo} onChange={(ev) => this.onChangeInput("tipo", ev)} className="mt-2">
												<option>{idioma.label_tipo}</option>
												<option value="Incubadora">{idioma.ambiente}</option>
												<option value="Startup">{idioma.negocios}</option>
												<option value="Perfil">{idioma.perfil}</option>
											</Input>
											<Input type="select" value={tipo_escolha} onChange={(ev) => this.handleUsers("tipo_escolha", ev)} className="mt-2" disabled={tipo == "" ? true : false} >
												{tipo == "" ?
													<option>{idioma.filtro_info}</option>
													: ""}
												{tipo == "Incubadora" ?
													<>
														<option>{idioma.label_ambiente}</option>
														{incubadoras ?
															incubadoras.map(incubadora => (
																<option value={incubadora.id}>{incubadora.nome}</option>

															))
															: ""}
													</>
													: tipo == "Startup" ?
														<>
															<option>{idioma.label_negocio}</option>
															{startups ?
																startups?.map(startup => (
																	<option value={startup.id}>{startup.nome}</option>

																))
																: ""}
														</>
														: tipo == "Perfil" ?
															<>
																<option>{idioma.label_perfil2}</option>
																{perfis ?
																	perfis.map(perfil => (
																		<option value={perfil.id}>{perfil.nome}</option>

																	))
																	: ""}
															</>
															:
															<option>{idioma.label_perfil}</option>}

											</Input>
											<Input type="select" value={id_usuario2} onChange={(ev) => this.onChangeInput("id_usuario2", ev)} className="mt-2" disabled={tipo_escolha == "" ? true : false}>
												<option>{idioma.label_usuario}</option>
												{tipo == "Perfil" ?
													usuariosPerfil?.map(usuarios => (
														<option value={usuarios.id}>{usuarios.nome}</option>
													))
													: tipo == "Incubadora" ?
														<>
															{usuarios.map(usuario => (
																usuario.id == id_usuario ?
																	<option value="todosusuarios">{idioma.label_todos_usuarios}</option>
																	: ""))}
															{usuarios.map(usuario => (
																<option value={usuario.id}>{usuario.nome}</option>
															))}
														</>
														: usuarios.map(usuario => (
															<option value={usuario.id}>{usuario.nome}</option>
														))}
											</Input>
											{filtro == true ?
												<Link to="#" onClick={() => {
													id_usuario2 == "todosusuarios" ? this.enviarTodosUsuarios(id_usuario) : this.filtrarChat(id_usuario2)
												}}> <Botao loading={carregando} texto={id_usuario2 == "todosusuarios" ? idioma.bt_filtrar_broadcast : idioma.bt_filtrar} classes="botao-responsivo botao-estilo" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
												: ""}
											{criar == true ?
												<Link to="#" onClick={() => this.cadChat(id_usuario2)}> <Botao loading={carregando} texto={idioma.inicio_chat} classes="botao-responsivo botao-estilo" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
												: ""}
										</>
										: ""}
									<div className="row mt-2">
										<div className="col">
											<Input type="text" value={procura} onKeyPress={(ev) => this.handleMensagem(ev, "buscar")} onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.label_pesquisa} />
										</div>
										<span onClick={() => this.buscar(procura)}><BotaoIcon icone="search" classes="mr-2" color={this.props.usuario?.personalizacao?.cor_terciaria} /></span>
										<Link to="#" data-tip data-for='filtrar' onClick={() =>
											filtro == true ?
												this.setState({ filtro: false })
												:
												<>
													{this.setState({ filtro: true })}
													{this.setState({ criar: false })}
													{this.setState({ tipo: null })}
													{this.setState({ tipo_escolha: null })}
													{this.setState({ id_usuario2: null })}


												</>
										}> <BotaoIcon loading={carregando} classes="botao-responsivo mr-2" color={this.props.usuario?.personalizacao?.cor_terciaria} icone="filter" /> </Link>
										{/* <span onClick={() => this.cadConversaPorNome(id_usuario, procura, usuarios)}><BotaoIcon icone="plus" classes="mr-2" cor="success" /></span>
										<span onClick={() => this.eraseSearch("procura")}><BotaoIcon icone="times" cor="danger" /></span> */}
										{pesquisando == true ?
											<Link to="#" onClick={() =>
												<>
													{this.setState({ pesquisando: false })}
													{this.listarChats(id_usuario)}
												</>
											}> <BotaoIcon loading={carregando} classes="botao-responsivo mr-2" cor="danger" icone="times" /> </Link>
											: ""}

										{credenciais.map(credencial => ((credencial.id_componente == 82) ?
											<Link to="#" data-tip data-for='broadcast' onClick={() =>
												<>
													{this.chamarBroadcast(id_usuario)}
												</>
											}> <BotaoIcon loading={carregando} classes="botao-responsivo mr-2" color={this.props.usuario?.personalizacao?.cor_terciaria} icone="broadcast-tower" /> </Link>
											: ""))}
										{credenciais.map(credencial => ((credencial.id_componente == 102) ?
											<Link to="#" data-tip data-for='broadcastIncubadora' onClick={() => { this.setState({ broadcastIncubadora: true }) }}> <BotaoIcon loading={carregando} classes="botao-responsivo mr-2" cor="success" icone="bullhorn" /> </Link>
											: ""))}
									</div>
									<ReactTooltip id='broadcastIncubadora' type='dark'><span>{idioma.broadcastIncubadora}</span></ReactTooltip>
									<ReactTooltip id='broadcast' type='dark'><span>{idioma.broadcast}</span></ReactTooltip>
									<ReactTooltip id='filtrar' type='dark'><span>{idioma.bt_filtrar}</span></ReactTooltip>
									{broadcastIncubadora &&
										<div>
											<Input type="select" value={tipo_escolha} onChange={(ev) => this.onChangeInput("tipo_escolha", ev)} className="mt-2">
												<option>{idioma.label_ambiente}</option>
												<>
													{minhasIncubadoras.map(minhas => (
														<option value={minhas.id}>{minhas.nome}</option>
													))}
												</>
											</Input>
											<Link to="#" onClick={() => { this.enviarTodosUsuarios(id_usuario) }}>
												<Botao loading={carregando} texto={idioma.bt_filtrar_broadcast} classes="botao-responsivo botao-estilo" color={this.props.usuario?.personalizacao?.cor_terciaria} /> </Link>
											<Link to="#" onClick={() => { this.setState({ broadcastIncubadora: false }) }}> <BotaoIcon loading={carregando} classes="botao-responsivo mr-2" cor="danger" icone="times" /> </Link>
										</div>
									}
									{/* <a data-tip data-for='radarAplicado'>
                                                </a> */}

									{chats && pesquisando == false ?
										<div className="">
											{chats.length > 0 &&
												chats.map(chat => (
													chat != null && chat.chats.id_usuario1 == id_usuario ?
														// <ScrollIntoView selector="#chat" onClick={() => this.setState({ scroll: true })}>


														<div onClick={() => {
															this.handleChat(chat.chats.id, chats)
															this.scrollToMyRef()
														}} className="px-3 my-2 border-bottom" id="div-chat" style={{ cursor: 'pointer' }}>
															<div className=" align-items-center pt-2">
																<div className="row">
																	<div className="col-md-2 mb-2">
																		{chat.usuario1.id == id_usuario ?
																			<img className="rounded-circle" src={chat.usuario2.fileName != null ? apiImagem + chat.usuario2.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																			:
																			<img className="rounded-circle" src={chat.usuario1.fileName != null ? apiImagem + chat.usuario1.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																		}
																	</div>
																	<div className="col-md-9 ml-4">
																		<div className="row">
																			<h6 className="m-0">{chat.chats.id_usuario1 == id_usuario ?
																				chat.usuario2.nome.slice(0, 20) + (chat.usuario2.nome.length > 20 ? '…' : '') : chat.usuario1.nome.slice(0, 20) + (chat.usuario1.nome.length > 20 ? '…' : '')}</h6>
																			<div class="dashboard-boasvindas-right ml-auto" onMouseOver={() => this.setState({ menu: true })} onMouseOut={() => this.setState({ menu: false })}>
																				<p className="m-0 text-muted dashboard-boasvindas-right ml-auto datahora-chat">{
																					chat.ultimaMensagem ?
																						format(new Date(chat.ultimaMensagem.datahora_envio), 'dd/MM', { locale: pt })
																						: ""
																				}</p>
																			</div>
																		</div>
																		<div className="row">
																			<p className="text-muted datahora-chat">
																				{chat.ultimaMensagem ?
																					chat.ultimaMensagem.mensagem.slice(0, 15) + (chat.ultimaMensagem.mensagem.length > 15 ? '…' : '')
																					: ""}
																			</p>
																			<div class="dashboard-boasvindas-right ml-auto">
																				<div className="row mr-1">
																					<div className="dashboard-boasvindas-right ml-auto">
																						{chat.naoLidas > 0 ?
																							<Badge color="success" pill>{chat.naoLidas}</Badge>
																							: ""}
																					</div>
																					<div className="dashboard-boasvindas-right ml-auto">
																						<UncontrolledDropdown setActiveFromChild>
																							<DropdownToggle tag="a" className="nav-link menu-header px-1 py-0">
																								<FontAwesomeIcon icon="ellipsis-v" color="gray" />
																							</DropdownToggle>
																							<DropdownMenu right>
																								<DropdownItem onClick={() => this.chamarDeletarChat(id_chat, chats)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> {idioma.bt_deletar}</DropdownItem>
																							</DropdownMenu>
																						</UncontrolledDropdown>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														// </ScrollIntoView>

														:
														chat != null && chat.chats.id_usuario2 == id_usuario ?
															// <ScrollIntoView selector="#chat" onClick={() => this.setState({ scroll: true })}>

															<div onClick={() => {
																this.handleChat(chat.chats.id, chats)
																this.scrollToMyRef()
															}} className="px-3 my-2 border-bottom" style={{ cursor: 'pointer' }}>
																<div className=" align-items-center pt-2">
																	<div className="row">
																		<div className="col-md-2 mb-2">
																			{chat.usuario1.id == id_usuario ?
																				<img className="rounded-circle" src={chat.usuario2.fileName != null ? apiImagem + chat.usuario2.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																				:
																				<img className="rounded-circle" src={chat.usuario1.fileName != null ? apiImagem + chat.usuario1.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																			}
																		</div>
																		<div className="col-md-9 ml-4">
																			<div className="row">
																				<h6 className="m-0">{chat.chats.id_usuario1 == id_usuario ?
																					chat.usuario2.slice(0, 20) + (chat.usuario2.nome.length > 20 ? '…' : '') : chat.usuario1.nome.slice(0, 20) + (chat.usuario1.nome.length > 20 ? '…' : '')}</h6>
																				<div class="dashboard-boasvindas-right ml-auto" onMouseOver={() => this.setState({ menu: true })} onMouseOut={() => this.setState({ menu: false })}>
																					<p className="m-0 text-muted dashboard-boasvindas-right ml-auto datahora-chat">{
																						chat.ultimaMensagem ?
																							format(new Date(chat.ultimaMensagem.datahora_envio), 'dd/MM', { locale: pt })
																							: ""
																					}</p>
																				</div>
																			</div>
																			<div className="row">
																				<p className="text-muted datahora-chat">
																					{chat.ultimaMensagem ?
																						chat.ultimaMensagem.mensagem.slice(0, 15) + (chat.ultimaMensagem.mensagem.length > 12 ? '…' : '')
																						: ""}
																				</p>
																				<div class="dashboard-boasvindas-right ml-auto">
																					<div className="row mr-1">
																						<div className="dashboard-boasvindas-right ml-auto">
																							{chat.naoLidas > 0 ?
																								<Badge color="success" pill>{chat.naoLidas}</Badge>
																								: ""}
																						</div>
																						<div className="dashboard-boasvindas-right ml-auto">
																							<UncontrolledDropdown setActiveFromChild>
																								<DropdownToggle tag="a" className="nav-link menu-header px-1 py-0">
																									<FontAwesomeIcon icon="ellipsis-v" color="gray" />
																								</DropdownToggle>
																								<DropdownMenu right>
																									<DropdownItem onClick={() => this.chamarDeletarChat(id_chat, chats)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> {idioma.bt_deletar}</DropdownItem>
																								</DropdownMenu>
																							</UncontrolledDropdown>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>

																	</div>
																</div>
															</div>
															// </ScrollIntoView>
															: ""
												))}
											{dadosApi ?
												<Link to="#" onClick={() => this.carregarMaisConversas()} >
													<Botao loading={atualizando} classes="botao-responsivo botao-estilo" color={this.props.usuario?.personalizacao?.cor_terciaria} icone="arrow-down" texto="Carregar mais conversas" posicao="E" />
												</Link>
												: ""}
										</div>
										:
										chats && pesquisando == true ?
											<>
												<h4 className="mt-4">{idioma.conversas}</h4>
												<div className="">
													{chats.length > 0 ?
														chats.map(chat => (
															chat != null ?
																// <ScrollIntoView selector="#chat" onClick={() => this.setState({ scroll: true })}>

																<div onClick={
																	() => {
																		this.handleChat(chat.chats.id, chats)
																		this.scrollToMyRef()
																	}} className="px-3 my-4 border-bottom" style={{ cursor: 'pointer' }}>
																	<div className=" align-items-center pt-2">
																		<div className="row">
																			<div className="col-md-2 mb-2">
																				{chat.usuario1.id == id_usuario ?
																					<img className="rounded-circle" src={chat.usuario2.fileName != null ? apiImagem + chat.usuario2.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																					:
																					<img className="rounded-circle" src={chat.usuario1.fileName != null ? apiImagem + chat.usuario1.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																				}
																			</div>
																			<div className="col-md-9 ml-4">
																				<div className="row">
																					<h6 className="m-0">{chat.chats.id_usuario1 == id_usuario ?
																						chat.usuario2.nome.slice(0, 20) + (chat.usuario2.nome.length > 20 ? '…' : '') : chat.usuario1.nome.slice(0, 20) + (chat.usuario1.nome.length > 20 ? '…' : '')}</h6>
																					<div class="dashboard-boasvindas-right ml-auto" onMouseOver={() => this.setState({ menu: true })} onMouseOut={() => this.setState({ menu: false })}>
																						<p className="m-0 text-muted dashboard-boasvindas-right ml-auto datahora-chat">{
																							chat.ultimaMensagem ?
																								format(new Date(chat.ultimaMensagem.datahora_envio), 'dd/MM', { locale: pt })
																								: ""
																						}</p>
																					</div>
																				</div>
																				<div className="row">
																					<p className="text-muted datahora-chat">
																						{chat.ultimaMensagem ?
																							chat.ultimaMensagem.mensagem.slice(0, 15) + (chat.ultimaMensagem.mensagem.length > 12 ? '…' : '')
																							: ""}
																					</p>
																					<div class="dashboard-boasvindas-right ml-auto">
																						<div className="row mr-1">
																							<div className="dashboard-boasvindas-right ml-auto">
																								{chat.naoLidas > 0 ?
																									<Badge color="success" pill>{chat.naoLidas}</Badge>
																									: ""}
																							</div>
																							<div className="dashboard-boasvindas-right ml-auto">
																								<UncontrolledDropdown setActiveFromChild>
																									<DropdownToggle tag="a" className="nav-link menu-header px-1 py-0">
																										<FontAwesomeIcon icon="ellipsis-v" color="gray" />
																									</DropdownToggle>
																									<DropdownMenu right>
																										<DropdownItem onClick={() => this.chamarDeletarChat(id_chat, chats)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> {idioma.bt_deletar}</DropdownItem>
																									</DropdownMenu>
																								</UncontrolledDropdown>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>


																</div>
																// </ScrollIntoView>
																: ""
														))
														:
														<h5 className="mt-2">{idioma.sem_chats}</h5>
													}
												</div>
												<h4 className="mt-2">{idioma.contatos}</h4>
												<div className="">
													{semChat ?
														semChat.length > 0 ?
															semChat.map(usuario => (
																<div onClick={() => this.cadChat(usuario.id)} className="px-3 my-4 border-bottom" style={{ cursor: 'pointer' }}>
																	<div className=" align-items-center pt-2">
																		<div className="row">
																			<div className="col-md-2 mb-2">
																				<img className="rounded-circle" src={usuario.fileName != null ? apiImagem + usuario.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																			</div>
																			<div className="col-md-7 ml-4">
																				<h6 className="m-0">{usuario.nome}</h6>
																			</div>
																		</div>
																	</div>
																</div>
															))
															:
															<h5 className="mt-2">{idioma.sem_contatos}</h5>
														:
														<h5 className="mt-2">{idioma.sem_contatos}</h5>}
												</div>
											</>
											: ""}

								</div>
								<div className="col-md-8">

									{id_chat != "" ?
										<>

											<div className="row m-0">
												<div className="col-md-11">
													{chats.map(chat => (chat != null) ?
														chat.chats.id == id_chat ?
															<div className="d-flex align-items-center mb-2">
																{chat.usuario1.id == id_usuario ?
																	<img className="rounded-circle" src={chat.usuario2.fileName != null ? apiImagem + chat.usuario2.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																	:
																	<img className="rounded-circle" src={chat.usuario1.fileName != null ? apiImagem + chat.usuario1.fileName : apiImagem + "avatar.jpg"} width="50" height="50" ></img>
																}
																<h6 className="m-0 ml-3">{chat.chats.id_usuario1 == id_usuario ?
																	chat.usuario2.nome.slice(0, 20) + (chat.usuario2.nome.length > 20 ? '…' : '') : chat.usuario1.nome.slice(0, 20) + (chat.usuario1.nome.length > 20 ? '…' : '')}</h6>
															</div>
															: ""
														: "")}
												</div>
												<div class="col-md-1 dashboard-boasvindas-right">
													<div >
														<UncontrolledDropdown setActiveFromChild>
															<DropdownToggle tag="a" className="nav-link menu-header">
																<FontAwesomeIcon icon="ellipsis-v" color="gray" />
															</DropdownToggle>
															<DropdownMenu right>
																<DropdownItem onClick={() => this.chamarDeletarChat(id_chat, chats)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> {idioma.bt_deletar}</DropdownItem>
															</DropdownMenu>
														</UncontrolledDropdown>
													</div>
												</div>
											</div>


											<div className="pb-3 conversa" ref={this.chatContainer}>

												{dadosApiChat ?
													mensagens.map(mensagem => (
														<>
															<div className="row">
																{mensagem.autor.id != id_usuario ?
																	<div className="mt-2 col-md-12">
																		<div className="col-md-7 baloon-left">
																			<div className="list-group-item bg-secondary-borderless p-2 ">
																				<div className="row">
																					{/* <div className="d-flex align-tems-center justify-content-between px-3">
																				<b><p className="m-0 text-center d-flex align-items-center text-12">{mensagem.autor.nome}</p></b>
																				<div className="col-md-1 dashboard-boasvindas-right " >
																					<div>
																						{mensagem.autor.id == id_usuario ?
																							<UncontrolledDropdown setActiveFromChild>
																								<DropdownToggle tag="a" className="nav-link menu-header padding-0">
																									<FontAwesomeIcon icon="ellipsis-v" color="gray" />
																								</DropdownToggle>
																								<DropdownMenu right>
																									<DropdownItem onClick={() => this.chamarDeletarMensagem(mensagem.mensagens.id, id_chat)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> Deletar</DropdownItem>
																								</DropdownMenu>
																							</UncontrolledDropdown>
																							: ""}

																					</div>

																				</div>
																			</div> */}
																					<div className="col-md-10" >
																						<p className="ml-1 mb-0">{parse("" + mensagem.mensagens.mensagem)}</p>
																						{mensagem.arquivos.length > 0 ?
																							mensagem.arquivos.map(arquivo => (
																								<Link to="#" onClick={() => this.handleFile(arquivo.arquivo)} className="ml-3 mb-0">{arquivo.nome}</Link>

																							))
																							: ""}
																					</div>
																					<div className="col-md-1 dashboard-boasvindas-right " >
																						<div>
																							{mensagem.autor.id == id_usuario ?
																								<UncontrolledDropdown setActiveFromChild>
																									<DropdownToggle tag="a" className="nav-link menu-header padding-0">
																										<FontAwesomeIcon icon="ellipsis-v" color="gray" />
																									</DropdownToggle>
																									<DropdownMenu right>
																										<DropdownItem onClick={() => this.chamarDeletarMensagem(mensagem.mensagens.id, id_chat)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> {idioma.bt_deletar}</DropdownItem>
																									</DropdownMenu>
																								</UncontrolledDropdown>
																								: ""}
																						</div>
																					</div>
																				</div>

																				<p className="mb-0 text-muted text-right datahora-msg">{
																					format(addHours(new Date(mensagem.mensagens.datahora_envio), 3), 'dd/MM/yyyy HH:mm', { locale: pt })
																				}</p>
																			</div>
																		</div>
																	</div>
																	:

																	<div className="mt-2 col-md-12">
																		<div className="col-md-7 baloon-right">
																			<div className="list-group-item bg-green p-2">
																				<div className="row">
																					{/* <div className="d-flex align-tems-center justify-content-between px-3">
																				<b><p className="m-0 text-center d-flex align-items-center text-12">{mensagem.autor.nome}</p></b>
																				<div className="col-md-1 dashboard-boasvindas-right " >
																					<div>
																						{mensagem.autor.id == id_usuario ?
																							<UncontrolledDropdown setActiveFromChild>
																								<DropdownToggle tag="a" className="nav-link menu-header padding-0">
																									<FontAwesomeIcon icon="ellipsis-v" color="gray" />
																								</DropdownToggle>
																								<DropdownMenu right>
																									<DropdownItem onClick={() => this.chamarDeletarMensagem(mensagem.mensagens.id, id_chat)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> Deletar</DropdownItem>
																								</DropdownMenu>
																							</UncontrolledDropdown>
																							: ""}

																					</div>

																				</div>
																			</div> */}
																					<div className="col-md-10" >
																						<p className="ml-1 mb-0">{parse("" + mensagem.mensagens.mensagem)}</p>
																						{mensagem.arquivos.length > 0 ?
																							mensagem.arquivos.map(arquivo => (
																								<Link to="#" onClick={() => this.handleFile(arquivo.arquivo)} className="ml-3 mb-0">{arquivo.nome}</Link>

																							))
																							: ""}
																					</div>
																					<div className="col-md-1 dashboard-boasvindas-right " >
																						<div>
																							{mensagem.autor.id == id_usuario ?
																								<UncontrolledDropdown setActiveFromChild>
																									<DropdownToggle tag="a" className="nav-link menu-header padding-0">
																										<FontAwesomeIcon icon="ellipsis-v" color="gray" />
																									</DropdownToggle>
																									<DropdownMenu right>
																										<DropdownItem onClick={() => this.chamarDeletarMensagem(mensagem.mensagens.id, id_chat)}><FontAwesomeIcon icon="trash-alt" className="mr-2" /> {idioma.bt_deletar}</DropdownItem>
																									</DropdownMenu>
																								</UncontrolledDropdown>
																								: ""}
																						</div>
																					</div>
																				</div>
																				<p className="mb-0 text-muted text-right datahora-msg">{
																					format(addHours(new Date(mensagem.mensagens.datahora_envio), 3), 'dd/MM/yyyy HH:mm', { locale: pt })
																				}</p>
																			</div>

																		</div>
																	</div>}

															</div>
														</>
													)) : ""}
												<div className="row">
													<div className="mt-2 col-md-12">
														<div className="col-md-7 baloon-right">
															{carregando ?
																<div className="list-group-item bg-green p-2">
																	<div className="row">
																		<div className="col-md-10" >
																			<p className="ml-1 mb-0">{previaMensgem ? previaMensgem : idioma.enviando}</p>
																		</div>
																	</div>
																	<p className="mb-0 text-muted text-right datahora-msg">{
																		<FontAwesomeIcon icon="clock" />
																	}</p>
																</div>
																: ""}
														</div>
													</div>
												</div>
											</div>

											<div className="d-flex align-items-center justify-content-between w-100 mt-3 col-auto">
												<Input type="text" value={mensagem} onKeyPress={(ev) => this.handleMensagem(ev, "mensagem")} onChange={(ev) => this.onChangeInput("mensagem", ev)} onClick={() => this.scrollToMyRef()} name="mensagem" id="mensagem" placeholder={idioma.digite_msg} />
												<span onClick={() => this.chamarArquivo(id_chat, mensagem)}><BotaoIcon icone="paperclip" tamanho="3x" color={this.props.usuario?.personalizacao?.cor_terciaria} className="text-muted mx-3 mt-3 ml-2" /></span>
												<span onClick={(ev) => {
													this.mandarMensagem(id_chat, mensagem)
												}}><BotaoIcon icone="paper-plane" tamanho="3x" color={this.props.usuario?.personalizacao?.cor_terciaria} className="text-muted mt-3 cursor-pointer ml-2" /></span>
											</div>

										</>
										:
										<SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.sem_dados} />
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}
const mapStatetoProps = state => ({
	chats: state.chat.chats,
	usuarios: state.chat.usuarios,
	incubadoras: state.chat.incubadoras,
	startups: state.chat.startups,
	perfis: state.chat.perfis,
	mensagens: state.chat.mensagens,
	usuariosPerfil: state.chat.usuariosPerfil,
	verificacao: state.chat.verificacao,
	usuario: state.chat.usuario,
	chatsBusca: state.chat.chatsBusca
})
export default connect(mapStatetoProps, actions)(Chat);