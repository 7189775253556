import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row, Card, Table } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import Swal from 'sweetalert2'
import ReactTooltip from 'react-tooltip';
import Alert from '@material-ui/lab/Alert';

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class EditarDesafio extends Component {
    state = {
        id_solucao: "",
        id_desafio: "",
        titulo: "",
        descricao: "",
        resumo: "",
        file: "",
        id_startup: "",
        id_autor: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_desafio, id_solucao } = this.props.match.params;
        this.setState({ id_desafio })
        this.setState({ id_solucao })
        this.props.getVerSolucao(id_desafio, id_solucao);
        this.listarArquivos(id_solucao);

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    async componentDidUpdate(nextProps) {
        const { id_desafio, id_solucao } = this.props.match.params;
        if (!this.props.solucao && nextProps.solucao) this.props.getVerSolucao(id_desafio, id_solucao);
        if (!this.props.arquivos && nextProps.arquivos) this.listarArquivos(id_solucao);
        await this.receberDadosAPI();
    }
    receberDadosAPI() {
        const { id_solucao, id_desafio } = this.props.match.params;
        if (typeof this.props.solucao !== "undefined" && this.props.solucao !== null && this.props.solucao.id == id_solucao && !this.state.dadosAPI) {
            this.setState({ id_solucao: this.props.solucao.id })
            this.setState({ titulo: this.props.solucao.titulo })
            this.setState({ resumo: this.props.solucao.resumo })
            this.onEditorStateChangeInicial(this.props.solucao.descricao)
            this.setState({ id_desafio: this.props.solucao.id_desafio })
            this.setState({ id_startup: this.props.solucao.id_startup })
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onEditorStateChangeInicial = (descricao) => {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(descricao)
                )

            ),
        })
    }

    listarArquivos(id_solucao) {
        this.props.getArquivosSolucao(id_solucao);
        const { arquivos } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (arquivos === "undefined") return null
    }


    async atualizarDesafio(id, id_desafio, titulo, resumo, editorState) {
        const id_autor = this.props.usuario.id
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosForm();
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putSolucao({ id, id_desafio, titulo, resumo, descricao, id_autor }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }
    receberDadosForm() {
        this.setState({ id_solucao: document.querySelector("#id_solucao").value })
        this.setState({ id_desafio: document.querySelector("#id_desafio").value })
        this.setState({ titulo: document.querySelector("#titulo").value })
    }
    validate() {
        const { titulo, erro, idioma } = this.state;
        if (!titulo) return this.setState({ erro: { message: idioma.cadastrarDesafio.erro_titulo } });
        return true;
    }

    chamerCadArquivo(id_desafio, id_solucao) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.cadastrarDesafio.erro_arq,
            input: 'file',
        })
            .then((result) => {
                if (result) {
                    this.cadArquivo(id_desafio, id_solucao, result.value)
                }
            })
    }

    async cadArquivo(id_desafio, id_solucao, file) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        this.setState({ carregando: true })
        const formData = new FormData();
        formData.append('id_desafio', id_desafio)
        formData.append('id_solucao', id_solucao)
        formData.append('id_autor', this.props.usuario.id)
        formData.append('file', file);
        this.props.postArquivo(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarArquivos(id_solucao);
            }
        })

    }

    chamarDeletar(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.cadastrarDesafio.info_imagem,
            showCancelButton: true,
            confirmButtonText: idioma.cadastrarDesafio.bt_deletar,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                this.deletarArquivo(id)
            }
        })
    }

    async deletarArquivo(id) {
        const { id_solucao } = this.state
        this.setState({ success: "" });
        this.setState({ erro: "" });
        this.setState({ carregando: true })
        this.props.deletarArquivo(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.listarArquivos(id_solucao);

            }
        })

    }


    render() {
        const { id_solucao, idioma, id_desafio, titulo, resumo, editorState, descricao, id_startup, carregando, dadosAPI, erro, success, formSuccess } = this.state;
        ////console.log(this.props, dadosAPI)
        const { usuario } = this.props;
        var arquivos = []
        if (this.props.arquivos) arquivos = this.props.arquivos.dados

        if (formSuccess) return <Redirect to={{
            pathname: "/hub/" + id_desafio + "/ver",
            state: { msg: idioma.cadastrarDesafio.edicao_suc }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarDesafio.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>                    <div>
                            {/* <img className="rounded-circle mt-2" src={imagem} width="80" height="80" alt={nome}></img> */}
                        </div>
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/" + id_desafio + "/ver/solucao/" + id_solucao}>
                                <Botao texto={idioma.pagina_ver.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                {/* <button className="btn btn-outline-primary btn-sm">{idioma.pagina_ver.bt_voltar}</button> */}
                            </Link>

                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            <Form>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="titulo">{idioma.cadastrarDesafio.titulo}</Label>
                                            <Input type="text" value={titulo} id="titulo" name="titulo" className="form-control" autoComplete="titulo" placeholder={dadosAPI ? idioma.cadastrarDesafio.titulo2 : "Carregando..."} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("titulo", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="resumo">{idioma.cadastrarDesafio.resumo}</Label>
                                            <Input type="text" value={resumo} autoComplete="resumo" onChange={(ev) => this.onChangeInput("resumo", ev)} name="resumo" id="resumo" placeholder={idioma.cadastrarDesafio.resumo2} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="descricao">{idioma.cadastrarDesafio.descricao}</Label>
                                            <Card className="card-editor">
                                                {dadosAPI ?
                                                    <div>
                                                        <Editor
                                                            editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={this.onEditorStateChange}

                                                        />
                                                    </div>
                                                    : ""}

                                            </Card>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Input type="hidden" value={id_desafio} id="id_desafio" name="id_desafio" />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="hidden" value={id_solucao} id="id_solucao" name="id_solucao" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="col-md-12 mb-2">
                                    <Alert severity="warning">{idioma.cadastrarDesafio.info_docs}</Alert>
                                </div>
                                <Link onClick={() => this.chamerCadArquivo(id_desafio, id_solucao)} to="#">
                                    <Botao loading={carregando} texto={idioma.cadastrarDesafio.bt_cadastrar} classes="mb-2" />
                                </Link>
                                <Row>
                                    <Table>
                                        <thead>
                                            <th >{idioma.avaliadores.nome}</th>
                                            <th>{idioma.avaliadores.acao}</th>
                                        </thead>
                                        <tbody>
                                            {arquivos.map(arquivo => (
                                                <tr>
                                                    <td>{arquivo.originalName}</td>
                                                    <td>
                                                        <a data-tip data-for='deletar'>
                                                            <Link to="#" onClick={() => this.chamarDeletar(arquivo.id)} className="mr-2">
                                                                <BotaoIcon loading={carregando} classes="btn-danger btn-sm ml-1" icone="trash-alt" />
                                                            </Link>
                                                        </a>
                                                        <ReactTooltip id='deletar' type='error'>
                                                            <span>{idioma.cadastrarDesafio.bt_deletar}</span>
                                                        </ReactTooltip>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Row>
                                <Link onClick={() => this.atualizarDesafio(id_solucao, id_desafio, titulo, resumo, editorState)} to="#">
                                    <Botao loading={carregando} texto={idioma.cadastrarDesafio.bt_salvar} cor="success" />
                                </Link>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    solucao: state.hub.solucao,
    arquivos: state.hub.arquivosSolucao

})
export default connect(mapStateToProps, actions)(EditarDesafio)