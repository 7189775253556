import React from 'react';
import Routes from './routes/routes'
import IdleTimer from './componentes/Idle/index'
function App() {
    return (
        <div>

            <Routes />
            {/* <IdleTimer /> */}
        </div>
    )
};

export default App;