import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
} from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import NumberFormat from 'react-number-format';
import BlocoPerfil from '../../containers/blocos/perfilSolucao';
import Skeleton from 'react-loading-skeleton';
import Toast from '../../componentes/Alertas/Toast';

import { ingles, portugues } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class CadastrarDesafio extends Component {
  state = {
    id_desafio: '',
    id_solucao: '',
    file: '',
    id_autor: '',
    nota_virgula: '',
    nota1_virgula: '',
    nota2_virgula: '',
    nota3_virgula: '',
    nota4_virgula: '',
    nota5_virgula: '',
    nota_final: '',
    erro: '',
    success: '',
    carregando: '',
    formSuccess: false,
    editorState: EditorState.createEmpty(),
    dadosApi: false,
    idioma: portugues,
    msg_backend: portuguese,
  };

  componentDidMount() {
    const { id_desafio, id_solucao } = this.props.match.params;
    this.setState({ id_desafio });
    this.setState({ id_solucao });
    this.listarSolucao(id_desafio, id_solucao);
    this.setState({ id_autor: this.props.usuario.id });

    const { usuario } = this.props;

    if (usuario?.idioma?.id === 3) {
      this.setState({ idioma: ingles });
      this.setState({ msg_backend: english });
    } else {
      this.setState({ idioma: portugues });
      this.setState({ msg_backend: portuguese });
    }
  }

  componentDidUpdate(nextProps) {
    const { id_desafio, id_solucao } = this.props.match.params;
    if (!this.props.solucao && nextProps.solucao) {
      this.listarSolucao(id_desafio, id_solucao);
    }
    this.receberDadosAPI();
  }

  receberDadosAPI() {
    if (
      typeof this.props.solucao != 'undefined' &&
      this.props.solucao != null &&
      !this.state.dadosApi
    ) {
      this.setState({ dadosApi: true });
    }
  }

  listarSolucao(id_desafio, id_solucao) {
    this.props.getVerSolucao(id_desafio, id_solucao);
    const { solucao } = this.props;
    if (this.props.location.state) {
      this.setState({ msg: this.props.location.state.msg });
      this.props.location.state.msg = '';
    }
    if (solucao === 'undefined') return null;
  }

  onChnageInput(campo, ev) {
    this.setState({ [campo]: ev.target.value });
  }

  onChangeInputFile(campo, ev) {
    this.setState({ [campo]: ev.target.files });
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };
  cadAvaliacao() {
    const {
      file,
      nota1_virgula,
      nota2_virgula,
      nota3_virgula,
      nota4_virgula,
      nota5_virgula,
      id_autor,
      id_desafio,
      id_solucao,
    } = this.state;
    this.setState({ carregando: true });
    const formData = new FormData();
    let nota1 = nota1_virgula.replace(',', '.');
    let nota2 = nota2_virgula.replace(',', '.');
    let nota3 = nota3_virgula.replace(',', '.');
    let nota4 = nota4_virgula.replace(',', '.');
    let nota5 = nota5_virgula.replace(',', '.');
    let nota_final = (
      (parseFloat(nota1) +
        parseFloat(nota2) +
        parseFloat(nota3) +
        parseFloat(nota4) +
        parseFloat(nota5)) /
      5
    ).toFixed(2);
    formData.append('nota1', nota1);
    formData.append('nota2', nota2);
    formData.append('nota3', nota3);
    formData.append('nota4', nota4);
    formData.append('nota5', nota5);
    formData.append('id_usuario', id_autor);
    formData.append('id_desafio', id_desafio);
    formData.append('id_solucao', id_solucao);
    formData.append('nota_final', nota_final);
    for (let f in file) {
      formData.append('file', file[f]);
    }
    this.props.postAvaliacao(formData, (msg) => {
      if (msg.erro.error) {
        Toast({ icon: 'error', title: 'Erro!', text: msg.erro.message });
        this.setState({
          erro: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ success: '' });
        this.setState({ carregando: false });
      } else {
        Toast({ icon: 'success', title: 'Sucesso!', text: msg.erro.message });
        this.setState({
          success: { message: this.state.msg_backend[msg.erro.code] },
        });
        this.setState({ erro: '' });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
      }
    });
  }
  render() {
    const {
      titulo,
      idioma,
      nota_virgula,
      id_solucao,
      id_desafio,
      dadosApi,
      nota1_virgula,
      nota2_virgula,
      nota3_virgula,
      nota4_virgula,
      nota5_virgula,
      nota_final,
      file,
      resumo,
      editorState,
      responsavel,
      empresa,
      cargo,
      id_autor,
      erro,
      success,
      carregando,
      iconSalvar,
      formSuccess,
    } = this.state;

    var usuario = [];
    if (this.props.usuario) usuario = this.props.usuario;
    var credenciais = [];
    if (this.props.credenciais) credenciais = this.props.credenciais;
    var solucao = null;
    if (this.props.solucao) solucao = this.props.solucao;
    var path = this.props.match.path;

    if (formSuccess)
      return (
        <Redirect
          to={{
            pathname: '/hub/' + id_desafio + '/ver/solucao/' + id_solucao,
            state: { msg: idioma.avaliar.info_sucesso },
          }}
        ></Redirect>
      );

    return (
      <>
        <div
          className="fundo-conteudo-inicio"
          style={{
            backgroundColor:
              this.props.usuario &&
              this.props.usuario.personalizacao.cor_principal,
          }}
        >
          <Titulo titutlo={idioma.avaliar.titulo} />
        </div>
        <div className="container-fluid bloco-topo">
          {solucao != null ? (
            <BlocoPerfil
              solucao={solucao}
              dadosApi={dadosApi}
              usuario={usuario}
              path={path}
              credenciais={credenciais}
            />
          ) : (
            <div className="col-md-12 mb-2">
              <div className="list-group-item">
                {' '}
                <div className="row">
                  <div className="col-md-12 ">
                    <h2 className="">
                      <Skeleton count={1} width={160} />
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-12 mb-2">
            <div
              className="list-group-item"
              style={{
                backgroundColor:
                  this.props.usuario &&
                  this.props.usuario.personalizacao?.cor_quaternaria,
              }}
            >
              <AlertaErro erros={erro} />
              <AlertaSuccess erros={success} />
              <Form>
                <Row form>
                  <Col md={3} className="mr-2">
                    <FormGroup>
                      <Label for="nota1_virgula">{idioma.avaliar.tecnologia}</Label>
                      <div className="">
                        <NumberFormat
                          value={nota1_virgula}
                          className="input-nota"
                          decimalSeparator=","
                          onChange={(ev) => this.onChnageInput("nota1_virgula", ev)}
                          placeholder={idioma.cadastrarDesafio.nota2}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="mr-2">
                    <FormGroup>
                      <Label for="nota2_virgula">{idioma.avaliar.gestao}</Label>
                      <div className="">
                        <NumberFormat
                          value={nota2_virgula}
                          className="input-nota"
                          decimalSeparator=","
                          onChange={(ev) => this.onChnageInput("nota2_virgula", ev)}
                          placeholder={idioma.cadastrarDesafio.nota2}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="mr-2">
                    <FormGroup>
                      <Label for="nota3_virgula">{idioma.avaliar.capital}</Label>
                      <div className="">
                        <NumberFormat
                          value={nota3_virgula}
                          className="input-nota"
                          decimalSeparator=","
                          onChange={(ev) => this.onChnageInput("nota3_virgula", ev)}
                          placeholder={idioma.cadastrarDesafio.nota2}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="mr-2">
                    <FormGroup>
                      <Label for="nota4_virgula">{idioma.avaliar.gestao}</Label>
                      <div className="">
                        <NumberFormat
                          value={nota4_virgula}
                          className="input-nota"
                          decimalSeparator=","
                          onChange={(ev) => this.onChnageInput("nota4_virgula", ev)}
                          placeholder={idioma.cadastrarDesafio.nota2}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={3} className="mr-2">
                    <FormGroup>
                      <Label for="nota5_virgula">
                        {idioma.avaliar.empreendedor}
                      </Label>
                      <div className="">
                        <NumberFormat
                          value={nota5_virgula}
                          className="input-nota"
                          decimalSeparator=","
                          onChange={(ev) =>
                            this.onChnageInput('nota5_virgula', ev)
                          }
                          placeholder={idioma.cadastrarDesafio.nota2}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label for="file">{idioma.avaliar.info_doc}</Label>
                  <Input
                    type="file"
                    id="file"
                    name="file"
                    multiple
                    className="form-control"
                    autoComplete="file"
                    onChange={(ev) => this.onChangeInputFile('file', ev)}
                  />
                </FormGroup>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Input
                        type="hidden"
                        value={id_autor}
                        id="id_autor"
                        name="id_autor"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Link to="#" onClick={() => this.cadAvaliacao()}>
                  {' '}
                  <Botao
                    loading={carregando}
                    texto={idioma.avaliar.bt_salvar}
                    cor="success"
                    icone="check"
                    posicao="E"
                  />{' '}
                </Link>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  usuarios: state.auth.usuarios,
  solucao: state.hub.solucao,
});

export default connect(mapStateToProps, actions)(CadastrarDesafio);
