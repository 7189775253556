export const portugues = {
  modal_titulo: 'Deseja deletar o conteúdo?',
  modal_info: 'Ao fazer isso você concorda na exclusão do conteúdo </br>Digite <strong>CONFIRMAR</strong> para realizar a exclusão',
  modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
  confirm: 'CONFIRMAR',
  bt_deletar: 'Deletar',
  modal_erro: 'Mensagem de confirmação inválida!',
  modal_sucesso: 'Conteúdo excluído com sucesso!',
  criado: 'Criado por:',
  acesso: 'Acesse em:',
  bt_editar: 'Editar',
  bt_editar_capa: 'Editar Capa'
}

export const ingles = {
  modal_titulo: 'Do you want to delete the content?',
  modal_info: 'By doing so you agree to the deletion of the content </br>Type <strong>CONFIRM</strong> to perform the deletion',
  modal_confirm: 'Type CONFIRM to perform the deletion',
  confirm: 'CONFIRM',
  bt_deletar: 'Delete',
  modal_erro: 'Invalid confirmation message!',
  modal_sucesso: 'Content successfully deleted!',
  criado: 'Created by:',
  acesso: 'Access at:',
  bt_editar: 'Edit',
  bt_editar_capa: 'Edit Cover'
}