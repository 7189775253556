import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Form, FormGroup, Label, Input, Card } from 'reactstrap';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import urlParser from "js-video-url-parser";
import Skeleton from "react-loading-skeleton";
import { apiAula } from '../../config';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class CadastrarAula extends Component {
    state = {
        nome: "",
        duracao: "",
        id_materia: "",
        id_modulo: "",
        id_aula: "",
        id_trilha: "",
        id_curso: "",
        id_modulo_selecionado: "",
        tipo: "",
        linkYT: "",
        linkOutro: "",
        linkPodCast: "",
        ordem: "",
        descricao: "",
        conteudo: "",
        file: null,
        data_publicacao: "",
        url: "",
        erro: "",
        success: "",
        carregando: "",
        dadosApi: false,
        formSuccess: false,
        editorState: EditorState.createEmpty(),
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });
        this.setState({ id_trilha })
        this.listarTrilha(id_trilha)
        if (this.props.usuario) this.listar(id_trilha, this.props.usuario.id)
        this.listarTipo()
        if (this.props.location.state !== undefined) {
            this.setState({ id_modulo: this.props.location.state.id_modulo })
            this.listarMateria(this.props.match.params.id_trilha, this.props.location.state.id_modulo)
            this.setState({ id_modulo_selecionado: this.props.location.state.id_modulo })
            this.setState({ id_materia: this.props.location.state.id_materia })

        }
        //////////////console.log(id_trilha)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.aula && nextProps.aula) {
            this.listar(id_trilha, this.props.usuario.id);
        }
        if (!this.props.tipoAula && nextProps.tipoAula) {
            this.listarTipo();
        }
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha)
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparModulos()
    }

    receberDadosAPI() {
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {

            if (typeof this.props.modulos != "undefined" && this.props.modulos != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
            if (typeof this.props.materias != "undefined" && this.props.materias != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
            if (typeof this.props.tipoAula != "undefined" && this.props.tipoAula != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onChangeInputFile = (field, ev) => {
        this.setState({ [field]: ev.target.files[0] });
    }



    // cadAulaVideo() {
    //     const { nome, ordem, id_trilha, id_modulo, id_materia, conteudo, duracao, tipo, linkYT } = this.state;
    //     this.setState({ carregando: true })
    //     var id_curso = id_trilha
    //     var id_tipo = tipo
    //     if (linkYT.includes("youtube")) {
    //         var link = urlParser.parse(linkYT).id
    //     } else if (linkYT.includes("youtu.be")) {
    //         var link = urlParser.parse(linkYT).id

    //     }
    //     ////////////console.log(nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao)
    //     this.props.postAula({ nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao, id_tipo, link }, (msg) => {
    //         if (msg.erro.error) {
    //             this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
    //             this.setState({ success: "" });
    //             this.setState({ carregando: false });
    //         } else {
    //             this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
    //             this.setState({ erro: "" });
    //             this.setState({ carregando: false });
    //             this.setState({ formSuccess: true });
    //         }
    //     }
    //     )
    // }

    cadAulaPodCast() {
        const { nome, ordem, id_trilha, id_modulo, id_materia, conteudo, duracao, tipo, linkPodCast, data_publicacao } = this.state;
        this.setState({ carregando: true })
        var id_curso = id_trilha
        var id_tipo = tipo
        var link = linkPodCast

        linkPodCast.split("?").forEach(
            character => character.split("/").forEach(character2 => {
                if (!character2.includes("si=") && !character2.includes("episode") && !character2.includes("https:") && !character2.includes("open.spotify.com")) {
                    link = character2
                }
            }
            )
        )
        ////////////console.log(nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao)
        this.props.postAula({ nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao, id_tipo, link, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    cadAulaVideo() {
        const { nome, ordem, id_trilha, id_modulo, id_materia, conteudo, duracao, tipo, linkYT, data_publicacao } = this.state;
        this.setState({ carregando: true })
        var id_curso = id_trilha
        var id_tipo = tipo
        if (linkYT.includes("youtube")) {
            var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id
        } else if (linkYT.includes("youtu.be")) {
            var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id
        }
        ////////////console.log(nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao)
        this.props.postAula({ nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao, id_tipo, link, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }


    cadAulaTexto() {
        const { nome, ordem, id_trilha, id_modulo, id_materia, editorState, duracao, tipo, data_publicacao } = this.state;
        this.setState({ carregando: true })
        var id_curso = id_trilha
        var id_tipo = tipo
        var conteudo = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        this.props.postAula({ nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao, id_tipo, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    cadAulaPDF() {
        const { nome, file, ordem, id_trilha, id_modulo, id_materia, duracao, tipo, data_publicacao } = this.state;
        this.setState({ carregando: true })
        var id_curso = id_trilha
        var id_tipo = tipo
        var url = apiAula
        ////////console.log(file)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('nome', nome)
        formData.append('ordem', ordem)
        formData.append('id_curso', id_curso)
        formData.append('id_modulo', id_modulo)
        formData.append('id_materia', id_materia)
        formData.append('url', url)
        formData.append('duracao', duracao)
        formData.append('id_tipo', id_tipo)
        formData.append('data_publicacao', data_publicacao)

        this.props.postAula(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    cadAulaOutro() {
        const { nome, ordem, id_trilha, id_modulo, id_materia, conteudo, duracao, tipo, linkOutro, data_publicacao } = this.state;
        this.setState({ carregando: true })
        if (!this.validate()) return;
        var id_curso = id_trilha
        var id_tipo = tipo
        var link = linkOutro
        this.props.postAula({ nome, ordem, id_curso, id_modulo, id_materia, conteudo, duracao, id_tipo, link, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }

    validate() {
        const { duracao } = this.state;
        if (!duracao) return this.setState({ erro: { message: "Preencha a duracao" } });
        return true;
    }
    // listar() {
    //     const { id_trilha } = this.state;
    //     this.props.getModulos(id_trilha)
    //     if (this.props.location.state) {
    //         this.setState({ msg: this.props.location.state.msg })
    //         this.props.location.state.msg = "";
    //     }
    //     if (id_trilha === "undefined") return null
    // }

    listarMateria(id_trilha, id_modulo) {
        this.props.getMaterias(id_trilha, id_modulo)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (id_trilha === "undefined") return null
    }

    listarTipo() {
        this.props.getTipoAula()
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
    }

    listar(id_trilha, id_usuario) {
        this.props.getModulos(id_trilha, id_usuario)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (id_trilha === "undefined") return null
    }

    handleSelectedModulo(campo, ev) {
        this.setState({ [campo]: ev.target.value })
        this.setState({ id_modulo_selecionado: ev.target.value })
        this.listarMateria(this.state.id_trilha, ev.target.value)
    }


    render() {
        const { nome, tipo, ordem, linkYT, idioma, id_trilha, id_modulo, data_publicacao, id_materia, editorState, duracao, id_modulo_selecionado, erro, success, carregando, formSuccess, dadosApi } = this.state;
        var materias = [];
        if (this.props.materias) materias = this.props.materias.dados;
        var modulos = [];
        if (this.props.modulos) modulos = this.props.modulos.dados.modulos;
        // var tipoAula = [];
        // if (this.props.tipoAula) tipoAula = this.props.tipoAula.dados;
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path

        var s = "https://open.spotify.com/episode/2Yw4Tm1oYmVcVhAQZXkatJ?si=Sq5kn-DMTemYNBy40P__Cg";
        var spotify = []
        var split = s.split("?").forEach(
            character => character.split("/").forEach(character2 => {
                if (!character2.includes("si=") && !character2.includes("episode") && !character2.includes("https:") && !character2.includes("open.spotify.com")) {
                    spotify = character2
                }
            }
            )
        )
        //////console.log(spotify)
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos",
            state: { msg: idioma.cadastrarAula.msg_sucesso }
        }}></Redirect>

        var tipoAula = [
            // { id: 5, nome: idioma.cadastrarAula.outros },
            { id: 1, nome: idioma.cadastrarAula.pdf },
            { id: 4, nome: idioma.cadastrarAula.podcast },
            { id: 3, nome: idioma.cadastrarAula.texto },
            { id: 2, nome: idioma.cadastrarAula.video },
        ]

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarAula.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div>
                                <AlertaErro erros={erro} />
                                <AlertaSuccess erros={success} />
                                <div>
                                    <Form>
                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarAula.nome}</Label>
                                                    <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChangeInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrarAula.nome2} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <Label for="ordem">{idioma.cadastrarAula.selecione_ord}</Label>
                                                <Input type="number" value={ordem} onChange={(ev) => this.onChangeInput("ordem", ev)} id="ordem" name="ordem" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="duracao">{idioma.cadastrarAula.selecione_dur}</Label>
                                                    <Input type="number" value={duracao} onChange={(ev) => this.onChangeInput("duracao", ev)} id="duracao" name="duracao" placeholder={idioma.cadastrarAula.duracao} />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <Label for="modulo">{idioma.cadastrarAula.modulo}</Label>
                                                <Input type="select" onChange={(ev) => this.handleSelectedModulo("id_modulo", ev)} id="id_modulo" name="id_modulo" >
                                                    <option>{idioma.cadastrarAula.escolher_mod}</option>
                                                    {modulos.map(modulo => (
                                                        <option value={modulo.modulo.id} key={modulo.modulo.id} selected={modulo.modulo.id == id_modulo ? true : false}>
                                                            {modulo.modulo.nome}
                                                        </option>))}
                                                </Input>

                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="data_publicacao">{idioma.cadastrarAula.data_pub}</Label>
                                                    <Input type="date" value={data_publicacao} onChange={(ev) => this.onChangeInput("data_publicacao", ev)} id="data_publicacao" name="data_publicacao" placeholder={idioma.cadastrarAula.data_pub} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Label for="materia">{idioma.cadastrarAula.materia}</Label>
                                                <Input type="select" onChange={(ev) => this.onChangeInput("id_materia", ev)} id="id_materia" name="id_materia" >
                                                    <option>{idioma.cadastrarAula.escolher_mat}</option>
                                                    {materias.map(materia => (
                                                        id_modulo_selecionado != "" ?
                                                            <option value={materia.materia.id} key={materia.materia.id} selected={materia.materia.id == id_materia ? true : false}>
                                                                {materia.materia.nome}
                                                            </option>
                                                            : ""
                                                    ))}
                                                </Input>
                                            </Col>
                                            <Col>
                                                <Label for="tipo">{idioma.cadastrarAula.tipo_aula}</Label>
                                                <Input type="select" onChange={(ev) => this.onChangeInput("tipo", ev)} id="tipo" name="tipo" >
                                                    <option>{idioma.cadastrarAula.escolher_aul}</option>
                                                    {tipoAula.map(tipo =>
                                                        <option value={tipo.id} key={tipo.id}>
                                                            {tipo.nome}
                                                        </option>
                                                    )}
                                                </Input>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            {tipo == 2 ?
                                                <div>
                                                    <Label for="linkYT">{idioma.cadastrarAula.link}</Label>
                                                    <Input type="text" value={linkYT} autoComplete="linkYT" onChange={(ev) => this.onChangeInput("linkYT", ev)} name="linkYT" id="linkYT" placeholder={idioma.cadastrarAula.link} />
                                                    <Row>
                                                        <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                                    </Row>
                                                    <Link to="#" onClick={() => this.cadAulaVideo()} className="mt-3"><Botao loading={carregando} texto={idioma.cadastrarAula.bt_cadastar} cor="success" classes="botao-responsivo botao-estilo" icone="user-astronaut" posicao="E" /> </Link>
                                                    <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                                </div>
                                                : tipo == 3 ?
                                                    <div>
                                                        <Label for="conteudo">{idioma.cadastrarAula.conteudo_aul}</Label>
                                                        <Card className="card-editor">
                                                            <div>
                                                                <Editor
                                                                    editorState={editorState}
                                                                    toolbarClassName="toolbarClassName"
                                                                    wrapperClassName="wrapperClassName"
                                                                    editorClassName="editorClassName"
                                                                    onEditorStateChange={this.onEditorStateChange}
                                                                />
                                                            </div>
                                                        </Card>
                                                        <Row>
                                                            <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                                        </Row>
                                                        <Link to="#" onClick={() => this.cadAulaTexto()} className="mt-3">  <Botao loading={carregando} texto={idioma.cadastrarAula.bt_cadastar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                                    </div>
                                                    : tipo == 1 ?
                                                        <div>
                                                            <Label for="file">{idioma.cadastrarAula.doc}</Label>
                                                            <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                                            <Row>
                                                                <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                                            </Row>
                                                            <Link to="#" onClick={() => this.cadAulaPDF()} className="mt-3">  <Botao loading={carregando} texto={idioma.cadastrarAula.bt_cadastar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                                            <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                                        </div>
                                                        :
                                                        tipo == 4 ?
                                                            <div>
                                                                <Label for="linkPodCast">{idioma.cadastrarAula.link2}</Label>
                                                                <Input type="text" id="linkPodCast" name="linkPodCast" className="form-control" autoComplete="linkPodCast" onChange={(ev) => this.onChangeInput("linkPodCast", ev)} name="linkPodCast" id="linkPodCast" placeholder={idioma.cadastrarAula.link2_place} />
                                                                <Row>
                                                                    <Input type="hidden" value={id_trilha} id="id_trilha" name="id_trilha" />
                                                                </Row>
                                                                <Link to="#" onClick={() => this.cadAulaPodCast()} className="mt-3"> <Botao loading={carregando} texto={idioma.cadastrarAula.bt_cadastar} classes="botao-responsivo botao-estilo" cor="success" icone="user-astronaut" posicao="E" /> </Link>
                                                                <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                                            </div>
                                                            : <div className="mt-3"><Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link></div>}
                                        </FormGroup>

                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    trilhas: state.trilhas.trilhas,
    modulos: state.trilhas.modulos,
    materias: state.trilhas.materias,
    tipoAula: state.trilhas.tipoAula,
    trilha: state.trilhas.trilha

})

export default connect(mapStateToProps, actions)(CadastrarAula);