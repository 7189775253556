import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import { Col, Row, Button, Form, FormGroup, Label, Input, DropdownButton, Dropdown } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import SemDados from '../../componentes/Mensagens/semDados';

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';

import { english, portuguese } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';


class CadastrarParticipante extends Component {
    state = {
        nome: "",
        senha: "",
        email: "",
        id_perfil: "",
        id_incubadora: "",
        erro: "",
        cod_idioma: "",
        success: "",
        carregando: "",
        pertence: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        this.setState({ id_perfil: 5 })
        const { id_incubadora } = this.props.match.params;
        this.setState({ id_incubadora })
        if (this.props.usuario && this.props.incubadora && this.props.credenciais) { this.verificaIncubadora(this.props.usuario, this.props.credenciais, id_incubadora) };
        this.verificaPertence(this.props.usuario.id, id_incubadora)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }

    componentDidUpdate(nextProps) {
        const { id_incubadora } = this.props.match.params;

        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_incubadora);
        }

        this.receberDadosAPI();
    }


    componentWillUnmount() {
        this.props.limparIncubadoras();
    }

    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    receberDadosAPI() {
        if (typeof this.props.incubadoras != "undefined" && this.props.incubadoras != null && !this.state.dadosApi && this.props.incubadoras.currentPage === this.state.currentPage) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {

                this.setState({ dadosApi: true });
            }
        }
    }



    cadUsuarioIncubadora() {
        const { nome, idioma, email, senha, id_perfil, id_incubadora } = this.state;
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.postUsuarioIncubadora({ nome, idioma, email, senha, id_perfil, id_incubadora }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { nome, idioma, email, senha, id_perfil, cod_idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrarUsuario.erro_nome } });
        if (!cod_idioma) return this.setState({ erro: { message: idioma.cadastrarUsuario.erro_idioma } });
        if (!email) return this.setState({ erro: { message: idioma.cadastrarUsuario.erro_email } });
        if (!validator.isEmail(email)) return this.setState({ erro: { message: idioma.cadastrarUsuario.erro_email2 } });
        if (!senha) return this.setState({ erro: { message: idioma.cadastrarUsuario.erro_senha } });
        if (senha.length < 6) return this.setState({ erro: { message: idioma.cadastrarUsuario.erro_senha2 } });
        return true;
    }

    verificaIncubadora(usuario, credenciais, id_incubadora) {
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }
        for (let i in usuario.incubadoras) {
            if (usuario.incubadoras[i].id_incubadora == id_incubadora) {
                this.setState({ pertence: true })
            }
        }
    }

    verificaPertence(id_usuario, id_incubadora) {
        this.props.getVerificarPertence(id_usuario, 0, id_incubadora);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { nome, idioma, email, pertence, senha, erro, success, carregando, iconSalvar, formSuccess, id_perfil, id_incubadora } = this.state;
        var perfis = [];
        var usuarioPertence = [];
        var credenciais = []
        var selIdiomaDisable = true;
        if (this.props.credenciais) credenciais = this.props.credenciais;
        for (let c in credenciais) {
            if (credenciais[c].id_componente === 95) selIdiomaDisable = false;
        }
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (this.props.perfis) perfis = this.props.perfis.dados;
        if (formSuccess) return <Redirect to={{
            pathname: "/ecossistema/incubadora/" + id_incubadora + "/usuarios",
            state: { msg: idioma.cadastrarUsuario.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarUsuario.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            {usuarioPertence.pertence ?

                                <Form>
                                    <FormGroup>
                                        <Label for="nome">{idioma.acompanhamento.nome}</Label>
                                        <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.acompanhamento.nome2} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="cod_idioma">{idioma.cadastrarUsuario.idioma}</Label>
                                        <Input type='select' onChange={(ev) => this.onChnageInput('cod_idioma', ev)} id='cod_idioma' name='cod_idioma'>
                                            <option>{idioma.cadastrarUsuario.erro_idioma}</option>
                                            <option value='portugues'>{idioma.cadastrarUsuario.portugues}</option>
                                            {credenciais.map(credencial => ((credencial.id_componente === 95) ?
                                                <option value='ingles'>{idioma.cadastrarUsuario.ingles}</option>
                                                : ""))}
                                        </Input>
                                    </FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="email">{idioma.cadastrarUsuario.email}</Label>
                                                <Input type="email" value={email} autoComplete="email" onChange={(ev) => this.onChnageInput("email", ev)} name="email" id="exampleEmail" placeholder={idioma.cadastrarUsuario.email} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="senha">{idioma.cadastrarUsuario.senha}</Label>
                                                <Input type="password" value={senha} autoComplete="senha" onChange={(ev) => this.onChnageInput("senha", ev)} name="senha" id="senha" placeholder={idioma.cadastrarUsuario.idioma} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*select bar com as credenciais*/}
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input type="hidden" value={id_perfil} id="id_perfil" name="id_perfil" />
                                                <Input type="hidden" value={id_incubadora} id="id_startup" name="id_incubadora" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Link to="#" onClick={() => this.cadUsuarioIncubadora()}> <Botao loading={carregando} classes="botao-responsivo botao-estilo" texto={idioma.cadastrar.bt_cadastrar} icone={iconSalvar} /> </Link>
                                    <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                </Form>
                                : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.cadastrarStartup.erro_acesso} />}

                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario,
    perfis: state.usuarios.perfis,
    pertence: state.incubadoras.pertence

})

export default connect(mapStateToProps, actions)(CadastrarParticipante);