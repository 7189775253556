import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Col, Row, Button, Form, FormGroup, Label, Input, DropdownButton, Dropdown } from 'reactstrap';
import BotaoIcon from '../../componentes/BotaoIcon';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import Swal from 'sweetalert2';
import MultiselectCheckox from 'react-multiselect-checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class Radar extends Component {
    state = {
        gabarito: "",
        resposta: "",
        respostas: [],
        resposta_texto: "",
        resposta_texto_longo: "",
        id_atividade: "",
        id_questao_selecionada: "",
        id_modulo: "",
        id_usuario: "",
        listaQuestoes: "",
        splitado: [],
        n: 3,
        step: 1,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        formSuccess: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {

        const { id_atividade, id_trilha, id_modulo, id_materia } = this.props.match.params;
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });

        this.setState({ id_atividade });
        this.setState({ id_modulo });
        this.setState({ id_materia });

        this.setState({ id_trilha });
        if (this.props.usuario) this.listarQuestoes(id_atividade, this.props.usuario.id);
        this.listarTrilha(id_trilha);
        this.listarAtividade(id_atividade)
        if (this.props.questoes) {
            this.getIdQuestoes(this.props.questoes.dados)
            this.handleAnswers(this.props.questoes.dados)
        }

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_trilha, id_atividade } = this.props.match.params;
        if (!this.props.questoes && nextProps.questoes) {
            this.listarQuestoes(id_atividade, this.props.usuario.id);


        }
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparQuestoes()
    }

    receberDadosAPI() {
        if (typeof this.props.atividade != "undefined" && this.props.atividade != null && !this.state.dadosApi) {
            return this.setState({ dadosApi: true });
        }

        if (typeof this.props.questoes != "undefined" && this.props.questoes != null && !this.state.dadosApi) {
            return this.setState({ dadosApi: true });
        }

    }

    getIdQuestoes(questoes) {
        var lista = []
        for (const q in questoes) {
            if (questoes[q].questao.tipo_campo == "longa" || questoes[q].questao.tipo_campo == "curta")
                lista = lista.concat(questoes[q].questao.id)
        }
        return this.setState({ listaQuestoes: lista })
    }

    listarAtividade(id_atividade) {

        this.props.getVerAtividade(id_atividade);
        const { atividade } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (atividade === "undefined") {
            return null;
        }
    }
    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listarQuestoes(id_atividade, id_usuario) {
        this.props.limparQuestoes()
        this.props.getQuestoes(id_atividade, id_usuario);
        const { questoes } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (questoes === "undefined") {
            return null;
        }
    }


    alerta(icon, title) {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: icon,
            title: title
        })
    }

    checked(questoes, id_questao, id_opcao, value) {
        const { id_atividade, id_usuario } = this.state
        let checked = "";
        if (questoes.length > 0) {
            for (const cont in questoes) {
                if (questoes[cont].questao.id == id_questao) {
                    for (const cont2 in questoes[cont].opcoes) {
                        if (questoes[cont].opcoes[cont2].id == id_opcao) {
                            if (questoes[cont].opcoes[cont2].opcao == value) {
                                checked = "checked";
                            }
                        }
                    }
                }


            }
        }
        this.listarQuestoes(id_atividade, id_usuario)
        return checked;

    }

    onChangeInput = (field, ev) => {
        //////console.log(field, ev.target.value)
        this.setState({ [field]: ev.target.value });
    }

    cadRespostaTexto(id_questao, id_usuario) {
        const { n, resposta, id_trilha, id_atividade, id_materia, id_modulo } = this.state
        var id_curso = id_trilha
        this.setState({ carregando: true })
        this.props.postQuestaoEntrega({ id_questao, resposta, id_usuario, id_curso, id_atividade, id_materia, id_modulo }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ gabarito: msg.erro.gabarito })
                this.setState({ carregando: false });
                this.setState({ formSuccess: true })

            }
        }
        )
    }

    handleN(n, campo) {
        this.setState({ n: n + 1 })
        this.setState({ [campo]: "" })

    }

    salvar(id_questao, id_opcao, ev) {
        const { id_usuario, id_atividade, id_trilha, id_modulo, id_materia, step } = this.state;
        var id_curso = id_trilha
        const listaQuestoes = this.props.questoes;
        var resposta = ""
        if (this.props.questoes) {
            if (ev.target.checked == true) {
                resposta = resposta.concat(ev.target.value)
            }
            this.setState({ id_questao });
            this.setState({ id_opcao });
            this.setState({ carregando: true });
            this.handleSelectedQuestion(id_questao)
        }
        ////console.log(resposta)
        this.props.postQuestaoEntrega({ id_questao, resposta, id_opcao, id_usuario }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ gabarito: msg.erro.gabarito })
                this.setState({ carregando: false });
                this.setState({ formSuccess: true })
                this.checked(listaQuestoes, id_questao, id_opcao, resposta)
            }
        }
        )
    }



    handlePage(step, action) {
        var questoes = []
        if (this.props.questoes) questoes = this.props.questoes.dados;

        if (action == "M") {
            this.setState({ step: step - 1 })
        } else if (step < questoes.length) {
            this.setState({ step: step + 1 })
        }
    }

    handleSelectedQuestion(id_questao_selecionada) {
        this.setState({ id_questao_selecionada: id_questao_selecionada })
    }

    handleAnswers(lista) {
        const { splitado } = this.state
        //////console.log(lista)
        for (let i in lista) {
            if (lista[i].entrega) {
                splitado[i] = { id: lista[i].entrega.id_questao, respostas: lista[i].entrega.resposta.split(",") }
                //////console.log(lista[i].entrega, splitado)
            }
        }
    }

    checkAnswer(respostas, opcao) {
        let checked = ""
        //////console.log(respostas, opcao)
        for (let i in respostas) {
            if (respostas[i] == opcao) {
                checked = "checked"
            }
        }
        return checked

    }

    handleArray(id_questao, id_opcao, ev, resposta) {
        const { id_usuario, id_atividade, id_trilha, id_modulo, id_materia, respostas } = this.state;
        var id_curso = id_trilha
        const listaQuestoes = this.props.questoes;
        if (ev.target.checked) {
            this.setState(prevState => ({ respostas: [...prevState.respostas, resposta] }))
        }


    }

    entregamultipla(id_questao, id_usuario, respostas) {
        // resposta = respostas.reduce((acc) => `${acc},`)
        const { id_trilha, id_materia, id_modulo, id_atividade } = this.state
        var id_curso = id_trilha
        var resposta = respostas.toString()
        ////console.log(resposta)
        this.props.postQuestaoEntrega({ id_questao, resposta, id_usuario, id_curso, id_materia, id_modulo, id_atividade }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ gabarito: msg.erro.gabarito })
                this.setState({ carregando: false });
                this.setState({ formSuccess: true })
                // this.checked(listaQuestoes, id_questao, id_opcao, resposta)
            }
        }
        )
    }

    render() {
        // Form names
        const { respostas, gabarito, id_questao_selecionada, step, id_trilha, id_atividade, id_modulo, id_materia, dadosApi, listaQuestoes, resposta, splitado, resposta_texto, resposta_texto_longo, carregando, id_usuario, n, formSuccess, idioma } = this.state;
        var atividade = []
        if (this.props.atividade) atividade = this.props.atividade;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var trilha = [];
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = [];

        if (this.props.usuario) usuario = this.props.usuario;
        var questoes = []
        if (this.props.questoes) questoes = this.props.questoes.dados;

        var erro = { message: idioma.verAtividade.resp_incorreta }

        var success = { message: idioma.verAtividade.resp_incorreta }

        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/" + id_modulo + "/aulas",
            state: { msg: idioma.cadastrarAtividade.msg_sucesso }
        }}></Redirect>

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.verAtividade.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className=" mb-1 my-3 " ><b>{idioma.verAtividade.titulo}:</b> {atividade.nome}</div>
                                    <div className=" mb-1 my-3 " ><b>{idioma.verAtividade.desc}:</b> <span dangerouslySetInnerHTML={{ __html: atividade.descricao }} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12">
                                    {dadosApi ?
                                        <div>

                                            {questoes.map(questao => (questao.entrega == null) ?
                                                <>
                                                    {questao.questao.ordem == step ?
                                                        <Form className="mb-3">
                                                            <div key={questao.questao.id}>

                                                                {questao.questao.tipo_campo == "longa" ?
                                                                    <div>
                                                                        <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>                                                                        <Input type="textarea" value={resposta} autoComplete="resposta" onClick={() => this.handleSelectedQuestion(questao.questao.id)} onChange={(ev) => this.onChangeInput("resposta", ev)} name="resposta" id="resposta" placeholder={idioma.verAtividade.resp} />

                                                                    </div> :
                                                                    questao.questao.tipo_campo == "curta" ?

                                                                        <div>
                                                                            <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>                                                                            <Input type="text" value={resposta} autoComplete="resposta" onClick={() => this.handleSelectedQuestion(questao.questao.id)} onChange={(ev) => { this.onChangeInput("resposta", ev) }} name="resposta" id="resposta" placeholder={idioma.verAtividade.resp} />

                                                                        </div>

                                                                        :
                                                                        questao.questao.tipo_campo == "multipla" ?
                                                                            <div>
                                                                                <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>
                                                                                {questao.opcoes.map(opcao => (
                                                                                    <FormGroup check>
                                                                                        <Label check><Input type="checkbox" value={opcao.opcao} autoComplete="resposta" onClick={() => this.handleSelectedQuestion(questao.questao.id)} onChange={(ev) => {
                                                                                            this.handleArray(opcao.id_questao, opcao.id, ev, opcao.opcao)
                                                                                        }} name="resposta" id="resposta" key={opcao.id} />{opcao.opcao}</Label>
                                                                                    </FormGroup>
                                                                                ))}
                                                                            </div> :
                                                                            questao.questao.tipo_campo == "unica" ?
                                                                                <div>
                                                                                    <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>                                                                                    {questao.opcoes.map(opcao => (

                                                                                        <FormGroup check key={opcao.id}>
                                                                                            <Label check><Input type="radio" value={opcao.opcao} autoComplete="resposta" name={opcao.id_questao} id="resposta" onChange={(ev) => {
                                                                                                this.salvar(opcao.id_questao, opcao.id, ev)
                                                                                            }} key={opcao.id} />{opcao.opcao}</Label>
                                                                                        </FormGroup>
                                                                                    ))}
                                                                                </div> : questao.questao.tipo_campo == null ? "" : ""}
                                                            </div>

                                                        </Form>
                                                        : ""}
                                                </>

                                                :
                                                questao.questao.ordem == step ?
                                                    <Form className="mb-3">
                                                        <div key={questao.questao.id}>

                                                            {questao.questao.tipo_campo == "longa" ?
                                                                <div>
                                                                    <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>                                                                    <Input type="textarea" value={questao.entrega.resposta} name="resposta" id="resposta" disabled />

                                                                </div> :
                                                                questao.questao.tipo_campo == "curta" ?
                                                                    <div>
                                                                        <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>                                                                        <Input type="text" value={questao.entrega.resposta} name="resposta_texto" id="resposta_texto" disabled />

                                                                    </div> :
                                                                    questao.questao.tipo_campo == "multipla" ?
                                                                        < div >

                                                                            <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>                                                                            {questao.opcoes.map(opcao => (
                                                                                splitado.map(splitado => (opcao.id_questao == splitado.id) ?

                                                                                    < FormGroup check >
                                                                                        <Label check><Input type="checkbox" value={opcao.opcao} autoComplete="resposta" onChange={(ev) => this.salvar(opcao.id_questao, opcao.id, ev)} name="resposta" id="resposta" checked={this.checkAnswer(splitado.respostas, opcao.opcao)} key={opcao.id} disabled />{opcao.opcao}</Label>
                                                                                    </FormGroup>

                                                                                    : "")
                                                                            ))}


                                                                        </div> :
                                                                        questao.questao.tipo_campo == "unica" ?
                                                                            <>
                                                                                <div>
                                                                                    <strong><h5><Label for="resposta">{idioma.verAtividade.quest} {questao.questao.ordem && questao.questao.ordem}: {questao.questao.titulo}</Label></h5></strong>                                                                                    {questao.opcoes.map(opcao => (opcao.id == questao.entrega.id_opcao) ?

                                                                                        <FormGroup check key={opcao.id}>
                                                                                            <Label check><Input type="radio" value={opcao.opcao} autoComplete="resposta" onChange={(ev) => this.salvar(opcao.id_questao, opcao.id, ev)} name={opcao.id} id="resposta" checked disabled key={opcao.id} />{opcao.opcao}</Label>
                                                                                        </FormGroup>
                                                                                        :
                                                                                        <FormGroup check key={opcao.id}>
                                                                                            <Label check><Input type="radio" value={opcao.opcao} autoComplete="resposta" onChange={(ev) => this.salvar(opcao.id_questao, opcao.id, ev)} name={opcao.id} id="resposta" disabled key={opcao.id} />{opcao.opcao}</Label>
                                                                                        </FormGroup>)}
                                                                                </div>
                                                                            </> : questao.questao.tipo_campo == null ? ""
                                                                                : ""}
                                                        </div>
                                                    </Form>
                                                    : "")}
                                        </div>
                                        : <div className="d-flex justify-content-center"> <Spinner color="primary" /></div>}
                                    <div className="d-flex justify-content-start">
                                        <Button onClick={() => {
                                            this.cadRespostaTexto(id_questao_selecionada, id_usuario)
                                            this.entregamultipla(id_questao_selecionada, id_usuario, respostas)

                                            this.listarQuestoes(id_atividade, id_usuario)
                                        }} color="success" >{idioma.verAtividade.bt_salvar}</Button>{' '}

                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <Button onClick={() => this.handlePage(step, "M")} outline color="secondary" disabled={step == 1 ? true : false}>{idioma.verAtividade.ante}</Button>{' '}
                                        <Button outline color="secondary">{step}</Button>{' '}
                                        <Button onClick={() => {
                                            this.handlePage(step, "P")
                                            //////console.log("ai oh")
                                        }} outline color="secondary" disabled={step == questoes.length ? true : false}>{idioma.verAtividade.prox}</Button>{' '}

                                    </div>
                                    <div className="mt-2">
                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link>

                                        {credenciais.map(credencial => ((credencial.id_componente == 52) ?
                                            usuario.id == trilha.id_autor ?
                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulo/" + id_modulo + "/materia/" + id_materia + "/atividade/" + id_atividade + "/editar/"}>
                                                    <Botao texto={idioma.verAtividade.bt_editar} icone="cogs" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} classes="mr-1" />
                                                </Link>
                                                : ""
                                            : ""))}

                                        {step == questoes.length ?
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/" + id_modulo + "/aulas"} onClick={() => {
                                                this.alerta("success", idioma.verAtividade.atv_fim)
                                            }}>
                                                <Botao texto={idioma.verAtividade.bt_finalizar} icone="check" posicao="E" cor="success" />
                                            </Link>
                                            : ""}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    atividade: state.trilhas.atividade,
    questoes: state.trilhas.questoes,
    trilha: state.trilhas.trilha,

})

export default connect(mapStateToProps, actions)(Radar);