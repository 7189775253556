import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
// import Popup from '../../componentes/Alertas/Popup';
import { Table } from 'reactstrap';
import Skeleton from "react-loading-skeleton";
import ProgressBar from 'react-customizable-progressbar'
import Swal from 'sweetalert2';
import Titulo from '../../componentes/TituloPaginas';
import CarregandoTabela from '../../componentes/Skeleton/CarregandoTabela';

import SemDados from '../../componentes/Mensagens/semMatricula';
import { Rating } from '@material-ui/lab';


import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';


class ParticipantesCurso extends Component {
    state = {
        currentPage: 1,
        size: 10,
        progresso: "",
        id_usuario: "",
        id_trilha: "",
        id_modulo: "",
        id_usuario: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha })
        this.listarParticipantes(id_trilha);
        this.listar(id_trilha, this.props.usuario.id);
        this.listarTrilha(id_trilha)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.alunos && nextProps.alunos) {
            this.listarParticipantes(id_trilha);
            this.listarTrilha(id_trilha)
        }
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha)
        }
        if (!this.props.modulos && nextProps.modulos) {
            this.listar(id_trilha, this.props.usuario.id);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparAlunos()
    }

    receberDadosAPI() {
        if (typeof this.props.alunos != "undefined" && this.props.alunos != null && !this.state.dadosApi) {
            if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi) {
                if (typeof this.props.modulos != "undefined" && this.props.modulos != null && !this.state.dadosApi) {

                    this.setState({ dadosApi: true });
                }
            }
        }

    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listar(id_trilha, id_usuario) {
        this.props.getModulos(id_trilha, id_usuario)
        const { modulos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (modulos === "undefined") return null
    }

    listarParticipantes(id_trilha) {
        const { currentPage, size } = this.state
        this.props.getAlunosCurso(id_trilha, currentPage, size)
        const { alunos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (alunos === "undefined") return null
    }

    changePageAtual(currentPage, id_trilha) {
        this.props.limparAlunos();
        this.setState({ currentPage })
        this.setState({ dadosApi: false });
        this.listarParticipantes(id_trilha);
    }


    //deletar vinculo participante
    deletandoVinculo(id_usuario, id_trilha) {
        this.setState({ carregando: true })
        this.props.deletarMatricula(id_usuario, id_trilha, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
                this.listarParticipantes(id_trilha);
            }
        })
    }

    chamarAlertaDeletar(id_usuario, id_trilha) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.participantes.modal_titulo,
            html: idioma.participantes.modal_info,
            input: 'text',
            inputPlaceholder: idioma.editarAtividade.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value === idioma.editarAtividade.confirm) {
                this.deletandoVinculo(id_usuario, id_trilha);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.editarAtividade.modal_erro
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.participantes.modal_sucesso
        })
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
    }

    cleanHover(ev, isActive, el) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = !isActive && this.props.usuario?.personalizacao?.cor_secundaria
    }

    render() {
        const { msg, erro, success, dadosApi, id_trilha, id_usuario, carregando, idioma } = this.state;
        var alunos = [];
        if (this.props.alunos) alunos = this.props.alunos.usuarios;
        var temPaginaAnterior = false;
        if (typeof this.props.alunos != "undefined" && this.props.alunos != null && this.props.alunos.currentPage !== "" && this.props.alunos.currentPage !== 1) {
            temPaginaAnterior = true;
        }
        var proximaPagina = false;
        var temProximaPagina = false;
        if (typeof this.props.alunos != "undefined" && this.props.alunos !== null && this.props.alunos.nextPage <= this.props.alunos.totalPages && this.props.alunos.nextPage != null) {
            proximaPagina = true;
            temProximaPagina = true;
        }

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var usuario = null;

        if (this.props.usuario) usuario = this.props.usuario;
        var path = this.props.match.path
        var matriculado = [];
        if (this.props.modulos) matriculado = this.props.modulos.dados.matriculado;
        var isAuthor = false;
        isAuthor = (trilha?.id_autor === usuario.id)
        for (const l in trilha?.autores) {
            if (!isAuthor) isAuthor = (trilha?.autores[l].id_autor === usuario.id)
        }
        var isAdmin = (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9);

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.participantes.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha matriculado={matriculado} dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {dadosApi ?
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                {trilha?.visibilidade === 'Público' || isAuthor || isAdmin || matriculado ?
                                    <>
                                        {/* <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link> */}
                                        {trilha && isAuthor || isAdmin ?
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/trilhas/" + trilha.id + "/participantes/vincular"} >
                                                {/* <button className="btn btn-outline botao-responsivo btn-md" onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: usuario?.personalizacao?.cor_secundaria }}>{idioma.participantes.bt_vincular}</button> */}
                                                <Botao texto={idioma.participantes.bt_vincular} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                            </Link>
                                            : ""}
                                        <div className="d-flex">
                                            <div className="col-md-12 p-2">
                                                {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                                                <AlertaErro erros={erro} />
                                                <AlertaSuccess erros={success} />

                                                {dadosApi ?
                                                    <div className="row table-responsive">
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>{idioma.participantes.nome}</th>
                                                                    <th>{idioma.participantes.perfil}</th>
                                                                    <th>{idioma.participantes.nota}</th>
                                                                    {isAuthor || isAdmin ? <th>{idioma.participantes.acoes}</th>
                                                                        : ""}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {alunos.map(aluno => (
                                                                    <tr key={aluno.usuario.id}>
                                                                        <td>
                                                                            {aluno.usuario.nome}
                                                                        </td>
                                                                        <td>
                                                                            {aluno.perfil != null ? aluno.perfil.nome : idioma.participantes.nao_possui}
                                                                        </td>
                                                                        <td>
                                                                            <Rating name='simple-controlled' value={aluno.usuario?.avaliacao ? aluno.usuario?.avaliacao : 0} disabled />
                                                                        </td>
                                                                        {isAuthor || isAdmin ?
                                                                            <td>
                                                                                <span className="pr-3" onClick={() => this.chamarAlertaDeletar(aluno.usuario.id, id_trilha)} id="pesquisa" name="pesquisa">
                                                                                    <Botao loading={carregando} cor="danger" texto={idioma.participantes.bt_excluir} icone="times" posicao="E" tamanho="sm" />
                                                                                </span>
                                                                            </td>
                                                                            : ""}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                    : <CarregandoTabela />}
                                                {dadosApi ?
                                                    <nav aria-label="Page navigation example">
                                                        <ul className="pagination justify-content-center">
                                                            <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                                                <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1, id_trilha)} >{idioma.page1}</span>
                                                            </li>

                                                            {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual((this.props.alunos.prevPage ? this.props.alunos.prevPage : 1), id_trilha)}>{this.props.alunos.prevPage ? this.props.alunos.prevPage : ""}</span></li> : ""}

                                                            <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.alunos ? this.props.alunos.currentPage : "1"}</span></li>

                                                            {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual((this.props.alunos.nextPage ? this.props.alunos.nextPage : 1), id_trilha)}>{this.props.alunos.nextPage ? this.props.alunos.nextPage : ""}</span></li> : ""}

                                                            <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                                                <span className="page-link cursor-pointer" onClick={() => this.changePageAtual((this.props.alunos.totalPages ? this.props.alunos.totalPages : 1), id_trilha)}>{idioma.page2}</span>
                                                            </li>
                                                        </ul>
                                                    </nav>
                                                    : ""}
                                            </div>
                                        </div>
                                    </>
                                    : <SemDados subtitulo={idioma.privado} titulo={idioma.conteudo_privado} />}
                            </div>
                        </div>
                        : ""}
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    alunos: state.trilhas.alunos,
    trilha: state.trilhas.trilha,
    modulos: state.trilhas.modulos,

})
export default connect(mapStatetoProps, actions)(ParticipantesCurso);