export const portugues = {
  foto_perfil: 'Editar foto perfil',
  perfil: 'Editar perfil',
  senha: 'Editar senha',
  idioma: 'Editar idioma',
  termos: 'Termos de uso',
  modal_password: {
    titulo: 'Selecione um idioma',
    idioma: 'Idioma',
    portugues: 'Português',
    ingles: 'Inglês',
    bt_salvar: 'Salvar',
    bt_cancelar: 'Cancelar'
  }
}

export const ingles = {
  foto_perfil: 'Edit profile picture',
  perfil: 'Edit profile',
  senha: 'Edit password',
  idioma: 'Edit language',
  termos: 'Terms of use',
  modal_password: {
    titulo: 'Select a language',
    idioma: 'Language',
    portugues: 'Portuguese',
    ingles: 'English',
    bt_salvar: 'Save',
    bt_cancelar: 'Cancel'
  }
}