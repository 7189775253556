export const portugues = {
  page1: 'Primeira',
  page2: 'Última',
  bt_cadastrar: 'Cadastrar',
  bt_voltar: 'Voltar',
  bt_salvar: 'Salvar',
  modal_titulo: 'Deseja deletar este perfil?',
  modal_info: 'Ao fazer isso você concorda que será excluído todos os vínculos de usuários a esta perfil',
  modal_confirm: 'Digite CONFIRMAR para realizar a exclusão',
  bt_deletar: 'Deletar',
  confirm: 'CONFIRMAR',
  modal_erro: 'Mensagem de confirmação inválida!',
  modal_sucesso: 'Perfil excluído com sucesso!',
  titulo: 'Perfis',
  perfil: 'Cadastrar perfil',
  nome: 'Nome',
  acao: 'Ação',
  perfil_input: 'Perfil',
  credenciais: 'Credenciais',
  cadastrarPerfil: {
    erro_nome: 'Preencha o nome',
    erro_descricao: 'Preencha uma descrição',
    msg_sucesso: 'Perfil cadastrado com sucesso!',
    titulo: 'Cadastrar perfil',
    nome: 'Nome',
    nome2: 'Nome completo',
    descricao: 'Descrição'
  },
  editarCredenciais: {
    msg_sucesso: 'Perfil editado com sucesso',
    titulo: 'Editar credenciais',
    cancelar: 'Cancelar edições',
    componentes: 'Componentes',
    perfil: 'Perfil'
  },
  editarPerfil: {
    erro_nome: 'Preencha o nome',
    erro_desc: 'Preencha uma descrição',
    msg_sucesso: 'Perfil editado com sucesso',
    titulo: 'Editar perfil',
    bt_cancelar: 'Cancelar edição',
    nome: 'Nome',
    nome2: 'Nome do perfil',
    desc: 'Descrição',
    desc2: 'Descrição do perfil',
    carregando: 'Carregando...'
  }
}

export const ingles = {
  page1: 'First',
  page2: 'Last',
  bt_cadastrar: 'Register',
  bt_voltar: 'Voltar',
  bt_salvar: 'Save',
  modal_titulo: 'Do you want to delete this profile?',
  modal_info: 'By doing so, you agree that all user links to this profile will be deleted.',
  modal_confirm: 'Type CONFIRM to perform the deletion',
  bt_deletar: 'Delete',
  confirm: 'CONFIRM',
  modal_erro: 'Invalid confirmation message!',
  modal_sucesso: 'Profile successfully deleted!',
  titulo: 'Profiles',
  perfil: 'Register profile',
  nome: 'Name',
  acao: 'Option',
  perfil_input: 'Profile',
  credenciais: 'Credentials',
  cadastrarPerfil: {
    erro_nome: 'Name must be filled',
    erro_descricao: 'Description must be filled',
    msg_sucesso: 'Profile successfully registered!',
    titulo: 'Register profile',
    nome: 'Name',
    nome2: 'Full name',
    descricao: 'Description'
  },
  editarCredenciais: {
    msg_sucesso: 'Profile successfully edited',
    titulo: 'Edit credentials',
    cancelar: 'Cancel edits',
    componentes: 'Components',
    perfil: 'Profile'
  },
  editarPerfil: {
    erro_nome: 'Name must be filled',
    erro_desc: 'Descriptionmust be filled',
    msg_sucesso: 'Profile successfully edited',
    titulo: 'Edit profile',
    bt_cancelar: 'Cancel edit',
    nome: 'Name',
    nome2: 'Profile name',
    desc: 'Description',
    desc2: 'Profile description',
    carregando: 'Loading...'
  }
}