import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { Spinner } from 'reactstrap';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { Link } from 'react-router-dom';
import { portugues, ingles } from '../../utils/paginausuario_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';



class detalheUsuario extends Component {

    state = {
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    renderInfoUsuario() {
        const { usuario } = this.props;
        if (!this.props.usuarioDetalhes) return null;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var isAdmin = false;
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) isAdmin = true;
        const { idioma } = this.state;
        return (
            <dl className="row">
                <dt className="col-sm-3">{idioma.nome}</dt>
                <dt className="col-sm-9">{this.props.usuarioDetalhes ? this.props.usuarioDetalhes.nome : ""}</dt>
                {isAdmin ?
                    <>
                        <dt className="col-sm-3">ID</dt>
                        <dt className="col-sm-9">{this.props.usuarioDetalhes ? this.props.usuarioDetalhes.id : ""}</dt>
                        <dt className="col-sm-3">{idioma.email}</dt>
                        <dt className="col-sm-9">{this.props.usuarioDetalhes ? this.props.usuarioDetalhes.email : ""}</dt>
                        <dt className="col-sm-3">{idioma.data_criacao}</dt>
                        <dt className="col-sm-9">{this.props.usuarioDetalhes ? format(new Date(this.props.usuarioDetalhes.dataCriacao), 'dd/MM/yyyy hh:mm:ss', { locale: pt }) : ""}</dt>
                    </>
                    : ""}

                <dt className="col-sm-3">{idioma.perfil}</dt>
                <dt className="col-sm-9">{this.props.usuarioDetalhes ? this.props.usuarioDetalhes.perfil : ""}</dt>
                <br />
                <dt className="col-sm-3">{idioma.incubadora}</dt>
                <dt className="col-sm-9">
                    {this.props.usuarioDetalhes ?
                        this.props.usuarioDetalhes.incubadoras.map((incubadora) => (
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id}>
                                <button className="btn btn-outline-primary btn-sm mt-2 mr-2">{incubadora.nome}</button>
                            </Link>
                        ))
                        : ""}
                </dt>
                <dt className="col-sm-3">{idioma.startups}</dt>
                <dt className="col-sm-9">
                    {this.props.usuarioDetalhes ?
                        this.props.usuarioDetalhes.startups.map((startup) => (
                            <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/startups/" + startup.id}>
                                <button className="btn btn-outline-primary btn-sm mt-2 mr-2">{startup.nome}</button>
                            </Link>
                        ))
                        : ""}
                </dt>
            </dl >
        )
    }
    render() {
        return (
            <>
                {this.props.usuarioDetalhes ? "" : <dl className="row">
                    <dt className="col-sm-3"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-9"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-3"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-9"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-3"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-9"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-3"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-9"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-3"><Skeleton count={1} width={80} /></dt>
                    <dt className="col-sm-9"><Skeleton count={1} width={80} /></dt>
                </dl>}
                {this.renderInfoUsuario()}
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario
})


export default connect(mapStateToProps, actions)(detalheUsuario);