import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/eventos';
import { Link } from 'react-router-dom';
import Botao from '../../componentes/Botoes';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import SemDados from '../../componentes/Mensagens/semDados';

import { Input } from 'reactstrap';
import iconeUsuario from '../../arquivos/imagens/avatar.jpg'
import { format, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { apiImagem } from '../../config/';

import { ingles, portugues } from '../../utils/paginaeventos_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


import Titulo from '../../componentes/TituloPaginas';


class Eventos extends Component {
    state = {
        currentPage: 1,
        procura: "",
        size: 6,
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        pesquisandoEvent: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { usuario } = this.props;
        this.listarEventos();

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.eventos && nextProps.eventos) this.listarEventos();
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparEventos();
    }

    receberDadosAPI() {
        if (typeof this.props.eventos != "undefined" && this.props.eventos != null && !this.state.dadosApi && this.props.eventos.currentPage === this.state.currentPage) {
            this.setState({ dadosApi: true });
        }
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    changePageAtual(currentPage) {
        this.setState({ currentPage }, () => {
            this.setState({ dadosApi: false });
            this.listarEventos();
        })
    }

    listarEventos() {
        const { currentPage, size } = this.state;
        this.props.getEventos(currentPage, size)
        const { eventos } = this.props;
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (eventos === "undefined") return null
    }

    apagarEvento(id) {
        this.setState({ carregando: true })
        this.props.deletarEvento(id, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }

    chamarAlerta(id) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.moda_titulo,
            text: idioma.modal_info,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.apagarEvento(id);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }

    handleSearchEvent(procura) {
        this.setState({ procura: procura })
        this.setState({ pesquisandoEvent: true })
        const { currentPage, size } = this.state;
        this.props.getSearchEvent(procura, currentPage, size)
    }
    eraseSearchEvent(campo) {
        this.setState({ pesquisandoEvent: false })
        this.setState({ [campo]: "" })
    }

    handleEnter(ev) {
        const { procura } = this.state;
        if (ev.key == 'Enter') {
            this.handleSearchEvent(procura)
        }
    }

    onHover(ev) {
        ev.target.style.backgroundColor = this.props.usuario?.personalizacao.cor_secundaria
        ev.target.style.color = this.props.usuario?.personalizacao.cor_texto_menu_hover
        ev.stopPropagation();
    }

    cleanHover(ev, isActive) {
        ev.target.style.backgroundColor = isActive ? this.props.usuario?.personalizacao.cor_secundaria : 'transparent'
        ev.target.style.color = this.props.usuario?.personalizacao.cor_secundaria
        ev.stopPropagation();
    }

    render() {
        const { msg, carregando, erro, success, procura, dadosApi, pesquisandoEvent, idioma } = this.state;
        const { usuario } = this.props;
        var eventos = [];
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;

        if (pesquisandoEvent == false) {
            if (this.props.eventos) eventos = this.props.eventos.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.eventos != "undefined" && this.props.eventos != null && this.props.eventos.currentPage !== "" && this.props.eventos.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.eventos != "undefined" && this.props.eventos !== null && this.props.eventos.nextPage <= this.props.eventos.totalPages && this.props.eventos.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        } else {
            if (this.props.search) eventos = this.props.search.dados;
            var temPaginaAnterior = false;
            if (typeof this.props.search != "undefined" && this.props.search != null && this.props.search.currentPage !== "" && this.props.search.currentPage !== 1) {
                temPaginaAnterior = true;
            }
            var proximaPagina = false;
            var temProximaPagina = false;
            if (typeof this.props.search != "undefined" && this.props.search !== null && this.props.search.nextPage <= this.props.search.totalPages && this.props.search.nextPage != null) {
                proximaPagina = true;
                temProximaPagina = true;
            }
        }

        ////////console.log(this.props.search)
        ////////console.log(this.props.eventos)

        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item mb-2" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_quaternaria }}>
                            {credenciais.map(credencial => ((credencial.id_componente == 61) ?
                                <Link to={"/" + usuario?.personalizacao?.titulo + "/eventos/cadastrar"}>
                                    <Botao texto={idioma.bt_cadastrar} classes="botao-responsivo" icone="plus" posicao="E" color={usuario?.personalizacao?.cor_terciaria} />
                                    {/* <button className="btn btn-outline btn-md botao-responsivo" onMouseOver={(e) => this.onHover(e)} onMouseOut={(e) => this.cleanHover(e, false)} style={{ border: `1px solid ${usuario?.personalizacao?.cor_secundaria}`, color: usuario?.personalizacao?.cor_secundaria }}>
                                        {idioma.bt_cadastrar}
                                    </button> */}
                                </Link>
                                : ""))}

                            {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                            <AlertaErro erros={erro} />
                            <AlertaSuccess erros={success} />
                            {dadosApi ?
                                <div>
                                    <div className="row pt-4 pb-4">
                                        <div className="col-md-8">
                                            <Input type="text" value={procura} onKeyPress={(ev) => this.handleEnter(ev)} autoComplete="procura" onChange={(ev) => this.onChangeInput("procura", ev)} name="procura" id="procura" placeholder={idioma.pesquisar} />
                                        </div>
                                        <div className="col-md-4">
                                            <div>
                                                <span className="pr-3" onClick={() => this.handleSearchEvent(procura)} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" color={this.props.usuario?.personalizacao?.cor_terciaria} texto={idioma.bt_pesquisar} icone="search" posicao="E" /></span>
                                                <span value={procura} onClick={() => this.eraseSearchEvent("procura")} id="pesquisa" name="pesquisa"><Botao loading={carregando} classes="botao-pesquisa-responsivo" cor="danger" texto={idioma.bt_cancelar} icone="times" posicao="E" /></span>
                                            </div>
                                        </div>
                                    </div>
                                    {eventos.length != 0 ?

                                        <div className="row">

                                            {eventos.map(evento => (
                                                <div className="col-md-6 col-xl-4" key={evento.id}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/eventos/" + evento.id + "/ver"}>
                                                        <div className="directory-card card">
                                                            <div>
                                                                <img className="card-imagem-trilha img-fluid card-eventos" src={evento.fileName != null ? apiImagem + evento.fileName : apiImagem + "fundo_startup.jpg"} alt="Capa do evento" />

                                                                <div className="directory-content text-center p-4">
                                                                    <h5 className="titulo-startup">{evento.nome}</h5>
                                                                    <p className="text-muted">{format(addDays(new Date(evento.data), 1), 'dd/MM/yyyy', { locale: pt })}</p>
                                                                    <span className="text-perfil">
                                                                        <FontAwesomeIcon icon="map-marker-alt" size="1x" color="black" className="mr-3" />
                                                                        {evento.cidade}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}
                                        </div>
                                        : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.sem_dados} />}
                                </div>


                                : <div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="directory-card  card-startups">
                                                <div className="directory-card card">
                                                    <div>
                                                        <div className="directory-skeleton-bg text-center">
                                                            <div className="directory-overlay">
                                                            </div>
                                                        </div>
                                                        <div className="directory-content text-center">
                                                            <div className="titulo-startup">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <div className="titulo-startup">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="directory-card  card-startups">
                                                <div className="directory-card card">
                                                    <div>
                                                        <div className="directory-skeleton-bg text-center">
                                                            <div className="directory-overlay">
                                                            </div>
                                                        </div>
                                                        <div className="directory-content text-center">
                                                            <div className="titulo-startup">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <div className="titulo-startup">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="directory-card  card-startups">
                                                <div className="directory-card card">
                                                    <div>
                                                        <div className="directory-skeleton-bg text-center">
                                                            <div className="directory-overlay">
                                                            </div>
                                                        </div>
                                                        <div className="directory-content text-center">
                                                            <div className="titulo-startup">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                            <div className="titulo-startup">
                                                                <Skeleton count={1} width={80} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>}
                            {dadosApi ?
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className={temPaginaAnterior ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(1)} >{idioma.page1}</span>
                                        </li>

                                        {temPaginaAnterior ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.eventos.prevPage ? this.props.eventos.prevPage : 1)}>{this.props.eventos.prevPage ? this.props.eventos.prevPage : ""}</span></li> : ""}

                                        <li className="page-item active" style={{ backgroundColor: usuario?.personalizacao?.cor_principal }}><span className="page-link cursor-pointer">{this.props.eventos ? this.props.eventos.currentPage : "1"}</span></li>

                                        {proximaPagina ? <li className="page-item"><span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.eventos.nextPage ? this.props.eventos.nextPage : 1)}>{this.props.eventos.nextPage ? this.props.eventos.nextPage : ""}</span></li> : ""}

                                        <li className={temProximaPagina ? "page-item" : "page-item disabled"}>
                                            <span className="page-link cursor-pointer" onClick={() => this.changePageAtual(this.props.eventos ? this.props.eventos.totalPages : 0)}>{idioma.page2}</span>
                                        </li>
                                    </ul>
                                </nav>
                                : ""}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStatetoProps = state => ({
    eventos: state.eventos.eventos,
    search: state.eventos.searchEvento,

})
export default connect(mapStatetoProps, actions)(Eventos);