import {
    GET_TRILHAS, GET_TRILHA, GET_MODULOS, GET_MODULO,
    GET_MATERIAS, GET_MATERIA, GET_AULAS, GET_AULA, LIMPAR_MATERIAS,
    LIMPAR_MODULOS, LIMPAR_MATERIA, LIMPAR_AULA, LIMPAR_MODULO, LIMPAR_TRILHA,
    LIMPAR_TRILHAS, GET_TIPO_AULA, GET_ATIVIDADES, GET_ATIVIDADE, GET_QUESTOES,
    GET_QUESTAO, GET_OPCOES, GET_OPCAO, GET_PROGRESSO_MODULO, GET_MATRICULAS,
    GET_ALUNOS_CURSO, GET_CURSOS_ALUNO, GET_MEUS_CURSOS, GET_FUTUROS_PARTICIPANTES, GET_SEARCH,
    GET_SEARCH_TODOS, GET_SEARCH_MEUS, GET_SEARCH_CURSOS, GET_TRILHAS_PUBLICAS, LIMPAR_QUESTOES, LIMPAR_OPCOES,
    LIMPAR_ALUNOS, LIMPAR_QUESTAO, GET_FEITO, GET_TODOS_ALUNOS_CURSO,
    GET_INCUBADORAS_COMPLETO, GET_PERFIS_ACESSO, GET_USUARIOS_PERFIL, GET_USUARIOS_INCUBADORA, GET_PARTICIPANTES, GET_STARTUPS_COMPLETO, GET_SEARCH_TODOS_CURSOS, GET_AUTORES, LIMPAR_AUTORES, GET_AVALIACAO_TRILHA
} from '../actions/types';
// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_TRILHAS:
            return {
                ...state,
                trilhas: actions.payload.data
            }
        case GET_TRILHAS_PUBLICAS:
            return {
                ...state,
                trilhasPublicas: actions.payload.data
            }
        case GET_AVALIACAO_TRILHA:
            return {
                ...state,
                avaliacaoTrilha: actions.payload.data
            }
        case GET_TRILHA:
            return {
                ...state,
                trilha: actions.payload.dados
            }
        case GET_INCUBADORAS_COMPLETO:
            return {
                ...state,
                incubadorasCompleto: null,
                incubadorasCompleto: actions.payload.data
            }
        case GET_PERFIS_ACESSO:
            return {
                ...state,
                perfis: actions.payload.data
            }
        case GET_USUARIOS_PERFIL:
            return {
                ...state,
                usuariosPerfil: actions.payload.data
            }
        case GET_USUARIOS_INCUBADORA:
            return {
                ...state,
                usuariosIncubadora: actions.payload.data
            }
        case GET_PARTICIPANTES:
            return {
                ...state,
                participantes: actions.payload.data
            }
        case GET_AUTORES:
            return {
                ...state,
                autores: actions.payload.data
            }
        case GET_STARTUPS_COMPLETO:
            return {
                ...state,
                startupsCompleto: null,
                startupsCompleto: actions.payload.data
            }
        case GET_TODOS_ALUNOS_CURSO:
            return {
                ...state,
                todosAlunos: actions.payload.data
            }
        case GET_SEARCH_TODOS:
            return {
                ...state,
                searchTodos: actions.payload.data
            }
        case GET_SEARCH:
            return {
                ...state,
                search: actions.payload.data
            }
        case GET_SEARCH_MEUS:
            return {
                ...state,
                searchMeus: actions.payload.data
            }
        case GET_SEARCH_CURSOS:
            return {
                ...state,
                searchCursos: actions.payload.data
            }
        case GET_SEARCH_TODOS_CURSOS:
            return {
                ...state,
                searchTodosCursos: actions.payload.data
            }
        case GET_MODULOS:
            return {
                ...state,
                modulos: actions.payload.data
            }
        case GET_MODULO:
            return {
                ...state,
                modulo: actions.payload.dados
            }
        case GET_MATERIAS:
            return {
                ...state,
                materias: actions.payload.data
            }
        case GET_MATERIA:
            return {
                ...state,
                materia: actions.payload.dados
            }
        case GET_AULAS:
            return {
                ...state,
                aulas: actions.payload.data
            }
        case GET_AULA:
            return {
                ...state,
                aula: actions.payload.dados
            }
        case GET_TIPO_AULA:
            return {
                ...state,
                tipoAula: actions.payload.data
            }
        case GET_ATIVIDADES:
            return {
                ...state,
                atividades: actions.payload.data
            }
        case GET_FEITO:
            return {
                ...state,
                feito: actions.payload.data
            }
        case GET_ATIVIDADE:
            return {
                ...state,
                atividade: actions.payload.dados
            }
        case GET_QUESTOES:
            return {
                ...state,
                questoes: actions.payload.data
            }
        case GET_QUESTAO:
            return {
                ...state,
                questao: actions.payload.dados
            }
        case GET_OPCOES:
            return {
                ...state,
                opcoes: actions.payload.data
            }
        case GET_OPCAO:
            return {
                ...state,
                opcao: actions.payload.dados
            }
        case GET_MATRICULAS:
            return {
                ...state,
                matriculas: actions.payload.dados
            }
        case GET_ALUNOS_CURSO:
            return {
                ...state,
                alunos: actions.payload.data
            }
        case GET_CURSOS_ALUNO:
            return {
                ...state,
                cursos: actions.payload.data
            }
        case GET_FUTUROS_PARTICIPANTES:
            return {
                ...state,
                futurosAlunos: actions.payload.data
            }
        case GET_MEUS_CURSOS:
            return {
                ...state,
                meusCursos: actions.payload.data
            }
        case LIMPAR_MATERIAS:
            return {
                materias: null
            }
        case LIMPAR_MODULOS:
            return {
                modulos: null
            }
        case LIMPAR_MATERIA:
            return {
                materia: null
            }
        case LIMPAR_AULA:
            return {
                aula: null
            }
        case LIMPAR_MODULO:
            return {
                modulo: null
            }
        case LIMPAR_TRILHA:
            return {
                trilha: null
            }
        case LIMPAR_TRILHAS:
            return {
                trilhas: null
            }
        case LIMPAR_QUESTOES:
            return {
                questoes: null
            }
        case LIMPAR_OPCOES:
            return {
                opcoes: null
            }
        case LIMPAR_AUTORES:
            return {
                autores: null
            }
        case LIMPAR_ALUNOS:
            return {
                futurosAlunos: null
            }
        case LIMPAR_QUESTAO:
            return {
                questao: null
            }
        default:
            return state;
    }
}