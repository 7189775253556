import React, { Component } from 'react';
import { Form, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import Botao from '../../componentes/Botoes'
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { apiImagem } from '../../config/';
import { Link, Redirect } from 'react-router-dom';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import { portugues, ingles } from '../../utils/paginalogin_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
library.add(fas);

class RecuperarSenha extends Component {
    state = {
        id: "",
        recuperarSenha: "",
        senha: "",
        chave: "",
        erro: "",
        success: "",
        carregando: false,
        formSuccess: false,
        chaveInvalida: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { recuperarSenha } = this.props.match.params;
        this.setState({ recuperarSenha });
        this.props.validarChave(recuperarSenha, (msg) => {
            if (msg.erro.error) {
                this.setState({ chaveInvalida: true })
            } else {
                this.setState({ id: msg.erro.usuario.id })
                this.setState({ recuperarSenha })
            }
        })
        const idiomaLocal = localStorage.getItem('idioma');
        if (idiomaLocal) {
            if (idiomaLocal === 'ingles') {
                this.setState({ idioma: ingles })
                this.setState({ msg_backend: english })
            } else {
                this.setState({ idioma: portugues })
                this.setState({ msg_backend: portuguese })
            }
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value })
    }
    async atualizarSenha() {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        if (!this.validate()) return;
        this.setState({ carregando: true })
        const { id, senha, recuperarSenha } = this.state;
        this.props.atualizarSenha({ id, senha, recuperarSenha }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })
    }

    handleLanguage(tipo, ev) {
        ev.preventDefault();
        if (tipo === 'ingles') {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
            localStorage.setItem('idioma', 'ingles')
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
            localStorage.setItem('idioma', 'portugues')
        }
    }
    validate() {
        const { senha, idioma } = this.state;
        if (!senha) return this.setState({ erro: { message: idioma.erro_senha } });
        if (senha.length < 6) return this.setState({ erro: { message: idioma.erro_senha2 } });
        return true;
    }
    render() {
        const { id, senha, chave, erro, msg, carregando, success, formSuccess, chaveInvalida, idioma } = this.state;
        const { personalizacao } = this.props.match.params;
        const { usuarioPersonalizacao } = this.props;
        if (chaveInvalida) {
            return <Redirect to={{
                pathname: `/${personalizacao}/login`,
                state: { msg: idioma.msg_erro }
            }} />
        }
        if (formSuccess) {
            return <Redirect to={{
                pathname: `/${personalizacao}/login`,
                state: { msg: idioma.msg_sucesso2 }
            }} />
        }
        return (
            <>
                <div className="tela-login container-fluid d-flex h-100 flex-column">
                    <div className="row" style={{ height: '98vh' }}>
                        <div className="col-md-3 col-sm-12 sem-padding">
                            <div className="login">
                                <Form className="form-signin text-center">
                                    <div style={{ display: 'flex', marginBottom: '30px', marginTop: '-40px', justifyContent: 'flex-end' }}>
                                    </div>
                                    <img className="mb-4" src={usuarioPersonalizacao?.data ? `${apiImagem}${usuarioPersonalizacao?.data?.dados[0]?.logo_dark_arquivo}` : "imagens/LOGO_EVOLUCAO_BLACK.png"} alt="Via Evolução" width="100%" style={{ maxHeight: "300px" }} />
                                    <div style={{ display: 'flex', marginBottom: '30px', justifyContent: 'center' }}>
                                        <button onClick={(e) => this.handleLanguage('portugues', e)} style={{ marginRight: '10px', border: `${idioma === portugues ? '2px solid gray' : 'none'}`, borderRadius: '2px', backgroundColor: 'transparent' }}>
                                            <img src='/imagens/brazil-flag.png' alt='Bandeira Brasil' style={{ width: '30px', height: 'auto' }} />
                                        </button>
                                        <button onClick={(e) => this.handleLanguage('ingles', e)} style={{ border: `${idioma === ingles ? '2px solid gray' : 'none'}`, borderRadius: '2px', backgroundColor: 'transparent' }}>
                                            <img src='/imagens/usa-flag.png' alt='Bandeira EUA' style={{ width: '30px', height: 'auto' }} />
                                        </button>
                                    </div>
                                    <h2 className="h5 mb-3 font-weight-normal">{idioma.bt_atualizarSenha}</h2>
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    {msg ? <AlertaErro erros={{ message: msg }} /> : ""}
                                    <Input type="hidden" value={id} id="id" name="id" />
                                    <Input type="hidden" value={chave} id="chave" name="chave" />
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon="user" /></InputGroupText></InputGroupAddon>
                                        <Input type="password" value={senha} name="senha" id="senha" placeholder={!carregando ? idioma.input_senha : "Carregando..."} disabled={!carregando ? false : true} onChange={(ev) => this.onChangeInput("senha", ev)} />
                                    </InputGroup>
                                    <br />
                                    <span onClick={() => this.atualizarSenha()}> <Botao loading={carregando} texto={idioma.bt_atualizarSenha} cor="success" tamanho="md" posicao="E" classes="btn-block" icone="key" /> </span>
                                    <p className="text-center mt-2"><Link to={`/${personalizacao}/login`}>{idioma.login}</Link></p>
                                </Form>
                            </div>
                        </div>
                        <div className="col-md-9 fundo-img-login">
                            <img className="img-fluid w-100" src={usuarioPersonalizacao ? `${apiImagem}${usuarioPersonalizacao?.data?.dados[0]?.banner_arquivo}` : "imagens/fundo_via_evolucao.png"} style={{ minHeight: '100%' }} alt="Evolução" />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = state => ({
    usuarioPersonalizacao: state.usuarios.usuarioPersonalizacao
})

export default connect(mapStatetoProps, actions)(RecuperarSenha);
