import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Col, Row, Button, Form, FormGroup, Label, Input, DropdownButton, Dropdown } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import SemDados from '../../componentes/Mensagens/semDados';
import Titulo from '../../componentes/TituloPaginas';

import { portugues, ingles } from '../../utils/paginastartup_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class CadastrarParticipante extends Component {
    state = {
        nome: "",
        senha: "",
        email: "",
        id_perfil: "",
        id_startup: "",
        id_incubadora: "",
        pertence: false,
        erro: "",
        success: "",
        carregando: "",
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese,
        idiomaCadastro: ''
    }
    componentDidMount() {
        this.setState({ id_perfil: 5 })
        const { id_startup, id_incubadora } = this.props.match.params;
        this.setState({ id_startup })
        this.setState({ id_incubadora })
        this.verificaPertence(this.props.usuario.id, id_startup)

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

        // if (this.props.usuario && this.props.credenciais) this.handleButtons(this.props.usuario, this.props.credenciais, id_startup, id_incubadora)

    }

    componentDidUpdate(nextProps) {
        const { id_startup } = this.props.match.params;

        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_startup);
        }

        this.receberDadosAPI();
    }


    onChnageInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    componentWillUnmount() {
        this.props.limparStartups()
    }

    receberDadosAPI() {
        if (typeof this.props.startups != "undefined" && this.props.startups != null && !this.state.dadosApi && this.props.startups.currentPage === this.state.currentPage) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }



    cadParticipante() {
        const { nome, idiomaCadastro, email, senha, id_perfil, id_startup } = this.state;
        const id_idioma = idiomaCadastro === 'portugues' ? 1 : 3;
        if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.postParticipante({ nome, email, senha, id_idioma, id_perfil, id_startup }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { nome, idiomaCadastro, email, senha, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrarParticipantes.erro_nome } });
        if (!idiomaCadastro) return this.setState({ erro: { message: idioma.cadastrarParticipantes.erro_idioma } });
        if (!email) return this.setState({ erro: { message: idioma.cadastrarParticipantes.erro_email } });
        if (!validator.isEmail(email)) return this.setState({ erro: { message: idioma.cadastrarParticipantes.erro_email2 } });
        if (!senha) return this.setState({ erro: { message: idioma.cadastrarParticipantes.erro_senha } });
        if (senha.length < 6) return this.setState({ erro: { message: idioma.cadastrarParticipantes.erro_senha2 } });
        return true;
    }


    // handleButtons(usuarios, credenciais, id_startup, id_incubadora) {
    //     if (usuarios.startups.length > 0) {
    //         for (let u in usuarios.startups) {
    //             if (usuarios.startups[u].id_startup == id_startup) {
    //                 this.setState({ pertence: true })
    //             }
    //         }
    //     }
    //     if (usuarios.incubadoras.length > 0) {
    //         for (let u in usuarios.incubadoras) {
    //             if (usuarios.incubadoras[u].id_incubadora == id_incubadora) {
    //                 this.setState({ pertence: true })
    //             }
    //         }
    //     }
    //     if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
    //         this.setState({ pertence: true })
    //     }

    // }

    verificaPertence(id_usuario, id_startup) {
        this.props.getVerificarPertence(id_usuario, id_startup);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { nome, idioma, email, senha, pertence, erro, success, carregando, iconSalvar, formSuccess, id_perfil, id_startup, idiomaCadastro } = this.state;
        var perfis = [];
        var usuarioPertence = [];
        var credenciais = []
        var selIdiomaDisable = true;
        if (this.props.credenciais) credenciais = this.props.credenciais;
        for (let c in credenciais) {
            if (credenciais[c].id_componente === 96) selIdiomaDisable = false;
        }
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (this.props.perfis) perfis = this.props.perfis.dados;
        if (formSuccess) return <Redirect to={{
            pathname: "/startups/" + id_startup + "/acompanhamento",
            state: { msg: idioma.cadastrarParticipantes.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarParticipantes.titulo} />
                </div>

                <div className="container-fluid bloco-topo" >
                    {usuarioPertence.pertence ?
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <AlertaErro erros={erro} />
                                <AlertaSuccess erros={success} />

                                <Form>
                                    <FormGroup>
                                        <Label for="nome">{idioma.cadastrarParticipantes.nome}</Label>
                                        <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChnageInput("nome", ev)} name="nome" id="nome" placeholder={idioma.cadastrarParticipantes.nome2} />
                                    </FormGroup>
                                    <FormGroup >
                                        <Label for="idiomaCadastro">{idioma.cadastrarParticipantes.idioma}</Label>
                                        <Input type='select' onChange={(ev) => this.onChnageInput('idiomaCadastro', ev)} id='idiomaCadastro' name='idiomaCadastro'>
                                            <option>{idioma.cadastrarParticipantes.selecioneIdioma}</option>
                                            <option value='portugues'>{idioma.cadastrarParticipantes.portugues}</option>
                                            {credenciais.map(credencial => ((credencial.id_componente === 96) ?
                                                <option value='ingles'>{idioma.cadastrarParticipantes.ingles}</option>
                                                : ""))}
                                        </Input>
                                    </FormGroup>
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="email">{idioma.cadastrarParticipantes.email}</Label>
                                                <Input type="email" value={email} autoComplete="email" onChange={(ev) => this.onChnageInput("email", ev)} name="email" id="exampleEmail" placeholder={idioma.cadastrarParticipantes.email} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="senha">{idioma.cadastrarParticipantes.senha}</Label>
                                                <Input type="password" value={senha} autoComplete="senha" onChange={(ev) => this.onChnageInput("senha", ev)} name="senha" id="senha" placeholder={idioma.cadastrarParticipantes.senha} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/*select bar com as credenciais*/}
                                    < Row form >
                                        <Col md={6}>
                                            <FormGroup>
                                                <Input type="hidden" value={id_perfil} id="id_perfil" name="id_perfil" />
                                                <Input type="hidden" value={id_startup} id="id_startup" name="id_startup" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Link to="#" onClick={() => this.cadParticipante()}> <Botao loading={carregando} cor='success' classes="botao-responsivo botao-estilo" texto={idioma.participantes.bt_cadastrar} icone={iconSalvar} /> </Link>
                                    <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.participantes.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                </Form>
                            </div>
                        </div>
                        : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.participantes.erro_acesso} />}
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario,
    perfis: state.usuarios.perfis,
    pertence: state.startups.pertence

})

export default connect(mapStateToProps, actions)(CadastrarParticipante);