import React from 'react';
import { BrowserRouter, Router, Switch, Route } from 'react-router-dom'

import { history } from '../history';
import { Provider } from 'react-redux';
import { store, persistor } from '../store/store.js'
import { PersistGate } from 'redux-persist/integration/react';
import permissaoDashboard from '../containers/permissaoDashboard';

import Login from '../paginas/Login';
import RecuperarSenha from '../paginas/Login/recuperarSenha';
import AtualizarSenha from '../paginas/Login/atualizarSenha';

import Dashboard from '../paginas/Dashboard';
import Perfil from '../paginas/Perfil/index';
import EditarPerfil from '../paginas/Perfil/editar';
import EditarSenhaPerfil from '../paginas/Perfil/editarSenha';
import EditarFotoPerfil from '../paginas/Perfil/editarFoto';
import Usuario from '../paginas/Usuario/index';
import VerUsuario from '../paginas/Usuario/ver';
import CadastrarUsuario from '../paginas/Usuario/cadastrar';
import EditarUsuario from '../paginas/Usuario/editar';
import EditarSenhaUsuario from '../paginas/Usuario/editarSenha';
import Startups from '../paginas/Incubadora/listarStartups';
// import MinhaStartup from '../paginas/Startup/perfil';
import MinhaStartup from '../paginas/Startup/minhasStartups';
import StartupMetodologia from '../paginas/Startup/metodologia';
import VincularMetodologia from '../paginas/Startup/vincularMetodologia';
import EditarMetodologia from '../paginas/Startup/editarMetodologia';
import VincularIncubadora from '../paginas/Startup/vincularIncubadora';
import StartupProcessos from '../paginas/Startup/processo';
import StartupEtapa from '../paginas/Startup/etapa';
import CadastrarStartup from '../paginas/Startup/cadastrar';
import EditarStartup from '../paginas/Startup/editar';
import EditarImagemStartup from '../paginas/Startup/editarImagem';
import EditarCapaStartup from '../paginas/Startup/editarCapa';
import Participantes from '../paginas/Startup/participantes';
import CadastrarParticipantes from '../paginas/Startup/cadastrarParticipantes';
import VincularParticipantes from '../paginas/Startup/vincularParticipante';
import Entrega from "../paginas/Startup/entrega";
import NovaEntrega from "../paginas/Startup/novaEntrega";
import Evidencia from "../paginas/Startup/evidencia";
import ConfigStartup from '../paginas/Startup/config';
import ConfigIncubadora from '../paginas/Incubadora/config';
import MinhaIncubadora from '../paginas/Incubadora/listar';
import Ecossistema from '../paginas/Ecossistema/listar';
import Incubadora from '../paginas/Ecossistema/incubadora';
import Startup from '../paginas/Ecossistema/startup';
import CadastrarIncubadora from '../paginas/Ecossistema/cadastrar';
import CadastrarIncubadoraStartup from '../paginas/Ecossistema/cadastrarStartup';
import EditarIncubadora from '../paginas/Ecossistema/editar';
import EditarImagemIncubadora from '../paginas/Ecossistema/editarImagem';
import EditarCapaIncubadora from '../paginas/Ecossistema/editarCapa';
import UsuariosIncubadora from '../paginas/Ecossistema/usuarios';
import CadastrarUsuarioIncubadora from '../paginas/Ecossistema/cadastrarUsuario';
import VincularUsuarioIncubadora from '../paginas/Ecossistema/vincularUsuario';
import Trilhas from '../paginas/Trilhas/listar';
import ConfigTrilha from '../paginas/Trilhas/config';
import ParticipantesCurso from '../paginas/Trilhas/participantes';
import DescricaoTrlha from '../paginas/Trilhas/descricao';
import CadastrarTrilha from '../paginas/Trilhas/cadastrar';
import EditarTrilha from '../paginas/Trilhas/editarTrilha';
import EditarCapaTrilha from '../paginas/Trilhas/editarCapa';
import EditarAula from '../paginas/Trilhas/editarAula';
import EditarMateria from '../paginas/Trilhas/editarMateria';
import EditarAtividade from '../paginas/Trilhas/editarAtividade';
import Modulos from '../paginas/Trilhas/conteudo';
import EditarModulo from '../paginas/Trilhas/editarModulo';
import Aulas from '../paginas/Trilhas/aulas';
import AutoresTrilha from '../paginas/Trilhas/autores';
import cadastrarAtividades from '../paginas/Trilhas/cadastrarAtividade';
import VerAtividade from '../paginas/Trilhas/verAtividade';
import ResultadoAtividade from '../paginas/Trilhas/resumo';
import CadastrarQuestoes from '../paginas/Trilhas/cadastrarQuestoes';
import EditarQuestoes from '../paginas/Trilhas/editarQuestao';
import cadastrarMaterias from '../paginas/Trilhas/cadastrarMateria';
import CadastrarAulas from '../paginas/Trilhas/cadastrarAula';
import VincularAluno from '../paginas/Trilhas/vincularParticipante';
import Configuracoes from '../paginas/Configuracoes/index';
import ListaStartups from '../paginas/Configuracoes/listagemStartups';
import ListaIncubadoras from '../paginas/Configuracoes/listagemIncubadoras';
import ListarMetodologias from '../paginas/Metodologia/listagemMetodologias';
import ListarVinculosMetodologias from '../paginas/Metodologia/listagemVinculosMetodologias';

import ListaTrilhas from '../paginas/Configuracoes/listagemTrilhas';
import Perfis from '../paginas/PerfilAcesso/index';
import CadastrarPerfil from '../paginas/PerfilAcesso/cadastrarPerfil';
import EditarPerfilAcesso from '../paginas/PerfilAcesso/editarPerfil';
import EditarCredenciais from '../paginas/PerfilAcesso/editarCredenciais';

//Metodologia
import listagemProcessos from '../paginas/Metodologia/Processo/listagemProcessos';
import cadastrarProcesso from '../paginas/Metodologia/Processo/cadastrarProcesso';
import editarProcesso from '../paginas/Metodologia/Processo/editarProcesso';
import listagemEtapas from '../paginas/Metodologia/Etapa/listagemEtapas';
import cadastrarEtapa from '../paginas/Metodologia/Etapa/cadastrarEtapa';
import editarEtapa from '../paginas/Metodologia/Etapa/editarEtapa';
import listagemEstagios from '../paginas/Metodologia/Estagio/listagemEstagios';
import cadastrarEstagio from '../paginas/Metodologia/Estagio/cadastrarEstagio';
import editarEstagio from '../paginas/Metodologia/Estagio/editarEstagio';
import listagemEntregas from '../paginas/Metodologia/Entrega/listagemEntregas';
import cadastrarEntrega from '../paginas/Metodologia/Entrega/cadastrarEntrega';
import editarEntrega from '../paginas/Metodologia/Entrega/editarEntrega';
import cadastrarMetodologia from '../paginas/Metodologia/Metodologias/cadastrarMetodologia';

import baseLogin from '../containers/login';
import baseDashboard from '../containers/dashboard';

import EditarPersonalizacao from '../paginas/Configuracoes/editarPersonalizacao';

import Radar from '../paginas/Startup/radar';
import RadarResumo from '../paginas/Startup/radarResumo';
import Eventos from '../paginas/Eventos';
import CadastrarEvento from '../paginas/Eventos/cadastrarEvento';
import EditarEvento from '../paginas/Eventos/editarEvento';
import EditarCapaEvento from '../paginas/Eventos/editarCapa';
import VerEvento from '../paginas/Eventos/ver';
import Conteudos from '../paginas/Conteudos/index';
import VerConteudo from '../paginas/Conteudos/ver';
import CadastrarConteudo from '../paginas/Conteudos/cadastrarnovo';
import EditarConteudo from '../paginas/Conteudos/editarConteudo';
import EditarCapaConteudo from '../paginas/Conteudos/editarCapa';
import Relatorios from '../paginas/Relatorios/index';
import Gestao from '../paginas/Incubadora/gestao';

//chat
import Chat from '../paginas/Chat/index';

//Hub
import Hub from '../paginas/Hub/index';
import CadastrarDesafio from '../paginas/Hub/cadastrarDesafio';
import VerDesafio from '../paginas/Hub/ver';
import EditarDesafio from '../paginas/Hub/editarDesafio';
//Hub - solucoes
import VerSolucao from '../paginas/Hub/verSolucao';
import EditarSolucao from '../paginas/Hub/editarSolucao';
import CadastrarSolucao from '../paginas/Hub/cadastrarSolucao';
import Avaliar from '../paginas/Hub/avaliar';
import EditarAvaliacao from '../paginas/Hub/editarAvaliacao';
//Hub - avaliadores
import Avaliadores from '../paginas/Hub/avaliadores';
import VincularAvaliador from '../paginas/Hub/vincularAvaliador';
import CadastrarAvaliador from '../paginas/Hub/cadastrarAvaliador';

//Beneficios
import Benificios from '../paginas/Beneficios/index';

import cadastrarModulo from '../paginas/Trilhas/cadastrarModulo';
import PerfilAcesso from '../paginas/PerfilAcesso/index';
import { Badge } from 'reactstrap';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';
import base from 'js-video-url-parser/lib/base';

//Permissao
import Permissao from '../paginas/Permissao/index';
import Politica from '../paginas/Permissao/politica';
import vincularAutor from '../paginas/Trilhas/vincularAutor';
//Radar
import Radares from '../paginas/Configuracoes/Radar/index';
import Pilares from '../paginas/Configuracoes/Radar/pilares';
import Perguntas from '../paginas/Configuracoes/Radar/perguntas';
import CadastrarPergunta from '../paginas/Configuracoes/Radar/cadastrarPerguntas';
import Opcoes from '../paginas/Configuracoes/Radar/opcoes';
import EditarPergunta from '../paginas/Configuracoes/Radar/editarPerguntas';
import personalizacao from '../paginas/Configuracoes/personalizacao';
import novaPersonalizacao from '../paginas/Configuracoes/novaPersonalizacao';
import criarVinculoPersonalizacao from '../paginas/Configuracoes/criarVinculoPersonalizacao';
import editarMetodolo from '../paginas/Metodologia/Metodologias/editarMetodolo';
import cadastrarRadar from '../paginas/Configuracoes/Radar/cadastrarRadar';
import editarRadar from '../paginas/Configuracoes/Radar/editarRadar';
import ListagemVinculosRadar from '../paginas/Configuracoes/Radar/listagemVinculosRadar';

export default function Routes() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <BrowserRouter>
                    <PersistGate persistor={persistor}>
                        <Switch>
                            <Route path="/:personalizacao?" exact component={baseLogin(Login)} />
                            <Route path="/:personalizacao?/login" exact component={baseLogin(Login)} />
                            <Route path="/:personalizacao/recuperar-senha" exact component={baseLogin(RecuperarSenha)} />
                            <Route path="/:personalizacao?/atualizar-senha/:recuperarSenha" exact component={baseLogin(AtualizarSenha)} />
                            <Route path="/:perso?/dashboard" exact component={baseDashboard(Dashboard)} />
                            <Route path="/:perso?/eventos" exact component={baseDashboard(Eventos)} />
                            <Route path="/:perso?/eventos/cadastrar" exact component={baseDashboard(CadastrarEvento)} />
                            <Route path="/:perso?/eventos/:id_evento/editar/" exact component={baseDashboard(EditarEvento)} />
                            <Route path="/:perso?/eventos/:id_evento/editar-capa/" exact component={baseDashboard(EditarCapaEvento)} />
                            <Route path="/:perso?/eventos/:id_evento/ver" exact component={baseDashboard(VerEvento)} />
                            <Route path="/:perso?/conteudos" exact component={baseDashboard(Conteudos)} />
                            <Route path="/:perso?/conteudo/:id_conteudo" exact component={baseDashboard(VerConteudo)} />
                            <Route path="/:perso?/conteudos/cadastrar" exact component={baseDashboard(CadastrarConteudo)} />
                            <Route path="/:perso?/conteudos/:id_conteudo/editar/" exact component={baseDashboard(EditarConteudo)} />
                            <Route path="/:perso?/conteudos/:id_conteudo/editar-capa/" exact component={baseDashboard(EditarCapaConteudo)} />
                            <Route path="/:perso?/perfil" exact component={baseDashboard(Perfil)} />
                            <Route path="/:perso?/perfil/editar" exact component={baseDashboard(EditarPerfil)} />
                            <Route path="/:perso?/perfil/editar-senha" exact component={baseDashboard(EditarSenhaPerfil)} />
                            <Route path="/:perso?/perfil/editar-foto" exact component={baseDashboard(EditarFotoPerfil)} />
                            <Route path="/:perso?/usuarios" exact component={baseDashboard(Usuario)} />
                            <Route path="/:perso?/usuarios/ver/:id" exact component={baseDashboard(VerUsuario)} />
                            <Route path="/:perso?/usuarios/cadastrar" exact component={baseDashboard(CadastrarUsuario)} />
                            <Route path="/:perso?/usuarios/editar/:id" exact component={baseDashboard(EditarUsuario)} />
                            <Route path="/:perso?/usuarios/editar-senha/:id" exact component={baseDashboard(EditarSenhaUsuario)} />
                            <Route path="/:perso?/incubadoras/:id_incubadora/startups" exact component={baseDashboard(Startups)} />
                            <Route path="/:perso?/incubadoras/:id_incubadora/configuracoes" exact component={baseDashboard(ConfigIncubadora)} />
                            <Route path="/:perso?/startups/painel" exact component={baseDashboard(MinhaStartup)} />
                            <Route path="/:perso?/startups/cadastrar" exact component={baseDashboard(CadastrarStartup)} />
                            <Route path="/:perso?/startups/:id_startup/editar" exact component={baseDashboard(EditarStartup)} />
                            <Route path="/:perso?/startups/:id_startup/foto" exact component={baseDashboard(EditarImagemStartup)} />
                            <Route path="/:perso?/startups/:id_startup/capa" exact component={baseDashboard(EditarCapaStartup)} />
                            <Route path="/:perso?/startups/:id/acompanhamento" exact component={baseDashboard(StartupMetodologia)} />
                            <Route path="/:perso?/startups/:id_startup/metodologia" exact component={baseDashboard(VincularMetodologia)} />
                            <Route path="/:perso?/startups/:id_startup/metodologia/editar" exact component={baseDashboard(EditarMetodologia)} />
                            <Route path="/:perso?/startups/:id/incubadora/" exact component={baseDashboard(VincularIncubadora)} />
                            <Route path="/:perso?/startups/:id_startup/acompanhamento/:id_metodologia/processos/:id_processo/etapa" exact component={baseDashboard(StartupProcessos)} />
                            <Route path="/:perso?/startups/:id_startup/acompanhamento/:id_metodologia/processos/:id_processo/etapa/:id_etapa" exact component={baseDashboard(StartupEtapa)} />
                            <Route path="/:perso?/startups/:id_startup/acompanhamento/:id_metodologia/processos/:id_processo/etapa/:id_etapa/radar" exact component={baseDashboard(Radar)} />
                            <Route path="/:perso?/startups/:id_startup/acompanhamento/:id_metodologia/processos/:id_processo/etapa/:id_etapa/radar/resumo" exact component={baseDashboard(RadarResumo)} />
                            <Route path="/:perso?/startups/:id_startup/acompanhamento/:id_metodologia/processos/:id_processo/etapa/:id_etapa/entrega/:id_entrega" exact component={baseDashboard(Entrega)} />
                            <Route path="/:perso?/startups/:id_startup/acompanhamento/:id_metodologia/processos/:id_processo/etapa/:id_etapa/entrega/:id_entrega/evidencias/nova_entrega" exact component={baseDashboard(NovaEntrega)} />
                            <Route path="/:perso?/startups/:id_startup/acompanhamento/:id_metodologia/processos/:id_processo/etapa/:id_etapa/entrega/:id_entrega/evidencias" exact component={baseDashboard(Evidencia)} />
                            <Route path="/:perso?/startups/:id_startup/participantes/vincular" exact component={baseDashboard(VincularParticipantes)} />
                            <Route path="/:perso?/startups/:id_startup/participantes/cadastrar" exact component={baseDashboard(CadastrarParticipantes)} />
                            <Route path="/:perso?/startups/:id_startup/configuracoes" exact component={baseDashboard(ConfigStartup)} />
                            <Route path="/:perso?/incubadoras/painel" exact component={baseDashboard(MinhaIncubadora)} />
                            <Route path="/:perso?/startups/:id_startup/participantes/" exact component={baseDashboard(Participantes)} />
                            <Route path="/:perso?/ecossistema" exact component={baseDashboard(Ecossistema)} />
                            <Route path="/:perso?/ecossistema/incubadoras/:id_incubadora" exact component={baseDashboard(Incubadora)} />
                            <Route path="/:perso?/ecossistema/startups/:id_startup" exact component={baseDashboard(Startup)} />
                            <Route path="/:perso?/ecossistema/cadastrar" exact component={baseDashboard(CadastrarIncubadora)} />
                            <Route path="/:perso?/ecossistema/incubadora/:id_incubadora/startups/cadastrar" exact component={baseDashboard(CadastrarIncubadoraStartup)} />
                            <Route path="/:perso?/ecossistema/incubadoras/:id_incubadora/editar" exact component={baseDashboard(EditarIncubadora)} />
                            <Route path="/:perso?/ecossistema/incubadoras/:id_incubadora/foto" exact component={baseDashboard(EditarImagemIncubadora)} />
                            <Route path="/:perso?/ecossistema/incubadoras/:id_incubadora/capa" exact component={baseDashboard(EditarCapaIncubadora)} />
                            <Route path="/:perso?/ecossistema/incubadora/:id_incubadora/usuarios" exact component={baseDashboard(UsuariosIncubadora)} />
                            <Route path="/:perso?/ecossistema/incubadora/:id_incubadora/usuarios/cadastrar" exact component={baseDashboard(CadastrarUsuarioIncubadora)} />
                            <Route path="/:perso?/ecossistema/incubadora/:id_incubadora/usuarios/vincular" exact component={baseDashboard(VincularUsuarioIncubadora)} />
                            <Route path="/:perso?/gestao" exact component={baseDashboard(Gestao)} />
                            <Route path="/:perso?/trilhas" exact component={baseDashboard(Trilhas)} />
                            <Route path="/:perso?/trilhas/:id_trilha/configuracoes" exact component={baseDashboard(ConfigTrilha)} />
                            <Route path="/:perso?/trilhas/cadastrar" exact component={baseDashboard(CadastrarTrilha)} />
                            <Route path="/:perso?/trilhas/:id_trilha/descricao" exact component={baseDashboard(DescricaoTrlha)} />
                            <Route path="/:perso?/trilhas/editar/:id_trilha" exact component={baseDashboard(EditarTrilha)} />
                            <Route path="/:perso?/trilhas/editar-capa/:id_trilha" exact component={baseDashboard(EditarCapaTrilha)} />
                            <Route path="/:perso?/trilhas/:id_trilha/participantes" exact component={baseDashboard(ParticipantesCurso)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulos" exact component={baseDashboard(Modulos)} />
                            <Route path="/:perso?/trilhas/:id_trilha/autores/" exact component={baseDashboard(AutoresTrilha)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulos/editar/:id_modulo" exact component={baseDashboard(EditarModulo)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulos/cadastrar" exact component={baseDashboard(cadastrarModulo)} />
                            <Route path="/:perso?/trilhas/:id_trilha/materias/cadastrar" exact component={baseDashboard(cadastrarMaterias)} />
                            <Route path="/:perso?/trilhas/:id_trilha/atividades/cadastrar" exact component={baseDashboard(cadastrarAtividades)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulos/:id_modulo/aulas" exact component={baseDashboard(Aulas)} />
                            <Route path="/:perso?/trilhas/:id_trilha/aulas/cadastrar" exact component={baseDashboard(CadastrarAulas)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulos/:id_modulo/materias/:id_materia/aulas/:id_aula/editar" exact component={baseDashboard(EditarAula)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulos/:id_modulo/materias/:id_materia/editar" exact component={baseDashboard(EditarMateria)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulo/:id_modulo/materia/:id_materia/atividade/:id_atividade/editar" exact component={baseDashboard(EditarAtividade)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulo/:id_modulo/materia/:id_materia/atividade/:id_atividade/questoes/cadastrar" exact component={baseDashboard(CadastrarQuestoes)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulo/:id_modulo/materia/:id_materia/atividade/:id_atividade/questoes/:id_questao/editar" exact component={baseDashboard(EditarQuestoes)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulo/:id_modulo/materia/:id_materia/atividade/:id_atividade/ver" exact component={baseDashboard(VerAtividade)} />
                            <Route path="/:perso?/trilhas/:id_trilha/modulo/:id_modulo/materia/:id_materia/atividade/:id_atividade/resultado" exact component={baseDashboard(ResultadoAtividade)} />
                            <Route path="/:perso?/trilhas/:id_trilha/participantes/vincular" exact component={baseDashboard(VincularAluno)} />
                            <Route path="/:perso?/trilhas/:id_trilha/autores/:id_usuario/vincular" exact component={baseDashboard(vincularAutor)} />
                            <Route path="/:perso?/hub" exact component={baseDashboard(Hub)} />
                            <Route path="/:perso?/hub/:id_desafio/ver" exact component={baseDashboard(VerDesafio)} />
                            <Route path="/:perso?/hub/:id_desafio/ver/solucao/:id_solucao" exact component={baseDashboard(VerSolucao)} />
                            <Route path="/:perso?/hub/cadastrar" exact component={baseDashboard(CadastrarDesafio)} />
                            <Route path="/:perso?/hub/desafios/:id_desafio/editar" exact component={baseDashboard(EditarDesafio)} />
                            <Route path="/:perso?/hub/desafio/:id_desafio/solucao/:id_solucao/editar" exact component={baseDashboard(EditarSolucao)} />
                            <Route path="/:perso?/hub/desafio/:id_desafio/solucao/cadastrar" exact component={baseDashboard(CadastrarSolucao)} />
                            <Route path="/:perso?/configuracoes" exact component={baseDashboard(Configuracoes)} />
                            <Route path="/:perso?/configuracoes/startups/listar" exact component={baseDashboard(ListaStartups)} />
                            <Route path="/:perso?/configuracoes/incubadoras/listar" exact component={baseDashboard(ListaIncubadoras)} />

                            {/* METODOLOGIA */}
                            <Route path="/:perso?/configuracoes/metodologias/listar" exact component={baseDashboard(ListarMetodologias)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/vinculos" exact component={baseDashboard(ListarVinculosMetodologias)} />
                            <Route path="/:perso?/configuracoes/metodologia/cadastrar" exact component={baseDashboard(cadastrarMetodologia)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/editar" exact component={baseDashboard(editarMetodolo)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processos/listar" exact component={baseDashboard(listagemProcessos)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/editar" exact component={baseDashboard(editarProcesso)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/cadastrar" exact component={baseDashboard(cadastrarProcesso)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapas/listar" exact component={baseDashboard(listagemEtapas)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/cadastrar" exact component={baseDashboard(cadastrarEtapa)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/:id_etapa/editar" exact component={baseDashboard(editarEtapa)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/:id_etapa/estagios/listar" exact component={baseDashboard(listagemEstagios)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/:id_etapa/estagio/cadastrar" exact component={baseDashboard(cadastrarEstagio)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/:id_etapa/estagio/:id_estagio/editar" exact component={baseDashboard(editarEstagio)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/:id_etapa/estagio/:id_estagio/entregas/listar" exact component={baseDashboard(listagemEntregas)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/:id_etapa/estagio/:id_estagio/entrega/cadastrar" exact component={baseDashboard(cadastrarEntrega)} />
                            <Route path="/:perso?/configuracoes/metodologia/:id_metodologia/processo/:id_processo/etapa/:id_etapa/estagio/:id_estagio/entrega/:id_entrega/editar" exact component={baseDashboard(editarEntrega)} />

                            <Route path="/:perso?/configuracoes/trilhas/listar" exact component={baseDashboard(ListaTrilhas)} />
                            <Route path="/:perso?/configuracoes/personalizacao" exact component={baseDashboard(personalizacao)} />
                            <Route path="/:perso?/configuracoes/personalizacao/nova" exact component={baseDashboard(novaPersonalizacao)} />
                            <Route path="/:perso?/perfis" exact component={baseDashboard(Perfis)} />
                            <Route path="/:perso?/perfil/cadastrar" exact component={baseDashboard(CadastrarPerfil)} />
                            <Route path="/:perso?/perfil/:id_perfil/editar" exact component={baseDashboard(EditarPerfilAcesso)} />
                            <Route path="/:perso?/perfil/:id_perfil/credenciais/editar" exact component={baseDashboard(EditarCredenciais)} />
                            <Route path="/:perso?/relatorios" exact component={baseDashboard(Relatorios)} />
                            <Route path="/:perso?/chat" exact component={baseDashboard(Chat)} />
                            <Route path="/:perso?/chat/:id_usuario2" exact component={baseDashboard(Chat)} />
                            <Route path="/:perso?/beneficios" exact component={baseDashboard(Benificios)} />
                            <Route path="/:perso?/termos" exact component={permissaoDashboard(Permissao)} />
                            <Route path="/:perso?/politica" exact component={permissaoDashboard(Politica)} />
                            <Route path="/:perso?/hub/avaliadores/:id_desafio" exact component={baseDashboard(Avaliadores)} />
                            <Route path="/:perso?/hub/avaliador/:id_desafio/vincular" exact component={baseDashboard(VincularAvaliador)} />
                            <Route path="/:perso?/hub/avaliador/:id_desafio/cadastrar" exact component={baseDashboard(CadastrarAvaliador)} />
                            <Route path="/:perso?/hub/avaliar/:id_desafio/solucao/:id_solucao" exact component={baseDashboard(Avaliar)} />
                            <Route path="/:perso?/hub/avaliar/:id_desafio/solucao/:id_solucao/editar/:id_avaliacao" exact component={baseDashboard(EditarAvaliacao)} />
                            <Route path="/:perso?/radar" exact component={baseDashboard(Radares)} />
                            <Route path="/:perso?/radar/cadastrar" exact component={baseDashboard(cadastrarRadar)} />
                            <Route path="/:perso?/radar/:id_radar/editar" exact component={baseDashboard(editarRadar)} />

                            <Route path="/:perso?/radar/:id_radar/vinculos" exact component={baseDashboard(ListagemVinculosRadar)} />
                            <Route path="/:perso?/radar/:id_radar/pilares" exact component={baseDashboard(Pilares)} />
                            <Route path="/:perso?/radar/:id_radar/pilar/:id_pilar/perguntas" exact component={baseDashboard(Perguntas)} />
                            <Route path="/:perso?/radar/:id_radar/pilar/:id_pilar/pergunta/cadastrar" exact component={baseDashboard(CadastrarPergunta)} />
                            <Route path="/:perso?/radar/:id_radar/pilar/:id_pilar/pergunta/:id_pergunta/opcoes" exact component={baseDashboard(Opcoes)} />
                            <Route path="/:perso?/radar/:id_radar/pilar/:id_pilar/pergunta/:id_pergunta/editar" exact component={baseDashboard(EditarPergunta)} />
                            <Route path="/:perso?/personalizacao/:id_personalizacao/editar" exact component={baseDashboard(EditarPersonalizacao)} />
                            <Route path="/:perso?/personalizacao/:id_personalizacao/editar/vincular" exact component={baseDashboard(criarVinculoPersonalizacao)} />
                        </Switch>
                    </PersistGate>
                </BrowserRouter>
            </Router>
        </Provider>
    )
}