import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/radares';
import { Link, Redirect } from 'react-router-dom';
import Botao from '../../../componentes/Botoes';
import AlertaErro from '../../../componentes/Alertas/AlertaErro';
import { Input, FormGroup, Label, Form } from 'reactstrap';
import Titulo from '../../../componentes/TituloPaginas';

import { english, portuguese } from '../../../utils/msgsBackend/backend_messages';
import { portugues, ingles } from '../../../utils/paginaradar_bilingue';
import Toast from '../../../componentes/Alertas/Toast';

class EditarRadar extends Component {
  state = {
    nome: '',
    peso_maximo: '',
    formSuccess: false,
    erro: "",
    success: "",
    carregando: false,
    msg_backend: portuguese,
    idioma: portugues,
    dadosApi: false
  }
  componentDidMount() {

    const { usuario } = this.props;
    const { id_radar } = this.props.match.params

    this.listarRadar(id_radar);

    if (usuario.idioma.id === 3) {
      this.setState({ idioma: ingles })
      this.setState({ msg_backend: english })
    } else {
      this.setState({ idioma: portugues })
      this.setState({ msg_backend: portuguese })
    }
  }

  componentDidUpdate(nextProps) {
    const { id_radar } = this.props.match.params

    if (!this.props.radar && nextProps.radar) this.listarRadar(id_radar);
    this.receberDadosAPI();
  }

  receberDadosAPI() {
    if (typeof this.props.radar != "undefined" && this.props.radar != null && !this.state.dadosApi) {
      this.setState({ nome: this.props.radar.dados.nome });
      this.setState({ peso_maximo: this.props.radar.dados.peso_maximo });
      this.setState({ dadosApi: true });
    }

  }

  validate() {
    const { nome, idioma, peso_maximo } = this.state;
    if (!nome) return this.setState({ erro: { message: idioma.cadastrarRadar.nome_placeholder } });
    if (!peso_maximo) return this.setState({ erro: { message: idioma.cadastrarRadar.nota_maxima_placeholder } });
    return true;
  }

  onChangeInput(campo, ev) {
    this.setState({ [campo]: ev.target.value })
  }

  handleEditarRadar() {
    const { msg_backend, idioma, nome, peso_maximo } = this.state;
    const { id_radar } = this.props.match.params
    if (!this.validate()) return;
    this.setState({ carregando: true })
    this.props.putRadar({ id_radarmetodologia: id_radar, nome, peso_maximo }, (msg) => {
      if (msg.erro.error) {
        Toast({ icone: 'error', titulo: idioma.erro, texto: msg_backend[msg.erro.code] })
        this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ carregando: false });
      } else {
        Toast({ icone: 'success', titulo: idioma.sucesso, texto: msg_backend[msg.erro.code] })
        this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
        this.setState({ carregando: false });
        this.setState({ formSuccess: true });
      }
    })
  }

  listarRadar(id_radar) {
    this.props.getRadar(id_radar)
    const { radar } = this.props;
    if (radar === "undefined") return null
  }

  render() {
    const { carregando, erro, formSuccess, idioma, nome } = this.state;

    const pesoMaximoOpcoes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    if (formSuccess) return <Redirect to={{
      pathname: `/${this.props.usuario?.personalizacao?.titulo}/radar`,
      state: { msg: idioma.editarRadar.msg_sucesso }
    }}></Redirect>

    return (
      <>
        <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
          <Titulo titulo={idioma.editarRadar.titulo} />
        </div>
        <div className="container-fluid bloco-topo" >
          <div className="col-md-12 mb-2">
            <div className="list-group-item" style={{ backgroundColor: this.props.usuario?.personalizacao && this.props.usuario.personalizacao.cor_quaternaria }}>
              <div>
                {erro && <AlertaErro erros={erro} />}
                <Form>
                  <FormGroup>
                    <Label for="nome">{idioma.cadastrarRadar.nome}</Label>
                    <Input type="text" onChange={(e) => this.onChangeInput('nome', e)} value={nome} autoComplete="nome" required name="nome" id="nome" placeholder={idioma.cadastrarRadar.nome_placeholder} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="peso_maximo">{idioma.cadastrarRadar.nota_maxima}</Label>
                    <Input type="select" onChange={(ev) => this.onChangeInput("peso_maximo", ev)} id="peso_maximo" name="peso_maximo" >
                      <option>{idioma.cadastrarRadar.nota_maxima_placeholder}</option>
                      {this.state.dadosApi && pesoMaximoOpcoes.map((peso) => (
                        <option key={peso} value={String(peso)} selected={String(peso) === String(this.props.radar.dados.peso_maximo)}>{peso}</option>
                      ))}
                    </Input>
                  </FormGroup>
                  <Link to="#" onClick={() => this.handleEditarRadar()}> <Botao loading={carregando} texto={idioma.editarRadar.bt_salvar} cor='success' /> </Link>
                  <Link to="#" onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} cor='danger' /> </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStatetoProps = state => ({
  radar: state.radares.radar
})
export default connect(mapStatetoProps, actions)(EditarRadar);