import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Row, Col, Card, CardBody, UncontrolledCollapse } from 'reactstrap';
import Botao from '../../componentes/Botoes';
import BlocoTrilha from '../../containers/blocos/perfilTrilha'
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import urlParser from "js-video-url-parser";
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { apiAula } from '../../config';
import Titulo from '../../componentes/TituloPaginas';

import { ingles, portugues } from '../../utils/paginatrilhas_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

class EditarAula extends Component {
    state = {
        nome: "",
        duracao: "",
        id_materia: "",
        id_aula: "",
        id_trilha: "",
        id_curso: "",
        linkYT: "",
        linkPodCast: "",
        data_publicacao: "",
        tipo: "",
        file: null,
        ordem: "",
        descricao: "",
        conteudo: "",
        erro: "",
        success: "",
        carregando: false,
        dadosApi: false,
        formSuccess: false,
        editorState: "",
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha, id_modulo, id_materia, id_aula } = this.props.match.params;
        this.setState({ id_aula })
        this.setState({ id_trilha })
        this.setState({ id_materia })
        this.setState({ id_modulo })
        this.listarAula(id_aula)
        this.listarTrilha(id_trilha)
        this.listarMateria(id_trilha, id_modulo)
        this.listarTipo()

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    async componentDidUpdate(nextProps) {
        const { id_trilha, id_modulo, id_materia, id_aula } = this.props.match.params;
        if (!this.props.aula && nextProps.aula) {
            this.listarAula(id_aula);
            this.listarTrilha(id_trilha)
        }
        if (!this.props.tipoAula && nextProps.tipoAula) {
            this.listarTipo();
        }
        if (!this.props.materias && nextProps.materias) {
            this.listarMateria(id_trilha, id_modulo);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparAula()
    }


    onEditorStateChangeInicial = (conteudo) => {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(conteudo)
                )

            ),
        })
    }

    receberDadosAPI() {
        const { id_aula } = this.props.match.params;
        if (typeof this.props.aula !== "undefined" && this.props.aula !== null && this.props.aula.id == id_aula &&
            typeof this.props.tipoAula != "undefined" && this.props.tipoAula != null &&
            typeof this.props.materias != "undefined" && this.props.materias != null && !this.state.dadosApi) {
            this.setState({ id_aula: this.props.aula.id })
            this.setState({ nome: this.props.aula.nome })
            this.setState({ ordem: this.props.aula.ordem })
            this.setState({ conteudo: this.props.aula.conteudo })
            this.setState({ tipo: this.props.aula.id_tipo })
            this.setState({ link: this.props.aula.link })
            this.setState({ duracao: this.props.aula.duracao })
            this.setState({ data_publicacao: this.props.aula.data_publicacao })
            if (this.props.aula.conteudo != null) {
                this.onEditorStateChangeInicial(this.props.aula.conteudo)
            }
            this.setState({ conteudo: this.props.aula.conteudo })
            this.setState({ dadosApi: true });

        }

    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile = (field, ev) => {
        this.setState({ [field]: ev.target.files[0] });
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    async atualizarAulaVideo(id_aula, id_materia, nome, ordem, tipo, linkYT, duracao, data_publicacao) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosFormVideo();
        var id = id_aula
        if (linkYT.includes("youtube")) {
            var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id
        } else if (linkYT.includes("youtu.be")) {
            var link = "https://www.youtube.com/embed/" + urlParser.parse(linkYT).id

        }
        // if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putAula({ id, id_materia, nome, ordem, tipo, link, duracao, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    async atualizarAulaPodCast(id_aula, id_materia, nome, ordem, tipo, linkPodCast, duracao, data_publicacao) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosFormVideo();
        var id = id_aula
        var link = linkPodCast

        // if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putAula({ id, id_materia, nome, ordem, tipo, link, duracao, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    async atualizarAulaTexto(id_aula, id_materia, nome, ordem, tipo, editorState, duracao, data_publicacao) {
        this.setState({ success: "" });
        this.setState({ erro: "" });

        await this.receberDadosFormTexto();
        var id = id_aula
        var conteudo = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        // if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putAula({ id, id_materia, nome, ordem, tipo, conteudo, duracao, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    async atualizarAulaPDF(id_aula, id_materia, nome, ordem, tipo, duracao, data_publicacao) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        const { file } = this.state
        await this.receberDadosFormPDF();
        // if (!this.validate()) return;
        var id = id_aula
        var url = apiAula
        const formData = new FormData();
        formData.append('nome', nome)
        formData.append('ordem', ordem)
        formData.append('id_materia', id_materia)
        formData.append('id', id)
        formData.append('duracao', duracao)
        formData.append('url', url)
        formData.append('tipo', tipo)
        formData.append('data_publicacao', data_publicacao)
        formData.append('file', file);

        this.setState({ carregando: true })
        this.props.putAula(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    async atualizarAulaOutro(id_aula, id_materia, nome, ordem, tipo, link, duracao, data_publicacao) {
        this.setState({ success: "" });
        this.setState({ erro: "" });
        await this.receberDadosFormVideo();
        var id = id_aula
        // if (!this.validate()) return;
        this.setState({ carregando: true })
        this.props.putAula({ id, id_materia, nome, ordem, tipo, link, duracao, data_publicacao }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }

    listarMateria(id_trilha, id_modulo) {
        ////////////console.log(id_modulo)
        this.props.getMaterias(id_trilha, id_modulo)
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (id_trilha === "undefined") return null
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listarTipo() {
        this.props.getTipoAula()
        if (this.props.location.state) {
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
    }

    listarAula(id_aula) {
        this.props.getVerAula(id_aula);
        const { aula } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (aula === "undefined") return null
    }

    receberDadosFormVideo() {
        this.setState({ id_aula: document.querySelector("#id_aula").value })
        this.setState({ id_materia: document.querySelector("#id_materia").value })
        this.setState({ tipo: document.querySelector("#tipo").value })
        this.setState({ link: document.querySelector("#link").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ ordem: document.querySelector("#ordem").value })
    }
    receberDadosFormTexto() {
        this.setState({ id_aula: document.querySelector("#id_aula").value })
        this.setState({ id_materia: document.querySelector("#id_materia").value })
        this.setState({ tipo: document.querySelector("#tipo").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ ordem: document.querySelector("#ordem").value })
    }
    receberDadosFormPDF() {
        this.setState({ id_aula: document.querySelector("#id_aula").value })
        this.setState({ id_materia: document.querySelector("#id_materia").value })
        this.setState({ tipo: document.querySelector("#tipo").value })
        this.setState({ file: document.querySelector("#file").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ ordem: document.querySelector("#ordem").value })
    }

    receberDadosFormVideo() {
        this.setState({ id_aula: document.querySelector("#id_aula").value })
        this.setState({ id_materia: document.querySelector("#id_materia").value })
        this.setState({ tipo: document.querySelector("#tipo").value })
        this.setState({ link: document.querySelector("#link").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ ordem: document.querySelector("#ordem").value })
    }
    // validate() {
    //     const { nome, ordem, descricao, erro } = this.state;
    //     if (!nome) return this.setState({ erro: { message: "Preencha o nome" } });
    //     if (!ordem) return this.setState({ erro: { message: "Preencha uma ordem" } });
    //     if (!descricao) return this.setState({ erro: { message: "Preencha uma descrição" } });
    //     return true;
    // }


    deletandoAula(id_aula) {
        this.setState({ carregando: true })
        this.props.deletarAula(id_aula, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }

    chamarAlerta(id_aula) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.editarAula.modal_titulo,
            html: idioma.editarAula.modal_info,
            input: 'text',
            inputPlaceholder: idioma.editarAtividade.modal_confirm,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            //////console.log(texto)
            if (texto.value == idioma.editarAtividade.confirm) {
                this.deletandoAula(id_aula);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.editarAtividade.modal_erro
                })
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.editarAula.modal_sucesso
        })
    }


    render() {
        const { id_materia, id_trilha, id_aula, id_modulo, tipo, link, data_publicacao, linkPodCast, nome, editorState, ordem, file, descricao, conteudo, idioma, duracao, carregando, dadosApi, erro, success, formSuccess } = this.state;
        ////////////console.log(this.props)
        var materias = [];
        if (this.props.materias) materias = this.props.materias.dados;

        var aula = [];
        if (this.props.aula) aula = this.props.aula;

        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;

        var tipoAula = [];
        if (this.props.tipoAula) tipoAula = this.props.tipoAula.dados;

        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        //console.log(aula, materias, tipoAula)
        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/trilhas/" + id_trilha + "/modulos/" + id_modulo + "/aulas/",
            state: { msg: idioma.editarAula.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.editarAula.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoTrilha dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton rectangle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-8 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <Form>
                                <Row form>
                                    <Col md={8}>
                                        <FormGroup>
                                            <Label for="nome">{idioma.editarAtividade.nome}</Label>
                                            <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosApi ? idioma.editarAula.nome_aula : idioma.editarAtividade.carregando} disabled={dadosApi ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <Label for="Ordem">{idioma.editarAtividade.sel_ordem}</Label>
                                        <Input type="number" value={ordem} id="ordem" name="ordem" className="form-control" autoComplete="ordem" placeholder={dadosApi ? idioma.editarAula.ordem_aula : idioma.editarAtividade.carregando} disabled={dadosApi ? false : true} onChange={(ev) => this.onChangeInput("ordem", ev)} />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="duracao">{idioma.editarAula.duracao_aula}</Label>
                                            <Input type="number" value={duracao} id="duracao" name="duracao" className="form-control" autoComplete="duracao" placeholder={dadosApi ? idioma.editarAula.ordem_aula : idioma.editarAtividade.carregando} disabled={dadosApi ? false : true} onChange={(ev) => this.onChangeInput("duracao", ev)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <Label for="materia">{idioma.editarAula.materia}</Label>
                                        <Input type="select" id="id_materia" name="id_materia" className="form-control" autoComplete="id_materia" placeholder={dadosApi ? idioma.editarAula.materia : idioma.editarAtividade.carregando} disabled={dadosApi ? false : true} onChange={(ev) => this.onChangeInput("id_materia", ev)} >
                                            <option>{idioma.editarAula.sel_materia}</option>
                                            {materias.map(materia => (
                                                <option value={materia.materia.id} key={materia.materia.id} selected={materia.materia.id == aula.id_materia ? true : false}>
                                                    {materia.materia.nome}
                                                </option>))}
                                        </Input>
                                    </Col>
                                    <Col md={3}>
                                        <Label for="tipo">{idioma.editarAula.tipo_aula}</Label>
                                        <Input type="select" onChange={(ev) => this.onChangeInput("tipo", ev)} id="tipo" name="tipo" >
                                            <option>{idioma.editarAula.sel_tipo}</option>
                                            {tipoAula.map(tipo => (tipo.id != 5) &&
                                                <option value={tipo.id} key={tipo.id} selected={tipo.id == aula.id_tipo ? true : false}>
                                                    {tipo.nome}
                                                </option>
                                            )}
                                        </Input>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label for="data_publicacao">{idioma.editarAtividade.data_pub}</Label>
                                            <Input type="date" value={data_publicacao} id="data_publicacao" name="data_publicacao" className="form-control" autoComplete="data_publicacao" placeholder={dadosApi ? idioma.editarAtividade.data_pub : idioma.editarAtividade.carregando} disabled={dadosApi ? false : true} onChange={(ev) => this.onChangeInput("data_publicacao", ev)} />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <FormGroup>
                                    {tipo == 2 ?
                                        <div>
                                            <Label for="link">{idioma.editarAula.link}</Label>
                                            <Input type="text" value={link} autoComplete="link" onChange={(ev) => this.onChangeInput("link", ev)} name="link" id="link" placeholder={idioma.editarAula.link} />
                                            <Row>
                                                <Input type="hidden" value={id_aula} id="id_aula" name="id_aula" />
                                            </Row>
                                        </div>
                                        : tipo == 3 ?
                                            <div>
                                                <Label for="conteudo">{idioma.editarAula.conteudo}</Label>
                                                <Card>
                                                    <div>
                                                        <Editor
                                                            editorState={editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={this.onEditorStateChange}
                                                        />
                                                    </div>
                                                </Card>
                                                <Row>
                                                    <Input type="hidden" value={id_aula} id="id_aula" name="id_aula" />
                                                </Row>
                                            </div>
                                            : tipo == 1 ?
                                                <div>
                                                    <Label for="file">{idioma.editarAula.doc_aula}</Label>
                                                    <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                                    <Row>
                                                        <Input type="hidden" value={id_aula} id="id_aula" name="id_aula" />
                                                    </Row>
                                                </div>
                                                : tipo == 4 ?
                                                    <div>
                                                        <Label for="link">{idioma.editarAula.doc_aula}</Label>
                                                        <Input type="text" value={link} autoComplete="link" onChange={(ev) => this.onChangeInput("link", ev)} name="link" id="link" placeholder={idioma.editarAula.link2} />
                                                        <Row>
                                                            <Input type="hidden" value={id_aula} id="id_aula" name="id_aula" />
                                                        </Row>
                                                    </div>
                                                    : ""}
                                </FormGroup>
                                <Link onClick={() => {
                                    tipo == 2 ?
                                        this.atualizarAulaVideo(id_aula, id_materia, nome, ordem, tipo, link, duracao, data_publicacao)
                                        : tipo == 3 ?
                                            this.atualizarAulaTexto(id_aula, id_materia, nome, ordem, tipo, editorState, duracao, data_publicacao)
                                            : tipo == 1 ?
                                                this.atualizarAulaPDF(id_aula, id_materia, nome, ordem, tipo, duracao, data_publicacao)
                                                : tipo == 4 ?
                                                    this.atualizarAulaPodCast(id_aula, id_materia, nome, ordem, tipo, link, duracao, data_publicacao)
                                                    : tipo == 5 &&
                                                    this.atualizarAulaOutro(id_aula, id_materia, nome, ordem, tipo, link, duracao, data_publicacao)
                                }} to="#">
                                    <Botao loading={carregando} texto="Salvar" classes="btn-secondary btn-md ml-1 mt-3" />
                                </Link>
                                <Link onClick={() => this.props.history.goBack()}> <Botao texto="Cancelar" classes="botao-responsivo mt-3" cor="danger" /> </Link>
                                {credenciais.map(credencial => ((credencial.id_componente == 27) ?

                                    <Link to="#" onClick={() => this.chamarAlerta(id_aula)} className="mr-2">
                                        <Botao loading={carregando} texto="Deletar aula" classes="btn-danger btn-md ml-1 mt-3" icone="trash-alt" />
                                    </Link>

                                    : ""))}


                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    aula: state.trilhas.aula,
    materias: state.trilhas.materias,
    tipoAula: state.trilhas.tipoAula,
    trilha: state.trilhas.trilha

})
export default connect(mapStateToProps, actions)(EditarAula)