import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/hub';
import { Link, Redirect } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import minhasStartups from '../../paginas/Startup/minhasStartups';
import { min } from 'date-fns';
import { format, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import ReactTooltip from "react-tooltip";

import { portugues, ingles } from '../../utils/paginainovacao_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

class PerfilDesafio extends Component {
    state = {
        id_usuario: "",
        dadosAPI: false,
        path: "",
        desafio: "",
        usuario: "",
        currentPage: 1,
        size: 20,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {

        // if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }
        // this.listarTrilha();

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }

    }
    // componentDidUpdate(nextProps) {
    //     if (!this.props.trilha && nextProps.trilha) this.listarTrilha();
    //     this.receberDadosAPI();
    // }

    // receberDadosAPI() {
    //     if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi && this.props.trilha.currentPage === this.state.currentPage) {
    //         this.setState({ dadosApi: true });
    //     }
    // }

    // listarTrilha(id_trilha) {
    //     this.props.getVerTrilha(id_trilha);
    //     const { trilha } = this.props;
    //     if (this.props.location.state) {

    //         this.setState({ msg: this.props.location.state.msg })
    //         this.props.location.state.msg = "";
    //     }
    //     if (trilha === "undefined") return null
    // }



    //deletar incubadora
    deletandoDesafio(id_desafio) {
        this.setState({ carregando: true })
        this.props.deletarDesafio(id_desafio, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_desafio) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.pagina_ver.modal_titulo,
            html:
                idioma.pagina_ver.modal_info1
                + '</br>' + idioma.pagina_ver.modal_info2 + ' <strong> ' + idioma.pagina_ver.modal_info3 + ' </strong> ' + idioma.pagina_ver.modal_info4,
            input: 'text',
            inputPlaceholder: idioma.pagina_ver.modal_info,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.pagina_ver.bt_deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value == idioma.pagina_ver.modal_info3) {
                this.deletandoDesafio(id_desafio);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.pagina_ver.erro_delete
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.pagina_ver.sucesso_delete
        })
    }

    //enviar email
    chamarEnviarEmail(id_desafio, id_usuario) {
        const { idioma } = this.state;

        Swal.fire({
            title: idioma.pagina_ver.modal_email,
            html:
                idioma.pagina_ver.modal_email_info,



            confirmButtonColor: '#3085d6',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: idioma.pagina_ver.bt_enviar,
            showLoaderOnConfirm: true,

        }).then((result) => {
            if (result.isConfirmed) {
                this.enviarEmail(id_desafio, id_usuario)
            }
        })
    }

    enviarEmail(id_desafio, id_usuario) {
        const { idioma } = this.state
        this.setState({ carregando: true })
        this.props.postEnviarDesafio({ id_desafio, id_usuario }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                Swal.fire({
                    icon: 'success',
                    title: idioma.pagina_ver.modal_email_sucesso

                })
            }
        }
        )
    }


    render() {
        let { usuario, desafio, dadosApi, path, formSuccess, idioma } = this.state;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        if (this.props.usuario) usuario = this.props.usuario;

        if (this.props.desafio) desafio = this.props.desafio;
        if (this.props.dadosApi) dadosApi = this.props.dadosApi;

        if (formSuccess) return <Redirect to={{
            pathname: "/" + usuario?.personalizacao?.titulo + "/hub",
        }}></Redirect>
        ////console.log(desafio)
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="list-group-item" >
                        {
                            dadosApi ?
                                <div>
                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <h2 className="">{desafio ? desafio.titulo : ""}</h2>
                                        </div>
                                        <div className="ml-4">
                                            <span className="text-muted mr-1">{idioma.pagina_ver.empresa}: </span>
                                            <span className="text-perfil ">{desafio.empresa}, </span>
                                            <span className="text-muted mr-1">{idioma.pagina_ver.responsavel}: </span>
                                            <span className="text-perfil"> {desafio.responsavel} {desafio.cargo != "" ? - desafio.cargo : ""}</span>
                                        </div>
                                    </div>
                                </div>
                                : <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className=""><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>}
                    </div>
                </div>
                {dadosApi ?
                    usuario.id == desafio.id_autor ?
                        <div className="col-md-12 mb-3">
                            <div className="list-group-item" >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className={path == "/hub" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/hub"}>                                                        <span className="text-perfil">
                                                        <FontAwesomeIcon icon="arrow-left" size="1x" color="black" className="mr-3" />
                                                        {idioma.pagina_ver.bt_voltar}
                                                    </span>
                                                    </Link>
                                                </div>
                                                <div className={path == "/hub/avaliadores/:id_desafio" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/avaliadores/" + desafio.id}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="chalkboard-teacher" size="1x" color="black" className="mr-3" />
                                                            {idioma.pagina_ver.bt_avaliadores}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className={path == "/hub/desafios/:id_desafio/editar" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/desafios/" + desafio.id + "/editar"}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                            {idioma.pagina_ver.bt_editar}
                                                        </span>
                                                    </Link>
                                                </div>
                                                <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                    {
                                                        desafio.id_autor == usuario.id ?
                                                            <a data-tip data-for='enviar'>
                                                                <Link to="#" onClick={() => this.chamarEnviarEmail(desafio.id, usuario.id)}>
                                                                    <span className="text-perfil">
                                                                        <FontAwesomeIcon icon="paper-plane" size="1x" color="black" className="mr-3" />
                                                                        {idioma.pagina_ver.bt_enviar}
                                                                    </span>
                                                                </Link>
                                                            </a>
                                                            : credenciais.length > 0 &&
                                                            (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) &&
                                                            <a data-tip data-for='enviar'>
                                                                <Link to="#" onClick={() => this.chamarEnviarEmail(desafio.id, usuario.id)}>
                                                                    <span className="text-perfil">
                                                                        <FontAwesomeIcon icon="paper-plane" size="1x" color="black" className="mr-3" />
                                                                        {idioma.pagina_ver.bt_enviar}
                                                                    </span>

                                                                </Link>
                                                            </a>

                                                    }
                                                    <ReactTooltip id='enviar' type='dark'>
                                                        <span>{idioma.pagina_ver.info_enviaremail}</span>
                                                    </ReactTooltip>
                                                </div>
                                                <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                    <Link to="#" onClick={() => this.chamarAlertaDeletar(desafio.id)}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="trash-alt" size="1x" color="red" className="mr-3" />
                                                            {idioma.pagina_ver.bt_deletar}
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div >
                                        </div>
                                    </div >

                                </div >
                            </div>
                        </div>

                        :
                        credenciais.length > 0 ?
                            (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) ?
                                <div className="col-md-12 mb-3">
                                    <div className="list-group-item" >

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        <div className={path == "/hub" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/hub"}>                                                        <span className="text-perfil">
                                                                <FontAwesomeIcon icon="arrow-left" size="1x" color="black" className="mr-3" />
                                                                {idioma.pagina_ver.bt_voltar}
                                                            </span>
                                                            </Link>
                                                        </div>
                                                        {/* {credenciais.map(credencial => ((credencial.id_componente == 70) ? */}

                                                        <div className={path == "/hub/avaliadores/:id_desafio" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/avaliadores/" + desafio.id}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="chalkboard-teacher" size="1x" color="black" className="mr-3" />
                                                                    {idioma.pagina_ver.bt_avaliadores}
                                                                </span>
                                                            </Link>
                                                        </div>
                                                        {/* : ""))} */}
                                                        {credenciais.map(credencial => ((credencial.id_componente == 70) ?

                                                            <div className={path == "/hub/desafios/:id_desafio/editar" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/hub/desafios/" + desafio.id + "/editar"}>
                                                                    <span className="text-perfil">
                                                                        <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                                        {idioma.pagina_ver.bt_editar}
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                            : ""))}
                                                        < div className="col-auto menu-cabecalho">
                                                            {credenciais.map(credencial => ((credencial.id_componente == 71) ?

                                                                <Link to="#" onClick={() => this.chamarAlertaDeletar(desafio.id)}>
                                                                    <span className="text-perfil">
                                                                        <FontAwesomeIcon icon="trash-alt" size="1x" color="red" className="mr-3" />
                                                                        {idioma.pagina_ver.bt_deletar}
                                                                    </span>
                                                                </Link>
                                                                : ""))}
                                                        </div>
                                                    </div >
                                                </div>
                                            </div >

                                        </div >
                                    </div>
                                </div>
                                : ""
                            : ""
                    : <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <div className="row">
                                    < div className="col-auto menu-cabecalho">
                                        <Skeleton count={1} width={80} />
                                    </div>
                                    <div className="col-auto menu-cabecalho active">
                                        <Skeleton count={1} width={80} />
                                    </div>
                                    <div className="col-auto menu-cabecalho active">
                                        <Skeleton count={1} width={80} />
                                    </div>
                                    <div className="col-auto menu-cabecalho" >
                                        <Skeleton count={1} width={80} />
                                    </div>
                                </div >
                            </div >
                        </div >

                    </div >
                }
            </>
        )
    }
}
const mapStatetoProps = state => ({
    desafio: state.hub.desafio,


})
export default connect(mapStatetoProps, actions)(PerfilDesafio);