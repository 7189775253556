import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Link } from 'react-router-dom';
import { Card, CardBody, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import minhasStartups from '../../paginas/Startup/minhasStartups';
import { min } from 'date-fns';
import { apiImagem } from '../../config/';

import { ingles, portugues } from '../../utils/blocoperfil_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class Perfil extends Component {
    state = {
        id_usuario: "",
        dadosApi: false,
        pertence: false,
        path: "",
        startup: "",
        usuario: "",
        usuarioDetalhes: "",
        currentPage: 1,
        size: 20,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        if (this.props.startup && this.props.usuario && this.props.credenciais) {
            this.verificaStartup(this.props.usuario, this.props.startup, this.props.credenciais)
        };

        const { usuario } = this.props;

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        if (!this.props.startup && nextProps.startup) {
            this.verificaStartup(this.props.usuario, this.props.startup, this.props.credenciais)
        }
        this.receberDadosAPI();
    }

    receberDadosAPI() {
        if (typeof this.props.credenciais != "undefined" && this.props.credenciais != null &&
            typeof this.props.usuario != "undefined" && this.props.usuario != null &&
            !this.state.dadosApi && typeof this.props.startup != "undefined" && this.props.startup != null) {
            this.setState({ dadosApi: true });
        }
    }
    desativandoStartup(id_startup) {
        this.setState({ carregando: true })
        this.props.desativarStartup(id_startup, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }

    chamarAlerta(id_startup) {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.bt_salvar
        }).then((result) => {
            if (result.value) {
                this.desativandoStartup(id_startup);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state;
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.modal_sucesso
        })
    }
    verificaStartup(usuario, startup, credenciais) {
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }
        for (let i in usuario.incubadoras) {
            if (usuario.incubadoras[i].id_incubadora == startup.id_incubadora) {
                this.setState({ pertence: true })
            }
        }
        for (let s in usuario.startups) {
            if (usuario.startups[s].id_startup == startup.id) {
                this.setState({ pertence: true })
            }
        }
    }
    render() {
        let { usuario, usuarioDetalhes, startup, dadosApi, path, pertence, idioma } = this.state;
        var credenciais = [];
        if (this.props.credenciais) {
            credenciais = this.props.credenciais;
        }
        if (this.props.usuario) usuario = this.props.usuario;
        if (this.props.usuarioDetalhes) usuarioDetalhes = this.props.usuarioDetalhes;
        if (this.props.startup) startup = this.props.startup;
        if (this.props.dadosApi) dadosApi = this.props.dadosApi;
        if (this.props.path) path = this.props.path;

        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                        {dadosApi ?
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <img className="rounded-circle mt-2" src={startup.fileName ? apiImagem + startup.fileName : apiImagem + "astronaut.png"} width="120" height="120" ></img>
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil">{startup ? startup.nome : ""}</h2>
                                        <p className="text-muted">{startup ? startup.segmento : ""}</p>
                                    </div>
                                </div>
                            </div>
                            : <div className="row">
                                <div className="col-auto centralizado" >
                                    <div className="img-perfil">
                                        <Skeleton circle={true} height={120} width={120} />
                                    </div>
                                </div>
                                <div className="col-md-9 centralizado texto-centralizado">
                                    <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                    <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                </div>
                            </div>}
                    </div>
                </div>
                {dadosApi ?
                    <div>
                        <div className="col-md-12 mb-3">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-fluid">
                                            <div className="row">
                                                < div className={path == "/ecossistema/startups/:id_startup" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/startups/" + startup.id}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="id-card" size="1x" color="black" className="mr-3" />
                                                            {idioma.perfil}
                                                        </span>
                                                    </Link>
                                                </div>
                                                < div className={path == "/startups/:id_startup/participantes/" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/participantes"}>
                                                        <span className="text-perfil">
                                                            <FontAwesomeIcon icon="user-astronaut" size="1x" color="black" className="mr-3" />
                                                            {idioma.participantes}
                                                        </span>
                                                    </Link>
                                                </div>

                                                {pertence ?
                                                    <>
                                                        < div className={path == "/startups/:id/acompanhamento/" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/acompanhamento/"}>
                                                                <span className="text-perfil">
                                                                    <FontAwesomeIcon icon="space-shuttle" size="1x" color="black" className="mr-3" />
                                                                    {idioma.evolucao}
                                                                </span>
                                                            </Link>
                                                        </div>

                                                        {credenciais.map(credencial => ((credencial.id_componente == 53) ?
                                                            < div className={path == "/startups/:id_startup/configuracoes" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/startups/" + startup.id + "/configuracoes"}>
                                                                    <span className="text-perfil">
                                                                        <FontAwesomeIcon icon="cogs" size="1x" color="black" className="mr-3" />
                                                                        {idioma.config}
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                            : ""))}
                                                    </>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="container-fluid">
                                        <div className="row">
                                            < div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                            <div className="col-auto menu-cabecalho texto-centralizado botao-responsivo" >
                                                <Skeleton count={1} width={80} />
                                            </div>
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                }
            </>
        )
    }
}
const mapStatetoProps = state => ({
    minhasStartups: state.startups.minhasStartups,
    usuarios: state.startups.usuarios,
    usuarioDetalhes: state.startups.usuarioDetalhes,


})
export default connect(mapStatetoProps, actions)(Perfil);