import {
    GET_STARTUPS, GET_STARTUP, GET_METODOLOGIAS_STARTUP,
    GET_ETAPAS_STARTUP, GET_ESTAGIOS_STARTUP, GET_PARTICIPANTES,
    GET_PARTICIPANTE_STARTUP, GET_METODOLOGIAS, GET_PERGUNTAS_RADAR, GET_RESPOSTAS_RADAR, GET_PILARES_RADAR, GET_MINHAS_STARTUPS,
    GET_EVIDENCIA, GET_ENTREGA, LIMPAR_PARTICIPANTE, LIMPAR_PARTICIPANTES,
    LIMPAR_STARTUPS, LIMPAR_STARTUP, GET_FUTUROS_PARTICIPANTES, GET_SEARCH_STARTUP, GET_MINHAS_INCUBADORAS_2, LIMPAR_STARTUP_METODOLOGIA,
    GET_TODAS_INCUBADORAS, GET_STARTUP_INCUBADORA, GET_INCUBADORA, LIMPAR_INCUBADORA, LIMPAR_ESTAGIOS_STARTUPS, GET_DETALHES_RADAR, GET_USER,
    GET_USERS, GET_STARTUPS_ATIVAS, LIMPAR_MINHAS_INCUBADORAS, LIMPAR_ENTREGA, LIMPAR_METOLOGIAS, LIMPAR_USERS, LIMPAR_MINHAS_STARTUPS, LIMPAR_ETAPAS, LIMPAR_PERGUNTAS, LIMPAR_RESPOSTAS,
    LIMPAR_INCUBADORAS, GET_RELATORIOS, GET_RELATORIOS_DESAFIOS, GET_RELATORIOS_MENTORIAS, GET_SEARCH,
    GET_RELATORIOS_DESENVOLVIMENTO, GET_RELATORIOS_CONTEUDOS, GET_RELATORIOS_INCUBADORA, GET_RELATORIOS_METODOLOGIA, GET_RELATORIOS_STARTUPS, GET_VER_PROCESSO, GET_ETAPA,
    GET_SEARCH_MINHAS_STARTUP, GET_SEARCH_STARTUP_CONFIG, GET_STATUS_ETAPA, LIMPAR_METODOLOGIA, GET_JORNADA_GESTAO,
    GET_MENSAGENS,
    GET_STARTUPS_GESTAO,
    GET_VERIFICAR,
    GET_RADARES_METODOLOGIA,
    GET_METODOLOGIAS_RADAR,
    GET_RADARES,
    GET_PROCESSOS,
    GET_METODOLOGIA,
    GET_VINCULOS_METODOLOGIA,
    GET_PROCESSO,
    GET_VINCULOS_RADAR, GET_RADARES_POR_STARTUP,
    GET_ETAPAS, GET_ESTAGIOS, GET_ESTAGIO, GET_ENTREGAS, GET_ENTREGA_ESTAGIO, LIMPAR_ENTREGA_ESTAGIO,
    LIMPAR_PROCESSO_METODOLOGIA, LIMPAR_ETAPA, GET_PROGRESSO_PROCESSO, LIMPAR_ESTAGIO, GET_METODOLOGIAS_POR_STARTUP
} from '../actions/types';

export default (state = {}, actions) => {
    switch (actions.type) {
        case GET_METODOLOGIAS_POR_STARTUP:
            return {
                ...state,
                metodologiasPorStartup: actions.payload.data
            }
        case GET_RADARES_POR_STARTUP:
            return {
                ...state,
                radaresPorStartup: actions.payload.data
            }
        case GET_VINCULOS_RADAR:
            return {
                ...state,
                radarVinculos: actions.payload.data
            }
        case GET_VINCULOS_METODOLOGIA:
            return {
                ...state,
                metodologiaVinculos: actions.payload.data
            }
        case GET_STARTUPS:
            return {
                ...state,
                startups: actions.payload.data
            }
        case GET_VERIFICAR:
            return {
                ...state,
                pertence: actions.payload.data
            }
        case GET_RADARES_METODOLOGIA:
            return {
                ...state,
                radares: actions.payload.data
            }
        case GET_METODOLOGIAS_RADAR:
            return {
                ...state,
                metodologiasRadar: actions.payload.data
            }
        case GET_METODOLOGIA:
            return {
                ...state,
                metodologia: actions.payload.data
            }
        case GET_PROCESSOS:
            return {
                ...state,
                processosMetodologia: actions.payload.data
            }
        case GET_PROCESSO:
            return {
                ...state,
                processoMetodologia: actions.payload.data
            }
        case LIMPAR_PROCESSO_METODOLOGIA:
            return {
                ...state,
                processoMetodologia: null
            }
        case GET_PROGRESSO_PROCESSO:
            return {
                ...state,
                progressoProcesso: actions.payload.data
            }
        case GET_ETAPAS:
            return {
                ...state,
                etapasProcesso: actions.payload.data
            }
        case GET_STATUS_ETAPA:
            return {
                ...state,
                etapasStatus: actions.payload.data
            }
        case GET_ESTAGIOS:
            return {
                ...state,
                estagiosEtapa: actions.payload.data
            }
        case GET_ESTAGIO:
            return {
                ...state,
                estagio: actions.payload.dados
            }
        case GET_ENTREGAS:
            return {
                ...state,
                entregasEstagio: actions.payload.data
            }
        case GET_ENTREGA_ESTAGIO:
            return {
                ...state,
                entregaEstagio: actions.payload.data
            }
        case LIMPAR_ENTREGA_ESTAGIO:
            return {
                ...state,
                entregaEstagio: null
            }
        case GET_RADARES:
            return {
                ...state,
                radares: actions.payload.data
            }
        case GET_STARTUPS_GESTAO:
            return {
                ...state,
                startupsGestao: actions.payload.data
            }
        case GET_JORNADA_GESTAO:
            return {
                ...state,
                jornadasGestao: actions.payload.data
            }
        case GET_VER_PROCESSO:
            return {
                ...state,
                processo: actions.payload.dados
            }

        case GET_SEARCH_STARTUP_CONFIG:
            return {
                ...state,
                searchStartup: actions.payload.data
            }
        case GET_ETAPA:
            return {
                ...state,
                etapa: actions.payload.dados
            }
        case GET_STARTUPS_ATIVAS:
            return {
                ...state,
                startupsAtivas: actions.payload.data
            }
        case GET_RELATORIOS:
            return {
                ...state,
                relatorios: actions.payload.data
            }
        case GET_MENSAGENS:
            return {
                ...state,
                mensagens: actions.payload.data
            }
        case GET_RELATORIOS_DESENVOLVIMENTO:
            return {
                ...state,
                relatoriosDesenvolvimento: actions.payload.data
            }
        case GET_RELATORIOS_CONTEUDOS:
            return {
                ...state,
                relatoriosConteudos: actions.payload.data
            }
        case GET_RELATORIOS_INCUBADORA:
            return {
                ...state,
                relatoriosIncubadora: actions.payload.data
            }
        case GET_RELATORIOS_METODOLOGIA:
            return {
                ...state,
                relatoriosMetodologia: actions.payload.data
            }
        case GET_RELATORIOS_STARTUPS:
            return {
                ...state,
                relatoriosStartups: actions.payload.data
            }
        case GET_RELATORIOS_DESAFIOS:
            return {
                ...state,
                desafios: actions.payload.data
            }
        case GET_RELATORIOS_MENTORIAS:
            return {
                ...state,
                mentorias: actions.payload.data
            }
        case GET_USERS:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case GET_MINHAS_STARTUPS:
            return {
                ...state,
                minhasStartups: actions.payload.data
            }
        case GET_MINHAS_INCUBADORAS_2:
            return {
                ...state,
                minhasIncubadoras: actions.payload.data
            }
        case GET_TODAS_INCUBADORAS:
            return {
                ...state,
                todasIncubadoras: actions.payload.data
            }

        case GET_STARTUP_INCUBADORA:
            return {
                ...state,
                incubadorasStartup: actions.payload.data
            }
        case GET_INCUBADORA:
            return {
                ...state,
                incubadora: actions.payload.dados
            }
        case GET_STARTUP:
            return {
                ...state,
                startup: actions.payload.dados
            }
        case GET_METODOLOGIAS_STARTUP:
            return {
                ...state,
                metodologias: actions.payload.data
            }
        case GET_ESTAGIOS_STARTUP:
            return {
                ...state,
                estagios: actions.payload.data
            }
        case GET_ETAPAS_STARTUP:
            return {
                ...state,
                etapas: actions.payload.data
            }
        case GET_PARTICIPANTE_STARTUP:
            return {
                ...state,
                participante: actions.payload.data
            }
        case GET_PARTICIPANTES:
            return {
                ...state,
                participantes: actions.payload.data
            }
        case GET_METODOLOGIAS:
            return {
                ...state,
                todasMetodologias: actions.payload.data
            }

        case GET_EVIDENCIA:
            return {
                ...state,
                evidencia: actions.payload.data
            }
        case GET_ENTREGA:
            return {
                ...state,
                entrega: actions.payload.data
            }
        case GET_PERGUNTAS_RADAR:
            return {
                ...state,
                perguntas: actions.payload.data
            }
        case GET_RESPOSTAS_RADAR:
            return {
                ...state,
                respostas: actions.payload.data
            }
        case GET_PILARES_RADAR:
            return {
                ...state,
                pilares: actions.payload.data
            }
        case GET_DETALHES_RADAR:
            return {
                ...state,
                detalhes: actions.payload.data
            }
        case GET_FUTUROS_PARTICIPANTES:
            return {
                ...state,
                usuarios: actions.payload.data
            }
        case GET_SEARCH_STARTUP:
            return {
                ...state,
                searchStartup: actions.payload.data
            }
        case GET_SEARCH_MINHAS_STARTUP:
            return {
                ...state,
                searchMinhasStartup: actions.payload.data
            }
        case GET_SEARCH:
            return {
                ...state,
                search: actions.payload.data
            }
        case GET_USER:
            return {
                ...state,
                usuarioDetalhes: actions.payload.dados
            }
        case LIMPAR_PARTICIPANTE:
            return {
                participanteDetalhes: null
            }
        case LIMPAR_PARTICIPANTES:
            return {
                participantes: null
            }
        case LIMPAR_STARTUPS:
            return {
                startups: null
            }
        case LIMPAR_INCUBADORA:
            return {
                incubadora: null
            }
        case LIMPAR_STARTUP:
            return {
                startup: null
            }
        case LIMPAR_METODOLOGIA:
            return {
                ...state,
                metodologia: null
            }
        case LIMPAR_ESTAGIOS_STARTUPS:
            return {
                estagios: null
            }
        case LIMPAR_MINHAS_INCUBADORAS:
            return {
                minhasIncubadoras: null
            }
        case LIMPAR_ENTREGA:
            return {
                entrega: null
            }
        case LIMPAR_METOLOGIAS:
            return {
                metodologias: null
            }
        case LIMPAR_STARTUP_METODOLOGIA:
            return {
                metodologias: null
            }
        case LIMPAR_USERS:
            return {
                usuarios: null
            }
        case LIMPAR_MINHAS_STARTUPS:
            return {
                minhasStartups: null
            }
        case LIMPAR_ETAPAS:
            return {
                etapas: null
            }
        case LIMPAR_ETAPA:
            return {
                etapa: null
            }
        case LIMPAR_ESTAGIO:
            return {
                estagio: null
            }
        case LIMPAR_PERGUNTAS:
            return {
                perguntas: null
            }
        case LIMPAR_RESPOSTAS:
            return {
                respostas: null
            }
        case LIMPAR_INCUBADORAS:
            return {
                todasIncubadoras: null
            }
        default:
            return state;
    }
}