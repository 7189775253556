import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/trilhas';
import { Link } from 'react-router-dom';
import BlocoPerfil from '../../containers/blocos/perfilTrilha';
import SemDados from '../../componentes/Mensagens/semDados';
import Botao from '../../componentes/Botoes';
import BotaoIcon from '../../componentes/BotaoIcon';

import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
// import Popup from '../../componentes/Alertas/Popup';
import Swal from 'sweetalert2';
import { Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Radar from '../../componentes/Graficos/Radar';
import iconeUsuario from '../../arquivos/imagens/astronaut.png';
import { Player } from 'video-react';

import ReactPlayer from 'react-player'
import parse from 'html-react-parser'
import Skeleton from "react-loading-skeleton";
import Titulo from '../../componentes/TituloPaginas';
import CarregandoPerfil from '../../componentes/Skeleton/CarregandoPerfil';

import { portugues, ingles } from '../../utils/paginatrilhas_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';


class DescricaoTrilha extends Component {
    state = {
        id_trilha: "",
        msg: "",
        erro: "",
        success: "",
        carregando: false,
        modal: false,
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_trilha } = this.props.match.params;
        this.setState({ id_trilha })
        this.listarTrilha(id_trilha)
        if (this.props.usuario) this.setState({ id_usuario: this.props.usuario.id });

        if (this.props.usuario) {
            this.setState({ id_usuario: this.props.usuario.id })
            this.listar(id_trilha, this.props.usuario.id);
        }

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }

    componentDidUpdate(nextProps) {
        const { id_trilha } = this.props.match.params;
        if (!this.props.trilha && nextProps.trilha) {
            this.listarTrilha(id_trilha);
        }
        if (!this.props.modulos && nextProps.modulos) {
            this.listar(id_trilha, this.props.usuario.id);
        }
        this.receberDadosAPI();
    }

    // componentWillUnmount() {
    //     this.props.limparTrilha();
    // }

    receberDadosAPI() {
        if (typeof this.props.trilha != "undefined" && this.props.trilha != null && !this.state.dadosApi &&
            typeof this.props.modulos != "undefined" && this.props.modulos != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    listarTrilha(id_trilha) {
        this.props.getVerTrilha(id_trilha);
        const { trilha } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (trilha === "undefined") return null
    }

    listar(id_trilha, id_usuario) {
        this.props.getModulos(id_trilha, id_usuario)
        const { modulos } = this.props;
        if (this.props.location.state) {
            //////////////console.log(id_trilha)
            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (modulos === "undefined") return null
    }



    render() {
        const { msg, carregando, erro, success, dadosApi, id_trilha, idioma } = this.state;
        var trilha = null;
        if (this.props.trilha) trilha = this.props.trilha;
        var usuario = null;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var matriculado = [];
        if (this.props.modulos) matriculado = this.props.modulos.dados.matriculado;
        var path = this.props.match.path
        var isAuthor = false;
        isAuthor = (trilha?.id_autor === usuario.id)
        for (const l in trilha?.autores) {
            if (!isAuthor) isAuthor = (trilha?.autores[l].id_autor === usuario.id)
        }
        var isAdmin = (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9);
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario?.personalizacao?.cor_principal }}>
                    <Titulo titulo={idioma.descricao.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {trilha != null && usuario != null ?
                        <BlocoPerfil matriculado={matriculado} dadosApi={dadosApi} trilha={trilha} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <CarregandoPerfil />
                    }
                    <div className="col-md-12 my-2">
                        {msg ? <AlertaSuccess erros={{ message: msg }} /> : ""}
                        <AlertaErro erros={erro} />
                        <AlertaSuccess erros={success} />
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {dadosApi ?
                                trilha?.visibilidade === 'Público' || isAuthor || isAdmin || matriculado ?
                                    <div className="row">
                                        <div className="col-md-12 my-2">
                                            {/* <Link onClick={() => this.props.history.goBack()} > <Botao texto={idioma.bt_voltar} classes="botao-responsivo" icone="arrow-left" posicao="E" color={this.props.usuario?.personalizacao.cor_terciaria} /> </Link> */}
                                            {trilha.descricao ?
                                                <div>
                                                    <div className="text-muted">{parse("" + trilha.descricao)}</div>
                                                </div>
                                                : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.descricao.sem_dados} />
                                            }
                                        </div>
                                    </div>
                                    : <SemDados subtitulo={idioma.privado} titulo={idioma.conteudo_privado} />
                                :
                                <div className="row">
                                    <div className="col-md-12 mb-2 text-center">
                                        <div>
                                            <div className="text-muted text-justify mt-3">
                                                <Skeleton count={1} width={80} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    modulos: state.trilhas.modulos,
    trilha: state.trilhas.trilha,
})
export default connect(mapStatetoProps, actions)(DescricaoTrilha);