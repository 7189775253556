import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/startups';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import validator from 'validator';
import { Link, Redirect } from 'react-router-dom';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import Botao from '../../componentes/Botoes';
import SemDados from '../../componentes/Mensagens/semDados';

import { portugues, ingles } from '../../utils/paginaecossis_bilingue';
import { english, portuguese } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class Cadastrar extends Component {
    state = {
        id_incubadora: "",
        nome: "",
        cnpj: "",
        segmento: "",
        cep: "",
        endereco: "",
        cidade: "",
        estado: "",
        siteHTTP: "",
        email: "",
        pitchYT: "",
        clientes: "",
        facebook: "",
        instagram: "",
        linkedin: "",
        id_usuario: "",
        twitter: "",
        erro: "",
        success: "",
        carregando: "",
        file: null,
        pertence: false,
        formSuccess: false,
        idioma: portugues,
        msg_backend: portuguese
    }

    componentDidMount() {
        const { id_incubadora } = this.props.match.params;
        if (this.props.usuario) { this.setState({ id_usuario: this.props.usuario.id }) }

        this.setState({ id_incubadora })
        this.verificaPertence(this.props.usuario.id, id_incubadora)

        if (this.props.usuario && this.props.incubadora && this.props.credenciais) { this.verificaIncubadora(this.props.usuario, this.props.credenciais, id_incubadora) };

        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    componentDidUpdate(nextProps) {
        const { id_incubadora } = this.props.match.params;

        if (!this.props.pertence && nextProps.pertence) {
            this.verificaPertence(this.props.usuario.id, id_incubadora);
        }

        this.receberDadosAPI();
    }


    componentWillUnmount() {
        this.props.limparStartups();
    }

    onChangeInput(campo, ev) {
        this.setState({ [campo]: ev.target.value })
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }



    receberDadosAPI() {
        if (typeof this.props.startups != "undefined" && this.props.startups != null && !this.state.dadosApi && this.props.startups.currentPage === this.state.currentPage) {
            if (typeof this.props.pertence != "undefined" && this.props.pertence != null && !this.state.dadosApi) {
                this.setState({ dadosApi: true });
            }
        }
    }


    cadStartup(id_incubadora, nome, cnpj, cep, endereco, pitchYT, cidade, estado, siteHTTP, segmento, clientes, facebook, instagram, linkedin, twitter, file) {
        if (!this.validate()) return;
        this.setState({ carregando: true })

        var facebookURL = "https://www.facebook.com/"
        var instagramURL = "https://www.instagram.com/"
        var linkedinURL = "https://www.linkedin.com/in/"
        var twitterURL = "https://twitter.com/"
        var pitch = pitchYT.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/");
        var site = siteHTTP.replace("www.", "https://www.");

        if (!facebook.includes("https://www.facebook.com/") || !facebook.includes("facebook.com/") || !facebook.includes("www.facebook.com/")) {
            if (facebook) { facebook = facebookURL + facebook }
            else { facebook = ""; }
        }

        if (!linkedin.includes("https://www.linkedin.com/in/")) {
            if (linkedin) { linkedin = linkedinURL + linkedin }
            else { linkedin = ""; }
        }

        if (!instagram.includes("https://www.instagram.com/")) {
            if (instagram) { instagram = instagramURL + instagram }
            else { instagram = ""; }

        }

        if (!twitter.includes("https://twitter.com/")) {
            if (twitter) { twitter = twitterURL + twitter }
            else { twitter = ""; }

        }
        const { id_usuario } = this.state

        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('id_incubadora', id_incubadora);
        formData.append('nome', nome)
        formData.append('cnpj', cnpj)
        formData.append('cep', cep)
        formData.append('endereco', endereco)
        formData.append('cidade', cidade)
        formData.append('estado', estado)
        formData.append('site', site)
        formData.append('segmento', segmento)
        formData.append('pitch', pitch)
        formData.append('clientes', clientes)
        formData.append('facebook', facebook)
        formData.append('instagram', instagram)
        formData.append('linkedin', linkedin)
        formData.append('twitter', twitter)
        formData.append('id_usuario', id_usuario)

        this.props.postStartup(formData, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ success: "" });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ erro: "" });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        }
        )
    }
    validate() {
        const { nome, cidade, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrarStartup.erro_nome } });
        if (!cidade) return this.setState({ erro: { message: idioma.cadastrarStartup.erro_cidade } });
        return true;
    }
    verificaIncubadora(usuario, credenciais, id_incubadora) {
        if (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 9) {
            this.setState({ pertence: true })
        }
        for (let i in usuario.incubadoras) {
            if (usuario.incubadoras[i].id_incubadora == id_incubadora) {
                this.setState({ pertence: true })
            }
        }
    }

    verificaPertence(id_usuario, id_incubadora) {
        this.props.getVerificarPertence(id_usuario, 0, id_incubadora);
        const { pertence } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (pertence === "undefined") return null
        // console.log(pertence)

    }

    render() {
        const { id_incubadora, pertence, nome, cnpj, file, cep, endereco, cidade, estado, siteHTTP, segmento, pitchYT, clientes, facebook, instagram, linkedin, twitter, erro, success, carregando, iconSalvar, formSuccess, idioma } = this.state;
        var usuarioPertence = [];
        if (this.props.pertence) usuarioPertence = this.props.pertence;
        if (formSuccess) return <Redirect to={{
            pathname: "/incubadoras/" + id_incubadora + "/startups",
            state: { msg: idioma.cadastrarStartup.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.cadastrarStartup.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    <div className="col-md-12 mb-2">
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            {usuarioPertence.pertence ?

                                <div>
                                    {/* <Link onClick={() => this.props.history.goBack()}> <Botao texto="Voltar" classes="botao-responsivo botao-estilo" icone="arrow-left" posicao="E" cor={this.props.usuario?.personalizacao.cor_terciaria} /> </Link> */}
                                    <AlertaErro erros={erro} />
                                    <AlertaSuccess erros={success} />
                                    <Form>
                                        <Row form>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.acompanhamento.nome}</Label>
                                                    <Input type="text" value={nome} autoComplete="nome" onChange={(ev) => this.onChangeInput("nome", ev)} name="nome" id="nome" placeholder={idioma.acompanhamento.nome2} />
                                                    <Input type="hidden" value={id_incubadora} id="id_incubadora" name="id_incubadora" />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="cnpj">{idioma.acompanhamento.cnpj}</Label>
                                                    <Input type="text" maxLength="14" value={cnpj} autoComplete="cnpj" onChange={(ev) => this.onChangeInput("cnpj", ev)} name="cnpj" id="cnpj" placeholder={idioma.acompanhamento.cnpj} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="segmento">{idioma.acompanhamento.segmento}</Label>
                                                    <Input type="text" value={segmento} autoComplete="segmento" onChange={(ev) => this.onChangeInput("segmento", ev)} name="segmento" id="segmento" placeholder={idioma.acompanhamento.segmento2} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="cep">{idioma.acompanhamento.cep}</Label>
                                                    <Input type="text" maxLength="8" value={cep} autoComplete="cep" onChange={(ev) => this.onChangeInput("cep", ev)} name="cep" id="cep" placeholder={idioma.acompanhamento.cep} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="endereco">{idioma.acompanhamento.endereco}</Label>
                                                    <Input type="text" value={endereco} autoComplete="endereco" onChange={(ev) => this.onChangeInput("endereco", ev)} name="endereco" id="endereco" placeholder={idioma.acompanhamento.endereco} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="file">{idioma.cadastrar.img}</Label>
                                            <Input type="file" id="file" name="file" className="form-control" autoComplete="file" onChange={(ev) => this.onChangeInputFile("file", ev)} />
                                        </FormGroup>
                                        <Row form>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="cidade">{idioma.acompanhamento.cidade}</Label>
                                                    <Input type="text" value={cidade} autoComplete="cidade" onChange={(ev) => this.onChangeInput("cidade", ev)} name="cidade" id="cidade" placeholder={idioma.acompanhamento.cidade} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="estado">{idioma.acompanhamento.estado}</Label>
                                                    <Input type="text" value={estado} autoComplete="estado" onChange={(ev) => this.onChangeInput("estado", ev)} name="estado" id="estado" placeholder={idioma.acompanhamento.estado} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="siteHTTP">{idioma.acompanhamento.site}</Label>
                                                    <Input type="text" value={siteHTTP} autoComplete="siteHTTP" onChange={(ev) => this.onChangeInput("siteHTTP", ev)} name="siteHTTP" id="siteHTTP" placeholder={idioma.acompanhamento.site2} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="pitchYT">{idioma.cadastrar.link}</Label>
                                                    <Input type="text" value={pitchYT} autoComplete="pitchYT" onChange={(ev) => this.onChangeInput("pitchYT", ev)} name="pitchYT" id="pitchYT" placeholder={idioma.cadastrarStartup.link2} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="clientes">{idioma.cadastrarStartup.num_clientes}</Label>
                                            <Input type="number" value={clientes} autoComplete="clientes" onChange={(ev) => this.onChangeInput("clientes", ev)} name="clientes" id="clientes" placeholder={idioma.cadastrarStartup.num_clientes} />
                                        </FormGroup>
                                        <Row form>
                                            <Col md={6}>
                                                <Label for="facebook">Facebook</Label>
                                                <Input type="text" value={facebook} autoComplete="facebook" onChange={(ev) => this.onChangeInput("facebook", ev)} name="facebook" id="facebook" placeholder="Facebook" />
                                            </Col>
                                            <Col md={6}>
                                                <Label for="instagram">Instagram</Label>
                                                <Input type="text" value={instagram} autoComplete="instagram" onChange={(ev) => this.onChangeInput("instagram", ev)} name="instagram" id="instagram" placeholder="Instagram" />
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={6}>
                                                <Label for="linkedin">LinkedIn</Label>
                                                <Input type="text" value={linkedin} autoComplete="linkedin" onChange={(ev) => this.onChangeInput("linkedin", ev)} name="linkedin" id="linkedin" placeholder="LinkedIn" />
                                            </Col>
                                            <Col md={6}>
                                                <Label for="twitter">Twitter</Label>
                                                <Input type="text" value={twitter} autoComplete="twitter" onChange={(ev) => this.onChangeInput("twitter", ev)} name="twitter" id="twitter" placeholder="Twitter" />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Link to="#" onClick={() => this.cadStartup(id_incubadora, nome, cnpj, cep, endereco, pitchYT, cidade, estado, siteHTTP, segmento, clientes, facebook, instagram, linkedin, twitter, file)}> <Botao loading={carregando} texto={idioma.cadastrar.bt_cadastrar} cor="success" classes="botao-responsivo botao-estilo" icone="user-astronaut" posicao="E" /> </Link>
                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                    </Form>
                                </div>
                                : <SemDados subtitulo={idioma.sem_dados_sub} titulo={idioma.cadastrarStartup.erro_acesso} />}

                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    usuarioDetalhes: state.usuarios.usuarioDetalhes,
    usuario: state.auth.usuario,
    pertence: state.startups.pertence

})

export default connect(mapStateToProps, actions)(Cadastrar);