import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/eventos';
import { Link, Redirect } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Col, Row, Card } from 'reactstrap';
import BlocoPerfil from '../../containers/blocos/perfilEvento';
import Botao from '../../componentes/Botoes';
import validator from 'validator';
import AlertaErro from '../../componentes/Alertas/AlertaErro';
import AlertaSuccess from '../../componentes/Alertas/AlertaSuccess';
import trilhasReducer from '../../store/reducers/trilhasReducer';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html'
import Skeleton from "react-loading-skeleton";

import { portugues, ingles } from '../../utils/paginaeventos_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';

import Titulo from '../../componentes/TituloPaginas';

class EditarEvento extends Component {
    state = {
        id_evento: "",
        nome: "",
        descricao: "",
        linkHTTP: "",
        cidade: "",
        responsavel: "",
        temDataFim: "",
        data_fim: "",
        data: "",
        erro: "",
        success: "",
        carregando: false,
        dadosAPI: false,
        formSuccess: false,
        editorState: "",
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_evento } = this.props.match.params;
        ////////console.log(id_evento)
        this.props.getVerEvento(id_evento);

        const { usuario } = this.props
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }
    async componentDidUpdate(nextProps) {
        const { id_evento } = this.props.match.params;
        if (!this.props.evento && nextProps.evento) this.props.getVerEvento(id_evento);
        await this.receberDadosAPI();
    }
    receberDadosAPI() {
        const { id_evento } = this.props.match.params;
        if (typeof this.props.evento !== "undefined" && this.props.evento !== null && this.props.evento.id == id_evento && !this.state.dadosAPI) {
            this.setState({ id_evento: this.props.evento.id })
            this.setState({ nome: this.props.evento.nome })
            this.setState({ descricao: this.props.evento.descricao })
            this.setState({ linkHTTP: this.props.evento.link })
            this.setState({ data: this.props.evento.data })
            this.setState({ data_fim: this.props.evento.data_fim })
            if (this.props.evento.data == this.props.evento.data_fim) {
                this.setState({ temDataFim: false })
            } else {
                this.setState({ temDataFim: true })
            }
            this.setState({ cidade: this.props.evento.cidade })
            this.setState({ fonte: this.props.evento.fonte })
            this.setState({ responsavel: this.props.evento.responsavel })
            this.onEditorStateChangeInicial(this.props.evento.descricao)
            this.setState({ dadosAPI: true });
        }
    }
    onChangeInput = (field, ev) => {
        this.setState({ [field]: ev.target.value });
    }

    onChangeInputFile(campo, ev) {
        this.setState({ [campo]: ev.target.files[0] })
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
    }

    onEditorStateChangeInicial = (descricao) => {
        this.setState({
            editorState: EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(descricao)
                )

            ),
        })
    }


    async atualizarEvento(id_evento, nome, fonte, editorState, data, fim, linkHTTP, cidade, responsavel) {
        const { temDataFim } = this.state
        let data_fim = null
        if (temDataFim) {
            data_fim = fim
        } else {
            data_fim = data
        }
        this.setState({ success: "" });
        this.setState({ erro: "" });
        var id = id_evento
        var link = linkHTTP.replace("www.", "https://www.");
        var descricao = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (!this.validate()) return;
        this.props.putEvento({ id, nome, fonte, data, data_fim, descricao, link, responsavel, cidade }, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
            }
        })

    }
    receberDadosForm() {
        this.setState({ id_evento: document.querySelector("#id_evento").value })
        this.setState({ nome: document.querySelector("#nome").value })
        this.setState({ data: document.querySelector("#data").value })
        this.setState({ editorState: document.querySelector("#descricao").value })
        this.setState({ linkHTTP: document.querySelector("#linkHTTP").value })
        this.setState({ fonte: document.querySelector("#fonte").value })
        this.setState({ cidade: document.querySelector("#cidade").value })
        this.setState({ responsavel: document.querySelector("#responsavel").value })
    }
    validate() {
        const { nome, descricao, cidade, responsavel, data, erro, idioma } = this.state;
        if (!nome) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_nome } });
        if (!descricao) return this.setState({ erro: { message: idioma.editarEvento.erro_descricao } });
        if (!cidade) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_cidade } });
        if (!data) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_data } });
        if (!responsavel) return this.setState({ erro: { message: idioma.cadastrarEvento.erro_responsavel } });
        return true;
    }
    render() {
        const { id_evento, nome, fonte, data_fim, temDataFim, descricao, editorState, linkHTTP, data, cidade, responsavel, carregando, dadosAPI, erro, success, formSuccess, idioma } = this.state;
        var evento = null;
        if (this.props.evento) evento = this.props.evento;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path
        if (formSuccess) return <Redirect to={{
            pathname: "/eventos/" + id_evento + "/ver",
            state: { msg: idioma.editarEvento.msg_sucesso }
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.editarEvento.titulo} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {evento != null ?
                        <BlocoPerfil evento={evento} dadosApi={dadosAPI} usuario={usuario} path={path} credenciais={credenciais} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" >
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <h2 className=""><Skeleton count={1} width={160} /></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="col-md-12 mb-2">
                        <AlertaErro erros={erro} />
                        <AlertaSuccess erros={success} />
                        <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <Form className="pt-4">
                                        <Input type="hidden" value={id_evento} id="id_evento" name="id_evento" />
                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="nome">{idioma.cadastrarEvento.nome}</Label>
                                                    <Input type="text" value={nome} id="nome" name="nome" className="form-control" autoComplete="nome" placeholder={dadosAPI ? idioma.editarEvento.nome2 : idioma.editarEvento.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("nome", ev)} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="data">{idioma.cadastrarEvento.data}</Label>
                                                    <Input type="date" value={data} id="data" name="data" className="form-control" autoComplete="data" placeholder={dadosAPI ? idioma.cadastrarEvento.data : idioma.editarEvento.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("data", ev)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={8}>
                                                <Label for="data">{idioma.cadastrarEvento.info_evento}</Label>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" onClick={() => this.setState({ temDataFim: true })} checked={temDataFim ? true : false} />{' '}
                                                        {idioma.cadastrarEvento.sim}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" name="radio1" onClick={() => this.setState({ temDataFim: false })} checked={!temDataFim ? true : false} />{' '}
                                                        {idioma.cadastrarEvento.nao}
                                                    </Label>
                                                </FormGroup>
                                            </Col>
                                            {temDataFim &&
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="data_fim">{idioma.cadastrarEvento.data_fim}</Label>
                                                        <Input type="date" value={data_fim} autoComplete="data_fim" onChange={(ev) => this.onChangeInput("data_fim", ev)} name="data_fim" id="data_fim" placeholder={idioma.cadastrarEvento.data_fim} />
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>
                                        <FormGroup>
                                            <Label for="fonte">{idioma.editarEvento.fonte}</Label>
                                            <Input type="text" value={fonte} id="fonte" name="fonte" className="form-control" autoComplete="fonte" placeholder={dadosAPI ? idioma.editarEvento.fonte2 : idioma.editarEvento.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("fonte", ev)} />
                                        </FormGroup>
                                        <Row form>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="responsavel">{idioma.cadastrarEvento.responsavel}</Label>
                                                    <Input type="text" value={responsavel} id="responsavel" name="responsavel" className="form-control" autoComplete="responsavel" placeholder={dadosAPI ? idioma.cadastrarEvento.responsavel : idioma.editarEvento.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("responsavel", ev)} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="cidade">{idioma.cadastrarEvento.cidade}</Label>
                                                    <Input type="text" value={cidade} id="cidade" name="cidade" className="form-control" autoComplete="cidade" placeholder={dadosAPI ? idioma.cadastrarEvento.cidade : idioma.editarEvento.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("cidade", ev)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="linkHTTP">{idioma.cadastrarEvento.link}</Label>
                                                    <Input type="text" value={linkHTTP} id="linkHTTP" name="linkHTTP" className="form-control" autoComplete="linkHTTP" placeholder={dadosAPI ? idioma.cadastrarEvento.link : idioma.editarEvento.carregando} disabled={dadosAPI ? false : true} onChange={(ev) => this.onChangeInput("linkHTTP", ev)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="descricao">{idioma.cadastrarEvento.descricao}</Label>
                                                    <Card className="card-editor">
                                                        <div>
                                                            <Editor
                                                                editorState={editorState}
                                                                toolbarClassName="toolbarClassName"
                                                                wrapperClassName="wrapperClassName"
                                                                editorClassName="editorClassName"
                                                                onEditorStateChange={this.onEditorStateChange}
                                                            />
                                                        </div>
                                                    </Card>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Link onClick={() => this.atualizarEvento(id_evento, nome, fonte, editorState, data, data_fim, linkHTTP, cidade, responsavel)} to="#">
                                            <Botao loading={carregando} texto={idioma.bt_salvar} cor='success' classes="botao-responsivo botao-estilo" />
                                        </Link>

                                        <Link onClick={() => this.props.history.goBack()}> <Botao texto={idioma.bt_cancelar} classes="botao-responsivo" cor="danger" /> </Link>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    evento: state.eventos.evento

})
export default connect(mapStateToProps, actions)(EditarEvento)