import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/incubadoras';
import BlocoPerfil from '../../containers/blocos/perfilIncubadora';
import { Card, CardImg, CardText, CardBody, CardTitle, CardSubtitle, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';

import { portugues, ingles } from '../../utils/paginaincubadora_bilingue';
import { portuguese, english } from '../../utils/msgsBackend/backend_messages';
import Titulo from '../../componentes/TituloPaginas';

class Configuracoes extends Component {
    state = {
        id_incubadora: "",
        incubadora: "",
        msg: "",
        dadosApi: false,
        idioma: portugues,
        msg_backend: portuguese
    }
    componentDidMount() {
        const { id_incubadora } = this.props.match.params;
        this.setState({ id_incubadora });

        const { usuario } = this.props

        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
            this.setState({ msg_backend: english })
        } else {
            this.setState({ idioma: portugues })
            this.setState({ msg_backend: portuguese })
        }
    }


    componentDidUpdate(nextProps) {
        const { id_incubadora } = this.props.match.params;
        if (!this.props.incubadora && nextProps.incubadora) {
            this.listarIncubadora(id_incubadora);
        }
        this.receberDadosAPI();
    }

    componentWillUnmount() {
        this.props.limparIncubadora();
    }

    receberDadosAPI() {
        if (typeof this.props.incubadora != "undefined" && this.props.incubadora != null && !this.state.dadosApi) {
            this.setState({ dadosApi: true });
        }
    }

    listarIncubadora(id_incubadora) {
        this.props.getVerIncubadora(id_incubadora);
        const { incubadora } = this.props;
        if (this.props.location.state) {

            this.setState({ msg: this.props.location.state.msg })
            this.props.location.state.msg = "";
        }
        if (incubadora === "undefined") return null
    }

    desativandoIncubadora(id_incubadora) {
        this.setState({ carregando: true })
        ////////////console.log(id_incubadora)
        this.props.desativarIncubadora(id_incubadora, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlerta();
            }
        })
    }

    chamarAlerta(id_incubadora) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.gestao.alerta_titulo,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: idioma.bt_cancelar,
            confirmButtonText: idioma.config.modal_confirm
        }).then((result) => {
            if (result.value) {
                this.desativandoIncubadora(id_incubadora);
            }
        })
    }
    sucessoAlerta() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.config.modal_sucesso
        })
    }

    //deletar incubadora
    deletandoIncubadora(id_incubadora) {
        this.setState({ carregando: true })
        this.props.deletarIncubadora(id_incubadora, (msg) => {
            if (msg.erro.error) {
                this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
            } else {
                this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                this.setState({ carregando: false });
                this.setState({ formSuccess: true });
                this.sucessoAlertaDeletar();
            }
        })
    }

    chamarAlertaDeletar(id_incubadora) {
        const { idioma } = this.state
        Swal.fire({
            title: idioma.config.modal_titulo2,
            html: idioma.config.modal_info,
            input: 'text',
            inputPlaceholder: idioma.config.modal_msg,
            confirmButtonColor: '#3085d6',
            icon: 'warning',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: idioma.config.deletar,
            showLoaderOnConfirm: true,

        }).then((texto) => {
            ////////console.log(texto)
            if (texto.value == idioma.config.confirmar) {
                this.deletandoIncubadora(id_incubadora);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: idioma.config.modal_sucesso
                })
            }
        })
    }
    sucessoAlertaDeletar() {
        const { idioma } = this.state
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: idioma.config.msg_sucesso
        })
    }
    render() {
        const { id_incubadora, dadosApi, formSuccess, idioma } = this.state

        var incubadora = null;
        if (this.props.incubadora) incubadora = this.props.incubadora;
        var usuario = [];
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = [];
        if (this.props.credenciais) credenciais = this.props.credenciais;
        var path = this.props.match.path

        if (formSuccess) return <Redirect to={{
            pathname: "/incubadoras/painel"
        }}></Redirect>
        return (
            <>
                <div className="fundo-conteudo-inicio" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao.cor_principal }}>
                    <Titulo titulo={idioma.config.configuracoes} />
                </div>
                <div className="container-fluid bloco-topo" >
                    {incubadora != null ?
                        <BlocoPerfil dadosApi={dadosApi} incubadora={incubadora} credenciais={credenciais} usuario={usuario} path={path} />
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-auto centralizado" >
                                        <div className="img-perfil">
                                            <Skeleton circle={true} height={120} width={120} />
                                        </div>
                                    </div>
                                    <div className="col-md-9 centralizado texto-centralizado">
                                        <h2 className="nomeperfil"><Skeleton count={1} width={160} /></h2>
                                        <p className="text-muted"><Skeleton count={1} width={160} /></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {dadosApi ?
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    {credenciais.length > 0 ?
                                        credenciais.map(credencial => ((credencial.id_componente == 8) ?
                                            <div className="col-md-2 card-menu-perfil" key={credencial.id_componente}>
                                                <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadora/" + incubadora.id + "/startups/cadastrar"}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="meteor" size="3x" color="black" />
                                                                <div className="titulo-menu-perfil">{idioma.config.cadastrar_neg}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            : ""))
                                        : ""}
                                    {(credenciais.length > 0) ?
                                        (credenciais[0].id_perfil == 1 || credenciais[0].id_perfil == 20) ?
                                            <>
                                                <div className="col-md-2 card-menu-perfil" key={incubadora.id} >
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id + "/editar"}>
                                                        <Card className="card-dash">
                                                            <CardBody className="img-text-wrapper">
                                                                <div className="logo-wrapper">
                                                                    <FontAwesomeIcon icon="pencil-alt" size="3x" color="black" />
                                                                    <div className="titulo-menu-perfil">{idioma.config.editar}</div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2 card-menu-perfil" key={incubadora.id} >
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id + "/foto"}>
                                                        <Card className="card-dash">
                                                            <CardBody className="img-text-wrapper">
                                                                <div className="logo-wrapper">
                                                                    <FontAwesomeIcon icon="camera" size="3x" color="black" />
                                                                    <div className="titulo-menu-perfil">{idioma.config.editar_foto}</div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                                <div className="col-md-2 card-menu-perfil" key={incubadora.id} >
                                                    <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id + "/capa"}>
                                                        <Card className="card-dash">
                                                            <CardBody className="img-text-wrapper">
                                                                <div className="logo-wrapper">
                                                                    <FontAwesomeIcon icon="image" size="3x" color="black" />
                                                                    <div className="titulo-menu-perfil">{idioma.config.editar_capa}</div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            </>
                                            :
                                            usuario.incubadoras.map(minhaIncubadoras => ((minhaIncubadoras.id_incubadora == incubadora.id) ?
                                                <>
                                                    <div className="col-md-2 card-menu-perfil" key={minhaIncubadoras.id_incubadora} >
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id + "/editar"}>
                                                            <Card className="card-dash">
                                                                <CardBody className="img-text-wrapper">
                                                                    <div className="logo-wrapper">
                                                                        <FontAwesomeIcon icon="pencil-alt" size="3x" color="black" />
                                                                        <div className="titulo-menu-perfil">{idioma.config.editar}</div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-2 card-menu-perfil" key={minhaIncubadoras.id_incubadora} >
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id + "/foto"}>
                                                            <Card className="card-dash">
                                                                <CardBody className="img-text-wrapper">
                                                                    <div className="logo-wrapper">
                                                                        <FontAwesomeIcon icon="camera" size="3x" color="black" />
                                                                        <div className="titulo-menu-perfil">{idioma.config.editar_foto}</div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-2 card-menu-perfil" key={incubadora.id} >
                                                        <Link to={"/" + usuario?.personalizacao?.titulo + "/ecossistema/incubadoras/" + incubadora.id + "/capa"}>
                                                            <Card className="card-dash">
                                                                <CardBody className="img-text-wrapper">
                                                                    <div className="logo-wrapper">
                                                                        <FontAwesomeIcon icon="image" size="3x" color="black" />
                                                                        <div className="titulo-menu-perfil">{idioma.config.editar_capa}</div>
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Link>
                                                    </div>
                                                </>
                                                : ""))
                                        : ""}
                                    {credenciais.length > 0 ?
                                        credenciais.map(credencial => ((credencial.id_componente == 36) ?
                                            <div className="col-md-2 card-menu-perfil" >
                                                <Link to="#" onClick={() => this.chamarAlerta(id_incubadora)}>
                                                    <Card className="card-dash" key={credencial.id_componente}>
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="power-off" size="3x" color="red" />
                                                                <div className="titulo-menu-perfil">{idioma.config.desativar}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            : ""))
                                        : ""}
                                    {credenciais.length > 0 ?

                                        credenciais.map(credencial => ((credencial.id_componente == 46) ?
                                            <div className="col-md-2 card-menu-perfil"  >
                                                <Link to="#" onClick={() => this.chamarAlertaDeletar(id_incubadora)}>
                                                    <Card className="card-dash">
                                                        <CardBody className="img-text-wrapper">
                                                            <div className="logo-wrapper">
                                                                <FontAwesomeIcon icon="trash-alt" size="3x" color="red" />
                                                                <div className="titulo-menu-perfil">{idioma.config.deletar}</div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Link>
                                            </div>
                                            : ""))
                                        : ""}
                                </div>

                            </div>
                        </div>
                        :
                        <div className="col-md-12 mb-2">
                            <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                                <div className="row">
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-2 card-menu-perfil">
                                        <Card className="card-dash">
                                            <CardBody className="img-text-wrapper">
                                                <div className="logo-wrapper">
                                                    <Skeleton count={1} width={80} />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    incubadora: state.incubadoras.incubadora
})
export default connect(mapStateToProps, actions)(Configuracoes);