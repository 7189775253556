import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/usuarios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { apiImagem } from '../../config/';

import { portugues, ingles } from '../../utils/perfiluser_bilingue';

class PerfilEvento extends Component {
    state = {
        id_usuario: "",
        dadosAPI: false,
        path: "",
        usuario: "",
        currentPage: 1,
        size: 20,
        idioma: ingles
    }

    componentDidMount() {
        const { usuario } = this.props;
        if (usuario?.idioma?.id === 3) {
            this.setState({ idioma: ingles })
        } else {
            this.setState({ idioma: portugues })
        }
    }

    async handleAlterarIdioma(idioma) {
        if (idioma.isDismissed) return;
        const { usuario } = this.props;
        const id_idioma = idioma.value === 'portugues' ? 1 : 3;

        const newData = {
            id: usuario.id,
            nome: usuario.nome,
            email: usuario.email,
            id_idioma: id_idioma
        }

        try {
            await this.props.putUsuario(newData, (msg) => {
                if (msg.erro.error) {
                    this.setState({ erro: { message: this.state.msg_backend[msg.erro.code] } });
                    this.setState({ carregando: false });
                } else {
                    this.setState({ success: { message: this.state.msg_backend[msg.erro.code] } });
                }
            });
        } finally {
            setTimeout(() => window.location.replace('/'), 2000);
        }


    }

    chamarModalIdioma() {
        const { idioma } = this.state;
        Swal.fire({
            title: idioma.modal_password.titulo,
            input: 'select',
            inputOptions: {
                portugues: idioma.modal_password.portugues,
                ingles: idioma.modal_password.ingles
            },
            inputPlaceholder: idioma.modal_password.idioma,
            showCancelButton: true,
            confirmButtonText: idioma.modal_password.bt_salvar,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#3085d6',
        }).then(value => {
            this.handleAlterarIdioma(value)
        })
    }


    render() {
        let { usuario, path, idioma } = this.state;
        if (this.props.usuario) usuario = this.props.usuario;
        var credenciais = []
        if (this.props.credenciais) credenciais = this.props.credenciais;
        return (
            <>
                <div className="col-md-12 mb-2">
                    <div className="list-group-item" style={{ backgroundColor: this.props.usuario && this.props.usuario.personalizacao?.cor_quaternaria }}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-auto" >
                                    <div className="img-perfil">
                                        <img className="rounded-circle mt-2" src={usuario.fileName ? apiImagem + usuario.fileName : apiImagem + "astronaut.png"} width="120" height="120" alt='Avatar' ></img>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <h2 className="nomeperfil">{usuario ? usuario.nome : ""}</h2>
                                    <p className="text-muted">{usuario ? usuario.segmento : ""}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-md-12 mb-3">
                    <div className="list-group-item" >

                        <div className="row">
                            <div className="col-md-12">
                                <div className="container-fluid">
                                    <div className="row">

                                        <div className={path === "/" + usuario?.personalizacao?.titulo + "/perfil/editar-foto" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/perfil/editar-foto"}>
                                                <span className="text-perfil">
                                                    <FontAwesomeIcon icon="camera" size="1x" color="black" className="mr-3" />
                                                    {idioma.foto_perfil}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className={path === "/" + usuario?.personalizacao?.titulo + "/perfil/editar" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/perfil/editar/"}>
                                                <span className="text-perfil">
                                                    <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                    {idioma.perfil}
                                                </span>
                                            </Link>
                                        </div>
                                        <div className={path === "/" + usuario?.personalizacao?.titulo + "/perfil/editar-senha" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/perfil/editar-senha"}>
                                                <span className="text-perfil">
                                                    <FontAwesomeIcon icon="key" size="1x" color="red" className="mr-3" />
                                                    {idioma.senha}
                                                </span>
                                            </Link>
                                        </div>

                                        {credenciais.map(credencial => ((credencial.id_componente == 100) ?
                                            <div className={path === "/" + usuario?.personalizacao?.titulo + "/perfil/editar-idioma" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                                <Link to="#" onClick={() => this.chamarModalIdioma()}>
                                                    <span className="text-perfil">
                                                        <FontAwesomeIcon icon="pencil-alt" size="1x" color="black" className="mr-3" />
                                                        {idioma.idioma}
                                                    </span>
                                                </Link>
                                            </div>
                                            : ""))}
                                        <div className={path === "/" + usuario?.personalizacao?.titulo + "/termos" ? "col-auto menu-cabecalho-active texto-centralizado botao-responsivo" : "col-auto menu-cabecalho texto-centralizado botao-responsivo"}>
                                            <Link to={"/" + usuario?.personalizacao?.titulo + "/termos"}>
                                                <span className="text-perfil">
                                                    <FontAwesomeIcon icon="file-alt" size="1x" color="black" className="mr-3" />
                                                    {idioma.termos}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStatetoProps = state => ({
    usuario: state.auth.usuario


})
export default connect(mapStatetoProps, actions)(PerfilEvento);